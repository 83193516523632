import { useEffect, useRef, useState } from 'react';

import config from '@config';

export type WebsocketProps<R = any> = {
  topic: string;
  onMessage?: (res: R) => void;
};

export const useWebsocket = <R>(props: WebsocketProps<R>): R | undefined => {
  const errorTimeout = useRef<undefined | NodeJS.Timeout>();
  const [instance, setInstance] = useState<EventSource | undefined>();
  const [response, setResponse] = useState<R | undefined>();
  const onMessageRef = useRef(props.onMessage);

  useEffect(() => {
    onMessageRef.current = props.onMessage;
  }, [props.onMessage]);

  useEffect(() => {
    return () => {
      instance?.close();
      cancelErrorTimeout();
    };
  }, [instance]);

  useEffect(() => {
    getInstance();
    return () => {
      instance?.close();
      cancelErrorTimeout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.topic]);

  const cancelErrorTimeout = () => {
    if (errorTimeout.current) {
      clearTimeout(errorTimeout.current);
    }
  };

  const getInstance = () => {
    const instance = new EventSource(
      `${config.websocketUrl}/.well-known/mercure?topic=${encodeURIComponent(props.topic)}`
    );
    instance.onmessage = (event) => {
      const data = event.data ? JSON.parse(event.data) : undefined;
      if (onMessageRef.current) {
        onMessageRef.current(data);
      }
      setResponse(data);
    };
    instance.onerror = () => {
      cancelErrorTimeout();
      errorTimeout.current = setTimeout(() => {
        getInstance();
      }, 1000);
    };
    setInstance(instance);
  };

  return response;
};
