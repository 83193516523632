import React, { ChangeEvent, useEffect, useRef } from 'react';
import _, { isArray } from 'lodash';
import clx from 'classnames';
import { FormikErrors } from 'formik';

import { Button, ControlFeedback, DatePickerInput, FormGroup } from '@components';
import { BuyerFormValues, UserAdditionalPersonValues } from '@types';
import { strIsLatin } from '@helpers/string';
import { Form } from 'react-bootstrap';
import cx from 'classnames';
import inputIcoCalendar from '@assets/images/input-ico-calendar.svg';
import moment from 'moment/moment';
import { getRequestDateFormat } from '@helpers/datetime';

interface Props {
  itemIndex: number;
  readOnly?: boolean;
  title?: string;
  withoutSuggest?: boolean;
  contentClassName?: string;
  values: BuyerFormValues;
  errors: FormikErrors<BuyerFormValues>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

class SuggestProvider extends window.SMap.SuggestProvider {
  _processData() {
    if (!this._responseData) return;

    const rawData: any = JSON.parse(this._responseData.data);

    if (rawData.result && Array.isArray(rawData.result)) {
      const resultsItems = rawData.result.map((item: any) => {
        const poiTypeId = item.userData.poiTypeId || 0;
        const firstRow = (item.userData.suggestFirstRow || '').trim();

        let id = item.userData.id;

        if (item.userData.source === 'coor' && typeof id === 'number') {
          id = item.userData.longitude + ',' + item.userData.latitude;
        }

        return {
          origData: item.userData,
          coords: window.SMap.Coords.fromWGS84(item.userData.longitude, item.userData.latitude),
          longitude: parseFloat(item.userData.longitude),
          latitude: parseFloat(item.userData.latitude),
          source: item.userData.source,
          id: id,
          title: firstRow,
          secondRow: (item.userData.suggestSecondRow || '').trim(),
          thirdRow: (item.userData.suggestThirdRow || '').trim(),
          phrase: firstRow || item.sentence,
          iconType: item.userData.iconType || '',
          iconUrl: this._getIconUrl(poiTypeId),
          poiTypeId: poiTypeId,
          mmid: item.userData.mmid,
          mmsource: item.userData.mmsource,
          mmtype: item.userData.mmtype,
          searchCategory: item.category,
        };
      });
      this._promise.fulfill(resultsItems);
    }
    this._promise = null;
    this._request = null;
  }
}

const Partner: React.FC<Props> = (props) => {
  const addressSuggest = useRef<any>();

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;
    if (!props.withoutSuggest) {
      timeout = setTimeout(() => {
        addressSuggest.current = addSuggest();
      }, 500);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      addressSuggest.current?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.withoutSuggest, props.itemIndex]);

  const addSuggest = () => {
    const suggest = new window.SMap.Suggest(
      document.getElementById(`user-additional-person-address-${props.itemIndex}`),
      {
        provider: new SuggestProvider(),
      }
    );

    suggest.addListener('suggest', (e: any) => {
      props.setFieldValue(`userAdditionalsPerson.${props.itemIndex}.zipCode`, e.data.origData.zipCode);
      props.setFieldValue(`userAdditionalsPerson.${props.itemIndex}.city`, e.data.origData.municipality);
      props.setFieldValue(`userAdditionalsPerson.${props.itemIndex}.address`, e.data.origData.suggestFirstRow);
    });

    return suggest;
  };

  const handleLatinValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      props.handleChange(e);
      return;
    }
    if (strIsLatin(e.target.value)) {
      props.handleChange(e);
    }
  };

  const handleItemRemove = () => {
    let items: UserAdditionalPersonValues[] = [];
    (props.values.userAdditionalsPerson || []).forEach((item, index) => {
      if (index !== props.itemIndex) {
        items.push(item);
      }
    });
    props.setFieldValue('userAdditionalsPerson', items);
  };

  const handleBirthDateChange = (values: Date | Array<Date> | null) => {
    if (values !== null) {
      const value = isArray(values) ? values[0] : values;
      props.setFieldValue(`userAdditionalsPerson.${props.itemIndex}.birthdate`, getRequestDateFormat(value));
    }
  };

  return (
    <div className={clx([props.contentClassName || 'responsive-table-content', 'mt-4', 'mb-2'])}>
      <FormGroup
        required
        type="text"
        label="Jméno"
        readOnly={props.readOnly}
        name={`userAdditionalsPerson.${props.itemIndex}.firstName`}
        error={_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_firstName`)}
        value={_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.firstName`)}
        onChange={handleLatinValueChange}
      />
      <FormGroup
        required
        type="text"
        label="Příjmení"
        readOnly={props.readOnly}
        name={`userAdditionalsPerson.${props.itemIndex}.lastName`}
        error={_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_lastName`)}
        value={_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.lastName`)}
        onChange={handleLatinValueChange}
      />
      <FormGroup
        required
        type="text"
        autoComplete="off"
        label="Ulice a č.p."
        readOnly={props.readOnly}
        id={`user-additional-person-address-${props.itemIndex}`}
        name={`userAdditionalsPerson.${props.itemIndex}.address`}
        error={_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_address`)}
        value={_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.address`)}
        onChange={handleLatinValueChange}
      />
      <FormGroup
        required
        type="text"
        label="PSČ"
        readOnly={props.readOnly}
        name={`userAdditionalsPerson.${props.itemIndex}.zipCode`}
        error={_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_zipCode`)}
        value={_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.zipCode`)}
        onChange={handleLatinValueChange}
      />
      <FormGroup
        required
        type="text"
        label="Město"
        readOnly={props.readOnly}
        name={`userAdditionalsPerson.${props.itemIndex}.city`}
        error={_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_city`)}
        value={_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.city`)}
        onChange={handleLatinValueChange}
      />
      <FormGroup
        required
        type="text"
        label="Země"
        readOnly={props.readOnly}
        name={`userAdditionalsPerson.${props.itemIndex}.country`}
        error={_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_country`)}
        value={_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.country`)}
        onChange={handleLatinValueChange}
      />
      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-right">Datum narození *</Form.Label>
        <div className="f-inline-control d-flex align-items-center">
          <Form.Group className="mb-2 inline-label mr-3 input-ico input-ico-md">
            <div
              className="w-135 position-relative"
              data-test-id={`user-registration-user-additionals-person-${props.itemIndex}-birthdate`}
            >
              <DatePickerInput
                clearIcon={null}
                readOnly={props.readOnly}
                onChange={handleBirthDateChange}
                calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                value={
                  !!_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.birthdate`)
                    ? moment(_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.birthdate`)).toDate()
                    : undefined
                }
                className={cx([
                  'form-control',
                  { 'is-invalid': !!_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_birthdate`) },
                ])}
              />
              {!!_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_birthdate`) && (
                <ControlFeedback type="invalid" style={{ left: 0, bottom: -25 }}>
                  {_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_birthdate`)}
                </ControlFeedback>
              )}
            </div>
          </Form.Group>
        </div>
      </Form.Group>
      <FormGroup
        required
        type="text"
        label="Rodné číslo"
        readOnly={props.readOnly}
        name={`userAdditionalsPerson.${props.itemIndex}.personalIdentificationNumber`}
        error={_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_personalIdentificationNumber`)}
        value={_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.personalIdentificationNumber`)}
        onChange={handleLatinValueChange}
      />
      <FormGroup
        required
        type="text"
        readOnly={props.readOnly}
        label="Číslo dokladu totožnosti"
        name={`userAdditionalsPerson.${props.itemIndex}.identityCardNumber`}
        error={_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_identityCardNumber`)}
        value={_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.identityCardNumber`)}
        onChange={handleLatinValueChange}
      />

      {props.values.type !== 'joint_ownership' && (
        <div style={{ minWidth: 750 }}>
          <FormGroup
            required
            type="number"
            label="Podíl"
            helpIcon="%"
            readOnly={props.readOnly}
            name={`userAdditionalsPerson.${props.itemIndex}.share`}
            error={_.get(props.errors, `userAdditionalsPerson_${props.itemIndex}_share`)}
            value={_.get(props.values, `userAdditionalsPerson.${props.itemIndex}.share`)}
            onChange={handleLatinValueChange}
          />
        </div>
      )}

      {props.values.type !== 'joint_ownership' && (
        <div className="mb-4">
          <Button className="btn-sm pl-3 pr-3" onClick={handleItemRemove}>
            Odebrat spoluvlastníka
          </Button>
        </div>
      )}
    </div>
  );
};

export default Partner;
