import { isArray } from 'lodash';

import { User, UserRole } from '@types';
import { useAuthStore } from './auth';
import { useAuthFrontStore } from './authFront';

export const userRoleChecks = (user: User | Array<User>) => {
  const isUserOfRole = (role: string): boolean => {
    const users = isArray(user) ? user : [user];

    for (const index in users) {
      if (users[index] && users[index].role === role) {
        return true;
      }
    }

    return false;
  };

  const isOfRoleUser = (): boolean => isUserOfRole(UserRole.user);

  const isOfRoleBuyer = (): boolean => isUserOfRole(UserRole.buyer);

  const isOfRoleSuperAdmin = (): boolean => isUserOfRole(UserRole.superAdmin);

  const isOfRoleAuctioneerAdmin = (): boolean => isUserOfRole(UserRole.auctioneerAdmin);

  const isOfRoleAuctioneerEditor = (): boolean => isUserOfRole(UserRole.auctioneerEditor);

  const isOfRoleAuctioneerContact = (): boolean => isUserOfRole(UserRole.auctioneerContact);

  const isOfAnyAdminRole = (): boolean => {
    return isOfRoleSuperAdmin() || isOfRoleAuctioneerAdmin() || isOfRoleAuctioneerEditor();
  };

  const isOfAnyStaffRole = (): boolean => {
    return isOfRoleSuperAdmin();
  };

  return {
    isUserOfRole,
    isOfRoleUser,
    isOfRoleBuyer,
    isOfRoleSuperAdmin,
    isOfRoleAuctioneerAdmin,
    isOfRoleAuctioneerEditor,
    isOfRoleAuctioneerContact,
    isOfAnyAdminRole,
    isOfAnyStaffRole,
  };
};

export const useCurrentUserRoleChecks = () => {
  const { user: userAdmin } = useAuthStore();
  const { user: userFront } = useAuthFrontStore();

  return userRoleChecks([userAdmin, userFront] as Array<User>);
};

export const useCurrentUserPermissionCheck = () => {
  const { user: userAdmin } = useAuthStore();
  const { user: userFront } = useAuthFrontStore();

  const hasPermission = (user: User | undefined, permissionName: string, permissionGroupName?: string): boolean => {
    if (permissionGroupName) {
      if (!!user?.permissions?.groups && isArray(user?.permissions?.groups[permissionGroupName])) {
        return !!~(user?.permissions?.groups[permissionGroupName] as Array<string>).indexOf(permissionName);
      }

      return false;
    }

    return isArray(user?.permissions?.items)
      ? !!~(user?.permissions?.items as Array<string>).indexOf(permissionName)
      : false;
  };

  return {
    hasPermission: (permissionName: string, permissionGroupName?: string): boolean => {
      return (
        hasPermission(userAdmin, permissionName, permissionGroupName) ||
        hasPermission(userFront, permissionName, permissionGroupName)
      );
    },
  };
};

export const useUserTypes = () => {
  const userTypes = [
    {
      label: 'Fyzická osoba',
      value: 'individual',
    },
    {
      label: 'OSVČ',
      value: 'own_account_worker',
    },
    {
      label: 'Právnická osoba',
      value: 'legal',
    },
    {
      label: 'SJM',
      value: 'joint_ownership',
    },
    {
      label: 'Podílové spoluvlastnictví',
      value: 'joint_assets',
    },
  ];

  return { userTypes };
};

export const useUserRegistrationStatus = () => {
  const userRegistrationStatuses: {
    [value: string]: string;
  } = {
    basic_nonactive: 'Základní neaktivní',
    basic_active: 'Základní aktivní',
    buyer_nonactive: 'Dražitel neověřený',
    buyer_active: 'Dražitel ověřený',
  };

  return {
    userRegistrationStatuses: Object.keys(userRegistrationStatuses).map((value) => ({
      value,
      label: userRegistrationStatuses[value],
    })),
    resolveRegistrationStatus: (user: User) => {
      if (user.role === 'ROLE_BUYER') {
        return user.verificationState === 'verified'
          ? userRegistrationStatuses.buyer_active
          : userRegistrationStatuses.buyer_nonactive;
      }

      if (user.role === UserRole.user) {
        if (user.state === 'active') {
          return userRegistrationStatuses.basic_active;
        }

        return userRegistrationStatuses.basic_nonactive;
      }

      return '-';
    },
  };
};
