import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';

import { useEnumApi } from '@api/enum';
import { useAuctionsApi } from '@api/auctions';
import { AuctionResponse, TimesEnumType } from '@types';
import { getRequestDateFormat, getRequestDateTimeFormat } from '@helpers/datetime';
import { ControlFeedback, DatePickerInput, Select, Button, BasePreloader } from '@components';

import inputIcoCalendar from '@assets/images/input-ico-calendar.svg';

interface Props {
  title: string;
  auction: AuctionResponse;
  getTranslation: (key: string) => string;
}

const OutageSection: React.FC<Props> = (props) => {
  const enumApi = useEnumApi();
  const auctionsApi = useAuctionsApi();
  const [generalTimes, setGeneralTimes] = useState<TimesEnumType>();

  const hoursOptions = (generalTimes?.hours || []).map((i) => ({
    label: i,
    value: parseInt(i).toString(),
  }));

  const minuteOptions = (generalTimes?.minutes || []).map((i) => ({
    label: i,
    value: parseInt(i).toString(),
  }));

  const formik = useFormik({
    onSubmit: () => handleSubmit(),
    validateOnChange: false,
    initialValues: {
      startDt: !!props.auction.startDt ? moment(props.auction.startDt).format('YYYY-MM-DD') : '',
      startDtHour: !!props.auction.startDt ? parseInt(moment(props.auction.startDt).format('H')) : 0,
      startDtMinutes: !!props.auction.startDt ? parseInt(moment(props.auction.startDt).format('m')) : 0,
      endDt: !!props.auction.endDt ? moment(props.auction.endDt).format('YYYY-MM-DD') : '',
      endDtHour: !!props.auction.endDt ? parseInt(moment(props.auction.endDt).format('H')) : 0,
      endDtMinutes: !!props.auction.endDt ? parseInt(moment(props.auction.endDt).format('m')) : 0,
    },
  });

  useEffect(() => {
    loadEnums().then();
    return () => {
      enumApi.cancelAllRequests();
      auctionsApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadEnums = async () => {
    try {
      const res = await enumApi.getTimes();
      setGeneralTimes(res.data.data);
    } catch (err) {
      if (enumApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleSubmit = async () => {
    formik.setSubmitting(true);
    try {
      const inputs = formik.values;

      let startDtHour = inputs.startDtHour.toString();
      if (parseInt(startDtHour) < 10) {
        startDtHour = `0${inputs.startDtHour}`;
      }
      let startDtMinutes = inputs.startDtMinutes.toString();
      if (parseInt(startDtMinutes) < 10) {
        startDtMinutes = `0${inputs.startDtMinutes}`;
      }
      let endDtHour = inputs.endDtHour.toString();
      if (parseInt(endDtHour) < 10) {
        endDtHour = `0${inputs.endDtHour}`;
      }
      let endDtMinutes = inputs.endDtMinutes.toString();
      if (parseInt(endDtMinutes) < 10) {
        endDtMinutes = `0${inputs.endDtMinutes}`;
      }

      await auctionsApi.updateAuctionOutage(props.auction.id, {
        startDt: !!inputs.startDt ? getRequestDateTimeFormat(`${inputs.startDt} ${startDtHour}:${startDtMinutes}`) : '',
        endDt: !!inputs.endDt ? getRequestDateTimeFormat(`${inputs.endDt} ${endDtHour}:${endDtMinutes}`) : '',
      });
      formik.setSubmitting(false);
    } catch (err: any) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
  };

  const handleDateChange = (prop: string, value: Date | Date[] | null) => {
    if (value !== null) {
      const inputValue = value as Date;
      formik.setFieldValue(prop, !!inputValue ? getRequestDateFormat(inputValue) : '');
    }
  };

  return (
    <div className="component-admin-box-content">
      <h2 className="f-weight-300 f-size-25 mb-4">{props.title}</h2>

      <form onSubmit={formik.handleSubmit}>
        <div className="responsive-table-content" style={{ overflow: 'visible' }}>
          <div className="pt-2">
            <>
              <Form.Group className="f-inline-group">
                <Form.Label className="f-inline-label">{props.getTranslation('tab_outage_label_start_dt')}</Form.Label>
                <div className="f-inline-control d-flex align-items-center datetime-feedback auction-datetime-picker">
                  <div className="position-relative">
                    <DatePickerInput
                      name="startDt"
                      className={classNames(['form-control', 'w-max-140', { 'is-invalid': !!formik.errors.startDt }])}
                      calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                      clearIcon={null}
                      onChange={(val) => handleDateChange('startDt', val)}
                      value={!!formik.values.startDt ? moment(formik.values.startDt || '').toDate() : undefined}
                    />
                    {!!formik.errors.startDt && (
                      <div className="auction-date-error">
                        <ControlFeedback type="invalid">{formik.errors.startDt}</ControlFeedback>
                      </div>
                    )}
                  </div>
                  <div className="auction-time-picker">
                    <span className="f-size-12 w-weight-400 ml-3 mr-3 time-at">v</span>
                    <div data-test-id="admin-auction-startDtHours">
                      <Select
                        size="md"
                        isSearchable
                        name="startDtHour"
                        minWidth={100}
                        options={hoursOptions}
                        dataTestId="startDtHours"
                        onChange={(val) => formik.setFieldValue('startDtHour', val?.value || '0')}
                        value={hoursOptions.find((o) => o.value === (formik.values.startDtHour || 0).toString())}
                      />
                    </div>
                    <span className="f-size-12 w-weight-400 ml-3 mr-3">:</span>
                    <div data-test-id="admin-auction-startDtMinutes">
                      <Select
                        size="md"
                        isSearchable
                        minWidth={100}
                        name="startDtMinutes"
                        options={minuteOptions}
                        dataTestId="startDtMinutes"
                        onChange={(val) => formik.setFieldValue('startDtMinutes', val?.value || '0')}
                        value={minuteOptions.find((o) => o.value === (formik.values.startDtMinutes || 0).toString())}
                      />
                    </div>
                    <span className="f-size-12 w-weight-400 ml-3 mr-3">hod</span>
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="f-inline-group">
                <Form.Label className="f-inline-label text-left">
                  {props.getTranslation('tab_outage_label_end_dt')}
                </Form.Label>
                <div className="f-inline-control d-flex align-items-center datetime-feedback auction-datetime-picker">
                  <div className="position-relative">
                    <DatePickerInput
                      name="endDt"
                      className={classNames(['form-control', 'w-max-140', { 'is-invalid': !!formik.errors.endDt }])}
                      calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                      clearIcon={null}
                      onChange={(val) => handleDateChange('endDt', val)}
                      value={!!formik.values.endDt ? moment(formik.values.endDt || '').toDate() : undefined}
                    />
                    {!!formik.errors.endDt && (
                      <div className="auction-date-error">
                        <ControlFeedback type="invalid">{formik.errors.endDt}</ControlFeedback>
                      </div>
                    )}
                  </div>
                  <div className="auction-time-picker">
                    <span className="f-size-12 w-weight-400 ml-3 mr-3 time-at">v</span>
                    <div data-test-id="admin-auction-endDtHours">
                      <Select
                        size="md"
                        isSearchable
                        name="endDtHour"
                        minWidth={100}
                        options={hoursOptions}
                        dataTestId="endDtHours"
                        onChange={(val) => formik.setFieldValue('endDtHour', val?.value || '0')}
                        value={hoursOptions.find((o) => o.value === (formik.values.endDtHour || 0).toString())}
                      />
                    </div>
                    <span className="f-size-12 w-weight-400 ml-3 mr-3">:</span>
                    <div data-test-id="admin-auction-endDtMinutes">
                      <Select
                        size="md"
                        isSearchable
                        minWidth={100}
                        name="endDtMinutes"
                        options={minuteOptions}
                        dataTestId="endDtMinutes"
                        onChange={(val) => formik.setFieldValue('endDtMinutes', val?.value || '0')}
                        value={minuteOptions.find((o) => o.value === (formik.values.endDtMinutes || 0).toString())}
                      />
                    </div>
                    <span className="f-size-12 w-weight-400 ml-3 mr-3">hod</span>
                  </div>
                </div>
              </Form.Group>
            </>
          </div>
        </div>

        <div className="mt-5">
          {!formik.isSubmitting ? (
            <Button type="submit" variant="btn-outline-primary" className="float-right">
              Uložit
            </Button>
          ) : (
            <BasePreloader size={29} className="d-inline-block float-right" />
          )}
          <div className="clearfix" />
        </div>
      </form>
    </div>
  );
};

export default OutageSection;
