import React from 'react';
import moment from 'moment';
import { ValueType } from 'react-select';
import { useLocation } from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { LogsFilters, UserType } from '@types';
import { getRequestDateFormat } from '@helpers/datetime';
import { Select, AdminFiltersBox, DatePickerInput } from '@components';

import FiltersInputs from '@components/AdminFiltersBox/components/FiltersInputs';
import FiltersSubmit from '@components/AdminFiltersBox/components/FiltersSubmit';

import inputIcoCalendar from '@assets/images/input-ico-calendar.svg';

interface Props {
  values: LogsFilters;
  userTypes: UserType[];
  onSubmit: (values: LogsFilters) => void;
}

interface PublicSelectOptionType {
  value: string;
  label: string;
}

interface RoleSelectOptionType {
  value: string;
  label: string;
  roles: string[];
}

const Filters: React.FC<Props> = (props) => {
  const pageState = usePageStore();
  const location = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: props.values,
    onSubmit: (values) => handleSubmit(values),
  });
  const userTypesOptions = props.userTypes.map((i) => ({ value: i.type, label: i.translated, roles: i.roles }));

  const publicStates = [
    {
      label: 'Podrobný log',
      value: 'false',
    },
    {
      label: 'Základní log',
      value: 'true',
    },
  ];

  React.useEffect(() => {
    setIsOpen(false);
    formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values]);

  const handleSubmit = (values: FormikValues) => {
    const formValues = { ...values };
    Object.getOwnPropertyNames(formValues).map((prop) => {
      if (formValues[prop] === '') {
        formValues[prop] = undefined;
      }
      return prop;
    });
    formValues.page = 1;
    props.onSubmit(formValues as LogsFilters);
  };

  const hasFilters = (): boolean => {
    return !!location.search;
  };

  const handlePublicChange = (value: ValueType<PublicSelectOptionType, boolean>) => {
    const itemValue = value as PublicSelectOptionType;
    if (itemValue?.value === 'true') {
      formik.setFieldValue('public', true);
      return;
    }
    formik.setFieldValue('public', false);
  };

  const handleRoleChange = (value: ValueType<RoleSelectOptionType, boolean>) => {
    const itemValue = value as RoleSelectOptionType;
    formik.setFieldValue('role', itemValue?.value);
  };

  const handleDateChange = (prop: string, value: Date | Date[] | null) => {
    if (value !== null) {
      const inputValue = value as Date;
      formik.setFieldValue(prop, !!inputValue ? getRequestDateFormat(inputValue) : '');
    } else {
      formik.setFieldValue(prop, '');
    }
  };

  return (
    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)}>
      <AdminFiltersBox isOpen={isOpen}>
        <FiltersInputs>
          <Row>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Změnu provedl (ID)</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="userId"
                  value={formik.values.userId || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Změnu provedl (Jméno)</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="name"
                  value={formik.values.name || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Aukce/Dražba (ID)</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="auction"
                  value={formik.values.auction || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Role</Form.Label>
                <Select
                  isClearable={true}
                  options={userTypesOptions}
                  value={userTypesOptions.find((i) => i.value === String(formik.values.role)) || null}
                  onChange={(val) => handleRoleChange(val as RoleSelectOptionType)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Log</Form.Label>
                <Select
                  isClearable={true}
                  options={publicStates}
                  value={publicStates.find((i) => i.value === String(formik.values.public)) || null}
                  onChange={handlePublicChange}
                />
              </Form.Group>
            </Col>

            <Col sm="auto">
              <Form.Group className="mb-0">
                <Form.Label>Datum</Form.Label>
              </Form.Group>
              <div className="d-block d-sm-flex mt-0">
                <Form.Group className="mb-3 inline-label mr-2 input-ico">
                  <Form.Label>Od</Form.Label>
                  <DatePickerInput
                    isNullable
                    clearIcon={null}
                    className="form-control form-control-sm w-135"
                    calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                    onChange={(val) => handleDateChange('dateFrom', val)}
                    value={formik.values.dateFrom ? moment(formik.values.dateFrom || '').toDate() : undefined}
                  />
                </Form.Group>
                <Form.Group className="mb-3 inline-label input-ico">
                  <Form.Label>Do</Form.Label>
                  <DatePickerInput
                    isNullable
                    clearIcon={null}
                    className="form-control form-control-sm w-135"
                    calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                    onChange={(val) => handleDateChange('dateTo', val)}
                    value={formik.values.dateTo ? moment(formik.values.dateTo || '').toDate() : undefined}
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>
        </FiltersInputs>
        <FiltersSubmit
          hasFilters={hasFilters()}
          onOpenClick={() => setIsOpen(true)}
          clearUrl={pageState.getPagePath(routes.admin.LOG)}
        />
      </AdminFiltersBox>
    </Form>
  );
};

export default Filters;
