import React, { useEffect, useState } from 'react';

import { InvoiceResponse } from '@types';
import { BasePreloader } from '@components';
import { useInvoiceApi } from '@api/invoice';
import { InvoiceForm } from './InvoiceForm';

type Props = {
  id: string;
};

export const InvoiceSection: React.FC<Props> = (props) => {
  const invoiceApi = useInvoiceApi();
  const [invoice, setInvoice] = useState<InvoiceResponse | null>(null);
  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    if (props.id) {
      loadDetail(props.id);
    }
    return () => invoiceApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const loadDetail = (id: string) => {
    setLoaded(false);
    invoiceApi
      .detailInvoice(id)
      .then((res) => {
        setInvoice(res.data.data);
        setLoaded(true);
      })
      .catch((err) => {
        if (invoiceApi.isCancel(err)) {
          return;
        }
      });
  };

  return (
    <div className="component-admin-box-content overlow-x-auto">
      {loaded && invoice ? (
        <div>
          <h2>Upravit fakturu</h2>
          <InvoiceForm data={invoice} />
        </div>
      ) : (
        <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
          <BasePreloader />
        </div>
      )}
    </div>
  );
};
