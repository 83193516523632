import { useWebsocket } from '@websocket/base';
import { AuctionResponse } from '@types';

type Props = {
  id: string | number;
  onMessage?: () => void;
};

export type AuctionDetailWebsocketResponse = {
  data: AuctionResponse;
};

export const useAuctionDetailWebsocket = (props: Props) => {
  return useWebsocket<AuctionDetailWebsocketResponse>({
    topic: `/auctions/${props.id}/detail`,
    onMessage: props.onMessage,
  });
};
