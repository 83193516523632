import React from 'react';
import Cookies from 'js-cookie';
import { QueryParamProvider } from 'use-query-params';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import config from '@config';
import * as routes from '@routes';
import { useAuthApi } from '@api/auth';
import { useAuctioneersApi } from '@api/auctioneers';
import {
  useAuthStore,
  usePageStore,
  useAuthFrontStore,
  useCurrentUserRoleChecks,
  pageContext,
  useApplicationStore,
} from '@stores';
import { PageType } from '@types';
import { useApi } from '@api/api';
import AdminRoutes from './AdminRoutes';
import AuthRoutes from './AuthRoutes';
import FrontRoutes from './FrontRoutes';
import OAuthRoutes from './OAuthRoutes';
import IframeRoutes from './IframeRoutes';
import { MainLoggedForm, Visible, CookieVerification } from '@components';

import 'react-image-lightbox/style.css';
import 'react-sortable-tree/style.css';
import '@assets/scss/style.scss';

const App: React.FC = () => {
  const api = useApi();
  const authApi = useAuthApi();
  const authState = useAuthStore();
  const auctioneersApi = useAuctioneersApi();
  const currentUserRoleChecks = useCurrentUserRoleChecks();
  const authFrontState = useAuthFrontStore();
  const pagesState = usePageStore();
  const applicationState = useApplicationStore();
  const [loaded, setLoaded] = React.useState(false);
  const [isIframeState, setIsIframeState] = React.useState(false);
  const [activePage, setActivePage] = React.useState<PageType | undefined>(undefined);

  React.useEffect(() => {
    if (window?.appVersion) {
      checkVersion();
    }
    loadData(window?.location?.pathname.startsWith('/iframe'));
    return () => authApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkVersion = async () => {
    try {
      const res = await api.request.get('/app-status', { baseURL: '', params: { t: `${Date.now()}` } });
      if (res.data.version && res.data.version !== window.appVersion) {
        window.location.reload();
      }
    } catch (err) {
    } finally {
      setTimeout(() => checkVersion(), 60000);
    }
  };

  const loadData = async (isIframe?: boolean) => {
    await applicationState.startTime();
    await applicationState.loadSystemInfo();
    setIsIframeState(isIframe || false);

    if (isIframe) {
      await pagesState.reload();
      setLoaded(true);
      return;
    }

    const token = Cookies.get(config.auth.accessTokenCookieName);
    const refreshToken = Cookies.get(config.auth.refreshTokenCookieName);
    const frontToken = Cookies.get(config.auth.accessTokenFrontCookieName);
    const frontRefreshToken = Cookies.get(config.auth.refreshTokenFrontCookieName);
    if (!!token && !!refreshToken) {
      try {
        const userResponse = await authApi.logged();

        // If role is auctioneer load auctioneer object
        if (userResponse.data.data.role.startsWith('ROLE_AUCTIONEER')) {
          const auctioneerResponse = await auctioneersApi.list({});
          if (auctioneerResponse.data.data.length > 0) {
            authState.setAuctioneer(auctioneerResponse.data.data[0]);
          } else {
            authState.setAuctioneer(undefined);
          }
        } else {
          authState.setAuctioneer(undefined);
        }

        authState.setUser(userResponse.data.data);
      } catch (e) {
        if (!e.response) {
          return;
        }
        authState.setAuctioneer(undefined);
        authState.unsetUser();
      }
    }
    if (!!frontToken && !!frontRefreshToken) {
      try {
        const frontUserResponse = await authApi.logged(true);
        authFrontState.setUser(frontUserResponse.data.data);
      } catch (e) {
        if (!e.response) {
          return;
        }
        authFrontState.unsetUser();
      }
    }
    await pagesState.reload();
    setLoaded(true);
  };

  if (!loaded || !pagesState.isPagesLoaded) {
    return null;
  }

  const renderContent = () => {
    if (isIframeState) {
      return <IframeRoutes />;
    }

    if (!authState.mainLogged) {
      return <MainLoggedForm />;
    }

    return (
      <pageContext.Provider value={{ activePage, setActivePage }}>
        <Switch>
          <Route path={routes.oauth.BASE}>
            <OAuthRoutes />
          </Route>
          {!!pagesState.getPage(routes.auth.BASE) && (
            <Route path={pagesState.getPagePath(routes.auth.BASE)}>
              <AuthRoutes />
            </Route>
          )}
          {!!pagesState.getPage(routes.admin.BASE) && currentUserRoleChecks.isOfAnyAdminRole() && (
            <Route path={pagesState.getPagePath(routes.admin.BASE)}>
              <AdminRoutes />
            </Route>
          )}
          <Route path={routes.front.HOME}>
            <FrontRoutes />
          </Route>
          <Visible
            and={[
              {
                anyAdmin: true,
              },
              () => authState.isLogged,
            ]}
            else={<Redirect from="*" to={routes.front.HOME} />}
          >
            <Redirect from="*" to={routes.admin.BASE} />
          </Visible>
        </Switch>
        <CookieVerification />
      </pageContext.Provider>
    );
  };

  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>{renderContent()}</QueryParamProvider>
    </Router>
  );
};

export default App;
