import React from 'react';

import * as routes from '@routes';
import { BaseAccountPage } from '@components';
import ChangePassword from './components/ChangePassword';

const AccountSettingsPage: React.FC = () => {
  return (
    <BaseAccountPage page={routes.front.ACCOUNT_SETTINGS}>
      <ChangePassword />
    </BaseAccountPage>
  );
};

export default AccountSettingsPage;
