const config = {
  nodeEnv: process.env.NODE_ENV,
  ga: process.env.REACT_APP_GA || ``,
  appEnv: process.env.REACT_APP_APP_ENV || `develop`,
  websocketUrl: process.env.REACT_APP_WEBSOCKET || ``,
  apiBaseUrl: process.env.REACT_APP_BASE_API_URL || ``,
  fbAppId: process.env.REACT_APP_FB_APP_ID || ``,
  reCaptchaKey: process.env.REACT_APP_RECAPTCHA_KEY || ``,
  auth: {
    accessTokenCookieName: 'token',
    refreshTokenCookieName: 'refresh_token',
    accessTokenFrontCookieName: 'front_token',
    refreshTokenFrontCookieName: 'front_refresh_token',
  },
  enableSelectSearchMinOptions: 10,
};

export default config;
