import React, { useMemo } from 'react';
import { IJodit } from 'jodit';
import Cookies from 'js-cookie';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import JoditEditor from 'jodit-react';

import config from '@config';
import ControlFeedback from '@components/ControlFeedback/ControlFeedback';

interface Props {
  label?: string;
  required?: boolean;
  name?: string;
  error?: string;
  className?: string;
  labelClassName?: string;
  value?: string;
  disabled?: boolean;
  placeholder?: string;
  type?: 'full' | 'normal';
  askBeforePasteHTML?: boolean;
  defaultActionOnPaste?: 'insert_clear_html' | 'insert_as_text' | 'insert_only_text';
  onChange?: (val: string) => void;
}

type EditorStateProps = {
  options: any;
  initialValue: string;
  onChange?: (val: string) => void;
};

const EditorState = (props: EditorStateProps) => {
  return useMemo(
    () => <JoditEditor value={props.initialValue} onChange={props.onChange} config={props.options} />,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
};

const Wysiwyg: React.FC<Props> = (props) => {
  const [initialValue] = React.useState(props.value || '');
  const [editorRef, setEditorRef] = React.useState<IJodit | undefined>();

  React.useEffect(() => {
    if (props.value === '' && editorRef) {
      editorRef.setEditorValue('');
    }
  }, [props.value, editorRef]);

  const getButtons = () => {
    switch (props.type) {
      case 'normal':
        return [
          'source',
          'fullsize',
          'preview',
          '|',
          'bold',
          'underline',
          'italic',
          'paragraph',
          '|',
          'ul',
          'ol',
          '|',
          'link',
          '|',
          'undo',
          'redo',
        ];
      default:
        return [
          'source',
          'fullsize',
          'preview',
          '|',
          'bold',
          'underline',
          'italic',
          'paragraph',
          '|',
          'ul',
          'ol',
          '|',
          'link',
          'image',
          'video',
          '|',
          'undo',
          'redo',
        ];
    }
  };

  const options = {
    language: 'cs',
    readonly: false,
    minHeight: 300,
    placeholder: props.placeholder || '',
    imageDefaultWidth: '100%',
    debugLanguage: false,
    disabled: props.disabled,
    defaultActionOnPaste: props.defaultActionOnPaste,
    askBeforePasteHTML: props.askBeforePasteHTML !== undefined ? props.askBeforePasteHTML : true,
    i18n: {
      cs: {
        'Word Paste Detected': 'Zjistěno kopírování z aplikace',
        'The pasted content is coming from a Microsoft Word/Excel document. Do you want to keep the format or clean it up?':
          'Vložený obsah pochází z dokumentu Microsoft Word/Excel. Chcete zachovat formát, nebo jej vyčistit?',
        'Change mode': 'Změnit režim',
        'Open editor in fullsize': 'Zobrazit na celou obrazovku',
        Clean: 'Vyčistit',
        Preview: 'Náhled',
        'Chars: %d': 'Znaků: %d',
        'Words: %d': 'Slov: %d',
        Bold: 'Tučně',
        Underline: 'Podtrhnout',
        Italic: 'Kurzíva',
        'Insert format block': 'Formát',
        Normal: 'Normální',
        'Heading 1': 'Nadpis 1',
        'Heading 2': 'Nadpis 2',
        'Heading 3': 'Nadpis 3',
        'Heading 4': 'Nadpis 4',
        Quote: 'Citace',
        'Insert link': 'Vložit odkaz',
        'Insert Image': 'Vložit obrázek',
        'Insert youtube/vimeo video': 'Vložit youtube/vimeo video',
        Undo: 'Zpět',
        Redo: 'Vpřed',
        URL: 'URL',
        Text: 'Text',
        'Class name': 'Třida stylu',
        'Open in new tab': 'Otevřít na nové kartě',
        'No follow': 'No follow',
        Insert: 'Vložit',
        Upload: 'Nahrát',
        'Drop image': 'Přetáhnout obrázek',
        'or click': 'Nebo kliknout',
        'Alternative text': 'Alt text',
        Update: 'Upravit',
        Unlink: 'Odpojit',
        'Image properties': 'Vlastnosti obrázku',
        Image: 'Obrázek',
        Advanced: 'Rozšířené',
        Src: 'Zdroj',
        Title: 'Název',
        Alternative: 'Alt text',
        Link: 'Odkaz',
        'Open link in new tab': 'Otevřít odkaz na nové kartě',
        Apply: 'Povrdit',
        Delete: 'Odebrat',
        Margins: 'Odsazení',
        top: 'Horní',
        right: 'Pravé',
        bottom: 'Spodní',
        left: 'Levé',
        Styles: 'Styly',
        Classes: 'Třídy stylu',
        'Border radius': 'Zaoblení',
        Align: 'Zarovnání',
        '--Not Set--': 'Nevyplněno',
        Left: 'Levé',
        Right: 'Pravé',
        Center: 'Uprostřed',
        'Paste as HTML': 'Vložit jako HTML',
        'Your code is similar to HTML. Keep as HTML?': 'Váš kód je stejný jako HTML. Zachovat jako HTML?',
        Keep: 'Zachovat',
        'Insert as Text': 'Vložit jako Text',
        'Insert only Text': 'Vložit pouze Text',
        Cancel: 'Zrušit',
      },
    },
    buttons: getButtons(),
    uploader: {
      filesVariableName: () => 'image',
      imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
      url: `${config.apiBaseUrl}/file/upload-wysiwyg`,
      headers: {
        Authorization: `Bearer ${Cookies.get(config.auth.accessTokenCookieName)}`,
      },
    },
    events: {
      afterInit: setEditorRef,
    },
  };

  return useMemo(
    () => (
      <div className="wysiwyg">
        <Form.Group className={classNames(['f-inline-group', 'f-align-start', props.className])}>
          {!!props.label && (
            <Form.Label className={classNames(['f-inline-label', props.labelClassName])}>
              {props.label}
              {props.required ? ' *' : ''}
            </Form.Label>
          )}
          <div className="f-inline-control" id={props.name}>
            <div style={{ outline: props.error ? '1px solid #b88282' : undefined, borderRadius: 2 }}>
              <EditorState options={options} initialValue={initialValue} onChange={props.onChange} />
            </div>
            <div>{!!props.error && <ControlFeedback type="invalid">{props.error}</ControlFeedback>}</div>
          </div>
        </Form.Group>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.error]
  );
};
export default Wysiwyg;
