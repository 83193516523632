import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useQueryParam } from 'use-query-params';

import * as routes from '@routes';
import { LoginModal } from '@components';
import GuestItems from './components/GuestItems';
import LoggedItems from './components/LoggedItems';
import { useAuthFrontStore, useAuthStore, usePageStore } from '@stores';
import MobileMenu from '@components/LayoutFrontHeader/components/MobileMenu';

import logo from '@assets/images/color-logo.svg';
import icoMail from '@assets/images/front/header/color-ico-mail.svg';
import icoPhone from '@assets/images/front/header/color-ico-phone.svg';
import icoAuctioneer from '@assets/images/front/header/color-ico-auctioneer.svg';

const LayoutFrontHeader: React.FC = () => {
  const authState = useAuthStore();
  const pageState = usePageStore();
  const authFrontState = useAuthFrontStore();
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const [openLoginParam, setOpenLoginParam] = useQueryParam('open-login');

  useEffect(() => {
    if (openLoginParam !== undefined) {
      setShowLoginModal(true);
      setOpenLoginParam(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <LoginModal
        title="Přihlášení"
        isOpen={showLoginModal}
        onRequestClose={() => setShowLoginModal(false)}
        onSuccess={() => setShowLoginModal(false)}
      />
      <div className="layout-front-header">
        <Container fluid className="bg-white">
          <Link to="/" className="header-logo">
            <img src={logo} alt="logo" data-test-id="front-header-logo" />
          </Link>
          <div className="header-info">
            <a href="tel:+420774740636" className="item f-size-19 f-weight-700 nowrap text-decoration-none">
              <img src={icoPhone} alt="ico" />
              +420 774 740 636
            </a>
            <Link
              to={
                authState.isLogged ? pageState.getPagePath(routes.admin.BASE) : pageState.getPagePath(routes.auth.LOGIN)
              }
              data-test-id="front-header-link-admin"
              className="item header-item nowrap f-size-14"
            >
              <img src={icoAuctioneer} alt="ico" />
              <span>Pro dražebníky</span>
            </Link>
            <a href="mailto:info@exdrazby.cz" className="item f-size-14 f-weight-300">
              <img src={icoMail} alt="ico" />
              info@exdrazby.cz
            </a>
          </div>
          <div className="header-items">
            {authFrontState.isLogged ? <LoggedItems /> : <GuestItems onLoginClick={() => setShowLoginModal(true)} />}
          </div>
          <MobileMenu onLoginClick={() => setShowLoginModal(true)} />
        </Container>
        <div className="divider" />
      </div>
    </>
  );
};

export default LayoutFrontHeader;
