import React from 'react';
import { useParams } from 'react-router-dom';

import * as routes from '@routes';
import { AdminBoxContent, Button } from '@components';
import { usePageStore } from '@stores';
import TextBlockForm from './TextBlockForm';
import TextBlockContentTable from './TextBlockContentTable';

type ParamsType = {
  textBlockId: string;
};

const TextBlock: React.FC = () => {
  const { textBlockId } = useParams<ParamsType>();
  const pageState = usePageStore();
  const page = pageState.getPage(
    !!textBlockId ? routes.admin.SETTINGS_TEXT_BLOCK_UPDATE : routes.admin.SETTINGS_TEXT_BLOCK_CREATE
  );

  return (
    <div>
      <div>
        <h2>{page?.name}</h2>
      </div>
      <AdminBoxContent className="p-0">
        <div className="w-min-500">
          <div className="component-admin-box-content overlow-x-auto">
            <TextBlockForm id={textBlockId as any} />
          </div>
        </div>
        {textBlockId && <TextBlockContentTable id={+textBlockId} />}
      </AdminBoxContent>
      <Button to={pageState.getPagePath(routes.admin.SETTINGS_TEXT_BLOCK)}>Zpět na textové bloky</Button>
    </div>
  );
};

export default TextBlock;
