import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { FormLabel } from 'react-bootstrap';

import { useEnumApi } from '@api/enum';
import { useInvoiceApi } from '@api/invoice';
import { EnumType, PaymentResponse } from '@types';
import { BasePreloader, Button, FormGroup, Modal, Select } from '@components';

type Props = {
  item?: PaymentResponse;
  onClose: (reload?: boolean) => void;
};

const PaymentNoteUpdateModal: React.FC<Props> = (props) => {
  const enumApi = useEnumApi();
  const invoiceApi = useInvoiceApi();
  const [paymentTypes, setPaymentTypes] = useState<EnumType[]>([]);
  const formik = useFormik({
    onSubmit: () => handleSubmit(),
    initialValues: {
      note: '',
      paymentType: '',
      variableSymbolOwn: '',
    },
  });
  const paymentTypesOptions = paymentTypes.map((i) => ({
    value: i.type,
    label: i.translated,
    isDisabled: i.isDisabled,
  }));

  useEffect(() => {
    loadData();
    return () => {
      enumApi.cancelAllRequests();
      invoiceApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      setPaymentTypes((await enumApi.getPaymentTypes()).data.data);
    } catch (err: any) {
      if (enumApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const handleSubmit = async () => {
    try {
      await invoiceApi.updatePayment(props.item?.id || '', formik.values);
      props.onClose(true);
    } catch (err: any) {
      if (invoiceApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleOpen = () => {
    formik.setValues({
      note: props.item?.note || '',
      paymentType: props.item?.paymentType || '',
      variableSymbolOwn: props.item?.variableSymbolOwn || '',
    });
  };

  const handleClose = () => {
    invoiceApi.cancelAllRequests();
    props.onClose();
    formik.setSubmitting(false);
  };

  return (
    <Modal title="Úprava platby" isOpen={!!props.item} onAfterOpen={handleOpen} onRequestClose={() => handleClose()}>
      <form onSubmit={formik.handleSubmit}>
        <div>
          <FormLabel>Předmět platby</FormLabel>
          <Select
            size="md"
            name="paymentType"
            options={paymentTypesOptions}
            isLoading={paymentTypesOptions.length < 1}
            value={paymentTypesOptions.find((i) => i.value === formik.values.paymentType) || null}
            onChange={(val) => formik.setFieldValue('paymentType', val?.value || '')}
          />
        </div>
        <div className="mt-3">
          <FormLabel>Vlastní variabilní symbol</FormLabel>
          <FormGroup
            name="variableSymbolOwn"
            className="mb-0"
            inlineControlClass="m-0"
            value={formik.values.variableSymbolOwn}
            onChange={formik.handleChange}
          />
        </div>
        <div className="mt-3">
          <FormLabel>Poznámka</FormLabel>
          <FormGroup
            as="textarea"
            rows={4}
            name="note"
            inlineControlClass="m-0"
            controlClassName="resize-none"
            value={formik.values.note}
            onChange={formik.handleChange}
          />
        </div>
        <div className="d-flex justify-content-end">
          {formik.isSubmitting ? <BasePreloader /> : <Button type="submit">Uložit</Button>}
        </div>
      </form>
    </Modal>
  );
};

export default PaymentNoteUpdateModal;
