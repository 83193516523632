import { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';

import * as routes from '@routes';

import { SEO } from '@components';
import { usePageStore } from '@stores';
import { HintMainItem } from './HintMainItem';
import { usePagesApi } from '@api/pages';
import { PageTextBlockType } from '@types';

export const Hint = () => {
  const pageState = usePageStore();
  const pagesApi = usePagesApi();
  const [textBlocks, setTextBlocks] = useState<PageTextBlockType[]>([]);
  const page = pageState.getPage(routes.front.HINT);
  const subPages = pageState.getPagesByParentPageId(pageState.getPage(routes.front.HINT)?.id || 0);

  const sortedPages = subPages.sort((a, b) => {
    if (a.menuPosition === null) {
      return 1;
    } else if (b.menuPosition === null) {
      return -1;
    }
    return a.menuPosition - b.menuPosition;
  });

  useEffect(() => {
    if (page) {
      loadTextBlocks(page.id);
    }
    return () => {
      pagesApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const loadTextBlocks = async (pageId: number) => {
    try {
      const response = await pagesApi.listTextBlocks(pageId);
      const data = response.data.data;
      setTextBlocks(data);
    } catch (err) {
      if (pagesApi.isCancel(err)) {
        return;
      }
    }
  };

  return (
    <Container fluid>
      <SEO title={page?.name} />
      <div className="component-front-hint">
        <h1>{page?.name || ''}</h1>
        {textBlocks.map((textBlock) => (
          <div className="text-center" dangerouslySetInnerHTML={{ __html: textBlock.content }} />
        ))}
        <div className="main-wrapper">
          {sortedPages.map((item) => (
            <HintMainItem item={item} />
          ))}
        </div>
      </div>
    </Container>
  );
};
