import React from 'react';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';

import Wysiwyg from '@components/Wysiwyg/Wysiwyg';
import { AuctionInformedConsent } from '@types';
import { useInformedConsentApi } from '@api/informedConsent';
import { BasePreloader, Button, FormGroup } from '@components';

interface Props {
  id: string;
  item?: AuctionInformedConsent;
  onClose: () => void;
}

interface FormValues {
  title: string;
  text: string;
}

const InformedConsentForm: React.FC<Props> = (props) => {
  const informedConsentApi = useInformedConsentApi();
  const formik = useFormik<FormValues>({
    onSubmit: () => handleSubmit(),
    validateOnChange: false,
    initialValues: {
      title: props.item?.title || '',
      text: props.item?.text || '',
    },
  });

  const handleSubmit = async () => {
    try {
      const data = { ...formik.values };
      if (!!props.item) {
        await informedConsentApi.update(props.id, props.item?.id || 0, data);
      } else {
        await informedConsentApi.create(props.id, data);
        props.onClose();
      }
    } catch (err) {
      if (informedConsentApi.isCancel(err)) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
  };

  return (
    <div>
      <div className="pl-2">
        <h2 className="f-weight-300 f-size-25">
          {!!props.item ? 'Úprava informovaného souhlasu' : 'Nový informovaný souhlas'}
        </h2>
        <Form className="mt-40" onSubmit={formik.handleSubmit}>
          <div className="responsive-table-content">
            <div className="w-min-600 pt-2">
              <FormGroup
                required
                name="title"
                label="Název"
                labelClassName="text-left"
                error={formik.errors.title}
                value={formik.values.title}
                onChange={formik.handleChange}
              />
              <Wysiwyg
                name="content"
                label="Text"
                error={formik.errors.text as string}
                value={formik.values.text}
                onChange={(val) => formik.setFieldValue('text', val)}
              />
            </div>
          </div>

          <div className="pt-3 d-flex flex-wrap justify-content-between">
            <Button type="button" onClick={() => props.onClose()}>
              Zpět na seznam
            </Button>
            <div>{formik.isSubmitting ? <BasePreloader /> : <Button type="submit">Uložit</Button>}</div>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default InformedConsentForm;
