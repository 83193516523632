import React from 'react';

import { Modal, Button } from '@components';
import { ModalProps } from '@components/Modal/Modal';

interface Props extends ModalProps {
  reason: React.ReactNode;
  onClose: () => void;
}

const RejectedModal: React.FC<Props> = (props) => {
  return (
    <Modal {...props} onRequestClose={props.onClose} className="modal-content-sm">
      <div style={{ margin: '12px 0 24px 0' }}>{props.reason}</div>
      <Button variant="btn-outline-primary" onClick={props.onClose}>
        Zavřít
      </Button>
    </Modal>
  );
};

export default RejectedModal;
