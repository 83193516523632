import Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2';

interface MessageProps {
  title: string;
  text: string;
  icon?: SweetAlertIcon;
  timer?: number;
  confirmButtonText?: string;
}

interface ConfirmMessageProps {
  title: string;
  text: string;
  isHtml?: boolean;
  icon?: SweetAlertIcon;
  confirmButtonText?: string;
  preConfirm?: () => Promise<any> | void;
  showLoaderOnConfirm?: boolean;
}

export function closeMessage() {
  Swal.close();
}

export function message(options: MessageProps): Promise<SweetAlertResult> {
  return Swal.fire({
    title: options.title,
    text: options.text,
    icon: options.icon,
    timer: options.timer,
    confirmButtonText: options.confirmButtonText || 'Zavřít',
    confirmButtonColor: '#0085bb',
  });
}

export function confirmMessage(options: ConfirmMessageProps): Promise<SweetAlertResult> {
  return Swal.fire({
    title: options.title,
    text: !options.isHtml ? options.text : undefined,
    html: options.isHtml ? options.text : undefined,
    showCancelButton: true,
    confirmButtonText: options.confirmButtonText || 'Ano',
    cancelButtonText: 'Zrušit',
    showLoaderOnConfirm: options.showLoaderOnConfirm,
    preConfirm: options.preConfirm,
  });
}
