import { ReactNode } from 'react';
import { Col } from 'react-bootstrap';

type ContactCardProps = {
  children: ReactNode;
  icon: string;
  prDisabled?: boolean;
};

export const ContactCard = (props: ContactCardProps) => {
  const { children, icon, prDisabled } = props;

  return (
    <Col sm={6} xs={12} className={prDisabled ? 'pr-disabled mb-4' : 'mb-4'}>
      <div className="contact-card-wrapper">
        <div className="contact-card ">
          <div className="icon-holder">
            <img src={icon} alt="ico" />
          </div>

          {children}
        </div>
      </div>
    </Col>
  );
};
