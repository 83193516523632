import { DeepPartial } from 'ts-essentials';
import { useState, useEffect } from 'react';
import { get, set, cloneDeep, isFunction } from 'lodash';

export type Listener<Payload extends any = void> = (payload?: Payload) => any;

export interface Store<State extends object> {
  connect(listener: Listener<State>): void;
  disconnect(listener: Listener<State>): void;
  getState(): State;
  get<Value>(path: string): Value;
  setState(newState: DeepPartial<State> | ((currentState: State) => State)): void;
  set(path: string, value: any): void;
}

export const createStore = <State extends object>(initialState: State): Store<State> => {
  const listeners: Array<Listener<State>> = [];

  let state: State = initialState;

  const checkListener = (listener: Listener<State>) => {
    if (typeof listener !== 'function') {
      throw new Error(`listener is expected to be a function, but is of type ${typeof listener}`);
    }
  };

  const handleConnect = (listener: Listener<State>) => {
    checkListener(listener);

    listeners.push(listener);
  };

  const handleDisconnect = (listener: Listener<State>) => {
    checkListener(listener);

    const index = listeners.indexOf(listener);
    if (~index) {
      delete listeners[index];
    }
  };

  const dispatch = () => {
    listeners.forEach((listener) => listener(state));
  };

  return {
    connect: handleConnect,
    disconnect: handleDisconnect,
    getState: () => {
      return Object.assign({}, state);
    },
    get: <Value>(path: string): Value => {
      return get(state, path);
    },
    setState: (change: DeepPartial<State> | ((currentState: State) => State)) => {
      if (isFunction(change)) {
        state = change(state);
        return dispatch();
      }
      Object.getOwnPropertyNames(change).map((prop) => set(state, prop, get(change, prop)));
      dispatch();
    },
    set: (path: string, value: any) => {
      const _state = cloneDeep(state);
      set(_state, path, typeof value === 'object' ? cloneDeep(value) : value);
      state = _state;
      dispatch();
    },
  };
};

export const useStoreState = <State extends object>(store: Store<State>): State => {
  const [state, setState] = useState(store.getState());

  const onStoreChange = () => setState(store.getState());

  useEffect(() => {
    store.connect(onStoreChange);
    return () => store.disconnect(onStoreChange);
    // eslint-disable-next-line
  }, []);

  return state;
};
