import _ from 'lodash';
import React from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';

import * as routes from '@routes';
import { useAuctionsApi } from '@api/auctions';
import { useTranslatorApi } from '@api/translator';
import { AuctionResponse, BaseObjectType } from '@types';
import AuctionSection from './components/AuctionSection';
import ProtestSection from './components/ProtestSection';
import ProtocolSection from './components/ProtocolSection';
import AuctionProgressSection from './components/AuctionProgressSection';
import { urlSearchStore, usePageStore, useUrlSearchStore } from '@stores';
import AuctioneerMessagesSection from './components/AuctioneerMessagesSection';
import { AdminBoxContent, AdminProgressFormMenu, BasePage, BasePreloader, Button } from '@components';

import FiltersForm from './components/FiltersForm';
import OutageSection from './components/OutageSection';
import { AuctionEmailsSection } from './components/AuctionEmailsSection';

import eyeColorIco from '@assets/images/color-ico-eye.svg';
import colorIcoConf from '@assets/images/color-ico-conf.svg';
import auctioneerIcoActive from '@assets/images/admin-sidebar/auctioneer-active-small.svg';
import { EmailsFilterForm } from './components/EmailsFilterForm';
import OngoingExtendedSection from './components/OngoingExtendedSection';

export enum AuctionSectionStep {
  auctionProgress = 'auctionProgress',
  auctioneers = 'auctioneers',
  protocol = 'protocol',
  messages = 'messages',
  protest = 'protest',
  outage = 'outage',
  extendedOngoing = 'extended-ongoing',
  auctionSecurity = 'auctionSecurity',
  emails = 'emails',
}

interface ParamsType {
  id: string;
}

const ActiveAuctionPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const translationsApi = useTranslatorApi();
  const { id } = useParams<ParamsType>();
  const urlSearchState = useUrlSearchStore();
  const pageState = usePageStore();
  const page = pageState.getPage(routes.admin.AUCTIONS_ACTIVE_DETAIL);
  const auctionsApi = useAuctionsApi();
  const [auction, setAuction] = React.useState<AuctionResponse | null>(null);
  const [isTranslationsLoaded, setIsTranslationsLoaded] = React.useState(false);
  const [domainTranslations, setDomainTranslations] = React.useState<BaseObjectType<string>>({});
  const [query, setQuery] = useQueryParams({
    s: withDefault(StringParam, AuctionSectionStep.auctioneers),
  });
  const isLoaded = !!auction;

  React.useEffect(() => {
    if (!!id) {
      loadDetail();
    }
    return () => auctionsApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  React.useEffect(() => {
    if (!!id) {
      const currentSearch = _.get(urlSearchState.adminOnlineAuctionbuyers, id);
      if (currentSearch) {
        history.push(`${pageState.getPagePath(routes.admin.AUCTIONS_ACTIVE_DETAIL, { ':id': id })}${currentSearch}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (!!id) {
      const currentSearch = { ...urlSearchState.adminOnlineAuctionbuyers };
      _.set(currentSearch, id, location.search);
      urlSearchStore.setState({ adminOnlineAuctionbuyers: currentSearch });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, location.search, query]);

  const loadDetail = async () => {
    try {
      const response = await auctionsApi.getAuction(id);
      setAuction(response.data.data);
      loadTranslations(
        response.data.data.auctionType === 'auction' ? 'admin_online_auction' : 'admin_online_auction_public'
      );
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const loadTranslations = async (domain: string) => {
    try {
      setDomainTranslations((await translationsApi.domainTranslations(domain)).data.data);
      setIsTranslationsLoaded(true);
    } catch (err) {
      if (translationsApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const getTranslation = (key: string) => _.get(domainTranslations, key, key);

  const getBackContent = () => {
    if (isLoaded && !!auction) {
      return (
        <span
          className="d-inline-flex align-items-center f-weight-400 f-size-14 text-nowrap back-content"
          style={{ marginTop: -3 }}
        >
          <img src={auctioneerIcoActive} alt="ico" className="mr-2" />
          <span className="f-weight-700 mr-1">Dražebník:</span>
          <Link
            className="d-inline-flex align-items-center f-weight-400 f-size-14"
            to={pageState.getPagePath(routes.admin.AUCTIONEER_UPDATE, { ':id': auction.auctioneer.id })}
          >
            {auction.auctioneer.auctioneerName}
          </Link>
          <Button
            className="ml-2"
            to={`${pageState.getPagePath(routes.admin.AUCTIONS_LIST)}${urlSearchState.adminAuctionsList}#auction-${
              auction.id
            }`}
            grayBg
          >
            Zpět
          </Button>
        </span>
      );
    }
    return undefined;
  };

  const navItems = [
    {
      label: getTranslation('tab_auctioneers_name'),
      url: AuctionSectionStep.auctioneers.toString(),
      isActive: query.s === AuctionSectionStep.auctioneers,
    },
    {
      label: getTranslation('tab_progress_name'),
      url: AuctionSectionStep.auctionProgress.toString(),
      isActive: query.s === AuctionSectionStep.auctionProgress,
    },
    {
      label: getTranslation('tab_protocol_name'),
      url: AuctionSectionStep.protocol.toString(),
      isActive: query.s === AuctionSectionStep.protocol,
    },
    {
      label: getTranslation('tab_messages'),
      url: AuctionSectionStep.messages.toString(),
      isActive: query.s === AuctionSectionStep.messages,
    },
    {
      label: getTranslation('tab_auction_security'),
      url: AuctionSectionStep.auctionSecurity.toString(),
      isActive: query.s === AuctionSectionStep.auctionSecurity,
    },
  ];

  if (auction?.canProtest) {
    navItems.splice(2, 0, {
      label: getTranslation('tab_progress_protest'),
      url: AuctionSectionStep.protest.toString(),
      isActive: query.s === AuctionSectionStep.protest,
    });
  }

  if (auction?.outage) {
    navItems.push({
      label: getTranslation('tab_progress_outage'),
      url: AuctionSectionStep.outage.toString(),
      isActive: query.s === AuctionSectionStep.outage,
    });
  }

  if (auction?.canExtendOnGoing) {
    navItems.push({
      label: getTranslation('tab_progress_extended_ongoing'),
      url: AuctionSectionStep.extendedOngoing.toString(),
      isActive: query.s === AuctionSectionStep.extendedOngoing,
    });
  }

  navItems.push({
    label: getTranslation('tab_progress_emails'),
    url: AuctionSectionStep.emails.toString(),
    isActive: query.s === AuctionSectionStep.emails,
  });

  if (!isTranslationsLoaded) {
    return null;
  }

  return (
    <BasePage
      page={routes.admin.AUCTIONS_LIST}
      customTitle={
        isLoaded && !!auction ? (
          <h1>
            <strong className="f-weight-600 f-size-20 mb-2 title mr-4">{auction.title}</strong>
            <div className="link-holder mb-3">
              <Link
                className="d-inline-flex align-items-center f-weight-400 f-size-14 ml-0"
                to={pageState.getPagePath(routes.admin.AUCTIONS_UPDATE, { ':id': auction.id.toString() })}
              >
                <img src={colorIcoConf} alt="ico" className="mr-1" />
                {getTranslation('update_btn')}
              </Link>
              <Link
                className="d-inline-flex align-items-center f-weight-400 f-size-14"
                to={pageState.getPagePath(
                  auction.auctionType === 'auction' ? routes.front.AUCTION_DETAIL : routes.front.AUCTION_PUBLIC_DETAIL,
                  { ':id': auction.hash }
                )}
                target="_blank"
              >
                <img src={eyeColorIco} alt="ico" className="mr-1" />
                Zobrazit na webu
              </Link>
            </div>
          </h1>
        ) : (
          page?.name
        )
      }
      backCustomContent={getBackContent()}
    >
      {(query.s === AuctionSectionStep.auctioneers || query.s === AuctionSectionStep.auctionSecurity) && !!auction && (
        <FiltersForm
          auctionId={auction?.id}
          getTranslation={getTranslation}
          hasCautionDeposit={!!auction?.cautionDeposit}
          isAuctionSecurity={query.s === AuctionSectionStep.auctionSecurity}
        />
      )}

      {auction && query.s === AuctionSectionStep.emails && <EmailsFilterForm auctionId={auction.id} />}

      <AdminProgressFormMenu items={navItems} onClick={(section: any) => setQuery({ s: section }, 'push')} />
      <AdminBoxContent className="p-0">
        {isLoaded ? (
          <>
            {query.s === AuctionSectionStep.auctioneers && (
              <AuctionSection isAuctionSection auction={auction as AuctionResponse} />
            )}
            {query.s === AuctionSectionStep.auctionProgress && (
              <AuctionProgressSection auction={auction as AuctionResponse} />
            )}
            {query.s === AuctionSectionStep.protocol && <ProtocolSection auction={auction as AuctionResponse} />}
            {query.s === AuctionSectionStep.messages && (
              <AuctioneerMessagesSection auction={auction as AuctionResponse} />
            )}
            {query.s === AuctionSectionStep.protest && auction?.canProtest && (
              <ProtestSection auction={auction as AuctionResponse} translations={domainTranslations} />
            )}
            {query.s === AuctionSectionStep.auctionSecurity && (
              <AuctionSection isAuctionSecurity auction={auction as AuctionResponse} />
            )}
            {auction?.outage && query.s === AuctionSectionStep.outage && (
              <OutageSection
                auction={auction}
                getTranslation={getTranslation}
                title={getTranslation('tab_outage_title')}
              />
            )}
            {auction?.canExtendOnGoing && query.s === AuctionSectionStep.extendedOngoing && (
              <OngoingExtendedSection
                auction={auction}
                getTranslation={getTranslation}
                title={getTranslation('tab_extended_ongoing_title')}
              />
            )}
            {auction && query.s === AuctionSectionStep.emails && <AuctionEmailsSection auctionId={auction.id} />}
          </>
        ) : (
          <div className="pt-5 pb-4">
            <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
              <BasePreloader />
            </div>
          </div>
        )}
      </AdminBoxContent>
      {!id && (
        <div>
          <Button grayBg to={`${pageState.getPagePath(routes.admin.AUCTIONS_LIST)}${urlSearchState.adminAuctionsList}`}>
            Zpět na online dražby
          </Button>
        </div>
      )}
    </BasePage>
  );
};

export default ActiveAuctionPage;
