import { AxiosResponse } from 'axios';

import { WysiwygFileResponse } from '@types';
import { useApi, API, APIResponse } from '@api/api';

export const fileDetailPath = (hash: string) => `/file/${hash}`;
export const uploadWysiwygPath = () => `/file/upload-wysiwyg`;

export interface MediaAPI extends API {
  detail(hash: string, view: true, size?: string): Promise<AxiosResponse<any>>;
  detail(hash: string, view: false | undefined, size?: string): Promise<AxiosResponse<File>>;
  detailFront(hash: string, view: true, isAuth?: boolean, size?: string): Promise<AxiosResponse<any>>;
  detailFront(hash: string, view: false | undefined, isAuth?: boolean, size?: string): Promise<AxiosResponse<File>>;
  uploadWysiwyg(file: File): APIResponse<WysiwygFileResponse>;
}

export const useMediaApi = (): MediaAPI => {
  const api = useApi();

  return {
    ...api,
    detail(hash: string, view?: boolean, size?: string) {
      const cancelToken = api.prepareCancelToken('detail');
      return api.authRequest.get(fileDetailPath(hash), {
        cancelToken,
        params: { view, size },
        responseType: view ? 'blob' : undefined,
      });
    },
    detailFront(hash: string, view?: boolean, isAuth?: boolean, size?: string) {
      const cancelToken = api.prepareCancelToken('detail');
      return (isAuth ? api.authFrontRequest : api.request).get(fileDetailPath(hash), {
        cancelToken,
        params: { view, size },
        responseType: view ? 'blob' : undefined,
      });
    },
    uploadWysiwyg(file: File) {
      const cancelToken = api.prepareCancelToken('uploadWysiwyg');
      var formData = new FormData();
      formData.append('image', file);
      return api.authRequest.post(uploadWysiwygPath(), formData, {
        cancelToken,
        headers: { 'Content-Type': 'multipart/form-data' },
      });
    },
  };
};
