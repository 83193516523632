import React from 'react';
import { Table } from 'react-bootstrap';

import { AuctionMapResponse } from '@types';

type Props = {
  auctions: AuctionMapResponse[];
  onClick: (auction: AuctionMapResponse) => void;
};

const MapPointsPopup: React.FC<Props> = (props) => {
  return (
    <div className="map-popup-content width-lg">
      <Table className="mb-0 table-bordered table-hover">
        <tbody>
          {props.auctions.map((auction) => (
            <tr className="cursor-pointer" key={`auction-${auction.auction}`} onClick={() => props.onClick(auction)}>
              <td className="p-2">{auction.number}</td>
              <td className="p-2">{auction.title}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default MapPointsPopup;
