import React from 'react';
import { Table } from 'react-bootstrap';
import fileDownload from 'js-file-download';

import { FileIcon } from '@components';
import { AuctionInformationDocumentResponse } from '@types';
import { useAuctionInformationApi } from '@api/auctionInformation';

import colorIcoDel from '@assets/images/color-ico-del.svg';

interface Props {
  auctionId: string;
  informationId: string | number;
  data: AuctionInformationDocumentResponse[];
  onDeleteClick: (item: AuctionInformationDocumentResponse) => void;
}

const AuctionInformationDocumentsTable: React.FC<Props> = (props) => {
  const auctionInformationApi = useAuctionInformationApi();

  const handleFileClick = (e: React.MouseEvent<HTMLAnchorElement>, item: AuctionInformationDocumentResponse) => {
    e.preventDefault();
    auctionInformationApi
      .getAdminDocument(props.auctionId, props.informationId, item.id)
      .then((res) => {
        fileDownload(res.data, item.media.originalName);
      })
      .catch((err) => {
        if (auctionInformationApi.isCancel(err)) {
          return;
        }
      });
  };

  const handleDeleteClick = (e: React.MouseEvent, item: AuctionInformationDocumentResponse) => {
    e.preventDefault();
    props.onDeleteClick(item);
  };

  return (
    <div>
      <div className="mt-4">
        <div className="responsive-table-content">
          <Table className="table-middle border-bottom-0" striped>
            <thead>
              <tr>
                <th>Originální název dokumentu</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {props.data.length < 1 && (
                <tr>
                  <td className="text-left" colSpan={100}>
                    Nebyla nalezena žádná data.
                  </td>
                </tr>
              )}
              {props.data.map((item, index) => (
                <tr key={`document-${index}`}>
                  <td>
                    <FileIcon name={item.media.originalName} />
                    <a href="/" onClick={(e) => handleFileClick(e, item)}>
                      {item.media.originalName}
                    </a>
                  </td>
                  <td className="text-right">
                    <a
                      href="/"
                      onClick={(e) => handleDeleteClick(e, item)}
                      className="d-inline-flex align-items-center"
                    >
                      <img src={colorIcoDel} alt="ico" className="mr-2" />
                      Smazat
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default AuctionInformationDocumentsTable;
