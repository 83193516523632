import React from 'react';

import { BaseFrontSidebarBox } from '@components/FrontSidebarBox';

import svetITBanner from '@assets/images/front/sidebar/svetit_banner.jpg';
import jrlivingBanner from '@assets/images/front/sidebar/banner_jrliving.jpg';

export const FrontSidebarBanners = () => (
  <BaseFrontSidebarBox title="Reklamní prostor" contentClassName="box-images">
    <a href="https://www.jrliving.cz" target="_blank" rel="noreferrer">
      <img src={jrlivingBanner} alt="jrliving.cz" />
    </a>
    <a href="https://svetit.cz" target="_blank" rel="noreferrer">
      <img src={svetITBanner} alt="svetit.cz" />
    </a>
  </BaseFrontSidebarBox>
);
