import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { AuctionCountResponse, BaseResponse } from '@types';
import { useAuctionsApi } from '@api/auctions';
import { usePageStore } from '@stores';
import * as routes from '@routes';
import { useAuctionsListWebsocket } from '@websocket';
import { useWindowFocus } from '@hooks';

const route: any = {
  prepared: routes.front.AUCTION_LIST_PREPARED,
  ongoing: routes.front.AUCTION_LIST_ONGOING,
  ended: routes.front.AUCTION_LIST_ENDED,
  overcharge: routes.front.AUCTION_LIST_OVERCHARGE,
};

export const MobileMenuAuctions = () => {
  const pageState = usePageStore();
  const auctionsApi = useAuctionsApi();
  const [items, setItems] = useState<AuctionCountResponse[]>([]);
  useAuctionsListWebsocket({
    onMessage: () => getAuctionCounts(),
  });

  useWindowFocus({
    onFocus: () => {
      getAuctionCounts();
    },
  });

  useEffect(() => {
    getAuctionCounts();

    return () => {
      auctionsApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getAuctionCounts = async () => {
    try {
      const auctionCountsResponse: BaseResponse<AuctionCountResponse[]> = (await auctionsApi.getWebAuctionsCount())
        .data;
      setItems(auctionCountsResponse.data);
    } catch (err: any) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  return (
    <div className="mobile-menu-items">
      <ul>
        {items.map((item, index) => (
          <li key={index}>
            {item.title !== 'Archiv' ? (
              <Link to={pageState.getPagePath(route[item.status as string])}>
                {item.title}
                {item.count !== undefined && <span>({item.count})</span>}
              </Link>
            ) : (
              <a target="_blank" rel="noopener noreferrer" href="https://exdrazby.cz">
                {item.title}
                {item.count !== undefined && <span>({item.count})</span>}
              </a>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
