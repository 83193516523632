import React from 'react';
import { Link } from 'react-router-dom';

import { useAuctioneersApi } from '@api/auctioneers';
import { BasePreloader, Button, Visible } from '@components';
import { closeMessage, confirmMessage } from '@helpers/messages';
import { AuctioneerPDData, PermissionGroup, UserPermission } from '@types';
import DataAdministrationForm from './DataAdministrationForm';

import plusIco from '@assets/images/plus-ico.svg';
import editIco from '@assets/images/edit-ico.svg';
import colorIcoDel from '@assets/images/color-ico-del.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';
import editIcoWhite from '@assets/images/edit-ico-white.svg';

type Props = {
  id: string;
  showPDForm: boolean;
  onNotFound: () => void;
  setShowPDForm: (val: boolean) => void;
};

export const PDAuthorization: React.FC<Props> = (props) => {
  const auctioneersApi = useAuctioneersApi();
  const [loaded, setLoaded] = React.useState(false);
  const [updateItem, setUpdateItem] = React.useState(undefined as AuctioneerPDData | undefined);
  const [pdData, setPdData] = React.useState({} as AuctioneerPDData);

  React.useEffect(() => {
    loadData();
    return () => auctioneersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    auctioneersApi
      .getPDData(props.id)
      .then((res) => {
        setPdData(res.data.data);
        setLoaded(true);
      })
      .catch((err) => {
        if (auctioneersApi.isCancel(err)) {
          return;
        }
        if (err?.response?.status === 404 || err?.response?.status === 403) {
          props.onNotFound();
          return;
        }
      });
  };

  const handleUpdateClick = (item: AuctioneerPDData) => {
    setUpdateItem(item);
    props.setShowPDForm(true);
  };

  const handleSave = () => {
    props.setShowPDForm(false);
    setUpdateItem(undefined);
    setLoaded(false);
    loadData();
  };

  const handleDeleteClick = (e: React.MouseEvent, item: AuctioneerPDData) => {
    e.preventDefault();
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat tuto položku?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return auctioneersApi.deletePDData(props.id).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadData();
      }
    });
  };

  if (!loaded) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <div className="mb-5">
      {props.showPDForm ? (
        <DataAdministrationForm
          id={props.id}
          data={updateItem}
          onClose={() => props.setShowPDForm(false)}
          onSubmit={() => props.setShowPDForm(false)}
          onSave={handleSave}
        />
      ) : (
        <div>
          <h2>Přístup na PD</h2>
          {pdData && (
            <div className="mt-4">
              <p className="d-inline-flex align-items-center">
                <strong className="mr-1">Login: </strong>
                {pdData.login}
                <Link
                  to="/"
                  className="d-inline-flex align-items-center ml-3"
                  onClick={(e) => handleDeleteClick(e, pdData)}
                >
                  <img src={colorIcoDel} alt="ico" className="mr-2" />
                </Link>
              </p>
            </div>
          )}
          <Visible
            permissionGroupName={PermissionGroup.auctioneerInformation}
            permissionName={UserPermission.canCreate}
          >
            <Button className="f-size-12 f-weight-400 mt-3" onClick={() => handleUpdateClick(pdData)}>
              {pdData ? (
                <>
                  <img src={editIco} alt="ico" className="mr-2 hover-hide" width={12} height={12} />
                  <img src={editIcoWhite} alt="ico" className="mr-2 hover-show" width={12} height={12} />
                </>
              ) : (
                <>
                  <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
                  <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
                </>
              )}
              {pdData ? 'Upravit přístup' : 'Přidat přístup'}
            </Button>
          </Visible>
        </div>
      )}
    </div>
  );
};
