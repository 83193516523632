import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import * as routes from '@routes';
import { useVisibility, Visible } from '@components';
import { Auctioneer, PermissionGroup, UserPermission } from '@types';
import { usePageStore, useCurrentUserRoleChecks } from '@stores';

import colorIcoDel from '@assets/images/color-ico-del.svg';
import colorIcoEye from '@assets/images/color-ico-eye.svg';
import colorIcoConf from '@assets/images/color-ico-conf.svg';
import colorIcoUser from '@assets/images/color-ico-user.svg';
import colorIcoRestore from '@assets/images/color-ico-restore.svg';

interface Props {
  data: Array<Auctioneer>;
  onDeleteClick: (item: Auctioneer) => void;
  onRestoreClick: (item: Auctioneer) => void;
}

const ItemsTable: React.FC<Props> = (props) => {
  const { shouldBeVisible } = useVisibility();
  const currentUserRoleChecks = useCurrentUserRoleChecks();
  const pageState = usePageStore();

  const handleDeleteClick = (e: React.MouseEvent, item: Auctioneer) => {
    e.preventDefault();
    props.onDeleteClick(item);
  };

  const handleRestoreClick = (e: React.MouseEvent, item: Auctioneer) => {
    e.preventDefault();
    props.onRestoreClick(item);
  };

  const renderDeleteItem = (item: Auctioneer): React.ReactNode => {
    if (
      !shouldBeVisible({
        permissionGroupName: PermissionGroup.auctioneers,
        permissionName: UserPermission.canDelete,
      })
    ) {
      return null;
    }

    if (item.state !== 'deleted') {
      return (
        <Link className="d-inline-flex align-items-center ml-4" to="/" onClick={(e) => handleDeleteClick(e, item)}>
          <img src={colorIcoDel} alt="ico" className="mr-2" />
          Smazat
        </Link>
      );
    }

    if (item.state === 'deleted' && currentUserRoleChecks.isOfRoleSuperAdmin()) {
      return (
        <Link className="d-inline-flex align-items-center ml-4" to="/" onClick={(e) => handleRestoreClick(e, item)}>
          <img src={colorIcoRestore} alt="ico" className="mr-2" />
          Obnovit
        </Link>
      );
    }

    return null;
  };

  return (
    <div className="responsive-table-content">
      <Table className="table-middle" striped>
        <thead>
          <tr>
            <th>Číslo</th>
            <th>Název subjektu</th>
            <th>IČO</th>
            <th>E-mail</th>
            <th>Tel.</th>
            <th>Typ Dražebníka</th>
            <th>Stav</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {props.data.map((item, index) => (
            <tr key={`list-item-${index}`} data-test-id={`admin-auctioneer-table-row-${index}`}>
              <td data-test-id="admin-auctioneer-table-column-id">{item.id}</td>
              <td data-test-id="admin-auctioneer-table-column-name">{item.auctioneerName}</td>
              <td data-test-id="admin-auctioneer-table-column-ic">{item.ic}</td>
              <td data-test-id="admin-auctioneer-table-column-email">{item.email}</td>
              <td data-test-id="admin-auctioneer-table-column-phone">{item.phone}</td>
              <td data-test-id="admin-auctioneer-table-column-type">{item.typeTranslation}</td>
              <td data-test-id="admin-auctioneer-table-column-state">{item.stateTranslation}</td>
              <td className="text-right" data-test-id="admin-auctioneer-table-column-actions">
                <Visible
                  or={[
                    {
                      permissionGroupName: PermissionGroup.auctioneerEmployees,
                      permissionName: UserPermission.canView,
                    },
                    {
                      permissionGroupName: PermissionGroup.auctioneerEmployees,
                      permissionName: UserPermission.canEdit,
                    },
                  ]}
                >
                  <Link
                    className="d-inline-flex align-items-center"
                    data-test-id="admin-auctioneer-table-column-actions-users"
                    to={`${pageState.getPagePath(
                      !!pageState.getPage(routes.admin.AUCTIONEER_UPDATE)
                        ? routes.admin.AUCTIONEER_UPDATE
                        : routes.admin.MY_COMPANY_UPDATE,
                      { ':id': item.id }
                    )}?step=3`}
                  >
                    <img src={colorIcoUser} alt="ico" className="mr-2" />
                    Uživatelé
                  </Link>
                </Visible>
                <Visible
                  or={[
                    {
                      permissionGroupName: PermissionGroup.auctioneers,
                      permissionName: UserPermission.canView,
                    },
                    {
                      permissionGroupName: PermissionGroup.myCompany,
                      permissionName: UserPermission.canView,
                    },
                  ]}
                >
                  <Link
                    className="d-inline-flex align-items-center ml-4"
                    data-test-id="admin-auctioneer-table-column-actions-detail"
                    to={
                      !!pageState.getPage(routes.admin.AUCTIONEER_UPDATE)
                        ? `${pageState.getPagePath(routes.admin.AUCTIONEER_UPDATE, { ':id': item.id })}?readonly=1`
                        : pageState.getPagePath(routes.admin.MY_COMPANY_UPDATE, { ':id': item.id })
                    }
                  >
                    <img src={colorIcoEye} alt="ico" className="mr-2" />
                    Detail
                  </Link>
                </Visible>
                <Visible
                  or={[
                    {
                      permissionGroupName: PermissionGroup.auctioneers,
                      permissionName: UserPermission.canEdit,
                    },
                    {
                      permissionGroupName: PermissionGroup.myCompany,
                      permissionName: UserPermission.canEdit,
                    },
                  ]}
                >
                  <Link
                    className="d-inline-flex align-items-center ml-4"
                    data-test-id="admin-auctioneer-table-column-actions-update"
                    to={
                      !!pageState.getPage(routes.admin.AUCTIONEER_UPDATE)
                        ? pageState.getPagePath(routes.admin.AUCTIONEER_UPDATE, { ':id': item.id })
                        : pageState.getPagePath(routes.admin.MY_COMPANY_UPDATE, { ':id': item.id })
                    }
                  >
                    <img src={colorIcoConf} alt="ico" className="mr-2" />
                    Upravit
                  </Link>
                </Visible>
                {renderDeleteItem(item)}
              </td>
            </tr>
          ))}
          {props.data.length < 1 && (
            <tr data-test-id="admin-auctioneer-table-empty-row">
              <td className="text-left" colSpan={100}>
                Nebyla nalezena žádná data.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default ItemsTable;
