import React, { ChangeEvent } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { FormikErrors } from 'formik';
import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { BuyerFormValues } from '@types';
import { ControlFeedback, FormGroup } from '@components';

import icoInfo from '@assets/images/ico-info.svg';
import { strIsLatin } from '@helpers/string';

interface Props {
  readOnly?: boolean;
  values: BuyerFormValues;
  errors: FormikErrors<BuyerFormValues>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

const Contact: React.FC<Props> = (props) => {
  const handleLatinValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      props.handleChange(e);
      return;
    }
    if (strIsLatin(e.target.value)) {
      props.handleChange(e);
    }
  };

  return (
    <div className="step-buyer-section">
      <div className={cx('section-title')}>Kontaktní údaje</div>
      <div className="responsive-table-content">
        <FormGroup
          required
          type="text"
          name="phone"
          label="Telefon"
          readOnly={props.readOnly}
          error={props.errors.phone}
          value={props.values.phone}
          onChange={handleLatinValueChange}
        />
        <Form.Group className="f-inline-group">
          <Form.Label className="f-inline-label text-right">
            Bankovní účet *
            <OverlayTrigger
              key="bankAccountNumber-help-text"
              placement="top"
              overlay={
                <Tooltip id="table-permission">Z tohoto účtu budou odesílány a přijímány dražební jistoty.</Tooltip>
              }
            >
              <img src={icoInfo} alt="ico" className="ml-2" />
            </OverlayTrigger>
          </Form.Label>
          <div className="f-inline-control d-flex align-items-center">
            <div className="w-100p w-max-315">
              <FormGroup
                required
                type="text"
                name="bankAccountNumber"
                helpText={<div>Z tohoto účtu budou odesílány a přijímány dražební jistoty.</div>}
                controlClassName="w-max-315"
                controlOnly={true}
                readOnly={props.readOnly}
                isInvalid={!!_.get(props.errors, 'accountNumber')}
                value={props.values.bankAccountNumber || ''}
                onChange={handleLatinValueChange}
              />
              {!!_.get(props.errors, 'accountNumber') && (
                <ControlFeedback type="invalid">{_.get(props.errors, 'accountNumber')}</ControlFeedback>
              )}
            </div>
            <span className="f-size-12 w-weight-400 ml-3 mr-3">/</span>
            <FormGroup
              required
              type="text"
              name="bankAccountCode"
              controlOnly={true}
              readOnly={props.readOnly}
              controlClassName="w-max-150"
              isInvalid={!!_.get(props.errors, 'accountNumber')}
              value={props.values.bankAccountCode || ''}
              onChange={handleLatinValueChange}
            />
          </div>
        </Form.Group>
      </div>
    </div>
  );
};

export default Contact;
