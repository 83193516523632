import React, { ChangeEvent } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import moment from 'moment';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import * as routes from '@routes';
import Address from './Address';
import Contact from './Contact';
import { useAuthApi } from '@api/auth';
import { useBuyerApi } from '@api/buyer';
import CompanyDetails from './CompanyDetails';
import PersonalDetails from './PersonalDetails';
import { BasePreloader, Button } from '@components';
import { useAuthFrontStore, usePageStore, useUserTypes } from '@stores';
import {
  BuyerAddress,
  BuyerFormValues,
  BuyerIndividual,
  BuyerLegal,
  BuyerOwnAccountWorker,
  BuyerSJMPV,
  UserAddressTypes,
} from '@types';
import { strIsLatin } from '@helpers/string';
import Partners from '@components/RegistrationSteps/Partners';

interface Props {
  email: string;
  captcha?: string;
  id?: string | number;
  isUpdate?: boolean;
  isRegistration?: boolean;
  aggreeValues?: { [key: string]: boolean };
  onSuccess: (email: string) => any;
  regenerateCaptcha?: () => void;
}

interface AddressResult {
  index: number;
  address: BuyerAddress;
}

const StepBuyer: React.FC<Props> = (props) => {
  const authFrontState = useAuthFrontStore();
  const pageState = usePageStore();
  const history = useHistory();
  const authApi = useAuthApi();
  const buyerApi = useBuyerApi();
  const { userTypes } = useUserTypes();
  const [loaded, setLoaded] = React.useState(props.isRegistration);

  const formik = useFormik<BuyerFormValues>({
    enableReinitialize: false,
    initialValues: {
      type: userTypes && userTypes[0] ? userTypes[0].value : undefined,
      email: props.email,
      degreePre: '',
      degreePost: '',
      firstName: '',
      lastName: '',
      birthDate: undefined,
      companyName: '',
      position: '',
      ico: '',
      dic: '',
      issuer: '',
      eBox: '',
      citizenship: '',
      identityCardNumber: '',
      personalIdentificationNumber: '',
      phone: '+420',
      bankAccountNumber: '',
      bankAccountCode: '',
      address: '',
      zip: '',
      city: '',
      country: '',
      hasDifferentDeliveryAddress: false,
      deliveryAddress: '',
      deliveryZip: '',
      deliveryCity: '',
      deliveryCountry: '',
      hasDifferentInvoiceAddress: false,
      invoiceAddress: '',
      invoiceZip: '',
      invoiceCity: '',
      invoiceCountry: '',
      note: '',
      share: '',
      doesAggreeTnc: false,
      doesAggreePrivacyPolicy: false,
      doesAggreeMarketing: false,
      captcha: props.captcha,
    },
    onSubmit: () => handleSubmit(),
  });

  React.useEffect(() => {
    formik.setFieldValue('doesAggreeTnc', props.aggreeValues?.tnc || false);
    formik.setFieldValue('doesAggreePrivacyPolicy', props.aggreeValues?.privacyPolicy || false);
    formik.setFieldValue('doesAggreeMarketing', props.aggreeValues?.marketing || false);
    if (!!props.id && !props.isRegistration) {
      loadData();
    }
    return () => {
      authApi.cancelAllRequests();
      buyerApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    if (!props.id) {
      return;
    }
    try {
      const response = await buyerApi.detail(props.id || '');
      const data = response.data.data;
      if (data.buyerType === 'individual') {
        formik.setValues(getDataIndividual(data as BuyerIndividual));
      }
      if (data.buyerType === 'own_account_worker') {
        formik.setValues(getDataOwnAccountWorker(data as BuyerOwnAccountWorker));
      }
      if (data.buyerType === 'legal') {
        formik.setValues(getDataLegal(data as BuyerLegal));
      }
      if (data.buyerType === 'joint_ownership' || data.buyerType === 'joint_assets') {
        formik.setValues(getDataSJMPV(data as BuyerSJMPV));
      }
      if (data.userAdditionalsPerson) {
        formik.setFieldValue(
          'userAdditionalsPerson',
          data.userAdditionalsPerson.map((i) => ({
            firstName: i.firstName,
            lastName: i.lastName,
            birthdate: i.birthdate,
            personalIdentificationNumber: i.personalIdentificationNumber,
            identityCardNumber: i.identityCardNumber,
            address: i.address,
            city: i.city,
            zipCode: i.zipCode,
            country: i.country,
            share: i.share,
          }))
        );
      }
      setLoaded(true);
    } catch (err: any) {
      if (!err.response) {
        return;
      }
    }
  };

  const handleSubmit = async () => {
    try {
      if (!!props.id || !props.isRegistration) {
        const res = await buyerApi.update(formik.values);
        const userResponse = await authApi.logged(true);
        authFrontState.setUser(userResponse.data.data);
        const pages = await pageState.reload();
        if (res.status === 201) {
          history.push(pages.find((i) => i.routeName === routes.front.ACCOUNT)?.url || '/');
        } else {
          history.push(pages.find((i) => i.routeName === routes.front.ACCOUNT_PERSONAL_DATA)?.url || '/');
        }
      } else {
        await buyerApi.create({
          ...formik.values,
          newsletter: formik.values.doesAggreeMarketing || false,
          portalConsent: formik.values.doesAggreeTnc || false,
          gdprConsent: formik.values.doesAggreePrivacyPolicy || false,
          captcha: props.captcha,
        });
      }
      await loadData();
      formik.setSubmitting(false);
      props.onSuccess(props.email);
    } catch (err: any) {
      if (undefined !== props.regenerateCaptcha) {
        props.regenerateCaptcha();
      }

      if (!err.response) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
  };

  const getAddress = (address: BuyerAddress[], addressType: UserAddressTypes): AddressResult | undefined => {
    for (let index = 0; index < address.length; index++) {
      if (address[index].type === addressType) {
        return {
          index,
          address: address[index],
        };
      }
    }
    return undefined;
  };

  const getDataIndividual = (data: BuyerIndividual): BuyerFormValues => {
    const deliveryAddress = getAddress(data.userAddresses, UserAddressTypes.delivery);
    const residenceAddress = getAddress(data.userAddresses, UserAddressTypes.residence);
    const invoiceAddress = getAddress(data.userAddresses, UserAddressTypes.invoice);
    const bankAccount: string[] = !!data.accountNumber ? data.accountNumber.split('/') : [];
    return {
      email: data.email,
      type: data.buyerType,
      companyName: '',
      ico: '',
      dic: '',
      issuer: '',
      position: '',
      citizenship: data.citizenship || '',
      degreePre: data.degreePre || '',
      degreePost: data.degreePost || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      birthDate: !!data.birthdate ? moment(data.birthdate).toDate() : undefined,
      identityCardNumber: data.identityCardNumber || '',
      personalIdentificationNumber: data.personalIdentificationNumber || '',
      eBox: data.dataBox || '',
      phone: data.phone || '',
      bankAccountNumber: bankAccount.length > 0 ? bankAccount[0] : '',
      bankAccountCode: bankAccount.length > 1 ? bankAccount[1] : '',
      addressId: residenceAddress?.address?.id,
      addressIndex: residenceAddress?.index,
      deliveryAddressId: deliveryAddress?.address?.id,
      deliveryAddressIndex: deliveryAddress?.index,
      invoiceAddressId: invoiceAddress?.address?.id,
      invoiceAddressIndex: invoiceAddress?.index,
      address: residenceAddress?.address?.address || '',
      zip: residenceAddress?.address?.zipCode || '',
      city: residenceAddress?.address?.city || '',
      country: residenceAddress?.address?.country,
      hasDifferentDeliveryAddress: !!deliveryAddress,
      deliveryAddress: deliveryAddress?.address?.address || '',
      deliveryZip: deliveryAddress?.address?.zipCode || '',
      deliveryCity: deliveryAddress?.address?.city || '',
      deliveryCountry: deliveryAddress?.address?.country || '',
      hasDifferentInvoiceAddress: !!invoiceAddress,
      invoiceAddress: invoiceAddress?.address?.address || '',
      invoiceZip: invoiceAddress?.address?.zipCode || '',
      invoiceCity: invoiceAddress?.address?.city || '',
      invoiceCountry: invoiceAddress?.address.country || '',
      note: '',
      share: '',
      doesAggreeTnc: true,
      doesAggreePrivacyPolicy: true,
      doesAggreeMarketing: true,
      captcha: props.captcha,
    };
  };

  const getDataOwnAccountWorker = (data: BuyerOwnAccountWorker): BuyerFormValues => {
    const deliveryAddress = getAddress(data.userAddresses, UserAddressTypes.delivery);
    const residenceAddress = getAddress(data.userAddresses, UserAddressTypes.residence);
    const invoiceAddress = getAddress(data.userAddresses, UserAddressTypes.invoice);
    const bankAccount: string[] = !!data.accountNumber ? data.accountNumber.split('/') : [];
    return {
      email: data.email,
      type: data.buyerType,
      companyName: '',
      ico: data.ico || '',
      dic: data.dic || '',
      issuer: '',
      position: '',
      citizenship: data.citizenship || '',
      degreePre: data.degreePre || '',
      degreePost: data.degreePost || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      birthDate: !!data.birthdate ? moment(data.birthdate).toDate() : undefined,
      identityCardNumber: data.identityCardNumber || '',
      personalIdentificationNumber: data.personalIdentificationNumber || '',
      eBox: data.dataBox || '',
      phone: data.phone || '',
      bankAccountNumber: bankAccount.length > 0 ? bankAccount[0] : '',
      bankAccountCode: bankAccount.length > 1 ? bankAccount[1] : '',
      addressId: residenceAddress?.address?.id,
      addressIndex: residenceAddress?.index,
      deliveryAddressId: deliveryAddress?.address?.id,
      deliveryAddressIndex: deliveryAddress?.index,
      invoiceAddressId: invoiceAddress?.address?.id,
      invoiceAddressIndex: invoiceAddress?.index,
      address: residenceAddress?.address?.address || '',
      zip: residenceAddress?.address?.zipCode || '',
      city: residenceAddress?.address?.city || '',
      country: residenceAddress?.address.country || '',
      hasDifferentDeliveryAddress: !!deliveryAddress,
      deliveryAddress: deliveryAddress?.address?.address || '',
      deliveryZip: deliveryAddress?.address?.zipCode || '',
      deliveryCity: deliveryAddress?.address?.city || '',
      deliveryCountry: deliveryAddress?.address?.country || '',
      hasDifferentInvoiceAddress: !!invoiceAddress,
      invoiceAddress: invoiceAddress?.address?.address || '',
      invoiceZip: invoiceAddress?.address?.zipCode || '',
      invoiceCity: invoiceAddress?.address?.city || '',
      invoiceCountry: invoiceAddress?.address?.country || '',
      note: '',
      share: '',
      doesAggreeTnc: true,
      doesAggreePrivacyPolicy: true,
      doesAggreeMarketing: true,
      captcha: props.captcha,
    };
  };

  const getDataLegal = (data: BuyerLegal): BuyerFormValues => {
    const deliveryAddress = getAddress(data.userAddresses, UserAddressTypes.delivery);
    const residenceAddress = getAddress(data.userAddresses, UserAddressTypes.residence);
    const invoiceAddress = getAddress(data.userAddresses, UserAddressTypes.invoice);
    const bankAccount: string[] = !!data.accountNumber ? data.accountNumber.split('/') : [];
    return {
      email: data.email,
      type: data.buyerType,
      companyName: data.company || '',
      ico: data.ico || '',
      dic: data.dic || '',
      citizenship: data.citizenship || '',
      issuer: data.register || '',
      position: data.position || '',
      degreePre: data.degreePre || '',
      degreePost: data.degreePost || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      birthDate: !!data.birthdate ? moment(data.birthdate).toDate() : undefined,
      identityCardNumber: data.identityCardNumber || '',
      personalIdentificationNumber: data.personalIdentificationNumber || '',
      eBox: data.dataBox || '',
      phone: data.phone || '',
      bankAccountNumber: bankAccount.length > 0 ? bankAccount[0] : '',
      bankAccountCode: bankAccount.length > 1 ? bankAccount[1] : '',
      addressId: residenceAddress?.address?.id,
      addressIndex: residenceAddress?.index,
      deliveryAddressId: deliveryAddress?.address?.id,
      deliveryAddressIndex: deliveryAddress?.index,
      invoiceAddressId: invoiceAddress?.address?.id,
      invoiceAddressIndex: invoiceAddress?.index,
      address: residenceAddress?.address?.address || '',
      zip: residenceAddress?.address?.zipCode || '',
      city: residenceAddress?.address?.city || '',
      country: residenceAddress?.address?.country || '',
      hasDifferentDeliveryAddress: !!deliveryAddress,
      deliveryAddress: deliveryAddress?.address?.address || '',
      deliveryZip: deliveryAddress?.address?.zipCode || '',
      deliveryCity: deliveryAddress?.address?.city || '',
      deliveryCountry: deliveryAddress?.address?.country || '',
      hasDifferentInvoiceAddress: !!invoiceAddress,
      invoiceAddress: invoiceAddress?.address?.address || '',
      invoiceZip: invoiceAddress?.address?.zipCode || '',
      invoiceCity: invoiceAddress?.address?.city || '',
      invoiceCountry: invoiceAddress?.address?.country || '',
      note: '',
      share: '',
      doesAggreeTnc: true,
      doesAggreePrivacyPolicy: true,
      doesAggreeMarketing: true,
      captcha: props.captcha,
    };
  };

  const getDataSJMPV = (data: BuyerSJMPV): BuyerFormValues => {
    const deliveryAddress = getAddress(data.userAddresses, UserAddressTypes.delivery);
    const residenceAddress = getAddress(data.userAddresses, UserAddressTypes.residence);
    const invoiceAddress = getAddress(data.userAddresses, UserAddressTypes.invoice);
    const bankAccount: string[] = !!data.accountNumber ? data.accountNumber.split('/') : [];
    return {
      email: data.email,
      type: data.buyerType,
      companyName: '',
      ico: '',
      dic: '',
      issuer: '',
      position: '',
      citizenship: data.citizenship || '',
      degreePre: data.degreePre || '',
      degreePost: data.degreePost || '',
      firstName: data.firstName || '',
      lastName: data.lastName || '',
      birthDate: !!data.birthdate ? moment(data.birthdate).toDate() : undefined,
      identityCardNumber: data.identityCardNumber || '',
      personalIdentificationNumber: data.personalIdentificationNumber || '',
      eBox: data.dataBox || '',
      phone: data.phone || '',
      bankAccountNumber: bankAccount.length > 0 ? bankAccount[0] : '',
      bankAccountCode: bankAccount.length > 1 ? bankAccount[1] : '',
      addressId: residenceAddress?.address?.id,
      addressIndex: residenceAddress?.index,
      deliveryAddressId: deliveryAddress?.address?.id,
      deliveryAddressIndex: deliveryAddress?.index,
      invoiceAddressId: invoiceAddress?.address?.id,
      invoiceAddressIndex: invoiceAddress?.index,
      address: residenceAddress?.address?.address || '',
      zip: residenceAddress?.address?.zipCode || '',
      city: residenceAddress?.address?.city || '',
      country: residenceAddress?.address?.country || '',
      hasDifferentDeliveryAddress: !!deliveryAddress,
      deliveryAddress: deliveryAddress?.address?.address || '',
      deliveryZip: deliveryAddress?.address?.zipCode || '',
      deliveryCity: deliveryAddress?.address?.city || '',
      deliveryCountry: deliveryAddress?.address?.country || '',
      hasDifferentInvoiceAddress: !!invoiceAddress,
      invoiceAddress: invoiceAddress?.address?.address || '',
      invoiceZip: invoiceAddress?.address?.zipCode || '',
      invoiceCity: invoiceAddress?.address?.city || '',
      invoiceCountry: invoiceAddress?.address?.country || '',
      note: '',
      share: data.share || '',
      doesAggreeTnc: true,
      doesAggreePrivacyPolicy: true,
      doesAggreeMarketing: true,
      captcha: props.captcha,
    };
  };

  const handleLatinValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      formik.handleChange(e);
      return;
    }
    if (strIsLatin(e.target.value)) {
      formik.handleChange(e);
    }
  };

  const renderTypeOption = (label: string, value: string, index: number) => {
    const isActive = value === formik.values.type;

    if (!!props.id) {
      return (
        <div className="user-type-option" data-test-id={`front-user-account-type-${value}`}>
          <Form.Check
            custom
            required
            name="type"
            type="radio"
            value={value}
            label={label}
            id={`input${value}`}
            checked={formik.values.type === value}
            onChange={!props.isUpdate ? formik.handleChange : undefined}
          />
        </div>
      );
    }

    return (
      <div
        key={`${index}-${value}`}
        className={cx('user-type-option', { isActive })}
        data-test-id={`user-registration-advanced-type-${value}`}
        onClick={() => {
          formik.setFieldValue('type', value);
        }}
      >
        {label}
      </div>
    );
  };

  const renderSection = (title: string, content: React.ReactNode, noBorder: boolean = false) => {
    return (
      <div key={title} className="step-buyer-section">
        <div className={cx('section-title', { noBorder })}>{title}</div>
        <div className="responsive-table-content">{content}</div>
      </div>
    );
  };

  if (!loaded) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="step-buyer">
      {renderSection(
        props.id === undefined ? 'Registrace dražitele' : 'Druh účtu',
        <div className="user-type-options">
          {userTypes.map((item, index) => renderTypeOption(item.label, item.value, index))}
        </div>,
        true
      )}
      {formik.values.type === 'legal' && (
        <CompanyDetails
          values={formik.values}
          errors={formik.errors}
          setFieldValue={formik.setFieldValue}
          handleChange={formik.handleChange}
        />
      )}
      <PersonalDetails
        values={formik.values}
        errors={formik.errors}
        setFieldValue={formik.setFieldValue}
        handleChange={formik.handleChange}
      />
      <Address
        withoutSuggest={formik.values.citizenship === 'other'}
        title={formik.values.type === 'legal' ? 'Adresa sídla' : 'Trvalé bydliště'}
        values={formik.values}
        errors={formik.errors}
        setFieldValue={formik.setFieldValue}
        handleChange={formik.handleChange}
      />

      {!!~['joint_ownership', 'joint_assets'].indexOf(formik.values.type || '') && (
        <Partners
          errors={formik.errors}
          setFieldValue={formik.setFieldValue}
          withoutSuggest={formik.values.citizenship === 'other'}
          title={formik.values.type === 'joint_assets' ? 'Spoluvlastníci' : 'Osobní údaje druhého z manželů'}
          handleChange={formik.handleChange}
          values={
            formik.values.type === 'joint_ownership'
              ? {
                  ...formik.values,
                  userAdditionalsPerson: [
                    {
                      firstName: _.get(formik.values, 'userAdditionalsPerson.0.firstName', ''),
                      lastName: _.get(formik.values, 'userAdditionalsPerson.0.lastName', ''),
                      birthdate: _.get(formik.values, 'userAdditionalsPerson.0.birthdate', ''),
                      personalIdentificationNumber: _.get(
                        formik.values,
                        'userAdditionalsPerson.0.personalIdentificationNumber',
                        ''
                      ),
                      identityCardNumber: _.get(formik.values, 'userAdditionalsPerson.0.identityCardNumber', ''),
                      address: _.get(formik.values, 'userAdditionalsPerson.0.address', ''),
                      city: _.get(formik.values, 'userAdditionalsPerson.0.city', ''),
                      zipCode: _.get(formik.values, 'userAdditionalsPerson.0.zipCode', ''),
                      country: _.get(formik.values, 'userAdditionalsPerson.0.country', 'Česká republika'),
                    },
                  ],
                }
              : formik.values
          }
        />
      )}

      <Contact
        values={formik.values}
        errors={formik.errors}
        setFieldValue={formik.setFieldValue}
        handleChange={formik.handleChange}
      />
      {renderSection(
        'Ostatní',
        <>
          <Form.Group className="f-inline-group align-items-start">
            <Form.Label className="f-inline-label text-right">Poznámka</Form.Label>
            <div className="f-inline-control">
              <Form.Control
                isInvalid={!!formik.errors.note}
                as="textarea"
                rows={10}
                name="note"
                className="w-max-500 resize-none"
                value={formik.values.note || ''}
                onChange={handleLatinValueChange}
              />
            </div>
          </Form.Group>
        </>
      )}
      {renderSection(
        '',
        <>
          {props.id === undefined && (
            <>
              <Form.Group className="d-flex align-items-center ml-3">
                <Form.Check
                  custom
                  required
                  type="checkbox"
                  label={
                    <p className="terms">
                      Souhlasím s{' '}
                      <Link to={pageState.getPagePath(routes.front.TERMS)} target="_blank">
                        podmínkami portálu
                      </Link>{' '}
                      *
                    </p>
                  }
                  className="mr-4"
                  id="doesAggreeTnc"
                  name="doesAggreeTnc"
                  onChange={formik.handleChange}
                  checked={formik.values.doesAggreeTnc}
                />
              </Form.Group>
              <Form.Group className="d-flex align-items-center ml-3">
                <Form.Check
                  custom
                  required
                  type="checkbox"
                  label={
                    <p className="terms">
                      Souhlasím s{' '}
                      <Link to={pageState.getPagePath(routes.front.GDPR)} target="_blank">
                        podmínkami ochrany osobních údajů
                      </Link>{' '}
                      *
                    </p>
                  }
                  className="mr-4"
                  id="doesAggreePrivacyPolicy"
                  name="doesAggreePrivacyPolicy"
                  onChange={formik.handleChange}
                  checked={formik.values.doesAggreePrivacyPolicy}
                />
              </Form.Group>
              <Form.Group className="d-flex align-items-center ml-3">
                <Form.Check
                  custom
                  type="checkbox"
                  label={
                    <p className="terms">
                      Souhlasím se zasíláním obchodních sdělení ve formě aktuální nabídky nových dražeb a aukcí
                    </p>
                  }
                  className="mr-4"
                  id="doesAggreeMarketing"
                  name="doesAggreeMarketing"
                  onChange={formik.handleChange}
                  checked={formik.values.doesAggreeMarketing}
                />
              </Form.Group>
            </>
          )}
          <div className="mt-5">
            {!formik.isSubmitting ? (
              <Button
                type="submit"
                variant="btn-outline-primary"
                className="float-right"
                disabled={formik.isSubmitting}
              >
                {props.isRegistration ? `Dokončit registraci` : `Uložit změny`}
              </Button>
            ) : (
              <BasePreloader size={29} className="d-inline-block float-right" />
            )}
            <div className="clearfix" />
          </div>
        </>
      )}
    </Form>
  );
};

export default StepBuyer;
