import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import { NumberParam, ObjectParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { AuctionEmailsFilter } from '@types';
import { AdminFiltersBox } from '@components';
import FiltersInputs from '@components/AdminFiltersBox/components/FiltersInputs';
import FiltersSubmit from '@components/AdminFiltersBox/components/FiltersSubmit';

import { AuctionSectionStep } from '../ActiveAuctionPage';

type Props = {
  auctionId: string | number;
};

export const EmailsFilterForm: React.FC<Props> = (props) => {
  const location = useLocation();
  const pageState = usePageStore();
  const [isOpen, setIsOpen] = React.useState(false);
  const formik = useFormik<AuctionEmailsFilter>({
    enableReinitialize: true,
    initialValues: {},
    onSubmit: (values) => handleSubmit(values),
  });
  const [query, setQuery] = useQueryParams({
    s: withDefault(StringParam, AuctionSectionStep.emails),
    page: withDefault(NumberParam, 1),
    perPage: withDefault(NumberParam, 15),
    content: withDefault(StringParam, undefined),
    subject: withDefault(StringParam, undefined),
    email: withDefault(StringParam, undefined),
    sort: withDefault(ObjectParam, {
      property: 'sentEmail.timeCreated',
      direction: 'DESC',
    }),
  });

  useEffect(() => {
    formik.setValues(query);
    setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const handleSubmit = (values: FormikValues) => {
    const formValues = { ...values };
    Object.getOwnPropertyNames(formValues).map((prop) => {
      if (formValues[prop] === '') {
        formValues[prop] = undefined;
      }
      return prop;
    });
    formValues.page = 1;
    setQuery(formValues, 'push');
  };

  const hasFilters = (): boolean => {
    if (location.search === '?s=emails') {
      return false;
    }
    return !!location.search;
  };

  const getClearUrl = () => {
    return `${pageState.getPagePath(routes.admin.AUCTIONS_ACTIVE_DETAIL, { ':id': props.auctionId })}?s=${
      AuctionSectionStep.emails
    }`;
  };

  return (
    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-3">
      <AdminFiltersBox isOpen={isOpen}>
        <FiltersInputs>
          <Row>
            <Col xs={12} md={7} lg={4}>
              <Form.Group>
                <Form.Label>Obsah zprávy</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="content"
                  value={formik.values.content || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={7} lg={4}>
              <Form.Group>
                <Form.Label>Předmět</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="subject"
                  value={formik.values.subject || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={7} lg={4}>
              <Form.Group>
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="email"
                  value={formik.values.email || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </FiltersInputs>
        <FiltersSubmit hasFilters={hasFilters()} onOpenClick={() => setIsOpen(true)} clearUrl={getClearUrl()} />
      </AdminFiltersBox>
    </Form>
  );
};
