import React, { FC, useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import { Table } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';

import { useWindowFocus } from '@hooks';
import { useAuctionsApi } from '@api/auctions';
import { useAuctionBidsWebsocket, useLicitatorWebsocket } from '@websocket';
import { getCurrencyFormat } from '@helpers/formats';
import { getDateTimeFormat } from '@helpers/datetime';
import { isAuthorizedOptions } from '@helpers/definitions';
import { closeMessage, confirmMessage } from '@helpers/messages';
import { useAuctionInformationApi } from '@api/auctionInformation';
import AuctionCountdown from '@components/AuctionCountdown/AuctionCountdown';
import { AuctionResponse, WebAuctionBid, WebInformationResponse } from '@types';
import { BasePreloader, Button, FormGroup, FrontAccountBox, FrontAuctionInformation } from '@components';

import AuctionResult from './AuctionResult';
import { AuctionProtest } from './AuctionProtest';

import alertIco from '@assets/images/front/account/alert.svg';
import warningHammerIco from '@assets/images/warning-hammer-ico.svg';
import icoAlertBell from '@assets/images/front/auction-detail/ico-alert-bell.svg';
import icoAlertBellRed from '@assets/images/front/auction-detail/ico-alert-bell-red.svg';

interface Props {
  isDetail?: boolean;
  auction: AuctionResponse;
  authorizationState: string;
  admitted: boolean;
  preEmption: boolean;
  canDraw?: boolean;
  uniqueCode: string;
  hasActiveProtest: boolean;
  auctionReload: () => void;
  onJoinClick?: (e: React.MouseEvent) => void;
}

const OngoingAuctionTab: FC<Props> = (props) => {
  const { addToast } = useToasts();
  const bidsTimeout = useRef<NodeJS.Timeout | undefined>();
  const informationTimeout = useRef<NodeJS.Timeout | undefined>();
  const licitatorRes = useLicitatorWebsocket({ id: props.auction.id });
  const auctionInformationApi = useAuctionInformationApi();
  const auctionsApi = useAuctionsApi(false, true);
  const [information, setInformation] = useState<WebInformationResponse[]>([]);
  const [bids, setBids] = useState<WebAuctionBid[]>([]);
  const [bidsLoaded, setBidsLoaded] = useState(false);
  const [drawSaved, setDrawSaved] = useState(true);
  const formik = useFormik({
    initialValues: { value: props.auction.minimalThrow },
    onSubmit: () => handleSubmit(),
  });
  const drawValue = bids.find(
    (v) => !v.extended && v.drawNumber && v.userAuction.uniqueCode.toString() === props.uniqueCode
  );

  useAuctionBidsWebsocket({
    id: props.auction.id,
    onMessage: (res) => setBids(res.data),
  });

  useWindowFocus({
    onFocus: () => {
      loadBids();
    },
  });

  useEffect(() => {
    loadBids();
    return () => {
      auctionsApi.cancelAllRequests();
      cancelBidsTimeout();
      cancelInformationTimeout();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadInformation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auction]);

  const loadBids = async () => {
    cancelBidsTimeout();
    try {
      const response = await auctionsApi.getWebBids(props.auction.id);
      setBids(response.data.data);
      setBidsLoaded(true);
      bidsTimeout.current = setTimeout(() => loadBids(), 5000);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const cancelBidsTimeout = () => {
    if (bidsTimeout.current) {
      clearTimeout(bidsTimeout.current);
    }
  };

  const cancelInformationTimeout = () => {
    if (informationTimeout.current) {
      clearTimeout(informationTimeout.current);
    }
  };

  const loadInformation = async () => {
    cancelInformationTimeout();
    try {
      const response = await auctionInformationApi.getList(props.auction.id);
      setInformation(response.data.data);
      informationTimeout.current = setTimeout(() => loadInformation(), 5000);
    } catch (err) {
      if (auctionInformationApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const getCurrentBid = () => {
    if (bids.length < 1) {
      return getCurrencyFormat(props.auction.minimalBid);
    }
    return getCurrencyFormat(parseFloat(formik.values.value || '0') + (bids.length > 0 ? bids[0].bidPrice : 0));
  };

  const handleSubmit = async (acceptBigBid?: boolean) => {
    if (bids.length < 1) {
      firstBid();
      return;
    }
    if (!formik.values.value) {
      return;
    }
    try {
      const response = await auctionsApi.createWebBid(props.auction.id, {
        acceptBigBid,
        bid: parseFloat(formik.values.value || '0'),
        bidPrice: parseFloat(formik.values.value || '0') + (bids.length > 0 ? bids[0].bidPrice : 0),
      });
      if (response.status === 200 && response.data.message) {
        confirmMessage({
          isHtml: true,
          title: 'Potvrzení',
          text: `<span style='display: block; font-size: 14px; line-height: 1.8; padding-right: 20px;'>${response.data.message}</span>`,
          showLoaderOnConfirm: true,
          preConfirm: () => {
            return handleSubmit(true);
          },
        })
          .then(() => closeMessage())
          .catch(() => closeMessage());
        return;
      }
      formik.setFieldValue('value', props.auction.minimalThrow);
      if (!!response.data.message) {
        addToast(response.data.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 4000,
        } as any);
      }
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  const handlePreEmptionClick = async () => {
    if (bids.length < 1) {
      return;
    }
    try {
      const response = await auctionsApi.preEmptionBid(props.auction.id, bids[0].bidPrice);
      formik.setFieldValue('value', props.auction.minimalThrow || '');
      if (!!response.data.message) {
        addToast(response.data.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 4000,
        } as any);
      }
      loadBids();
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleDrawClick = async () => {
    setDrawSaved(false);
    try {
      await auctionsApi.auctionDraw(props.auction.id);
      await props.auctionReload();
      await loadBids();
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    } finally {
      setDrawSaved(true);
    }
  };

  const firstBid = async () => {
    try {
      const response = await auctionsApi.acceptWebMinBid(props.auction.id);
      if (!!response.data.message) {
        addToast(response.data.message, {
          appearance: 'success',
          autoDismiss: true,
          autoDismissTimeout: 4000,
        } as any);
      }
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  const auctionPermission = () => {
    return props.auction?.cautionDeposit
      ? isAuthorizedOptions.some((option) => option === props.authorizationState) && props.admitted
      : props.admitted;
  };

  const renderOngoingForm = () => {
    if (props.auction.status === 'ongoing' && props.auction?.canProtest && props.auction?.protestEndDatetime) {
      return !props.hasActiveProtest ? (
        <AuctionProtest auction={props.auction} auctionReload={() => props.auctionReload()} />
      ) : (
        <div className="pt-2 font-weight-bold f-size-14">
          {props.auction.translations?.auction_protest_created || 'auction_protest_created'}
        </div>
      );
    }

    if (props.auction.status === 'ongoing_protest' || props.auction.status === 'ongoing_outage') {
      return null;
    }

    return (
      <>
        <h3 className="tab-title">{props.auction.translations?.auction_process || 'auction_process'}</h3>
        <div className="info-items">
          <div className="item">
            Vyvolávací cena: <span>{getCurrencyFormat(props.auction.minimalBid)}</span>
          </div>
          <div className="item">
            Aktuální cena: <span>{bids.length > 0 ? <>{getCurrencyFormat(bids[0].bidPrice)}</> : `0 Kč`}</span>
          </div>
          <div className="item">
            Minimální příhoz: <span>{getCurrencyFormat(props.auction.minimalThrow)}</span>
          </div>
        </div>
        {props.auction.draw ? (
          <div className="process-form draw-wrapper">
            <div className="item-label label-value">
              Byla uplatněna shodná podání prioritního práva. Bude provedeno losování.
            </div>
            <div className="item-buttons">
              {drawValue ? (
                <p className="mb-0">
                  Výše vašeho losu je číslo <strong>{drawValue.drawNumber}</strong>
                </p>
              ) : (
                <>
                  {drawSaved ? (
                    <>
                      {props.canDraw && (
                        <Button onClick={handleDrawClick} disabled={!props.canDraw}>
                          Losovat
                        </Button>
                      )}
                    </>
                  ) : (
                    <div className="d-flex align-items-center">
                      <p className="mb-0 mr-3">Probíhá losování...</p>
                      <BasePreloader size={34} />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        ) : (
          <div className="process-form">
            <form onSubmit={formik.handleSubmit}>
              {bids.length > 0 && (
                <>
                  <div className="item-label">Váš příhoz:</div>
                  <FormGroup
                    controlOnly
                    thousandSeparator
                    name="value"
                    controlClassName="item-input"
                    value={formik.values.value}
                    onValueChange={(val) => formik.setFieldValue('value', val.value)}
                  />
                  <div className="item-currency">Kč</div>
                </>
              )}
              <div className="item-label label-value">Vaše podání:</div>
              <div className="item-value">{getCurrentBid()}</div>
              <div className="item-buttons">
                {props.preEmption && bids.length > 0 && (
                  <Button
                    className="preemption-btn"
                    type="button"
                    disabled={bids[0].userAuction.uniqueCode.toString() === props.uniqueCode}
                    onClick={handlePreEmptionClick}
                  >
                    Dorovnat poslední příhoz
                  </Button>
                )}
                <Button type="submit">{bids.length > 0 ? `Přihodit` : `Akceptovat vyvolávací cenu`}</Button>
              </div>
            </form>
          </div>
        )}
      </>
    );
  };

  const getOngoingText = () => {
    if (props.auction.status !== 'ongoing' && props.auction.canProtest && props.auction.protestEndDatetime) {
      return props.auction.translations?.auction_protest_processing || 'auction_protest_processing';
    }

    if (props.auction.canProtest && props.auction.protestEndDatetime) {
      return `${props.auction.translations?.auction_can_protest_end || 'auction_can_protest_end'}: `;
    } else {
      return `${props.auction.translations?.auction_until_end || 'auction_until_end'}: `;
    }
  };

  const renderOngoingBox = () => {
    if (props.isDetail && props.auction.status === 'ongoing_outage' && props.auction.outage) {
      return (
        <div className="block-alert alert-tab mb-2 mt-2">
          <p>{props.auction.translations?.auction_outage || 'auction_outage'}</p>
        </div>
      );
    }

    return (
      <>
        {props.isDetail &&
          props.auction &&
          props.auction.parentStatus === 'ongoing' &&
          (props.auction.draw && props.auction.drawToDt ? (
            <div className="block-alert alert-tab mb-4">
              <p>
                Probíhá losování:{' '}
                <span className="notranslate">
                  <AuctionCountdown
                    completeText="Čekám výsledek"
                    date={moment(props.auction.drawToDt).toDate()}
                    onlySeconds
                  />
                </span>
                <br />
                Vyhrává nejvyšší vylosované číslo.
              </p>
            </div>
          ) : (
            <div className="block-alert alert-tab mb-4">
              <p>
                <img src={icoAlertBell} alt="icon" />
                {getOngoingText()}
                <span className="notranslate">
                  {props.auction.canProtest && props.auction.protestEndDatetime ? (
                    <>
                      {props.auction.status === 'ongoing' && (
                        <AuctionCountdown
                          ongoing
                          completeText="Probíhá ukončování"
                          date={moment(props.auction.protestEndDatetime).toDate()}
                        />
                      )}
                    </>
                  ) : (
                    <AuctionCountdown
                      ongoing
                      completeText="Probíhá ukončování"
                      date={moment(props.auction.realEndDt || props.auction.endDt).toDate()}
                    />
                  )}
                </span>
              </p>
              <span className="alert-link text-d-none text-color-gray">
                {props.auction.translations?.auction_ongoing || 'auction_ongoing'}
              </span>
            </div>
          ))}
      </>
    );
  };

  const renderOngoing = () => {
    // If I don't have access to the auction, hide in the auction details in my account.
    if (!props.isDetail && props.auction.parentStatus === 'ongoing' && !auctionPermission()) {
      return null;
    }

    if (props.auction.parentStatus !== 'ended' && props.auction.parentStatus !== 'overcharge') {
      return (
        <FrontAccountBox className="mb-3">
          <div className="component-auction-process-tab">
            {props.isDetail && props.auction && props.auction.parentStatus === 'prepared' && (
              <div className="block-alert alert-tab mb-4">
                <p className="notranslate">
                  <img src={icoAlertBell} alt="icon" />
                  {props.auction.translations?.auction_until_start || 'auction_until_start'}:{' '}
                  <span className="notranslate">
                    <AuctionCountdown
                      completeText="Probíhá zahajování"
                      date={moment(props.auction?.startDt).toDate()}
                    />
                  </span>
                </p>
                {props.auction?.isJoined ? (
                  <span className="alert-link text-d-none">Již jste připojen</span>
                ) : (
                  <>
                    {!props.auction.outage && (
                      <a href="/" className="alert-link" onClick={props.onJoinClick}>
                        {props.auction.translations?.auction_join || 'auction_join'}
                      </a>
                    )}
                  </>
                )}
              </div>
            )}

            {renderOngoingBox()}

            {props.isDetail &&
              props.auction &&
              props.auction.parentStatus === 'ended' &&
              props.auction.status !== 'ended_adjourned' &&
              props.auction.status !== 'adjourned' && (
                <div className="block-alert info-wrapper alert-tab mb-4">
                  <div className=" info-wrapper">
                    <p className="start-date ">
                      <img src={icoAlertBell} alt="icon" />
                      {props.auction.translations?.auction_started || 'auction_started'}{' '}
                      {getDateTimeFormat(props.auction.startDt)}
                    </p>
                    <p className="end-date ">
                      <img src={icoAlertBellRed} alt="icon" />
                      {props.auction.translations?.auction_ended || 'auction_ended'}{' '}
                      {getDateTimeFormat(props.auction.realEndDt || props.auction.endDt)}
                    </p>
                  </div>
                </div>
              )}

            {props.auction.parentStatus === 'ongoing' && !props.auction.draw && !!licitatorRes?.text && (
              <div className="bids-alert-box">
                <p>
                  <img src={warningHammerIco} alt="icon" />
                  {!!licitatorRes.title && <span>{licitatorRes.title} </span>}
                  {licitatorRes.text}
                </p>
              </div>
            )}

            {props.auction.parentStatus === 'ongoing' && auctionPermission() && (
              <div>
                {props.auction.draw && (
                  <div className="bids-alert-box">
                    <p>
                      <img src={warningHammerIco} alt="icon" />
                      Byla uplatněna shodná podání prioritního práva. Nejvyššímu losu bude udělen příklep.
                    </p>
                  </div>
                )}

                {renderOngoingForm()}
              </div>
            )}
          </div>
        </FrontAccountBox>
      );
    }

    return null;
  };

  const renderBids = () => {
    if (bids.length < 1) {
      return <p className="mt-3 mb-0">Nebylo učiněno žádné podání.</p>;
    }

    const isDraw = bids.some((bid) => bid.draw);

    return (
      <div className="item-table">
        <div className="responsive-table-content">
          <Table className="table-middle" striped>
            <thead>
              <tr>
                <th>Dražitel</th>
                <th>Čas příhozu</th>
                <th>Výše příhozu</th>
                {isDraw && <th>Čas losování</th>}
                {isDraw && <th>Číslo losování</th>}
                <th className="text-right">Podání</th>
              </tr>
            </thead>
            <tbody>
              {bids.map((bid, index) => (
                <tr key={`bid-${index}`}>
                  <td>
                    Dražitel č.{bid.userAuction.uniqueCode}
                    {bid.userAuction.uniqueCode.toString() === props.uniqueCode && (
                      <span className="text-color-green f-weight-500"> - Vaše podání</span>
                    )}
                  </td>
                  <td>{moment(bid.timeCreated).format('D. M. Y HH:mm:ss')}</td>
                  <td>
                    {bid.bid > 0
                      ? getCurrencyFormat(bid.bid)
                      : index !== bids.length - 1
                      ? `Dorovnáno`
                      : getCurrencyFormat(0)}
                  </td>
                  {isDraw && <td>{bid.drawDt ? moment(bid.drawDt).format('D. M. Y HH:mm:ss') : ''}</td>}
                  {isDraw && <td>{bid.drawNumber ?? ''}</td>}
                  <td className="text-right font-weight-bold">{getCurrencyFormat(bid.bidPrice)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  };

  if (props.auction.parentStatus === 'prepared') {
    return (
      <>
        <FrontAccountBox>
          <div className="component-auction-process-tab">
            {props.isDetail && props.auction.status !== 'adjourned' && (
              <div className="block-alert alert-tab mb-4">
                <p>
                  <img src={icoAlertBell} alt="icon" />
                  {props.auction.translations?.auction_until_start || 'auction_until_start'}:{' '}
                  <span className="notranslate">
                    <AuctionCountdown
                      completeText="Probíhá zahajování"
                      date={moment(props.auction?.startDt).toDate()}
                    />
                  </span>
                </p>
                {props.auction?.isJoined ? (
                  <span className="alert-link text-d-none">Již jste připojen</span>
                ) : (
                  <>
                    {!props.auction.outage && (
                      <a href="/" className="alert-link" onClick={props.onJoinClick}>
                        {props.auction.translations?.auction_join || 'auction_join'}
                      </a>
                    )}
                  </>
                )}
              </div>
            )}

            <div className="prepared-auction-alert">
              <img src={alertIco} alt="ico" className="item-ico" />
              {props.auction.translations?.auction_not_ongoing || 'auction_not_ongoing'}
            </div>
          </div>
        </FrontAccountBox>

        {information.filter((i) => i.category === 'a').length > 0 && (
          <FrontAccountBox className="mt-3">
            <div className="component-auction-process-tab">
              <FrontAuctionInformation
                category="a"
                className="mt-0 pt-0"
                auction={props.auction}
                data={information.filter((i) => i.category === 'a')}
              />
            </div>
          </FrontAccountBox>
        )}
      </>
    );
  }

  if (!bidsLoaded) {
    return (
      <FrontAccountBox>
        <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
          <BasePreloader />
        </div>
      </FrontAccountBox>
    );
  }

  return (
    <>
      {renderOngoing()}

      <FrontAccountBox className="mb-3">
        <div className="component-auction-process-tab">
          <h3 className="tab-title">Přihazování</h3>

          {bidsLoaded ? (
            <>{renderBids()}</>
          ) : (
            <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
              <BasePreloader />
            </div>
          )}
        </div>
      </FrontAccountBox>

      {(props.auction.parentStatus === 'ended' ||
        props.auction.parentStatus === 'overcharge' ||
        information.length > 0) && <AuctionResult auction={props.auction} />}
    </>
  );
};

export default OngoingAuctionTab;
