import { Link } from 'react-router-dom';

import { PageType } from '@types';

import { hintDataIcons } from './hintData';

export type HintMainItemItemProps = {
  item: PageType;
};

export const HintMainItem = (props: HintMainItemItemProps) => {
  const { item } = props;
  const icons = hintDataIcons(item.routeName);

  return (
    <div className="main-item-wrapper">
      <Link to={item.fullUrl} style={{ textDecoration: 'none' }}>
        <div className="item">
          <div>
            <img src={icons.icon} alt="Ikona" className="hover-hide" />
            <img src={icons.hoverIcon} alt="Ikona" className="hover-show" />
          </div>
          <div>
            <h5>{item.name}</h5>
          </div>
        </div>
      </Link>
    </div>
  );
};
