import { useEffect, useRef } from 'react';

type Props = {
  onFocus: () => void;
};

export const useWindowFocus = (props: Props) => {
  const onFocusRef = useRef(props.onFocus);

  useEffect(() => {
    onFocusRef.current = props.onFocus;
  }, [props.onFocus]);

  useEffect(() => {
    const handleFocus = () => {
      onFocusRef.current();
    };
    window?.addEventListener('focus', handleFocus);
    return () => {
      window?.removeEventListener('focus', handleFocus);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
