import * as React from 'react';

import { AuctionResponse } from '@types';
import ProgressPanel from './ProgressPanel';

interface Props {
  auction: AuctionResponse;
}

const AuctionProgressSection: React.FC<Props> = (props) => {
  return (
    <div className="component-admin-box-content">
      <ProgressPanel auction={props.auction} />
    </div>
  );
};

export default AuctionProgressSection;
