import { ProtestAdminResponse } from '@types';
import { API, APIResponse, useApi } from '@api/api';

const getAdminProtestsPath = (auctionId: string | number) => `/admin/auction/${auctionId}/protest`;
const getAuctionProtestPath = (auctionId: string | number) => `/user/auction/${auctionId}/protest`;
const getAdminProtestAccept = (auctionId: string | number, protestId: string | number) =>
  `/admin/auction/${auctionId}/protest/${protestId}/accept`;
const getAdminProtestRejected = (auctionId: string | number, protestId: string | number) =>
  `/admin/auction/${auctionId}/protest/${protestId}/rejected`;

export type SaveProtestData = {
  message: string;
};

export type RejectedProtestData = {
  reason: string;
};

export interface AuctionProtestAPI extends API {
  saveProtest(auctionId: string | number, data: SaveProtestData): APIResponse;
  getAdminProtests(auctionId: string | number): APIResponse<ProtestAdminResponse[]>;
  acceptAdminProtest(auctionId: string | number, protestId: string | number): APIResponse;
  rejectedAdminProtest(auctionId: string | number, protestId: string | number, data: RejectedProtestData): APIResponse;
}

export const useAuctionProtestApi = (): AuctionProtestAPI => {
  const api = useApi();

  return {
    ...api,
    saveProtest(auctionId: string | number, data: SaveProtestData) {
      const cancelToken = api.prepareCancelToken('saveProtest');
      return api.authFrontRequest.post(getAuctionProtestPath(auctionId), data, { cancelToken });
    },
    getAdminProtests(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('getAdminProtests');
      return api.authRequest.get(getAdminProtestsPath(auctionId), { cancelToken });
    },
    acceptAdminProtest(auctionId: string | number, protestId: string | number) {
      const cancelToken = api.prepareCancelToken('acceptAdminProtest');
      return api.authRequest.put(getAdminProtestAccept(auctionId, protestId), {}, { cancelToken });
    },
    rejectedAdminProtest(auctionId: string | number, protestId: string | number, data: RejectedProtestData) {
      const cancelToken = api.prepareCancelToken('rejectedAdminProtest');
      return api.authRequest.put(getAdminProtestRejected(auctionId, protestId), data, { cancelToken });
    },
  };
};
