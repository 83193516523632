import { createContext, useContext } from 'react';
import { get, unionBy } from 'lodash';

import { usePagesApi } from '@api/pages';
import { PagePropType, PageType } from '@types';
import { createStore, useStoreState } from './store';

export interface PageContext {
  activePage?: PageType;
  setActivePage(activePage: PageType | undefined): void;
}

export const pageContext = createContext<PageContext>({
  activePage: undefined,
  setActivePage: () => {},
});

export const usePageContext = () => {
  const _pageContext = useContext(pageContext);
  return _pageContext;
};

export interface PageState {
  page?: string;
  isPagesLoaded: boolean;
  pages: PageType[];
}

export const pageStore = createStore<PageState>({
  page: undefined,
  isPagesLoaded: false,
  pages: [],
});

export const usePageStore = () => {
  const pagesApi = usePagesApi();
  const pageState = useStoreState(pageStore);

  const reload = (setLoaded?: boolean): Promise<PageType[]> => {
    return new Promise((resolve, eject) => {
      if (setLoaded) {
        pageStore.setState({ isPagesLoaded: false });
      }

      Promise.all([pagesApi.listEnabled(), pagesApi.listEnabledFront()])
        .then((values) => {
          const pages = unionBy([...values[0].data.data, ...values[1].data.data], (page) => page.id);
          pageStore.setState({ pages });
          resolve(pages);
          pageStore.setState({ isPagesLoaded: true });
        })
        .catch((err) => {
          if (pagesApi.isCancel(err)) {
            return;
          }
          eject(err);
        });
    });
  };

  const getPagesByParentPageId = (parentPageId: number, menuOnly?: boolean): PageType[] => {
    if (menuOnly) {
      return pageState.pages.filter((i) => i.parentPageId === parentPageId && i.menu);
    } else {
      return pageState.pages.filter((i) => i.parentPageId === parentPageId);
    }
  };

  const getPage = (route: string): PageType | undefined => {
    return pageState.pages.find((i) => i.routeName === route);
  };

  const getPagePath = (route: string, props?: PagePropType): string => {
    const page = getPage(route);
    if (page === undefined) {
      if (process.env.NODE_ENV === 'development') {
        console.error(`Page does not exists - ${route}`);
      }
      return '';
    }
    let path = `${page.fullUrl || ''}`;
    if (props !== undefined) {
      Object.getOwnPropertyNames(props).map((prop: string) => {
        path = path.replace(prop, `${get(props, prop, '')}`);
        return true;
      });
    }
    return path;
  };

  return {
    ...pageState,
    reload,
    getPage,
    getPagePath,
    getPagesByParentPageId,
  };
};
