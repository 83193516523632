import { API, APIResponse, useApi } from '@api/api';
import { AdminPageRequest, AdminPageTextBlockRequest, PageTextBlockType, PageType } from '@types';

export const pagesList = () => `/web/page/page-enabled`;
export const pageTextBlocks = (pageId: string | number) => `web/page/${pageId}/text-blocks`;
export const adminPagesListPath = () => `/admin/pages`;
export const adminPageCreatePath = () => `/admin/page`;
export const adminPageDetailPath = (id: string | number) => `/admin/page/${id}`;
export const adminPageBlockDetailPath = (pageId: string | number, blockId: string | number) =>
  `/admin/page/${pageId}/block/${blockId}`;
export const adminPageBlockCreatePath = (pageId: string | number) => `/admin/page/${pageId}/block`;

export interface PagesAPI extends API {
  listEnabled(): APIResponse<PageType[]>;
  listEnabledFront(): APIResponse<PageType[]>;
  listTextBlocks(pageId: string | number): APIResponse<PageTextBlockType[]>;
  getAdminPagesList(): APIResponse<PageType[]>;
  getAdminPageDetail(id: string | number): APIResponse<PageType>;
  adminPageCreate(data: AdminPageRequest): APIResponse<PageType>;
  adminPageUpdate(id: string | number, data: AdminPageRequest): APIResponse<PageType>;
  adminPageDelete(id: string | number): APIResponse;
  getAdminPageBlockDetail(pageId: string | number, blockId: string | number): APIResponse<PageTextBlockType>;
  getAdminPageBlockCreate(pageId: string | number, data: AdminPageTextBlockRequest): APIResponse<PageTextBlockType>;
  getAdminPageBlockUpdate(
    pageId: string | number,
    blockId: string | number,
    data: AdminPageTextBlockRequest
  ): APIResponse<PageTextBlockType>;
  adminPageBlockDelete(pageId: string | number, blockId: string | number): APIResponse;
}

export const usePagesApi = (): PagesAPI => {
  const api = useApi();

  return {
    ...api,
    listEnabled() {
      const cancelToken = api.prepareCancelToken('pagesList');
      return api.authRequest.get(pagesList(), { cancelToken });
    },
    listEnabledFront() {
      const cancelToken = api.prepareCancelToken('pagesListFront');
      return api.authFrontRequest.get(pagesList(), { cancelToken });
    },
    listTextBlocks(pageId: number | string) {
      const cancelToken = api.prepareCancelToken('listTextBlocks');
      return api.authRequest.get(pageTextBlocks(pageId), { cancelToken });
    },
    getAdminPagesList() {
      const cancelToken = api.prepareCancelToken('getAdminPages');
      return api.authRequest.get(adminPagesListPath(), {
        cancelToken,
        params: {
          page: 1,
          perPage: 1000,
          sort: JSON.stringify({
            direction: 'ASC',
            property: 'page.menuPosition',
          }),
          filter: JSON.stringify([
            {
              property: 'page.state',
              operator: 'ne',
              value: 'deleted',
              type: 'string',
            },
            {
              property: 'page.showInAdministration',
              operator: 'eq',
              value: true,
              type: 'boolean',
            },
          ]),
        },
      });
    },
    getAdminPageDetail(id: string | number) {
      const cancelToken = api.prepareCancelToken('getAdminPageDetail');
      return api.authRequest.get(adminPageDetailPath(id), { cancelToken });
    },
    adminPageCreate(data: AdminPageRequest) {
      const cancelToken = api.prepareCancelToken('adminPageCreate');
      return api.authRequest.post(adminPageCreatePath(), data, { cancelToken });
    },
    adminPageUpdate(id: string | number, data: AdminPageRequest) {
      const cancelToken = api.prepareCancelToken('adminPageUpdate');
      return api.authRequest.put(adminPageDetailPath(id), data, { cancelToken });
    },
    adminPageDelete(id: string | number) {
      const cancelToken = api.prepareCancelToken('adminPageDelete');
      return api.authRequest.delete(adminPageDetailPath(id), { cancelToken });
    },
    getAdminPageBlockDetail(pageId: string | number, blockId: string | number) {
      const cancelToken = api.prepareCancelToken('getAdminPageDetail');
      return api.authRequest.get(adminPageBlockDetailPath(pageId, blockId), { cancelToken });
    },
    getAdminPageBlockCreate(pageId: string | number, data: AdminPageTextBlockRequest) {
      const cancelToken = api.prepareCancelToken('getAdminPageBlockCreate');
      return api.authRequest.post(adminPageBlockCreatePath(pageId), data, { cancelToken });
    },
    getAdminPageBlockUpdate(pageId: string | number, blockId: string | number, data: AdminPageTextBlockRequest) {
      const cancelToken = api.prepareCancelToken('getAdminPageBlockUpdate');
      return api.authRequest.put(adminPageBlockDetailPath(pageId, blockId), data, { cancelToken });
    },
    adminPageBlockDelete(pageId: string | number, blockId: string | number) {
      const cancelToken = api.prepareCancelToken('adminPageBlockDelete');
      return api.authRequest.delete(adminPageBlockDetailPath(pageId, blockId), { cancelToken });
    },
  };
};
