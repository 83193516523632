import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import config from '@config';
import { useAuctionsApi } from '@api/auctions';
import { AuctionParameterResponse, AuctionResponse } from '@types';
import { AuctionTypeEnum } from '@components/FrontAuctionDetail/FrontAuctionDetail';

import logo from '@assets/images/color-logo.svg';

type ParamsType = {
  id: string;
};

type Props = {
  type: keyof typeof AuctionTypeEnum;
};

export const AuctionPrint: React.FC<Props> = (props) => {
  const { id } = useParams<ParamsType>();
  const auctionsApi = useAuctionsApi(true);
  const [auction, setAuction] = useState<AuctionResponse>();
  const [parameters, setParameters] = useState<AuctionParameterResponse[]>([]);

  useEffect(() => {
    setAuction(undefined);
    setParameters([]);
    loadAuction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const loadAuction = async () => {
    try {
      const res = await auctionsApi.getWebAuctionInformations(id, props.type);
      await loadAuctionParameterShort();
      setAuction(res.data.data);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadAuctionParameterShort = async () => {
    try {
      const response = await auctionsApi.auctionParameterShort(id);
      setParameters(response.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  if (!auction) {
    return null;
  }

  return (
    <div style={{ width: '100%', marginTop: 20 }}>
      <table style={{ border: 0, width: '100%' }}>
        <tr style={{ border: 0 }}>
          <td
            style={{
              border: 0,
              fontSize: 18,
              paddingBottom: 10,
              fontWeight: 'bold',
            }}
          >
            {auction.title}
          </td>
          <td style={{ border: 0, textAlign: 'right' }}>
            <img src={logo} alt="logo" style={{ maxWidth: '50mm' }} />
          </td>
        </tr>
        <tr style={{ border: 0 }}>
          <td
            colSpan={2}
            style={{
              fontSize: 16,
              paddingBottom: 10,
              fontWeight: 'bold',
              borderBottom: '1px solid black',
            }}
          >
            {auction.number}
          </td>
        </tr>
        <tr style={{ border: 0 }}>
          <td colSpan={2} style={{ border: 0, paddingTop: 20 }}>
            <table style={{ width: '100%', border: 0 }}>
              <tr style={{ border: 0 }}>
                <td style={{ border: 0, width: '50%', paddingRight: 20, verticalAlign: 'top' }}>
                  {auction.auctionMedia.length > 0 && (
                    <img
                      alt={auction.title}
                      style={{ width: '100%' }}
                      src={`${config.apiBaseUrl}/file/${auction.auctionMedia[0].media.hash}?view=true&size=auction.crop`}
                    />
                  )}
                </td>
                <td style={{ border: 0, width: '50%', verticalAlign: 'top' }}>
                  <table style={{ width: '100%', border: 0 }}>
                    {parameters.map((parameter, index) => (
                      <tr style={{ border: 0 }} key={`parameter-${index}`}>
                        <td style={{ width: '30%', border: 0, fontSize: 12 }}>{parameter.name}</td>
                        <td
                          style={{
                            border: 0,
                            fontSize: 12,
                            textAlign: 'right',
                            fontWeight: 'bold',
                          }}
                        >
                          {parameter.value}
                        </td>
                      </tr>
                    ))}
                  </table>
                </td>
              </tr>
            </table>
          </td>
        </tr>
        <tr style={{ border: 0 }}>
          <td colSpan={2} style={{ paddingTop: 20 }} dangerouslySetInnerHTML={{ __html: auction.description || '' }} />
        </tr>
      </table>
    </div>
  );
};
