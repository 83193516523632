import React from 'react';
import Slider from 'react-slick';
import Lightbox from 'react-image-lightbox';

import { AuctionImageType } from '@types';
import { useMediaApi } from '@api/media';
import AuctionVideo from './AuctionVideo';

import auctionNoImage from '@assets/images/auction-no-image.svg';

interface Props {
  images: AuctionImageType[];
}

export type GalleryItemType = {
  src: string;
  image: AuctionImageType;
};

interface GalleryImageProps {
  image: AuctionImageType;
  onLoad: (url: string, image: AuctionImageType) => void;
  onClick: (url: AuctionImageType) => void;
}

const GalleryImage: React.FC<GalleryImageProps> = (props) => {
  const mediaApi = useMediaApi();
  const [imageUrl, setImageUrl] = React.useState('');

  React.useEffect(() => {
    loadImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadImages = async () => {
    await loadImage('auction.crop');
    await loadDetail('auction.photo.detail');
  };

  const loadImage = async (size?: string) => {
    try {
      const media = await mediaApi.detailFront(props.image.media.hash, true, false, size);
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(media.data);
      setImageUrl(imageUrl);
    } catch (err) {
      console.error(err);
      setImageUrl(props.image.id.toString());
    }
  };

  const loadDetail = async (size?: string) => {
    try {
      if (props.image.media.type !== 'embed') {
        const media = await mediaApi.detailFront(props.image.media.hash, true, false, size);
        const urlCreator = window.URL || window.webkitURL;
        const imageUrl = urlCreator.createObjectURL(media.data);
        props.onLoad(imageUrl, props.image);
      } else {
        props.onLoad(props.image.id.toString(), props.image);
      }
    } catch (err) {
      props.onLoad(props.image.id.toString(), props.image);
    }
  };

  if (!imageUrl) {
    return <div className="no-image" />;
  }

  return (
    <div className="cursor-pointer position-relative" onClick={() => props.onClick(props.image)}>
      {props.image.media.type !== 'file' && !props.image.media.type.startsWith('image') && (
        <div className="video-icon">
          <svg
            role="img"
            focusable="false"
            data-prefix="far"
            aria-hidden="true"
            viewBox="0 0 512 512"
            data-icon="play-circle"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#ddd"
              d="M371.7 238l-176-107c-15.8-8.8-35.7 2.5-35.7 21v208c0 18.4 19.8 29.8 35.7 21l176-101c16.4-9.1 16.4-32.8 0-42zM504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256z"
            ></path>
          </svg>
        </div>
      )}
      <img src={imageUrl} alt="auction" />
    </div>
  );
};

const AuctionImages: React.FC<Props> = (props) => {
  const [openLightbox, setOpenLightbox] = React.useState(false);
  const [imageIndex, setImageIndex] = React.useState(0);
  const [auctionImages, setAuctionImages] = React.useState<GalleryItemType[]>([]);

  const handleImageLoad = (url: string, image: AuctionImageType, imageIndex: number) => {
    auctionImages[imageIndex] = { src: url, image };
    setAuctionImages(auctionImages);
  };

  const handleImageClick = (image: AuctionImageType) => {
    const index = auctionImages.findIndex((i) => i.image.id === image.id);
    if (index > -1) {
      setImageIndex(index);
      setOpenLightbox(true);
    }
  };

  const renderLightbox = () => {
    const currentImage = auctionImages[imageIndex];
    if (currentImage.image.media.type === 'embed') {
      return (
        <AuctionVideo
          item={currentImage}
          onRequestClose={() => setOpenLightbox(false)}
          onPrev={() => setImageIndex((imageIndex + auctionImages.length - 1) % auctionImages.length)}
          onNext={() => setImageIndex((imageIndex + 1) % auctionImages.length)}
        />
      );
    }
    return (
      <Lightbox
        animationDuration={0}
        clickOutsideToClose={false}
        mainSrc={currentImage.src}
        nextSrc={auctionImages[(imageIndex + 1) % auctionImages.length].src}
        prevSrc={auctionImages[(imageIndex + auctionImages.length - 1) % auctionImages.length].src}
        onCloseRequest={() => setOpenLightbox(false)}
        onMovePrevRequest={() => setImageIndex((imageIndex + auctionImages.length - 1) % auctionImages.length)}
        onMoveNextRequest={() => setImageIndex((imageIndex + 1) % auctionImages.length)}
      />
    );
  };

  return (
    <div className="image-items">
      {openLightbox && renderLightbox()}
      {(props.images || []).length > 0 ? (
        <Slider
          slidesToShow={1}
          slidesToScroll={1}
          nextArrow={<a href="/">&nbsp;</a>}
          prevArrow={<a href="/">&nbsp;</a>}
        >
          {(props.images || []).map((auctionImage, index) => (
            <div className="image-item" key={`auction-image-${index}`}>
              <GalleryImage
                image={auctionImage}
                onLoad={(url, image) => handleImageLoad(url, image, index)}
                onClick={handleImageClick}
              />
            </div>
          ))}
        </Slider>
      ) : (
        <div className="no-image">
          <img src={auctionNoImage} alt="auction" />
        </div>
      )}
    </div>
  );
};

export default AuctionImages;
