import React from 'react';

import { Modal, Button } from '@components';
import { ModalProps } from '@components/Modal/Modal';

interface Props extends ModalProps {
  content: React.ReactNode;
  actionText: string;
  onAction: () => void;
}

const BeforeJoinModal: React.FC<Props> = (props) => {
  return (
    <Modal {...props} title="Připojit se k dražbě/aukci">
      {props.content}
      <div style={{ marginTop: '24px' }}>
        <Button onClick={props.onAction}>{props.actionText}</Button>
      </div>
    </Modal>
  );
};

export default BeforeJoinModal;
