import React from 'react';
import { FormikValues, useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';

import { BasePreloader, FormGroup, Button } from '@components';
import { Supplier } from '@types';
import { useInvoiceApi } from '@api/invoice';

interface Props {
  data: Supplier | null;
}

const BillingInformationsForm: React.FC<Props> = (props) => {
  const invoiceApi = useInvoiceApi();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: props.data?.title || '',
      ico: props.data?.ico || '',
      dic: props.data?.dic || '',
      street: props.data?.street || '',
      city: props.data?.city || '',
      zip: props.data?.zip || '',
      bankAccount: props.data?.bankAccount || '',
      iban: props.data?.iban || '',
      swift: props.data?.swift || '',
      bankTitle: props.data?.bankTitle || '',
      email: props.data?.email || '',
      phoneNumber: props.data?.phoneNumber || '',
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = (values: FormikValues) => {
    values.phoneNumber = values.phoneNumber.replaceAll(' ', '');
    invoiceApi
      .updateSupplier(values)
      .then(() => {
        formik.setSubmitting(false);
      })
      .catch((err) => {
        if (invoiceApi.isCancel(err)) {
          return;
        }
        const errors = err.response?.data?.errors || {};
        Object.getOwnPropertyNames(errors).map((prop) => {
          formik.setFieldError(prop, errors[prop][0]);
          return prop;
        });
        formik.setSubmitting(false);
      });
  };

  return (
    <div>
      <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-5">
        <div className="responsive-table-content">
          <div>
            <FormGroup
              type="text"
              name="title"
              label="Název:"
              value={formik.values.title}
              onChange={formik.handleChange}
              error={formik.errors.title}
              required
            />
            <FormGroup
              type="text"
              name="email"
              label="Email:"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={formik.errors.email}
              required
            />
            <FormGroup
              type="text"
              name="phoneNumber"
              label="Telefon:"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange}
              error={formik.errors.phoneNumber}
              required
            />
            <FormGroup
              type="text"
              name="ico"
              label="IČ:"
              value={formik.values.ico}
              onChange={formik.handleChange}
              error={formik.errors.ico}
              required
            />
            <FormGroup
              type="text"
              name="dic"
              label="DIČ:"
              value={formik.values.dic}
              onChange={formik.handleChange}
              error={formik.errors.dic}
              required
            />
            <FormGroup
              type="text"
              name="street"
              label="Ulice:"
              value={formik.values.street}
              onChange={formik.handleChange}
              error={formik.errors.street}
              required
            />
            <FormGroup
              type="text"
              name="city"
              label="Město:"
              value={formik.values.city}
              onChange={formik.handleChange}
              error={formik.errors.city}
              required
            />
            <FormGroup
              type="text"
              name="zip"
              label="PSČ:"
              value={formik.values.zip}
              onChange={formik.handleChange}
              error={formik.errors.zip}
              required
            />
            <FormGroup
              type="text"
              name="bankAccount"
              label="Bankovní účet:"
              value={formik.values.bankAccount}
              onChange={formik.handleChange}
              error={formik.errors.bankAccount}
              required
            />
            <FormGroup
              type="text"
              name="iban"
              label="IBAN:"
              value={formik.values.iban}
              onChange={formik.handleChange}
              error={formik.errors.iban}
              required
            />
            <FormGroup
              type="text"
              name="swift"
              label="SWIFT:"
              value={formik.values.swift}
              onChange={formik.handleChange}
              error={formik.errors.swift}
              required
            />
            <FormGroup
              type="text"
              name="bankTitle"
              label="Název banky:"
              value={formik.values.bankTitle}
              onChange={formik.handleChange}
              error={formik.errors.bankTitle}
              required
            />
          </div>
        </div>
        <Row>
          <Col xs={12} className="mt-4 text-right">
            {!formik.isSubmitting ? (
              <Button variant="btn-outline-primary" type="submit" disabled={formik.isSubmitting}>
                Uložit
              </Button>
            ) : (
              <BasePreloader size={29} className="d-inline-block" />
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default BillingInformationsForm;
