import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { BaseAdmin, SEO } from '@components';
import Admin404Page from './pages/admin/Admin404Page/Admin404Page';
import AuctioneerCreatePage from './pages/admin/AuctioneerCreatePage/AuctioneerCreatePage';
import AuctioneerListPage from './pages/admin/AuctioneerListPage/AuctioneerListPage';
import UserListPage from './pages/admin/UserListPage/UserListPage';
import UserUpdatePage from './pages/admin/UserUpdatePage/UserUpdatePage';
import SettingsPage from './pages/admin/SettingsPage/SettingsPage';
import AuctionsListPage from './pages/admin/AuctionsListPage/AuctionsListPage';
import AuctionsUpdatePage from './pages/admin/AuctionsUpdatePage/AuctionsUpdatePage';
import ActiveAuctionPage from './pages/admin/ActiveAuctionPage/ActiveAuctionPage';
import DocumentsPage from './pages/admin/DocumentsPage/DocumentsPage';
import LogListPage from './pages/admin/LogListPage/LogListPage';
import UserDocumentsPage from './pages/admin/UserDocumentsPage/UserDocumentsPage';
import InvoicesPage from './pages/admin/InvoicesPage/InvoicesPage';
import StatisticPage from './pages/admin/StatisticsPage/StatisticsPage';
import DocumentsAndSecurityPage from './pages/admin/DocumentsAndSecurityPage/DocumentsAndSecurityPage';
import { InvoiceUpdatePage } from './pages/admin/InvoiceUpdatePage';

const AdminRoutes: React.FC = () => {
  const pageState = usePageStore();

  return (
    <BaseAdmin>
      <SEO />
      <Switch>
        {!!pageState.getPage(routes.admin.AUCTIONEER_LIST) && (
          <Route exact path={pageState.getPagePath(routes.admin.AUCTIONEER_LIST)}>
            <AuctioneerListPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.AUCTIONEER_CREATE) && (
          <Route exact path={pageState.getPagePath(routes.admin.AUCTIONEER_CREATE)}>
            <AuctioneerCreatePage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.AUCTIONEER_UPDATE) && (
          <Route exact path={pageState.getPagePath(routes.admin.AUCTIONEER_UPDATE)}>
            <AuctioneerCreatePage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.MY_COMPANY_LIST) && (
          <Route exact path={pageState.getPagePath(routes.admin.MY_COMPANY_LIST)}>
            <AuctioneerListPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.MY_COMPANY_UPDATE) && (
          <Route exact path={pageState.getPagePath(routes.admin.MY_COMPANY_UPDATE)}>
            <AuctioneerCreatePage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.USER_LIST) && (
          <Route exact path={pageState.getPagePath(routes.admin.USER_LIST)}>
            <UserListPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.USER_CREATE) && (
          <Route exact path={pageState.getPagePath(routes.admin.USER_CREATE)}>
            <UserUpdatePage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.USER_DETAIL) && (
          <Route exact path={pageState.getPagePath(routes.admin.USER_DETAIL)}>
            <UserUpdatePage readOnly={true} />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.USER_UPDATE) && (
          <Route exact path={pageState.getPagePath(routes.admin.USER_UPDATE)}>
            <UserUpdatePage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.USER_DOCUMENTS) && (
          <Route exact path={pageState.getPagePath(routes.admin.USER_DOCUMENTS)}>
            <UserDocumentsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.DOCUMENTS_AND_SECURITY) && (
          <Route exact path={pageState.getPagePath(routes.admin.DOCUMENTS_AND_SECURITY)}>
            <DocumentsAndSecurityPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.AUCTIONS_LIST) && (
          <Route exact path={pageState.getPagePath(routes.admin.AUCTIONS_LIST)}>
            <AuctionsListPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.AUCTIONS_CREATE) && (
          <Route exact path={pageState.getPagePath(routes.admin.AUCTIONS_CREATE)}>
            <AuctionsUpdatePage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.AUCTIONS_UPDATE) && (
          <Route exact path={pageState.getPagePath(routes.admin.AUCTIONS_UPDATE)}>
            <AuctionsUpdatePage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.AUCTIONS_ACTIVE_DETAIL) && (
          <Route exact path={pageState.getPagePath(routes.admin.AUCTIONS_ACTIVE_DETAIL)}>
            <ActiveAuctionPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.INVOICES) && (
          <Route exact path={pageState.getPagePath(routes.admin.INVOICES)}>
            <InvoicesPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.INVOICES_UPDATE) && (
          <Route exact path={pageState.getPagePath(routes.admin.INVOICES_UPDATE)}>
            <InvoiceUpdatePage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.CASES) && (
          <Route exact path={pageState.getPagePath(routes.admin.CASES)}>
            <Admin404Page page={routes.admin.CASES} />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.DOCUMENTS) && (
          <Route exact path={pageState.getPagePath(routes.admin.DOCUMENTS)}>
            <DocumentsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_PRICE_LIST) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_PRICE_LIST)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_ADMINISTRATORS) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_ADMINISTRATORS)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_INFORMATIONS) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_INFORMATIONS)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_INFORMED_CONSENTS) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_INFORMED_CONSENTS)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_NEWS) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_NEWS)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_NEWS_CREATE) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_NEWS_CREATE)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_NEWS_UPDATE) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_NEWS_UPDATE)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_BILLING_INFORMATIONS) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_BILLING_INFORMATIONS)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_ADMINISTRATORS_CREATE) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_ADMINISTRATORS_CREATE)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_ADMINISTRATORS_UPDATE) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_ADMINISTRATORS_UPDATE)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_TEXT_BLOCK) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_TEXT_BLOCK)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_TEXT_BLOCK_CREATE) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_TEXT_BLOCK_CREATE)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_TEXT_BLOCK_UPDATE) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_TEXT_BLOCK_UPDATE)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_TEXT_BLOCK_CONTENT_CREATE) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_TEXT_BLOCK_CONTENT_CREATE)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_TEXT_BLOCK_CONTENT_UPDATE) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_TEXT_BLOCK_CONTENT_UPDATE)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_PAGES_LIST) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_PAGES_LIST)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_PAGES_CREATE) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_PAGES_CREATE)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_PAGES_DETAIL) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_PAGES_DETAIL)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_PAGES_BLOCK_CREATE) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_PAGES_BLOCK_CREATE)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_PAGES_BLOCK_DETAIL) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_PAGES_BLOCK_DETAIL)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.SETTINGS_OTHER) && (
          <Route exact path={pageState.getPagePath(routes.admin.SETTINGS_OTHER)}>
            <SettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.LOG) && (
          <Route exact path={pageState.getPagePath(routes.admin.LOG)}>
            <LogListPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.STATISTICS) && (
          <Route exact path={pageState.getPagePath(routes.admin.STATISTICS)}>
            <StatisticPage />
          </Route>
        )}
        {!!pageState.getPage(routes.admin.AUCTIONS_LIST) && (
          <Redirect from="*" to={pageState.getPagePath(routes.admin.AUCTIONS_LIST)} />
        )}
      </Switch>
    </BaseAdmin>
  );
};

export default AdminRoutes;
