import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import _ from 'lodash';
import { Bar } from 'react-chartjs-2';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { getBaseNumberFormat } from '@helpers/formats';
import { Auctioneer, StatisticGraphEnumType, StatisticReponse, StatisticYearsType } from '@types';
import { AdminBoxContent, BasePage, BasePreloader } from '@components';

import Filters from './components/Filters';
import { useStatisticApi } from '@api/statistics';
import { useAuctioneersApi } from '@api/auctioneers';
import { ChartOptions } from 'chart.js';

const StatisticPage: React.FC = () => {
  const statisticApi = useStatisticApi();
  const auctioneersApi = useAuctioneersApi();
  const location = useLocation();
  const pageState = usePageStore();
  const page = pageState.getPage(routes.admin.STATISTICS);
  const [data, setData] = React.useState<StatisticReponse | null>(null);
  const [graphs, setGraphs] = React.useState<Array<StatisticGraphEnumType>>([]);
  const [years, setYears] = React.useState<StatisticYearsType>({});
  const [auctioneers, setAuctioneers] = React.useState([] as Array<Auctioneer>);
  const [loaded, setLoaded] = React.useState(false);
  const [query, setQuery] = useQueryParams({
    year: withDefault(StringParam, '2021'),
    graph: withDefault(StringParam, 'number_auctions'),
    auctioneer: withDefault(StringParam, undefined),
  });

  React.useEffect(() => {
    loadData();
    return () => {
      statisticApi.cancelAllRequests();
      auctioneersApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, query]);

  React.useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [years, graphs]);

  const loadData = async () => {
    setLoaded(false);
    try {
      const responseAuctioneers = await auctioneersApi.list({});
      setAuctioneers(responseAuctioneers.data.data);
      const graphsResponse = await statisticApi.statisticGraphEnum();
      setGraphs(graphsResponse.data.data);
      const yearsResponse = await statisticApi.statisticYears();
      setYears(yearsResponse.data);
      const filters = _.omit(query, ['year', 'graph']);
      const response = await statisticApi.getStatistics(query.graph, query.year, filters);
      setData(response.data);
      setLoaded(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const graphData = {
    labels: data?.data?.map((item) => item.month) || [],
    datasets: [
      {
        label: data?.graph.title || '',
        data: data?.data?.map((item) => (item.values.length > 0 ? item.values[0].value : 0)) || [],
        backgroundColor: data?.data?.map((item) => 'rgb(98, 205, 163)') || ['rgb(98, 205, 163)'],
      },
    ],
  };

  const options: ChartOptions = {
    plugins: {
      tooltip: {
        intersect: true,
        callbacks: {
          label: function (context: any) {
            let label = `${context.dataset.label} - ${context.formattedValue} ` || '';
            return data?.graph.unit ? label + data?.graph.unit : label;
          },
        },
      },
      datalabels: {
        color: 'black',
        align: 'top',
        anchor: 'end',
        font: {
          size: 14,
        },
        formatter: function (value) {
          return data?.graph.unit
            ? getBaseNumberFormat(Math.round(value * 100) / 100) + ` ${data?.graph.unit}`
            : getBaseNumberFormat(value);
        },
      },
    },
    scales: {
      y: {
        ticks: {
          callback: function (value: any) {
            return data?.graph.unit ? value + ` ${data?.graph.unit}` : value;
          },
        },
      },
    },
  };

  return (
    <BasePage page={routes.admin.STATISTICS} title={page?.name}>
      <Filters
        values={query}
        graphs={graphs}
        years={years}
        auctioneers={auctioneers}
        onSubmit={(values) => setQuery(values, 'push')}
      />
      <AdminBoxContent>
        <Row>
          <Col xs={12}>
            {loaded && data ? (
              <div>
                <Bar data={graphData} options={options} type="bar" plugins={[ChartDataLabels]} />
              </div>
            ) : (
              <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
                <BasePreloader />
              </div>
            )}
          </Col>
        </Row>
      </AdminBoxContent>
    </BasePage>
  );
};

export default StatisticPage;
