import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { AuctionConceptType } from '@types';
import { getDateTimeFormat } from '@helpers/datetime';

import colorIcoEye from '@assets/images/color-ico-eye.svg';
import colorIcoDel from '@assets/images/color-ico-del.svg';
import colorCopy from '@assets/images/admin-sidebar/document-active-small.svg';

interface Props {
  data: AuctionConceptType[];
  onDeleteClick: (item: AuctionConceptType) => void;
  onCopyClick: (item: AuctionConceptType) => void;
}

const ConceptItemsTable: React.FC<Props> = (props) => {
  const pageState = usePageStore();

  const handleDeleteClick = (e: React.MouseEvent, item: AuctionConceptType) => {
    e.preventDefault();
    props.onDeleteClick(item);
  };

  const handleCopyConcept = (e: React.MouseEvent, item: AuctionConceptType) => {
    e.preventDefault();
    props.onCopyClick(item);
  };

  return (
    <div className="responsive-table-content">
      <Table className="table-middle" striped>
        <thead>
          <tr>
            <th className="w-1">ID</th>
            <th>Datum vytvoření</th>
            <th />
          </tr>
        </thead>
        <tbody>
          {props.data.map((item, index) => (
            <tr key={`list-item-${index}`}>
              <td>{item.id}</td>
              <td>{getDateTimeFormat(item.timeCreated)}</td>
              <td className="text-right">
                <Link className="d-inline-flex align-items-center" to="/" onClick={(e) => handleCopyConcept(e, item)}>
                  <img src={colorCopy} alt="ico" className="mr-2" />
                  Kopírovat
                </Link>
                <Link
                  className="d-inline-flex align-items-center ml-4"
                  to={`${pageState.getPagePath(routes.admin.AUCTIONS_CREATE)}?concept=${item.id}`}
                >
                  <img src={colorIcoEye} alt="ico" className="mr-2" />
                  Otevřít
                </Link>
                <Link
                  className="d-inline-flex align-items-center ml-4"
                  to="/"
                  onClick={(e) => handleDeleteClick(e, item)}
                >
                  <img src={colorIcoDel} alt="ico" className="mr-2" />
                  Smazat
                </Link>
              </td>
            </tr>
          ))}
          {props.data.length < 1 && (
            <tr>
              <td className="text-left" colSpan={100}>
                Nebyla nalezena žádná data.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default ConceptItemsTable;
