import * as React from 'react';
import { Form } from 'react-bootstrap';

import icoSend2Color from '@assets/images/ico-send-2-color.svg';
import icoSend2White from '@assets/images/ico-send-2-white.svg';

import { useBuyerApi } from '@api/buyer';
import { useAuctionsApi } from '@api/auctions';
import Wysiwyg from '@components/Wysiwyg/Wysiwyg';
import { Button, BasePreloader } from '@components';
import {
  UserAuctionSecurityState,
  AuctionResponse,
  BuyerAuctionResponse,
  AuctionCautionDepositType,
  AuctionSecurityCounterInfo,
} from '@types';

interface Props {
  setBuyer: (buyer: BuyerAuctionResponse) => void;
  buyerAuction: BuyerAuctionResponse;
  auction: AuctionResponse;
  buyerId: string;
  auctionId: string;
  getTranslation: (key: string) => string;
}

const AuctionSecurityPanel: React.FC<Props> = (props) => {
  const buyerApi = useBuyerApi();
  const auctionsApi = useAuctionsApi();
  const [auctionDepositTypes, setAuctionDepositTypes] = React.useState<AuctionCautionDepositType[]>([]);
  const [auctionSecurityCounterInfo, setAuctionSecurityCounterInfo] = React.useState<AuctionSecurityCounterInfo>();
  const [auctionSecurityCounterInfoLoaded, setAuctionSecurityCounterInfoLoaded] = React.useState(false);
  const [auctionDepositTypesLoaded, setAuctionDepositTypesLoaded] = React.useState(false);
  const [securityState, setSecurityState] = React.useState<UserAuctionSecurityState>(UserAuctionSecurityState.none);
  const [isSendedInstructions, setIsSendedInstructions] = React.useState(true);
  const [rejectedReason, setRejectedReason] = React.useState('');
  const [rejectedReasonError, setRejectedReasonError] = React.useState('');
  const [authSecuritySaved, setSecuritySaved] = React.useState(true);

  React.useEffect(() => {
    loadCautionTypes();
    loadAuctionSecurityCounterInfo();
    setSecurityState(props.buyerAuction.auctionSecurity as UserAuctionSecurityState);
    setRejectedReason(props.buyerAuction.securityStateReason || '');
    return () => buyerApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCautionTypes = async () => {
    try {
      const response = await auctionsApi.getCautionDeposit(props.auctionId);
      setAuctionDepositTypes(response.data.data);
      setAuctionDepositTypesLoaded(true);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const loadAuctionSecurityCounterInfo = async () => {
    try {
      const response = await auctionsApi.getCautionDepositCounterInfo(props.auctionId, props.buyerId);
      setAuctionSecurityCounterInfo(response.data.data);
      setAuctionSecurityCounterInfoLoaded(true);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const handleSecurityStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as UserAuctionSecurityState;
    setSecurityState(value);
    if (value === UserAuctionSecurityState.rejected) {
      setTimeout(() => {
        document.getElementById('rejectedReason')?.focus();
      }, 200);
    }
  };

  const handleSendInstructionsClick = async () => {
    setIsSendedInstructions(false);
    try {
      await buyerApi.sendSecurityInstructions(props.buyerId, props.auctionId);
      loadAuctionSecurityCounterInfo();
    } catch (err) {
      if (buyerApi.isCancel(err)) {
        return;
      }
      console.error(err);
    } finally {
      setIsSendedInstructions(true);
    }
  };

  const handleChangeClick = async () => {
    setSecuritySaved(false);
    try {
      await buyerApi.changeAuctionSecurity(props.buyerId, props.auctionId, {
        auctionSecurity: securityState,
        securityStateReason: securityState === UserAuctionSecurityState.rejected ? rejectedReason : undefined,
      });
      props.setBuyer({ ...props.buyerAuction, auctionSecurity: securityState });
      setRejectedReasonError('');
      if (securityState !== UserAuctionSecurityState.rejected) {
        setRejectedReason('');
      }
    } catch (err) {
      if (buyerApi.isCancel(err)) {
        return;
      }
      const reasonError = err.response?.data?.errors?.authorizationStateReason;
      setRejectedReasonError(!!reasonError ? reasonError[0] : '');
    } finally {
      setSecuritySaved(true);
    }
  };

  return (
    <div className="auction-panel-content pb-5">
      {parseFloat(props.auction.cautionDeposit || '0') > 0 ? (
        <>
          <div>
            <h3 className="f-size-16 f-weight-600">{props.getTranslation('tab_auction_deposit_instruction_title')}</h3>
            {(props.auction.auctionCautionCash.enabled || props.auction.auctionCautionBankAccount.enabled) &&
              auctionSecurityCounterInfoLoaded && (
                <div className="mt-3">{auctionSecurityCounterInfo?.counterMessage}</div>
              )}
          </div>
          <div className="mt-30 d-flex flex-wrap align-items-center">
            {isSendedInstructions ? (
              <>
                <Button type="button" className="f-size-12 mr-4" onClick={handleSendInstructionsClick}>
                  <img src={icoSend2Color} alt="ico" className="mr-2 hover-hide" />
                  <img src={icoSend2White} alt="ico" className="mr-2 hover-show" />
                  {props.getTranslation('tab_auction_deposit_send_instruction_btn')}
                </Button>
                {/* <p className="mb-0 f-size-12 f-weight-normal text-color-gray-3">Posláno: 0x naposledy</p> */}
              </>
            ) : (
              <BasePreloader size={34} className="m-0" />
            )}
          </div>

          <div className="mt-50">
            <h3 className="f-size-16 f-weight-600">{props.getTranslation('tab_auction_deposit_title')}</h3>
            {auctionDepositTypesLoaded ? (
              <div className="pl-1">
                {auctionDepositTypes.map((item, index) => (
                  <div className="mt-3">
                    <Form.Check
                      custom
                      type="radio"
                      id={`auctionSecurity${index}`}
                      name="auctionSecurity"
                      className="radio-point"
                      value={item.type}
                      label={item.translated}
                      checked={securityState === item.type}
                      onChange={handleSecurityStateChange}
                    />
                  </div>
                ))}
                {securityState === UserAuctionSecurityState.rejected && (
                  <div className="mt-3">
                    <Wysiwyg
                      type="normal"
                      name="rejectedReason"
                      value={rejectedReason}
                      error={rejectedReasonError}
                      placeholder="Důvod zamítnutí"
                      onChange={(val) => setRejectedReason(val)}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="mt-3">
                <BasePreloader size={30} />
              </div>
            )}
          </div>

          {auctionDepositTypesLoaded && (
            <div className="mt-30 d-flex flex-wrap align-items-center">
              {authSecuritySaved ? (
                <>
                  <Button type="button" className="f-size-12 mr-4" onClick={handleChangeClick}>
                    <img src={icoSend2Color} alt="ico" className="mr-2 hover-hide" />
                    <img src={icoSend2White} alt="ico" className="mr-2 hover-show" />
                    {props.getTranslation('tab_auction_deposit_send_btn')}
                  </Button>
                  {/* <p className="mb-0 f-size-12 f-weight-normal text-color-gray-3">Posláno: 0x naposledy</p> */}
                </>
              ) : (
                <BasePreloader size={34} className="m-0" />
              )}
            </div>
          )}
        </>
      ) : (
        <div className="mt-2">
          <p className="mb-0">{props.getTranslation('tab_auction_deposit_empty')}</p>
        </div>
      )}
    </div>
  );
};

export default AuctionSecurityPanel;
