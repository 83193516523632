import React from 'react';
import classNames from 'classnames';

import { Button } from '@components';
import { FrontBannerNavigationItemType } from '@types';

const NavigationItem: React.FC<FrontBannerNavigationItemType> = (props) => (
  <div style={props.style} className={classNames(['navigation-item', { 'item-active': props.active }])}>
    <div className="item-ico">
      <img src={props.icon} alt="ico" className="hover-hide" />
      <img src={props.iconActive} alt="ico" className="hover-show" />
    </div>
    <div className="item-text">
      <p>{props.text}</p>
      <div>
        <Button className={props.btnClassName}>{props.btnText}</Button>
      </div>
    </div>
  </div>
);

export default NavigationItem;
