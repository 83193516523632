import { PaginationParams, User } from '@types';
import { API, APIResponse, useApi } from '@api/api';

export const administratorsPath = () => `/admin/administrator`;

export const administratorPath = (administratorId: string | number) => `/admin/administrator/${administratorId}`;

export const administratorRestorePath = (administratorId: string | number) => `/admin/user/${administratorId}/restore`;

export interface AdministratorAPI extends API {
  list(params: PaginationParams): APIResponse<Array<User>>;
  detail(id: string | number): APIResponse<User>;
  create(user: Partial<User>): APIResponse<User>;
  update(userId: number | string, user: Partial<User>): APIResponse<User>;
  delete(userId: number | string): APIResponse;
  restore(userId: number | string): APIResponse;
}

export const useAdministratorApi = (): AdministratorAPI => {
  const api = useApi();

  return {
    ...api,
    list(params: PaginationParams) {
      const cancelToken = api.prepareCancelToken('list');
      return api.authRequest.get(administratorsPath(), {
        cancelToken,
        params: {
          page: params.page,
          perPage: params.perPage,
          sort: JSON.stringify({ property: 'user.state', direction: 'asc' }),
        },
      });
    },
    detail(userId: number | string) {
      const cancelToken = api.prepareCancelToken('detail');
      return api.authRequest.get(administratorPath(userId), { cancelToken });
    },
    create(user: Partial<User>) {
      const cancelToken = api.prepareCancelToken('create');
      return api.authRequest.post(administratorsPath(), user, { cancelToken });
    },
    update(userId: number | string, user: Partial<User>) {
      const cancelToken = api.prepareCancelToken('update');
      return api.authRequest.put(administratorPath(userId), user, { cancelToken });
    },
    delete(userId: number) {
      const cancelToken = api.prepareCancelToken('delete');
      return api.authRequest.delete(administratorPath(userId), { cancelToken });
    },
    restore(userId: number) {
      const cancelToken = api.prepareCancelToken('restore');
      return api.authRequest.get(administratorRestorePath(userId), { cancelToken });
    },
  };
};
