import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useFormik } from 'formik';
import { BasePreloader, Button, FormGroup, Modal } from '@components';
import { AuctionResponse, BaseObjectType, ProtestAdminResponse } from '@types';
import { useAuctionProtestApi } from '@api/auctionProtest';

type Props = {
  isOpen: boolean;
  auction: AuctionResponse;
  item: ProtestAdminResponse;
  translations: BaseObjectType;
  onClose: (reload: boolean) => void;
};

export const ProtestRejectedModal: React.FC<Props> = (props) => {
  const auctionProtestApi = useAuctionProtestApi();
  const [showLoaded, setShowLoader] = useState(false);
  const formik = useFormik({
    validateOnChange: false,
    onSubmit: () => handleSubmit(),
    initialValues: {
      reason: '',
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => auctionProtestApi.cancelAllRequests(), []);

  const getTranslation = (key: string) => _.get(props.translations, key, key);

  const handleSubmit = async () => {
    if (showLoaded) {
      return;
    }
    setShowLoader(true);
    try {
      await auctionProtestApi.rejectedAdminProtest(props.auction.id, props.item.id, { ...formik.values });
      props.onClose(true);
    } catch (err: any) {
      if (auctionProtestApi.isCancel(err)) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      setShowLoader(false);
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      className="w-max-500"
      title={getTranslation('protest_rejected_modal_title')}
      onRequestClose={() => props.onClose(false)}
    >
      <form onSubmit={formik.handleSubmit}>
        <div>
          <FormGroup
            rows={5}
            as="textarea"
            name="reason"
            className="d-block"
            controlClassName="noresize"
            labelClassName="w-auto mb-2"
            error={formik.errors.reason}
            onChange={formik.handleChange}
            label={getTranslation('protest_rejected_reason_label')}
          />
          <div className="d-flex justify-content-end">
            {showLoaded ? (
              <BasePreloader size={25} className="mr-2" />
            ) : (
              <Button type="submit">{getTranslation('protest_rejected_btn')}</Button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  );
};
