import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import nl2br from 'react-nl2br';
import { Col, Row } from 'react-bootstrap';

import { usePageStore } from '@stores';
import { useAuctionsApi } from '@api/auctions';
import { BasePreloader, Button } from '@components';
import { getCurrencyFormat } from '@helpers/formats';
import { getDateTimeFormat } from '@helpers/datetime';
import AuctionCountdown from '@components/AuctionCountdown/AuctionCountdown';
import AuctionParams from '@components/FrontAuctionDetail/components/AuctionParams';
import AuctionImages from '@components/FrontAuctionDetail/components/AuctionImages';
import { AuctionImageType, AuctionParameterResponse, AuctionResponse } from '@types';

import icoCalendar from '@assets/images/front/auction-detail/ico-calendar.svg';
import icoAlertBell from '@assets/images/front/auction-detail/ico-alert-bell.svg';
import * as routes from '@routes';
import AuctionTabs from './AuctionTabs';

type Props = {
  auctionType: string;
  auctionId: string | number;
  onBack: () => void;
};

export const AuctionDetail: React.FC<Props> = (props) => {
  const pageState = usePageStore();
  const auctionsApi = useAuctionsApi();
  const [section, setSection] = useState(0);
  const [auction, setAuction] = useState<AuctionResponse | undefined>();
  const [auctionImages, setAuctionImages] = useState<AuctionImageType[]>([]);
  const [auctionParameterShort, setAuctionParameterShort] = useState<AuctionParameterResponse[]>();
  const [auctionParameterComplete, setAuctionParameterComplete] = useState<AuctionParameterResponse[]>();
  const tabsRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    loadAuction();
    return () => auctionsApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAuction = async () => {
    try {
      const response = await auctionsApi.getWebAuctionInformations(props.auctionId, props.auctionType);
      await loadAuctionParameterShort();
      await loadAuctionParameterComplete();
      setAuctionImages(response.data.data.auctionMedia);
      setAuction(response.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
      if (err.response.status === 404) {
        props.onBack();
      }
    }
  };

  const loadAuctionParameterShort = async () => {
    try {
      const response = await auctionsApi.auctionParameterShort(props.auctionId);
      setAuctionParameterShort(response.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const loadAuctionParameterComplete = async () => {
    try {
      const response = await auctionsApi.auctionParameterComplete(props.auctionId);
      setAuctionParameterComplete(response.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const handleMoreClick = (e: React.MouseEvent) => {
    e.preventDefault();
    setSection(1);
    setTimeout(() => {
      if (!!tabsRef.current) {
        tabsRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 100);
  };

  if (!auction) {
    return (
      <div className="p-3">
        <BasePreloader size={25} />
      </div>
    );
  }

  return (
    <div className="layout-iframe-detail">
      <div>
        <Button onClick={() => props.onBack()}>Zpět</Button>
      </div>
      <div>
        <div className="component-front-auction-detail">
          <div className="auction-detail-content">
            <h1 className="auction-title">{auction.title}</h1>

            {auction && auction.parentStatus === 'prepared' && auction.status !== 'adjourned' && (
              <div className="block-alert">
                <p>
                  <img src={icoAlertBell} alt="icon" />
                  {auction.startDt ? (
                    <>
                      {auction.translations?.auction_until_start || 'auction_until_start'}:{' '}
                      <span className="notranslate">
                        <AuctionCountdown
                          completeText="Probíhá zahajování"
                          date={moment(auction?.startDt).toDate()}
                          onComplete={() => {
                            setAuction(undefined);
                            setTimeout(() => loadAuction(), 1000);
                          }}
                        />
                      </span>
                    </>
                  ) : (
                    `${auction.translations?.auction_not_set_start || 'auction_not_set_start'}`
                  )}
                </p>
                {auction.parentStatus === 'prepared' ? (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={pageState.getPagePath(
                      auction.auctionType === 'auction'
                        ? routes.front.AUCTION_DETAIL
                        : routes.front.AUCTION_PUBLIC_DETAIL,
                      { ':id': auction.id }
                    )}
                    className="alert-link"
                  >
                    {auction.translations?.auction_join || 'auction_join'}
                  </a>
                ) : (
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={pageState.getPagePath(
                      auction.auctionType === 'auction'
                        ? routes.front.AUCTION_DETAIL
                        : routes.front.AUCTION_PUBLIC_DETAIL,
                      { ':id': auction.id }
                    )}
                    className="alert-link"
                  >
                    Zobrazit dražbu
                  </a>
                )}
              </div>
            )}

            <div className="top-info">
              <div className="price-label">{auction?.winnerBid?.bidPrice ? `Vydraženo za` : `Nejnižší podání`}:</div>
              <div className="price-value">
                {getCurrencyFormat(auction?.winnerBid?.bidPrice || auction?.minimalBid)}
              </div>
              <div className="auction-label">{auction?.statusTranslation}</div>
              <div className="top-info__right">
                <div className="auction-number">
                  <span> {auction?.translations?.auction_number || 'auction_number'}:</span> {auction?.number}
                </div>
                <div className="auction-created">
                  <span>Vloženo:</span> {getDateTimeFormat(auction?.timeCreated)}
                </div>
              </div>
            </div>

            <div className="block-auction">
              <Row>
                <Col sm={12} md={6} className="mb-4 mb-md-0 column-images">
                  <AuctionImages images={auctionImages} />
                  {!!auction?.visitationDescription && (
                    <div className="banner-info">
                      <img src={icoCalendar} alt="icon" className="block-ico" />
                      <div className="block-text">
                        <h3 className="item-title">Termín prohlídky:</h3>
                        <p className="item-text">{nl2br(auction?.visitationDescription)}</p>
                      </div>
                    </div>
                  )}
                </Col>
                <Col sm={12} md={6} className="column-params">
                  <AuctionParams
                    auction={auction}
                    parameterShort={auctionParameterShort}
                    onMoreClick={handleMoreClick}
                  />
                </Col>
              </Row>
            </div>
          </div>

          <div className="block-tabs" ref={tabsRef}>
            <AuctionTabs
              activeSection={section}
              auctionDetail={auction}
              auctionParameterComplete={auctionParameterComplete}
              onChange={setSection}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
