import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormikErrors, FormikValues } from 'formik';

import { randStr } from '@helpers/string';
import Wysiwyg from '@components/Wysiwyg/Wysiwyg';
import { ModalProps } from '@components/Modal/Modal';
import { useAuctioneersApi } from '@api/auctioneers';
import { Button, FormGroup, Modal } from '@components';
import { AuctioneerTemplate, AuctionInformedConsent, BaseObjectType } from '@types';

interface Props extends ModalProps {
  id: string;
  updatedItem?: AuctioneerTemplate;
  itemIndex?: number;
  item?: AuctionInformedConsent;
  auctionType: 'auction' | 'auction_public';
  onClose: () => void;
  values: FormikValues;
  isSubmitting: boolean;
  errors: FormikErrors<FormikValues>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  auctionTemplateId?: string;
}

const TemplatesInformedConsentForm: React.FC<Props> = (props) => {
  const auctioneersApi = useAuctioneersApi();
  const [title, setTitle] = useState<string>(props.item?.title || '');
  const [text, setText] = useState<string>(props.item?.text || '');
  const [enabled, setEnabled] = useState<boolean | undefined>(props.item?.enabled);
  const [errors, setErrors] = useState<BaseObjectType>({});

  const handleSubmit = async () => {
    let valid = true;
    const errors: BaseObjectType = {};

    if (title.trim() === '') {
      errors.title = 'Název musí být vyplněn.';
      valid = false;
    }
    if (text.trim() === '') {
      errors.text = 'Text musí být vyplněn.';
      valid = false;
    }

    setErrors(errors);
    if (!valid) {
      return;
    }

    const rawData = { ...(props.updatedItem || {}) };
    rawData.data = JSON.parse(rawData.data);
    rawData.data.informedConsents = [...props.values.data.informedConsents];

    if (props.itemIndex !== undefined) {
      rawData.data.informedConsents[props.itemIndex].title = title;
      rawData.data.informedConsents[props.itemIndex].text = text;
      rawData.data.informedConsents[props.itemIndex].enabled = enabled;
    } else {
      rawData.data.informedConsents.push({
        title,
        text,
        enabled,
        uniqueId: randStr(16),
      });
    }

    try {
      await auctioneersApi.updateTemplate(
        rawData.name || '',
        rawData.auctionType || '',
        rawData.isDefault || false,
        rawData.data,
        props.id,
        props.auctionTemplateId || ''
      );

      if (props.itemIndex !== undefined) {
        props.setFieldValue(`informedConsents[${props.itemIndex}]`, rawData.data.informedConsents[props.itemIndex]);
        props.setFieldValue(
          `data.informedConsents[${props.itemIndex}]`,
          rawData.data.informedConsents[props.itemIndex]
        );
      } else {
        props.setFieldValue(
          `informedConsents[${rawData.data.informedConsents.length - 1}]`,
          rawData.data.informedConsents[rawData.data.informedConsents.length - 1]
        );
        props.setFieldValue(
          `data.informedConsents[${rawData.data.informedConsents.length - 1}]`,
          rawData.data.informedConsents[rawData.data.informedConsents.length - 1]
        );
      }

      props.onClose();
    } catch (err: any) {
      if (auctioneersApi.isCancel(err)) {
        return;
      }
    }
  };

  return (
    <Modal {...props} onRequestClose={props.onClose} className="modal-content-lg invoice-modal-content">
      <div>
        <div className="pl-2">
          <div className="mt-0">
            <div className="responsive-table-content">
              <div className="pt-2">
                <label>Název *</label>
                <FormGroup
                  required
                  name="title"
                  value={title}
                  error={errors.title}
                  className="d-block mt-0 pt-0"
                  labelClassName="text-left"
                  onChange={(e: React.FormEvent<HTMLInputElement>) => setTitle(e.currentTarget.value)}
                />

                <div>
                  <label>Text *</label>
                  <div style={{ paddingLeft: 2, marginTop: -25 }}>
                    <Wysiwyg value={text} name="content" error={errors.text} onChange={(val) => setText(val)} />
                  </div>
                </div>

                <div className="pb-3 d-flex mb-3">
                  <div className="mr-4 ml-0">
                    <Form.Check
                      type="switch"
                      id="enabled"
                      className="enabled"
                      checked={enabled}
                      onChange={() => setEnabled(!enabled)}
                    />
                  </div>
                  <div className="font-weight-bold">Aktivováno</div>
                </div>
              </div>
            </div>

            <div className="pt-3 d-flex flex-wrap justify-content-between">
              <Button type="button" onClick={() => props.onClose()}>
                Zpět na seznam
              </Button>
              <div>
                <Button onClick={handleSubmit}>Uložit</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TemplatesInformedConsentForm;
