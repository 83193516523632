import React from 'react';
import clx from 'classnames';

import { getCurrencyFormat } from '@helpers/formats';
import { getDateFormat, getTimeFormat } from '@helpers/datetime';
import { AuctionResponse, AuctionWinnerResponse } from '@types';

interface Props {
  winner?: AuctionWinnerResponse;
  auction: AuctionResponse;
  className?: string;
}

const AuctionEndedInfo: React.FC<Props> = (props) => {
  if (props.auction.parentStatus !== 'ended' && props.auction.parentStatus !== 'overcharge') {
    return null;
  }

  return (
    <div className={clx(['component-auction-ended-info', props.className])}>
      <h2 className="block-title">
        {props.winner?.winnerBid?.drawNumber
          ? props.auction.translations?.auction_result_draw || 'auction_result_draw'
          : props.auction.translations?.auction_result || 'auction_result'}
      </h2>
      <div className="ended-info">
        {props.auction.translations?.auction_result_end_dt || 'auction_result_end_dt'}{' '}
        {getDateFormat(props.auction.realEndDt || props.auction.endDt)} v{' '}
        {getTimeFormat(props.auction.realEndDt || props.auction.endDt)}
      </div>
      <div className="ended-info-subtitle">V tuto chvíli není možné činit další podání.</div>

      {!!props.winner?.winnerBid && (
        <div className="bids-info">
          <div className="bid-item">
            {props.winner?.winnerBid?.userAuction.preEmption ||
            props.winner?.winnerBid?.userAuction.ownershipPreEmption ? (
              <>
                <span>Příklep byl z důvodu předkupního práva učiněn dražiteli č.:</span>{' '}
                {props.winner.winnerBid?.userAuction.uniqueCode}
              </>
            ) : (
              <>
                <span>Nejvyšší podání učinil:</span> Dražitel č.{props.winner.winnerBid?.userAuction.uniqueCode}
              </>
            )}
          </div>
          <div className="bid-item">
            <span>Nejvyšší podání učiněno ve výši:</span> {getCurrencyFormat(props.winner.winnerBid.bidPrice)}
          </div>
        </div>
      )}
    </div>
  );
};

export default AuctionEndedInfo;
