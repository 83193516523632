import React from 'react';
import axios from 'axios';
import { Table } from 'react-bootstrap';

import { APIResponse } from '@api/api';
import { getName, isHTML } from '@helpers/string';
import { stripeHtml } from '@helpers/stripeHtml';
import { getDateTimeFormat } from '@helpers/datetime';
import { BasePreloader, Pagination, Modal } from '@components';
import { HistoryType, HistoryAuthorType } from '@types';

import colorIcoEye from '@assets/images/color-ico-eye.svg';

interface Props {
  id: string | number;
  title?: string;
  showUser?: boolean;
  api: (id: string | number, perPage?: number, page?: number) => APIResponse<HistoryType[]>;
  author?: HistoryAuthorType;
  dateCreated?: string;
}

type ShowHTMLType = {
  title: string;
  value: string;
};

const HistoryTable: React.FC<Props> = (props) => {
  const perPage = 10;
  const [history, setHistory] = React.useState<HistoryType[]>([]);
  const [loaded, setLoaded] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [totalRows, setTotalRows] = React.useState(0);
  const [showHtml, setShowHtml] = React.useState<ShowHTMLType | undefined>();

  React.useEffect(() => {
    loadHistory(props.id, 1);
    // eslint-disable-next-line
  }, [props.id]);

  const loadHistory = (id: string | number, page: number) => {
    setLoaded(false);
    setCurrentPage(page);
    props
      .api(id, perPage, page)
      .then((res) => {
        setHistory(res.data.data);
        setLoaded(true);
        setTotalRows(res.data.total || 0);
      })
      .catch((err) => {
        if (axios.isCancel(err)) {
          return;
        }
      });
  };

  const getUserName = (item: HistoryType) => {
    return getName(item.userCreated) || item.userCreated?.email || 'Systém';
  };

  const getAuthorName = (item: HistoryAuthorType) => {
    return getName(item) || item.email || '-';
  };

  const getChanges = (item: HistoryType) => item.changes || item.changesTranslation || item.historyChanges || [];

  const handleShowHTML = (e: React.MouseEvent<HTMLAnchorElement>, title: string, value: string) => {
    e.preventDefault();
    setShowHtml({ title, value });
  };

  const renderValue = (attr: string, value: string) => {
    return (
      <>
        {stripeHtml(value, 30)}
        <a href="/" onClick={(e) => handleShowHTML(e, attr, value)}>
          <img src={colorIcoEye} alt="show" className="ml-2" />
        </a>
      </>
    );
  };

  return (
    <>
      <Modal
        title={showHtml?.title || ''}
        isOpen={!!showHtml}
        className="w-max-500"
        onRequestClose={() => setShowHtml(undefined)}
      >
        <div dangerouslySetInnerHTML={{ __html: showHtml?.value || '' }} />
      </Modal>
      <div className="mt-4">
        {!!props.title && <h3 className="f-size-19 mb-20">{props.title}</h3>}
        {loaded ? (
          <>
            <div className="responsive-table-content">
              <Table className="text-left table-middle border-bottom-0" striped>
                <thead>
                  <tr>
                    {props.showUser && <th className="text-left w-15p">Uživatel</th>}
                    <th className="text-left w-15p">Pole</th>
                    <th className="text-left w-30p">Stará hodnota</th>
                    <th className="text-left w-30p">Nová hodnota</th>
                    <th className="text-right w-15p">Datum změny</th>
                  </tr>
                </thead>
                <tbody>
                  {!props.author && history.length < 1 && (
                    <tr>
                      <td className="text-left" colSpan={100}>
                        Nebyla nalezena žádná data.
                      </td>
                    </tr>
                  )}
                  {history.map((item, index) => (
                    <React.Fragment key={`history-${index}`}>
                      {getChanges(item).map((changeItem, changeIndex) => (
                        <tr key={`history-change-${changeIndex}`}>
                          {props.showUser && (
                            <td className="white-space-normal word-wrap-break-word">{getUserName(item)}</td>
                          )}
                          <td>{changeItem.translatedField}</td>
                          {isHTML((changeItem.translatedOldValue || changeItem.oldValue || '').toString()) ? (
                            <td className="white-space-normal word-wrap-break-word">
                              {renderValue(
                                changeItem.translatedField,
                                (changeItem.translatedOldValue || changeItem.oldValue || '').toString()
                              )}
                            </td>
                          ) : (
                            <td
                              className="white-space-normal word-wrap-break-word"
                              dangerouslySetInnerHTML={{
                                __html: (changeItem.translatedOldValue || changeItem.oldValue || '').toString(),
                              }}
                            />
                          )}
                          {isHTML((changeItem.translatedNewValue || changeItem.newValue || '').toString()) ? (
                            <td className="white-space-normal word-wrap-break-word">
                              {renderValue(
                                changeItem.translatedField,
                                (changeItem.translatedNewValue || changeItem.newValue || '').toString()
                              )}
                            </td>
                          ) : (
                            <td
                              className="white-space-normal word-wrap-break-word"
                              dangerouslySetInnerHTML={{
                                __html: (changeItem.translatedNewValue || changeItem.newValue || '').toString(),
                              }}
                            />
                          )}
                          <td className="text-right">{getDateTimeFormat(item.timeCreated, { withSeconds: true })}</td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                  {!!props.author && (
                    <tr>
                      <td className="white-space-normal word-wrap-break-word">{getAuthorName(props.author)}</td>
                      <td>Vytvořeno</td>
                      <td />
                      <td />
                      <td className="text-right">{!!props.dateCreated ? getDateTimeFormat(props.dateCreated) : ''}</td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
            {totalRows > perPage && (
              <Pagination
                page={currentPage}
                perPage={perPage}
                totalRows={totalRows}
                onPageChange={(page) => loadHistory(props.id, page)}
              />
            )}
          </>
        ) : (
          <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
            <BasePreloader />
          </div>
        )}
      </div>
    </>
  );
};

export default HistoryTable;
