import React from 'react';
import { Link } from 'react-router-dom';

import logo from '@assets/images/logo.svg';

const BoxCentered: React.FC = (props) => (
  <div className="component-box-centered m-auto">
    <div className="box-header">
      <Link to="/">
        <img src={logo} alt="logo" />
      </Link>
    </div>
    <div className="box-content">{props.children}</div>
  </div>
);

export default BoxCentered;
