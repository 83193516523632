import React, { useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import { PaymentResponse } from '@types';
import { useInvoiceApi } from '@api/invoice';
import { AdminBoxContent, BasePreloader, Pagination } from '@components';

import { InvoicesSections } from '../InvoicesPage';
import PaymentsItemsTable from './PaymentsItemsTable';
import PaymentsDataFilter from './PaymentsDataFilter';

const SectionPayments: React.FC = () => {
  const location = useLocation();
  const invoicesApi = useInvoiceApi();
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState<PaymentResponse[]>([]);
  const [totalRows, setTotalRows] = useState(0);
  const [query, setQuery] = useQueryParams({
    section: withDefault(StringParam, InvoicesSections.invoices),
    page: withDefault(NumberParam, 1),
    perPage: withDefault(NumberParam, 10),
    senderName: withDefault(StringParam, undefined),
    paymentType: withDefault(StringParam, undefined),
    variableSymbol: withDefault(StringParam, undefined),
    senderAccountNumber: withDefault(StringParam, undefined),
  });

  useEffect(() => {
    setQuery({ paymentType: 'none' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadData();
    return () => invoicesApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, query]);

  const loadData = async () => {
    try {
      const res = await invoicesApi.getPayments({ ...query });
      setData(res.data.data);
      setTotalRows(res.data.total || 0);
      setLoaded(true);
    } catch (err: any) {
      if (invoicesApi.isCancel(err)) {
        return;
      }
    }
  };

  const renderContent = () => {
    if (!loaded) {
      return (
        <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
          <BasePreloader />
        </div>
      );
    }

    return (
      <div>
        <PaymentsItemsTable data={data} reload={() => loadData()} />
        {totalRows > query.perPage && (
          <div className="mt-2">
            <Pagination
              page={query.page}
              perPage={query.perPage}
              totalRows={totalRows}
              onPageChange={(page) => setQuery({ ...query, page: page }, 'push')}
            />
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <PaymentsDataFilter
        values={query}
        onSubmit={(values) => setQuery({ ...values, page: undefined, perPage: undefined }, 'push')}
      />
      <AdminBoxContent>
        <Row>
          <Col xs={12}>{renderContent()}</Col>
        </Row>
      </AdminBoxContent>
    </>
  );
};

export default SectionPayments;
