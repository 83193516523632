import React from 'react';

import { User } from '@types';
import { RegistrationSteps, BaseFrontPage } from '@components';
import {
  FrontSidebarBanners,
  FrontSidebarMenu,
  FrontSidebarNotifications,
  FrontSidebarStatistics,
} from '@components/FrontSidebarBox';
import { useAuthFrontStore, userRoleChecks } from '@stores';

const RegistrationPage: React.FC = () => {
  const { user } = useAuthFrontStore();

  return (
    <BaseFrontPage
      page="/"
      sidebarItems={[
        <FrontSidebarMenu title="Dražby a aukce" />,
        <FrontSidebarNotifications title="Upozornění na Váš e-mail" />,
        <FrontSidebarStatistics title="Exdražby v číslech" />,
        <FrontSidebarBanners />,
      ]}
    >
      <RegistrationSteps
        defaultEmail={user?.email}
        skipBasicRegistration={userRoleChecks(user as User).isOfRoleUser()}
      />
    </BaseFrontPage>
  );
};

export default RegistrationPage;
