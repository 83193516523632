import React from 'react';
import { FormikValues, useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import Wysiwyg from '@components/Wysiwyg/Wysiwyg';

import { AuctionInformationsCType } from '@types';
import { useSettingsInformationsApi } from '@api/settingsInformations';
import { BasePreloader, FormGroup, Button } from '@components';

interface Props {
  data?: AuctionInformationsCType;
  onSubmit: () => void;
  onClose: () => void;
  onSave: () => void;
}

const InformationsForm: React.FC<Props> = (props) => {
  const settingsInformationsApi = useSettingsInformationsApi();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: props.data?.title || '',
      text: props.data?.text || '',
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = (values: FormikValues) => {
    props.data &&
      settingsInformationsApi
        .update(props.data?.id, values as AuctionInformationsCType)
        .then(() => {
          props.onSave();
        })
        .catch((err) => {
          if (settingsInformationsApi.isCancel(err)) {
            return;
          }
          const errors = err.response?.data?.errors || {};
          Object.getOwnPropertyNames(errors).map((prop) => {
            formik.setFieldError(prop, errors[prop][0]);
            return prop;
          });
          formik.setSubmitting(false);
        });
  };

  return (
    <div>
      <h2>{!!props.data ? 'Upravit službu' : 'Přidat službu'}</h2>
      <div>
        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-3 mt-md-5">
          <div className="responsive-table-content">
            <div className="pt-1">
              <FormGroup
                type="text"
                name="title"
                label="Název"
                labelClassName="text-left"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.errors.title}
                required
              />
              <Wysiwyg
                name="text"
                label="Text"
                required={true}
                error={formik.errors.text as string}
                value={formik.values.text}
                onChange={(val) => formik.setFieldValue('text', val)}
              />
            </div>
          </div>
          <div className="mt-5">
            <Button variant="btn-outline-default" className="float-left" type="button" onClick={() => props.onClose()}>
              Zrušit
            </Button>
            {!formik.isSubmitting ? (
              <Button
                type="submit"
                variant="btn-outline-primary"
                className="float-right"
                disabled={formik.isSubmitting}
              >
                Uložit informaci
              </Button>
            ) : (
              <BasePreloader size={29} className="d-inline-block float-right" />
            )}
            <div className="clearfix" />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default InformationsForm;
