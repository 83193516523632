import { Col, Container, Row } from 'react-bootstrap';

import * as routes from '@routes';

import { ContactUs } from '@components/Contact/ContactUs';
import { usePageStore } from '@stores';
import { IWantToSellItem } from './IWantToSellItem';
import { SEO } from '@components';

export const IWantToSell = () => {
  const pageState = usePageStore();
  const subPages = pageState.getPagesByParentPageId(pageState.getPage(routes.front.I_WANT_TO_SELL)?.id || 0);

  const sortedPages = subPages.sort((a, b) => {
    if (a.menuPosition === null) {
      return 1;
    } else if (b.menuPosition === null) {
      return -1;
    }
    return a.menuPosition - b.menuPosition;
  });

  return (
    <Container fluid>
      <SEO title="Chci prodat" />
      <div className="component-front-i-want-to-sell">
        <h1>Chci prodat</h1>
        <Row>
          {sortedPages.map((item, index) => (
            <IWantToSellItem key={`item-${index}`} item={item} />
          ))}

          <Col lg={6} md={12} />
        </Row>
        <ContactUs />
      </div>
    </Container>
  );
};
