import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import moment from 'moment-timezone';
import numeral from 'numeral';
import { ToastProvider } from 'react-toast-notifications';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import 'moment/locale/cs';

import App from './App';
import config from '@config';
import * as serviceWorker from './serviceWorker';

moment.tz.setDefault('Europe/Prague');

numeral.register('locale', 'cs', {
  delimiters: {
    thousands: ' ',
    decimal: '.',
  },
  abbreviations: {
    thousand: 'tis.',
    million: 'mil.',
    billion: 'bil.',
    trillion: 'trl.',
  },
  ordinal: (number) => {
    const rest = number % 10;
    return ~~((number % 100) / 10) === 1 ? 'tý' : rest === 1 ? 'ní' : rest === 2 ? 'hý' : rest === 3 ? 'tí' : 'tý';
  },
  currency: {
    symbol: 'Kč',
  },
});

numeral.locale('cs');

Modal.setAppElement('#root');

ReactDOM.render(
  <React.StrictMode>
    <ToastProvider>
      <GoogleReCaptchaProvider reCaptchaKey={config.reCaptchaKey}>
        <App />
      </GoogleReCaptchaProvider>
    </ToastProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
