import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import colorIcoDel from '@assets/images/color-ico-del.svg';
import colorIcoConf from '@assets/images/color-ico-conf.svg';
import { TextBlockType } from '@types';
import * as routes from '@routes';
import { usePageStore } from '@stores';
import { getDateTimeFormat } from '@helpers/datetime';

interface Props {
  data: Array<TextBlockType>;
  onDeleteClick: (item: TextBlockType) => void;
}

const TextBlocksTable: React.FC<Props> = (props) => {
  const pageState = usePageStore();

  const handleDeleteClick = (e: React.MouseEvent, item: TextBlockType) => {
    e.preventDefault();
    props.onDeleteClick(item);
  };

  return (
    <div>
      <div className="responsive-table-content">
        <Table className="table-middle" striped>
          <thead>
            <tr>
              <th>ID</th>
              <th>Název</th>
              <th>Slug</th>
              <th>Poslední úprava</th>
            </tr>
          </thead>
          <tbody>
            {props.data.map((item, index) => (
              <tr key={`list-item-${index}`}>
                <td className="text-left">{item.id}</td>
                <td className="text-left">{item.name}</td>
                <td className="text-left">{item.machineName}</td>
                <td className="text-left">{getDateTimeFormat(item.timeUpdate)}</td>
                <td className="text-right">
                  <Link
                    className="d-inline-flex align-items-center ml-4"
                    to={pageState.getPagePath(routes.admin.SETTINGS_TEXT_BLOCK_UPDATE, {
                      ':textBlockId': item.id.toString(),
                    })}
                  >
                    <img src={colorIcoConf} alt="ico" className="mr-2" />
                    Upravit
                  </Link>
                  <Link
                    className="d-inline-flex align-items-center ml-4"
                    to="/"
                    onClick={(e) => handleDeleteClick(e, item)}
                  >
                    <img src={colorIcoDel} alt="ico" className="mr-2" />
                    Smazat
                  </Link>
                </td>
              </tr>
            ))}
            {props.data.length < 1 && (
              <tr>
                <td className="text-left" colSpan={100}>
                  Nebyla nalezena žádná data.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default TextBlocksTable;
