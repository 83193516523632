import { EnumType, TimesEnumType } from '@types';
import { API, APIResponse, useApi } from '@api/api';

export const getTimesPath = () => `/enum/general/times`;
export const getStatesPath = () => `/enum/general/states`;
export const getCitizenshipPath = () => `/enum/user/citizenship`;
export const getPaymentTypesPath = () => `/enum/auction/payment-type`;
export const getExportPreviewImagesPath = () => `/enum/auction-export/auction-export-preview-images`;
export const getAuctionAuthorizationStatePath = () => `/enum/user/auction-authorization-state`;
export const getBuyerTypesPath = () => `/enum/user/buyer-type`;
export const getCanProtestTimePath = () => `/enum/auction/protest-time`;
export const getPreEmptionWeightPath = () => `/enum/user/auction-pre-emption-weight`;

export interface EnumAPI extends API {
  getTimes(): APIResponse<TimesEnumType>;
  getStates(): APIResponse<EnumType[]>;
  getPaymentTypes(): APIResponse<EnumType[]>;
  getExportPreviewImages(): APIResponse<EnumType[]>;
  getCitizenship(): APIResponse<EnumType[]>;
  getAuctionAuthorizationState(): APIResponse<EnumType[]>;
  getBuyersTypes(): APIResponse<EnumType[]>;
  getProtestTime(): APIResponse<EnumType[]>;
  getPreEmptionWeight(): APIResponse<EnumType[]>;
}

export const useEnumApi = (): EnumAPI => {
  const api = useApi();

  return {
    ...api,
    getTimes() {
      const cancelToken = api.prepareCancelToken('getTimes');
      return api.request.get(getTimesPath(), { cancelToken });
    },
    getStates() {
      const cancelToken = api.prepareCancelToken('getStates');
      return api.request.get(getStatesPath(), { cancelToken });
    },
    getPaymentTypes() {
      const cancelToken = api.prepareCancelToken('getPaymentTypes');
      return api.request.get(getPaymentTypesPath(), { cancelToken });
    },
    getExportPreviewImages() {
      const cancelToken = api.prepareCancelToken('getExportPreviewImages');
      return api.request.get(getExportPreviewImagesPath(), { cancelToken });
    },
    getCitizenship() {
      const cancelToken = api.prepareCancelToken('getCitizenship');
      return api.request.get(getCitizenshipPath(), { cancelToken });
    },
    getAuctionAuthorizationState() {
      const cancelToken = api.prepareCancelToken('getAuctionAuthorizationState');
      return api.request.get(getAuctionAuthorizationStatePath(), { cancelToken });
    },
    getBuyersTypes() {
      const cancelToken = api.prepareCancelToken('getBuyersTypes');
      return api.request.get(getBuyerTypesPath(), { cancelToken });
    },
    getProtestTime() {
      const cancelToken = api.prepareCancelToken('getCanProtestTime');
      return api.request.get(getCanProtestTimePath(), { cancelToken });
    },
    getPreEmptionWeight() {
      const cancelToken = api.prepareCancelToken('getPreEmptionWeight');
      return api.request.get(getPreEmptionWeightPath(), { cancelToken });
    },
  };
};
