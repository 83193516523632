import { Button } from '@components';
import * as routes from '@routes';
import { usePageStore } from '@stores';
import { AuctioneersWebResponse } from '@types';

type FrontAcutioneerItemProps = {
  auctioneer: AuctioneersWebResponse;
};

export const FrontAuctioneerItem = (props: FrontAcutioneerItemProps) => {
  const { auctioneer } = props;
  const pageState = usePageStore();
  const renderContent = () => {
    return (
      <>
        <div className="item-content">
          <div className="item-info">
            <h2 className="item-title">{auctioneer.auctioneerName}</h2>
            <div className="info">
              <div className="info-content">
                <div>
                  <p className="strong">Fakturační adresa</p>
                  <p>
                    {auctioneer.invoiceAddress?.street} {auctioneer.invoiceAddress?.houseNumber}
                  </p>
                  <p>{auctioneer.invoiceAddress?.city}</p>
                  <p>{auctioneer.invoiceAddress?.zipCode}</p>
                </div>
              </div>
              <div className="info-content">
                {auctioneer.phone && (
                  <p>
                    <strong>Telefon: </strong>
                    <a href={`tel:${auctioneer.phone}`}>{auctioneer.phone}</a>
                  </p>
                )}
                {auctioneer.email && (
                  <p>
                    <strong>E-mail: </strong>
                    {<a href={`mailto:${auctioneer.email}`}>{auctioneer.email}</a>}
                  </p>
                )}
                {auctioneer.website && (
                  <p>
                    <strong>WWW: </strong>
                    <a href={auctioneer.website}>{auctioneer.website}</a>
                  </p>
                )}
                {auctioneer.dataBox && (
                  <p>
                    <strong>Dátová schránka: </strong>
                    <span>{auctioneer.dataBox} </span>
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="item-action">
            <Button
              type="button"
              variant="btn-outline-primary"
              to={`${pageState.getPagePath(routes.front.AUCTIONEER_DETAIL, { ':id': auctioneer.id })}?status=prepared`}
            >
              Nabídka tohoto dražebníka
            </Button>
          </div>
        </div>
      </>
    );
  };

  return <div className="list-item">{renderContent()}</div>;
};
