import React from 'react';
import { useMediaApi } from '@api/media';

interface AuctionImageProps {
  hash: string;
  style?: {};
  size?: string;
}

const AuctionImage: React.FC<AuctionImageProps> = (props) => {
  const mediaApi = useMediaApi();
  const [imageUrl, setImageUrl] = React.useState('');

  React.useEffect(() => {
    loadImage(false, props.size);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.hash]);

  const loadImage = async (reinit: boolean, size?: string) => {
    try {
      const media = await mediaApi.detailFront(props.hash, true, false, size);
      const urlCreator = window.URL || window.webkitURL;
      setImageUrl(urlCreator.createObjectURL(media.data));
    } catch (err) {
      if (!!size && !reinit) {
        loadImage(true);
      }
    }
  };

  if (!imageUrl) {
    return <></>;
  }

  return <img style={{ ...props.style }} src={imageUrl} alt="auction" />;
};

export default AuctionImage;
