import React from 'react';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';

import * as routes from '@routes';
import { PageType } from '@types';
import { useAuthApi } from '@api/auth';
import { useAuthFrontStore, usePageContext, usePageStore } from '@stores';

import logoutIco from '@assets/images/front/header/logout.svg';
import lockColor16 from '@assets/images/front/icons/lock-color-16.svg';
import { getName } from '@helpers/string';

interface Props {
  onLogout: () => void;
}

const MobileLoggedUser: React.FC<Props> = (props) => {
  const history = useHistory();
  const authApi = useAuthApi();
  const pageState = usePageStore();
  const authFrontState = useAuthFrontStore();
  const user = authFrontState.user;
  const { activePage } = usePageContext();

  const items = [
    pageState.getPage(routes.front.ACCOUNT) as PageType,
    ...pageState.getPagesByParentPageId(pageState.getPage(routes.front.ACCOUNT)?.id || 0, false),
  ]
    .filter((page) => page)
    .map((page) => ({
      label: page.name,
      active: page.routeName === activePage?.routeName,
      link: pageState.getPagePath(page.routeName),
    }));

  const handleLogoutClick = async (e: React.MouseEvent) => {
    e.preventDefault();
    await authApi.logout(false);
    authFrontState.unsetUser();
    await pageState.reload();
    history.push('/');
    props.onLogout();
  };

  return (
    <div className="mobile-logged-user">
      <ul>
        <li>
          <a href="/" onClick={(e) => e.preventDefault()}>
            <img src={lockColor16} alt="ico" className="main-ico" />
            {getName(user) || user?.email}
          </a>
          <ul>
            {items.map((item, index) => (
              <li key={`menu-item-${index}`} className={classNames({ 'item-active': item.active })}>
                <Link to={item.link}>{item.label}</Link>
              </li>
            ))}
            <li className="no-arrow">
              <Link to="/" onClick={handleLogoutClick}>
                <img src={logoutIco} alt="ico" />
                Odhlásit se
              </Link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  );
};

export default MobileLoggedUser;
