import React from 'react';

import { useUsersApi } from '@api/users';
import { HistoryTable } from '@components';
import { User, HistoryAuthorType } from '@types';

interface Props {
  user?: User;
  dateCreated?: string;
  author?: HistoryAuthorType;
}

const UserHistory: React.FC<Props> = (props) => {
  const usersApi = useUsersApi();

  return (
    <div>
      <div>
        <h2>Historie</h2>
        {!!props.user && (
          <>
            <HistoryTable
              showUser
              id={props.user.id}
              api={usersApi.getHistory}
              dateCreated={props.dateCreated}
              author={props.author}
            />
            <HistoryTable showUser title="Adresy" id={props.user.id} api={usersApi.getAddressHistory} />
            <HistoryTable showUser title="Dokumenty" id={props.user.id} api={usersApi.getDocumentsHistory} />
          </>
        )}
      </div>
    </div>
  );
};

export default UserHistory;
