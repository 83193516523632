import React, { useState, MouseEvent } from 'react';
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';

import { PaymentResponse } from '@types';
import { getDateFormat } from '@helpers/datetime';
import { getBaseNumberFormat } from '@helpers/formats';
import PaymentNoteUpdateModal from './PaymentNoteUpdateModal';

import colorIcoEye from '@assets/images/color-ico-eye.svg';
import colorIcoConf from '@assets/images/color-ico-conf.svg';

type Props = {
  data: PaymentResponse[];
  reload: () => void;
};

const PaymentsItemsTable: React.FC<Props> = (props) => {
  const [openModal, setOpenModal] = useState<PaymentResponse | undefined>();

  const handleUpdateClick = (e: MouseEvent<HTMLAnchorElement>, item: PaymentResponse) => {
    e.preventDefault();
    setOpenModal(item);
  };

  const handleModalClose = (reload?: boolean) => {
    setOpenModal(undefined);
    if (reload) {
      props.reload();
    }
  };

  return (
    <>
      {openModal && <PaymentNoteUpdateModal item={openModal} onClose={handleModalClose} />}
      <div className="responsive-table-content">
        <Table className="table-middle" striped>
          <thead>
            <tr>
              <th>Číslo účtu</th>
              <th>
                Variabilní symbol
                <br />
                banka
              </th>
              <th>
                Variabilní symbol
                <br />
                faktura
              </th>
              <th>Dražebník</th>
              <th>Splatnost</th>
              <th>Částka</th>
              <th>Předmět platby</th>
              <th>Poznámka</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {props.data.map((item) => (
              <tr key={`item-${item.id}`}>
                <td>
                  {item.senderAccountNumber}/{item.senderBankCode}
                </td>
                <td>{item.variableSymbol || '-'}</td>
                <td>{item.variableSymbolOwn || '-'}</td>
                <td>{item.senderName}</td>
                <td>{getDateFormat(item.date)}</td>
                <td>
                  {getBaseNumberFormat(item.amount)} {item.currencyTranslation}
                </td>
                <td>{item.paymentTypeTranslation}</td>
                <td>
                  {item.note ? (
                    <OverlayTrigger
                      key={`${item.id}-note`}
                      placement="top"
                      overlay={<Tooltip id="table-permission">{item.note}</Tooltip>}
                    >
                      <span className="cursor-default">
                        <img src={colorIcoEye} alt="ico" className="mr-1" /> zobrazit
                      </span>
                    </OverlayTrigger>
                  ) : (
                    '-'
                  )}
                </td>
                <td className="text-right">
                  <a
                    href="/"
                    className="d-inline-flex align-items-center ml-4"
                    onClick={(e) => handleUpdateClick(e, item)}
                  >
                    <img src={colorIcoConf} alt="ico" className="mr-2" />
                    Upravit
                  </a>
                </td>
              </tr>
            ))}
            <tr>
              {props.data.length < 1 && (
                <td className="text-left" colSpan={100}>
                  Nebyla nalezena žádná data.
                </td>
              )}
            </tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default PaymentsItemsTable;
