import { BaseFrontPage } from '@components';
import {
  FrontSidebarBanners,
  FrontSidebarMenu,
  FrontSidebarNotifications,
  FrontSidebarStatistics,
} from '@components/FrontSidebarBox';

import { AboutUs } from '@components/AboutUs/AboutUs';

export const AboutUsPage = () => {
  return (
    <BaseFrontPage
      disableScrollToTop
      sidebarItems={[
        <FrontSidebarMenu title="Dražby a aukce" />,
        <FrontSidebarNotifications title="Upozornění na Váš e-mail" />,
        <FrontSidebarStatistics title="Exdražby v číslech" />,
        <FrontSidebarBanners />,
      ]}
    >
      <AboutUs />
    </BaseFrontPage>
  );
};
