import * as React from 'react';

import ProtestTable from './ProtestTable';
import { AuctionResponse, BaseObjectType } from '@types';

interface Props {
  auction: AuctionResponse;
  translations: BaseObjectType;
}

const ProtestSection: React.FC<Props> = (props) => {
  return (
    <div className="component-admin-box-content">
      <ProtestTable auction={props.auction} translations={props.translations} />
    </div>
  );
};

export default ProtestSection;
