import { LogResponse, LogsFilters } from '@types';
import _ from 'lodash';
import { API, APIResponse, useApi } from '@api/api';
import { useGridFilter } from '@stores/gridFilters';

export const getLogsPath = () => `/admin/log`;

export interface LogAPI extends API {
  getLogs(params: LogsFilters, sortField?: string, sortDirection?: 'asc' | 'desc'): APIResponse<Array<LogResponse>>;
}

export const useLogApi = (): LogAPI => {
  const api = useApi();

  const logsFilter = useGridFilter<LogsFilters>([
    {
      key: 'userId',
      property: 'user.id',
      type: 'int',
      value: (v) => (isNaN(v) ? undefined : Number(v)),
    },
    {
      key: 'auction',
      property: 'auctionLogView.auction',
      type: 'int',
      value: (v) => (isNaN(v) ? undefined : Number(v)),
    },
    {
      group: 'alpha',
      key: 'name',
      property: 'user.firstName',
      operator: 'like',
      splitKeyWords: true,
      value: (value) => {
        return _.isString(value) ? value.trim() : value;
      },
    },
    {
      group: 'alpha',
      key: 'name',
      property: 'user.lastName',
      operator: 'like',
      splitKeyWords: true,
      value: (value) => {
        return _.isString(value) ? value.trim() : value;
      },
    },
    {
      key: 'public',
      type: 'boolean',
      property: 'log.public',
      value: (value) => {
        if (!value) {
          return undefined;
        }
        return true;
      },
    },
    {
      group: 'role',
      property: 'user.role',
      key: 'roles',
      type: 'string',
    },
    {
      property: 'log.timeCreated',
      key: 'dateFrom',
      operator: 'gte',
    },
    {
      property: 'log.timeCreated',
      key: 'dateTo',
      operator: 'lte',
    },
  ]);

  return {
    ...api,
    getLogs(params: LogsFilters, sortField: string = 'log.id', sortDirection: 'asc' | 'desc' = 'desc') {
      const cancelToken = api.prepareCancelToken('getLogs');
      return api.authRequest.get(getLogsPath(), {
        cancelToken,
        params: {
          page: params.page,
          perPage: params.perPage,
          filter: logsFilter.isEmpty(params) ? undefined : logsFilter.toString(params),
          sort: JSON.stringify({
            property: sortField,
            direction: sortDirection,
          }),
        },
      });
    },
  };
};
