import React from 'react';

import { BasePreloader } from '@components';
import { AuctioneerUserResponse } from '@types';
import { useAuctioneersApi } from '@api/auctioneers';
import { closeMessage, confirmMessage } from '@helpers/messages';

import UsersForm from './UsersForm';
import UsersTable from './UsersTable';

interface Props {
  id: string;
  onNotFound: () => void;
}

const Users: React.FC<Props> = (props) => {
  const auctioneersApi = useAuctioneersApi();
  const [loaded, setLoaded] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);
  const [data, setData] = React.useState([] as Array<AuctioneerUserResponse>);
  const [updateItem, setUpdateItem] = React.useState(undefined as AuctioneerUserResponse | undefined);

  React.useEffect(() => {
    loadUsers();
    return () => auctioneersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadUsers = async () => {
    try {
      const res = await auctioneersApi.usersList(props.id);
      setData(res.data);
      setLoaded(true);
    } catch (err: any) {
      if (auctioneersApi.isCancel(err)) {
        return;
      }
      if (err?.response?.status === 404 || err?.response?.status === 403) {
        props.onNotFound();
        return;
      }
    }
  };

  const handleAddClick = () => {
    setUpdateItem(undefined);
    setShowForm(true);
  };

  const handleUpdateClick = (item: AuctioneerUserResponse) => {
    setUpdateItem(item);
    setShowForm(true);
  };

  const handleSave = () => {
    setShowForm(false);
    setUpdateItem(undefined);
    setLoaded(false);
    loadUsers();
  };

  const handleDeleteClick = (item: AuctioneerUserResponse) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat tuto položku?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return auctioneersApi.usersDelete(props.id, item.user.id.toString()).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadUsers();
      }
    });
  };

  const handleRestoreClick = (item: AuctioneerUserResponse) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete obnovit tuto položku?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return auctioneersApi.userRestore(props.id, item.user.id.toString()).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadUsers();
      }
    });
  };

  if (!loaded) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <div>
      {showForm ? (
        <UsersForm
          id={props.id}
          data={updateItem}
          onSave={handleSave}
          onClose={() => setShowForm(false)}
          onSubmit={() => setShowForm(false)}
        />
      ) : (
        <UsersTable
          data={data}
          onClickAdd={handleAddClick}
          onUpdateClick={handleUpdateClick}
          onDeleteClick={handleDeleteClick}
          onRestoreClick={handleRestoreClick}
        />
      )}
    </div>
  );
};

export default Users;
