import React from 'react';
import cx from 'classnames';

import colorIcoCheck from '@assets/images/color-ico-check.svg';

interface Props {
  index: number;
  title: string;
  text: React.ReactNode;
  isActive: boolean;
  isNext?: boolean;
  completeTestId?: string;
  renderAction?: () => React.ReactNode;
}

const AccountBlock: React.FC<Props> = (props) => {
  const renderAction = () => {
    if (props.isActive) {
      return (
        <div>
          <img
            alt="check"
            width="21px"
            src={colorIcoCheck}
            style={{ marginRight: '8px' }}
            data-test-id={props.completeTestId}
          />
          Aktivní
        </div>
      );
    }

    if (typeof props.renderAction === 'function') {
      return props.renderAction();
    }
  };

  return (
    <div className={cx('account-state-block', { isActive: props.isActive })}>
      <div className="block-index">{props.index}</div>
      <div className="block-content">
        <div className="block-content-title">{props.title}</div>
        <div className="block-content-text">{props.text}</div>
      </div>
      <div className="block-action">{renderAction()}</div>
    </div>
  );
};

export default AccountBlock;
