import React from 'react';
import colorIcoEdit from '@assets/images/color-ico-edit.svg';
import icoCloseGrey from '@assets/images/ico-close-grey.svg';
import colorIcoCheck from '@assets/images/color-ico-check.svg';
import { useAuctionsApi } from '@api/auctions';

interface Props {
  auctionId: string;
  documentId: string;
  name: string;
}

const AuctionDocumentEdit: React.FC<Props> = (props) => {
  const auctionsApi = useAuctionsApi();
  const [beingEdited, setBeingEdited] = React.useState<boolean>(false);
  const [newTitle, setNewTitle] = React.useState<string>(props.name);

  const renameDocument = async () => {
    if (newTitle.length > 0) {
      try {
        await auctionsApi.renameDocument(props.auctionId, props.documentId, newTitle);
        setBeingEdited(false);
      } catch (err) {
        setNewTitle(props.name);
        setBeingEdited(false);
      }
    }
  };

  return (
    <div className="auction-document-edit">
      {!beingEdited && (
        <>
          <img
            src={colorIcoEdit}
            alt="ico"
            className="mr-2"
            onClick={() => setBeingEdited((prevState) => !prevState)}
          />
          {newTitle}
        </>
      )}
      {beingEdited && (
        <>
          <input className="edit-input" value={newTitle} onChange={(e) => setNewTitle(e.target.value)} />
          <img
            src={colorIcoCheck}
            alt="ico"
            className="mr-2"
            onClick={() => {
              renameDocument();
              setBeingEdited(false);
            }}
          />
          <img
            src={icoCloseGrey}
            alt="ico"
            className="mr-2"
            onClick={() => {
              setNewTitle(props.name);
              setBeingEdited(false);
            }}
          />
        </>
      )}
    </div>
  );
};

export default AuctionDocumentEdit;
