import React from 'react';
import { stringify } from 'query-string';
import { useAuctionsListWebsocket } from '@websocket';
import { useHistory, useLocation } from 'react-router-dom';
import { useQueryParams, withDefault, ArrayParam, StringParam, QueryParamConfig, NumberParam } from 'use-query-params';

import * as routes from '@routes';
import { useAuthApi } from '@api/auth';
import { useWindowFocus } from '@hooks';
import { useAuctionsApi } from '@api/auctions';
import { AuctionMapResponse, AuctionResponse, AuctionStatus, WebAuctionsFilter } from '@types';
import { authFrontStore, useAuthFrontStore, usePageStore } from '@stores';
import { BaseFrontPage, FrontMainFilter, FrontBannerNavigation, FrontActionsList, Button } from '@components';
import {
  FrontSidebarBanners,
  FrontSidebarMenu,
  FrontSidebarNotifications,
  FrontSidebarStatistics,
} from '@components/FrontSidebarBox';

import iconHammerColor19 from '@assets/images/front/icons/hammer-color-19.svg';
import iconHammerWhite19 from '@assets/images/front/icons/hammer-white-19.svg';
import iconHammerWhite90 from '@assets/images/front/icons/hammer-white-90.svg';
import iconHammerColor90 from '@assets/images/front/icons/hammer-color-90.svg';
import iconDocumentColor83 from '@assets/images/front/icons/document-color-83.svg';
import iconDocumentWhite83 from '@assets/images/front/icons/document-white-83.svg';
import iconTabsCheckColor79 from '@assets/images/front/icons/tabs-check-color-79.svg';
import iconTabsCheckWhite79 from '@assets/images/front/icons/tabs-check-white-79.svg';

const HomePage: React.FC = () => {
  const perPage = 6;
  const authApi = useAuthApi();
  const history = useHistory();
  const location = useLocation();
  const authFrontState = useAuthFrontStore();
  const auctionsApi = useAuctionsApi();
  const pageState = usePageStore();
  const [joinedLoaded, setJoinedLoaded] = React.useState<string | number | undefined>();
  const [showFiltersPreloader, setShowFiltersPreloader] = React.useState(true);
  const [loaded, setLoaded] = React.useState(false);
  const [auctions, setAuctions] = React.useState<AuctionResponse[]>([]);
  const [allAuctions, setAllAuctions] = React.useState<AuctionMapResponse[]>([]);
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    title: withDefault(StringParam, ''),
    priceTo: withDefault(StringParam, ''),
    priceFrom: withDefault(StringParam, ''),
    mainCategory: withDefault(StringParam, '0'),
    auctionType: withDefault(StringParam, ''),
    subCategories: withDefault(ArrayParam, []) as QueryParamConfig<string[]>,
    regions: withDefault(ArrayParam, []) as QueryParamConfig<string[]>,
    districts: withDefault(ArrayParam, []) as QueryParamConfig<string[]>,
    districtsParts: withDefault(ArrayParam, []) as QueryParamConfig<string[]>,
  });

  useAuctionsListWebsocket({
    onMessage: () => loadData(true),
  });

  useWindowFocus({
    onFocus: () => {
      loadData(true);
    },
  });

  React.useEffect(() => {
    loadData();
    return () => {
      auctionsApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authFrontState.user, location.search]);

  const loadData = async (isTimer?: boolean) => {
    if (!isTimer) {
      setShowFiltersPreloader(true);
    }
    if (!(await loadMapData({ ...query, status: 'prepared' }))) {
      return;
    }
    setShowFiltersPreloader(false);
    if (!(await loadAuctions())) {
      return;
    }
  };

  const loadMapData = async (filterProps?: WebAuctionsFilter) => {
    try {
      const requestProps = !!filterProps ? { ...filterProps } : {};
      requestProps.mainCategory = requestProps.mainCategory !== '0' ? requestProps.mainCategory : undefined;
      requestProps.status = 'prepared';
      const response = await auctionsApi.getWebMapAuctions(requestProps);
      setAllAuctions(response.data.data);
      return true;
    } catch (err) {
      if (!err.response) {
        return false;
      }
      setLoaded(true);
      return true;
    }
  };

  const loadAuctions = async (filterProps?: WebAuctionsFilter): Promise<boolean> => {
    if (authFrontStore.getState().isLogged) {
      try {
        await authApi.logged(true);
      } catch (err) {}
    }
    try {
      const requestProps = !!filterProps ? { ...filterProps } : { page: 1, perPage };
      requestProps.perPage = requestProps.perPage || perPage;
      requestProps.status = AuctionStatus.prepared;
      requestProps.mainCategory = requestProps.mainCategory !== '0' ? requestProps.mainCategory : undefined;
      const response = await auctionsApi.getWebAuctions(requestProps, 'timeCreated', 'DESC');
      setAuctions(response.data.data);
      setLoaded(true);
      return true;
    } catch (err) {
      if (!err.response) {
        return false;
      }
      setLoaded(true);
      return true;
    }
  };

  const handleAuctionJoin = async (auction: AuctionResponse) => {
    if (!!joinedLoaded) {
      return;
    }
    setJoinedLoaded(auction.id);
    try {
      await auctionsApi.joinAuction(auction.id);
      setJoinedLoaded(undefined);
      loadAuctions(query);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      setJoinedLoaded(undefined);
    }
  };

  const handleAuctionIsFavouriteChange = async (auction: AuctionResponse, isFavorite: boolean) => {
    try {
      if (isFavorite) {
        await auctionsApi.addAuctionToFavourites(auction.id);
      } else {
        await auctionsApi.removeAuctionFromFavourites(auction.id);
      }
      loadAuctions(query);
    } catch (err) {}
  };

  return (
    <BaseFrontPage
      page="/"
      disableScrollToTop
      sidebarItems={[
        <FrontSidebarMenu title="Dražby a aukce" />,
        <FrontSidebarNotifications title="Upozornění na Váš e-mail" />,
        <FrontSidebarStatistics title="Exdražby v číslech" />,
        <FrontSidebarBanners />,
      ]}
    >
      <FrontMainFilter
        withMap
        mobileToggle
        query={query}
        auctions={allAuctions}
        auctionsLoaded={loaded}
        itemsCount={allAuctions.length}
        showPreloader={showFiltersPreloader}
        onSearch={(props, submit) =>
          submit
            ? history.push(`${pageState.getPagePath(routes.front.AUCTION_LIST_PREPARED)}?${stringify(props)}`)
            : setQuery(props, 'push')
        }
      />
      <div className="d-flex d-lg-block flex-column-reverse">
        <FrontBannerNavigation
          xs={12}
          sm={6}
          md={4}
          lg={4}
          xl={4}
          items={[
            {
              icon: iconHammerColor90,
              iconActive: iconHammerWhite90,
              btnClassName: 'btn-small',
              btnText: 'Kontaktujte nás',
              text: 'Chcete prodat nemovitost nebo movitý majetek v aukci či dražbě?',
              onClick: () => history.push(pageState.getPagePath(routes.front.CONTACT)),
            },
            {
              icon: iconDocumentColor83,
              iconActive: iconDocumentWhite83,
              btnClassName: 'btn-small',
              btnText: 'Kontaktujte nás',
              text: 'Potřebujete poradit jak se zúčastnit dražby nebo aukce?',
              onClick: () => history.push(pageState.getPagePath(routes.front.CONTACT)),
            },
            {
              icon: iconTabsCheckColor79,
              iconActive: iconTabsCheckWhite79,
              btnClassName: 'btn-small',
              btnText: 'Kontaktujte nás',
              text: 'Chcete využívat náš systém pro realizaci svých dražeb a aukcí?',
              onClick: () => history.push(pageState.getPagePath(routes.front.CONTACT)),
            },
          ]}
        />
        <div>
          <FrontActionsList
            title="Nejnovější dražby a aukce"
            auctions={auctions}
            isLoading={!loaded}
            joinedLoaded={joinedLoaded}
            onAuctionJoin={handleAuctionJoin}
            onAuctionIsFavouriteChange={handleAuctionIsFavouriteChange}
          />
          {loaded && auctions.length > 0 && (
            <div className="mb-5 mt-4 d-none d-lg-block">
              <Button
                grayBg
                type="button"
                variant="btn-outline-primary"
                onClick={() => history.push(pageState.getPagePath(routes.front.AUCTION_LIST_PREPARED))}
              >
                <img src={iconHammerColor19} alt="icon" className="mr-13 hover-hide" />
                <img src={iconHammerWhite19} alt="icon" className="mr-13 hover-show" />
                Další dražby a aukce
              </Button>
            </div>
          )}
        </div>
      </div>
    </BaseFrontPage>
  );
};

export default HomePage;
