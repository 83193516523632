import React, { useState } from 'react';

import { BuyerDocument } from '@types';
import { useUsersApi } from '@api/users';
import { BasePreloader, Button, Modal, Select } from '@components';

type OptionValue = {
  label: string;
  value: string;
};

type Props = {
  documentTypes: OptionValue[];
  buyerDocument?: BuyerDocument;
  onClose?: () => void;
  onSuccess: () => void;
};

export const DocumentTypeModal: React.FC<Props> = (props) => {
  const usersApi = useUsersApi();
  const [saved, setSaved] = useState(true);
  const [currentType, setCurrentType] = useState(props.buyerDocument?.type);

  const handleSubmit = async () => {
    if (!currentType || !props.buyerDocument) {
      return;
    }
    setSaved(false);
    try {
      await usersApi.updateUserDocumentType(props.buyerDocument.id, currentType);
      setSaved(true);
      props.onSuccess();
    } catch (err) {
      if (usersApi.isCancel(err)) {
        return;
      }
      setSaved(true);
    }
  };

  return (
    <Modal noPadding title="Úprava typu dokumentu" isOpen={!!props.buyerDocument} onRequestClose={props.onClose}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit().then();
        }}
      >
        <div className="px-3 py-4">
          <Select
            options={props.documentTypes}
            value={props.documentTypes.find((i) => i.value === currentType) || null}
            onChange={(val) => setCurrentType(val?.value)}
          />
          <div className="mt-4 d-flex justify-content-end">
            {saved ? <Button type="submit">Uložit</Button> : <BasePreloader size={35} />}
          </div>
        </div>
      </form>
    </Modal>
  );
};
