import React from 'react';
import { Formik, FormikValues } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import { useToasts } from 'react-toast-notifications';

import { useAuthStore } from '@stores';
import { BasePage, BoxCentered, Button } from '@components';

const MainLoggedForm: React.FC = () => {
  const { addToast } = useToasts();
  const authState = useAuthStore();

  const handleFormSubmit = (values: FormikValues, setSubmitting: (isSubmitting: boolean) => void) => {
    if (authState.mainLoggedPasswd !== values.password) {
      addToast('Přihlášení se nezdařilo', { appearance: 'error', autoDismiss: true });
    } else {
      authState.setMainLogged();
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        password: '',
      }}
      onSubmit={(values, { setSubmitting }) => handleFormSubmit(values, setSubmitting)}
    >
      {(formProps) => (
        <BasePage className="d-flex justify-content-center align-items-center min-vh-100 p-2">
          <BoxCentered>
            <p>Stránky nejsou prozatím přístupné veřejnosti, zadejte prosím univerzální přístupové heslo.</p>
            <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formProps.handleSubmit(e)}>
              <Form.Group>
                <Form.Label>Heslo</Form.Label>
                <Form.Control
                  required
                  type="password"
                  name="password"
                  data-test-id="developer-login-password"
                  value={formProps.values.password}
                  disabled={formProps.isSubmitting}
                  onChange={formProps.handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-0">
                <Row className="align-items-center mt-4 pt-2">
                  <Col xs={12}>
                    <Button
                      type="submit"
                      variant="btn-outline-primary"
                      disabled={formProps.isSubmitting}
                      dataTestId="developer-login-button"
                    >
                      Přihlásit se
                    </Button>
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </BoxCentered>
        </BasePage>
      )}
    </Formik>
  );
};

export default MainLoggedForm;
