import React from 'react';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { pageStore, usePageContext, usePageStore } from '@stores';

interface Props {
  title?: string;
  page?: string;
  className?: string;
  hideSidebar?: boolean;
  disableScrollToTop?: boolean;
  sidebarItems?: Array<React.ReactNode>;
  printContent?: React.ReactNode;
}

const BaseFrontPage: React.FC<Props> = (props) => {
  const history = useHistory();
  const pageState = usePageStore();
  const { setActivePage } = usePageContext();

  React.useEffect(() => {
    pageStore.setState({ page: props.page });
    setActivePage(props.page ? pageState.getPage(props.page) : undefined);
    if (!props.disableScrollToTop) {
      window.scrollTo(0, 0);
    }
    const historyChangeUnlistener = history.listen(() => {
      if (!props.disableScrollToTop) {
        window.scrollTo(0, 0);
      }
    });
    return () => {
      if (!props.disableScrollToTop) {
        window.scrollTo(0, 0);
      }
      historyChangeUnlistener();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  return (
    <>
      <div className={classNames(['front-page-wrapper', 'min-vh-100', props.className])}>
        <Container fluid className="d-flex front-main-container">
          {!props.hideSidebar && (
            <div className="front-page-sidebar">
              {props.sidebarItems?.map((item, index) => (
                <React.Fragment key={`sidebar-item-${index}`}>{item}</React.Fragment>
              ))}
            </div>
          )}
          <div className="front-page-content">{props.children}</div>
        </Container>
      </div>
      {props.printContent && <div className="print-container">{props.printContent}</div>}
    </>
  );
};

export default BaseFrontPage;
