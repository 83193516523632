import React from 'react';
import _ from 'lodash';
import { ValueType } from 'react-select';
import { useLocation } from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { Auctioneer, LogsFilters, StatisticFilters, StatisticGraphEnumType, StatisticYearsType } from '@types';
import { Select, AdminFiltersBox } from '@components';

import FiltersInputs from '@components/AdminFiltersBox/components/FiltersInputs';
import FiltersSubmit from '@components/AdminFiltersBox/components/FiltersSubmit';

interface Props {
  values: StatisticFilters;
  graphs: StatisticGraphEnumType[];
  years: StatisticYearsType;
  auctioneers: Auctioneer[];
  onSubmit: (values: StatisticFilters) => void;
}

interface SelectOptionType {
  value: string;
  label: string;
}

const Filters: React.FC<Props> = (props) => {
  const pageState = usePageStore();
  const location = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: props.values,
    onSubmit: (values) => handleSubmit(values),
  });

  const yearsOptions = _.isEmpty(props.years)
    ? null
    : Object.getOwnPropertyNames(props.years).map((prop) => ({
        value: prop,
        label: props.years[prop],
      }));

  const graphOptions = props.graphs.map((item) => ({
    value: item.type,
    label: item.translated,
  }));

  const AuctioneersOptions = props.auctioneers.map((i) => ({ value: i.id.toString(), label: i.auctioneerName }));

  React.useEffect(() => {
    setIsOpen(false);
    formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values]);

  const handleSubmit = (values: FormikValues) => {
    const formValues = { ...values };
    Object.getOwnPropertyNames(formValues).map((prop) => {
      if (formValues[prop] === '') {
        formValues[prop] = undefined;
      }
      return prop;
    });
    props.onSubmit(formValues as LogsFilters);
  };

  const handleYearChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    formik.setFieldValue('year', itemValue?.value);
  };

  const handleGraphChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    formik.setFieldValue('graph', itemValue?.value);
  };

  const handleAuctioneerChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    formik.setFieldValue('auctioneer', itemValue?.value);
  };

  const hasFilters = (): boolean => {
    return !!location.search;
  };

  return (
    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)}>
      <AdminFiltersBox isOpen={isOpen}>
        <FiltersInputs>
          <Row>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Typ grafu</Form.Label>
                <Select
                  options={graphOptions}
                  value={
                    graphOptions ? graphOptions.find((i) => i.value === String(formik.values.graph)) || null : null
                  }
                  onChange={(val) => handleGraphChange(val as SelectOptionType)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Rok</Form.Label>
                <Select
                  options={yearsOptions}
                  value={yearsOptions ? yearsOptions.find((i) => i.value === String(formik.values.year)) || null : null}
                  onChange={(val) => handleYearChange(val as SelectOptionType)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Dražebník</Form.Label>
                <Select
                  isClearable={true}
                  options={AuctioneersOptions}
                  value={AuctioneersOptions.find((i) => i.value === String(formik.values.auctioneer)) || null}
                  onChange={(val) => handleAuctioneerChange(val as SelectOptionType)}
                />
              </Form.Group>
            </Col>
          </Row>
        </FiltersInputs>
        <FiltersSubmit
          hasFilters={hasFilters()}
          onOpenClick={() => setIsOpen(true)}
          clearUrl={pageState.getPagePath(routes.admin.STATISTICS)}
        />
      </AdminFiltersBox>
    </Form>
  );
};

export default Filters;
