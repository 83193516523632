import { API, APIResponse, BaseAPIResponse, useApi } from '@api/api';
import { AppStatistics, ServerTimeResponse, SystemInfo } from '@types';

export const getServerTimePath = () => `/web/server-time`;
export const getSystemInfoPath = () => `/system/info`;
export const getAppStatisticsPath = () => `/web/real-numbers`;

export interface ApplicationAPI extends API {
  getServerTime(): APIResponse<ServerTimeResponse>;
  getSystemInfo(): BaseAPIResponse<SystemInfo>;
  getAppStatistics(): BaseAPIResponse<AppStatistics>;
}

export const useApplicationApi = (): ApplicationAPI => {
  const api = useApi();

  return {
    ...api,
    getServerTime() {
      const cancelToken = api.prepareCancelToken('getServerTime');
      return api.request.get(getServerTimePath(), { cancelToken });
    },
    getSystemInfo() {
      const cancelToken = api.prepareCancelToken('getSystemInfo');
      return api.request.get(getSystemInfoPath(), { cancelToken });
    },
    getAppStatistics() {
      const cancelToken = api.prepareCancelToken('getAppStatistics');
      return api.request.get(getAppStatisticsPath(), { cancelToken });
    },
  };
};
