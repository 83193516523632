import { API, APIResponse, BaseAPIResponse, useApi } from '@api/api';
import { AuctionProtocolListResponse, PaginationParams } from '@types';

const protocolListPath = (auctionId: string) => `/admin/auction/${auctionId}/protocol/list`;
const protocolGeneratePath = (auctionId: string) => `/admin/auction/${auctionId}/protocol/generate`;
const protocolDownloadPath = (auctionId: string, protocolId: string) =>
  `/admin/auction/${auctionId}/protocol/download/${protocolId}`;

export interface AuctionProtocolAPI extends API {
  protocolList(auctionId: string, params?: PaginationParams): APIResponse<AuctionProtocolListResponse[]>;
  protocolGenerate(auctionId: string): APIResponse;
  protocolDownload(auctionId: string, protocolId: string): BaseAPIResponse<File>;
}

export const useAuctionProtocolApi = (
  errorToastDisable?: boolean,
  successToastDisabled?: boolean
): AuctionProtocolAPI => {
  const api = useApi(errorToastDisable, successToastDisabled);

  return {
    ...api,
    protocolList(auctionId: string, params?: PaginationParams) {
      const cancelToken = this.prepareCancelToken('protocolList');
      return api.authRequest.get(protocolListPath(auctionId), {
        cancelToken,
        params: {
          ...params,
          sort: JSON.stringify({ property: 'auctionProtocol.id', direction: 'DESC' }),
        },
      });
    },
    protocolGenerate(auctionId: string) {
      const cancelToken = this.prepareCancelToken('protocolGenerate');
      return api.authRequest.get(protocolGeneratePath(auctionId), { cancelToken });
    },
    protocolDownload(auctionId: string, protocolId: string) {
      const cancelToken = this.prepareCancelToken('protocolDownload');
      return api.authRequest.get(protocolDownloadPath(auctionId, protocolId), {
        cancelToken,
        responseType: 'blob',
      });
    },
  };
};
