import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import { Button, Visible } from '@components';
import { getCurrencyFormat, getPercentageFormat } from '@helpers/formats';
import { PermissionGroup, ServiceForAuctioneer, UserPermission } from '@types';

import ServiceDetailModal from './ServiceDetailModal';

import plusIco from '@assets/images/plus-ico.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';
import colorIcoDel from '@assets/images/color-ico-del.svg';
import colorIcoConf from '@assets/images/color-ico-conf.svg';
import colorIcoRestore from '@assets/images/color-ico-restore.svg';
import colorIcoCheck from '@assets/images/color-ico-check.svg';

interface Props {
  data: Array<ServiceForAuctioneer>;
  onClickAdd: () => void;
  onUpdateClick: (item: ServiceForAuctioneer) => void;
  onDeleteClick: (item: ServiceForAuctioneer) => void;
  onRestoreClick: (item: ServiceForAuctioneer) => void;
  onActivateForAllClick: (item: ServiceForAuctioneer) => void;
}

const ServicesTable: React.FC<Props> = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const handleCreateButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    props.onClickAdd();
  };

  const handleActivateForAllClick = (e: React.MouseEvent, item: ServiceForAuctioneer) => {
    e.preventDefault();
    props.onActivateForAllClick(item);
  };

  const handleUpdateClick = (e: React.MouseEvent, item: ServiceForAuctioneer) => {
    e.preventDefault();
    props.onUpdateClick(item);
  };

  const handleDeleteClick = (e: React.MouseEvent, item: ServiceForAuctioneer) => {
    e.preventDefault();
    props.onDeleteClick(item);
  };

  const handleRestoreClick = (e: React.MouseEvent, item: ServiceForAuctioneer) => {
    e.preventDefault();
    props.onRestoreClick(item);
  };

  return (
    <div>
      <Visible permissionGroupName={PermissionGroup.priceList} permissionName={UserPermission.canCreate}>
        <div>
          <h2>Ceník</h2>
          <Button onClick={handleCreateButtonClick} className="mt-3">
            <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
            <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
            Přidat službu
          </Button>
        </div>
      </Visible>
      <div className="mt-4">
        <div className="responsive-table-content">
          <Table className="table-middle border-bottom-0" striped responsive>
            <thead>
              <tr>
                <th>Název</th>
                <th>Doporučená cena</th>
                <th>DPH</th>
                <th>Stav</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {props.data.length < 1 && (
                <tr>
                  <td className="text-left" colSpan={100}>
                    Nebyla nalezena žádná data.
                  </td>
                </tr>
              )}
              {props.data.map((item, index) => (
                <tr key={`item-${index}`}>
                  <td className="text-left">{item.serviceTypeTranslation || '-'}</td>
                  <td className="text-left">
                    {item.serviceType === 'electronic_auction' ? (
                      <span className="admin-service-link" onClick={() => setModalOpen(true)}>
                        Dle ceníku
                      </span>
                    ) : (
                      `${getCurrencyFormat(item.price)}`
                    )}
                  </td>
                  <td className="text-left">{getPercentageFormat(item.vat)}</td>
                  <td className="text-left">{item.stateTranslation}</td>
                  <td className="text-right">
                    {item.state === 'active' && (
                      <Visible permissionGroupName={PermissionGroup.priceList} permissionName={UserPermission.canEdit}>
                        <Link
                          to="/"
                          className="d-inline-flex align-items-center"
                          onClick={(e) => handleActivateForAllClick(e, item)}
                        >
                          <img src={colorIcoCheck} alt="ico" className="mr-2" />
                          Aktivovat&nbsp;všem
                        </Link>
                      </Visible>
                    )}
                    <Visible permissionGroupName={PermissionGroup.priceList} permissionName={UserPermission.canEdit}>
                      <Link
                        to="/"
                        className="d-inline-flex align-items-center ml-4"
                        onClick={(e) => handleUpdateClick(e, item)}
                      >
                        <img src={colorIcoConf} alt="ico" className="mr-2" />
                        Upravit
                      </Link>
                    </Visible>
                    {item.state !== 'deleted' ? (
                      <Visible
                        permissionGroupName={PermissionGroup.priceList}
                        permissionName={UserPermission.canDelete}
                      >
                        <Link
                          to="/"
                          className="d-inline-flex align-items-center ml-4"
                          onClick={(e) => handleDeleteClick(e, item)}
                        >
                          <img src={colorIcoDel} alt="ico" className="mr-2" />
                          Smazat
                        </Link>
                      </Visible>
                    ) : (
                      <Visible
                        permissionGroupName={PermissionGroup.priceList}
                        permissionName={UserPermission.canCreate}
                      >
                        <Link
                          to="/"
                          className="d-inline-flex align-items-center ml-4"
                          onClick={(e) => handleRestoreClick(e, item)}
                        >
                          <img src={colorIcoRestore} alt="ico" className="mr-2" />
                          Obnovit
                        </Link>
                      </Visible>
                    )}
                  </td>
                  {item.serviceType === 'electronic_auction' && (
                    <ServiceDetailModal
                      isOpen={modalOpen}
                      data={item}
                      actionText="Zavřít"
                      onAction={() => setModalOpen(false)}
                      title="Detail ceníku"
                      onRequestClose={() => setModalOpen(false)}
                    />
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default ServicesTable;
