import React from 'react';

import { PermissionGroup } from '@types';
import { pageStore, usePageStore, usePageContext } from '@stores';
import { Button } from '@components';

interface Props {
  id?: string;
  title?: string | React.ReactElement;
  customTitle?: string | React.ReactElement;
  page?: string;
  className?: string;
  permissionGroup?: PermissionGroup;
  backUrl?: string;
  backText?: string;
  backCustomContent?: React.ReactElement;
}

const BasePage: React.FC<Props> = (props) => {
  const pageState = usePageStore();
  const { setActivePage } = usePageContext();

  React.useEffect(() => {
    pageStore.setState(props);
    setActivePage(props.page ? pageState.getPage(props.page) : undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.page]);

  return (
    <div className={props.className} id={props.id}>
      <div className="page-title-with-back-button">
        {props.title && (
          <div className="page-title-content">
            <h1>{props.title}</h1>
          </div>
        )}
        {props.customTitle && <div className="custom-page-title-content">{props.customTitle}</div>}
        {props.backUrl && (
          <Button grayBg to={props.backUrl}>
            {props.backText || `Zpět`}
          </Button>
        )}
        {props.backCustomContent}
      </div>
      {props.children}
    </div>
  );
};

export default BasePage;
