import React, { FC } from 'react';
import _ from 'lodash';
import { AuctionFieldsEnabled, AuctionParameterType } from '@types';
import { FormikErrors, FormikValues } from 'formik';
import { RealEstateFlatForm } from './RealEstateFlatForm';
import { RealEstateHouseForm } from './RealEstateHouseForm';
import { RealEstateLandForm } from './RealEstateLandForm';
import { VehicleCarForm } from './VehicleCarForm';
import { VehicleMotorcycleForm } from './VehicleMotorcycleForm';
import { VehicleOtherForm } from './VehicleOtherForm';
import { RealEstateCommercialForm } from './RealEstateCommercialForm';
import { RealEstateOtherForm } from './RealEstateOtherForm';

export interface AuctionParameterTypeProps {
  id?: string;
  values: FormikValues;
  isSubmitting: boolean;
  fieldsEnabled?: AuctionFieldsEnabled;
  requiredParametersFields: string[];
  errors: FormikErrors<FormikValues>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  auctionParameterType: AuctionParameterType;
}

export type AuctionParameterForm = {
  values: FormikValues;
  isSubmitting: boolean;
  readOnly?: boolean;
  requiredParametersFields: string[];
  errors: FormikErrors<FormikValues>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AuctionParameterForm: FC<AuctionParameterTypeProps> = (props) => {
  const { values, isSubmitting, errors, setFieldValue, handleSubmit, handleChange, auctionParameterType } = props;

  switch (auctionParameterType) {
    case 'real_estate_house':
      return (
        <RealEstateHouseForm
          values={values}
          errors={errors}
          isSubmitting={isSubmitting}
          requiredParametersFields={props.requiredParametersFields}
          readOnly={!_.get(props.fieldsEnabled, 'auctionParameterRealEstateHouse', true)}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setFieldValue={setFieldValue}
        />
      );
    case 'real_estate_flat':
      return (
        <RealEstateFlatForm
          values={values}
          errors={errors}
          isSubmitting={isSubmitting}
          requiredParametersFields={props.requiredParametersFields}
          readOnly={!_.get(props.fieldsEnabled, 'auctionParameterRealEstateFlat', true)}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setFieldValue={setFieldValue}
        />
      );
    case 'real_estate_land':
      return (
        <RealEstateLandForm
          values={values}
          errors={errors}
          isSubmitting={isSubmitting}
          requiredParametersFields={props.requiredParametersFields}
          readOnly={!_.get(props.fieldsEnabled, 'auctionParameterRealEstateLand', true)}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setFieldValue={setFieldValue}
        />
      );
    case 'real_estate_commercial':
      return (
        <RealEstateCommercialForm
          values={values}
          errors={errors}
          isSubmitting={isSubmitting}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setFieldValue={setFieldValue}
          requiredParametersFields={props.requiredParametersFields}
          readOnly={!_.get(props.fieldsEnabled, 'auctionParameterRealEstateCommercial', true)}
        />
      );
    case 'real_estate_other':
      return (
        <RealEstateOtherForm
          values={values}
          errors={errors}
          isSubmitting={isSubmitting}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setFieldValue={setFieldValue}
          requiredParametersFields={props.requiredParametersFields}
          readOnly={!_.get(props.fieldsEnabled, 'auctionParameterRealEstateOther', true)}
        />
      );
    case 'vehicle_car':
      return (
        <VehicleCarForm
          values={values}
          errors={errors}
          isSubmitting={isSubmitting}
          requiredParametersFields={props.requiredParametersFields}
          readOnly={!_.get(props.fieldsEnabled, 'auctionParameterVehicleCar', true)}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setFieldValue={setFieldValue}
        />
      );
    case 'vehicle_motorcycle':
      return (
        <VehicleMotorcycleForm
          values={values}
          errors={errors}
          isSubmitting={isSubmitting}
          requiredParametersFields={props.requiredParametersFields}
          readOnly={!_.get(props.fieldsEnabled, 'auctionParameterVehicleMotorcycle', true)}
          handleChange={handleChange}
          handleSubmit={handleSubmit}
          setFieldValue={setFieldValue}
        />
      );
    case 'vehicle_other':
      return (
        <VehicleOtherForm
          values={values}
          errors={errors}
          isSubmitting={isSubmitting}
          handleChange={handleChange}
          requiredParametersFields={props.requiredParametersFields}
          readOnly={!_.get(props.fieldsEnabled, 'auctionParameterVehicleOther', true)}
          handleSubmit={handleSubmit}
          setFieldValue={setFieldValue}
        />
      );
    default:
      return null;
  }
};
