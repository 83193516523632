import React from 'react';
import { Form } from 'react-bootstrap';
import { Formik, FormikValues } from 'formik';

import { Auctioneer } from '@types';
import { useAuctioneersApi } from '@api/auctioneers';
import { ModalProps } from '@components/Modal/Modal';
import { Modal, Button, BasePreloader } from '@components';

interface Props extends ModalProps {
  auctioneer: Auctioneer;
  onSuccess: (accessToken: string) => void;
}

export const FioTokenModal: React.FC<Props> = (props) => {
  const auctioneerApi = useAuctioneersApi();

  const handleFormSubmit = async (
    values: FormikValues,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    try {
      const res = await auctioneerApi.getFioToken(props.auctioneer.id, values.email, values.password);
      setSubmitting(false);
      props.onSuccess(res.data.accessToken);
    } catch (err: any) {
      if (auctioneerApi.isCancel(err)) {
        return;
      }
      setFieldValue('password', ``);
      setSubmitting(false);
    }
  };

  return (
    <Modal {...props}>
      {props.isOpen && (
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={(values, { setSubmitting, setFieldValue }) =>
            handleFormSubmit(values, setSubmitting, setFieldValue)
          }
        >
          {(formProps) => (
            <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formProps.handleSubmit(e)}>
              <Form.Group className="mb-16">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  required={true}
                  disabled={formProps.isSubmitting}
                  className="input-md"
                  type="email"
                  value={formProps.values.email}
                  name="email"
                  onChange={formProps.handleChange}
                />
              </Form.Group>
              <Form.Group className="mb-30">
                <Form.Label>Heslo</Form.Label>
                <Form.Control
                  required={true}
                  disabled={formProps.isSubmitting}
                  className="input-md"
                  type="password"
                  value={formProps.values.password}
                  name="password"
                  onChange={formProps.handleChange}
                />
              </Form.Group>

              <Form.Group>
                {formProps.isSubmitting ? (
                  <BasePreloader size={34} />
                ) : (
                  <Button type="submit" variant="btn-outline-primary">
                    Zobrazit
                  </Button>
                )}
              </Form.Group>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};
