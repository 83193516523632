import React from 'react';
import Select, { Props } from 'react-select';

import config from '@config';

export interface SelectProps extends Props {
  isMulti?: false | any;
  size?: 'md';
  isInvalid?: boolean;
  readOnly?: boolean;
  disableSearch?: boolean;
  options: any;
  minWidth?: number;
  dataTestId?: string;
}

const SelectComponent: React.FC<SelectProps> = (props) => {
  const getPadding = () => {
    if (props.size === 'md') {
      return '3px 15px';
    }
    return '0px 15px';
  };

  const isSearchableInitial = props.options && props.options.length >= config.enableSelectSearchMinOptions;

  const getTestId = (value: string) => {
    if (props.dataTestId) {
      return `${props.dataTestId}-${value}`;
    }
    return undefined;
  };

  return (
    <Select
      isMulti={props.isMulti}
      placeholder={props.placeholder || 'Vyberte ze seznamu'}
      noOptionsMessage={props.noOptionsMessage || (() => 'Nenalezeno')}
      isDisabled={props.readOnly || props.isDisabled}
      isSearchable={isSearchableInitial}
      menuPortalTarget={document.querySelector('body')}
      formatOptionLabel={(option) => <span data-test-id={getTestId(option.value)}>{option.label}</span>}
      styles={{
        control: (provided) => ({
          ...provided,
          width: '100%',
          minWidth: props.minWidth,
          outline: '0',
          boxShadow: 'none',
          borderRadius: 30,
          border: !props.isInvalid ? '1px solid #d2d2d2 !important' : '1px solid #caa2a2 !important',
          background: props.isInvalid ? '#fdfafa' : props.isDisabled ? '#e9ecef' : undefined,
          padding: getPadding(),
          fontSize: 12,
          marginTop: -1,
          fontWeight: 'normal',
          whiteSpace: 'nowrap',
        }),
        singleValue: (provided) => ({
          ...provided,
          color: '#000',
        }),
        menu: (provided) => ({ ...provided, zIndex: 10 }),
        menuPortal: (provided) => ({ ...provided, zIndex: 1000 }),
        indicatorSeparator: () => ({}),
        option: (provided) => ({
          ...provided,
          fontWeight: 'normal',
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: '#BFBFBF',
        }),
        noOptionsMessage: (provided) => ({
          ...provided,
          fontWeight: 'normal',
        }),
      }}
      {...props}
    />
  );
};

export default SelectComponent;
