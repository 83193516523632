import * as React from 'react';

import { AuctionResponse } from '@types';
import BidsTable from './BidsTable';

interface Props {
  auction: AuctionResponse;
}

const ProgressPanel: React.FC<Props> = (props) => {
  const renderContent = () => {
    return (
      <div className="auction-panel-content">
        <BidsTable auction={props.auction} />
      </div>
    );
  };

  return <div className="active-auction-panel">{renderContent()}</div>;
};

export default ProgressPanel;
