import React from 'react';

import { AuctioneerAddress } from '@types';
import { useAuctioneersApi } from '@api/auctioneers';
import { BasePreloader } from '@components';
import { closeMessage, confirmMessage } from '@helpers/messages';
import AddressForm from './AddressForm';
import AddressTable from './AddressTable';

interface Props {
  id: string;
  onNotFound: () => void;
}

const Address: React.FC<Props> = (props) => {
  const auctioneersApi = useAuctioneersApi();
  const [loaded, setLoaded] = React.useState(false);
  const [updateItem, setUpdateItem] = React.useState(undefined as AuctioneerAddress | undefined);
  const [showForm, setShowForm] = React.useState(false);
  const [addressData, setAddressData] = React.useState([] as Array<AuctioneerAddress>);

  React.useEffect(() => {
    loadData();
    return () => auctioneersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    auctioneersApi
      .establishmentsList(props.id)
      .then((res) => {
        setAddressData(res.data.data);
        setLoaded(true);
      })
      .catch((err) => {
        if (auctioneersApi.isCancel(err)) {
          return;
        }
        if (err?.response?.status === 404 || err?.response?.status === 403) {
          props.onNotFound();
          return;
        }
      });
  };

  const handleAddClick = () => {
    setUpdateItem(undefined);
    setShowForm(true);
  };

  const handleUpdateClick = (item: AuctioneerAddress) => {
    setUpdateItem(item);
    setShowForm(true);
  };

  const handleSave = () => {
    setShowForm(false);
    setUpdateItem(undefined);
    setLoaded(false);
    loadData();
  };

  const handleDeleteClick = (item: AuctioneerAddress) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat tuto položku?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return auctioneersApi.establishmentDelete(props.id, item.id.toString()).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadData();
      }
    });
  };

  if (!loaded) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <div>
      {showForm ? (
        <AddressForm
          id={props.id}
          data={updateItem}
          mainAddress={addressData.find((address) => address.mainAddress)}
          onClose={() => setShowForm(false)}
          onSubmit={() => setShowForm(false)}
          onSave={handleSave}
        />
      ) : (
        <AddressTable
          data={addressData}
          onClickAdd={handleAddClick}
          onUpdateClick={handleUpdateClick}
          onDeleteClick={handleDeleteClick}
        />
      )}
    </div>
  );
};

export default Address;
