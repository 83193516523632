import React from 'react';

import { useAuctionsApi } from '@api/auctions';
import { AuctionResponse, AuctionWinnerResponse, WebInformationResponse } from '@types';
import { AuctionEndedInfo, FrontAccountBox, FrontAuctionInformation } from '@components';
import { useAuctionInformationApi } from '@api/auctionInformation';

interface Props {
  auction: AuctionResponse;
}

const AuctionResult: React.FC<Props> = (props) => {
  const auctionsApi = useAuctionsApi();
  const auctionInformationApi = useAuctionInformationApi();
  const [information, setInformation] = React.useState<WebInformationResponse[]>([]);
  const [winner, setWinner] = React.useState<AuctionWinnerResponse | undefined>(undefined);

  React.useEffect(() => {
    loadWinner();
    loadInformation();
    return () => auctionsApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auction]);

  const loadWinner = async () => {
    if (props.auction.parentStatus !== 'ended' && props.auction.parentStatus !== 'overcharge') {
      setWinner(undefined);
      return;
    }
    try {
      const response = await auctionsApi.getWinner(props.auction.id);
      setWinner(response.data);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const loadInformation = async () => {
    try {
      const response = await auctionInformationApi.getList(props.auction.id);
      setInformation(response.data.data);
    } catch (err) {
      if (auctionInformationApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  return (
    <FrontAccountBox className="mb-3">
      <div className="component-auction-process-tab">
        <AuctionEndedInfo winner={winner} auction={props.auction} className="pb-4" />
        <FrontAuctionInformation
          category="a"
          auction={props.auction}
          className="pb-4"
          data={information.filter((i) => i.category === 'a')}
        />
        <FrontAuctionInformation
          category="b"
          auction={props.auction}
          data={information.filter((i) => i.category === 'b')}
        />
        {winner?.endedMessage && (
          <div className="mt-3 auction-result-message">
            <div dangerouslySetInnerHTML={{ __html: winner.endedMessage }} />
          </div>
        )}
      </div>
    </FrontAccountBox>
  );
};

export default AuctionResult;
