import { useEffect, useState } from 'react';

import { BasePreloader } from '@components';
import { usePageStore } from '@stores';
import { PageTextBlockType } from '@types';
import { usePagesApi } from '@api/pages';

type DefaultPageProps = {
  route: string;
};

export const DefaultPage = (props: DefaultPageProps) => {
  const pageState = usePageStore();
  const pagesApi = usePagesApi();
  const [loaded, setLoaded] = useState(false);
  const page = pageState.pages.find((i) => i.fullUrl === props.route);
  const [textBlocks, setTextBlock] = useState<PageTextBlockType[]>([]);

  useEffect(() => {
    if (page) {
      loadTextBlocks(page.id);
    }
    return () => {
      pagesApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const loadTextBlocks = async (pageId: number) => {
    setLoaded(false);
    try {
      const response = await pagesApi.listTextBlocks(pageId);
      const data = response.data.data;
      setTextBlock(data);
    } catch (err) {
      if (pagesApi.isCancel(err)) {
        return;
      }
    }
    setLoaded(true);
  };

  const renderTextBlock = (textBlocks: PageTextBlockType[]): JSX.Element => {
    return (
      <>
        {textBlocks.map((textBlock) => {
          if (textBlock.name !== 'Perex') {
            return <div key={textBlock.id} dangerouslySetInnerHTML={{ __html: textBlock.content }} />;
          }
          return null;
        })}
      </>
    );
  };

  return (
    <>
      <h1 className="about-us-page-title">{page?.name}</h1>
      <div className="component-about-us">{loaded ? renderTextBlock(textBlocks) : <BasePreloader />}</div>
    </>
  );
};
