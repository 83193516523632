import { API, APIResponse, useApi } from '@api/api';
import { AuctionInformationsCType } from '@types';

export const auctionInformationCTypePath = () => `/admin/auction-information-c-type`;

export const auctionInformationCTypeDetailPath = (informationId: number | string) =>
  `/admin/auction-information-c-type/${informationId}`;

export interface ServicesAPI extends API {
  list(): APIResponse<Array<AuctionInformationsCType>>;
  detail(informationId: number | string): APIResponse<AuctionInformationsCType>;
  update(
    informationId: number | string,
    auctionInformationCType: Partial<AuctionInformationsCType>
  ): APIResponse<AuctionInformationsCType>;
}

export const useSettingsInformationsApi = (): ServicesAPI => {
  const api = useApi();

  return {
    ...api,
    list() {
      const cancelToken = api.prepareCancelToken('list');
      return api.authRequest.get(auctionInformationCTypePath(), { cancelToken });
    },
    detail(informationId: number | string) {
      const cancelToken = api.prepareCancelToken('detail');
      return api.authRequest.get(auctionInformationCTypeDetailPath(informationId), { cancelToken });
    },
    update(informationId: number | string, auctionInformationCType: Partial<AuctionInformationsCType>) {
      const cancelToken = api.prepareCancelToken('update');
      return api.authRequest.put(auctionInformationCTypeDetailPath(informationId), auctionInformationCType, {
        cancelToken,
      });
    },
  };
};
