import React from 'react';
import fileDownload from 'js-file-download';

import { useMediaApi } from '@api/media';
import { AuctionDocumentResponse } from '@types';

import colorIcoDocument from '@assets/images/color-ico-document.svg';
import colorIcoPdf from '@assets/images/color-ico-pdf.svg';
import colorIcoDoc from '@assets/images/color-ico-doc.svg';

interface Props {
  auctionPublicDocuments: AuctionDocumentResponse[];
}

const AuctionDocumentTable: React.FC<Props> = ({ auctionPublicDocuments }) => {
  const mediaApi = useMediaApi();

  const handleFileDownload = (e: React.MouseEvent<HTMLButtonElement> | undefined, item: AuctionDocumentResponse) => {
    if (!!e) {
      e.preventDefault();
    }
    mediaApi
      .detail(item.media.hash, true)
      .then((res) => {
        fileDownload(res.data, item.media.originalName);
      })
      .catch((err) => {
        if (mediaApi.isCancel(err)) {
          return;
        }
      });
  };

  const getDocumentIco = (document: AuctionDocumentResponse) => {
    let src = colorIcoDocument;
    if (document.media.mimeType === 'application/pdf') {
      src = colorIcoPdf;
    }
    if (document.media.mimeType === 'application/msword') {
      src = colorIcoDoc;
    }
    return (
      <img
        className="file-ico cursor-pointer"
        alt={document.media.originalName}
        src={src}
        onClick={() => handleFileDownload(undefined, document)}
      />
    );
  };

  if (!auctionPublicDocuments || !auctionPublicDocuments.filter((document) => document.public).length) {
    return <p>Pro tuto dražbu neevidujeme žádné dokumenty.</p>;
  }

  return (
    <table className="auction-document-table">
      <thead>
        <tr>
          <th colSpan={2} className="text-left">
            Název dokumentu
          </th>
        </tr>
      </thead>
      <tbody>
        {auctionPublicDocuments
          .filter((document) => document.public)
          .map((document) => (
            <tr key={document.id}>
              <td>
                <div className="auction-document__name">
                  {getDocumentIco(document)}
                  <span className="cursor-pointer" onClick={() => handleFileDownload(undefined, document)}>
                    {document.media.title || document.media.originalName}
                  </span>
                </div>
              </td>
              <td>
                <button onClick={(e) => handleFileDownload(e, document)} className="btn-download">
                  stáhnout dokument
                </button>
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
};

export default AuctionDocumentTable;
