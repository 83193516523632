import React, { useEffect, useState } from 'react';

import { BasePreloader } from '@components';
import InformedConsentForm from './InformedConsentForm';
import InformedConsentsTable from './InformedConsentsTable';
import { InformedConsentDefaultResponse, useInformedConsentApi } from '@api/informedConsent';

const InformedConsents: React.FC = () => {
  const informedConsentApi = useInformedConsentApi();
  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState<InformedConsentDefaultResponse[]>([]);
  const [edit, setEdit] = useState<InformedConsentDefaultResponse | undefined>();

  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      const res = await informedConsentApi.getDefaults();
      setData(res.data.data);
      setIsLoaded(true);
    } catch (err: any) {
      if (informedConsentApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const handleUpdate = async () => {
    await loadData();
  };

  if (!isLoaded) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <>
      {edit ? (
        <InformedConsentForm data={edit} onSave={() => handleUpdate()} onClose={() => setEdit(undefined)} />
      ) : (
        <InformedConsentsTable data={data} onUpdateClick={(item) => setEdit(item)} />
      )}
    </>
  );
};

export default InformedConsents;
