import React from 'react';
import { Table } from 'react-bootstrap';

import { LogResponse } from '@types';
import { getName } from '@helpers/string';
import { getDateTimeFormat } from '@helpers/datetime';

interface Props {
  data: Array<LogResponse>;
}

const ItemsTable: React.FC<Props> = (props) => {
  const renderAuthor = (item: LogResponse) => {
    if (item.user?.id) {
      return getName(item.user) || '';
    }

    return 'Systém';
  };

  return (
    <div className="responsive-table-content">
      <Table className="table-middle" striped>
        <thead>
          <tr>
            <th>Akce</th>
            <th>Detail</th>
            <th>Datum</th>
            <th>Změnu provedl</th>
            <th>Role</th>
          </tr>
        </thead>
        <tbody>
          {props.data.map((item, index) => (
            <tr key={`list-item-${index}`}>
              <td>{item.action}</td>
              <td dangerouslySetInnerHTML={{ __html: item.detail }} />
              <td>{getDateTimeFormat(item.timeCreated, { withSeconds: true })}</td>
              <td>{renderAuthor(item)}</td>
              <td>{item.user?.type}</td>
            </tr>
          ))}
          {props.data.length < 1 && (
            <tr>
              <td className="text-left" colSpan={100}>
                Nebyla nalezena žádná data.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default ItemsTable;
