import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import * as routes from '@routes';
import { NewsType, PermissionGroup, UserPermission } from '@types';
import { Visible } from '@components';

import colorIcoRestore from '@assets/images/color-ico-restore.svg';
import colorIcoConf from '@assets/images/color-ico-conf.svg';
import { stripeHtml } from '@helpers/stripeHtml';
import { usePageStore } from '@stores';

interface Props {
  data: Array<NewsType>;
  onArchiveClick: (item: NewsType) => void;
  onActivateClick: (item: NewsType) => void;
}

const NewsTable: React.FC<Props> = (props) => {
  const pageState = usePageStore();
  const handleArchiveClick = (e: React.MouseEvent, item: NewsType) => {
    e.preventDefault();
    props.onArchiveClick(item);
  };

  const handleActivateClick = (e: React.MouseEvent, item: NewsType) => {
    e.preventDefault();
    props.onActivateClick(item);
  };

  return (
    <div>
      <div className="mt-4">
        <div className="responsive-table-content">
          <Table className="table-middle border-bottom-0" striped responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Název</th>
                <th>Text</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {props.data.length < 1 && (
                <tr>
                  <td className="text-left" colSpan={100}>
                    Nebyla nalezena žádná data.
                  </td>
                </tr>
              )}
              {props.data.map((item, index) => (
                <tr key={`item-${index}`}>
                  <td className="text-left">{item.id}</td>
                  <td className="text-left">{item.title}</td>
                  <td className="text-left information-text">{stripeHtml(item.perex, 100)}</td>
                  <td className="text-right">
                    <Visible
                      and={[
                        {
                          permissionGroupName: PermissionGroup.administrator,
                          permissionName: UserPermission.canEdit,
                        },
                        () => item.state !== 'deleted',
                      ]}
                      disableSuperAdminOverride
                    >
                      <Link
                        className="d-inline-flex align-items-center ml-4"
                        to={pageState.getPagePath(routes.admin.SETTINGS_NEWS_UPDATE, { ':newsId': item.id.toString() })}
                      >
                        <img src={colorIcoConf} alt="ico" className="mr-2" />
                        Upravit
                      </Link>
                    </Visible>
                    {item.state === 'active' && (
                      <Visible permissionGroupName={PermissionGroup.priceList} permissionName={UserPermission.canEdit}>
                        <Link
                          to="/"
                          className="d-inline-flex align-items-center ml-4"
                          onClick={(e) => handleArchiveClick(e, item)}
                        >
                          <img src={colorIcoRestore} alt="ico" className="mr-2" />
                          Archivovat
                        </Link>
                      </Visible>
                    )}
                    {item.state === 'archived' && (
                      <Visible permissionGroupName={PermissionGroup.priceList} permissionName={UserPermission.canEdit}>
                        <Link
                          to="/"
                          className="d-inline-flex align-items-center ml-4"
                          onClick={(e) => handleActivateClick(e, item)}
                        >
                          <img src={colorIcoRestore} alt="ico" className="mr-2" />
                          Aktivovat
                        </Link>
                      </Visible>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default NewsTable;
