import React from 'react';
import { Link } from 'react-router-dom';

import { BaseAccountPage, Button } from '@components';
import * as routes from '@routes';
import { UserRole } from '@types';
import { useAuthFrontStore, usePageStore } from '@stores';
import AccountBlock from './components/AccountBlock';
import AccountLinks from './components/AccountLinks';

const AccountPage: React.FC = () => {
  const authFrontState = useAuthFrontStore();
  const pageState = usePageStore();

  let activeIndex = 0;
  if (authFrontState.user?.role === UserRole.user) {
    activeIndex = 0;
  } else if (authFrontState.user?.role === UserRole.buyer) {
    activeIndex = 1;
  }
  if (authFrontState.user?.verificationState === 'verified') {
    activeIndex = 2;
  }

  const items = [
    {
      title: 'Základní registrace',
      completeTestId: 'user-account-advanced-basic-complete',
      text: 'Můžete pasivně sledovat průběh vybraných dražeb (bez vaší aktivní účasti) a také využít služby emailových upozornění na dražby dle nastavených vlastních kritérií.',
    },
    {
      title: 'Registrace dražitele',
      completeTestId: 'user-account-advanced-registration-complete',
      text: 'Tuto možnost volíte u registrace v případě, že se chcete stát aktivním účastníkem vybrané dražby (činit podání, příhozy, podávat námitky, apod.). Rovněž můžete využívat informací a nabízených služeb portálu exdrazby.cz.',
      renderAction: () => (
        <Button
          to={pageState.getPagePath(routes.front.ACCOUNT_AUCTIONEER_REGISTRATION)}
          variant="btn-outline-primary"
          dataTestId="front-user-account-advanced-registration-btn"
        >
          Registrovat
        </Button>
      ),
    },
    {
      title: 'Ověření dokumetů',
      completeTestId: 'user-account-documents-complete',
      text: 'Elektr. prokázání totožnosti - na poště - konvertovanými doklady.',
      renderAction: () =>
        activeIndex >= 1 ? (
          <Button
            to={pageState.getPagePath(routes.front.ACCOUNT_DOCUMENTS)}
            variant="btn-outline-primary"
            dataTestId="front-user-account-documents-btn"
          >
            Ověření dokumetů
          </Button>
        ) : (
          <div>
            Nejdříve musíte dokončit
            <br />
            <Link
              to={pageState.getPagePath(routes.front.ACCOUNT_AUCTIONEER_REGISTRATION)}
              className="text-underline"
              style={{ color: '#62CEA3' }}
            >
              Registraci dražitele
            </Link>
          </div>
        ),
    },
  ];

  return (
    <BaseAccountPage page={routes.front.ACCOUNT}>
      <div className="account-page-title">Stav profilu</div>
      {items.map((item, index) => (
        <AccountBlock
          key={index}
          index={index + 1}
          isNext={index === activeIndex + 1}
          isActive={index <= activeIndex}
          {...item}
        />
      ))}
      <AccountLinks />
    </BaseAccountPage>
  );
};

export default AccountPage;
