import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { BooleanParam, NumberParam, ObjectParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import { useInvoiceApi } from '@api/invoice';
import { AdminBoxContent, BasePreloader, Pagination } from '@components';
import { Auctioneer, InvoiceResponse, QuantificationOfInvoicing } from '@types';

import { useAuctioneersApi } from '@api/auctioneers';
import { getCurrencyFormat } from '@helpers/formats';

import { InvoicesSections } from '../InvoicesPage';
import { InvoiceExports } from './InvoiceExports';
import InvoicesItemsTable from './InvoicesItemsTable';
import InvoicesDataFilters from './InvoicesDataFilters';
import { TableColumnSortProps } from '@components/Table/TableColumn';

const SectionInvoices: React.FC = () => {
  const invoiceApi = useInvoiceApi();
  const auctioneersApi = useAuctioneersApi();
  const location = useLocation();
  const [data, setData] = React.useState<Array<InvoiceResponse>>([]);
  const [auctioneers, setAuctioneers] = React.useState([] as Array<Auctioneer>);
  const [quantificationOfInvoicing, setQuantificationOfInvoicing] = React.useState<
    QuantificationOfInvoicing | undefined
  >(undefined);
  const [totalRows, setTotalRows] = React.useState(0);
  const [loaded, setLoaded] = React.useState(false);
  const [query, setQuery] = useQueryParams({
    section: withDefault(StringParam, InvoicesSections.invoices),
    page: withDefault(NumberParam, 1),
    perPage: withDefault(NumberParam, 15),
    paid: withDefault(BooleanParam, undefined),
    invoiceNumber: withDefault(StringParam, undefined),
    enforcement: withDefault(BooleanParam, undefined),
    auctioneer: withDefault(StringParam, undefined),
    auctioneerType: withDefault(StringParam, undefined),
    auction: withDefault(StringParam, undefined),
    dateOfIssueFrom: withDefault(StringParam, undefined),
    dateOfIssueTo: withDefault(StringParam, undefined),
    datePaidFrom: withDefault(StringParam, undefined),
    datePaidTo: withDefault(StringParam, undefined),
    sort: withDefault(ObjectParam, {
      property: 'auctionInvoice.invoiceNumber',
      direction: 'ASC',
    }),
  });

  React.useEffect(() => {
    loadData().then();
    return () => {
      invoiceApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, query]);

  const loadData = async () => {
    try {
      const responseAuctioneers = await auctioneersApi.list({ perPage: 999 });
      setAuctioneers(responseAuctioneers.data.data);
      const response = await invoiceApi.getInvoices(query, query.sort.property, query.sort.direction);
      setData(response.data.data);
      setTotalRows(response.data.total as number);
      setQuantificationOfInvoicing({
        invoicePaid: response.data.invoicePaid,
        invoiceNotPaid: response.data.invoiceNotPaid,
      });
      setLoaded(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  return (
    <>
      <InvoicesDataFilters
        auctioneers={auctioneers}
        values={query}
        onSubmit={(values) => setQuery({ ...values, page: undefined }, 'push')}
      />
      <AdminBoxContent>
        {quantificationOfInvoicing && (
          <Row>
            <Col xs={12}>
              <div className="invoice-sumarry">
                <h4>Předběžné vyčíslení fakturace</h4>
                <div className="items">
                  <p>
                    Zaplaceno doteď: <strong>{getCurrencyFormat(quantificationOfInvoicing.invoicePaid)}</strong>
                  </p>
                  <p>
                    Nezaplaceno doteď: <strong>{getCurrencyFormat(quantificationOfInvoicing.invoiceNotPaid)}</strong>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={12}>
            {loaded ? (
              <div>
                <div>
                  <InvoicesItemsTable
                    data={data}
                    sort={query.sort as TableColumnSortProps}
                    onSortChange={(sort) => setQuery({ ...query, sort: sort }, 'push')}
                  />
                </div>
                <InvoiceExports query={query} />
                {data.length > 0 && (
                  <div className="mt-2">
                    <Pagination
                      perPageChoiceEnabled
                      page={query.page}
                      perPage={query.perPage}
                      totalRows={totalRows}
                      onPageChange={(page, perPage) => setQuery({ ...query, page, perPage }, 'push')}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
                <BasePreloader />
              </div>
            )}
          </Col>
        </Row>
      </AdminBoxContent>
    </>
  );
};

export default SectionInvoices;
