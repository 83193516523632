import Cookies from 'js-cookie';

import { createStore, useStoreState } from './store';

export interface AdminConfigState {
  hideSidebar: boolean;
}

export const adminConfigStore = createStore<AdminConfigState>({
  hideSidebar: Cookies.get('adminHideSidebar') === 'yes',
});

export const useAdminConfigStore = () => {
  const adminConfigState = useStoreState(adminConfigStore);

  const setHideSidebar = (hideSidebar: boolean) => {
    Cookies.set('adminHideSidebar', hideSidebar ? 'yes' : 'no', { expires: 365 });
    adminConfigStore.setState({ hideSidebar });
  };

  return {
    ...adminConfigState,
    setHideSidebar,
  };
};
