import React from 'react';
import { useHistory } from 'react-router-dom';

import * as routes from '@routes';
import { useAuthApi } from '@api/auth';
import { BasePage, BoxCentered } from '@components';
import { useAuctioneersApi } from '@api/auctioneers';
import { useAuthStore, userRoleChecks, usePageStore } from '@stores';
import LoginForm from './components/LoginForm/LoginForm';

const AuthLoginPage: React.FC = () => {
  const history = useHistory();
  const authApi = useAuthApi();
  const auctioneersApi = useAuctioneersApi();
  const authState = useAuthStore();
  const pageState = usePageStore();

  const handleLogin = async (token: string, refreshToken: string) => {
    authState.setAuthCookies(token, refreshToken);
    const userResponse = await authApi.logged();
    authState.setUser(userResponse.data.data);

    if (userResponse.data.data.role.startsWith('ROLE_AUCTIONEER')) {
      const auctioneerResponse = await auctioneersApi.list({});
      if (auctioneerResponse.data.data.length > 0) {
        authState.setAuctioneer(auctioneerResponse.data.data[0]);
      } else {
        authState.setAuctioneer(undefined);
      }
    } else {
      authState.setAuctioneer(undefined);
    }

    await pageState.reload();
    if (userRoleChecks(userResponse.data.data).isOfAnyAdminRole()) {
      history.push(pageState.getPagePath(routes.admin.BASE));
    } else {
      history.push('/');
    }
  };

  return (
    <BasePage className="d-flex justify-content-center align-items-center min-vh-100 p-2">
      <BoxCentered>
        <LoginForm onLogin={handleLogin} />
      </BoxCentered>
    </BasePage>
  );
};

export default AuthLoginPage;
