import React from 'react';
import classNames from 'classnames';

import { AuctionResponse } from '@types';
import { useAuthFrontStore } from '@stores';
import { isAuthorizedOptions, validCautionSecutiry } from '@helpers/definitions';

interface Props {
  auction?: AuctionResponse;
  securityPaid?: boolean;
  authorizationState?: string;
  auctionSecurity?: string;
  auctionSecurityTranslation?: string;
  admitted?: boolean;
}

const AuctionProgress: React.FC<Props> = (props) => {
  const { isLogged } = useAuthFrontStore();

  const items = [
    {
      number: 1,
      text: `Registrován a přihlášen do systému`,
      isActive: isLogged,
    },
    {
      number: 2,
      text: `Přihláška podána`,
      isActive: props.auction?.isJoined,
    },
    {
      number: 3,
      text: `Totožnost ověřena`,
      isActive: isAuthorizedOptions.some((option) => option === props.authorizationState),
    },
    {
      number: 4,
      text: validCautionSecutiry.some((option) => option === props.auctionSecurity)
        ? `${props.auctionSecurityTranslation}`
        : `Jistota složena`,
      hide: !props.auction?.cautionDeposit,
      isActive: validCautionSecutiry.some((option) => option === props.auctionSecurity),
    },
    {
      number: !!props.auction?.cautionDeposit ? 5 : 4,
      text: `Přihláška schválena`,
      isActive: props.auction?.cautionDeposit
        ? isAuthorizedOptions.some((option) => option === props.authorizationState) && props.admitted
        : props.admitted,
    },
  ];

  return (
    <div className="auction-progress">
      {items
        .filter((i) => !i.hide)
        .map((item, index) => (
          <div
            className={classNames(['progress-item', { 'item-active': item.isActive }])}
            key={`progress-item-${index}`}
          >
            <div className="progress-number">{item.number}</div>
            <div className="progress-text">{item.text}</div>
          </div>
        ))}
    </div>
  );
};

export default AuctionProgress;
