import React from 'react';
import { Link, useHistory } from 'react-router-dom';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { useAuthFrontStore } from '@stores/authFront';
import HeaderLoggedUser from '@components/HeaderLoggedUser/HeaderLoggedUser';

import heartIco from '@assets/images/front/icons/heart-color-20.svg';
import icoList from '@assets/images/front/header/color-ico-list.svg';

const LoggedItems: React.FC = () => {
  const history = useHistory();
  const pageState = usePageStore();
  const authFrontState = useAuthFrontStore();

  return (
    <>
      <Link
        to="/"
        className="header-item logged-item"
        data-test-id="header-logged-menu-account-favourites"
        onClick={(event) => {
          event.preventDefault();
          history.push(pageState.getPagePath(routes.front.ACCOUNT_FAVOURITES));
        }}
        title="Oblíbené"
      >
        <img src={heartIco} alt="ico" />
        <span>Oblíbené</span>
      </Link>
      {authFrontState.user?.role !== 'ROLE_USER' && (
        <Link
          title="Moje dražby a aukce"
          className="header-item logged-item"
          data-test-id="header-logged-menu-my-auctions"
          to={pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS)}
        >
          <img src={icoList} alt="ico" />
          <span>Moje dražby a aukce</span>
        </Link>
      )}
      <HeaderLoggedUser user={authFrontState.user} />
    </>
  );
};

export default LoggedItems;
