import React from 'react';
import _ from 'lodash';

import { AuctionResponse } from '@types';
import { getCurrencyFormat } from '@helpers/formats';
import { isAuthorizedOptions, validCautionSecutiry } from '@helpers/definitions';

interface Props {
  auction?: AuctionResponse;
  authorizationState: string;
  authorizationStateTranslation: string;
  auctionSecurity: string;
  auctionSecurityTranslation: string;
}

const TermsTab: React.FC<Props> = (props) => {
  const getTranslation = (key: string) => {
    return _.get(props.auction?.translations, key, key);
  };

  const isAuthorized = () => {
    return isAuthorizedOptions.some((option) => option === props.authorizationState);
  };

  return (
    <div className="items">
      <div className="item item-active">
        <div className="item-number">1</div>
        <div className="item-value">
          <h3 className="item-title">{getTranslation('terms_tab_01_title')}</h3>
          <div className="item-text">
            <strong>
              <p>{getTranslation('terms_tab_01_text')}</p>
            </strong>
          </div>
        </div>
      </div>
      <div className={isAuthorized() ? 'item item-active' : 'item'}>
        <div className="item-number">2</div>
        <div className="item-value">
          <h3 className="item-title">
            {getTranslation(isAuthorized() ? 'terms_tab_02_auth_title' : 'terms_tab_02_title')}
          </h3>
          <div className="item-text">
            <p>
              {!isAuthorized() ? (
                (props.auction?.auctionAuthorizationsType || []).map((item, index) => (
                  <>
                    {props.auction?.translations?.auction_info_conditions_title || 'auction_info_conditions_title'} (
                    {props.auction?.title || ''}):
                    <React.Fragment key={`authorization-type-${index}`}>
                      <br />
                      {item.typeTranslation || item.type}
                    </React.Fragment>
                  </>
                ))
              ) : (
                <p>{props.authorizationStateTranslation}</p>
              )}

              <strong>
                <p>{getTranslation(isAuthorized() ? 'terms_tab_02_auth_text' : 'terms_tab_02_text')}</p>
              </strong>
            </p>
          </div>
        </div>
      </div>
      {props.auction?.cautionDeposit && parseInt(props.auction?.cautionDeposit) > 0 && (
        <div
          className={
            validCautionSecutiry.some((option) => option === props.auctionSecurity) ? 'item item-active' : 'item'
          }
        >
          <div className="item-number">3</div>
          <div className="item-value">
            {!validCautionSecutiry.some((option) => option === props.auctionSecurity) ? (
              <>
                <h3 className="item-title">{getTranslation('terms_tab_03_title')}</h3>
                <div className="item-text">
                  <p>
                    {getTranslation('terms_tab_03_text_01')} {props.auction?.title || ''}{' '}
                    {getTranslation('terms_tab_03_text_02')} {getCurrencyFormat(props.auction?.cautionDeposit || 0)}{' '}
                    {getTranslation('terms_tab_03_text_03')}
                  </p>
                </div>
              </>
            ) : (
              <>
                <h3 className="item-title">{getTranslation('terms_tab_03_text_default')}</h3>
                <p>{props.auctionSecurityTranslation}</p>
                <strong>
                  <p>{getTranslation('terms_tab_03_text_04')}</p>
                </strong>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default TermsTab;
