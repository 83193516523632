import { Form } from 'react-bootstrap';
import { ValueType } from 'react-select';
import React, { FC, useState } from 'react';

import { arrKeyExists } from '@helpers/validator';
import { BaseType, AuctionParametersResponse } from '@types';
import { useAuctionParametersApi } from '@api/auctionParameters';
import { ControlFeedback, FormGroup, Select } from '@components';

import { BooleanParameter } from './BooleanParameter';
import { AuctionParameterForm } from './AuctionParameterForm';

interface SelectOptionType {
  value: string;
  label: string;
}

export const RealEstateCommercialForm: FC<AuctionParameterForm> = (props) => {
  const auctionParameterApi = useAuctionParametersApi();
  const [commercialObjectTypes, setCommercialObjectTypes] = useState<AuctionParametersResponse[]>([]);
  const [placementTypes, setPlacementTypes] = useState<AuctionParametersResponse[]>([]);
  const [buildingConstructionTypes, setBuildingConstructionTypes] = useState<AuctionParametersResponse[]>([]);
  const [objectStatusTypes, setObjectStatusTypes] = useState<AuctionParametersResponse[]>([]);
  const [heatingElement, setHeatingElement] = useState<AuctionParametersResponse[]>([]);
  const [heatingSource, setHeatingSource] = useState<AuctionParametersResponse[]>([]);
  const [waterHeatSource, setWaterHeatSource] = useState<AuctionParametersResponse[]>([]);
  const [circuitBreaker, setCircuitBreaker] = useState<AuctionParametersResponse[]>([]);
  const [waterTypes, setWaterTypes] = useState<AuctionParametersResponse[]>([]);
  const [electricityTypes, setElectricityTypes] = useState<AuctionParametersResponse[]>([]);
  const [wasteTypes, setWasteTypes] = useState<AuctionParametersResponse[]>([]);
  const [gasTypes, setGasTypes] = useState<AuctionParametersResponse[]>([]);
  const [parkingTypes, setParkingTypes] = useState<AuctionParametersResponse[]>([]);
  const [transportTypes, setTransportTypes] = useState<AuctionParametersResponse[]>([]);
  const [furnishedTypes, setFurnishedTypes] = useState<AuctionParametersResponse[]>([]);
  const [protectionTypes, setProtectionTypes] = useState<AuctionParametersResponse[]>([]);
  const [surroundingsTypes, setSurroundingsTypes] = useState<AuctionParametersResponse[]>([]);
  const [roadTypes, setRoadTypes] = useState<AuctionParametersResponse[]>([]);
  const [energyEfficiencyRatingTypes, setEnergyEfficiencyRatingTypes] = useState<AuctionParametersResponse[]>([]);
  const [telecommucationTypes, setTelecommucationTypes] = useState<AuctionParametersResponse[]>([]);
  const [internetConnectionTypes, setInternetConnectionTypes] = useState<AuctionParametersResponse[]>([]);
  const [wellTypes, setWellTypes] = useState<AuctionParametersResponse[]>([]);
  const [phaseDistributions, setPhaseDistributions] = useState<AuctionParametersResponse[]>([]);

  const furnishedTypeOptions = furnishedTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const protectionTypeOptions = protectionTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const surroundingsTypeOptions = surroundingsTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const roadTypeOptions = roadTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const energyEfficiencyRatingTypeOptions = energyEfficiencyRatingTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const telecommucationTypeOptions = telecommucationTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const placementTypeOptions = placementTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const buildingConstructionTypeOptions = buildingConstructionTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const objectStatusTypeOptions = objectStatusTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  const heatingElementOptions = heatingElement.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  const heatingSourceOptions = heatingSource.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  const waterHeatSourceOptions = waterHeatSource.map((item) => ({
    value: item.id,
    label: item.title,
  }));

  const objectTypeOptions = commercialObjectTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const waterTypeOptions = waterTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const electricityTypeOptions = electricityTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const wasteTypeOptions = wasteTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const gasTypeOptions = gasTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const parkingTypeOptions = parkingTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const transportTypeOptions = transportTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const circuitBreakerOptions = circuitBreaker.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const internetConnectionTypeOptions = internetConnectionTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const wellTypeOptions = wellTypes.map((item) => ({
    value: item.id,
    label: item.title,
  }));
  const phaseDistributionOptions = phaseDistributions.map((item) => ({
    value: item.id,
    label: item.title,
  }));


  React.useEffect(() => {
    loadData();
    return () => {
      auctionParameterApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      const commercialObjectTypesResponse = await auctionParameterApi.getRealEstateCommercialObjectType();
      setCommercialObjectTypes(commercialObjectTypesResponse.data.data);

      const realEstateRes = await auctionParameterApi.getRealEstateAll();
      setPlacementTypes(realEstateRes.data.data.parameterRealEstatePlacement);
      setBuildingConstructionTypes(realEstateRes.data.data.parameterRealEstateBuildingConstruction);
      setObjectStatusTypes(realEstateRes.data.data.parameterRealEstateObjectStatus);
      setHeatingElement(realEstateRes.data.data.parameterRealEstateHeatingElement);
      setHeatingSource(realEstateRes.data.data.parameterRealEstateHeatingSource);
      setWaterHeatSource(realEstateRes.data.data.parameterRealEstateWaterHeatSource);
      setWaterTypes(realEstateRes.data.data.parameterRealEstateWater);
      setElectricityTypes(realEstateRes.data.data.parameterRealEstateElectricity);
      setWasteTypes(realEstateRes.data.data.parameterRealEstateWaste);
      setGasTypes(realEstateRes.data.data.parameterRealEstateGas);
      setParkingTypes(realEstateRes.data.data.parameterRealEstateParking);
      setTransportTypes(realEstateRes.data.data.parameterRealEstateTransport);
      setFurnishedTypes(realEstateRes.data.data.parameterRealEstateFurnished);
      setProtectionTypes(realEstateRes.data.data.parameterRealEstateProtection);
      setSurroundingsTypes(realEstateRes.data.data.parameterRealEstateSurroundingsType);
      setRoadTypes(realEstateRes.data.data.parameterRealEstateRoadType);
      setEnergyEfficiencyRatingTypes(realEstateRes.data.data.parameterRealEstateEnergyEfficiencyRating);
      setTelecommucationTypes(realEstateRes.data.data.parameterRealEstateTelecommunication);
      setCircuitBreaker(realEstateRes.data.data.parameterRealEstateCircuitBreaker);
      setInternetConnectionTypes(realEstateRes.data.data.parameterRealEstateInternetConnectionType);
      setWellTypes(realEstateRes.data.data.parameterRealEstateWellType);
      setPhaseDistributions(realEstateRes.data.data.parameterRealEstatePhaseDistribution);
    } catch (e: any) {
      if (!e.response) {
        return;
      }
    }
  };

  const handlerCircuitBreakerChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateCommercial.parameterRealEstateCircuitBreaker', itemValue?.value);
  };

  const handlerPhaseDistributionChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateCommercial.parameterRealEstatePhaseDistribution', itemValue?.value);
  };

  const handleCommercialObjectTypeChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.parameterRealEstateCommercialObjectType',
      itemValue?.value,
    );
  };

  const handlePlacementChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateCommercial.parameterRealEstatePlacement', itemValue?.value);
  };

  const handleCommercialBuildingConstructionChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.parameterRealEstateBuildingConstruction',
      itemValue?.value,
    );
  };

  const handleCommercialObjectStatusChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateCommercial.parameterRealEstateObjectStatus', itemValue?.value);
  };

  const handleRealEstateCommercialHeatingElementChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateCommercial?.auctionParameterRealEstateCommercialHeatingElements || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialHeatingElements',
      itemValue.map((i) => ({
        parameterRealEstateHeatingElement: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateHeatingElement.id || f.parameterRealEstateHeatingElement) === i.value,
        )?.id,
      })),
    );
  };

  const handleRealEstateCommercialWellTypesChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateCommercial?.auctionParameterRealEstateCommercialWellTypes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialWellTypes',
      itemValue.map((i) => ({
        parameterRealEstateWellType: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateWellType.id || f.parameterRealEstateWellType) === i.value,
        )?.id,
      })),
    );
  };

  const handleRealEstateCommercialHeatingSourceChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateCommercial?.auctionParameterRealEstateCommercialHeatingSources || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialHeatingSources',
      itemValue.map((i) => ({
        parameterRealEstateHeatingSource: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateHeatingSource.id || f.parameterRealEstateHeatingSource) === i.value,
        )?.id,
      })),
    );
  };

  const handleRealEstateCommercialWaterHeatSourceChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateCommercial?.auctionParameterRealEstateCommercialWaterHeatSources || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialWaterHeatSources',
      itemValue.map((i) => ({
        parameterRealEstateWaterHeatSource: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateWaterHeatSource.id || f.parameterRealEstateWaterHeatSource) === i.value,
        )?.id,
      })),
    );
  };

  const handleRealEstateWaterChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateCommercial?.auctionParameterRealEstateCommercialWaters || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialWaters',
      itemValue.map((i) => ({
        parameterRealEstateWater: i.value,
        id: currentValues.find((f: any) => (f.parameterRealEstateWater.id || f.parameterRealEstateWater) === i.value)
          ?.id,
      })),
    );
  };

  const handleRealEstateElectricityChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateCommercial?.auctionParameterRealEstateCommercialElectricities || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialElectricities',
      itemValue.map((i) => ({
        parameterRealEstateElectricity: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateElectricity.id || f.parameterRealEstateElectricity) === i.value,
        )?.id,
      })),
    );
  };

  const handleRealEstateWasteChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateCommercial?.auctionParameterRealEstateCommercialWastes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialWastes',
      itemValue.map((i) => ({
        parameterRealEstateWaste: i.value,
        id: currentValues.find((f: any) => (f.parameterRealEstateWaste.id || f.parameterRealEstateWaste) === i.value)
          ?.id,
      })),
    );
  };

  const handleRealEstateGasChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateCommercial?.auctionParameterRealEstateCommercialGases || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialGases',
      itemValue.map((i) => ({
        parameterRealEstateGas: i.value,
        id: currentValues.find((f: any) => (f.parameterRealEstateGas.id || f.parameterRealEstateGas) === i.value)?.id,
      })),
    );
  };

  const handleCommercialParkingChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateCommercial.parameterRealEstateParking', itemValue?.value);
  };

  const handleTransportChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateCommercial?.auctionParameterRealEstateCommercialTransports || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialTransports',
      itemValue.map((i) => ({
        parameterRealEstateTransport: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateTransport.id || f.parameterRealEstateTransport) === i.value,
        )?.id,
      })),
    );
  };

  const handleInternetConnectionTypeChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateCommercial?.auctionParameterRealEstateCommercialInternetConnectionTypes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialInternetConnectionTypes',
      itemValue.map((i) => ({
        parameterRealEstateInternetConnectionType: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateInternetConnectionType.id || f.parameterRealEstateInternetConnectionType) === i.value,
        )?.id,
      })),
    );
  };

  const handleFurnishedChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateCommercial.parameterRealEstateFurnished', itemValue?.value);
  };

  const handleProtectionChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateCommercial.parameterRealEstateProtection', itemValue?.value);
  };

  const handleSurroundingsTypeChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue('auctionParameterRealEstateCommercial.parameterRealEstateSurroundingsType', itemValue?.value);
  };

  const handleRoadTypeChange = (value: ValueType<SelectOptionType, true>) => {
    const currentValues =
      props.values.auctionParameterRealEstateCommercial?.auctionParameterRealEstateCommercialRoadTypes || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialRoadTypes',
      itemValue.map((i) => ({
        parameterRealEstateRoadType: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateRoadType.id || f.parameterRealEstateRoadType) === i.value,
        )?.id,
      })),
    );
  };

  const handleEnergyEfficiencyRatingChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.parameterRealEstateEnergyEfficiencyRating',
      itemValue?.value,
    );
  };

  const handleTelecommucationChange = (value: ValueType<SelectOptionType, boolean>) => {
    const currentValues =
      props.values.auctionParameterRealEstateCommercial?.auctionParameterRealEstateCommercialTelecommunications || [];
    const itemValue = value as SelectOptionType[];
    props.setFieldValue(
      'auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialTelecommunications',
      itemValue.map((i) => ({
        parameterRealEstateTelecommunication: i.value,
        id: currentValues.find(
          (f: any) => (f.parameterRealEstateTelecommunication.id || f.parameterRealEstateTelecommunication) === i.value,
        )?.id,
      })),
    );
  };

  const getSelectValue = (val: any) => {
    if (val !== null && typeof val === 'object') {
      return val.id;
    }
    return val;
  };

  return (
    <>
      <div className="pt-4 pb-3">
        <h3 className="mb-4 font-weight-normal">Základní informace</h3>
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Budova{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateBuildingConstruction') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateBuildingConstruction">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateBuildingConstruction"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateBuildingConstruction}
              options={buildingConstructionTypeOptions}
              onChange={handleCommercialBuildingConstructionChange}
              value={
                buildingConstructionTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(
                      props.values.auctionParameterRealEstateCommercial.parameterRealEstateBuildingConstruction,
                    ),
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateBuildingConstruction && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_parameterRealEstateBuildingConstruction}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Stav{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateObjectStatus') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateObjectStatus">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateObjectStatus"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateObjectStatus}
              options={objectStatusTypeOptions}
              onChange={handleCommercialObjectStatusChange}
              value={
                objectStatusTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateCommercial.parameterRealEstateObjectStatus),
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateObjectStatus && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_parameterRealEstateObjectStatus}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Typ stavby
          {arrKeyExists(props.requiredParametersFields, 'parameterRealEstateCommercialObjectType') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateCommercialObjectType">
            <Select
              isClearable
              required
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateCommercialObjectType"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateCommercialObjectType}
              options={objectTypeOptions}
              onChange={handleCommercialObjectTypeChange}
              value={
                objectTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(
                      props.values.auctionParameterRealEstateCommercial.parameterRealEstateCommercialObjectType,
                    ),
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateCommercialObjectType && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_parameterRealEstateCommercialObjectType}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <FormGroup
        type="number"
        label="Počet podlaží"
        readOnly={props.readOnly}
        error={props.errors.auctionParameterRealEstateCommercial_numberOfFloors as string}
        labelClassName="text-left"
        name="auctionParameterRealEstateCommercial.numberOfFloors"
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'numberOfFloors')}
        value={props.values.auctionParameterRealEstateCommercial.numberOfFloors}
        dataTestId="auctionParameterRealEstateCommercial.numberOfFloors"
      />

      <FormGroup
        type="number"
        label="Počet podzemních podlaží"
        readOnly={props.readOnly}
        error={props.errors.auctionParameterRealEstateCommercial_undergroundFloors as string}
        labelClassName="text-left"
        name="auctionParameterRealEstateCommercial.undergroundFloors"
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'undergroundFloors')}
        value={props.values.auctionParameterRealEstateCommercial.undergroundFloors}
        dataTestId="auctionParameterRealEstateCommercial.undergroundFloors"
      />

      <FormGroup
        type="number"
        label="Umístění podlaží"
        readOnly={props.readOnly}
        labelClassName="text-left"
        name="auctionParameterRealEstateCommercial.floorNumber"
        value={props.values.auctionParameterRealEstateCommercial.floorNumber}
        error={props.errors.auctionParameterRealEstateCommercial_floorNumber as string}
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'floorNumber')}
        dataTestId="auctionParameterRealEstateCommercial.floorNumber"
      />

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <BooleanParameter
        label="Výtah"
        dataTestId="auctionParameterRealEstateCommercial.elevator"
        name="auctionParameterRealEstateCommercial.elevator"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateCommercial?.elevator}
        errors={props.errors.auctionParameterRealEstateCommercial_elevator}
        required={arrKeyExists(props.requiredParametersFields, 'elevator')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateCommercial.elevator', val)}
      />

      <BooleanParameter
        label="Bezbariérový přístup"
        dataTestId="auctionParameterRealEstateCommercial.easyAccess"
        name="auctionParameterRealEstateCommercial.easyAccess"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateCommercial?.easyAccess}
        errors={props.errors.auctionParameterRealEstateCommercial_easyAccess}
        required={arrKeyExists(props.requiredParametersFields, 'easyAccess')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateCommercial.easyAccess', val)}
      />

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Umístění{arrKeyExists(props.requiredParametersFields, 'parameterRealEstatePlacement') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstatePlacement">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstatePlacement"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstatePlacement}
              options={placementTypeOptions}
              onChange={handlePlacementChange}
              value={
                placementTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateCommercial.parameterRealEstatePlacement),
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstatePlacement && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_parameterRealEstatePlacement}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Okolní zástavba
          {arrKeyExists(props.requiredParametersFields, 'parameterRealEstateSurroundingsType') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateSurroundingsType">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateSurroundingsType"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateSurroundingsType}
              options={surroundingsTypeOptions}
              onChange={handleSurroundingsTypeChange}
              value={
                surroundingsTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(
                      props.values.auctionParameterRealEstateCommercial.parameterRealEstateSurroundingsType,
                    ),
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateSurroundingsType && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_parameterRealEstateSurroundingsType}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Ochrana{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateProtection') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateProtection">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateProtection"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateProtection}
              options={protectionTypeOptions}
              onChange={handleProtectionChange}
              value={
                protectionTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateCommercial.parameterRealEstateProtection),
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateProtection && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_parameterRealEstateProtection}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Plochy</h3>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          name="auctionParameterRealEstateCommercial.usableArea"
          thousandSeparator
          label="Plocha užitná"
          error={props.errors.auctionParameterRealEstateCommercial_usableArea as string}
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          readOnly={props.readOnly}
          required={arrKeyExists(props.requiredParametersFields, 'usableArea')}
          value={props.values.auctionParameterRealEstateCommercial.usableArea}
          onValueChange={(val) =>
            props.setFieldValue('auctionParameterRealEstateCommercial.usableArea', parseInt(val.value))
          }
          dataTestId="auctionParameterRealEstateCommercial.usableArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          name="auctionParameterRealEstateCommercial.builtUpArea"
          thousandSeparator
          label="Plocha zastavěná"
          error={props.errors.auctionParameterRealEstateCommercial_builtUpArea as string}
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          readOnly={props.readOnly}
          required={arrKeyExists(props.requiredParametersFields, 'builtUpArea')}
          value={props.values.auctionParameterRealEstateCommercial.builtUpArea}
          onValueChange={(val) =>
            props.setFieldValue('auctionParameterRealEstateCommercial.builtUpArea', parseInt(val.value))
          }
          dataTestId="auctionParameterRealEstateCommercial.builtUpArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          name="auctionParameterRealEstateCommercial.gardenArea"
          error={props.errors.auctionParameterRealEstateCommercial_gardenArea as string}
          thousandSeparator
          label="Plocha pozemku"
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          type="number"
          readOnly={props.readOnly}
          required={arrKeyExists(props.requiredParametersFields, 'gardenArea')}
          value={props.values.auctionParameterRealEstateCommercial.gardenArea}
          onValueChange={(val) =>
            props.setFieldValue('auctionParameterRealEstateCommercial.gardenArea', parseInt(val.value))
          }
          dataTestId="auctionParameterRealEstateCommercial.gardenArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="d-flex align-items-center mb-35">
        <FormGroup
          name="auctionParameterRealEstateCommercial.floorArea"
          error={props.errors.auctionParameterRealEstateCommercial_floorArea as string}
          thousandSeparator
          label="Plocha podlahová"
          className="mt-0 mb-0"
          labelClassName="text-left"
          controlClassName="w-max-205"
          type="number"
          readOnly={props.readOnly}
          required={arrKeyExists(props.requiredParametersFields, 'floorArea')}
          value={props.values.auctionParameterRealEstateCommercial.floorArea}
          onValueChange={(val) =>
            props.setFieldValue('auctionParameterRealEstateCommercial.floorArea', parseInt(val.value))
          }
          dataTestId="auctionParameterRealEstateCommercial.floorArea"
        />
        <span className="f-size-12 f-weight-400">
          m<sup>2</sup>
        </span>
      </div>

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Vybavení</h3>
      </div>

      <BooleanParameter
        label="Parkování"
        dataTestId="parameterRealEstateParkingExists"
        name="auctionParameterRealEstateCommercial.parkingExists"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateCommercial.parkingExists}
        errors={props.errors.auctionParameterRealEstateCommercial_parkingExists}
        required={arrKeyExists(props.requiredParametersFields, 'parkingExists')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateCommercial.parkingExists', val)}
      />

      {props.values.auctionParameterRealEstateCommercial.parkingExists === 'yes' && (
        <>
          <Form.Group className="f-inline-group">
            <Form.Label className="f-inline-label text-left">Typ parkovacího místa *</Form.Label>
            <div className="f-inline-control">
              <div className="w-max-500" data-test-id="parameterRealEstateParking">
                <Select
                  isClearable
                  size="md"
                  isDisabled={props.readOnly}
                  name="parameterRealEstateParking"
                  isInvalid={!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateParking}
                  options={parkingTypeOptions}
                  onChange={handleCommercialParkingChange}
                  value={
                    parkingTypeOptions.find(
                      (i) =>
                        i.value ===
                        getSelectValue(props.values.auctionParameterRealEstateCommercial.parameterRealEstateParking),
                    ) || null
                  }
                />
                {!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateParking && (
                  <ControlFeedback type="invalid">
                    {props.errors.auctionParameterRealEstateCommercial_parameterRealEstateParking}
                  </ControlFeedback>
                )}
              </div>
            </div>
          </Form.Group>

          <FormGroup
            required
            type="number"
            label="Počet parkovacích míst"
            readOnly={props.readOnly}
            controlClassName="w-max-205"
            labelClassName="text-left"
            error={props.errors.auctionParameterRealEstateCommercial_countOfParkingPlaces as string}
            name="auctionParameterRealEstateCommercial.countOfParkingPlaces"
            onChange={props.handleChange}
            value={props.values.auctionParameterRealEstateCommercial.countOfParkingPlaces}
            dataTestId="auctionParameterRealEstateCommercial.countOfParkingPlaces"
          />
        </>
      )}

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Termíny</h3>
      </div>

      <FormGroup
        type="number"
        label="Rok výstavby"
        readOnly={props.readOnly}
        controlClassName="w-max-205"
        labelClassName="text-left"
        error={props.errors.auctionParameterRealEstateCommercial_objectYear as string}
        name="auctionParameterRealEstateCommercial.objectYear"
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'objectYear')}
        value={props.values.auctionParameterRealEstateCommercial.objectYear}
        dataTestId="auctionParameterRealEstateCommercial.objectYear"
      />

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <div className="pb-3">
        <h3 className="mb-4 font-weight-normal">Ostatní</h3>
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Třída energetické náročnosti
          {arrKeyExists(props.requiredParametersFields, 'parameterRealEstateEnergyEfficiencyRating') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateEnergyEfficiencyRating">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateEnergyEfficiencyRating"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateEnergyEfficiencyRating}
              options={energyEfficiencyRatingTypeOptions}
              onChange={handleEnergyEfficiencyRatingChange}
              value={
                energyEfficiencyRatingTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(
                      props.values.auctionParameterRealEstateCommercial.parameterRealEstateEnergyEfficiencyRating,
                    ),
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateEnergyEfficiencyRating && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_parameterRealEstateEnergyEfficiencyRating}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <div className="pt-1 pb-2">
        <hr className="mt-1 mb-4" />
      </div>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Zdroj vody
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateCommercialWaters') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateWater">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateWater"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialWaters}
              options={waterTypeOptions}
              onChange={(val: BaseType) => handleRealEstateWaterChange(val)}
              value={(
                props.values.auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialWaters || []
              ).map((v: { parameterRealEstateWater: string | number }) =>
                waterTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWater)),
              )}
            />
            {!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialWaters && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialWaters}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Typ studny
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateCommercialWellTypes') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateWellTypet">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateCommercialWellTypes"
              isInvalid={
                !!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialWellTypes
              }
              options={wellTypeOptions}
              onChange={(val: BaseType) => handleRealEstateCommercialWellTypesChange(val)}
              value={(
                props.values.auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialWellTypes || []
              ).map((v: { parameterRealEstateWellType: string | number }) =>
                wellTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWellType)),
              )}
            />
            {!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialWellTypes && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialWellTypes}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Topné těleso
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateCommercialHeatingElement') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateHeatingElement">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateCommercialHeatingElements"
              isInvalid={
                !!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialHeatingElements
              }
              options={heatingElementOptions}
              onChange={(val: BaseType) => handleRealEstateCommercialHeatingElementChange(val)}
              value={(
                props.values.auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialHeatingElements || []
              ).map((v: { parameterRealEstateHeatingElement: string | number }) =>
                heatingElementOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateHeatingElement)),
              )}
            />
            {!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialHeatingElements && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialHeatingElements}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Zdroj topení
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateCommercialHeatingSources') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateHeatingSource">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateCommercialHeatingSources"
              isInvalid={
                !!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialHeatingSources
              }
              options={heatingSourceOptions}
              onChange={(val: BaseType) => handleRealEstateCommercialHeatingSourceChange(val)}
              value={(
                props.values.auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialHeatingSources || []
              ).map((v: { parameterRealEstateHeatingSource: string | number }) =>
                heatingSourceOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateHeatingSource)),
              )}
            />
            {!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialHeatingSources && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialHeatingSources}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Zdroj teplé vody
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateCommercialWaterHeatSources') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateWaterHeatSource">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateCommercialWaterHeatSources"
              isInvalid={
                !!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialWaterHeatSources
              }
              options={waterHeatSourceOptions}
              onChange={(val: BaseType) => handleRealEstateCommercialWaterHeatSourceChange(val)}
              value={(
                props.values.auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialWaterHeatSources || []
              ).map((v: { parameterRealEstateWaterHeatSource: string | number }) =>
                waterHeatSourceOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWaterHeatSource)),
              )}
            />
            {!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialWaterHeatSources && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialWaterHeatSources}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Rozvod plynu
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateCommercialGases') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateCommercialGases">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateCommercialGases"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialGases}
              options={gasTypeOptions}
              onChange={(val: BaseType) => handleRealEstateGasChange(val)}
              value={(
                props.values.auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialGases || []
              ).map((v: { parameterRealEstateGas: string | number }) =>
                gasTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateGas)),
              )}
            />
            {!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialGases && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialGases}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Typ odpadu
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateCommercialWastes') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateWaste">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateWaste"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialWastes}
              options={wasteTypeOptions}
              onChange={(val: BaseType) => handleRealEstateWasteChange(val)}
              value={(
                props.values.auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialWastes || []
              ).map((v: { parameterRealEstateWaste: string | number }) =>
                wasteTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateWaste)),
              )}
            />
            {!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialWastes && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialWastes}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Příjezdová komunikace
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateCommercialRoadTypes') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateRoadType">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateRoadType"
              isInvalid={
                !!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialRoadTypes
              }
              options={roadTypeOptions}
              onChange={(val: BaseType) => handleRoadTypeChange(val)}
              value={(
                props.values.auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialRoadTypes || []
              ).map((v: { parameterRealEstateRoadType: string | number }) =>
                roadTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateRoadType)),
              )}
            />
            {!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialRoadTypes && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialRoadTypes}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Telekomunikace
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateCommercialTelecommunications')
            ? ' *'
            : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateCommercialTelecommunications">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateCommercialTelecommunications"
              isInvalid={
                !!props.errors
                  .auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialTelecommunications
              }
              options={telecommucationTypeOptions}
              onChange={(val: BaseType) => handleTelecommucationChange(val)}
              value={(
                props.values.auctionParameterRealEstateCommercial
                  .auctionParameterRealEstateCommercialTelecommunications || []
              ).map((v: { parameterRealEstateTelecommunication: string | number }) =>
                telecommucationTypeOptions.find(
                  (f) => f.value === getSelectValue(v.parameterRealEstateTelecommunication),
                ),
              )}
            />
            {!!props.errors
              .auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialTelecommunications && (
              <ControlFeedback type="invalid">
                {
                  props.errors
                    .auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialTelecommunications
                }
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Elektřina
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateCommercialElectricities')
            ? ' *'
            : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateElectricity">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateElectricity"
              isInvalid={
                !!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialElectricities
              }
              options={electricityTypeOptions}
              onChange={(val: BaseType) => handleRealEstateElectricityChange(val)}
              value={(
                props.values.auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialElectricities ||
                []
              ).map((v: { parameterRealEstateElectricity: string | number }) =>
                electricityTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateElectricity)),
              )}
            />
            {!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialElectricities && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialElectricities}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Doprava
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateCommercialTransports') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateCommercialTransports">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateCommercialTransports"
              isInvalid={
                !!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialTransports
              }
              options={transportTypeOptions}
              onChange={(val: BaseType) => handleTransportChange(val)}
              value={(
                props.values.auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialTransports || []
              ).map((v: { parameterRealEstateTransport: string | number }) =>
                transportTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateTransport)),
              )}
            />
            {!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialTransports && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialTransports}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <BooleanParameter
        label="Fotovoltaika"
        dataTestId="auctionParameterRealEstateCommercial.ftvPanels"
        name="auctionParameterRealEstateCommercial.ftvPanels"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateCommercial?.ftvPanels}
        errors={props.errors.auctionParameterRealEstateCommercial_ftvPanels}
        required={arrKeyExists(props.requiredParametersFields, 'ftvPanels')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateCommercial.ftvPanels', val)}
      />

      <BooleanParameter
        label="Solární panely"
        dataTestId="auctionParameterRealEstateCommercial.solarPanels"
        name="auctionParameterRealEstateCommercial.solarPanels"
        readOnly={props.readOnly}
        value={props.values.auctionParameterRealEstateCommercial?.solarPanels}
        errors={props.errors.auctionParameterRealEstateCommercial_solarPanels}
        required={arrKeyExists(props.requiredParametersFields, 'solarPanels')}
        onChange={(val) => props.setFieldValue('auctionParameterRealEstateCommercial.solarPanels', val)}
      />

      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <FormGroup
        type="text"
        label="Poskytovatel innternetového připojení"
        readOnly={props.readOnly}
        labelClassName="text-left"
        name="auctionParameterRealEstateCommercial.internetConnectionProvider"
        value={props.values.auctionParameterRealEstateCommercial.internetConnectionProvider}
        error={props.errors.auctionParameterRealEstateCommercial_internetConnectionProvider as string}
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'internetConnectionProvider')}
        dataTestId="auctionParameterRealEstateCommercial.internetConnectionProvider"
      />

      <FormGroup
        type="number"
        label="Rychlost internetového připojení (Mbit/s)"
        readOnly={props.readOnly}
        labelClassName="text-left"
        name="auctionParameterRealEstateCommercial.internetConnectionSpeed"
        value={props.values.auctionParameterRealEstateCommercial.internetConnectionSpeed}
        error={props.errors.auctionParameterRealEstateCommercial_internetConnectionSpeed as string}
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'internetConnectionSpeed')}
        dataTestId="auctionParameterRealEstateCommercial.internetConnectionSpeed"
      />

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Typ internetového připojení
          {arrKeyExists(props.requiredParametersFields, 'auctionParameterRealEstateCommercialInternetConnectionTypes') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="auctionParameterRealEstateCommercialInternetConnectionTypes">
            <Select
              isMulti
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="auctionParameterRealEstateCommercialInternetConnectionTypes"
              isInvalid={
                !!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialInternetConnectionTypes
              }
              options={internetConnectionTypeOptions}
              onChange={(val: BaseType) => handleInternetConnectionTypeChange(val)}
              value={(
                props.values.auctionParameterRealEstateCommercial.auctionParameterRealEstateCommercialInternetConnectionTypes || []
              ).map((v: { parameterRealEstateInternetConnectionType: string | number }) =>
                internetConnectionTypeOptions.find((f) => f.value === getSelectValue(v.parameterRealEstateInternetConnectionType)),
              )}
            />
            {!!props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialInternetConnectionTypes && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_auctionParameterRealEstateCommercialInternetConnectionTypes}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>


      <div className="pb-2">
        <hr className="mb-4" />
      </div>

      <FormGroup
        readOnly={props.readOnly}
        label="Náklady na bydlení"
        labelClassName="text-left"
        error={props.errors.auctionParameterRealEstateCommercial_costOfLiving as string}
        name="auctionParameterRealEstateCommercial.costOfLiving"
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'costOfLiving')}
        value={props.values.auctionParameterRealEstateCommercial.costOfLiving}
        dataTestId="auctionParameterRealEstateCommercial.costOfLiving"
      />

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Zařízeno{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateFurnished') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateFurnished">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateFurnished"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateFurnished}
              options={furnishedTypeOptions}
              onChange={handleFurnishedChange}
              value={
                furnishedTypeOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateCommercial.parameterRealEstateFurnished),
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateFurnished && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_parameterRealEstateFurnished}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Jističe{arrKeyExists(props.requiredParametersFields, 'parameterRealEstateCircuitBreaker') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstateCircuitBreaker">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstateCircuitBreaker"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateCircuitBreaker}
              options={circuitBreakerOptions}
              onChange={handlerCircuitBreakerChange}
              value={
                circuitBreakerOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateCommercial.parameterRealEstateCircuitBreaker),
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstateCircuitBreaker && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_parameterRealEstateCircuitBreaker}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>

      <Form.Group className="f-inline-group">
        <Form.Label className="f-inline-label text-left">
          Počet fází{arrKeyExists(props.requiredParametersFields, 'parameterRealEstatePhaseDistribution') ? ' *' : ''}
        </Form.Label>
        <div className="f-inline-control">
          <div className="w-max-500" data-test-id="parameterRealEstatePhaseDistribution">
            <Select
              isClearable
              size="md"
              isDisabled={props.readOnly}
              name="parameterRealEstatePhaseDistribution"
              isInvalid={!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstatePhaseDistribution}
              options={phaseDistributionOptions}
              onChange={handlerPhaseDistributionChange}
              value={
                phaseDistributionOptions.find(
                  (i) =>
                    i.value ===
                    getSelectValue(props.values.auctionParameterRealEstateCommercial.parameterRealEstatePhaseDistribution),
                ) || null
              }
            />
            {!!props.errors.auctionParameterRealEstateCommercial_parameterRealEstatePhaseDistribution && (
              <ControlFeedback type="invalid">
                {props.errors.auctionParameterRealEstateCommercial_parameterRealEstatePhaseDistribution}
              </ControlFeedback>
            )}
          </div>
        </div>
      </Form.Group>


      <FormGroup
        type="text"
        label="Klíčová slova (oddělená středníkem)"
        readOnly={props.readOnly}
        labelClassName="text-left"
        name="auctionParameterRealEstateCommercial.keywords"
        value={props.values.auctionParameterRealEstateCommercial.keywords}
        error={props.errors.auctionParameterRealEstateCommercial_keywords as string}
        onChange={props.handleChange}
        required={arrKeyExists(props.requiredParametersFields, 'keywords')}
        dataTestId="auctionParameterRealEstateCommercial.keywords"
      />

      <div className="pb-3">
        <hr />
      </div>
    </>
  );
};
