import React, { useState } from 'react';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { usePageStore } from '@stores';
import { useApplicationStore } from '@stores';

const FrontTopMenu: React.FC = () => {
  const timeFormat = 'D. M. Y HH:mm:ss';
  const location = useLocation();
  const pageState = usePageStore();
  const applicationState = useApplicationStore({ onTick: (time) => handleTimeTick(time) });
  const [currentTime, setCurrentTime] = useState<number | undefined>(applicationState.getTime());

  const handleTimeTick = (time?: number) => setCurrentTime(time);
  const menuPages = pageState.pages.filter((page) => page.menu && page.showInAdministration);

  const sortedMenuPages = menuPages.sort((a, b) => {
    if (a.menuPosition === null) {
      return 1;
    } else if (b.menuPosition === null) {
      return -1;
    }
    return a.menuPosition - b.menuPosition;
  });

  if (!currentTime) {
    return null;
  }

  return (
    <div className="component-front-top-menu">
      <Container fluid>
        <ul>
          <li className={location.pathname === '/' ? 'is-active' : ''}>
            <Link to="/">Úvod</Link>
          </li>
          {sortedMenuPages.map((page, index) => {
            return (
              <li key={index} className={location.pathname.includes(page.fullUrl) ? 'is-active' : ''}>
                <Link to={pageState.getPagePath(page.routeName)}>{page.name}</Link>
              </li>
            );
          })}
        </ul>
        <div className="current-time">
          <>
            Aktuální čas: <span className="notranslate">{moment(currentTime).format(timeFormat)}</span>
          </>
        </div>
        <div className="clearfix" />
      </Container>
    </div>
  );
};

export default FrontTopMenu;
