import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';

import { useUsersApi } from '@api/users';
import { User, UserAuctionResponse } from '@types';
import { getDateTimeFormat } from '@helpers/datetime';
import { BasePreloader, Pagination } from '@components';
import { getBaseNumberFormat } from '@helpers/formats';

interface Props {
  user?: User;
}

export const JoinedAuctions: React.FC<Props> = (props) => {
  const usersApi = useUsersApi();
  const location = useLocation();
  const [data, setData] = useState<UserAuctionResponse[]>([]);
  const [total, setTotal] = React.useState(0);
  const [isLoaded, setIsLoaded] = useState(false);
  const [query, setQuery] = useQueryParams({
    section: withDefault(NumberParam, 1),
    page: withDefault(NumberParam, 1),
    perPage: withDefault(NumberParam, 15),
  });

  useEffect(() => {
    loadData();
    return () => usersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const loadData = async () => {
    setIsLoaded(false);
    try {
      const res = await usersApi.getAuctionsJoined(props.user?.id || '', { ...query });
      setTotal(res.data.total || 0);
      setData(res.data.data);
      setIsLoaded(true);
    } catch (err) {
      if (usersApi.isCancel(err)) {
        return;
      }
    }
  };

  if (!isLoaded) {
    return (
      <div className="d-flex justify-content-center py-5">
        <BasePreloader />
      </div>
    );
  }

  return (
    <div>
      <div>
        <h2>Dražební/aukční proces dražitele</h2>
      </div>

      <div className="component-admin-auctions-table mt-4">
        <Table className="border-bottom-0">
          <thead>
            <tr>
              <th className="text-nowrap">Jednací číslo</th>
              <th className="text-nowrap text-center">Kategorie</th>
              <th className="text-nowrap text-center">Podkategorie</th>
              <th className="text-nowrap text-center">Datum a čas připojení k dražbě/aukci</th>
              <th className="text-nowrap text-center">Připuštěn</th>
              <th className="text-nowrap text-center">Počet příhozů</th>
              <th className="text-nowrap text-center">Vyhrál</th>
              <th className="text-nowrap text-center">Informovaná souhlasy</th>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => (
              <tr key={`list-item-${index}`} className="border-top">
                <td>{item.auction.number}</td>
                <td className="text-center">
                  {item.auction.auctionCategory.parentTitle || item.auction.auctionCategory.title}
                </td>
                <td className="text-center">
                  {item.auction.auctionCategory.parentTitle ? item.auction.auctionCategory.title : ''}
                </td>
                <td className="text-center">{getDateTimeFormat(item.joinedTime)}</td>
                <td className="text-center">{item.admitted ? 'ANO' : 'NE'}</td>
                <td className="text-center">{getBaseNumberFormat(item.countAuctionBids || 0)}</td>
                <td className="text-center">{item.isWinner ? 'ANO' : 'NE'}</td>
                <td className="text-center">{item.hasUserInformedConsents ? 'ANO' : 'NE'}</td>
              </tr>
            ))}
            {data.length < 1 && (
              <tr>
                <td className="text-left" colSpan={100}>
                  Nebyla nalezena žádná data.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      <div>
        {total > query.perPage && (
          <div className="mt-2">
            <Pagination
              page={query.page}
              perPage={query.perPage}
              totalRows={total}
              onPageChange={(page, perPage) => setQuery({ ...query, page: page, perPage: perPage }, 'push')}
            />
          </div>
        )}
      </div>
    </div>
  );
};
