import React from 'react';
import _ from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { BooleanParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import * as routes from '@routes';
import { useLogApi } from '@api/log';
import { usePageStore } from '@stores';
import { useUsersApi } from '@api/users';
import { LogResponse, UserType } from '@types';
import { AdminBoxContent, BasePage, BasePreloader, Pagination } from '@components';

import Filters from './components/Filters';
import ItemsTable from './components/ItemsTable';

const LogListPage: React.FC = () => {
  const logApi = useLogApi();
  const usersApi = useUsersApi();
  const location = useLocation();
  const pageState = usePageStore();
  const page = pageState.getPage(routes.admin.LOG);
  const [userTypes, setUserTypes] = React.useState<UserType[]>([]);
  const [data, setData] = React.useState<Array<LogResponse>>([]);
  const [totalRows, setTotalRows] = React.useState(0);
  const [loaded, setLoaded] = React.useState(false);
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    perPage: withDefault(NumberParam, 15),
    dateFrom: withDefault(StringParam, undefined),
    dateTo: withDefault(StringParam, undefined),
    userId: withDefault(StringParam, undefined),
    name: withDefault(StringParam, undefined),
    role: withDefault(StringParam, undefined),
    auction: withDefault(StringParam, undefined),
    public: withDefault(BooleanParam, true),
  });

  React.useEffect(() => {
    loadData();
    return () => {
      logApi.cancelAllRequests();
      usersApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, query]);

  const loadData = async () => {
    try {
      const res = await usersApi.getUserTypes();
      setUserTypes(res.data);
      const userType = res.data.find((i) => i.type === query.role);
      const filters = _.omit({ ...query, roles: userType?.roles }, ['role']);
      const response = await logApi.getLogs(filters);
      setData(response.data.data);
      setTotalRows(response.data.total as number);
      setLoaded(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  return (
    <BasePage page={routes.admin.LOG} title={page?.name}>
      <Filters userTypes={userTypes} values={query} onSubmit={(values) => setQuery(values, 'push')} />
      <AdminBoxContent>
        <Row>
          <Col xs={12}>
            {loaded ? (
              <div>
                <div>
                  <ItemsTable data={data} />
                </div>
                {data.length > 0 && (
                  <div className="mt-2">
                    <Pagination
                      page={query.page}
                      perPage={query.perPage}
                      totalRows={totalRows}
                      onPageChange={(page) => setQuery({ ...query, page: page }, 'push')}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
                <BasePreloader />
              </div>
            )}
          </Col>
        </Row>
      </AdminBoxContent>
    </BasePage>
  );
};

export default LogListPage;
