import { useEffect } from 'react';
import { Col } from 'react-bootstrap';

import { usePagesApi } from '@api/pages';
import { PageType } from '@types';
import { Button } from '@components';

import { iWantToSellDataIcons } from './iWantToSellData';

export type IWantToSellItemProps = {
  item: PageType;
};

export const IWantToSellItem = (props: IWantToSellItemProps) => {
  const { item } = props;
  const pagesApi = usePagesApi();
  const icons = iWantToSellDataIcons(item.routeName);

  useEffect(() => {
    return () => {
      pagesApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  return (
    <Col sm={6} xs={12} className="item-wrapper py-3">
      <div className="item">
        <div>
          <img src={icons.icon} alt="Ikona" className="hover-hide" />
          <img src={icons.hoverIcon} alt="Ikona" className="hover-show" />
        </div>
        <div>
          <h2>{item.name}</h2>
          <p>{item.perex}</p>
          <Button type="button" variant="btn-outline-primary" to={item.fullUrl}>
            Více informací
          </Button>
        </div>
      </div>
    </Col>
  );
};
