import auctionIcon from '@assets/images/front/i-want-to-sell/ico-i-want-to-sell-auction.svg';
import individualPersonIcon from '@assets/images/front/i-want-to-sell/ico-i-want-to-sell-individual-person.svg';
import legalPersonIcon from '@assets/images/front/i-want-to-sell/ico-i-want-to-sell-legal-person.svg';
import realEstateAgenciesIcon from '@assets/images/front/i-want-to-sell/ico-i-want-to-sell-real-estate-agencies.svg';
import insolvencyAdministratorsIcon from '@assets/images/front/i-want-to-sell/ico-i-want-to-sell-insolvency-administrators.svg';
import auctionWhiteIcon from '@assets/images/front/i-want-to-sell/ico-i-want-to-sell-auction-white.svg';
import individualPersonWhiteIcon from '@assets/images/front/i-want-to-sell/ico-i-want-to-sell-individual-person-white.svg';
import legalPersonWhiteIcon from '@assets/images/front/i-want-to-sell/ico-i-want-to-sell-legal-person-white.svg';
import realEstateAgenciesWhiteIcon from '@assets/images/front/i-want-to-sell/ico-i-want-to-sell-real-estate-agencies-white.svg';
import insolvencyAdministratorsWhiteIcon from '@assets/images/front/i-want-to-sell/ico-i-want-to-sell-insolvency-administrators-white.svg';

export type IWantToSellIcons = {
  icon: string;
  hoverIcon: string;
};

export const iWantToSellDataIcons = (routeName: string): IWantToSellIcons => {
  switch (routeName) {
    case 'i-want-to-sell-auction':
      return {
        icon: auctionIcon,
        hoverIcon: auctionWhiteIcon,
      };
    case 'i-want-to-sell-drazba':
      return {
        icon: auctionIcon,
        hoverIcon: auctionWhiteIcon,
      };
    case 'i-want-to-sell-insolvency-administrators':
      return {
        icon: insolvencyAdministratorsIcon,
        hoverIcon: insolvencyAdministratorsWhiteIcon,
      };
    case 'i-want-to-sell-individual':
      return {
        icon: individualPersonIcon,
        hoverIcon: individualPersonWhiteIcon,
      };
    case 'i-want-to-sell-legal-person':
      return {
        icon: legalPersonIcon,
        hoverIcon: legalPersonWhiteIcon,
      };
    case 'i-want-to-sell-real-estate-agencies':
      return {
        icon: realEstateAgenciesIcon,
        hoverIcon: realEstateAgenciesWhiteIcon,
      };
    default:
      return {
        icon: auctionIcon,
        hoverIcon: auctionWhiteIcon,
      };
  }
};
