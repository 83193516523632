import { AxiosResponse } from 'axios';

import { API, APIResponse, BaseAPIResponse, useApi } from '@api/api';
import {
  AuctioneerFilters,
  Auctioneer,
  AuctioneerDetail,
  AuctioneerAddress,
  AuctioneerUser,
  AuctionService,
  AuctionServiceUpdate,
  AuctioneerType,
  AuctionType,
  DocumentCreate,
  AuctioneerUserResponse,
  Document,
  AuctioneerAddressRequest,
  AuctionResponse,
  WebAuctionsFilter,
  WebAuctioneerFilters,
  AuctioneerUserContactResponse,
  AuctioneerPDData,
  HashPDResponse,
  AuctioneerTemplate,
  AuctionTypeResponse,
  CSASAuthResponse,
  CSASAccount,
  AuctioneerStates,
  Licitator,
} from '@types';
import { useGridFilter } from '@stores';

export const auctioneerTypesPath = () => `/enum/auctioneer/types`;

export const auctionStatusGroupPath = (statusGroup: string) => `/enum/auction/status/group/${statusGroup}`;

export const auctioneersPath = () => `/admin/auctioneer`;
export const auctioneersByAuctionTypePath = (auctionType: 'auction' | 'auction_public') =>
  `/admin/auction/auctioneers/${auctionType}`;

export const auctioneerPath = (auctioneerId: number | string) => `/admin/auctioneer/${auctioneerId}`;

export const auctioneerRestorePath = (auctioneerId: number | string) => `/admin/auctioneer/${auctioneerId}/restore`;

export const auctioneerEstablishmentsPath = (auctioneerId: number | string) =>
  `/admin/auctioneer/${auctioneerId}/establishments`;

export const auctioneerEstablishmentPath = (auctioneerId: number | string, id: number | string) =>
  `/admin/auctioneer/${auctioneerId}/establishment/${id}`;

export const auctioneerEmployeesPath = (auctioneerId: number | string) => `/admin/auctioneer/${auctioneerId}/employee`;

export const auctioneerEmployeesContactPath = (auctioneerId: number | string) =>
  `/admin/auctioneer/${auctioneerId}/employee-contact`;

export const auctioneerEmployeePath = (auctioneerId: number | string, userId: number | string) =>
  `/admin/auctioneer/${auctioneerId}/employee/${userId}`;

export const auctioneerEmployeeRestorePath = (auctioneerId: number | string, userId: number | string) =>
  `/admin/auctioneer/${auctioneerId}/employee/${userId}/restore`;

export const auctioneerAuctionTypesPath = (auctioneerId: number | string) =>
  `/admin/auctioneer/${auctioneerId}/auction-type`;

export const auctioneerServicesPath = (auctioneerId: number | string) => `/admin/auctioneer/${auctioneerId}/services`;

export const auctioneerServiceUpdatePath = (auctioneerId: number | string, auctioneerServiceId: number | string) =>
  `/admin/auctioneer/${auctioneerId}/service/${auctioneerServiceId}`;

export const auctioneerDocumentsPath = (auctioneerId: number | string) => `/admin/auctioneer/${auctioneerId}/document`;

export const auctioneerDocumentPath = (auctioneerId: number | string, documentId: number | string) =>
  `/admin/auctioneer/${auctioneerId}/document/${documentId}`;

export const auctioneersWebPath = () => `/web/auctioneer`;

export const auctioneerWebAuctions = (auctioneerId: number | string) => `/web/auctioneer/${auctioneerId}/auction`;

export const auctioneerPDDataPath = (auctioneerId: number | string) =>
  `/admin/auctioneer/${auctioneerId}/portal-drazeb`;
export const getCSASAuthPath = (auctioneerId: number | string) => `/admin/auctioneer/${auctioneerId}/csas/auth`;
export const getCSASAccountPath = (auctioneerId: number | string) => `/admin/auctioneer/${auctioneerId}/csas/account`;
export const getCSASAccountsPath = (auctioneerId: number | string) => `/admin/auctioneer/${auctioneerId}/csas/accounts`;
export const getCSASTokenPath = () => `/admin/auctioneer/csas/code`;
export const getFioPath = (auctioneerId: number | string) => `/admin/auctioneer/${auctioneerId}/fio`;

export const auctioneerPDResponsiblePersonsPath = (auctioneerId: number | string) =>
  `admin/auctioneer/${auctioneerId}/portal-drazeb/responsible-persons`;

export const auctioneerEmployeeResponsiblePersonPath = (auctioneerId: number | string) =>
  `admin/auctioneer/${auctioneerId}/employee-responsible-person`;

export const auctioneerTemplatesPath = (auctioneerId: number | string) =>
  `/admin/auctioneer/${auctioneerId}/auction-template`;
export const auctioneerTemplatePath = (auctioneerId: number | string, auctionTemplateId: number | string) =>
  `/admin/auctioneer/${auctioneerId}/auction-template/${auctionTemplateId}`;
export const auctioneerTemplateCopyPath = (auctioneerId: number | string, auctionTemplateId: number | string) =>
  `/admin/auctioneer/${auctioneerId}/auction-template/${auctionTemplateId}/copy`;
export const auctioneerStatesPath = () => `/enum/auctioneer/states`;
export const licitatorDetailPath = (auctioneerId: number | string) => `/admin/auctioneer/${auctioneerId}/licitator`;

export interface AuctioneersAPI extends API {
  listByAuctionType(auctionType: 'auction' | 'auction_public', auctionId?: string): APIResponse<Array<Auctioneer>>;
  list(filters: AuctioneerFilters): APIResponse<Array<Auctioneer>>;
  getAuctionStatusGroup(statusGroup: string): APIResponse<Array<AuctionTypeResponse>>;
  types(): APIResponse<Array<AuctioneerType>>;
  create(data: Partial<Auctioneer>): APIResponse<Auctioneer>;
  update(id: string, data: Partial<Auctioneer>): APIResponse<Auctioneer>;
  delete(id: string): APIResponse;
  restore(id: string): APIResponse;
  detail(id: string): APIResponse<AuctioneerDetail>;
  establishmentsList(auctioneerId: string): APIResponse<Array<AuctioneerAddress>>;
  establishmentCreate(
    auctioneerId: string,
    data: Partial<AuctioneerAddressRequest>
  ): APIResponse<Array<AuctioneerAddress>>;
  establishmentUpdate(
    auctioneerId: string,
    id: string,
    data: Partial<AuctioneerAddressRequest>
  ): APIResponse<Array<AuctioneerAddress>>;
  establishmentDelete(auctioneerId: string, id: string): APIResponse;
  usersList(auctioneerId: string): Promise<AxiosResponse<Array<AuctioneerUserResponse>>>;
  usersContactList(auctioneerId: string): Promise<AxiosResponse<Array<AuctioneerUserContactResponse>>>;
  usersResponsiblePersonList(auctioneerId: string): Promise<AxiosResponse<Array<AuctioneerUserContactResponse>>>;
  responsiblePersonHashPdList(auctioneerId: string): Promise<APIResponse<Array<HashPDResponse>>>;
  usersCreate(auctioneerId: string, data: Partial<AuctioneerUser>): APIResponse<Array<AuctioneerUserResponse>>;
  usersUpdate(
    auctioneerId: string,
    userId: string,
    data: Partial<AuctioneerUser>
  ): APIResponse<Array<AuctioneerUserResponse>>;
  usersDelete(auctioneerId: string, userId: string): APIResponse;
  userRestore(auctioneerId: string, userId: string): APIResponse;
  auctionTypesList(auctioneerId: string): APIResponse<Array<AuctionType>>;
  auctionTypesUpdate(auctioneerId: string, data: Array<string>): APIResponse<Array<Auctioneer>>;
  servicesList(auctioneerId: string): APIResponse<Array<AuctionService>>;
  servicesUpdate(
    auctioneerId: string,
    auctioneerServiceId: string,
    data: Partial<AuctionServiceUpdate>
  ): APIResponse<Array<AuctionService>>;
  documentsList(auctioneerId: string): Promise<AxiosResponse<Array<Document>>>;
  documentsCreate(auctioneerId: string, data: Partial<DocumentCreate>): APIResponse<Array<Document>>;
  documentsDelete(auctioneerId: string, documentId: string): APIResponse;
  getDocument(auctioneerId: string | number, documentId: string | number): Promise<AxiosResponse<any>>;
  getWebAuctioneers(filters: AuctioneerFilters): APIResponse<Array<Auctioneer>>;
  getWebAuctioneerAuctions(
    auctioneerId: string | number,
    params: WebAuctionsFilter,
    sortProp?: string,
    sortDirection?: string
  ): APIResponse<Array<AuctionResponse>>;
  getPDData(auctioneerId: string): APIResponse<AuctioneerPDData>;
  getCSASToken(code: string, state: string): APIResponse<{ auctioneerId: string }>;
  getCSASAuth(auctioneerId: string | number): BaseAPIResponse<CSASAuthResponse>;
  getCSASAccounts(auctioneerId: string | number): BaseAPIResponse<CSASAccount[]>;
  updateCSASAccount(auctioneerId: string | number, accountId: string): APIResponse;
  updateFioAccount(auctioneerId: string | number, accessToken: string): APIResponse;
  getFioToken(auctioneerId: string | number, email: string, password: string): BaseAPIResponse<{ accessToken: string }>;
  deleteFioToken(auctioneerId: string | number): APIResponse;
  createPDData(auctioneerId: string, data: Partial<AuctioneerPDData>): APIResponse;
  updatePDData(auctioneerId: string, data: Partial<AuctioneerPDData>): APIResponse;
  deletePDData(auctioneerId: string): APIResponse;
  listTemplates(auctioneerId: string): APIResponse<Array<AuctioneerTemplate>>;
  detailTemplate(auctioneerId: string, auctionTemplateId: string): APIResponse<AuctioneerTemplate>;
  createTemplate(name: string, auctionType: string, values: any, auctioneerId: string): APIResponse<AuctioneerTemplate>;
  updateTemplate(
    name: string,
    auctionType: string,
    isDefault: boolean,
    values: any,
    auctioneerId: string,
    auctionTemplateId: string
  ): APIResponse<AuctioneerTemplate>;
  deleteTemplate(auctioneerId: string, auctionTemplateId: string): APIResponse<AuctioneerTemplate>;
  copyTemplate(auctioneerId: string, auctionTemplateId: string): APIResponse<AuctioneerTemplate>;
  getAuctioneerStates(): APIResponse<AuctioneerStates[]>;
  licitatorDetail(auctioneerId: number | string): APIResponse<Licitator>;
  licitatorUpdate(auctioneerId: number | string, data: Licitator): APIResponse;
}

export const useAuctioneersApi = (): AuctioneersAPI => {
  const api = useApi();

  const webAuctionsFilter = useGridFilter<WebAuctionsFilter>([
    {
      group: 'search',
      key: 'search',
      operator: 'like',
      property: 'auction.title',
      value: (val) => (!!val ? `%${val}%` : undefined),
    },
    {
      group: 'search',
      key: 'search',
      operator: 'like',
      property: 'auction.number',
      value: (val) => (!!val ? `%${val.replaceAll(' ', '')}%` : undefined),
    },
    {
      type: 'int',
      key: 'priceFrom',
      operator: 'gte',
      property: 'auction.minimalBid',
    },
    {
      type: 'int',
      key: 'priceTo',
      operator: 'lte',
      property: 'auction.minimalBid',
    },
    {
      group: 'statusGroup',
      type: 'string',
      key: 'status',
      property: 'auction.status',
    },
    {
      type: 'int',
      key: 'mainCategory',
      property: 'auctionCategory.parent',
      operator: 'eq',
    },
    {
      group: 'regions',
      type: 'int',
      key: 'regions',
      property: 'auctionAddress.region',
      operator: 'eq',
    },
    {
      group: 'district',
      type: 'int',
      key: 'districts',
      property: 'auctionAddress.district',
      operator: 'eq',
    },
    {
      group: 'subCategories',
      type: 'int',
      key: 'subCategories',
      property: 'auctionCategory.id',
      operator: 'eq',
    },
    {
      group: 'auctionType',
      type: 'string',
      key: 'auctionType',
      property: 'auction.auctionType',
      operator: 'eq',
    },
  ]);

  const webAuctioneersFilter = useGridFilter<WebAuctioneerFilters>([
    {
      group: 'search',
      key: 'search',
      property: 'auctioneer.auctioneerName',
      operator: 'like',
      value: (val) => (!!val ? `%${val}%` : undefined),
    },
    {
      group: 'search',
      key: 'search',
      property: 'auctioneer.email',
      operator: 'like',
      value: (val) => (!!val ? `%${val}%` : undefined),
    },
    {
      group: 'search',
      key: 'search',
      property: 'auctioneer.phone',
      operator: 'like',
      value: (val) => (!!val ? `%${val}%` : undefined),
    },
    {
      group: 'regions',
      key: 'regions',
      property: 'invoiceAddress.region',
      operator: 'eq',
    },
  ]);

  const getFilterOperator = (prop: string): string => {
    switch (prop) {
      case 'auctioneer':
      case 'testDate':
      case 'invoice':
      case 'noInvoice':
      case 'state':
      case 'type':
      case 'regions':
        return 'eq';
      case 'testDateFrom':
        return 'gte';
      case 'testDateTo':
        return 'lte';
      default:
        return 'like';
    }
  };

  const getFilterType = (prop: string): string => {
    switch (prop) {
      case 'testDate':
      case 'invoice':
      case 'noInvoice':
        return 'boolean';
      case 'testDateFrom':
      case 'testDateTo':
        return 'date';
      default:
        return 'string';
    }
  };

  const getFilterProperty = (prop: string): string => {
    switch (prop) {
      case 'state':
        return 'auctioneer.state';
      case 'ic':
        return 'auctioneer.ic';
      case 'auctioneerName':
        return 'auctioneer.auctioneerName';
      case 'email':
        return 'auctioneer.email';
      case 'type':
        return 'auctioneer.type';
      case 'invoice':
        return 'auctioneer.invoice';
      case 'noInvoice':
        return 'auctioneer.invoice';
      case 'testDate':
        return 'auctioneer.testDate';
      case 'testDateFrom':
        return 'auctioneer.testDateFrom';
      case 'testDateTo':
        return 'auctioneer.testDateTo';
      case 'regions':
        return 'auctioneerAddress.region';
      default:
        return prop;
    }
  };

  const getFilteValue = (prop: string, value: string | boolean): string | boolean | undefined => {
    if (prop === 'noInvoice' && value === true) {
      return false;
    }
    if (prop === 'email' && value) {
      return `%${value}%`;
    }
    if (prop === 'auctioneerName' && value) {
      return `%${value}%`;
    }
    if (value === false) {
      return undefined;
    }
    return value;
  };

  return {
    ...api,
    listByAuctionType(auctionType: 'auction' | 'auction_public', auctionId?: string) {
      const cancelToken = api.prepareCancelToken('listByAuctionType');
      return api.authRequest.get(auctioneersByAuctionTypePath(auctionType), { cancelToken, params: { auctionId } });
    },
    list(params: AuctioneerFilters) {
      const cancelToken = api.prepareCancelToken('list');

      let queryParams: any = { ...params };
      delete queryParams.page;
      delete queryParams.perPage;
      const filters = Object.getOwnPropertyNames(queryParams)
        .map((prop) => ({
          operator: getFilterOperator(prop),
          value: getFilteValue(prop, queryParams[prop]),
          property: getFilterProperty(prop),
          type: getFilterType(prop),
        }))
        .filter((item) => item.value !== undefined);

      return api.authRequest.get(auctioneersPath(), {
        cancelToken,
        params: {
          page: params.page,
          perPage: params.perPage,
          sort: JSON.stringify({ property: 'auctioneer.id', direction: 'ASC' }),
          filter: filters.length > 0 ? JSON.stringify(filters) : undefined,
        },
      });
    },
    getAuctionStatusGroup(statusGroup: string) {
      const cancelToken = api.prepareCancelToken('getAuctionStatusGroup');
      return api.request.get(auctionStatusGroupPath(statusGroup), { cancelToken });
    },
    types() {
      const cancelToken = api.prepareCancelToken('types');
      return api.authRequest.get(auctioneerTypesPath(), { cancelToken });
    },
    create(data: Partial<Auctioneer>) {
      const cancelToken = api.prepareCancelToken('create');
      if (data.type === 'executor') {
        delete data.secondPartFileMark;
      }
      return api.authRequest.post(auctioneersPath(), data, { cancelToken });
    },
    update(auctioneerId: string, data: Partial<Auctioneer>) {
      const cancelToken = api.prepareCancelToken('update');
      if (data.type === 'executor') {
        delete data.secondPartFileMark;
      }
      return api.authRequest.put(auctioneerPath(auctioneerId), data, { cancelToken });
    },
    delete(auctioneerId: string) {
      const cancelToken = api.prepareCancelToken('delete');
      return api.authRequest.delete(auctioneerPath(auctioneerId), { cancelToken });
    },
    restore(auctioneerId: string) {
      const cancelToken = api.prepareCancelToken('restore');
      return api.authRequest.get(auctioneerRestorePath(auctioneerId), { cancelToken });
    },
    detail(auctioneerId: number | string) {
      const cancelToken = api.prepareCancelToken('detail');
      return api.authRequest.get(auctioneerPath(auctioneerId), { cancelToken });
    },
    establishmentsList(auctioneerId: number | string) {
      const cancelToken = api.prepareCancelToken('establishmentsList');
      return api.authRequest.get(auctioneerEstablishmentsPath(auctioneerId), { cancelToken });
    },
    establishmentCreate(auctioneerId: number | string, data: Partial<AuctioneerAddressRequest>) {
      const cancelToken = api.prepareCancelToken('establishmentCreate');
      return api.authRequest.post(auctioneerEstablishmentsPath(auctioneerId), data, { cancelToken });
    },
    establishmentUpdate(auctioneerId: number | string, id: number | string, data: Partial<AuctioneerAddressRequest>) {
      const cancelToken = api.prepareCancelToken('establishmentUpdate');
      return api.authRequest.put(auctioneerEstablishmentPath(auctioneerId, id), data, { cancelToken });
    },
    establishmentDelete(auctioneerId: number | string, id: number | string) {
      const cancelToken = api.prepareCancelToken('establishmentDelete');
      return api.authRequest.delete(auctioneerEstablishmentPath(auctioneerId, id), { cancelToken });
    },
    usersList(auctioneerId: number | string) {
      const cancelToken = api.prepareCancelToken('usersList');
      return api.authRequest.get(auctioneerEmployeesPath(auctioneerId), { cancelToken });
    },
    usersContactList(auctioneerId: number | string) {
      const cancelToken = api.prepareCancelToken('usersContactList');
      return api.authRequest.get(auctioneerEmployeesContactPath(auctioneerId), { cancelToken });
    },
    usersResponsiblePersonList(auctioneerId: number | string) {
      const cancelToken = api.prepareCancelToken('usersResponsiblePersonList');
      return api.authRequest.get(auctioneerEmployeeResponsiblePersonPath(auctioneerId), { cancelToken });
    },
    responsiblePersonHashPdList(auctioneerId: number | string) {
      const cancelToken = api.prepareCancelToken('responsiblePersonHashPdList');
      return api.authRequest.get(auctioneerPDResponsiblePersonsPath(auctioneerId), { cancelToken });
    },
    usersCreate(auctioneerId: number | string, data: Partial<AuctioneerUser>) {
      const cancelToken = api.prepareCancelToken('usersCreate');
      return api.authRequest.post(auctioneerEmployeesPath(auctioneerId), data, { cancelToken });
    },
    usersUpdate(auctioneerId: number | string, userId: number | string, data: Partial<AuctioneerUser>) {
      const cancelToken = api.prepareCancelToken('usersUpdate');
      return api.authRequest.put(auctioneerEmployeePath(auctioneerId, userId), data, { cancelToken });
    },
    usersDelete(auctioneerId: number | string, userId: number | string) {
      const cancelToken = api.prepareCancelToken('usersDelete');
      return api.authRequest.delete(auctioneerEmployeePath(auctioneerId, userId), { cancelToken });
    },
    userRestore(auctioneerId: number | string, userId: number | string) {
      const cancelToken = api.prepareCancelToken('userRestore');
      return api.authRequest.get(auctioneerEmployeeRestorePath(auctioneerId, userId), { cancelToken });
    },
    auctionTypesList(auctioneerId: number | string) {
      const cancelToken = api.prepareCancelToken('auctionTypesList');
      return api.authRequest.get(auctioneerAuctionTypesPath(auctioneerId), { cancelToken });
    },
    auctionTypesUpdate(auctioneerId: number | string, data: Array<string>) {
      const cancelToken = api.prepareCancelToken('auctionTypesUpdate');
      return api.authRequest.put(
        auctioneerAuctionTypesPath(auctioneerId),
        {
          auctioneerAuctionTypes: data.map((item) => ({ auctionType: item })),
        },
        { cancelToken }
      );
    },
    servicesList(auctioneerId: number | string) {
      const cancelToken = api.prepareCancelToken('servicesList');
      return api.authRequest.get(auctioneerServicesPath(auctioneerId), { cancelToken });
    },
    servicesUpdate(
      auctioneerId: number | string,
      auctioneerServiceId: number | string,
      data: Partial<AuctionServiceUpdate>
    ) {
      const cancelToken = api.prepareCancelToken('servicesUpdate');
      return api.authRequest.put(auctioneerServiceUpdatePath(auctioneerId, auctioneerServiceId), data, { cancelToken });
    },
    documentsList(auctioneerId: string) {
      const cancelToken = api.prepareCancelToken('documentsList');
      return api.authRequest.get(auctioneerDocumentsPath(auctioneerId), { cancelToken });
    },
    documentsCreate(auctioneerId: string, data: Partial<DocumentCreate>) {
      const cancelToken = api.prepareCancelToken('documentsCreate');
      return api.authRequest.post(auctioneerDocumentsPath(auctioneerId), data, { cancelToken });
    },
    documentsDelete(auctioneerId: number | string, documentId: number | string) {
      const cancelToken = api.prepareCancelToken('documentsDelete');
      return api.authRequest.delete(auctioneerDocumentPath(auctioneerId, documentId), { cancelToken });
    },
    getDocument(auctionId: string | number, documentId: string | number) {
      return api.authRequest.get(auctioneerDocumentPath(auctionId, documentId), {
        params: { view: true },
        responseType: 'blob',
      });
    },
    getWebAuctioneers(params: AuctioneerFilters) {
      const cancelToken = api.prepareCancelToken('getWebAuctioneers');
      return api.authRequest.get(auctioneersWebPath(), {
        cancelToken,
        params: {
          page: params.page,
          perPage: params.perPage,
          sort: JSON.stringify({ property: 'auctioneer.id', direction: 'ASC' }),
          filter: !webAuctioneersFilter.isEmpty(params) ? webAuctioneersFilter.toString(params) : undefined,
        },
      });
    },
    async getWebAuctioneerAuctions(
      auctioneerId: string | number,
      params: WebAuctionsFilter,
      sortProp?: string,
      sortDirection?: string
    ) {
      const cancelToken = api.prepareCancelToken('getWebAuctioneerAuctions');
      return api.authFrontRequest.get(auctioneerWebAuctions(auctioneerId), {
        cancelToken,
        params: {
          page: params.page,
          perPage: params.perPage,
          sort: JSON.stringify({ property: sortProp || 'auction.id', direction: sortDirection || 'DESC' }),
          filter: !webAuctionsFilter.isEmpty(params) ? webAuctionsFilter.toString(params) : undefined,
        },
      });
    },
    getPDData(auctioneerId: number | string) {
      const cancelToken = api.prepareCancelToken('getPDData');
      return api.authRequest.get(auctioneerPDDataPath(auctioneerId), { cancelToken });
    },
    getCSASAuth(auctioneerId: number | string) {
      const cancelToken = api.prepareCancelToken('getCSASAuth');
      return api.authRequest.get(getCSASAuthPath(auctioneerId), { cancelToken });
    },
    getCSASToken(code: string, state: string) {
      const cancelToken = api.prepareCancelToken('getCSASToken');
      return api.authRequest.get(getCSASTokenPath(), { cancelToken, params: { code, state } });
    },
    getCSASAccounts(auctioneerId: string | number) {
      const cancelToken = api.prepareCancelToken('getCSASAccounts');
      return api.authRequest.get(getCSASAccountsPath(auctioneerId), { cancelToken });
    },
    updateCSASAccount(auctioneerId: string | number, accountId: string) {
      const cancelToken = api.prepareCancelToken('updateCSASAccount');
      return api.authRequest.put(getCSASAccountPath(auctioneerId), { accountId }, { cancelToken });
    },
    updateFioAccount(auctioneerId: string | number, accessToken: string) {
      const cancelToken = api.prepareCancelToken('updateFioAccount');
      return api.authRequest.put(getFioPath(auctioneerId), { accessToken }, { cancelToken });
    },
    getFioToken(auctioneerId: string | number, email: string, password: string) {
      const cancelToken = api.prepareCancelToken('getFioToken');
      return api.authRequest.post(getFioPath(auctioneerId), { email, password }, { cancelToken });
    },
    deleteFioToken(auctioneerId: string | number) {
      const cancelToken = api.prepareCancelToken('deleteFioToken');
      return api.authRequest.delete(getFioPath(auctioneerId), { cancelToken });
    },
    createPDData(auctioneerId: number | string, data: Partial<AuctioneerPDData>) {
      const cancelToken = api.prepareCancelToken('createPDData');
      return api.authRequest.post(auctioneerPDDataPath(auctioneerId), data, { cancelToken });
    },
    updatePDData(auctioneerId: number | string, data: Partial<AuctioneerPDData>) {
      const cancelToken = api.prepareCancelToken('updatePDData');
      return api.authRequest.put(auctioneerPDDataPath(auctioneerId), data, { cancelToken });
    },
    deletePDData(auctioneerId: number | string) {
      const cancelToken = api.prepareCancelToken('detelePDData');
      return api.authRequest.delete(auctioneerPDDataPath(auctioneerId), { cancelToken });
    },
    listTemplates(auctioneerId: number | string) {
      const cancelToken = api.prepareCancelToken('listTemplates');
      return api.authRequest.get(auctioneerTemplatesPath(auctioneerId), { cancelToken });
    },
    createTemplate(name: string, auctionType: string, values: any, auctioneerId: number | string) {
      const cancelToken = api.prepareCancelToken('createTemplate');
      return api.authRequest.post(
        auctioneerTemplatesPath(auctioneerId),
        { name: name, auctionType: auctionType, data: JSON.stringify(values) },
        { cancelToken }
      );
    },
    detailTemplate(auctioneerId: number | string, auctionTemplateId: number | string) {
      const cancelToken = api.prepareCancelToken('detailTemplate');
      return api.authRequest.get(auctioneerTemplatePath(auctioneerId, auctionTemplateId), { cancelToken });
    },
    updateTemplate(
      name: string,
      auctionType: string,
      isDefault: boolean,
      values: any,
      auctioneerId: number | string,
      auctionTemplateId: number | string
    ) {
      const cancelToken = api.prepareCancelToken('updateTemplate');
      return api.authRequest.put(
        auctioneerTemplatePath(auctioneerId, auctionTemplateId),
        { name: name, auctionType: auctionType, isDefault: isDefault, data: JSON.stringify(values) },
        { cancelToken }
      );
    },
    deleteTemplate(auctioneerId: number | string, auctionTemplateId: number | string) {
      const cancelToken = api.prepareCancelToken('deleteTemplate');
      return api.authRequest.delete(auctioneerTemplatePath(auctioneerId, auctionTemplateId), { cancelToken });
    },
    copyTemplate(auctioneerId: number | string, auctionTemplateId: number | string) {
      const cancelToken = api.prepareCancelToken('copyTemplate');
      return api.authRequest.post(auctioneerTemplateCopyPath(auctioneerId, auctionTemplateId), { cancelToken });
    },
    getAuctioneerStates() {
      const cancelToken = api.prepareCancelToken('getAuctioneerStates');
      return api.request.get(auctioneerStatesPath(), { cancelToken });
    },
    licitatorDetail(auctioneerId: number | string) {
      const cancelToken = api.prepareCancelToken('detail');
      return api.authRequest.get(licitatorDetailPath(auctioneerId), { cancelToken });
    },
    licitatorUpdate(auctioneerId: number | string, data: Licitator) {
      const cancelToken = api.prepareCancelToken('update');
      return api.authRequest.put(licitatorDetailPath(auctioneerId), data, { cancelToken });
    },
  };
};
