import { API, APIResponse, useApi } from '@api/api';
import { AddressSuggestType, AddressType } from '@types';

export const gpsAddressPath = () => `/admin/address/gps-address`;
export const suggestAddressPath = () => `/admin/address/suggest-address`;

export interface AddressAPI extends API {
  gpsAddress(lat: number, long: number): APIResponse<AddressType>;
  suggestAddress(q: string): APIResponse<AddressSuggestType>;
}

export const useAddressApi = (): AddressAPI => {
  const api = useApi();
  return {
    ...api,
    gpsAddress(lat: number, long: number) {
      const cancelToken = api.prepareCancelToken('getAddress');
      return api.authRequest.get(gpsAddressPath(), { cancelToken, params: { lat, long } });
    },
    suggestAddress(q: string) {
      const cancelToken = api.prepareCancelToken('suggestAddress');
      return api.authRequest.get(suggestAddressPath(), { cancelToken, params: { q } });
    },
  };
};
