import React from 'react';
import clx from 'classnames';

import { AuctionResponse, WebInformationResponse } from '@types';

import Documents from './Documents';

interface Props {
  category: string;
  auction: AuctionResponse;
  className?: string;
  data: WebInformationResponse[];
}

const FrontAuctionInformation: React.FC<Props> = (props) => {
  if (props.data.length < 1) {
    return null;
  }

  const getTitle = () => {
    if (props.category === 'a') {
      return props.auction.translations?.auction_info_before_title || 'auction_info_before_title';
    } else {
      return props.auction.translations?.auction_info_after_title || 'auction_info_after_title';
    }
  };

  return (
    <div className={clx(['component-front-auction-information', props.className])}>
      <h3 className="information-title">{getTitle()}</h3>

      {props.data.map((item, index) => (
        <div className="information-item" key={`item-${index}`}>
          <div className="item-text" dangerouslySetInnerHTML={{ __html: item.contentTranslation || item.content }} />
          <Documents documents={item.auctionInformationDocuments} />
        </div>
      ))}
    </div>
  );
};

export default FrontAuctionInformation;
