import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { Supplier } from '@types';
import { BasePreloader } from '@components';
import { useInvoiceApi } from '@api/invoice';
import BillingInformationsForm from './BillingInformationsForm';

const BillingInformations: React.FC = () => {
  const invoiceApi = useInvoiceApi();
  const [loaded, setLoaded] = React.useState(false);
  const [supplier, setSupplier] = React.useState<Supplier | null>(null);

  React.useEffect(() => {
    loadData();
    return () => invoiceApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      const response = await invoiceApi.getSupplier();
      setSupplier(response.data.data);
      setLoaded(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
      setLoaded(true);
    }
  };

  return (
    <div>
      <div>
        <h2>Fakturační údaje</h2>
      </div>
      <Row>
        <Col xs={12}>
          {loaded ? (
            <BillingInformationsForm data={supplier} />
          ) : (
            <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
              <BasePreloader />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default BillingInformations;
