import React, { ChangeEvent } from 'react';
import { Form } from 'react-bootstrap';
import { FormikErrors, FormikValues } from 'formik';

import { FormGroup, Select } from '@components';
import ControlFeedback from '@components/ControlFeedback/ControlFeedback';

interface Props {
  id: string;
  values: FormikValues;
  isSubmitting: boolean;
  errors: FormikErrors<FormikValues>;
  getTranslation: (key: string) => string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

const TemplatesSettings: React.FC<Props> = (props) => {
  const { values, setFieldValue, handleChange, getTranslation } = props;

  const userIdentificationOptions = [
    { value: 'vs', label: 'Variabilní symbol' },
    { value: 'ss', label: 'Specifický symbol' },
  ];

  function checkAll() {
    const userAuctionAuthorizationTypeArray = [
      'personally',
      'post',
      'formWithCertificate',
      'dataBoxWithCertificate',
      'dataBoxWithConvertDocument',
      'userAccountConvertDocument',
    ];
    setFieldValue('data.auctionAuthorizationsType', userAuctionAuthorizationTypeArray);
  }

  function handleCautionInCash(event: React.FormEvent<HTMLInputElement>) {
    setFieldValue('data.auctionCautionCash.enabled', event.currentTarget.checked);
  }

  function handleCautionBankAccount(event: React.FormEvent<HTMLInputElement>) {
    setFieldValue('data.auctionCautionBankAccount.enabled', event.currentTarget.checked);
  }

  function handleCautionBankGuarantee(event: React.FormEvent<HTMLInputElement>) {
    setFieldValue('data.auctionCautionBankGuarantee.enabled', event.currentTarget.checked);
  }

  function handleBankAccountNumber(event: React.FormEvent<HTMLInputElement>) {
    setFieldValue('data.auctionCautionBankAccount.bankAccountNumber', event.currentTarget.value);
  }

  function handleBankAccountCode(event: React.FormEvent<HTMLInputElement>) {
    setFieldValue('data.auctionCautionBankAccount.bankAccountCode', event.currentTarget.value);
  }

  function handleVariableSymbolType(value: string) {
    setFieldValue('data.auctionCautionBankAccount.variableSymbolType', value);
  }

  function handleSpecificSymbolType(value: string) {
    setFieldValue('data.auctionCautionBankAccount.specificSymbolType', value);
  }

  const isBankAccount = (): boolean => values.data.auctionCautionBankAccount.enabled;

  return (
    <div className="pb-4">
      <h2 className="f-weight-300 f-size-25 mt-30">{getTranslation('tab_settings_security_title')}</h2>
      <div className="mt-30">
        <Form.Check
          custom
          type="checkbox"
          label={getTranslation('tab_settings_security_personally')}
          className="mt-checkbox mr-4 mb-3"
          id="personally"
          name="[data.auctionAuthorizationsType]"
          value="personally"
          checked={values.data.auctionAuthorizationsType.includes('personally') || false}
          onChange={handleChange}
        />
        <Form.Check
          custom
          type="checkbox"
          label={getTranslation('tab_settings_security_post')}
          className="mt-checkbox mr-4 mb-3"
          id="post"
          value="post"
          name="[data.auctionAuthorizationsType]"
          checked={values.data.auctionAuthorizationsType.includes('post') || false}
          onChange={handleChange}
        />
        <Form.Check
          custom
          type="checkbox"
          label={getTranslation('tab_settings_security_data_box_with_certificate')}
          className="mt-checkbox mr-4 mb-3"
          id="dataBoxWithCertificate"
          value="dataBoxWithCertificate"
          name="[data.auctionAuthorizationsType]"
          checked={values.data.auctionAuthorizationsType.includes('dataBoxWithCertificate') || false}
          onChange={handleChange}
        />
        <Form.Check
          custom
          type="checkbox"
          label={getTranslation('tab_settings_security_form_with_certificate')}
          className="mt-checkbox mr-4 mb-3"
          id="formWithCertificate"
          value="formWithCertificate"
          name="[data.auctionAuthorizationsType]"
          checked={values.data.auctionAuthorizationsType.includes('formWithCertificate') || false}
          onChange={handleChange}
        />
        <Form.Check
          custom
          type="checkbox"
          label={getTranslation('tab_settings_security_data_box_with_convert_document')}
          className="mt-checkbox mr-4 mb-3"
          id="dataBoxWithConvertDocument"
          value="dataBoxWithConvertDocument"
          name="[data.auctionAuthorizationsType]"
          checked={values.data.auctionAuthorizationsType.includes('dataBoxWithConvertDocument') || false}
          onChange={handleChange}
        />
        <Form.Check
          custom
          type="checkbox"
          label={getTranslation('tab_settings_security_user_account_convert_document')}
          className="mt-checkbox mr-4 mb-3"
          id="userAccountConvertDocument"
          value="userAccountConvertDocument"
          name="[data.auctionAuthorizationsType]"
          checked={values.data.auctionAuthorizationsType.includes('userAccountConvertDocument') || false}
          onChange={handleChange}
        />
        <button type="button" className="btn btn-link" onClick={checkAll}>
          Vybrat vše
        </button>
        <h2 className="f-weight-300 f-size-25 mt-30">{getTranslation('tab_settings_caution_title')}</h2>
        <Form.Group className="mt-30">
          <Form.Check
            custom
            type="checkbox"
            label={getTranslation('tab_settings_caution_is_cash')}
            className="mt-checkbox mr-4 mb-3"
            id="inCash"
            value={'tab_settings_caution_is_cash'}
            name="auctionCautionsType.type"
            checked={values.data.auctionCautionCash.enabled}
            onChange={handleCautionInCash}
          />
          <Form.Check
            custom
            type="checkbox"
            id="bankGuarantee"
            name="auctionCautionsType.type"
            className="mt-checkbox mr-4 mb-3"
            value="auctionCautionBankGuarantee"
            checked={values.data.auctionCautionBankGuarantee.enabled}
            label={getTranslation('tab_settings_caution_is_bank_guarantee')}
            onChange={handleCautionBankGuarantee}
          />
          <Form.Check
            custom
            type="checkbox"
            label={getTranslation('tab_settings_caution_on_bank_account')}
            className="mt-checkbox mr-4 mb-3"
            id="onBankAccount"
            value={'tab_settings_caution_on_bank_account'}
            name="auctionCautionsType.type"
            checked={values.data.auctionCautionBankAccount.enabled}
            onChange={handleCautionBankAccount}
          />
        </Form.Group>
        <Form.Group className="f-inline-group">
          <Form.Label className="f-inline-label">
            {getTranslation('tab_settings_caution_label_bank_account')}
          </Form.Label>
          <div className="f-inline-control d-flex align-items-center mb-3 mb-md-0">
            <div>
              <FormGroup
                type="text"
                name="bankAccountNumber"
                controlClassName="w-max-315"
                controlOnly={true}
                readOnly={!isBankAccount()}
                error={props.errors.bankAccountNumber as string}
                value={values.data.auctionCautionBankAccount.bankAccountNumber || ''}
                onChange={handleBankAccountNumber}
              />
            </div>
            <span className="f-size-12 w-weight-400 ml-3 mr-3">/</span>
            <div>
              <FormGroup
                type="text"
                name="bankAccountCode"
                controlOnly={true}
                readOnly={!isBankAccount()}
                controlClassName="w-max-150"
                hideErrorMessage={!!props.errors.bankAccountNumber}
                error={props.errors.bankAccountCode as string}
                value={values.data.auctionCautionBankAccount.bankAccountCode || ''}
                onChange={handleBankAccountCode}
              />
            </div>
          </div>
        </Form.Group>

        <div>
          <Form.Group className="f-inline-group">
            <Form.Label className="f-inline-label text-left text-nowrap">Identifikátor dražitele *</Form.Label>
            <div className="f-inline-control">
              <div style={{ maxWidth: 355 }} data-test-id="auctionType">
                <Select
                  size="md"
                  options={userIdentificationOptions}
                  isInvalid={!!props.errors.auctionCautionBankAccount_buyerIdentifier}
                  readOnly={!isBankAccount()}
                  value={
                    userIdentificationOptions.find(
                      (i) => i.value === props.values.data.auctionCautionBankAccount.buyerIdentifier
                    ) || null
                  }
                  onChange={(val) => {
                    props.setFieldValue('data.auctionCautionBankAccount.buyerIdentifier', val?.value || 'vs');
                    props.setFieldValue('data.auctionCautionBankAccount.variableSymbolType', '');
                    props.setFieldValue('data.auctionCautionBankAccount.specificSymbolType', '');
                  }}
                />
                {!!props.errors.auctionType && (
                  <ControlFeedback type="invalid">{props.errors.buyerIdentifier}</ControlFeedback>
                )}
              </div>
            </div>
          </Form.Group>
        </div>

        <Form.Group className="f-inline-group">
          <Form.Label className="f-inline-label mb-0">
            {props.getTranslation('tab_settings_caution_label_variable_symbol')} *
          </Form.Label>
          <div className="f-inline-control d-flex align-items-center settings-group">
            {props.values.data.auctionCautionBankAccount.buyerIdentifier === 'vs' && (
              <Form.Check
                disabled={!isBankAccount()}
                custom
                type="radio"
                label={props.getTranslation('tab_settings_caution_label_variable_symbol_type_rico')}
                className="mr-4 radio-point"
                id="variableSymbolTypeRcico"
                name="variableSymbolType"
                checked={props.values.data.auctionCautionBankAccount.variableSymbolType === 'rc_ico'}
                onChange={() => handleVariableSymbolType('rc_ico')}
              />
            )}

            {props.values.data.auctionCautionBankAccount.buyerIdentifier === 'ss' && (
              <Form.Check
                disabled={!isBankAccount()}
                custom
                type="radio"
                label={props.getTranslation('tab_settings_caution_specific_symbol_auction_generated')}
                className="radio-point mr-4"
                id="variable_generated_auction"
                name="variableSymbolType"
                checked={props.values.data.auctionCautionBankAccount.variableSymbolType === 'generated_auction'}
                onChange={() => handleVariableSymbolType('generated_auction')}
              />
            )}

            <>
              <Form.Check
                disabled={!isBankAccount()}
                custom
                type="radio"
                label={props.getTranslation('tab_settings_caution_label_variable_symbol_type_custom')}
                className="mr-4 radio-point"
                id="variableSymbolTypeCustom"
                name="variableSymbolType"
                checked={props.values.data.auctionCautionBankAccount.variableSymbolType === 'own'}
                onChange={() => handleVariableSymbolType('own')}
              />
              <FormGroup
                type="text"
                name="customVariableSymbol"
                className="mb-0 w-min-200 mt-4 mb-3 mt-md-0 mb-md-0"
                value={props.values.data.auctionCautionBankAccount.variableSymbol || ''}
                error={props.errors.variableSymbol as string}
                readOnly={!isBankAccount() || props.values.data.auctionCautionBankAccount.variableSymbolType !== 'own'}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  props.setFieldValue('data.auctionCautionBankAccount.variableSymbol', e.target.value)
                }
              />
            </>

            {props.values.data.auctionCautionBankAccount.buyerIdentifier === 'vs' && (
              <Form.Check
                disabled={!isBankAccount()}
                custom
                type="radio"
                label={props.getTranslation('tab_settings_caution_specific_symbol_generated')}
                className="radio-point mr-4 "
                id="variable_generated_user"
                name="variableSymbolType"
                checked={props.values.data.auctionCautionBankAccount.variableSymbolType === 'generated_user'}
                onChange={() => handleVariableSymbolType('generated_user')}
              />
            )}
            {!!props.errors.variableSymbolType && (
              <ControlFeedback type="invalid" style={{ marginTop: 70 }}>
                {props.errors.variableSymbolType}
              </ControlFeedback>
            )}
          </div>
        </Form.Group>
        <Form.Group className="f-inline-group">
          <Form.Label className="f-inline-label mb-0">
            {props.getTranslation('tab_settings_caution_label_specific_symbol')} *
          </Form.Label>
          <div className="f-inline-control d-flex align-items-center settings-group">
            {props.values.data.auctionCautionBankAccount.buyerIdentifier === 'ss' && (
              <Form.Check
                disabled={!isBankAccount()}
                custom
                type="radio"
                label={props.getTranslation('tab_settings_caution_label_specific_symbol_type')}
                className="radio-point mr-4"
                id="rcico"
                name="specificSymbolType"
                checked={props.values.data.auctionCautionBankAccount.specificSymbolType === 'rc_ico'}
                onChange={() => handleSpecificSymbolType('rc_ico')}
              />
            )}

            {props.values.data.auctionCautionBankAccount.buyerIdentifier === 'vs' && (
              <Form.Check
                disabled={!isBankAccount()}
                custom
                type="radio"
                label={props.getTranslation('tab_settings_caution_specific_symbol_auction_generated')}
                className="radio-point mr-4"
                id="generated_auction"
                name="specificSymbolType"
                checked={props.values.data.auctionCautionBankAccount.specificSymbolType === 'generated_auction'}
                onChange={() => handleSpecificSymbolType('generated_auction')}
              />
            )}

            <>
              <Form.Check
                disabled={!isBankAccount()}
                custom
                type="radio"
                label={props.getTranslation('tab_settings_caution_label_specific_symbol_type_custom')}
                className="mr-4 radio-point"
                id="custom"
                name="specificSymbolType"
                checked={props.values.data.auctionCautionBankAccount.specificSymbolType === 'own'}
                onChange={() => handleSpecificSymbolType('own')}
              />
              <FormGroup
                readOnly={!isBankAccount() || props.values.data.auctionCautionBankAccount.specificSymbolType !== 'own'}
                type="text"
                name="customSpecificSymbol"
                className="mb-0 w-min-200 mt-4 mb-0 mt-md-0 mt-mb-0"
                value={props.values.data.auctionCautionBankAccount.specificSymbol || ''}
                error={props.errors.specificSymbol as string}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  props.setFieldValue('data.auctionCautionBankAccount.specificSymbol', e.target.value)
                }
              />
            </>

            {props.values.data.auctionCautionBankAccount.buyerIdentifier === 'ss' && (
              <Form.Check
                disabled={!isBankAccount()}
                custom
                type="radio"
                label={props.getTranslation('tab_settings_caution_specific_symbol_generated')}
                className="radio-point mr-4"
                id="generated_user"
                name="specificSymbolType"
                checked={props.values.data.auctionCautionBankAccount.specificSymbolType === 'generated_user'}
                onChange={() => handleSpecificSymbolType('generated_user')}
              />
            )}

            {!!props.errors.specificSymbolType && (
              <ControlFeedback type="invalid" style={{ marginTop: 70 }}>
                {props.errors.specificSymbolType}
              </ControlFeedback>
            )}
          </div>
        </Form.Group>
      </div>
    </div>
  );
};

export default TemplatesSettings;
