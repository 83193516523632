import { API, APIResponse, useApi } from '@api/api';
import { AuctionCategoryReponse, WebCategories, AuctionStatus } from '@types';

export const categoriesPath = () => `/admin/auction-category`;
export const categoryPath = (categoryId: string | number) => `/admin/auction-category/${categoryId}`;
export const webMainCategories = () => `/web/auction/category/counts`;
export const webSubCategories = (categoryId: string | number) => `/web/auction/category/${categoryId}/counts`;

export interface AuctionCategoriesAPI extends API {
  getCategories(parent?: string): APIResponse<Array<AuctionCategoryReponse>>;
  getCategory(categoryId: string | number): APIResponse<AuctionCategoryReponse>;
  getWebMainCategories(status?: keyof typeof AuctionStatus, auctioneer?: string): APIResponse<Array<WebCategories>>;
  getWebSubCategories(
    categoryId: string | number,
    status?: keyof typeof AuctionStatus,
    auctioneer?: string
  ): APIResponse<Array<WebCategories>>;
}

export const useAuctionCategoriesApi = (): AuctionCategoriesAPI => {
  const api = useApi();

  return {
    ...api,
    getCategories(parent?: string) {
      return api.authRequest.get(categoriesPath(), {
        params: {
          page: 1,
          perPage: 1000,
          filter: JSON.stringify([
            {
              operator: 'eq',
              value: parent || null,
              property: 'auctionCategory.parent',
            },
          ]),
        },
      });
    },
    getCategory(categoryId: string | number) {
      const cancelToken = api.prepareCancelToken('getCategory');
      return api.authRequest.get(categoryPath(categoryId), {
        cancelToken,
      });
    },
    getWebMainCategories(status?: keyof typeof AuctionStatus, auctioneer?: string) {
      const cancelToken = api.prepareCancelToken('getWebMainCategories');
      return api.authRequest.get(webMainCategories(), {
        cancelToken,
        params: { statusGroup: status || AuctionStatus.prepared, auctioneer },
      });
    },
    getWebSubCategories(categoryId: string | number, status?: keyof typeof AuctionStatus, auctioneer?: string) {
      const cancelToken = api.prepareCancelToken('getWebSubCategories');
      return api.authRequest.get(webSubCategories(categoryId), {
        cancelToken,
        params: { statusGroup: status || AuctionStatus.prepared, auctioneer },
      });
    },
  };
};
