import React from 'react';
import { useFormik } from 'formik';

import { Auctioneer } from '@types';
import { useAuctioneersApi } from '@api/auctioneers';
import { BasePreloader, Button, FormGroup } from '@components';

type Props = {
  id: string;
  auctioneer: Auctioneer;
  onSave: () => void;
  setShowFioAccount: (val: boolean) => void;
};

export const FioAccount: React.FC<Props> = (props) => {
  const auctioneersApi = useAuctioneersApi();
  const formik = useFormik({
    validateOnChange: false,
    onSubmit: () => handleSubmit(),
    initialValues: {
      accessToken: '',
    },
  });

  const handleSubmit = async () => {
    try {
      await auctioneersApi.updateFioAccount(props.auctioneer.id, formik.values.accessToken);
      await formik.setFieldValue('accessToken', '');
      props.onSave();
    } catch (err) {
      if (auctioneersApi.isCancel(err)) {
        return;
      }
      const errors = err?.response?.data?.errors;
      formik.setErrors(errors || {});
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <h2>Propojení bankovního účtu FIO</h2>

        <FormGroup
          required
          label="Token"
          name="accessToken"
          autoComplete="off"
          className="d-block mt-3"
          labelClassName="text-left mb-2"
          value={formik.values.accessToken}
          error={formik.errors.accessToken}
          onChange={formik.handleChange}
        />

        <div className="mt-5">
          <Button
            type="button"
            className="float-left"
            variant="btn-outline-default"
            onClick={() => props.setShowFioAccount(false)}
          >
            Zrušit
          </Button>
          {formik.isSubmitting ? (
            <div className="float-right">
              <BasePreloader />
            </div>
          ) : (
            <Button type="submit" className="float-right" variant="btn-outline-primary">
              Uložit údaje
            </Button>
          )}
          <div className="clearfix" />
        </div>
      </div>
    </form>
  );
};
