import { useLocation, useHistory } from 'react-router-dom';
import { Container } from 'react-bootstrap';

import * as routes from '@routes';

import { SEO } from '@components';
import { usePageStore } from '@stores';
import { HintSubPageMenu } from './HintSubPageMenu';
import { HintSubPageContent } from './HintSubPageContent';

export const HintDetail = () => {
  const pageState = usePageStore();
  let history = useHistory();
  const location = useLocation();
  const page = pageState.pages.find((subPage) => subPage.fullUrl === location.pathname);
  const subPages = pageState.getPagesByParentPageId(page?.id || 0);

  if (page === undefined) {
    history.push(pageState.getPagePath(routes.front.HINT));
  }

  return (
    <>
      <SEO title={page?.name} />
      <Container>
        {page &&
          (subPages.length > 0 ? (
            <HintSubPageMenu page={page} subPages={subPages} />
          ) : (
            <HintSubPageContent page={page} />
          ))}
      </Container>
    </>
  );
};
