import React, { useState } from 'react';
import { FormikErrors, FormikValues } from 'formik';

import Wysiwyg from '@components/Wysiwyg/Wysiwyg';
import { ModalProps } from '@components/Modal/Modal';
import { useAuctioneersApi } from '@api/auctioneers';
import { Button, FormGroup, Modal } from '@components';
import { AuctioneerTemplate, AuctionInformationsCType, BaseObjectType } from '@types';

interface Props extends ModalProps {
  id: string;
  updatedItem?: AuctioneerTemplate;
  item?: AuctionInformationsCType;
  auctionType: 'auction' | 'auction_public';
  onClose: () => void;
  values: FormikValues;
  isSubmitting: boolean;
  errors: FormikErrors<FormikValues>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  auctionTemplateId?: string;
}

const TemplatesInformationForm: React.FC<Props> = (props) => {
  const auctioneersApi = useAuctioneersApi();
  const [title, setTitle] = useState(props.item?.title || '');
  const [text, setText] = useState(props.item?.text || '');
  const [errors, setErrors] = useState<BaseObjectType>({});

  const handleSubmit = () => {
    let valid = true;
    const errors: BaseObjectType = {};

    if (title.trim() === '') {
      errors.title = 'Název musí být vyplněn.';
      valid = false;
    }
    if (text.trim() === '') {
      errors.text = 'Text musí být vyplněn.';
      valid = false;
    }

    setErrors(errors);
    if (!valid) {
      return;
    }

    const templateInputs = { ...(props.updatedItem || {}) };
    templateInputs.data = JSON.parse(props.updatedItem?.data || {});

    if (props.item) {
      const informations = props.values.data.informations;
      const index = informations.findIndex((a: AuctionInformationsCType) => a.id === props.item!.id);
      informations[index].title = title;
      informations[index].text = text;
      templateInputs.data.informations = informations;
    }
    !!props.auctionTemplateId &&
      auctioneersApi
        .updateTemplate(
          templateInputs?.name || '',
          templateInputs?.auctionType || '',
          templateInputs?.isDefault || false,
          templateInputs.data,
          props.id,
          props.auctionTemplateId
        )
        .then(() => {
          props.setShowForm(false);
        })
        .catch((err) => {
          if (auctioneersApi.isCancel(err)) {
            return;
          }
        });
  };

  return (
    <Modal {...props} onRequestClose={props.onClose} className="modal-content-lg invoice-modal-content">
      <div>
        <div className="pl-2">
          <h2 className="f-weight-300 f-size-25">
            Informace typ C - (automatické možnosti na konci vydraženo/nevydraženo)
          </h2>
          <div className="mt-40">
            <div className="responsive-table-content">
              <div className="w-min-600 pt-2">
                <FormGroup
                  required
                  name="title"
                  label="Název"
                  labelClassName="text-left"
                  value={title}
                  error={errors.title}
                  onChange={(e: React.FormEvent<HTMLInputElement>) => setTitle(e.currentTarget.value)}
                />
                <Wysiwyg
                  required
                  name="content"
                  label="Text"
                  value={text}
                  error={errors.text}
                  onChange={(val) => setText(val)}
                  labelClassName="nowrap"
                />
              </div>
            </div>

            <div className="pt-3 d-flex flex-wrap justify-content-between">
              <Button type="button" onClick={() => props.onClose()}>
                Zpět na seznam
              </Button>
              <div>
                <Button onClick={handleSubmit}>Uložit</Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default TemplatesInformationForm;
