import React from 'react';
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import fileDownload from 'js-file-download';

import { AuctionSectionStep } from '../../ActiveAuctionPage/ActiveAuctionPage';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { useMediaApi } from '@api/media';
import { InvoiceResponse } from '@types';
import { useInvoiceApi } from '@api/invoice';
import { getCurrencyFormat } from '@helpers/formats';
import { getDaysTranslation } from '@helpers/translation';
import { getDateFormat, getDateTimeFormat } from '@helpers/datetime';
import TableColumn, { TableColumnSortProps } from '@components/Table/TableColumn';

import colorIcoEye from '@assets/images/color-ico-eye.svg';
import colorIcoPdf from '@assets/images/color-ico-pdf.svg';
import colorIcoConf from '@assets/images/color-ico-conf.svg';
import colorIcoGavel from '@assets/images/color-ico-gavel.svg';

interface Props {
  sort: TableColumnSortProps;
  data: Array<InvoiceResponse>;
  onSortChange: (sort: TableColumnSortProps) => void;
}

const InvoicesItemsTable: React.FC<Props> = (props) => {
  const invoiceApi = useInvoiceApi();
  const mediaApi = useMediaApi();
  const pageState = usePageStore();

  const handleFileClick = async (e: React.MouseEvent<HTMLAnchorElement>, auctionInvoiceId: number | string) => {
    e.preventDefault();
    const documentData = await invoiceApi.getInvoicePDF(auctionInvoiceId);
    mediaApi
      .detail(documentData.data.data.hash, true)
      .then((res) => {
        fileDownload(res.data, documentData.data.data.originalName);
      })
      .catch((err) => {
        if (invoiceApi.isCancel(err)) {
          return;
        }
        console.error(err);
      });
  };

  return (
    <div className="responsive-table-content">
      <Table className="table-middle" striped>
        <thead>
          <tr>
            <TableColumn
              sort={props.sort}
              title="Č. faktury"
              className="align-bottom"
              sortField="auctionInvoice.invoiceNumber"
              onSortChange={(val) => props.onSortChange(val)}
            />
            <TableColumn title="Jméno" className="align-bottom" />
            <TableColumn title="Celkem bez DPH" className="align-bottom" />
            <TableColumn title="DPH" className="align-bottom" />
            <TableColumn title="Celkem s DPH" className="align-bottom" />
            <TableColumn
              title="Datum a čas vystavení"
              className="align-bottom"
              sort={props.sort}
              sortField="auctionInvoice.timeCreated"
              onSortChange={(val) => props.onSortChange(val)}
            />
            <TableColumn
              title="Datum splatnosti"
              className="align-bottom"
              sort={props.sort}
              sortField="auctionInvoice.dateDue"
              onSortChange={(val) => props.onSortChange(val)}
            />
            <TableColumn
              title="Datum zapl"
              sort={props.sort}
              sortField="auctionInvoice.datePaid"
              className="align-bottom text-nowrap"
              onSortChange={(val) => props.onSortChange(val)}
            />
            <TableColumn title="Zaplaceno" className="align-bottom" />
            <TableColumn title="Vymáhano" className="align-bottom" />
            <TableColumn title="Splatnost" className="align-bottom" />
            <TableColumn title="dražba/aukce" className="align-bottom" />
            <th />
            <th />
          </tr>
        </thead>
        <tbody>
          {props.data.map((item, index) => (
            <tr key={`list-item-${index}`}>
              <td>{item.invoiceNumber}</td>
              <td>{item.auctionInvoiceSubscriber?.title || ''}</td>
              <td>{getCurrencyFormat(item.invoicedPrice)}</td>
              <td>{getCurrencyFormat(item.invoicedPriceVat)}</td>
              <td>{getCurrencyFormat(item.invoicedPriceWithVat)}</td>
              <td>{item.timeCreated ? getDateTimeFormat(item.timeCreated) : '-'}</td>
              <td>{item.dateDue ? getDateFormat(item.dateDue) : '-'}</td>
              <td>{item.datePaid ? getDateFormat(item.datePaid) : '-'}</td>
              <td>{item.paid ? 'Ano' : 'Ne'}</td>
              <td>{item.enforcement ? 'Ano' : 'Ne'}</td>
              <td>
                {!!item.datePaid ? (
                  'Zaplaceno'
                ) : (
                  <>
                    {item.due} {getDaysTranslation(item.due)}
                  </>
                )}
              </td>
              <td>
                {item.auction ? (
                  <>
                    {item.auction.state === 'active' ? (
                      <Link
                        target="_blank"
                        className={'d-inline-flex align-items-center'}
                        to={pageState.getPagePath(
                          item.auction.auctionType === 'auction'
                            ? routes.front.AUCTION_DETAIL
                            : routes.front.AUCTION_PUBLIC_DETAIL,
                          { ':id': item.auction.hash }
                        )}
                      >
                        <OverlayTrigger
                          key="table-permission"
                          placement="top"
                          overlay={
                            <Tooltip id="table-permission">
                              <div>Zobrazit na webu</div>
                            </Tooltip>
                          }
                        >
                          <img src={colorIcoEye} alt="ico" />
                        </OverlayTrigger>
                      </Link>
                    ) : (
                      <Link
                        target="_blank"
                        className={'d-inline-flex align-items-center disabled-action'}
                        to="/"
                        onClick={(event) => event.preventDefault()}
                      >
                        <OverlayTrigger
                          key="table-permission"
                          placement="top"
                          overlay={
                            <Tooltip id="table-permission">
                              <div>Zobrazit na webu</div>
                            </Tooltip>
                          }
                        >
                          <img src={colorIcoEye} alt="ico" />
                        </OverlayTrigger>
                      </Link>
                    )}

                    {item.auction.state === 'active' ? (
                      <Link
                        target="_blank"
                        className={'d-inline-flex align-items-center ml-4'}
                        to={`${pageState.getPagePath(routes.admin.AUCTIONS_ACTIVE_DETAIL, {
                          ':id': item.auction.id,
                        })}?s=${AuctionSectionStep.auctionProgress}`}
                      >
                        <OverlayTrigger
                          key="table-permission"
                          placement="top"
                          overlay={
                            <Tooltip id="table-permission">
                              <div>Přůběh {item.auction.auctionType === 'auction' ? 'aukce' : 'dražby'}</div>
                            </Tooltip>
                          }
                        >
                          <img src={colorIcoGavel} alt="ico" />
                        </OverlayTrigger>
                      </Link>
                    ) : (
                      <Link
                        className={'d-inline-flex align-items-center ml-4 disabled-action'}
                        to="/"
                        onClick={(event) => event.preventDefault()}
                      >
                        <OverlayTrigger
                          key="table-permission"
                          placement="top"
                          overlay={
                            <Tooltip id="table-permission">
                              <div>Přůběh {item.auction.auctionType === 'auction' ? 'aukce' : 'dražby'}</div>
                            </Tooltip>
                          }
                        >
                          <img src={colorIcoGavel} alt="ico" />
                        </OverlayTrigger>
                      </Link>
                    )}
                  </>
                ) : (
                  '-'
                )}
              </td>
              <td className="text-right">
                <Link
                  to="/"
                  className="d-inline-flex align-items-center ml-4"
                  onClick={(e) => handleFileClick(e, item.id)}
                >
                  <img src={colorIcoPdf} alt="ico" className="mr-2" />
                  Faktura
                </Link>
              </td>
              <td className="text-right">
                <Link
                  to={`${pageState.getPagePath(routes.admin.INVOICES_UPDATE, { ':id': item.id })}`}
                  className="d-inline-flex align-items-center ml-4"
                >
                  <img src={colorIcoConf} alt="ico" className="mr-2" />
                  Upravit
                </Link>
              </td>
            </tr>
          ))}
          {props.data.length < 1 && (
            <tr>
              <td className="text-left" colSpan={100}>
                Nebyla nalezena žádná data.
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default InvoicesItemsTable;
