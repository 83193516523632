import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import { Modal, Visible } from '@components';
import { stripeHtml } from '@helpers/stripeHtml';
import { AuctionInformationsCType, PermissionGroup, UserPermission } from '@types';

import colorIcoConf from '@assets/images/color-ico-conf.svg';
import colorIcoEye from '@assets/images/color-ico-eye.svg';

interface Props {
  data: Array<AuctionInformationsCType>;
  onUpdateClick: (item: AuctionInformationsCType) => void;
}

const InformationsTable: React.FC<Props> = (props) => {
  const [showHtml, setShowHtml] = React.useState<string>();

  const handleUpdateClick = (e: React.MouseEvent, item: AuctionInformationsCType) => {
    e.preventDefault();
    props.onUpdateClick(item);
  };

  const handleShowHTML = (e: React.MouseEvent<HTMLAnchorElement>, value: string) => {
    e.preventDefault();
    setShowHtml(value);
  };

  return (
    <div>
      <div className="mt-4">
        <div className="responsive-table-content">
          <Table className="table-middle border-bottom-0" striped responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Název</th>
                <th>Text</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {props.data.length < 1 && (
                <tr>
                  <td className="text-left" colSpan={100}>
                    Nebyla nalezena žádná data.
                  </td>
                </tr>
              )}
              {props.data.map((item, index) => (
                <tr key={`item-${index}`}>
                  <td className="text-left">{item.id}</td>
                  <td className="text-left">{item.title}</td>
                  <td className="text-left information-text">
                    {stripeHtml(item.text, 100)}
                    <a href="/" onClick={(e) => handleShowHTML(e, item.text)}>
                      <img src={colorIcoEye} alt="show" className="ml-2" />
                    </a>
                  </td>
                  <td className="text-right">
                    <Visible permissionGroupName={PermissionGroup.priceList} permissionName={UserPermission.canEdit}>
                      <Link
                        to="/"
                        className="d-inline-flex align-items-center ml-4"
                        onClick={(e) => handleUpdateClick(e, item)}
                      >
                        <img src={colorIcoConf} alt="ico" className="mr-2" />
                        Upravit
                      </Link>
                    </Visible>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
      <Modal title="Text" isOpen={!!showHtml} className="w-max-500" onRequestClose={() => setShowHtml(undefined)}>
        <div dangerouslySetInnerHTML={{ __html: showHtml || '' }} />
      </Modal>
    </div>
  );
};

export default InformationsTable;
