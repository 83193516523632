import React, { useState, MouseEvent } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Formik, FormikValues } from 'formik';

import * as routes from '@routes';
import { useAuthApi } from '@api/auth';
import { usePageStore, useAuthFrontStore } from '@stores';
import { ModalProps } from '@components/Modal/Modal';
import { Modal, Button, BasePreloader } from '@components';

import icoSend from '@assets/images/ico-send.svg';

interface Props extends ModalProps {
  onSuccess: () => void;
}

const LoginModal: React.FC<Props> = (props) => {
  const authApi = useAuthApi();
  const pageState = usePageStore();
  const authFrontState = useAuthFrontStore();
  const [showReset, setShowReset] = useState(false);
  const [isEmailRequestComplete, setIsEmailRequestComplete] = useState(true);
  const [showResendActivationEmail, setShowResentActivationEmail] = useState<string | undefined>();

  const handleFormSubmit = async (
    values: FormikValues,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    try {
      if (!showReset) {
        const response = await authApi.login(values.email, values.password, 'fe');
        authFrontState.setAuthCookies(response.data.token, response.data.refresh_token);
        const userResponse = await authApi.logged(true);
        authFrontState.setUser(userResponse.data.data);
        await pageState.reload();
        setSubmitting(false);
        props.onSuccess();
      } else {
        await authApi.resetPassword(values.email);
        if (!!props.onRequestClose) {
          props.onRequestClose();
        }
      }
    } catch (err: any) {
      if (err.response?.data?.data?.unverified) {
        setShowResentActivationEmail(values.email);
      }
      setFieldValue('password', ``);
      setSubmitting(false);
    }
  };

  const handleResetPasswordClick = (
    e: React.MouseEvent,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    e.preventDefault();
    setShowReset(true);
    setFieldValue('password', '');
  };

  const handleSendActivationEmail = async (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (!showResendActivationEmail || !isEmailRequestComplete) {
      return;
    }
    setIsEmailRequestComplete(false);
    try {
      await authApi.sendActivationEmail(showResendActivationEmail);
    } catch (err: any) {
      if (authApi.isCancel(err)) {
        return;
      }
    } finally {
      setIsEmailRequestComplete(true);
    }
  };

  const handleAfterOpen = () => {
    setIsEmailRequestComplete(true);
    setShowResentActivationEmail(undefined);
  };

  return (
    <Modal
      {...props}
      title={showReset ? 'Reset hesla' : props.title}
      onAfterOpen={handleAfterOpen}
      onAfterClose={() => setShowReset(false)}
    >
      {props.isOpen && (
        <Formik
          initialValues={{
            email: '',
            password: '',
          }}
          onSubmit={(values, { setSubmitting, setFieldValue }) =>
            handleFormSubmit(values, setSubmitting, setFieldValue)
          }
        >
          {(formProps) => (
            <Form
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => formProps.handleSubmit(e)}
              data-test-id="front-login-modal-form"
            >
              <Form.Group
                className={classNames({
                  'mb-16': !showReset,
                  'mb-30': showReset,
                })}
              >
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  required={true}
                  disabled={formProps.isSubmitting}
                  className="input-md"
                  type="email"
                  value={formProps.values.email}
                  name="email"
                  data-test-id="front-login-modal-form-email"
                  onChange={formProps.handleChange}
                />
              </Form.Group>
              {!showReset && (
                <Form.Group className="mb-30">
                  <Form.Label>Heslo</Form.Label>
                  <Form.Control
                    required={true}
                    disabled={formProps.isSubmitting}
                    className="input-md"
                    type="password"
                    value={formProps.values.password}
                    name="password"
                    data-test-id="front-login-modal-form-password"
                    onChange={formProps.handleChange}
                  />
                </Form.Group>
              )}

              {!!showResendActivationEmail && (
                <div className="pb-4">
                  <a href="/" onClick={handleSendActivationEmail} className="d-flex align-items-center">
                    {isEmailRequestComplete ? (
                      <img src={icoSend} alt="ico" className="mr-2" />
                    ) : (
                      <BasePreloader className="mr-2" size={21} />
                    )}
                    Znovu zaslat aktivační e-mail
                  </a>
                </div>
              )}

              <Form.Group>
                {formProps.isSubmitting ? (
                  <BasePreloader size={34} />
                ) : (
                  <Button type="submit" variant="btn-outline-primary" dataTestId="front-login-modal-form-btn">
                    {showReset ? 'Reset' : 'Přihlásit se'}
                  </Button>
                )}
              </Form.Group>
              {!showReset && (
                <>
                  <Form.Group className="mb-3">
                    <a
                      href="/"
                      onClick={(e) => handleResetPasswordClick(e, formProps.setFieldValue)}
                      className="text-underline"
                      data-test-id="front-login-modal-form-forgot-stupid-link"
                    >
                      Zapomněli jste své heslo?
                    </a>
                  </Form.Group>
                  <Form.Group>
                    <Link
                      to={pageState.getPagePath(routes.front.REGISTRATION)}
                      onClick={props.onRequestClose}
                      className="text-underline"
                    >
                      Nemáte ještě účet? Zaregistrujte se.
                    </Link>
                  </Form.Group>
                </>
              )}
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default LoginModal;
