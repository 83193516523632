import React, { ChangeEvent } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { FormikErrors } from 'formik';

import { FormGroup } from '@components';
import { BuyerFormValues } from '@types';
import { strIsLatin } from '@helpers/string';

interface Props {
  readOnly?: boolean;
  values: BuyerFormValues;
  errors: FormikErrors<BuyerFormValues>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

const CompanyDetails: React.FC<Props> = (props) => {
  const handleLatinValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      props.handleChange(e);
      return;
    }
    if (strIsLatin(e.target.value)) {
      props.handleChange(e);
    }
  };

  return (
    <div className="step-buyer-section">
      <div className={cx('section-title')}>Firemní údaje</div>
      <div className="responsive-table-content">
        <FormGroup
          required
          type="text"
          name="companyName"
          label="Název společnosti"
          error={_.get(props.errors, 'company')}
          readOnly={props.readOnly}
          value={props.values.companyName}
          onChange={props.handleChange}
        />
        <FormGroup
          required
          type="text"
          name="ico"
          label="IČ"
          readOnly={props.readOnly}
          value={props.values.ico}
          error={props.errors.ico}
          onChange={handleLatinValueChange}
        />
        <FormGroup
          type="text"
          name="dic"
          label="DIČ"
          readOnly={props.readOnly}
          value={props.values.dic}
          error={props.errors.dic}
          onChange={handleLatinValueChange}
        />
        <FormGroup
          required
          type="text"
          name="issuer"
          label="Vedená u"
          error={_.get(props.errors, 'register')}
          readOnly={props.readOnly}
          value={props.values.issuer}
          onChange={handleLatinValueChange}
        />
      </div>
    </div>
  );
};

export default CompanyDetails;
