import moment from 'moment';

export function getTimestamp(value: moment.MomentInput): number {
  return moment(value).unix();
}

export function getDateFormat(value: moment.MomentInput): string {
  return moment(value).format('D. M. Y');
}

export function getTimeFormat(value: moment.MomentInput): string {
  return moment(value).format('HH:mm:ss');
}

export function getRequestDateFormat(value: moment.MomentInput): string {
  return moment(value).format('YYYY-MM-DD');
}

export function getRequestDateTimeFormat(value: moment.MomentInput): string {
  return moment(value).format('YYYY-MM-DD HH:mm:ss');
}

export function getDateTimeFormat(value: moment.MomentInput, options?: { withSeconds?: boolean }): string {
  let dateTimeFormat = `D. M. Y HH:mm`;
  if (options?.withSeconds) {
    dateTimeFormat += `:ss`;
  }
  return moment(value).format(dateTimeFormat);
}
