import React from 'react';

import * as routes from '@routes';
import { useAuthFrontStore, userRoleChecks } from '@stores';
import { BaseAccountPage, FrontAccountBox } from '@components';
import StepBuyer from '@components/RegistrationSteps/StepBuyer';

const AccountPersonalDataPage: React.FC = () => {
  const authFrontState = useAuthFrontStore();

  if (!authFrontState.user) {
    return null;
  }

  return (
    <BaseAccountPage page={routes.front.ACCOUNT_PERSONAL_DATA}>
      <FrontAccountBox className="page-account-personal-data">
        <StepBuyer
          id={authFrontState.user?.id}
          email={authFrontState.user?.email || ''}
          isUpdate={!userRoleChecks(authFrontState.user).isOfRoleUser()}
          onSuccess={() => {}}
        />
      </FrontAccountBox>
    </BaseAccountPage>
  );
};

export default AccountPersonalDataPage;
