import { createStore, useStoreState } from './store';
import { BaseObjectType } from '@types';

export interface UrlSearchState {
  adminAuctioneerList: string;
  adminAuctionsList: string;
  adminUsersList: string;
  adminAuctionsDocuments: string;
  adminAuctionsSecurity: string;
  adminOnlineAuctionbuyers: BaseObjectType<string>;
  frontAccountAuctions: string;
  frontAuctioneerList: string;
}

export const urlSearchStore = createStore<UrlSearchState>({
  adminAuctioneerList: '',
  adminAuctionsList: '',
  adminUsersList: '',
  adminAuctionsDocuments: '',
  adminAuctionsSecurity: '',
  adminOnlineAuctionbuyers: {},
  frontAccountAuctions: '',
  frontAuctioneerList: '',
});

export const useUrlSearchStore = () => {
  const urlSearchState = useStoreState(urlSearchStore);

  const clearAdmin = () => {
    urlSearchStore.setState({
      adminAuctioneerList: '',
      adminAuctionsList: '',
      adminUsersList: '',
      adminAuctionsDocuments: '',
      adminAuctionsSecurity: '',
      adminOnlineAuctionbuyers: {},
    });
  };

  return {
    ...urlSearchState,
    clearAdmin,
  };
};
