import React from 'react';
import { ValueType } from 'react-select';
import { useLocation } from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { useEnumApi } from '@api/enum';
import { Select, AdminFiltersBox } from '@components';
import { DocumentsFilters, DocumentState, EnumType } from '@types';
import FiltersInputs from '@components/AdminFiltersBox/components/FiltersInputs';
import FiltersSubmit from '@components/AdminFiltersBox/components/FiltersSubmit';

interface Props {
  values: DocumentsFilters;
  onSubmit: (values: DocumentsFilters) => void;
}

interface StateSelectOptionType {
  value: string;
  label: string;
}

const FiltersForm: React.FC<Props> = (props) => {
  const pageState = usePageStore();
  const location = useLocation();
  const enumApi = useEnumApi();
  const [isOpen, setIsOpen] = React.useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: props.values,
    onSubmit: (values) => handleSubmit(values),
  });
  const [buyerTypes, setbuyersTypes] = React.useState<EnumType[]>([]);

  const buyerTypesOptions = buyerTypes.map((v) => ({
    value: v.type,
    label: v.translated,
  }));

  const documentStates = [
    {
      label: 'Čeká na schválení',
      value: DocumentState.uploaded,
    },
    {
      label: 'Schváleno',
      value: DocumentState.approved,
    },
    {
      label: 'Neschváleno',
      value: DocumentState.rejected,
    },
  ];

  React.useEffect(() => {
    loadbuyerTypes().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setIsOpen(false);
    formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values]);

  const loadbuyerTypes = async () => {
    try {
      const res = await enumApi.getBuyersTypes();
      setbuyersTypes(res.data.data);
    } catch (err) {
      if (enumApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleSubmit = (values: FormikValues) => {
    const formValues = { ...values };
    Object.getOwnPropertyNames(formValues).map((prop) => {
      if (formValues[prop] === '') {
        formValues[prop] = undefined;
      }
      return prop;
    });
    formValues.page = 1;
    props.onSubmit(formValues as DocumentsFilters);
  };

  const hasFilters = (): boolean => {
    return !!location.search;
  };

  const handleStateChange = (value: ValueType<StateSelectOptionType, boolean>) => {
    const itemValue = value as StateSelectOptionType;
    if (itemValue) {
      formik.setFieldValue('state', itemValue?.value || '');
      return;
    }

    formik.setFieldValue('state', null);
  };

  const handleBuyerTypeChange = (value: ValueType<StateSelectOptionType, boolean>) => {
    const itemValue = value as StateSelectOptionType;
    if (itemValue) {
      formik.setFieldValue('buyerType', itemValue?.value || '');
      return;
    }

    formik.setFieldValue('buyerType', null);
  };

  return (
    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)}>
      <AdminFiltersBox isOpen={isOpen}>
        <FiltersInputs>
          <Row>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Stav</Form.Label>
                <Select
                  isClearable={true}
                  options={documentStates}
                  value={documentStates.find((i) => i.value === formik.values.state) || null}
                  onChange={handleStateChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Druh dražitele</Form.Label>
                <Select
                  isClearable={true}
                  options={buyerTypesOptions}
                  value={buyerTypesOptions.find((i) => i.value === formik.values.buyerType) || null}
                  onChange={handleBuyerTypeChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="email"
                  value={formik.values.email || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Jméno / Název</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="name"
                  value={formik.values.name || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </FiltersInputs>
        <FiltersSubmit
          hasFilters={hasFilters()}
          onOpenClick={() => setIsOpen(true)}
          clearUrl={pageState.getPagePath(routes.admin.DOCUMENTS)}
        />
      </AdminFiltersBox>
    </Form>
  );
};

export default FiltersForm;
