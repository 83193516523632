import React from 'react';

import LayoutFooter from '../LayoutFooter/LayoutFooter';

const BaseAuth: React.FC = (props) => (
  <div className="page-auth">
    <div className="page-content">{props.children}</div>
    <LayoutFooter />
  </div>
);

export default BaseAuth;
