export const isAuthorizedOptions = [
  'formWithCertificate',
  'dataBoxWithCertificate',
  'dataBoxWithConvertDocument',
  'personally',
  'post',
  'userAccountConvertDocument',
];

export const validCautionSecutiry = ['bankAccount', 'bankGuarantee', 'cash'];
