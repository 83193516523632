import { useWebsocket } from '@websocket/base';

type Props = {
  id: string | number;
};

export type LicitatorWebsocketResponse = {
  title: string;
  text: string;
};

export const useLicitatorWebsocket = (props: Props) => {
  return useWebsocket<LicitatorWebsocketResponse>({ topic: `/auctions/${props.id}/licitator` });
};
