import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';

import { BasePreloader, Button, SEO } from '@components';
import { ContactUsSideBar } from '@components/Contact/ContactUsSideBar';
import * as routes from '@routes';
import { usePageStore } from '@stores';
import { usePagesApi } from '@api/pages';
import { PageTextBlockType } from '@types';

export const IWantToSellDetail = () => {
  const pageState = usePageStore();
  const pagesApi = usePagesApi();
  const location = useLocation();
  const [loaded, setLoaded] = useState(true);
  const subPages = pageState.getPagesByParentPageId(pageState.getPage(routes.front.I_WANT_TO_SELL)?.id || 0);
  const page = subPages.find((subPage) => subPage.fullUrl === location.pathname);
  const [textBlocks, setTextBlock] = useState<PageTextBlockType[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (page) {
      loadTextBlocks(page.id);
    }
    return () => {
      pagesApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const loadTextBlocks = async (pageId: number) => {
    setLoaded(false);
    try {
      const response = await pagesApi.listTextBlocks(pageId);
      const data = response.data.data;
      setTextBlock(data);
    } catch (err) {
      if (pagesApi.isCancel(err)) {
        return;
      }
    }
    setLoaded(true);
  };

  const renderTextBlock = (textBlocks: PageTextBlockType[]): JSX.Element => {
    return (
      <>
        {textBlocks.map((textBlock) => {
          if (textBlock.name !== 'Perex') {
            return <div key={textBlock.id} dangerouslySetInnerHTML={{ __html: textBlock.content }} />;
          }
          return null;
        })}
      </>
    );
  };

  return (
    <>
      <Container fluid>
        <SEO title={page?.name} />
        <div className="component-front-i-want-to-sell">
          <Row>
            <Col lg={8} sm={12} className="py-3">
              <div className="subpage-content">
                {page && <h2>{page.name}</h2>}
                {loaded ? renderTextBlock(textBlocks) : <BasePreloader />}
                <a href="https://prodejtojinak.cz" target="_blank" rel="noreferrer" className="text-decoration-none">
                  <div className="banner">
                    <p>www.prodejtojinak.cz</p>
                  </div>
                </a>
                <Button
                  type="button"
                  variant="btn-outline-primary"
                  className="mt-4"
                  to={pageState.getPagePath(routes.front.I_WANT_TO_SELL)}
                >
                  Vrátit se zpět
                </Button>
              </div>
            </Col>
            <Col lg={4} md={8} sm={12} className="py-3">
              <ContactUsSideBar />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
};
