import React from 'react';
import { Preloader, Oval } from 'react-preloader-icon';
import { LoaderProps } from 'react-preloader-icon/Preloader';

interface BasePreloaderProps {
  use?: React.FC<LoaderProps>;
  size?: number;
  strokeWidth?: number;
  strokeColor?: string;
  duration?: number;
  className?: string;
}

const BasePreloader: React.FC<BasePreloaderProps> = (props) => (
  <div
    className={props.className}
    style={{
      width: props.size || 35,
      height: props.size || 35,
    }}
  >
    <Preloader
      use={props.use || Oval}
      size={props.size || 35}
      strokeWidth={props.strokeWidth || 12}
      strokeColor={props.strokeColor || `#52B1AE`}
      duration={props.duration || 800}
    />
  </div>
);

export default BasePreloader;
