import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Table } from 'react-bootstrap';

import { Button } from '@components';
import { AuctioneerTemplate } from '@types';

import plusIco from '@assets/images/plus-ico.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';
import colorIcoDel from '@assets/images/color-ico-del.svg';
import colorIcoConf from '@assets/images/color-ico-conf.svg';
import { getDateTimeFormat } from '@helpers/datetime';

interface Props {
  data: Array<AuctioneerTemplate>;
  onClickAdd: () => void;
  onUpdateClick: (item: AuctioneerTemplate) => void;
  onDeleteClick: (item: AuctioneerTemplate) => void;
  onChangeDefault: (item: AuctioneerTemplate, isDefault: boolean) => void;
}

const TemplatesTable: React.FC<Props> = (props) => {
  const handleCreateButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    props.onClickAdd();
  };

  const handleUpdateClick = (e: React.MouseEvent, item: AuctioneerTemplate) => {
    e.preventDefault();
    props.onUpdateClick(item);
  };

  const handleDeleteClick = (e: React.MouseEvent, item: AuctioneerTemplate) => {
    e.preventDefault();
    props.onDeleteClick(item);
  };

  return (
    <div>
      <div>
        <h2>Šablony</h2>
        <Button className="f-size-12 f-weight-400 mt-3" onClick={handleCreateButtonClick}>
          <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
          <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
          Přidat šablonu
        </Button>
      </div>
      <div className="mt-4">
        <div className="responsive-table-content">
          <Table className="table-middle border-bottom-0" striped responsive>
            <thead>
              <tr>
                <th>Název</th>
                <th>Dátum vytvoření</th>
                <th>Dátum úpravy</th>
                <th>Typ</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {props.data.length < 1 && (
                <tr>
                  <td className="text-left" colSpan={100}>
                    Nebyla nalezena žádná data.
                  </td>
                </tr>
              )}
              {props.data.map((item, index) => {
                return (
                  <tr key={`item-${index}`}>
                    <td>{item.name || '-'}</td>
                    <td className="text-left">{getDateTimeFormat(item.timeCreated)}</td>
                    <td className="text-left">{getDateTimeFormat(item.timeCreated)}</td>
                    <td>{item.auctionType === 'auction' ? 'Aukce' : 'Dražba'}</td>
                    <td className="text-right">
                      <label className="d-inline-flex">
                        <span>Výchozí šablona</span>
                        <Form.Check
                          custom
                          type="checkbox"
                          label=""
                          className="ml-3"
                          id={item.name}
                          checked={item.isDefault}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            props.onChangeDefault(item, e.target.checked);
                          }}
                        />
                      </label>
                      <Link
                        to="/"
                        className="d-inline-flex align-items-center ml-4"
                        onClick={(e) => handleUpdateClick(e, item)}
                      >
                        <img src={colorIcoConf} alt="ico" className="mr-2" />
                        Upravit
                      </Link>
                      <Link
                        to="/"
                        className="d-inline-flex align-items-center ml-4"
                        onClick={(e) => handleDeleteClick(e, item)}
                      >
                        <img src={colorIcoDel} alt="ico" className="mr-2" />
                        Smazat
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default TemplatesTable;
