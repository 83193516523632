import React, { useState, MouseEvent, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
import Cookies from 'js-cookie';
import { useFormik } from 'formik';

import config from '@config';
import { Button, Modal } from '@components/index';

const CookieVerification: React.FC = () => {
  const location = useLocation();
  const [hasVerified, setHasVerified] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const formik = useFormik({
    onSubmit: () => handleSubmit(),
    initialValues: {
      analytics: false,
      marketing: false,
    },
  });

  useEffect(
    () => {
      setHasVerified(Cookies.get('cookieVerification') === '1');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    if (config.ga && hasVerified && Cookies.get('cookieVerificationAnalytics') === '1') {
      ReactGA.initialize(config.ga);
    }
  }, [hasVerified]);

  useEffect(() => {
    if (config.ga && hasVerified && Cookies.get('cookieVerificationAnalytics') === '1') {
      ReactGA.pageview(`${location.pathname}${location.search}`);
    }
  }, [hasVerified, location.pathname, location.search]);

  const handleSubmit = () => {
    if (formik.values.analytics) {
      Cookies.set('cookieVerificationAnalytics', '1', { expires: 365 });
    } else {
      Cookies.remove('cookieVerificationAnalytics');
    }
    if (formik.values.marketing) {
      Cookies.set('cookieVerificationMarketing', '1', { expires: 365 });
    } else {
      Cookies.remove('cookieVerificationMarketing');
    }
    Cookies.set('cookieVerification', '1', { expires: 365 });
    setHasVerified(true);
  };

  const handleAllClick = () => {
    Cookies.set('cookieVerificationAnalytics', '1', { expires: 365 });
    Cookies.set('cookieVerificationMarketing', '1', { expires: 365 });
    Cookies.set('cookieVerification', '1', { expires: 365 });
    setHasVerified(true);
  };

  const handleDeclineClick = () => {
    Cookies.remove('cookieVerificationAnalytics');
    Cookies.remove('cookieVerificationMarketing');
    Cookies.set('cookieVerification', '1', { expires: 365 });
    setHasVerified(true);
  };

  const handleSetupClick = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    setModalIsOpen(true);
  };

  if (hasVerified) {
    return null;
  }

  return (
    <>
      <Modal noPadding title="Nastavení cookies" isOpen={modalIsOpen} onRequestClose={() => setModalIsOpen(false)}>
        <div className="component-cookies-modal">
          <div className="modal-cookies">
            <form onSubmit={formik.handleSubmit}>
              <div className="cookies-row default-cookies">
                <div className="default-cookies-ico">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-check-lg"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12.736 3.97a.733.733 0 0 1 1.047 0c.286.289.29.756.01 1.05L7.88 12.01a.733.733 0 0 1-1.065.02L3.217 8.384a.757.757 0 0 1 0-1.06.733.733 0 0 1 1.047 0l3.052 3.093 5.4-6.425a.247.247 0 0 1 .02-.022Z" />
                  </svg>
                </div>
                <div className="default-cookies-label">
                  <span>Nutné cookies</span>
                  <br />
                  Tyto cookies jsou nezbytné pro fungování webové stránky a nemohou být vypnuty v našich systémech. Tyto
                  cookies neskladují žádná osobní data.
                </div>
              </div>

              <div className="cookies-row">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="checkbox-cookies-analytics"
                    name="analytics"
                    checked={formik.values.analytics}
                    onChange={formik.handleChange}
                  />
                  <label className="custom-control-label" htmlFor="checkbox-cookies-analytics">
                    <span>Analytické cookies</span>
                    <br />
                    Umožňují nám sledovat souhrnné informace o návštěvnosti stránek a využívání různých funkcí. To nám
                    pomáhá naše weby zlepšovat tak, aby Vám lépe sloužily.
                  </label>
                </div>
              </div>

              <div className="cookies-row">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="checkbox-cookies-marketing"
                    name="marketing"
                    checked={formik.values.marketing}
                    onChange={formik.handleChange}
                  />
                  <label className="custom-control-label" htmlFor="checkbox-cookies-marketing">
                    <span>Marketingové cookies</span>
                    <br />
                    Používají se ke shromažďování informací o webových stránkách navštívených uživatelem za účelem
                    zobrazování reklam přizpůsobených vám. Neukládají přímo osobní identifikační údaje, ale jsou
                    založeny na jedinečné identifikaci Vašeho prohlížeče a internetového zařízení. Pokud tyto cookies
                    nepovolíte, dočkáte se méně cílené reklamy.
                  </label>
                </div>
              </div>

              <div className="cookies-row cookies-btn">
                <Button type="submit">Uložit nastavení</Button>
              </div>
            </form>
          </div>
        </div>
      </Modal>

      <div className="component-cookie-verification">
        <div className="cookie-text">
          Tento web používá k personalizaci reklam a analýze návštěvnosti soubory cookies. Více informací najdete v
          našich <Link to="/cookies">zásadách používání cookies</Link>. Své nastavení si můžete změnit v{' '}
          <a href="/" onClick={handleSetupClick}>
            Nastavení cookies
          </a>
        </div>
        <Button
          type="button"
          dataTestId="cookies-verification-reject-all"
          className="cookie-button mr-2"
          onClick={handleDeclineClick}
        >
          Odmítnout cookies
        </Button>
        <Button
          type="button"
          dataTestId="cookies-verification-approve-all"
          className="cookie-button"
          onClick={handleAllClick}
        >
          Přijmout cookies
        </Button>
      </div>
    </>
  );
};

export default CookieVerification;
