import React from 'react';

import * as routes from '@routes';
import { Button } from '@components';
import { usePageStore, useUrlSearchStore } from '@stores';

const BackButton: React.FC = () => {
  const urlSearchState = useUrlSearchStore();
  const pageState = usePageStore();

  return (
    <div>
      <Button
        grayBg
        to={`${pageState.getPagePath(
          !!pageState.getPage(routes.admin.AUCTIONEER_LIST)
            ? routes.admin.AUCTIONEER_LIST
            : routes.admin.MY_COMPANY_LIST
        )}${urlSearchState.adminAuctioneerList}`}
      >
        {!!pageState.getPage(routes.admin.AUCTIONEER_LIST) ? 'Zpět na dražebníky' : 'Zpět na moji firmu'}
      </Button>
    </div>
  );
};

export default BackButton;
