import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { ValueType } from 'react-select';
import { useLocation } from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';

import * as routes from '@routes';
import { useEnumApi } from '@api/enum';
import { useAuctionsApi } from '@api/auctions';
import { useAuctioneersApi } from '@api/auctioneers';
import { getRequestDateFormat } from '@helpers/datetime';
import { useCurrentUserRoleChecks, usePageStore } from '@stores';
import { DatePickerInput, AdminFiltersBox, Select } from '@components';
import FiltersInputs from '@components/AdminFiltersBox/components/FiltersInputs';
import FiltersSubmit from '@components/AdminFiltersBox/components/FiltersSubmit';
import {
  AuctionStatusResponse,
  AdminDocumentsUsersFilter,
  AuctionAuthorizationType,
  Auctioneer,
  EnumType,
} from '@types';

import inputIcoCalendar from '@assets/images/input-ico-calendar.svg';

interface Props {
  values: AdminDocumentsUsersFilter;
  onSubmit: (values: AdminDocumentsUsersFilter) => void;
}

interface SelectOptionType {
  value: string;
  label: string;
}

const DocumentsFiltersForm: React.FC<Props> = (props) => {
  const auctioneersApi = useAuctioneersApi();
  const pageState = usePageStore();
  const auctionsApi = useAuctionsApi();
  const location = useLocation();
  const enumApi = useEnumApi();
  const { isOfRoleSuperAdmin } = useCurrentUserRoleChecks();
  const [isOpen, setIsOpen] = React.useState(false);
  const [auctioneers, setAuctioneers] = React.useState<Auctioneer[]>([]);
  const [auctionStatus, setAuctionStatus] = useState<Array<AuctionStatusResponse>>([]);
  const [auctionAuthorizationTypes, setAuctionAuthorizationTypes] = useState<AuctionAuthorizationType[]>([]);
  const [buyerTypes, setbuyersTypes] = React.useState<EnumType[]>([]);
  const [auctionSecurityStates] = useState<FormikValues>([
    { label: 'ANO', value: 'yes' },
    { label: 'NE', value: 'no' },
    { label: 'Není vyžadována', value: 'not_required' },
  ]);
  const [admitted] = useState<FormikValues>([
    { label: 'ANO', value: true },
    { label: 'NE', value: false },
  ]);

  const buyerTypesOptions = buyerTypes.map((v) => ({
    value: v.type,
    label: v.translated,
  }));

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: props.values,
    onSubmit: (values) => handleSubmit(values),
  });

  const authorizationTypes = auctionAuthorizationTypes.map((v) => ({
    value: v.type,
    label: v.translated,
  }));

  useEffect(() => {
    setIsOpen(false);
    loadAuctionStatus().then();
    if (isOfRoleSuperAdmin()) {
      loadAuctioneers().then();
    }
    return () => auctionsApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values]);

  useEffect(() => {
    loadEnums().then();
    loadAuctionStatus().then();
    loadBuyerTypes().then();
    return () => {
      enumApi.cancelAllRequests();
      auctionsApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsOpen(false);
    formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values]);

  const loadBuyerTypes = async () => {
    try {
      const res = await enumApi.getBuyersTypes();
      setbuyersTypes(res.data.data);
    } catch (err) {
      if (enumApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadAuctioneers = async () => {
    try {
      const auctioneersResponse = await auctioneersApi.list({ page: 1, perPage: 1000 });
      setAuctioneers(auctioneersResponse.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const loadAuctionStatus = async () => {
    try {
      const response = await auctionsApi.getAuctionStatus();
      setAuctionStatus(response.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const loadEnums = async () => {
    try {
      setAuctionAuthorizationTypes((await enumApi.getAuctionAuthorizationState()).data.data);
    } catch (err: any) {
      if (enumApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleSubmit = (values: FormikValues) => {
    const formValues = { ...values };
    Object.getOwnPropertyNames(formValues).map((prop) => {
      if (formValues[prop] === '') {
        formValues[prop] = undefined;
      }
      return prop;
    });

    formValues.page = 1;
    formValues.startDtFrom = formValues.startDtFrom || undefined;
    formValues.startDtTo = formValues.startDtTo || undefined;
    formValues.endDtFrom = formValues.endDtFrom || undefined;
    formValues.endDtTo = formValues.endDtTo || undefined;
    formValues.status = formValues.status || undefined;
    formValues.name = formValues.name || undefined;
    formValues.mainCategory = formValues.mainCategory || undefined;
    formValues.subCategory = formValues.subCategory || undefined;
    formValues.variableSymbol = formValues.variableSymbol || undefined;
    formValues.specificSymbol = formValues.specificSymbol || undefined;
    formValues.auctionSecurity = formValues.auctionSecurity || undefined;
    formValues.authorizationState = formValues.authorizationState || undefined;
    formValues.cautionState = formValues.cautionState || undefined;
    formValues.admitted = formValues.admitted !== undefined ? formValues.admitted : undefined;
    formValues.buyerType = formValues.buyerType !== undefined ? formValues.buyerType : undefined;
    props.onSubmit(formValues as AdminDocumentsUsersFilter);
  };

  const getClearUrl = () => {
    return `${pageState.getPagePath(routes.admin.DOCUMENTS_AND_SECURITY)}?s=1`;
  };

  const hasFilters = (): boolean => {
    return !!location.search && !['?s=1'].some((i) => i === location.search);
  };

  const handleDateChange = (prop: string, value: Date | Date[] | null) => {
    if (value !== null) {
      const inputValue = value as Date;
      formik.setFieldValue(prop, !!inputValue ? getRequestDateFormat(inputValue) : '');
    } else {
      formik.setFieldValue(prop, '');
    }
  };

  const getStatusOptions = () => {
    return auctionStatus.map((i) => ({ value: i.type, label: i.translated }));
  };

  const handleStatusChange = (value: ValueType<SelectOptionType, true>) => {
    const itemValue = value as SelectOptionType[];
    formik.setFieldValue(
      'status',
      itemValue.map((s) => s.value)
    );
  };

  const handleCautionStateChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    formik.setFieldValue('cautionState', itemValue?.value || '');
  };

  const handleAdmittedStateChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    formik.setFieldValue('admitted', itemValue?.value !== undefined ? itemValue?.value : undefined);
  };

  const handleAuctioneerChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    formik.setFieldValue('auctioneer', itemValue?.value || '');
  };

  const handleBuyerTypeChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    if (itemValue) {
      formik.setFieldValue('buyerType', itemValue?.value || '');
      return;
    }

    formik.setFieldValue('buyerType', null);
  };

  const getAuctioneerOptions = () => {
    return auctioneers.map((i) => ({ value: i.id.toString(), label: i.auctioneerName }));
  };

  return (
    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)}>
      <AdminFiltersBox isOpen={isOpen}>
        <FiltersInputs>
          <Row>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Jednací číslo</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="number"
                  value={formik.values.number || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Dražitel</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="name"
                  value={formik.values.name || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Variabilní symbol</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="variableSymbol"
                  value={formik.values.variableSymbol || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Specifický symbol</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="specificSymbol"
                  value={formik.values.specificSymbol || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Status</Form.Label>
                <Select
                  isMulti
                  isClearable={true}
                  options={getStatusOptions()}
                  value={(() => {
                    const res: SelectOptionType[] = [];
                    (formik.values.status || []).forEach((v) => {
                      const item = getStatusOptions().find((s) => s.value === v);
                      if (!!item) {
                        res.push(item);
                      }
                    });
                    return res;
                  })()}
                  onChange={(val: any) => handleStatusChange(val)}
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Ověření totožnosti</Form.Label>
                <Select
                  isClearable={true}
                  options={authorizationTypes}
                  value={authorizationTypes.find((i) => i.value === formik.values.authorizationState) || null}
                  onChange={(val) => formik.setFieldValue('authorizationState', val?.value)}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Jistota</Form.Label>
                <Select
                  isClearable={true}
                  options={auctionSecurityStates}
                  value={auctionSecurityStates.find((i: any) => i.value === formik.values.cautionState) || null}
                  onChange={handleCautionStateChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Připuštěn</Form.Label>
                <Select
                  isClearable={true}
                  options={admitted}
                  value={admitted.find((i: any) => i.value === formik.values.admitted) || null}
                  onChange={handleAdmittedStateChange}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            {isOfRoleSuperAdmin() && (
              <Col xs={12} md={6} lg={3}>
                <Form.Group>
                  <Form.Label>Dražebník</Form.Label>
                  <Select
                    isClearable={true}
                    options={getAuctioneerOptions()}
                    value={getAuctioneerOptions().find((i) => i.value === formik.values.auctioneer) || null}
                    onChange={handleAuctioneerChange}
                  />
                </Form.Group>
              </Col>
            )}

            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Druh dražitele</Form.Label>
                <Select
                  isClearable={true}
                  options={buyerTypesOptions}
                  value={buyerTypesOptions.find((i) => i.value === formik.values.buyerType) || null}
                  onChange={handleBuyerTypeChange}
                />
              </Form.Group>
            </Col>

            <Col sm="auto">
              <Form.Group className="mb-0">
                <Form.Label>Datum zahájení</Form.Label>
              </Form.Group>
              <div className="d-block d-sm-flex mt-0">
                <Form.Group className="mb-3 inline-label mr-2 input-ico">
                  <Form.Label>Od</Form.Label>
                  <DatePickerInput
                    isNullable
                    clearIcon={null}
                    className="form-control form-control-sm w-135"
                    calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                    onChange={(val) => handleDateChange('startDtFrom', val)}
                    value={formik.values.startDtFrom ? moment(formik.values.startDtFrom || '').toDate() : undefined}
                  />
                </Form.Group>
                <Form.Group className="mb-3 inline-label input-ico">
                  <Form.Label>Do</Form.Label>
                  <DatePickerInput
                    isNullable
                    clearIcon={null}
                    className="form-control form-control-sm w-135"
                    calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                    onChange={(val) => handleDateChange('startDtTo', val)}
                    value={formik.values.startDtTo ? moment(formik.values.startDtTo || '').toDate() : undefined}
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>
        </FiltersInputs>
        <FiltersSubmit hasFilters={hasFilters()} onOpenClick={() => setIsOpen(true)} clearUrl={getClearUrl()} />
      </AdminFiltersBox>
    </Form>
  );
};

export default DocumentsFiltersForm;
