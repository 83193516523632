import React from 'react';

import { AuctionParameterResponse, AuctionResponse } from '@types';

interface Props {
  auction?: AuctionResponse;
  parameterShort?: AuctionParameterResponse[];
  parameterComplete?: AuctionParameterResponse[];
  onMoreClick?: (e: React.MouseEvent) => void;
  isMoreInfo?: boolean;
}

const AuctionParams: React.FC<Props> = (props) => (
  <div className="auction-params">
    <table>
      <tbody>
        {props.parameterShort &&
          props.parameterShort.map((parameter, index) => (
            <tr key={`auction-param-${index}`}>
              <th>{parameter.name}</th>
              <td>
                <div dangerouslySetInnerHTML={{ __html: parameter.value }} />
              </td>
            </tr>
          ))}
        {props.isMoreInfo &&
          props.parameterComplete?.map((parameter, index) => (
            <tr key={`auction-param-${index}`}>
              <th>{parameter.name}</th>
              <td>
                <div dangerouslySetInnerHTML={{ __html: parameter.value }} />
              </td>
            </tr>
          ))}
      </tbody>
    </table>
    {!!props.onMoreClick && (
      <a href="/" onClick={props.onMoreClick} className="more-btn">
        Více informací
      </a>
    )}
  </div>
);

export default AuctionParams;
