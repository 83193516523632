import { AxiosResponse } from 'axios';

import { API, APIResponse, useApi } from '@api/api';
import { LoginResponse, AllowedRole, AllowedUserState, User, ConfirmResponse, RegistrationConfirmData } from '@types';

export const loginPath = () => `/login`;
export const logoutPath = () => `/logout`;
export const loggedPath = () => `/user/logged`;
export const sendActivationEmailPath = () => `/user/send-activation-email`;
export const registrationConfirmPath = (token: string) => `/user/registration-confirm/${token}`;
export const resetPasswordPath = () => `/user/reset-password`;
export const resetPasswordConfirmPath = () => `/user/reset-password/confirm`;
export const changePasswordPath = () => `/user/change-password`;
export const allowedRolesPath = () => `enum/user/get-allowed-roles`;
export const userAllowedStatesPath = () => `/user/get-allowed-states`;

export interface AuthAPI extends API {
  logout(isAdmin: boolean): APIResponse;
  login(email: string, password: string, section: 'admin' | 'fe'): Promise<AxiosResponse<LoginResponse>>;
  logged(isFront?: boolean): APIResponse<User>;
  confirm(token: string, data: RegistrationConfirmData): APIResponse<ConfirmResponse>;
  sendActivationEmail(email: string): APIResponse;
  resetPassword(email: string): APIResponse;
  newPassword(token: string, password1: string, password2: string): APIResponse<{ isAdmin: boolean }>;
  changePassword(oldPassword: string, newPassword: string, newPassword1: string): APIResponse;
  getAllowedUserRoles(type: 'admin' | 'auctioneer' | 'user'): Promise<APIResponse<Array<AllowedRole>>>;
  getAllowedUserStates(): Promise<AxiosResponse<Array<AllowedUserState>>>;
}

export const useAuthApi = (): AuthAPI => {
  const api = useApi();

  return {
    ...api,
    logout(isAdmin: boolean) {
      const cancelToken = api.prepareCancelToken('logout');
      return (isAdmin ? api.authRequest : api.authFrontRequest).delete(logoutPath(), { cancelToken });
    },
    login(email: string, password: string, section: 'admin' | 'fe') {
      const cancelToken = api.prepareCancelToken('login');
      return api.request.post(loginPath(), { email, password, section: section }, { cancelToken });
    },
    logged(isFront?: boolean) {
      const cancelToken = api.prepareCancelToken('logged');
      if (isFront) {
        return api.authFrontRequest.get(loggedPath(), { cancelToken });
      } else {
        return api.authRequest.get(loggedPath(), { cancelToken });
      }
    },
    confirm(token: string, data: RegistrationConfirmData) {
      const cancelToken = api.prepareCancelToken('confirm');
      return api.request.put(registrationConfirmPath(token), data, { cancelToken });
    },
    sendActivationEmail(email: string) {
      const cancelToken = api.prepareCancelToken('sendActivationEmail');
      return api.request.post(sendActivationEmailPath(), { email }, { cancelToken });
    },
    resetPassword(email: string) {
      const cancelToken = api.prepareCancelToken('resetPassword');
      return api.request.post(resetPasswordPath(), { email }, { cancelToken });
    },
    newPassword(token: string, password1: string, password2: string) {
      const cancelToken = api.prepareCancelToken('newPassword');
      return api.request.put(
        resetPasswordConfirmPath(),
        {
          newPassword: password1,
          newPassword1: password2,
        },
        {
          cancelToken,
          params: { token },
        }
      );
    },
    changePassword(oldPassword: string, newPassword: string, newPassword1: string) {
      const cancelToken = api.prepareCancelToken('changePassword');
      return api.authFrontRequest.post(
        changePasswordPath(),
        {
          oldPassword,
          newPassword,
          newPassword1,
        },
        { cancelToken }
      );
    },
    getAllowedUserRoles(type: 'admin' | 'auctioneer' | 'user') {
      const cancelToken = api.prepareCancelToken('allowedRoles');
      return api.authRequest.get(allowedRolesPath(), { cancelToken, params: { type: type } });
    },
    getAllowedUserStates() {
      const cancelToken = api.prepareCancelToken('allowedStates');
      return api.authRequest.get(userAllowedStatesPath(), { cancelToken });
    },
  };
};
