import React from 'react';

import { BasePage, AdminBoxContent } from '@components';

interface Props {
  page: string;
}

const Admin404Page: React.FC<Props> = (props) => {
  return (
    <BasePage title="Stránka nenalezena" page={props.page}>
      <AdminBoxContent className="p-0">
        <div className="w-min-500">
          <div className="component-admin-box-content overlow-x-auto">Omlouváme se, tuto sekci připravujeme.</div>
        </div>
      </AdminBoxContent>
    </BasePage>
  );
};

export default Admin404Page;
