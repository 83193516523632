import Cookies from 'js-cookie';

import config from '@config';
import { User } from '@types';
import { createStore, useStoreState } from './store';

export interface AuthFrontState {
  isLogged: boolean;
  user?: User;
}

export const authFrontStore = createStore<AuthFrontState>({
  isLogged: false,
  user: undefined,
});

export const useAuthFrontStore = () => {
  const authState = useStoreState(authFrontStore);

  const setUser = (user: User): void => {
    authFrontStore.setState({
      user,
      isLogged: true,
    });
  };

  const unsetUser = (): void => {
    authFrontStore.setState({
      isLogged: false,
      user: undefined,
    });
    clearAuthCookies();
  };

  const setAuthCookies = (token: string, refreshToken: string): void => {
    Cookies.set(config.auth.accessTokenFrontCookieName, token, { expires: 365 });
    Cookies.set(config.auth.refreshTokenFrontCookieName, refreshToken, { expires: 365 });
  };

  const clearAuthCookies = (): void => {
    Cookies.remove(config.auth.accessTokenFrontCookieName);
    Cookies.remove(config.auth.refreshTokenFrontCookieName);
  };

  return {
    ...authState,
    setUser,
    unsetUser,
    setAuthCookies,
    clearAuthCookies,
  };
};
