import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';

import { usePageStore } from '@stores';
import * as routes from '@routes';
import { BasePreloader, FormGroup, Button } from '@components';
import Wysiwyg from '@components/Wysiwyg/Wysiwyg';
import { useNewsApi } from '@api/news';

interface Props {
  id?: number;
}

const NewForm: React.FC<Props> = (props) => {
  const history = useHistory();
  const pageState = usePageStore();
  const newsApi = useNewsApi();
  const [loaded, setLoaded] = React.useState(true);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: '',
      perex: '',
      content: '',
    },
    onSubmit: (values) => handleSubmit(values),
  });

  React.useEffect(() => {
    if (props.id) {
      loadNew(props.id);
    }
    return () => {
      newsApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const loadNew = (id: number) => {
    setLoaded(false);
    newsApi
      .detailNews(id)
      .then((res) => {
        const data = res.data.data;
        formik.setValues({ title: data.title, perex: data.perex, content: data.content });
        setLoaded(true);
      })
      .catch((err) => {
        if (newsApi.isCancel(err)) {
          return;
        }
      });
  };

  const handleSubmit = (values: FormikValues) => {
    (!!props.id ? newsApi.updateNews(props.id as number, formik.values) : newsApi.createNews(formik.values))
      .then(() => {
        formik.setSubmitting(false);
        history.push(pageState.getPagePath(routes.admin.SETTINGS_NEWS));
      })
      .catch((err) => {
        if (newsApi.isCancel(err)) {
          return;
        }
        const errors = err.response?.data?.errors || {};
        Object.getOwnPropertyNames(errors).map((prop) => {
          formik.setFieldError(prop, errors[prop][0]);
          return prop;
        });
        formik.setSubmitting(false);
      });
  };

  return (
    <div>
      {loaded ? (
        <>
          <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-5">
            <div className="responsive-table-content">
              <div>
                <FormGroup
                  type="text"
                  name="title"
                  label="Název"
                  labelClassName="text-left"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  error={formik.errors.title}
                  required
                />
                <Wysiwyg
                  name="perex"
                  label="Perex aktuality"
                  required={true}
                  error={formik.errors.perex as string}
                  value={formik.values.perex}
                  onChange={(val) => formik.setFieldValue('perex', val)}
                />
                <Wysiwyg
                  name="content"
                  label="Obsah aktuality"
                  required={true}
                  error={formik.errors.content as string}
                  value={formik.values.content}
                  onChange={(val) => formik.setFieldValue('content', val)}
                />
              </div>
            </div>
            <Row>
              <Col xs={12} className="mt-4 text-right">
                {!formik.isSubmitting ? (
                  <Button variant="btn-outline-primary" type="submit" disabled={formik.isSubmitting}>
                    Uložit
                  </Button>
                ) : (
                  <BasePreloader size={29} className="d-inline-block" />
                )}
              </Col>
            </Row>
          </Form>
        </>
      ) : (
        <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
          <BasePreloader />
        </div>
      )}
    </div>
  );
};

export default NewForm;
