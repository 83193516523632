import React, { ChangeEvent, useCallback, useState } from 'react';

import { AddressType, LatLng } from '@types';
import { useAddressApi } from '@api/address';
import { BasePreloader, Button, FormGroup, Modal } from '@components';

type Props = {
  isOpen: boolean;
  gps?: string;
  onClose: () => void;
  onSelect: (address: AddressType) => void;
};

let mapInstance: any = undefined;
let markerLayer: any = undefined;
let mapMarker: any = undefined;

export const MapAddressModal: React.FC<Props> = (props) => {
  const addressApi = useAddressApi();
  const [address, setAddress] = useState('');
  const [loaded, setLoaded] = useState(false);
  const [addressGps, setAddressGps] = useState<LatLng | undefined>();
  const [fullAddress, setFullAddress] = useState<AddressType | undefined>();
  const mapCallback = useCallback(async (node: HTMLDivElement) => {
    const center = window.SMap.Coords.fromWGS84(15.4749126, 49.8037633);

    mapInstance = new window.SMap(node, center, 7);
    mapInstance.addDefaultControls();
    mapInstance.addDefaultLayer(window.SMap.DEF_BASE).enable();

    markerLayer = new window.SMap.Layer.Marker();
    mapInstance.addLayer(markerLayer);
    markerLayer.enable();

    if (props.gps) {
      const markerLatLng = props.gps.split(',');
      if (markerLatLng.length === 2) {
        const markerCenter = window.SMap.Coords.fromWGS84(markerLatLng[1], markerLatLng[0]);
        mapMarker = new window.SMap.Marker(markerCenter, 'marker', {});
        markerLayer.addMarker(mapMarker);
        mapInstance.setZoom(18);
        mapInstance.setCenter(markerCenter);
        try {
          const res = await addressApi.gpsAddress(parseFloat(markerLatLng[0]), parseFloat(markerLatLng[1]));
          setAddress(getAddressLabel(res.data.data, parseFloat(markerLatLng[0]), parseFloat(markerLatLng[1])));
          setAddressGps({ lat: parseFloat(markerLatLng[0]), lng: parseFloat(markerLatLng[1]) });
          setFullAddress(res.data.data);
          setLoaded(true);
        } catch (err: any) {
          if (addressApi.isCancel(err)) {
            return;
          }
          setLoaded(true);
        }
      }
    } else {
      setLoaded(true);
    }

    const signals = mapInstance.getSignals();
    signals.addListener(window, '*', handleMapClick);

    const suggest = new window.SMap.Suggest(document.getElementById('address-input'), {
      provider: new window.SMap.SuggestProvider({
        updateParams: (params: any) => {
          let c = mapInstance.getCenter().toWGS84();
          params.lon = c[0].toFixed(5);
          params.lat = c[1].toFixed(5);
          params.zoom = mapInstance.getZoom();
          params.lang = 'cs,en';
        },
      }),
    });

    suggest.addListener('suggest', handleSuggest);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSuggest = async (data: any) => {
    setLoaded(false);
    const coords = window.SMap.Coords.fromWGS84(data.data.longitude, data.data.latitude);
    if (markerLayer) {
      markerLayer.removeAll();
    }
    mapMarker = new window.SMap.Marker(coords, 'marker', {});
    markerLayer.addMarker(mapMarker);
    mapInstance.setZoom(18);
    mapInstance.setCenter(coords);
    try {
      const res = await addressApi.gpsAddress(data.data.latitude, data.data.longitude);
      setFullAddress(res.data.data);
      setAddress(getAddressLabel(res.data.data, data.data.latitude, data.data.longitude));
      setAddressGps({ lat: data.data.latitude, lng: data.data.longitude });
      setLoaded(true);
    } catch (err: any) {
      if (addressApi.isCancel(err)) {
        return;
      }
      setLoaded(true);
    }
  };

  const handleMapClick = async (e: any) => {
    if (e.type === 'map-click') {
      setLoaded(false);
      const coords = window.SMap.Coords.fromEvent(e.data.event, mapInstance);
      const latLng = coords.toWGS84();
      if (markerLayer) {
        markerLayer.removeAll();
      }
      const center = window.SMap.Coords.fromWGS84(latLng[0], latLng[1]);
      mapMarker = new window.SMap.Marker(center, 'marker', {});
      markerLayer.addMarker(mapMarker);
      mapInstance.setCenter(coords);

      try {
        const res = await addressApi.gpsAddress(latLng[1], latLng[0]);
        setFullAddress(res.data.data);
        setAddress(getAddressLabel(res.data.data, latLng[1], latLng[0]));
        setAddressGps({ lat: latLng[1], lng: latLng[0] });
        setLoaded(true);
      } catch (err: any) {
        if (addressApi.isCancel(err)) {
          return;
        }
        setLoaded(true);
      }
    }
  };

  const getAddressLabel = (gpsData: AddressType, lat: number, long: number) => {
    let newAddress = '';
    if (gpsData.addressStreet) {
      newAddress += gpsData.addressStreet;
    }
    if (gpsData.addressHouseNumber) {
      if (gpsData.addressStreet) {
        newAddress += ' ';
      }
      newAddress += gpsData.addressHouseNumber;
    }
    if (gpsData.addressCity) {
      if (newAddress) {
        newAddress += ', ';
      }
      newAddress += gpsData.addressCity;
    }
    if (gpsData.addressCity && gpsData.addressZipCode) {
      newAddress += ` ${gpsData.addressZipCode}`;
    }
    if (newAddress) {
      newAddress += ' ';
    }
    newAddress += `(${lat},${long})`;
    return newAddress;
  };

  const handleSelectClick = async () => {
    if (fullAddress) {
      props.onSelect(fullAddress);
    }
  };

  return (
    <Modal
      title="Mapa"
      className="component-admin-map-modal"
      isOpen={props.isOpen}
      onRequestClose={() => props.onClose()}
    >
      <div>
        <div className="address-input-wrapper">
          <FormGroup
            readOnly={!loaded}
            name="address"
            value={address}
            label="Adresa:"
            id="address-input"
            labelClassName="address-label"
            onChange={(e: ChangeEvent<HTMLInputElement>) => setAddress(e.target.value)}
          />
        </div>
        <div>
          <div className="map" ref={mapCallback} style={{ height: 500 }} />
        </div>
        {!!addressGps && (
          <div className="button-wrapper">
            {loaded ? (
              <Button type="button" onClick={() => handleSelectClick()}>
                Vyplnit adresu
              </Button>
            ) : (
              <BasePreloader />
            )}
          </div>
        )}
        {!addressGps && !loaded && (
          <div className="button-wrapper">
            <BasePreloader />
          </div>
        )}
      </div>
    </Modal>
  );
};
