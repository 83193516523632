import React from 'react';

import { Modal } from '@components';
import { AuctionService } from '@types';
import { ModalProps } from '@components/Modal/Modal';
import { getCurrencyFormat, getPercentageFormat } from '@helpers/formats';

interface Props extends ModalProps {
  data: AuctionService;
  actionText: string;
  onAction: () => void;
  onRequestClose: () => void;
}

const ServiceDetailModal: React.FC<Props> = (props) => {
  return (
    <Modal className="service-detail-modal-parent" {...props}>
      <div className="service-detail-modal">
        <h5>Ceník</h5>
        <div className="items-wrapper">
          <div className="items-holder">
            <p className="font-weight-bold">Cenové rozpětí</p>
            <p className="font-weight-bold">Procenta</p>
            <p className="font-weight-bold">Paušální částka</p>
          </div>
          <div className="items-holder">
            <p className="mr-2">0 - 30 000:</p>
            <p>{getPercentageFormat(props.data.auctioneerServiceElectronicAuction?.percent30000)}</p>
            <p>{getCurrencyFormat(props.data.auctioneerServiceElectronicAuction?.plus30000)}</p>
          </div>
          <div className="items-holder">
            <p className="mr-2">30 001 - 300 0000:</p>
            <p>{getPercentageFormat(props.data.auctioneerServiceElectronicAuction?.percent300000)}</p>
            <p>{getCurrencyFormat(props.data.auctioneerServiceElectronicAuction?.plus300000)}</p>
          </div>
          <div className="items-holder">
            <p className="mr-2">300 001 - 1 000 000:</p>
            <p>{getPercentageFormat(props.data.auctioneerServiceElectronicAuction?.percent1000000)}</p>
            <p>{getCurrencyFormat(props.data.auctioneerServiceElectronicAuction?.plus1000000)}</p>
          </div>
          <div className="items-holder">
            <p className="mr-2">1 000 001 - 3 000 000:</p>
            <p>{getPercentageFormat(props.data.auctioneerServiceElectronicAuction?.percent3000000)}</p>
            <p>{getCurrencyFormat(props.data.auctioneerServiceElectronicAuction?.plus3000000)}</p>
          </div>
          <div className="items-holder">
            <p className="mr-2">3 000 001 - 10 000 000:</p>
            <p>{getPercentageFormat(props.data.auctioneerServiceElectronicAuction?.percent10000000)}</p>
            <p>{getCurrencyFormat(props.data.auctioneerServiceElectronicAuction?.plus10000000)}</p>
          </div>
          <div className="items-holder">
            <p className="mr-2">10 000 001 - 999 999 999:</p>
            <p>{getPercentageFormat(props.data.auctioneerServiceElectronicAuction?.percent999999999)}</p>
            <p>{getCurrencyFormat(props.data.auctioneerServiceElectronicAuction?.plus999999999)}</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ServiceDetailModal;
