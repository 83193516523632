import React from 'react';
import { Link } from 'react-router-dom';

import { HOME } from '@routes/front';

import '../../assets/scss/component-auction-not-found.scss';

const FrontAuctionNotFound = () => {
  return (
    <div className="auction-not-found">
      <h1 className="headline">404</h1>
      <p className="info">Stránka nebyla nalezena</p>
      <p className="info__small">
        Zdá se, že stránka, kterou jste hledali, byla buď přesunuta, smazána nebo neexistuje.
      </p>

      <Link to={HOME} className="auction-not-found__btn">
        Přejít na domovskou stránku
      </Link>
    </div>
  );
};

export default FrontAuctionNotFound;
