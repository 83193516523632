export const isHTML = (str: string) => {
  const doc = new DOMParser().parseFromString(str, 'text/html');
  return Array.from(doc.body.childNodes).some((node) => node.nodeType === 1);
};

export const getName = (data?: {
  firstName?: string;
  lastName?: string;
  degreePre?: string;
  degreePost?: string;
  company?: string;
}): string | null => {
  if (!!data?.firstName || !!data?.lastName) {
    return `${data?.degreePre ? `${data?.degreePre} ` : ``}${data?.firstName} ${data?.lastName}${
      data?.degreePost ? `, ${data?.degreePost}` : ``
    }`;
  } else {
    return null;
  }
};

export const randStr = (length: number): string => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

export const strIsLatin = (value: string) => {
  const regexp = new RegExp(/^[`\p{sc=Latin}\s0-9.,+-/]+$/u);
  return regexp.test(value);
};
