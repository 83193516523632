import React from 'react';
import cx from 'classnames';
import { FormikErrors } from 'formik';

import { Button } from '@components';
import { BuyerFormValues, UserAdditionalPersonValues } from '@types';
import Partner from '@components/RegistrationSteps/Partner';

interface Props {
  readOnly?: boolean;
  title?: string;
  withoutSuggest?: boolean;
  contentClassName?: string;
  values: BuyerFormValues;
  errors: FormikErrors<BuyerFormValues>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

const Partners: React.FC<Props> = (props) => {
  const handleAddPartnerClick = () => {
    const currentValue = props.values.userAdditionalsPerson ?? [];
    currentValue.push({
      city: '',
      share: '',
      zipCode: '',
      address: '',
      lastName: '',
      firstName: '',
      birthdate: '',
      identityCardNumber: '',
      country: 'Česká republika',
      personalIdentificationNumber: '',
    });
    props.setFieldValue('userAdditionalsPerson', currentValue);
  };

  return (
    <div className="step-buyer-section">
      {!!props.title && <div className={cx('section-title')}>{props.title}</div>}
      {props.values.type !== 'joint_ownership' && (
        <Button className="mb-4" onClick={handleAddPartnerClick}>
          Přidat spoluvlastníka
        </Button>
      )}
      <div>
        {(props.values.userAdditionalsPerson || []).map((item: UserAdditionalPersonValues, index: number) => (
          <Partner
            itemIndex={index}
            title={props.title}
            errors={props.errors}
            values={props.values}
            readOnly={props.readOnly}
            handleChange={props.handleChange}
            setFieldValue={props.setFieldValue}
            withoutSuggest={props.withoutSuggest}
            key={`userAdditionalsPerson-${index}`}
          />
        ))}
      </div>
    </div>
  );
};

export default Partners;
