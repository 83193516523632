import React, { ReactElement } from 'react';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import cs from 'date-fns/locale/cs';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('cs', cs);

interface Props {
  readOnly?: boolean;
  dateFormat?: string;
  name?: string;
  value?: Date;
  minDate?: Date;
  maxDate?: Date;
  isNullable?: boolean;
  className?: string;
  clearIcon?: string | ReactElement | null;
  calendarIcon?: string | ReactElement | null;
  onChange?: (value: Date | Array<Date> | null) => void;
  showMonthYearPicker?: boolean;
}

const DatePickerInput: React.FC<Props> = (props) => {
  const handleChange = (date: Date | [Date | null, Date | null] | null) => {
    if (props.onChange && date instanceof Date) {
      props.onChange(date);
    }
    if (props.onChange && props.isNullable && date === null) {
      props.onChange(null);
    }
  };

  const getDateObject = () => {
    if (!props.value) {
      return undefined;
    }
    const momentDate = moment(props.value);
    momentDate.add(momentDate.toDate().getTimezoneOffset() + momentDate.utcOffset(), 'minutes');
    return momentDate.toDate();
  };

  return (
    <DatePicker
      locale="cs"
      dateFormat={props.dateFormat || 'P'}
      disabled={props.readOnly}
      readOnly={props.readOnly}
      minDate={props.minDate}
      maxDate={props.maxDate}
      selected={getDateObject()}
      className={props.className}
      showMonthYearPicker={props.showMonthYearPicker}
      onChange={handleChange}
    />
  );
};

export default DatePickerInput;
