import React from 'react';
import cx from 'classnames';

import { getCurrencyFormat } from '@helpers/formats';
import { getDateTimeFormat } from '@helpers/datetime';
import { AuctionResponse, BaseObjectType } from '@types';
import { FrontMainFilter, Pagination } from '@components';
import AuctionImage from '@components/AuctionImage/AuctionImage';

import bellRedIco from '@assets/images/bell-red.svg';
import bellGreenIco from '@assets/images/bell-green.svg';
import auctionNoImage from '@assets/images/auction-no-image.svg';

type Props = {
  setQuery: any;
  query: BaseObjectType;
  status: string;
  loaded: boolean;
  auctioneer?: string;
  totalAuctions: number;
  auctions: AuctionResponse[];
  showFiltersPreloader: boolean;
  onSelect: (item: { id: string | number; type: string }) => void;
};

export const AuctionsList: React.FC<Props> = (props) => {
  const getTypeText = (auction: AuctionResponse) => {
    return auction.auctionCategory.parentTitle
      ? `${auction.auctionCategory.parentTitle} - ${auction.auctionCategory.title}:`
      : auction.auctionCategory.title;
  };

  const renderContentItem = (label: string, value: React.ReactNode) => {
    return (
      <p className="item-info">
        <span className="f-weight-700 mr-2">{label}</span>
        {value}
      </p>
    );
  };

  const renderContentTimeframe = (auction: AuctionResponse) => {
    return (
      <>
        {auction.startDt && (
          <p>
            <img src={bellGreenIco} style={{ marginRight: '8px' }} alt="" />
            {auction.startDt ? (
              <>
                {auction.translations?.auction_start || 'auction_start'} {getDateTimeFormat(auction.startDt)}
              </>
            ) : (
              <>{auction.translations?.auction_start || 'auction_start_null'}</>
            )}
          </p>
        )}
        {(auction.endDt || auction.realEndDt) && (
          <p>
            <img src={bellRedIco} style={{ marginRight: '8px' }} alt="" />
            {auction.translations?.auction_end || 'auction_end'}{' '}
            {getDateTimeFormat(auction.realEndDt || auction.endDt, {
              withSeconds: !!auction.realEndDt,
            })}
          </p>
        )}
      </>
    );
  };

  const renderContentPrices = (auction: AuctionResponse) => {
    return (
      <div className="content-bottom">
        <div className="item-price">{getCurrencyFormat(auction.winnerBid?.bidPrice || auction.minimalBid)}</div>
      </div>
    );
  };

  return (
    <div className="layout-iframe">
      <FrontMainFilter
        hideType
        hasToggler
        withMap={false}
        closeWithSubmit
        query={props.query}
        status={props.status}
        auctions={[]}
        auctioneer={props.auctioneer}
        auctionsLoaded={props.loaded}
        itemsCount={props.totalAuctions}
        showPreloader={props.showFiltersPreloader}
        onSearch={(searchProps) => props.setQuery(searchProps, 'push')}
      />

      <div className="component-front-auctions-list">
        <div className="list-items">
          {props.auctions.map((auction) => (
            <div className="list-item" key={`auction-${auction.id}`}>
              <div className="item-wrapper">
                <div className="item-image">
                  {!!auction.parentStatus && <div className="auction-tag text-left">{auction.statusTranslation}</div>}
                  <div className="image-inner">
                    <div
                      className={cx([
                        'item-centered',
                        {
                          'no-image': !(
                            !!auction?.auctionMedia &&
                            auction.auctionMedia.length > 0 &&
                            !!auction.auctionMedia[0]?.media?.hash
                          ),
                        },
                      ])}
                    >
                      <a
                        href="/iframe"
                        className="text-d-none"
                        onClick={(e) => {
                          e.preventDefault();
                          props.onSelect({
                            id: auction.id,
                            type: auction.auctionType === 'auction' ? 'auction' : 'auction_public',
                          });
                        }}
                      >
                        {!!auction?.auctionMedia &&
                        auction.auctionMedia.length > 0 &&
                        !!auction.auctionMedia[0]?.media?.hash ? (
                          <AuctionImage hash={auction.auctionMedia[0].media.hash} size="auction.crop" />
                        ) : (
                          <img src={auctionNoImage} alt="auction" />
                        )}
                      </a>
                    </div>
                  </div>
                </div>

                <div className="item-content">
                  <div className="content-inner">
                    <h2 className="item-title">
                      <a
                        href="/iframe"
                        className="text-d-none"
                        onClick={(e) => {
                          e.preventDefault();
                          props.onSelect({
                            id: auction.id,
                            type: auction.auctionType === 'auction' ? 'auction' : 'auction_public',
                          });
                        }}
                      >
                        {auction.title}
                      </a>
                    </h2>
                    {renderContentItem('Type:', auction.typeTranslation || auction.auctionType)}
                    {renderContentItem(getTypeText(auction), `čj: ${auction.number}`)}
                    {renderContentItem(
                      'Kraj:',
                      `${auction?.auctionAddress?.region?.name}, ${auction?.auctionAddress?.city}` || '-'
                    )}
                    {auction?.winnerBid?.bidPrice &&
                      auction.minimalBid &&
                      renderContentItem(
                        `${auction.translations?.auction_start_price || 'auction_start_price'}:`,
                        getCurrencyFormat(auction.minimalBid)
                      )}
                    {renderContentItem(
                      `${auction.translations?.auction_security_pay || 'auction_security_pay'}:`,
                      parseFloat(auction.cautionDeposit || '0')
                        ? getCurrencyFormat(auction.cautionDeposit)
                        : `Není vyžadována`
                    )}
                  </div>
                  <div className="content-middle">{renderContentTimeframe(auction)}</div>
                  {renderContentPrices(auction)}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {props.loaded && !props.showFiltersPreloader && props.totalAuctions > props.query.perPage && (
        <div className="mt-2">
          <Pagination
            page={props.query.page}
            perPage={props.query.perPage}
            totalRows={props.totalAuctions}
            onPageChange={(page) => props.setQuery({ ...props.query, page: page }, 'push')}
          />
        </div>
      )}
    </div>
  );
};
