import React from 'react';
import { Formik, FormikValues } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import { Link, useHistory } from 'react-router-dom';

import * as routes from '@routes';
import { useAuthApi } from '@api/auth';
import { usePageStore } from '@stores';
import { Button, PasswordValidator } from '@components';

interface Props {
  token: string;
}

const NewPasswordForm: React.FC<Props> = (props) => {
  const history = useHistory();
  const authApi = useAuthApi();
  const pageState = usePageStore();

  const handleFormSubmit = (
    values: FormikValues,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    authApi
      .newPassword(props.token, values.newPassword, values.newPassword1)
      .then((res) => {
        setFieldValue('newPassword', '');
        setFieldValue('newPassword1', '');
        setSubmitting(false);
        if (res.data.data.isAdmin) {
          history.push(pageState.getPagePath(routes.auth.LOGIN));
        } else {
          history.push('/?open-login');
        }
      })
      .catch((err) => {
        if (authApi.isCancel(err)) {
          return;
        }
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{
        newPassword: '',
        newPassword1: '',
      }}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        handleFormSubmit(values, setSubmitting, setFieldValue);
      }}
    >
      {(formProps) => (
        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formProps.handleSubmit(e)}>
          <Form.Group>
            <Form.Label>Heslo</Form.Label>
            <Form.Control
              required={true}
              disabled={formProps.isSubmitting}
              type="password"
              value={formProps.values.newPassword}
              name="newPassword"
              onChange={formProps.handleChange}
            />
            <PasswordValidator value={formProps.values.newPassword} />
          </Form.Group>

          <Form.Group>
            <Form.Label>Heslo znovu</Form.Label>
            <Form.Control
              required={true}
              disabled={formProps.isSubmitting}
              type="password"
              value={formProps.values.newPassword1}
              name="newPassword1"
              onChange={formProps.handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-0">
            <Row className="align-items-center mt-4 pt-2">
              <Col xs={6}>
                <Button disabled={formProps.isSubmitting} variant="btn-outline-primary" type="submit">
                  Uložit heslo
                </Button>
              </Col>
              <Col xs={6} className="text-right">
                <Link to={routes.auth.LOGIN}>Přihlásit se</Link>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

export default NewPasswordForm;
