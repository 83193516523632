import React from 'react';
import classNames from 'classnames';

import { AdminProgressFormMenuItemType } from '@types';
import { Link } from 'react-router-dom';

interface Props {
  dataTestId?: string;
  items: Array<AdminProgressFormMenuItemType>;
  onClick: (step: number | string) => void;
}

const AdminProgressFormMenu: React.FC<Props> = (props) => {
  const handleItemClick = (item: AdminProgressFormMenuItemType, index: number) => {
    if (!item.disabled) {
      props.onClick(item.url || index + 1);
    }
  };

  const getTestId = (item: AdminProgressFormMenuItemType) => {
    if (props.dataTestId && item.routeName) {
      return `${props.dataTestId}-${item.routeName}`;
    }
    return undefined;
  };

  return (
    <div className="component-admin-progress-form-menu">
      <ul>
        {props.items.map((item, index) => (
          <React.Fragment key={`menu-item-${index}`}>
            {item.outsideUrl ? (
              <li className={classNames({ 'is-active': item.isActive })} data-test-id={getTestId(item)}>
                <Link to={item.outsideUrl} className="text-decoration-none">
                  <span className={classNames({ 'cursor-pointer': !item.disabled })}>
                    {item.label}
                    {item.hasError && !item.isActive && <span className="item-error" />}
                  </span>
                </Link>
              </li>
            ) : (
              <li className={classNames({ 'is-active': item.isActive })} data-test-id={getTestId(item)}>
                <span
                  onClick={() => handleItemClick(item, index)}
                  className={classNames({ 'cursor-pointer': !item.disabled })}
                >
                  {item.label}
                  {item.hasError && !item.isActive && <span className="item-error" />}
                </span>
              </li>
            )}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default AdminProgressFormMenu;
