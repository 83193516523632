import React from 'react';
import classNames from 'classnames';

interface Props {
  title?: string;
  className?: string;
}

const FrontAccountBox: React.FC<Props> = (props) => (
  <div className={classNames(['component-front-account-box', props.className])}>
    {!!props.title && <h1 className="box-title">{props.title}</h1>}
    {props.children}
  </div>
);

export default FrontAccountBox;
