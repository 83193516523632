import React from 'react';
import _ from 'lodash';
import { useFormik } from 'formik';
import { ValueType } from 'react-select';
import { Col, Form, Row } from 'react-bootstrap';

import { useEnumApi } from '@api/enum';
import { useLocationApi } from '@api/location';
import { useAuctionsApi } from '@api/auctions';
import { useWatchDogApi } from '@api/watchDog';
import { FormGroup, Button, Select } from '@components';
import { useAuctionCategoriesApi } from '@api/auctionCategories';
import BasePreloader from '@components/BasePreloader/BasePreloader';
import FrontTabsMenu from '@components/FrontTabsMenu/FrontTabsMenu';
import {
  District,
  Region,
  WebCategories,
  BaseObjectType,
  WatchDogType,
  EnumType,
  TrackVariant,
  DistrictPart,
} from '@types';

type Props = {
  item?: WatchDogType;
  onSave: () => void;
};

type Values = {
  title: string;
  search: string;
  interval: string;
  priceTo: string;
  state: string;
  priceFrom: string;
  trackAll: boolean;
  regions: string[];
  districts: BaseObjectType<string[]>;
  districtsParts: BaseObjectType<string[]>;
  subCategories: string[];
  auctionType: string[];
};

const AccountWatchdogFilter: React.FC<Props> = (props) => {
  const enumApi = useEnumApi();
  const watchDogApi = useWatchDogApi();
  const locationApi = useLocationApi();
  const auctionsApi = useAuctionsApi();
  const auctionCategoriesApi = useAuctionCategoriesApi();
  const [showPreloader, setShowPreloader] = React.useState(true);
  const [showFilters, setShowFilters] = React.useState(false);
  const [regions, setRegions] = React.useState<Array<Region>>([]);
  const [districts, setDistricts] = React.useState<Array<District>>([]);
  const [districtsParts, setDistrictsParts] = React.useState<Array<DistrictPart>>([]);
  const [filterMenuActive, setFilterMenuActive] = React.useState('0');
  const [mainCategories, setMainCategories] = React.useState<WebCategories[]>([]);
  const [subCategories, setSubCategories] = React.useState<WebCategories[]>([]);
  const [watchDogInterval, setWatchDogInterval] = React.useState<EnumType[]>([]);
  const [watchDogStates, setWatchDogStates] = React.useState<EnumType[]>([]);
  const [watchDogTrackVariant, setWatchDogTrackVariant] = React.useState<TrackVariant[]>([]);
  const [watchDog, setWatchDog] = React.useState<WatchDogType | undefined>(undefined);
  const initialValue = {
    title: '',
    search: '',
    interval: '',
    priceTo: '',
    state: '',
    priceFrom: '',
    trackAll: true,
    regions: [],
    districts: {},
    districtsParts: {},
    subCategories: [],
    auctionType: [],
  };

  const formik = useFormik<Values>({
    validateOnChange: false,
    enableReinitialize: true,
    initialValues: initialValue,
    onSubmit: () => handleSubmit(),
  });

  const setTrackOptions = () => {
    setWatchDogTrackVariant([
      {
        name: 'Vše',
        value: true,
      },
      {
        name: 'Vlastní nastavení',
        value: false,
      },
    ]);
  };

  const displayDistricts = districts.filter((district: District) => {
    return ~formik.values.regions.indexOf((district.region?.id || 0).toString());
  });

  const displayDistrictsParts = districtsParts.filter((districtPart: DistrictPart) => {
    return Object.keys(formik.values.districts).some(
      (key) => ~formik.values.districts[key].indexOf(districtPart.district?.id?.toString() || '')
    );
  });

  const watchDogStatesOptions = watchDogStates.map((v) => ({ value: v.type, label: v.translated }));
  const watchDogIntervalOptions = watchDogInterval.map((v) => ({ value: v.type, label: v.translated }));
  const watchDogTrackAllOptions = watchDogTrackVariant.map((v) => ({ value: v.value.toString(), label: v.name }));

  React.useEffect(() => {
    loadData();
    return () => {
      enumApi.cancelAllRequests();
      watchDogApi.cancelAllRequests();
      locationApi.cancelAllRequests();
      auctionsApi.cancelAllRequests();
      auctionCategoriesApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.item]);

  const loadData = async () => {
    setWatchDog(undefined);
    await formik.setValues(formik.initialValues);
    setTrackOptions();
    await loadRegions();
    await loadDistricts();
    await loadDistrictsParts();
    await loadWatchDogStates();
    await loadWatchDogInterval();
    await loadMainCategories();
    await loadWatchDog();
  };

  React.useEffect(() => {
    if (filterMenuActive !== '0') {
      loadSubCategories(filterMenuActive);
    } else {
      setSubCategories([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterMenuActive]);

  const loadWatchDog = async () => {
    if (!!props.item) {
      setWatchDog(props.item);
      await formik.setFieldValue('title', props.item.title);
      await formik.setFieldValue('state', props.item.state);
      await formik.setFieldValue('priceTo', props.item.priceTo);
      await formik.setFieldValue('priceFrom', props.item.priceFrom);
      await formik.setFieldValue('search', props.item.keywords);
      await formik.setFieldValue('interval', props.item.watchDogInterval);
      await formik.setFieldValue('trackAll', props.item.trackAll);

      setShowFilters(!props.item.trackAll);

      if (props.item.auctionWatchDogCategories.length > 0) {
        setFilterMenuActive(
          props.item.auctionWatchDogCategories[0].auctionCategory.parentId?.toString() ||
            props.item.auctionWatchDogCategories[0].auctionCategory.id.toString()
        );
        await formik.setFieldValue(
          'subCategories',
          props.item.auctionWatchDogCategories.map((i) => i.auctionCategory?.id.toString())
        );
      }
      if (props.item.auctionWatchDogRegions.length > 0) {
        const regions: string[] = [];
        const districts = {};
        props.item.auctionWatchDogRegions.forEach((item) => {
          regions.push(item.region.id.toString());
          _.set(
            districts,
            item.region.id.toString(),
            (item.auctionWatchDogDistricts || []).map((d) => d.district.id.toString())
          );
          item.auctionWatchDogDistricts.forEach((district) => {
            _.set(
              districtsParts,
              district.district.id.toString(),
              (district.auctionWatchDogDistrictParts || []).map((dp) => dp.districtPart.id.toString())
            );
          });
        });
        await formik.setFieldValue('regions', regions);
        await formik.setFieldValue('districts', districts);
        await formik.setFieldValue('districtsParts', districtsParts);
      }
    }
  };

  const trackAllClick = (val: ValueType<any, any>) => {
    formik.setFieldValue('trackAll', val?.value === 'true' || false);
    setShowFilters(val?.value === 'false');
  };

  const loadWatchDogStates = async () => {
    try {
      const response = await enumApi.getStates();
      setWatchDogStates(response.data.data);
      if (response.data.data.length > 0) {
        formik.setFieldValue('state', response.data.data[0].type);
      }
    } catch (err) {
      if (enumApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadWatchDogInterval = async () => {
    try {
      const response = await watchDogApi.getInterval();
      setWatchDogInterval(response.data.data);
      if (response.data.data.length > 0) {
        formik.setFieldValue('interval', response.data.data[0].type);
      }
    } catch (err) {
      if (watchDogApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadRegions = async () => {
    try {
      const response = await locationApi.getRegions();
      setRegions(response.data.data);
    } catch (err) {
      if (locationApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadDistricts = async () => {
    try {
      const response = await locationApi.getDistricts();
      setDistricts(response.data.data);
    } catch (err) {
      if (locationApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadDistrictsParts = async () => {
    try {
      const response = await locationApi.getDistrictParts();
      setDistrictsParts(response.data.data);
    } catch (err) {
      if (locationApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadMainCategories = async () => {
    try {
      const result: WebCategories[] = [{ id: '0', title: 'Všechny', count: 0 }];
      const response = await auctionCategoriesApi.getWebMainCategories();
      response.data.data.forEach((i) => {
        result[0].count += i.count;
        result.push({ id: i.id, title: i.title, count: i.count });
      });
      setMainCategories(result);
    } catch (err) {
      if (auctionCategoriesApi.isCancel(err)) {
        return;
      }
    } finally {
      setShowPreloader(false);
    }
  };

  const loadSubCategories = async (itemId: string) => {
    itemId = itemId.toString();
    if (itemId === '0') {
      setSubCategories([]);
      return;
    }
    setShowPreloader(true);
    try {
      const response = await auctionCategoriesApi.getWebSubCategories(itemId);
      if (response.data.data.length > 0) {
        setSubCategories(
          response.data.data.map((i) => ({
            id: i.id,
            title: i.title,
            count: i.count,
          }))
        );
      } else {
        const mainCategory = mainCategories.find((c) => c.id.toString() === itemId.toString());
        if (mainCategory) {
          setSubCategories([{ ...mainCategory }]);
        }
      }
    } catch (err) {
      if (auctionCategoriesApi.isCancel(err)) {
        return;
      }
    } finally {
      setShowPreloader(false);
    }
  };

  const handleSubmit = async () => {
    setShowPreloader(true);
    try {
      await watchDogApi.saveWatchDog(
        {
          title: formik.values.title,
          state: formik.values.state,
          priceFrom: formik.values.priceFrom,
          priceTo: formik.values.priceTo,
          keywords: formik.values.search,
          trackAll: formik.values.trackAll,
          watchDogInterval: formik.values.interval,
          auctionWatchDogCategories: formik.values.subCategories.map((i) => ({ auctionCategory: i })),
          auctionWatchDogRegions: formik.values.regions.map((region) => ({
            region,
            auctionWatchDogDistricts: _.get(formik.values.districts, region, []).map((district) => ({
              district,
              auctionWatchDogDistrictParts: _.get(formik.values.districtsParts, district, []).map((districtPart) => ({
                districtPart,
              })),
            })),
          })),
        },
        watchDog?.id
      );
      props.onSave();
    } catch (err) {
      if (watchDogApi.isCancel(err)) {
        return;
      }
      const errors = err?.response?.data?.errors;
      formik.setErrors(errors || {});
    } finally {
      setShowPreloader(false);
    }
  };

  const handleRegionChange = async (region: Region, isChecked: boolean) => {
    region.id = region.id.toString();

    if (isChecked) {
      const regionsValue = _.union([...formik.values.regions, region.id]);
      await formik.setFieldValue('regions', regionsValue);
      return;
    }

    const regionsValue = formik.values.regions.filter((item) => item !== region.id);
    await formik.setFieldValue('regions', regionsValue);

    let districtPartsValues = { ...formik.values.districtsParts };
    for (const districtIndex in formik.values.districts[region.id] || []) {
      districtPartsValues = _.omit(districtPartsValues, formik.values.districts[region.id][districtIndex]);
    }

    await formik.setFieldValue('districtsParts', districtPartsValues);
    await formik.setFieldValue('districts', _.omit(formik.values.districts, region.id));
  };

  const handleDistrictChange = (district: District, isChecked: boolean) => {
    district.id = district.id.toString();
    const districts = { ...formik.values.districts };

    if (isChecked) {
      if (district.region?.id) {
        _.set(districts, district.region.id.toString(), districts[district.region.id.toString()] || []);
        districts[district.region.id.toString()].push(district.id);
      }
      formik.setFieldValue('districts', districts);
      return;
    }

    formik.setFieldValue('districtsParts', _.omit(formik.values.districtsParts, district.id));

    if (district.region?.id) {
      districts[district.region.id.toString()] = districts[district.region.id.toString()].filter(
        (item) => item !== district.id
      );
      formik.setFieldValue('districts', districts);
    }
  };

  const handleDistrictPartChange = (districtPart: DistrictPart, isChecked: boolean) => {
    districtPart.id = districtPart.id.toString();
    const districtsParts = { ...formik.values.districtsParts };

    if (isChecked) {
      if (districtPart.district?.id) {
        _.set(
          districtsParts,
          districtPart.district.id.toString(),
          districtsParts[districtPart.district.id.toString()] || []
        );
        districtsParts[districtPart.district.id.toString()].push(districtPart.id);
      }
      formik.setFieldValue('districtsParts', districtsParts);
      return;
    }

    if (districtPart.district?.id) {
      districtsParts[districtPart.district.id.toString()] = districtsParts[districtPart.district.id.toString()].filter(
        (item) => item !== districtPart.id
      );
      formik.setFieldValue('districtsParts', districtsParts);
    }
  };

  const handleSubCategoryChange = (category: WebCategories, isChecked: boolean) => {
    if (isChecked) {
      const categoriesValue = _.union([...formik.values.subCategories, category.id.toString()]);
      formik.setFieldValue('subCategories', categoriesValue);
      return;
    }

    const categoriesValues = formik.values.subCategories.filter((item) => item !== category.id.toString());
    formik.setFieldValue('subCategories', categoriesValues);
  };

  const handleMainMenuChange = (index: number) => {
    const mainCategory: WebCategories | undefined = (mainCategories || [])[index];
    if (mainCategory?.id.toString() !== filterMenuActive) {
      formik.setFieldValue('subCategories', []);
      setFilterMenuActive(mainCategory?.id ? mainCategory.id.toString() : '0');
    }
  };

  const districtChecked = (item: District) => {
    for (const regionIndex in Object.keys(formik.values.districts)) {
      const regionId = Object.keys(formik.values.districts)[regionIndex];
      for (const districtIndex in formik.values.districts[regionId]) {
        if (formik.values.districts[regionId][districtIndex] === item.id.toString()) {
          return true;
        }
      }
    }
    return false;
  };

  const districtPartChecked = (item: DistrictPart) => {
    for (const districtIndex in Object.keys(formik.values.districtsParts)) {
      const districtId = Object.keys(formik.values.districtsParts)[districtIndex];
      for (const districtPartIndex in formik.values.districtsParts[districtId]) {
        if (formik.values.districtsParts[districtId][districtPartIndex] === item.id.toString()) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="component-front-main-filter">
        <div>
          <div className="filters-content">
            <button type="submit" style={{ display: 'none' }} />
            <div className="content-item">
              <h2>Nastavení hlídacího psa</h2>
              <Row className="extended-filters">
                <Col xl={4} lg={6} sm={12}>
                  <h5 className="filter-title">Název hlídacího psa</h5>
                  <div className="filter-inputs">
                    <div className="w-100p">
                      <FormGroup
                        controlOnly
                        name="title"
                        controlClassName="input-md input-search"
                        error={formik.errors.title}
                        value={formik.values.title}
                        onChange={formik.handleChange}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="extended-filters">
                <Col sm={12}>
                  <h5 className="filter-title mb-20">Jak často chci dostávat upozornění?</h5>
                  <div className="filter-inputs">
                    <Form.Group className="mb-0 input-md input-search select-input w-max-200">
                      <Select
                        name="interval"
                        options={watchDogIntervalOptions}
                        value={watchDogIntervalOptions.find((i) => i.value === formik.values.interval) || null}
                        onChange={(val) => formik.setFieldValue('interval', val?.value || '')}
                      />
                    </Form.Group>
                  </div>
                </Col>
              </Row>
              <Row className="extended-filters">
                <Col xl={4} lg={6} sm={12}>
                  <h5 className="filter-title">Stav hlídacího psa</h5>
                  <div className="filter-inputs">
                    <div className="w-100p">
                      <Form.Group className="mb-0 input-md input-search select-input w-max-200">
                        <Select
                          name="state"
                          options={watchDogStatesOptions}
                          value={watchDogStatesOptions.find((i) => i.value === formik.values.state) || null}
                          onChange={(val) => formik.setFieldValue('state', val?.value || '')}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row className="extended-filters">
                <Col sm={12}>
                  <h5 className="filter-title mb-20">Jaké dražby / aukce chcete posílat?</h5>
                  <div className="filter-inputs">
                    <Form.Group className="mb-0 input-md input-search select-input w-max-200">
                      <Select
                        name="trackAll"
                        options={watchDogTrackAllOptions}
                        value={
                          watchDogTrackAllOptions.find((i) => i.value === formik.values.trackAll.toString()) || null
                        }
                        onChange={(val) => trackAllClick(val)}
                      />
                    </Form.Group>
                  </div>
                </Col>
              </Row>
            </div>

            {formik.values.trackAll && (
              <>
                <div className="block-separator mb-20" />
                <div className="content-item">
                  <div className="mt-0 mb-3 text-center">
                    <Button type="submit" variant="btn-outline-primary" className="ml-2 mr-2">
                      {!!props.item ? `Upravit hlídacího psa` : `Vytvořit hlídacího psa`}
                    </Button>
                  </div>
                </div>
              </>
            )}

            {showPreloader && (
              <div className="preloader-content">
                <BasePreloader className="filters-preloader" size={50} strokeWidth={5} />
              </div>
            )}
          </div>
        </div>
      </div>

      {showFilters && (
        <div className="component-front-main-filter">
          <div>
            <FrontTabsMenu
              onClick={handleMainMenuChange}
              items={(mainCategories || []).map((i) => ({
                label: i.title,
                isActive: filterMenuActive === i.id.toString(),
                disabled: formik.values.subCategories.length > 0 && filterMenuActive !== i.id.toString(),
                disabledMessage:
                  'Hlídací pes je pouze pro jednu hlavní kategorii, pro další kategorii si vytvořte nového hlídacího psa.',
              }))}
            />
            <div className="filters-content">
              <div className="content-item">
                {subCategories.length > 0 && (
                  <div>
                    {subCategories.map((item: WebCategories, index: number) => (
                      <div className="filter-column" key={`filter-item-${index}`}>
                        <Form.Check
                          custom
                          type="checkbox"
                          label={
                            <>
                              {item.title}
                              {item.count !== undefined && <span>({item.count})</span>}
                            </>
                          }
                          id={`subcategory-${item.id.toString()}`}
                          name={`subcategory-${item.id.toString()}`}
                          checked={!!~formik.values.subCategories.indexOf(item.id.toString())}
                          onChange={(e) => {
                            handleSubCategoryChange(item, e.currentTarget.checked);
                          }}
                        />
                      </div>
                    ))}
                    <div className="block-separator mb-25" />
                  </div>
                )}
                <div>
                  {regions.map((item: Region, index: number) => (
                    <div className="filter-column" key={`filter-item-${index}`}>
                      <Form.Check
                        custom
                        type="checkbox"
                        label={item.name}
                        id={`region-${item.id}`}
                        name={`region-${item.id}`}
                        checked={!!~formik.values.regions.indexOf(item.id.toString())}
                        onChange={(e) => {
                          handleRegionChange(item, e.currentTarget.checked);
                        }}
                      />
                    </div>
                  ))}
                  {displayDistricts.length > 0 && <div className="block-separator mb-25" />}
                </div>
                <div>
                  {displayDistricts.map((item: District, index: number) => (
                    <div className="filter-column" key={`filter-item-${index}`}>
                      <Form.Check
                        custom
                        type="checkbox"
                        label={item.name}
                        id={`district-${item.id}`}
                        name={`district-${item.id}`}
                        checked={districtChecked(item)}
                        onChange={(e) => {
                          handleDistrictChange(item, e.currentTarget.checked);
                        }}
                      />
                    </div>
                  ))}
                  {displayDistrictsParts.length > 0 && <div className="block-separator mb-25" />}
                </div>
                <div>
                  {displayDistrictsParts.map((item: DistrictPart, index: number) => (
                    <div className="filter-column" key={`filter-item-${index}`}>
                      <Form.Check
                        custom
                        type="checkbox"
                        label={item.name}
                        id={`district-part-${item.id}`}
                        name={`district-part-${item.id}`}
                        checked={districtPartChecked(item)}
                        onChange={(e) => {
                          handleDistrictPartChange(item, e.currentTarget.checked);
                        }}
                      />
                    </div>
                  ))}
                </div>
                {regions.length > 0 && <hr className="block-separator" />}
                <div className="extended-filters-content h-max-none">
                  <Row className="extended-filters">
                    <Col xl={4}>
                      <h5 className="filter-title">Cena</h5>
                      <div className="filter-inputs price-inputs">
                        <div className="price-input">
                          <Form.Label>od</Form.Label>
                          <FormGroup
                            thousandSeparator
                            controlOnly
                            type="number"
                            name="priceFrom"
                            controlClassName="input-md"
                            value={formik.values.priceFrom}
                            onValueChange={(val) => formik.setFieldValue('priceFrom', val.value)}
                          />
                        </div>
                        <div className="price-input">
                          <Form.Label>do</Form.Label>
                          <FormGroup
                            thousandSeparator
                            controlOnly
                            type="number"
                            name="priceTo"
                            controlClassName="input-md"
                            value={formik.values.priceTo}
                            onValueChange={(val) => formik.setFieldValue('priceTo', val.value)}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="extended-filters">
                    <Col xl={4} lg={6} sm={12}>
                      <h5 className="filter-title">Hledaný výraz</h5>
                      <div className="filter-inputs">
                        <FormGroup
                          controlOnly
                          controlClassName="input-md input-search"
                          name="search"
                          value={formik.values.search}
                          onChange={formik.handleChange}
                        />
                      </div>
                    </Col>
                  </Row>
                  <hr className="block-separator" />
                </div>
              </div>
              <div className="content-item">
                <div className="mt-0 mb-3 text-center">
                  <Button type="submit" variant="btn-outline-primary" className="ml-2 mr-2">
                    {!!props.item ? `Upravit hlídacího psa` : `Vytvořit hlídacího psa`}
                  </Button>
                </div>
              </div>

              {showPreloader && (
                <div className="preloader-content">
                  <BasePreloader className="filters-preloader" size={50} strokeWidth={5} />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </form>
  );
};

export default AccountWatchdogFilter;
