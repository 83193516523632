import React from 'react';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';

import Wysiwyg from '@components/Wysiwyg/Wysiwyg';
import { BasePreloader, FormGroup, Button } from '@components';
import { InformedConsentDefaultResponse, useInformedConsentApi } from '@api/informedConsent';

interface Props {
  data?: InformedConsentDefaultResponse;
  onSave: () => void;
  onClose: () => void;
}

const InformedConsentForm: React.FC<Props> = (props) => {
  const informedConsentApi = useInformedConsentApi();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: props.data?.title || '',
      text: props.data?.text || '',
    },
    onSubmit: () => handleSubmit(),
  });

  const handleSubmit = async () => {
    if (!props.data) {
      return;
    }
    try {
      await informedConsentApi.updateDefault(props.data.id, { ...formik.values });
      props.onSave();
    } catch (err: any) {
      if (informedConsentApi.isCancel(err)) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
  };

  return (
    <div>
      <h2>Informovaný souhlas</h2>
      <div>
        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-3 mt-md-5">
          <div className="responsive-table-content">
            <div className="pt-1">
              <FormGroup
                required
                type="text"
                name="title"
                label="Název"
                labelClassName="text-left"
                value={formik.values.title}
                onChange={formik.handleChange}
                error={formik.errors.title}
              />
              <Wysiwyg
                required
                name="text"
                label="Text"
                error={formik.errors.text as string}
                value={formik.values.text}
                onChange={(val) => formik.setFieldValue('text', val)}
              />
            </div>
          </div>
          <div className="mt-5">
            <Button variant="btn-outline-default" className="float-left" type="button" onClick={() => props.onClose()}>
              Zrušit
            </Button>
            {!formik.isSubmitting ? (
              <Button
                type="submit"
                variant="btn-outline-primary"
                className="float-right"
                disabled={formik.isSubmitting}
              >
                Uložit informovaný souhlas
              </Button>
            ) : (
              <BasePreloader size={29} className="d-inline-block float-right" />
            )}
            <div className="clearfix" />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default InformedConsentForm;
