import React, { useEffect, useState } from 'react';
import { FormikErrors, FormikValues } from 'formik';

import { AuctioneerTemplate, AuctionInformedConsent } from '@types';
import TemplatesInformedConsentsTable from './TemplatesInformedConsentsTable';
import { useAuctioneersApi } from '@api/auctioneers';
import TemplatesInformedConsentForm from './TemplatesInformedConsentForm';

interface Props {
  id: string;
  values: FormikValues;
  isSubmitting: boolean;
  auctionTemplateId?: string;
  updatedItem?: AuctioneerTemplate;
  errors: FormikErrors<FormikValues>;
  getTranslation: (key: string) => string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

const TemplatesInformedConsents: React.FC<Props> = (props) => {
  const auctioneersApi = useAuctioneersApi();
  const [showForm, setShowForm] = useState(false);
  const [editIndex, setEditIndex] = useState<number | undefined>();
  const [edit, setEdit] = useState<AuctionInformedConsent | undefined>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => auctioneersApi.cancelAllRequests(), []);

  const handleEnabledChange = async (index: number, enabled: boolean) => {
    const rawData = { ...(props.updatedItem || {}) };
    rawData.data = JSON.parse(rawData.data);
    rawData.data.informedConsents = [...props.values.data.informedConsents];
    rawData.data.informedConsents[index].enabled = enabled;

    try {
      await auctioneersApi.updateTemplate(
        rawData.name || '',
        rawData.auctionType || '',
        rawData.isDefault || false,
        rawData.data,
        props.id,
        props.auctionTemplateId || ''
      );
      props.setFieldValue(`informedConsents[${index}].enabled`, enabled);
      props.setFieldValue(`data.informedConsents[${index}].enabled`, enabled);
    } catch (err: any) {
      if (auctioneersApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleEditClick = (index: number, item: AuctionInformedConsent) => {
    setEditIndex(index);
    setEdit(item);
    setShowForm(true);
  };

  const handleFormClose = () => {
    setShowForm(false);
    setEdit(undefined);
    setEditIndex(undefined);
  };

  const handleDelete = async (index: number) => {
    const rawData = { ...(props.updatedItem || {}) };
    rawData.data = JSON.parse(rawData.data);
    rawData.data.informedConsents = [...props.values.data.informedConsents];
    rawData.data.informedConsents.splice(index, 1);

    try {
      await auctioneersApi.updateTemplate(
        rawData.name || '',
        rawData.auctionType || '',
        rawData.isDefault || false,
        rawData.data,
        props.id,
        props.auctionTemplateId || ''
      );
      props.setFieldValue(`informedConsents`, rawData.data.informedConsents);
      props.setFieldValue(`data.informedConsents`, rawData.data.informedConsents);
    } catch (err: any) {
      if (auctioneersApi.isCancel(err)) {
        return;
      }
    }
  };

  return (
    <div className="mt-5">
      <TemplatesInformedConsentsTable
        title="Informovaný souhlas"
        data={props.values.data.informedConsents || []}
        onDeleteClick={handleDelete}
        onEditClick={handleEditClick}
        onChangeEnabled={handleEnabledChange}
        onCreateClick={() => setShowForm(true)}
      />
      {showForm && (
        <TemplatesInformedConsentForm
          id={props.id}
          item={edit}
          isOpen={showForm}
          itemIndex={editIndex}
          errors={props.errors}
          values={props.values}
          updatedItem={props.updatedItem}
          isSubmitting={props.isSubmitting}
          setFieldValue={props.setFieldValue}
          auctionTemplateId={props.auctionTemplateId}
          auctionType={props.values.data.auctionType}
          title={editIndex !== undefined ? 'Úprava informovaného souhlasu' : 'Přidání informovaného souhlasu'}
          onClose={handleFormClose}
        />
      )}
    </div>
  );
};

export default TemplatesInformedConsents;
