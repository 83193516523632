import * as routes from '@routes';
import { BaseFrontPage, SEO } from '@components';
import { Contact } from '@components/Contact';

export const ContactPage = () => {
  return (
    <BaseFrontPage page={routes.front.CONTACT} hideSidebar>
      <SEO title="Kontakt" />
      <Contact />
    </BaseFrontPage>
  );
};
