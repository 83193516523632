import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { FormikValues } from 'formik';
import { Form } from 'react-bootstrap';

import { useLocationApi } from '@api/location';
import { AddressType, BaseObjectType, BaseSelectType } from '@types';
import { Button, ControlFeedback, FormGroup, MapAddressModal, Select } from '@components';

type Props = {
  type: string;
  title: string;
  readOnly?: boolean;
  values: FormikValues;
  errors: BaseObjectType;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
};

export const InformationAddress: React.FC<Props> = (props) => {
  const locationApi = useLocationApi();
  const [showMapModal, setShowMapModal] = React.useState(false);
  const [districtLoading, setDistrictLoading] = useState(false);
  const [districtPartLoading, setDistrictPartLoading] = useState(false);
  const [countryLoading, setCountryLoading] = useState(false);
  const [regionLoading, setRegionLoading] = useState(false);
  const [countryOptions, setCountryOptions] = useState<BaseSelectType[]>([]);
  const [regionOptions, setRegionOptions] = useState<BaseSelectType[]>([]);
  const [districtOptions, setDistrictOptions] = useState<BaseSelectType[]>([]);
  const [districtPartOptions, setDistrictPartOptions] = useState<BaseSelectType[]>([]);

  const countryValue = _.get(props.values, `${props.type}.country`, '').toString();
  const regionValue = _.get(props.values, `${props.type}.region`, '').toString();
  const districtValue = _.get(props.values, `${props.type}.district`, '').toString();
  const districtPartValue = _.get(props.values, `${props.type}.districtPart`, '').toString();

  useEffect(() => {
    loadCountryOptions();
    return () => locationApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (countryValue) {
      loadRegionOptions(_.get(props.values, `${props.type}.country`).toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryValue]);

  useEffect(() => {
    if (regionValue) {
      loadDistrictOptions(regionValue.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionValue]);

  useEffect(() => {
    if (districtValue) {
      loadDistrictPartsOptions(districtValue);
    } else {
      setDistrictPartOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [districtValue]);

  const loadCountryOptions = async () => {
    setCountryLoading(true);
    try {
      const response = await locationApi.getCountries();
      setCountryOptions(
        response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
      if (!_.get(props.values, `${props.type}.country`) && response.data.data.length > 0) {
        props.setFieldValue(`${props.type}.country`, response.data.data[0].id);
      }
    } catch (err) {
      if (locationApi.isCancel(err)) {
        return;
      }
    } finally {
      setCountryLoading(false);
    }
  };

  const loadDistrictOptions = async (regionId: string) => {
    setDistrictLoading(true);

    try {
      const res = await locationApi.getDistricts(regionId);
      setDistrictOptions(
        res.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (err) {
      if (locationApi.isCancel(err)) {
        return;
      }
    } finally {
      setDistrictLoading(false);
    }
  };

  const loadRegionOptions = async (countryId: string) => {
    setRegionLoading(true);
    try {
      const res = await locationApi.getRegions(countryId);
      setRegionOptions(
        res.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (err) {
      if (locationApi.isCancel(err)) {
        return;
      }
    } finally {
      setRegionLoading(false);
    }
  };

  const loadDistrictPartsOptions = async (districtId: string) => {
    if (!districtId) {
      setDistrictPartOptions([]);
    }
    setDistrictPartLoading(true);
    try {
      const respose = await locationApi.getDistrictParts(districtId);
      setDistrictPartOptions(
        respose.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (err) {
      if (locationApi.isCancel(err)) {
        return;
      }
    } finally {
      setDistrictPartLoading(false);
    }
  };

  const handleSelectFromMap = (v: AddressType) => {
    props.setFieldValue(`${props.type}.gps`, v.addressGps);
    props.setFieldValue(`${props.type}.city`, v.addressCity);
    props.setFieldValue(`${props.type}.zipCode`, v.addressZipCode);
    props.setFieldValue(`${props.type}.street`, v.addressStreet);
    props.setFieldValue(`${props.type}.houseNumber`, v.addressHouseNumber);
    props.setFieldValue(`${props.type}.country`, v.addressCountry);
    props.setFieldValue(`${props.type}.region`, v.addressRegion);
    props.setFieldValue(`${props.type}.district`, v.addressDistrict);
    props.setFieldValue(`${props.type}.districtPart`, v.addressDistrictPart || '');
    props.setFieldValue(`${props.type}.ruian`, v.addressRuian);
    props.setFieldValue(`${props.type}.cadastralArea`, v.addressCadastral);
    setShowMapModal(false);
  };

  return (
    <>
      <div className="pb-4 d-flex">
        <h2 className="mr-3">{props.title}</h2>
        {!props.readOnly && (
          <Button type="button" onClick={() => setShowMapModal(true)}>
            Vybrat z mapy
          </Button>
        )}
      </div>

      <div className="responsive-table-content">
        <div className="pt-1">
          <Form.Group className="f-inline-group">
            <Form.Label className="f-inline-label text-right">Stát *</Form.Label>
            <div className="f-inline-control">
              <div className="w-max-500" data-test-id={`admin-auctioneer-form-${props.type}-select-country`}>
                <Select
                  size="md"
                  options={countryOptions}
                  readOnly={props.readOnly}
                  isLoading={countryLoading}
                  isInvalid={!!_.get(props.errors, `${props.type}_country`)}
                  value={countryOptions.find((i) => i.value.toString() === countryValue) || null}
                  onChange={(v) => props.setFieldValue(`${props.type}.country`, v?.value)}
                />
                {!!_.get(props.errors, `${props.type}_country`) && (
                  <ControlFeedback type="invalid">{_.get(props.errors, `${props.type}_country`)}</ControlFeedback>
                )}
              </div>
            </div>
          </Form.Group>

          <Form.Group className="f-inline-group">
            <Form.Label className="f-inline-label text-right">Kraj *</Form.Label>
            <div className="f-inline-control">
              <div className="w-max-500" data-test-id={`admin-auctioneer-form-${props.type}-select-region`}>
                <Select
                  size="md"
                  options={regionOptions}
                  readOnly={props.readOnly}
                  isLoading={regionLoading}
                  isInvalid={!!_.get(props.errors, `${props.type}_region`)}
                  value={regionOptions.find((i) => i.value.toString() === regionValue) || null}
                  onChange={(v) => {
                    if (v?.value !== regionValue) {
                      props.setFieldValue(`${props.type}.district`, '');
                      props.setFieldValue(`${props.type}.districtPart`, '');
                    }
                    props.setFieldValue(`${props.type}.region`, v?.value);
                  }}
                />
                {!!_.get(props.errors, `${props.type}_region`) && (
                  <ControlFeedback type="invalid">{_.get(props.errors, `${props.type}_region`)}</ControlFeedback>
                )}
              </div>
            </div>
          </Form.Group>

          <Form.Group className="f-inline-group">
            <Form.Label className="f-inline-label text-right">Okres *</Form.Label>
            <div className="f-inline-control">
              <div className="w-max-500" data-test-id={`admin-auctioneer-form-${props.type}-select-district`}>
                <Select
                  size="md"
                  readOnly={props.readOnly}
                  options={districtOptions}
                  isLoading={districtLoading}
                  isInvalid={!!_.get(props.errors, `${props.type}_district`)}
                  value={districtOptions.find((i) => i.value.toString() === districtValue) || null}
                  onChange={(v) => {
                    if (v?.value !== districtValue) {
                      props.setFieldValue(`${props.type}.districtPart`, '');
                    }
                    props.setFieldValue(`${props.type}.district`, v?.value);
                  }}
                />
                {!!_.get(props.errors, `${props.type}_district`) && (
                  <ControlFeedback type="invalid">{_.get(props.errors, `${props.type}_district`)}</ControlFeedback>
                )}
              </div>
            </div>
          </Form.Group>

          {districtPartOptions.length > 0 && (
            <Form.Group className="f-inline-group">
              <Form.Label className="f-inline-label text-right">Městská část</Form.Label>
              <div className="f-inline-control">
                <div className="w-max-500" data-test-id={`admin-auctioneer-form-${props.type}-select-district-part`}>
                  <Select
                    size="md"
                    isClearable
                    readOnly={props.readOnly}
                    options={districtPartOptions}
                    isLoading={districtPartLoading}
                    isInvalid={!!_.get(props.errors, `${props.type}_districtPart`)}
                    value={districtPartOptions.find((i) => i.value.toString() === districtPartValue) || null}
                    onChange={(v) => props.setFieldValue(`${props.type}.districtPart`, v?.value || '')}
                  />
                  {!!_.get(props.errors, `${props.type}_districtPart`) && (
                    <ControlFeedback type="invalid">
                      {_.get(props.errors, `${props.type}_districtPart`)}
                    </ControlFeedback>
                  )}
                </div>
              </div>
            </Form.Group>
          )}

          <FormGroup
            required
            type="text"
            label="Město"
            readOnly={props.readOnly}
            name={`${props.type}.city`}
            error={_.get(props.errors, `${props.type}_city`)}
            value={_.get(props.values, `${props.type}.city`) || ''}
            onChange={props.handleChange}
          />

          <FormGroup
            required
            type="text"
            label="Ulice"
            readOnly={props.readOnly}
            name={`${props.type}.street`}
            error={_.get(props.errors, `${props.type}_street`)}
            value={_.get(props.values, `${props.type}.street`) || ''}
            onChange={props.handleChange}
          />

          <FormGroup
            required
            type="text"
            label="Č.p. / Č.o."
            readOnly={props.readOnly}
            name={`${props.type}.houseNumber`}
            error={_.get(props.errors, `${props.type}_houseNumber`)}
            value={_.get(props.values, `${props.type}.houseNumber`) || ''}
            onChange={props.handleChange}
          />

          <FormGroup
            required
            type="text"
            label="PSČ"
            readOnly={props.readOnly}
            name={`${props.type}.zipCode`}
            error={_.get(props.errors, `${props.type}_zipCode`)}
            value={_.get(props.values, `${props.type}.zipCode`) || ''}
            onChange={props.handleChange}
          />

          <FormGroup
            readOnly={props.readOnly}
            labelClassName="text-right"
            label="Katastrální pracoviště"
            name={`${props.type}.cadastralArea`}
            error={_.get(props.errors, `${props.type}_cadastralArea`)}
            value={_.get(props.values, `${props.type}.cadastralArea`) || ''}
            onChange={props.handleChange}
          />

          <FormGroup
            label="RUIAN"
            type="number"
            labelClassName="text-right"
            readOnly={props.readOnly}
            name={`${props.type}.ruian`}
            error={_.get(props.errors, `${props.type}_ruian`)}
            value={_.get(props.values, `${props.type}.ruian`) || ''}
            onChange={props.handleChange}
          />

          <FormGroup
            required
            label="GPS"
            helpText={
              <div>
                GPS souřadnice ve formátu
                <br />
                [00.000000, 00.000000]
              </div>
            }
            labelClassName="text-right"
            readOnly={props.readOnly}
            name={`${props.type}.gps`}
            error={_.get(props.errors, `${props.type}_gps`)}
            value={_.get(props.values, `${props.type}.gps`) || ''}
            onChange={props.handleChange}
          />
        </div>
      </div>

      {showMapModal && (
        <MapAddressModal
          isOpen={showMapModal}
          gps={_.get(props.values, `${props.type}.gps`)}
          onSelect={handleSelectFromMap}
          onClose={() => setShowMapModal(false)}
        />
      )}
    </>
  );
};
