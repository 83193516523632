import React from 'react';
import { ValueType } from 'react-select';
import { useLocation } from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';

import * as routes from '@routes';
import { UserFilters } from '@types';
import { useUserTypes } from '@stores/users';
import { useAuctioneersApi } from '@api/auctioneers';
import { Select, AdminFiltersBox } from '@components';
import { useDomainTranslation, usePageStore } from '@stores';
import { useAllowedUserRoles, useAllowedUserStates } from '@stores/auth';
import FiltersInputs from '@components/AdminFiltersBox/components/FiltersInputs';
import FiltersSubmit from '@components/AdminFiltersBox/components/FiltersSubmit';

interface Props {
  values: UserFilters;
  onSubmit: (values: UserFilters) => void;
}

interface SelectOptionType {
  value: string;
  label: string;
}

const FiltersForm: React.FC<Props> = (props) => {
  const pageState = usePageStore();
  const auctioneersApi = useAuctioneersApi();
  const location = useLocation();
  const { userTypes } = useUserTypes();
  const { getTranslation } = useDomainTranslation('user');
  const { allowedUserRoles } = useAllowedUserRoles('user');
  const { allowedUserStates } = useAllowedUserStates();
  const [isOpen, setIsOpen] = React.useState(false);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: props.values,
    onSubmit: (values) => handleSubmit(values),
  });
  const [verificationStateOptions, setVerificationStateOptions] = React.useState<Array<SelectOptionType>>([]);

  React.useEffect(() => {
    loadVerificationState();
    setIsOpen(false);
    formik.resetForm();
    return () => auctioneersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values]);

  const loadVerificationState = async () => {
    setVerificationStateOptions([
      { value: 'verified', label: await getTranslation('verified') },
      { value: 'unverified', label: await getTranslation('unverified') },
    ]);
  };

  const handleSubmit = (values: FormikValues) => {
    const formValues = { ...values };
    Object.getOwnPropertyNames(formValues).map((prop) => {
      if (formValues[prop] === '') {
        formValues[prop] = undefined;
      }
      return prop;
    });
    formValues.page = 1;
    props.onSubmit(formValues as UserFilters);
  };

  const hasFilters = (): boolean => {
    return !!location.search;
  };

  const getRoleOptions = () => {
    return allowedUserRoles.map((i) => ({ label: i.translated, value: i.type }));
  };

  const getStateOptions = () => {
    return allowedUserStates.map((i) => ({ label: i.name, value: i.state }));
  };

  const handleTypeChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    formik.setFieldValue('type', itemValue?.value || '');
  };

  const handleRoleChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    formik.setFieldValue('role', itemValue?.value || '');
  };

  const handleStateChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    formik.setFieldValue('state', itemValue?.value || '');
  };

  const handleVerificationStateChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    formik.setFieldValue('verificationState', itemValue?.value || '');
  };

  return (
    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)}>
      <AdminFiltersBox isOpen={isOpen}>
        <FiltersInputs>
          <Row>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Druh dražitele</Form.Label>
                <Select
                  isClearable={true}
                  options={userTypes}
                  value={userTypes.find((i) => i.value === formik.values.type) || null}
                  onChange={handleTypeChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Jméno / název</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="name"
                  value={formik.values.name || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>RČ / IČO</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="ic"
                  value={formik.values.ic || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Telefon</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="phone"
                  value={formik.values.phone || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="email"
                  value={formik.values.email || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Úroveň registrace</Form.Label>
                <Select
                  isClearable={true}
                  options={getRoleOptions()}
                  value={getRoleOptions().find((i) => i.value === formik.values.role) || null}
                  onChange={handleRoleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Stav účtu</Form.Label>
                <Select
                  isClearable={true}
                  options={getStateOptions()}
                  value={getStateOptions().find((i) => i.value === formik.values.state) || null}
                  onChange={handleStateChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Stav ověření</Form.Label>
                <Select
                  isClearable={true}
                  options={verificationStateOptions}
                  value={verificationStateOptions.find((i) => i.value === formik.values.verificationState) || null}
                  onChange={handleVerificationStateChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </FiltersInputs>
        <FiltersSubmit
          hasFilters={hasFilters()}
          onOpenClick={() => setIsOpen(true)}
          clearUrl={pageState.getPagePath(routes.admin.USER_LIST)}
        />
      </AdminFiltersBox>
    </Form>
  );
};

export default FiltersForm;
