import React from 'react';
import { Table } from 'react-bootstrap';
import fileDownload from 'js-file-download';
import moment from 'moment';

import { FileIcon, Visible } from '@components';
import { useAuctioneersApi } from '@api/auctioneers';
import { Document, PermissionGroup, UserPermission } from '@types';

import colorIcoDel from '@assets/images/color-ico-del.svg';

interface Props {
  data: Array<Document>;
  auctionId: string | number;
  onDeleteClick: (item: Document) => void;
}

const DocumentsTable: React.FC<Props> = (props) => {
  const auctioneersApi = useAuctioneersApi();

  const handleFileClick = (e: React.MouseEvent<HTMLAnchorElement>, item: Document) => {
    e.preventDefault();
    auctioneersApi
      .getDocument(props.auctionId, item.id || '')
      .then((res) => {
        fileDownload(res.data, item.media.originalName);
      })
      .catch((err) => {
        if (auctioneersApi.isCancel(err)) {
          return;
        }
      });
  };

  const handleDeleteClick = (e: React.MouseEvent, item: Document) => {
    e.preventDefault();
    props.onDeleteClick(item);
  };

  return (
    <div>
      <div className="mt-4">
        <div className="responsive-table-content">
          <Table className="text-center table-middle border-bottom-0" striped>
            <thead>
              <tr>
                <th className="text-left">Název dokumentu</th>
                <th>Nahráno</th>
                <th className="w-1" />
              </tr>
            </thead>
            <tbody>
              {props.data.length < 1 && (
                <tr>
                  <td className="text-left" colSpan={100}>
                    Nebyla nalezena žádná data.
                  </td>
                </tr>
              )}
              {props.data.map((item, index) => (
                <tr key={`document-${index}`}>
                  <td className="text-left">
                    <FileIcon name={item.media.originalName} />
                    <a href="/" onClick={(e) => handleFileClick(e, item)}>
                      {item.media.originalName}
                    </a>
                  </td>
                  <td>{item.timeCreated && moment(item.timeCreated).format('DD.MM.YYYY HH:mm')}</td>
                  <td className="text-right">
                    <Visible
                      permissionGroupName={PermissionGroup.auctioneerDocuments}
                      permissionName={UserPermission.canDelete}
                    >
                      <a
                        href="/"
                        onClick={(e) => handleDeleteClick(e, item)}
                        className="d-inline-flex align-items-center"
                      >
                        <img src={colorIcoDel} alt="ico" className="mr-2" />
                        Smazat
                      </a>
                    </Visible>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default DocumentsTable;
