import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { FormikValues, useFormik } from 'formik';

import inputIcoCalendar from '@assets/images/input-ico-calendar.svg';

import { ModalProps } from '@components/Modal/Modal';
import { Modal, Button, DatePickerInput } from '@components';

interface Props extends ModalProps {
  onSave(dateFrom: Date, dateTo: Date, weeks?: string): void;
  auctionStartDate: string;
  exportTimeType: number;
}

const AuctionExportTimeframeModal: React.FC<Props> = (props) => {
  const formik = useFormik({
    initialValues: {
      weeks: '',
      dateFrom: new Date(),
      dateTo: new Date(props.auctionStartDate),
    },
    onSubmit: (values) => handleSubmit(values),
  });

  const handleSubmit = (values: FormikValues) => {
    props.onSave(values.dateFrom, values.dateTo, props.exportTimeType === 2 ? formik.values.weeks : undefined);
  };

  const handleDateFromChange = (value: Date | Date[] | null) => {
    if (value !== null) {
      const inputValue = value as Date;
      formik.setFieldValue('dateFrom', !!inputValue ? moment(inputValue).toDate() : '');
    }
  };

  const handleDateToChange = (value: Date | Date[] | null) => {
    if (value !== null) {
      const inputValue = value as Date;
      formik.setFieldValue('dateTo', !!inputValue ? moment(inputValue).toDate() : '');
    }
  };

  return (
    <Modal {...props}>
      <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)}>
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div style={{ flex: 1, marginRight: '12px' }}>
            <Form.Group className="mb-3 mr-2 input-ico">
              <Form.Label>Od</Form.Label>
              <DatePickerInput
                className="form-control form-control-sm w-135"
                calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                clearIcon={null}
                onChange={handleDateFromChange}
                value={formik.values.dateFrom ? moment(formik.values.dateFrom || '').toDate() : undefined}
                minDate={new Date()}
                maxDate={new Date(props.auctionStartDate) > new Date() ? new Date(props.auctionStartDate) : new Date()}
                readOnly={new Date() > new Date(props.auctionStartDate)}
              />
            </Form.Group>
          </div>
          <div style={{ flex: 1, marginLeft: '12px' }}>
            {props.exportTimeType === 2 ? (
              <Form.Group className="mb-3 mr-2 input-ico">
                <Form.Label>Počet týdnů</Form.Label>
                <Form.Control
                  type="text"
                  name="weeks"
                  style={{ height: 35 }}
                  value={formik.values.weeks}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            ) : (
              <Form.Group className="mb-3 mr-2 input-ico">
                <Form.Label>Do</Form.Label>
                <DatePickerInput
                  className="form-control form-control-sm w-135"
                  calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                  clearIcon={null}
                  onChange={handleDateToChange}
                  value={formik.values.dateTo ? moment(formik.values.dateTo || '').toDate() : undefined}
                  minDate={new Date()}
                  maxDate={new Date(props.auctionStartDate)}
                  readOnly={new Date() > new Date(props.auctionStartDate)}
                />
              </Form.Group>
            )}
          </div>
        </div>
        <div className="mt-24">
          <Button variant="btn-outline-primary" type="submit" className="mr-24">
            Exportovat
          </Button>
          <Link
            to="#"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();

              if (props.onRequestClose) {
                props.onRequestClose(event);
              }
            }}
          >
            Zrušit
          </Link>
        </div>
      </Form>
    </Modal>
  );
};

export default AuctionExportTimeframeModal;
