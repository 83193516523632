import * as React from 'react';

import { useMediaApi } from '@api/media';
import { fileToBase64 } from '@helpers/files';
import { useAuctionsApi } from '@api/auctions';
import { useInformedConsentApi } from '@api/informedConsent';
import { useAuctionInformationApi } from '@api/auctionInformation';
import {
  AuctionDocumentResponse,
  AuctionImageResponse,
  AuctionInformationDocumentResponse,
  AuctionInformationResponse,
  AuctionInformedConsent,
} from '@types';

export const useCopyAttachments = () => {
  const auctionsApi = useAuctionsApi();
  const mediaApi = useMediaApi();
  const informedConsentApi = useInformedConsentApi(false, false);
  const auctionInformationApi = useAuctionInformationApi(false, false);

  const [isCopying, setIsCopying] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [uploadStatus, setUploadStatus] = React.useState('');

  const loadDocuments = async (auctionId: string | number): Promise<Array<AuctionDocumentResponse>> => {
    const { data } = await auctionsApi.getDocuments(auctionId);
    return data;
  };

  const loadImages = async (auctionId: string | number): Promise<Array<AuctionImageResponse>> => {
    const { data } = await auctionsApi.getImages(auctionId);
    return data;
  };

  const uploadDocument = async (auctionId: string | number, document: AuctionDocumentResponse) => {
    const { data: documentData } = await mediaApi.detail(document.media.hash, true);
    const documentDataBase64 = await fileToBase64(documentData);
    const data = {
      type: 'file',
      data: documentDataBase64,
      mime: document?.media?.mimeType,
      original_name: document?.media?.originalName,
    };
    if (document.public) {
      await auctionsApi.createDocument(auctionId, document.documentType, data);
    } else {
      await auctionsApi.createDocumentPrivate(auctionId, data);
    }
  };

  const uploadImage = async (auctionId: string | number, image: AuctionImageResponse) => {
    if (image.media.type !== 'embed') {
      const { data: imageData } = await mediaApi.detail(image.media.hash, true);
      const imageDataBase64 = await fileToBase64(imageData);
      await auctionsApi.createImage(auctionId, {
        type: 'image',
        data: imageDataBase64,
        mime: image?.media?.mimeType,
        image_title: image?.title,
        original_name: image?.media?.originalName,
      });
    } else {
      if (!image.media.embedHash) {
        return;
      }
      await auctionsApi.createVideo(auctionId, {
        mime: image.media.mimeType,
        data: image.media.embedHash,
      });
    }
  };

  const loadInformedConsents = async (auctionId: string | number) => {
    try {
      return (await informedConsentApi.getInformedConsents(auctionId)).data.data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const uploadInformedConsent = async (
    sourceId: string | number,
    destinationId: string | number,
    item: AuctionInformedConsent
  ) => {
    try {
      await informedConsentApi.create(destinationId, {
        title: item.title,
        text: item.text,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const loadInformation = async (auctionId: string | number): Promise<AuctionInformationResponse[]> => {
    try {
      return (await auctionInformationApi.getAdminList(auctionId)).data.data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const loadInformationDocuments = async (
    auctionId: string | number,
    informationId: string | number
  ): Promise<AuctionInformationDocumentResponse[]> => {
    try {
      return (await auctionInformationApi.getAdminDocuments(auctionId, informationId)).data.data;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const uploadInformationDocument = async (
    destinationId: string | number,
    informationId: string | number,
    document: AuctionInformationDocumentResponse
  ) => {
    const { data: imageData } = await mediaApi.detail(document.media.hash, true);
    const documentBase64 = await fileToBase64(imageData);
    await auctionInformationApi.createDocument(destinationId, informationId, {
      type: 'file',
      data: documentBase64,
      mime: document.media.mimeType,
      original_name: document.media.originalName,
      title: document.media.title,
    });
  };

  const uploadInformation = async (
    sourceId: string | number,
    destinationId: string | number,
    information: AuctionInformationResponse
  ) => {
    try {
      const response = await auctionInformationApi.adminCreate(destinationId, {
        title: information.title,
        content: information.content,
        position: information.position?.toString(),
        showTimeType: information.showTimeType,
        date: information.date,
        category: information.category,
        categoryKey: information.category === 'c' ? information.categoryKey : undefined,
      });
      const documents = await loadInformationDocuments(sourceId, information.id);
      for (const index in documents) {
        await uploadInformationDocument(destinationId, response.data.data?.id || '', documents[index]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const copyAttachments = async (sourceAuctionId: string | number, destinationAuctionId: string | number) => {
    let documents: AuctionDocumentResponse[];
    let images: AuctionImageResponse[];
    let information: AuctionInformationResponse[];
    let informedConsents: AuctionInformedConsent[];
    setIsCopying(true);

    setIsLoading(true);
    try {
      documents = (await loadDocuments(sourceAuctionId)).filter((d) => !d.documentType.endsWith('_notice'));
      images = await loadImages(sourceAuctionId);
      information = await loadInformation(sourceAuctionId);
      informedConsents = await loadInformedConsents(sourceAuctionId);
    } finally {
      setIsLoading(false);
    }

    for (const index in documents) {
      setUploadStatus(`Kopíruji dokument ${Number(index) + 1}/${documents.length}`);
      await uploadDocument(destinationAuctionId, documents[index]);
    }

    for (const index in images) {
      if (images[index].media.type !== 'embed') {
        setUploadStatus(`Kopíruji obrázek ${Number(index) + 1}/${images.length}`);
      } else {
        setUploadStatus(`Kopíruji video ${Number(index) + 1}/${images.length}`);
      }
      await uploadImage(destinationAuctionId, images[index]);
    }

    for (const index in information) {
      setUploadStatus(`Kopíruji informace ${Number(index) + 1}/${information.length}`);
      await uploadInformation(sourceAuctionId, destinationAuctionId, information[index]);
    }

    for (const index in informedConsents) {
      setUploadStatus(`Kopíruji informovaný souhlas ${Number(index) + 1}/${informedConsents.length}`);
      await uploadInformedConsent(sourceAuctionId, destinationAuctionId, informedConsents[index]);
    }

    setIsCopying(false);
  };

  return {
    copyAttachments,
    isCopying,
    get copyStatus(): string {
      if (isLoading) {
        return 'Načítám data';
      }

      return uploadStatus;
    },
  };
};
