import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import { NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import * as routes from '@routes';
import { useUsersApi } from '@api/users';
import { PermissionGroup, User, UserPermission } from '@types';
import { urlSearchStore, usePageStore, useUrlSearchStore } from '@stores';
import { BasePage, Pagination, BasePreloader, AdminBoxContent, Visible, Button } from '@components';

import ItemsTable from './components/ItemsTable';
import FiltersForm from './components/FiltersForm';

import plusIco from '@assets/images/plus-ico.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';

const UserListPage: React.FC = () => {
  const usersApi = useUsersApi();
  const pageState = usePageStore();
  const location = useLocation();
  const history = useHistory();
  const urlSearchState = useUrlSearchStore();
  const [loaded, setLoaded] = React.useState(false);
  const [data, setData] = React.useState<Array<User>>([]);
  const [dataLen, setDataLen] = React.useState(0);
  const [query, setQuery] = useQueryParams({
    page: withDefault(NumberParam, 1),
    perPage: withDefault(NumberParam, 15),
    type: withDefault(StringParam, undefined),
    name: withDefault(StringParam, undefined),
    ic: withDefault(StringParam, undefined),
    phone: withDefault(StringParam, undefined),
    email: withDefault(StringParam, undefined),
    role: withDefault(StringParam, undefined),
    state: withDefault(StringParam, undefined),
    verificationState: withDefault(StringParam, undefined),
  });
  const page = pageState.getPage(routes.admin.USER_LIST);

  React.useEffect(() => {
    if (urlSearchState.adminUsersList) {
      history.push(`${pageState.getPagePath(routes.admin.USER_LIST)}${urlSearchState.adminUsersList}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    loadUsers();
    urlSearchStore.setState({ adminUsersList: location.search });
    return () => usersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search, query]);

  const loadUsers = async () => {
    try {
      const response = await usersApi.getUsers(query);
      setData(response.data.data);
      setDataLen(response.data.total as number);
      setLoaded(true);
    } catch (err: any) {
      if (!err.response) {
        return;
      }
      setLoaded(true);
    }
  };

  return (
    <BasePage page={routes.admin.USER_LIST} title={page?.name}>
      <FiltersForm values={query} onSubmit={(values) => setQuery(values, 'push')} />
      <AdminBoxContent>
        <Row className="align-items-center">
          <Col xs={12} lg={6}>
            <Visible permissionGroupName={PermissionGroup.buyers} permissionName={UserPermission.canCreate}>
              <Button to={pageState.getPagePath(routes.admin.USER_CREATE)} dataTestId="admin-buyers-create-btn">
                <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
                <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
                Přidat dražitele
              </Button>
            </Visible>
          </Col>
          <Col xs={12} lg={6} className="text-left text-lg-right mt-4 mt-lg-0">
            {loaded && (
              <p className="m-0">
                Celkem <strong>{dataLen}</strong> uživatelů
              </p>
            )}
          </Col>
        </Row>
        <Row className="mt-3 pt-3">
          <Col xs={12}>
            {loaded ? (
              <div>
                <div>
                  <ItemsTable data={data} />
                </div>
                {data.length > 0 && (
                  <div className="mt-2">
                    <Pagination
                      page={query.page}
                      perPage={query.perPage}
                      totalRows={dataLen}
                      onPageChange={(page) => setQuery({ ...query, page: page }, 'push')}
                    />
                  </div>
                )}
              </div>
            ) : (
              <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
                <BasePreloader />
              </div>
            )}
          </Col>
        </Row>
      </AdminBoxContent>
    </BasePage>
  );
};

export default UserListPage;
