import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import plusIco from '@assets/images/plus-ico.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';
import { TextBlockType } from '@types';
import { usePageStore } from '@stores';
import * as routes from '@routes';
import { BasePreloader, Button } from '@components';
import { closeMessage, confirmMessage } from '@helpers/messages';
import { useTextBlockApi } from '@api/textBlock';
import TextBlocksTable from './TextBlocksTable';

const TextBlocks: React.FC = () => {
  const textBlockApi = useTextBlockApi();
  const pageState = usePageStore();
  const location = useLocation();
  const [loaded, setLoaded] = React.useState(false);
  const [data, setData] = React.useState<Array<TextBlockType>>([]);
  const [dataLen, setDataLen] = React.useState(0);

  React.useEffect(() => {
    loadData();
    return () => textBlockApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const loadData = async () => {
    try {
      const response = await textBlockApi.listTextBlock();
      setData(response.data.data);
      setDataLen(response.data.total as number);
      setLoaded(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
      setLoaded(true);
    }
  };

  const handleDeleteClick = (item: TextBlockType) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat textový blok?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return textBlockApi.deleteTextBlock(item.id).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadData();
      }
    });
  };

  return (
    <div>
      <div>
        <h2>Textové bloky</h2>
      </div>
      <Row className="align-items-center">
        <Col xs={12} lg={6}>
          <Button to={pageState.getPagePath(routes.admin.SETTINGS_TEXT_BLOCK_CREATE)} className="mt-3">
            <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
            <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
            Přidat textový blok
          </Button>
        </Col>
        <Col xs={12} lg={6} className="text-left text-lg-right mt-4 mt-lg-0">
          {loaded && (
            <p className="m-0">
              Celkem <strong>{dataLen}</strong> textových bloků
            </p>
          )}
        </Col>
      </Row>
      <Row className="mt-3 pt-3">
        <Col xs={12}>
          {loaded ? (
            <div>
              <div>
                <TextBlocksTable data={data} onDeleteClick={handleDeleteClick} />
              </div>
            </div>
          ) : (
            <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
              <BasePreloader />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default TextBlocks;
