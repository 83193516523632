import React from 'react';
import fileDownload from 'js-file-download';

import { useMediaApi } from '@api/media';
import { AuctionInformationDocumentResponse } from '@types';

import colorIcoPdf from '@assets/images/color-ico-pdf.svg';
import colorIcoDoc from '@assets/images/color-ico-doc.svg';
import colorIcoDocument from '@assets/images/color-ico-document.svg';

interface Props {
  documents: AuctionInformationDocumentResponse[];
}

const Documents: React.FC<Props> = ({ documents }) => {
  const mediaApi = useMediaApi();

  const handleFileDownload = (e: React.MouseEvent<HTMLButtonElement>, item: AuctionInformationDocumentResponse) => {
    e.preventDefault();
    mediaApi
      .detail(item.media.hash, true)
      .then((res) => {
        fileDownload(res.data, item.media.originalName);
      })
      .catch((err) => {
        if (mediaApi.isCancel(err)) {
          return;
        }
      });
  };

  const getDocumentIco = (mimeType: string) => {
    let src = colorIcoDocument;
    if (mimeType === 'application/pdf') {
      src = colorIcoPdf;
    }
    if (mimeType === 'application/msword') {
      src = colorIcoDoc;
    }
    return <img className="file-ico" alt={mimeType} src={src} />;
  };

  if (documents.length < 1) {
    return null;
  }

  return (
    <table className="auction-document-table border-top">
      <tbody>
        {documents.map((document) => (
          <tr key={document.id}>
            <td>
              <div className="auction-document__name">
                {getDocumentIco(document.media.mimeType)}
                {document.media.title || document.media.originalName}
              </div>
            </td>
            <td>
              <button onClick={(e) => handleFileDownload(e, document)} className="btn-download f-weight-700">
                stáhnout dokument
              </button>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Documents;
