import React from 'react';

import { Button } from '@components';

import stepFinishedIco from '@assets/images/registration-finished.svg';

interface Props {
  isBuyer: boolean;
}

const StepFinished: React.FC<Props> = (props) => {
  return (
    <div className="step-finished">
      <div className="step-finished-image">
        <img src={stepFinishedIco} alt="ico" />
      </div>
      <p className="step-finished-content" data-test-id="user-registration-finished-content">
        <div className="title">Děkujeme za registraci!</div>
        {props.isBuyer && `Zbývá poslední krok k účasti k dražbě. Prokázání Vaší totožnosti provedete ve Vašem účtu. `}
        Na Váš e-mail bude doručena výzva k potvrzení registrace s odkazem, kterým účet aktivujete. V případě, že email
        nepřijde do 15 minut, zkontrolujte složku SPAM.
        <br />
        <br />
        S pozdravem
        <br />
        <strong>team exdrazby.cz</strong>
        <div style={{ marginTop: '16px' }}>
          <Button to="/" variant="btn-outline-primary" className="mt-3">
            Přejít na hlavní stránku
          </Button>
        </div>
      </p>
    </div>
  );
};

export default StepFinished;
