import { FormGroup, Select } from '@components';
import { AuctioneerFilters, Region } from '@types';

import { Col, Form, Row } from 'react-bootstrap';
import icoColorSearch from '@assets/images/front/auctioneers/ico-color-search.svg';
import { useEffect, useState } from 'react';
import { FormikValues, useFormik } from 'formik';
import { ValueType } from 'react-select';
import { useLocationApi } from '@api/location';

type FrontAuctioneerFilterProps = {
  values: AuctioneerFilters;
  onSubmit: (values: AuctioneerFilters) => void;
};

interface TypeSelectOptionType {
  value: string;
  label: string;
}

export const FrontAuctioneerFilter = (props: FrontAuctioneerFilterProps) => {
  const locationApi = useLocationApi();
  const [regions, setRegions] = useState([] as Array<Region>);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: props.values,
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    loadRegion();
    formik.resetForm();
    return () => locationApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values]);

  const loadRegion = async () => {
    try {
      const response = await locationApi.getRegions();
      setRegions(response.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const handleSubmit = (values: FormikValues) => {
    const formValues = { ...values };
    Object.getOwnPropertyNames(formValues).map((prop) => {
      if (formValues[prop] === '') {
        formValues[prop] = undefined;
      }
      return prop;
    });
    formValues.page = 1;
    formValues.regions = formValues.regions || undefined;
    props.onSubmit(formValues as AuctioneerFilters);
  };

  const getRegionOptions = () => {
    return regions.map((i) => ({ label: i.name, value: `${i.id}` }));
  };

  const handleRegionChange = (value: ValueType<TypeSelectOptionType, boolean>) => {
    const itemValue = value as TypeSelectOptionType;
    formik.setFieldValue('regions', itemValue?.value || '');
  };
  return (
    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)}>
      <div className="front-auctioneer-filter">
        <Row className="extended-filters">
          <Col md={4}>
            <div className="filter-input">
              <Form.Label>Kraj</Form.Label>
              <Form.Group className="input-search select-input input-md">
                <Select
                  isClearable={true}
                  options={getRegionOptions()}
                  value={getRegionOptions().find((i) => i.value === formik.values.regions) || null}
                  onChange={handleRegionChange}
                />
              </Form.Group>
            </div>
          </Col>
          <Col md={8}>
            <div className="filter-input">
              <Form.Label>Vyhledat na stránce</Form.Label>
              <FormGroup
                controlOnly
                controlClassName="input-md input-search"
                name="search"
                placeholder="Zadejte jméno, tel, e-mail..."
                onChange={formik.handleChange}
                value={formik.values.search}
              />
              <button type="submit" className="ml-10 search-button">
                <img src={icoColorSearch} alt="ico" />
              </button>
            </div>
          </Col>
        </Row>
      </div>
    </Form>
  );
};
