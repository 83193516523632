import React from 'react';
import classNames from 'classnames';

interface Props {
  className?: string;
}

const AdminBoxContent: React.FC<Props> = (props) => (
  <div className={classNames(['container-fluid box-content component-admin-box-content', props.className])}>
    {props.children}
  </div>
);

export default AdminBoxContent;
