import React, { useEffect, useState } from 'react';

import { useInvoiceApi } from '@api/invoice';
import { BasePreloader, HistoryTable } from '@components';
import { BaseObjectType, InvoiceResponse } from '@types';

type Props = {
  id: string;
  translations: BaseObjectType<string>;
};

export const HistorySection: React.FC<Props> = (props) => {
  const invoiceApi = useInvoiceApi();
  const [invoice, setInvoice] = useState<InvoiceResponse | null>(null);
  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    if (props.id) {
      loadDetail(props.id);
    }
    return () => invoiceApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const loadDetail = (id: string) => {
    setLoaded(false);
    invoiceApi
      .detailInvoice(id)
      .then((res) => {
        setInvoice(res.data.data);
        setLoaded(true);
      })
      .catch((err) => {
        if (invoiceApi.isCancel(err)) {
          return;
        }
      });
  };

  return (
    <div className="component-admin-box-content overlow-x-auto">
      {loaded && invoice ? (
        <div>
          <HistoryTable
            showUser
            id={props.id}
            api={invoiceApi.getHistory}
            dateCreated={invoice.timeCreated}
            author={{
              id: 0,
              email: props.translations.system_user || 'system_user',
            }}
          />
        </div>
      ) : (
        <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
          <BasePreloader />
        </div>
      )}
    </div>
  );
};
