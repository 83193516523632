import React from 'react';
import { Col, Row } from 'react-bootstrap';

import { AuctionResponse } from '@types';
import AuctionProgress from './AuctionProgress';
import AuctionQuestionForm from './AuctionQuestionForm';

interface Props {
  auction?: AuctionResponse;
  securityPaid?: boolean;
  authorizationState?: string;
  auctionSecurity?: string;
  auctionSecurityTranslation?: string;
  admitted?: boolean;
}

const AuctionBottomBox: React.FC<Props> = (props) => (
  <Row>
    <Col md={6} className="mb-4">
      <div className="box-item">
        <div className="box-header">
          {props.auction?.translations?.participation_conditions || 'participation_conditions'}
        </div>
        <div className="box-content item-conditions">
          <p className="item-text">
            {props.auction?.translations?.participation_conditions_info || 'participation_conditions_info'}
          </p>
          <AuctionProgress
            auction={props.auction}
            admitted={props.admitted}
            securityPaid={props.securityPaid}
            authorizationState={props.authorizationState}
            auctionSecurity={props.auctionSecurity}
            auctionSecurityTranslation={props.auctionSecurityTranslation}
          />
        </div>
      </div>
    </Col>
    <Col md={6} className="mb-4">
      <div className="box-item">
        <div className="box-header">Máte dotaz?</div>
        <div className="box-content">
          <AuctionQuestionForm auctionId={props.auction?.id || ''} />
        </div>
      </div>
    </Col>
  </Row>
);

export default AuctionBottomBox;
