import _ from 'lodash';

import { API, APIResponse, useApi } from '@api/api';
import {
  AuctionInformationDocumentCreate,
  AuctionInformationDocumentResponse,
  AuctionInformationRequest,
  AuctionInformationResponse,
  BaseObjectType,
  WebInformationResponse,
} from '@types';
import { AxiosResponse } from 'axios';

export const timeTypesPath = () => `/enum/auction-information/show-time-types`;
export const getListPath = (auctionId: string | number) => `/web/${auctionId}/auction-information`;
export const adminListPath = (auctionId: string | number) => `/admin/auction/${auctionId}/auction-information`;
export const adminDetailPath = (auctionId: string | number, itemId: string | number) =>
  `/admin/auction/${auctionId}/auction-information/${itemId}`;
export const adminDocumentsPath = (auctionId: string | number, itemId: string | number) =>
  `/admin/auction/${auctionId}/auction-information/${itemId}/document`;
export const adminDocumentsListPath = (auctionId: string | number, itemId: string | number) =>
  `/admin/auction/${auctionId}/auction-information/${itemId}/documents`;
export const adminDocumentPath = (auctionId: string | number, itemId: string | number, documentId: string | number) =>
  `/admin/auction/${auctionId}/auction-information/${itemId}/document/${documentId}`;

export interface AuctionInformationAPI extends API {
  getTimeTypes(auctionType?: string): APIResponse<BaseObjectType<string>[]>;
  getList(auctionId: string | number): APIResponse<WebInformationResponse[]>;
  adminCreate(auctionId: string | number, data: AuctionInformationRequest): APIResponse<AuctionInformationResponse>;
  adminUpdate(auctionId: string | number, itemId: string | number, data: AuctionInformationRequest): APIResponse;
  getAdminDocument(
    auctionId: string | number,
    auctionInformationId: string | number,
    itemId: string | number
  ): Promise<AxiosResponse<any>>;
  getAdminDocuments(
    auctionId: string | number,
    itemId: string | number
  ): APIResponse<AuctionInformationDocumentResponse[]>;
  createDocument(
    auctionId: string | number,
    itemId: string | number,
    data: AuctionInformationDocumentCreate
  ): APIResponse;
  deleteDocument(auctionId: string | number, itemId: string | number, documentId: string | number): APIResponse;
  deleteInformation(auctionId: string | number, itemId: string | number): APIResponse;
  getAdminList(auctionId: string | number): APIResponse<AuctionInformationResponse[]>;
}

export const useAuctionInformationApi = (
  errorToastDisable?: boolean,
  successToastDisabled?: boolean
): AuctionInformationAPI => {
  const api = useApi(errorToastDisable, successToastDisabled);

  return {
    ...api,
    getTimeTypes(auctionType?: string) {
      const cancelToken = api.prepareCancelToken('getTimeTypes');
      return api.request.get(timeTypesPath(), { cancelToken, params: { auctionType } });
    },
    getList(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('getList');
      return api.request.get(getListPath(auctionId), { cancelToken });
    },
    adminCreate(auctionId: string | number, data: AuctionInformationRequest) {
      let requestData = { ...data };
      if (requestData.showTimeType !== 'specific_time') {
        requestData = _.omit(requestData, ['date']);
      }
      if (requestData.category === 'b') {
        requestData = _.omit(requestData, ['date', 'showTimeType']);
      }
      if (requestData.category === 'c') {
        requestData = _.omit(requestData, ['date', 'position', 'showTimeType']);
      }
      const cancelToken = api.prepareCancelToken('adminCreate');
      return api.authRequest.post(adminListPath(auctionId), requestData, { cancelToken });
    },
    adminUpdate(auctionId: string | number, itemId: string | number, data: AuctionInformationRequest) {
      let requestData = { ...data };
      if (requestData.showTimeType !== 'specific_time') {
        requestData = _.omit(requestData, ['date']);
      }
      if (requestData.category === 'b') {
        requestData = _.omit(requestData, ['date', 'showTimeType']);
      }
      if (requestData.category === 'c') {
        requestData = _.omit(requestData, ['date', 'position', 'showTimeType']);
      }
      const cancelToken = api.prepareCancelToken('adminUpdate');
      return api.authRequest.put(adminDetailPath(auctionId, itemId), requestData, { cancelToken });
    },
    getAdminDocument(auctionId: string | number, auctionInformationId: string | number, documentId: string | number) {
      return api.authRequest.get(adminDocumentPath(auctionId, auctionInformationId, documentId), {
        params: { view: true },
        responseType: 'blob',
      });
    },
    getAdminDocuments(auctionId: string | number, itemId: string | number) {
      const cancelToken = api.prepareCancelToken('getAdminDocuments');
      return api.authRequest.get(adminDocumentsListPath(auctionId, itemId), { cancelToken });
    },
    createDocument(auctionId: string | number, itemId: string | number, data: AuctionInformationDocumentCreate) {
      const cancelToken = api.prepareCancelToken('createDocument');
      return api.authRequest.post(adminDocumentsPath(auctionId, itemId), data, { cancelToken });
    },
    deleteDocument(auctionId: string | number, itemId: string | number, documentId: string | number) {
      const cancelToken = api.prepareCancelToken('deleteDocument');
      return api.authRequest.delete(adminDocumentPath(auctionId, itemId, documentId), {
        cancelToken,
      });
    },
    deleteInformation(auctionId: string | number, itemId: string | number) {
      const cancelToken = api.prepareCancelToken('deleteInformation');
      return api.authRequest.delete(adminDetailPath(auctionId, itemId), { cancelToken });
    },
    getAdminList(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('getAdminList');
      return api.authRequest.get(adminListPath(auctionId), {
        cancelToken,
        params: {
          sort: JSON.stringify({ property: 'auctionInformation.position', direction: 'ASC' }),
          filter: JSON.stringify([
            [
              {
                operator: 'eq',
                value: 'active',
                property: 'auctionInformation.state',
              },
              {
                operator: 'eq',
                value: 'deactive',
                property: 'auctionInformation.state',
              },
            ],
          ]),
        },
      });
    },
  };
};
