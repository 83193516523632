import React from 'react';

import { BaseAccountPage } from '@components';

const Account404Page: React.FC = () => {
  return (
    <BaseAccountPage>
      <div className="account-page-title">Stránka nenalezena</div>
      <div className="w-min-500" style={{ background: 'white' }}>
        <div className="component-admin-box-content overlow-x-auto">Omlouváme se, tuto sekci připravujeme.</div>
      </div>
    </BaseAccountPage>
  );
};

export default Account404Page;
