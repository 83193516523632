import { Link } from 'react-router-dom';

import { Button } from '@components';
import { PageType } from '@types';
import { usePageStore } from '@stores';

type HintSubPageMenuProps = {
  page: PageType;
  subPages: PageType[];
};

export const HintSubPageMenu = (props: HintSubPageMenuProps) => {
  const { page, subPages } = props;
  const pageState = usePageStore();
  const backUrl = pageState.pages.find((item) => item.id === page.parentPageId)?.fullUrl;
  return (
    <div className="component-front-hint">
      <h1>{page?.name || ''}</h1>
      <div className="main-wrapper">
        {subPages.map((item) => (
          <div className="main-item-wrapper">
            <Link to={item.fullUrl} style={{ textDecoration: 'none' }}>
              <div className="item">
                <div>
                  <h5 className="mt-0">{item.name}</h5>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>

      <div className="action-holder">
        <Button type="button" variant="btn-outline-primary" to={backUrl}>
          Vrátit se zpět
        </Button>
      </div>
    </div>
  );
};
