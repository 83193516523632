import { createElement, Fragment, useEffect } from 'react';
import { isEmpty, merge } from 'lodash';

import { useTranslatorApi } from '@api/translator';
import { createStore, useStoreState } from './store';

interface TranslatorState {
  text: {
    [text: string]: string;
  };
  domain: {
    [domain: string]: {
      [text: string]: string;
    };
  };
}

export const translatorStore = createStore<TranslatorState>({
  text: {},
  domain: {},
});

export const useTranslatorStore = () => {
  const translatorState = useStoreState(translatorStore);

  return {
    ...translatorState,
    getDomainTranslation: (domain: string, text: string) => {
      if (isEmpty(translatorState.domain[domain])) {
        return undefined;
      }

      return translatorState.domain[domain][text];
    },
  };
};

export const useDomainTranslation = (domain: string) => {
  const translatorApi = useTranslatorApi();
  const { getDomainTranslation } = useTranslatorStore();

  const translationQueue: Array<string> = [];

  const loadTranslation = async (text: string): Promise<string> => {
    try {
      const translation = await translatorApi.domainTranslate(domain, text);
      translationQueue.splice(translationQueue.indexOf(text), 1);
      translatorStore.setState((state) =>
        merge({}, state, { domain: { [domain]: { [text]: translation.data.data.cs } } })
      );
      return translation.data.data.cs;
    } catch (err) {
      return text;
    }
  };

  const queueTranslation = (text: string) => {
    if (!~translationQueue.indexOf(text)) {
      translationQueue.push(text);
      loadTranslation(text);
    }
  };

  const TranslationRenderer = ({ text }: { text: string }) => {
    const translatedText = getDomainTranslation(domain, text);

    useEffect(() => {
      if (typeof translatedText !== 'string' && typeof text === 'string') {
        queueTranslation(text);
      }
      // eslint-disable-next-line
    }, [text]);

    return createElement(Fragment, {}, translatedText || text);
  };

  const getTranslation = async (text: string): Promise<string> => {
    const translatedText = getDomainTranslation(domain, text);
    if (translatedText !== undefined) {
      return translatedText;
    } else {
      return await loadTranslation(text);
    }
  };

  return {
    getTranslation,
    renderTranslation: (text: string) => createElement(TranslationRenderer, { text }),
  };
};
