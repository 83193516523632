import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';

import { useAuctionsApi } from '@api/auctions';
import { AuctionResponse, BaseObjectType } from '@types';
import { getRequestDateFormat, getRequestDateTimeFormat } from '@helpers/datetime';
import { BasePreloader, Button, ControlFeedback, DatePickerInput, FormGroup, Modal } from '@components';

import inputIcoCalendar from '@assets/images/input-ico-calendar.svg';

type Props = {
  isOpen: boolean;
  auction: AuctionResponse;
  translations: BaseObjectType;
  onClose: (reload: boolean) => void;
};

export const ProtestExtendModal: React.FC<Props> = (props) => {
  const auctionsApi = useAuctionsApi();
  const [showLoaded, setShowLoader] = useState(false);
  const formik = useFormik({
    validateOnChange: false,
    onSubmit: () => handleSubmit(),
    initialValues: {
      endDt: '',
      endDtHour: 0,
      endDtMinutes: 0,
    },
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => auctionsApi.cancelAllRequests(), []);

  const getTranslation = (key: string) => _.get(props.translations, key, key);

  const getEndDt = () => {
    let endDtHour = formik.values.endDtHour.toString();
    if (parseInt(endDtHour) < 10) {
      endDtHour = `0${formik.values.endDtHour}`;
    }
    let endDtMinutes = formik.values.endDtMinutes.toString();
    if (parseInt(endDtMinutes) < 10) {
      endDtMinutes = `0${formik.values.endDtMinutes}`;
    }
    return !!formik.values.endDt ? getRequestDateTimeFormat(`${formik.values.endDt} ${endDtHour}:${endDtMinutes}`) : '';
  };

  const handleSubmit = async () => {
    if (showLoaded) {
      return;
    }
    setShowLoader(true);
    try {
      await auctionsApi.auctionExtend(props.auction.id, getEndDt());
      props.onClose(true);
    } catch (err: any) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      setShowLoader(false);
    }
  };

  const handleDateChange = (value: Date | Date[] | null) => {
    if (value !== null) {
      const inputValue = value as Date;
      formik.setFieldValue('endDt', !!inputValue ? getRequestDateFormat(inputValue) : '');
    }
  };

  const handleMaxNumberChange = (e: React.ChangeEvent<HTMLInputElement>, max: number) => {
    if (!!e.target.value && parseInt(e.target.value) > max) {
      e.target.value = max.toString();
    }
    formik.handleChange(e);
  };

  const handleTimeFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    const fieldName = event.target.name;
    if (String(_.get(formik.values, fieldName)) === '0') {
      formik.setFieldValue(fieldName, '');
    }
  };

  const handleTimeBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    const fieldName = event.target.name;
    if (/^\s*$/.test(_.get(formik.values, fieldName))) {
      formik.setFieldValue(fieldName, '0');
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      className="w-max-500"
      title={getTranslation('protest_extend_modal_title')}
      onRequestClose={() => props.onClose(false)}
    >
      <form onSubmit={formik.handleSubmit}>
        <div>
          <div>
            <Form.Group>
              <Form.Label>{getTranslation('protest_extend_modal_end_dt_label')}</Form.Label>
              <div className="d-flex align-items-center datetime-feedback pt-3 pb-3">
                <div className="position-relative">
                  <DatePickerInput
                    name="endDt"
                    clearIcon={null}
                    className={classNames(['form-control', 'w-max-140', { 'is-invalid': !!formik.errors.endDt }])}
                    calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                    onChange={(val) => handleDateChange(val)}
                    value={!!formik.values.endDt ? moment(formik.values.endDt || '').toDate() : undefined}
                  />
                  {!!formik.errors.endDt && (
                    <div className="auction-date-error">
                      <ControlFeedback type="invalid">{formik.errors.endDt}</ControlFeedback>
                    </div>
                  )}
                </div>
                <div className="d-flex align-items-center">
                  <span className="f-size-12 w-weight-400 ml-3 mr-3 time-at">v</span>
                  <FormGroup
                    controlOnly
                    type="number"
                    name="endDtHour"
                    controlClassName="w-max-70 text-center"
                    value={formik.values.endDtHour}
                    onBlur={handleTimeBlur}
                    onFocus={handleTimeFocus}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMaxNumberChange(e, 23)}
                  />
                  <span className="f-size-12 w-weight-400 ml-3 mr-3">:</span>
                  <FormGroup
                    controlOnly
                    type="number"
                    name="endDtMinutes"
                    controlClassName="w-max-70 text-center"
                    value={formik.values.endDtMinutes}
                    onBlur={handleTimeBlur}
                    onFocus={handleTimeFocus}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleMaxNumberChange(e, 59)}
                  />
                  <span className="f-size-12 w-weight-400 ml-3 mr-3">hod</span>
                </div>
              </div>
            </Form.Group>
          </div>
          <div className="d-flex justify-content-end">
            {showLoaded ? (
              <BasePreloader size={25} className="mr-2" />
            ) : (
              <Button type="submit">{getTranslation('protest_extend_save_btn')}</Button>
            )}
          </div>
        </div>
      </form>
    </Modal>
  );
};
