import * as React from 'react';

import { HistoryTable } from '@components';
import { useAuctionsApi } from '@api/auctions';
import { HistoryAuthorType } from '@types';

interface Props {
  auctionId: string | number;
  author?: HistoryAuthorType;
  dateCreated?: string;
}

const AuctionHistory: React.FC<Props> = (props) => {
  const auctionsApi = useAuctionsApi();

  return (
    <div className="component-history-table">
      <div>
        <h2>Historie</h2>
        <HistoryTable
          showUser
          id={props.auctionId}
          author={props.author}
          api={auctionsApi.getHistory}
          dateCreated={props.dateCreated}
        />
      </div>
    </div>
  );
};

export default AuctionHistory;
