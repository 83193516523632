import React from 'react';
import { useLocation } from 'react-router-dom';

import { BasePage, BoxCentered } from '@components';
import ResetPasswordForm from './components/ResetPasswordForm/ResetPasswordForm';
import NewPasswordForm from './components/NewPasswordForm/NewPasswordForm';

const AuthResetPasswordPage: React.FC = () => {
  const searchParams = new URLSearchParams(useLocation().search);
  const token = searchParams.get('token');

  return (
    <BasePage className="d-flex justify-content-center align-items-center min-vh-100 p-2">
      <BoxCentered>{!token ? <ResetPasswordForm /> : <NewPasswordForm token={token || ''} />}</BoxCentered>
    </BasePage>
  );
};

export default AuthResetPasswordPage;
