import React, { useEffect, useState } from 'react';
import clsx from 'classnames';

type Props = {
  value: string;
  className?: string;
};

export const PasswordValidator: React.FC<Props> = (props) => {
  const [passwordTip, setPasswordTip] = useState('');
  const [passwordValidity, setPasswordValidity] = useState(0);

  useEffect(() => {
    const passRes = getPasswordValidity();
    setPasswordTip(passRes.passwordTip);
    setPasswordValidity(passRes.validity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.value]);

  const getPasswordValidity = () => {
    let tip = '';
    let passwordStrength = 0;

    if (props.value.match(/[a-zA-Z]/)) {
      ++passwordStrength;

      if (props.value.match(/[a-z]/)) {
        ++passwordStrength;
      }
    }

    if (props.value.match(/\d+/)) {
      ++passwordStrength;
    }

    if (props.value.match(/[^a-zA-Z0-9]/)) {
      ++passwordStrength;
    }

    if (props.value.match(/[a-zA-Z]/)) {
      if (!props.value.match(/[a-z]/)) {
        if (!tip && passwordStrength < 3) {
          tip = 'Heslo musí obsahovat malé písmeno';
        }
        if (!tip && passwordStrength > 2) {
          tip = 'Doporučujeme vložit malé písmeno';
        }
      } else if (!props.value.match(/[A-Z]/)) {
        if (!tip && passwordStrength < 3) {
          tip = 'Heslo musí obsahovat velké písmeno';
        }
        if (!tip && passwordStrength > 2) {
          tip = 'Doporučujeme vložit velké písmeno';
        }
      }
    } else {
      tip = 'Heslo nebylo vyplněno';
    }

    if (!props.value.match(/\d+/)) {
      if (!tip && passwordStrength < 3) {
        tip = 'Heslo musí obsahovat číslo';
      }
      if (!tip && passwordStrength > 2) {
        tip = 'Doporučujeme vložit číslo';
      }
    }

    if (!props.value.match(/[^a-zA-Z0-9]/)) {
      if (!tip && passwordStrength < 3) {
        tip = 'Heslo by mělo obsahovat jeden speciální znak';
      }
      if (!tip && passwordStrength > 2) {
        tip = 'Doporučujeme vložit speciální znak';
      }
    }

    if (props.value.length < 13) {
      if (!tip && passwordStrength < 3) {
        tip = 'Heslo by mělo být delší než 12 znaků';
      }
      if (!tip && passwordStrength > 2) {
        tip = 'Doporučujeme, aby heslo mělo minimálně 12 znaků';
      }
    }

    if (!tip) {
      passwordStrength++;
      tip = 'Velmi silné heslo';
    }

    return {
      passwordTip: tip,
      validity: passwordStrength,
    };
  };

  if (!props.value) {
    return null;
  }

  return (
    <div className={clsx(['component-password-validator', props.className])}>
      <div className="component-items">
        <div
          className={clsx([
            'component-item',
            {
              'item-danger': passwordValidity < 3,
              'item-warning': passwordValidity > 2 && passwordValidity < 5,
            },
          ])}
        >
          <span>{passwordTip}</span>
        </div>
      </div>
    </div>
  );
};
