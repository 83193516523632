import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import * as routes from '@routes';
import OAuthCSAS from './pages/oauth/OAuthCSAS';

const OAuthRoutes: React.FC = () => (
  <Switch>
    <Route exact path={routes.oauth.CSAS}>
      <OAuthCSAS />
    </Route>
    <Redirect from="*" to="/" />
  </Switch>
);

export default OAuthRoutes;
