import React from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';

import { BaseFront, SEO } from '@components';
import * as routes from '@routes';
import { usePageStore } from '@stores';
import { IWantToSellDetail } from '@components/IWantToSell/IWantToSellDetail';
import { HintDetail } from '@components/Hint/HintDetail';

import HomePage from './pages/front/HomePage/HomePage';
import RegistrationPage from './pages/front/RegistrationPage/RegistrationPage';
import AccountPage from './pages/front/AccountPage/AccountPage';
import AccountWatchdogPage from './pages/front/AccountWatchdogPage/AccountWatchdogPage';
import AccountWatchdogFormPage from './pages/front/AccountWatchdogFormPage/AccountWatchdogFormPage';
import AccountDocumentsPage from './pages/front/AccountDocumentsPage/AccountDocumentsPage';
import AccountAuctionsPage from './pages/front/AccountAuctionsPage/AccountAuctionsPage';
import AccountFavouriteAuctionsPage from './pages/front/AccountFavouriteAuctionsPage/AccountFavouriteAuctionsPage';
import AccountAuctioneerRegistrationPage from './pages/front/AccountAuctioneerRegistrationPage/AccountAuctioneerRegistrationPage';
import AccountSettingsPage from './pages/front/AccountSettingsPage/AccountSettingsPage';
import Account404Page from './pages/front/Account404Page/Account404Page';
import AccountPersonalDataPage from './pages/front/AccountPersonalDataPage/AccountPersonalDataPage';
import AccountAuctionInformations from './pages/front/AccountAuctionInformations/AccountAuctionInformations';
import AccountEmailsPage from './pages/front/AccountEmailsPage/AccountEmailsPage';
import AuctionDetailPage from './pages/front/AuctionDetailPage/AuctionDetailPage';
import AuctionListPage from './pages/front/AuctionListPage/AuctionListPage';
import { AuctioneerListPage } from './pages/front/AuctioneerListPage/AuctioneerListPage';
import { ContactPage } from './pages/front/Contact/ContactPage';
import { AuctioneerDetailPage } from './pages/front/AuctioneerAuctionsPage/AuctioneerAuctionsPage';
import { IWantToSellPage } from './pages/front/IWantToSellPage/IWantToSellPage';
import { NewsPage } from './pages/front/News/NewsPage';
import { NewsPageDetail } from './pages/front/News/NewsPageDetail';
import { HintPage } from './pages/front/HintPage/HintPage';
import { AboutUsPage } from './pages/front/AboutUs/AboutUsPage';
import { DefaultPageComponent } from './pages/front/DefaultPage/DefaultPageComponent';

const FrontRoutes: React.FC = () => {
  const pageState = usePageStore();
  const location = useLocation();

  return (
    <BaseFront>
      <SEO />
      <Switch>
        {!!pageState.getPage(routes.front.REGISTRATION) && (
          <Route exact path={pageState.getPagePath(routes.front.REGISTRATION)}>
            <RegistrationPage />
          </Route>
        )}
        {!!pageState.getPage(routes.front.ACCOUNT) && (
          <Route exact path={pageState.getPagePath(routes.front.ACCOUNT)}>
            <AccountPage />
          </Route>
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_DOG) && (
          <Route exact path={pageState.getPagePath(routes.front.ACCOUNT_DOG)}>
            <AccountWatchdogPage />
          </Route>
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_DOG_DETAIL) && (
          <Route exact path={pageState.getPagePath(routes.front.ACCOUNT_DOG_DETAIL)}>
            <AccountWatchdogFormPage />
          </Route>
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_DOG_CREATE) && (
          <Route exact path={pageState.getPagePath(routes.front.ACCOUNT_DOG_CREATE)}>
            <AccountWatchdogFormPage />
          </Route>
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_DOCUMENTS) && (
          <Route exact path={pageState.getPagePath(routes.front.ACCOUNT_DOCUMENTS)}>
            <AccountDocumentsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_AUCTIONS) && (
          <Route exact path={pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS)}>
            <AccountAuctionsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_AUCTIONS_INFORMATIONS) && (
          <Route exact path={pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS_INFORMATIONS)}>
            <AccountAuctionInformations />
          </Route>
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_PERSONAL_DATA) && (
          <Route exact path={pageState.getPagePath(routes.front.ACCOUNT_PERSONAL_DATA)}>
            <AccountPersonalDataPage />
          </Route>
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_EMAILS) && (
          <Route exact path={pageState.getPagePath(routes.front.ACCOUNT_EMAILS)}>
            <AccountEmailsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_DOG) && (
          <Route exact path={pageState.getPagePath(routes.front.ACCOUNT_DOG)}>
            <Account404Page />
          </Route>
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_FAVOURITES) && (
          <Route exact path={pageState.getPagePath(routes.front.ACCOUNT_FAVOURITES)}>
            <AccountFavouriteAuctionsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_SETTINGS) && (
          <Route exact path={pageState.getPagePath(routes.front.ACCOUNT_SETTINGS)}>
            <AccountSettingsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.front.ACCOUNT_AUCTIONEER_REGISTRATION) && (
          <Route exact path={pageState.getPagePath(routes.front.ACCOUNT_AUCTIONEER_REGISTRATION)}>
            <AccountAuctioneerRegistrationPage />
          </Route>
        )}
        {!!pageState.getPage(routes.front.AUCTION_LIST_PREPARED) && (
          <Route exact path={pageState.getPagePath(routes.front.AUCTION_LIST_PREPARED)}>
            <AuctionListPage status="prepared" />
          </Route>
        )}
        {!!pageState.getPage(routes.front.AUCTION_LIST_ONGOING) && (
          <Route exact path={pageState.getPagePath(routes.front.AUCTION_LIST_ONGOING)}>
            <AuctionListPage status="ongoing" />
          </Route>
        )}
        {!!pageState.getPage(routes.front.AUCTION_LIST_ENDED) && (
          <Route exact path={pageState.getPagePath(routes.front.AUCTION_LIST_ENDED)}>
            <AuctionListPage status="ended" />
          </Route>
        )}
        {!!pageState.getPage(routes.front.AUCTION_LIST_OVERCHARGE) && (
          <Route exact path={pageState.getPagePath(routes.front.AUCTION_LIST_OVERCHARGE)}>
            <AuctionListPage status="overcharge" />
          </Route>
        )}
        {!!pageState.getPage(routes.front.AUCTION_DETAIL) && (
          <Route exact path={pageState.getPagePath(routes.front.AUCTION_DETAIL)}>
            <AuctionDetailPage type="auction" />
          </Route>
        )}
        {!!pageState.getPage(routes.front.AUCTION_PUBLIC_DETAIL) && (
          <Route exact path={pageState.getPagePath(routes.front.AUCTION_PUBLIC_DETAIL)}>
            <AuctionDetailPage type="auction_public" />
          </Route>
        )}
        {!!pageState.getPage(routes.front.AUCTIONEER_LIST) && (
          <Route exact path={pageState.getPagePath(routes.front.AUCTIONEER_LIST)}>
            <AuctioneerListPage />
          </Route>
        )}
        {!!pageState.getPage(routes.front.AUCTIONEER_DETAIL) && (
          <Route exact path={pageState.getPagePath(routes.front.AUCTIONEER_DETAIL)}>
            <AuctioneerDetailPage />
          </Route>
        )}
        {!!pageState.getPage(routes.front.CONTACT) && (
          <Route exact path={pageState.getPagePath(routes.front.CONTACT)}>
            <ContactPage />
          </Route>
        )}
        {!!pageState.getPage(routes.front.I_WANT_TO_SELL) && (
          <Route exact path={pageState.getPagePath(routes.front.I_WANT_TO_SELL)}>
            <IWantToSellPage />
          </Route>
        )}
        {!!pageState.getPage(routes.front.I_WANT_TO_SELL) && (
          <Route exact path={`${pageState.getPagePath(routes.front.I_WANT_TO_SELL)}/:slug`}>
            <IWantToSellDetail />
          </Route>
        )}
        {!!pageState.getPage(routes.front.NEWS) && (
          <Route exact path={pageState.getPagePath(routes.front.NEWS)}>
            <NewsPage />
          </Route>
        )}
        {!!pageState.getPage(routes.front.NEWS_DETAIL) && (
          <Route exact path={pageState.getPagePath(routes.front.NEWS_DETAIL)}>
            <NewsPageDetail />
          </Route>
        )}
        {!!pageState.getPage(routes.front.HINT) && (
          <Route exact path={pageState.getPagePath(routes.front.HINT)}>
            <HintPage />
          </Route>
        )}
        {!!pageState.getPage(routes.front.HINT) && (
          <Route exact path={`${pageState.getPagePath(routes.front.HINT)}/:slug+`}>
            <HintDetail />
          </Route>
        )}
        {!!pageState.getPage(routes.front.ABOUT_US) && (
          <Route exact path={pageState.getPagePath(routes.front.ABOUT_US)}>
            <AboutUsPage />
          </Route>
        )}
        <Route exact path={routes.front.HOME}>
          <HomePage />
        </Route>
        {pageState.pages.some((page) => page.fullUrl === location.pathname) && (
          <Route exact path={pageState.getPagePath(location.pathname)}>
            <DefaultPageComponent route={location.pathname} />
          </Route>
        )}
        <Redirect from="*" to="/" />
      </Switch>
    </BaseFront>
  );
};

export default FrontRoutes;
