import React, { useEffect, useState } from 'react';
import _, { parseInt } from 'lodash';
import { useLocation, useParams } from 'react-router-dom';
import { NumberParam, useQueryParams, withDefault } from 'use-query-params';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { useTranslatorApi } from '@api/translator';
import { InvoiceSection, HistorySection } from './components';
import { AdminProgressFormMenuItemType, BaseObjectType } from '@types';
import { AdminBoxContent, AdminProgressFormMenu, BasePage, Button } from '@components';

export enum InvoiceSections {
  invoice,
  history,
}

interface ParamsType {
  id: string;
}

export const InvoiceUpdatePage = () => {
  const { id } = useParams<ParamsType>();
  const location = useLocation();
  const translationsApi = useTranslatorApi();
  const pageState = usePageStore();
  const page = pageState.getPage(routes.admin.INVOICES_UPDATE);
  const [isLoaded, setIsLoaded] = useState(false);
  const [domainTranslations, setDomainTranslations] = useState<BaseObjectType<string>>({});
  const [query, setQuery] = useQueryParams({
    section: withDefault(NumberParam, InvoiceSections.invoice),
  });

  useEffect(() => {
    window?.scrollTo(0, 0);
    loadTranslations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const loadTranslations = async () => {
    try {
      setDomainTranslations((await translationsApi.domainTranslations('invoices')).data.data);
      setIsLoaded(true);
    } catch (err) {
      if (translationsApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const getTranslation = (key: string) => _.get(domainTranslations, key, key);

  const getActiveSection = (section: number): InvoiceSections => {
    return section in InvoiceSections ? section : InvoiceSections.invoice;
  };

  const handleSectionChange = (url: number | string) => {
    let section: number | undefined = parseInt(url.toString());
    if (section === InvoiceSections.invoice) {
      section = undefined;
    }
    setQuery({ ...query, section }, 'push');
  };

  const navItems: AdminProgressFormMenuItemType[] = [
    {
      label: getTranslation('tab_invoice'),
      url: InvoiceSections.invoice.toString(),
      isActive: getActiveSection(query.section) === InvoiceSections.invoice,
    },
    {
      label: getTranslation('tab_history'),
      url: InvoiceSections.history.toString(),
      isActive: getActiveSection(query.section) === InvoiceSections.history,
    },
  ];

  const renderContent = () => {
    const activeSection = getActiveSection(query.section);
    switch (activeSection) {
      case InvoiceSections.invoice:
        return <InvoiceSection id={id} />;
      case InvoiceSections.history:
        return <HistorySection id={id} translations={domainTranslations} />;
      default:
        return null;
    }
  };

  if (!isLoaded) {
    return null;
  }

  return (
    <BasePage page={routes.admin.INVOICES} title={page?.name}>
      <AdminProgressFormMenu onClick={handleSectionChange} items={navItems} />
      <AdminBoxContent className="p-0">{renderContent()}</AdminBoxContent>
      <Button grayBg to={`${pageState.getPagePath(routes.admin.INVOICES)}`}>
        {' '}
        Zpět na faktury
      </Button>
    </BasePage>
  );
};
