import React, { useEffect } from 'react';
import ReactModal from 'react-modal';

import { GalleryItemType } from './AuctionImages';

interface Props {
  item: GalleryItemType;
  onNext: () => void;
  onPrev: () => void;
  onRequestClose?: () => void;
}

const AuctionVideo: React.FC<Props> = (props) => {
  useEffect(() => {
    document.body.addEventListener('keydown', handleKeyDown);
    return () => {
      document.body.removeEventListener('keydown', handleKeyDown);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'ArrowLeft') {
      props.onPrev();
    }
    if (e.key === 'ArrowRight') {
      props.onNext();
    }
  };

  const renderVideo = () => {
    switch (props.item.image.media.mimeType) {
      case 'matterport':
        return (
          <iframe
            allowFullScreen
            title="Matterport"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            src={`https://my.matterport.com/show/?m=${props.item.image.media.embedHash}`}
            style={{
              zIndex: 1,
              width: '100%',
              maxWidth: 1024,
              height: '100%',
              maxHeight: 650,
              border: 0,
            }}
          />
        );
      case 'youtube':
        return (
          <iframe
            allowFullScreen
            title="YouTube"
            src={`https://www.youtube.com/embed/${props.item.image.media.embedHash}`}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            style={{
              zIndex: 1,
              width: '100%',
              maxWidth: 1024,
              height: '100%',
              maxHeight: 650,
              border: 0,
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <ReactModal
      isOpen
      onRequestClose={props.onRequestClose}
      style={{
        overlay: {
          position: 'fixed',
          inset: 0,
          backgroundColor: 'transparent',
          zIndex: 1000,
        },
        content: {
          position: 'absolute',
          inset: 0,
          border: 'none',
          background: 'transparent',
          overflow: 'hidden',
          borderRadius: 0,
          outline: 'none',
          padding: 0,
        },
      }}
    >
      <div className="ril-outer ril__outer ril__outerAnimating">
        <div className="ril-inner ril__inner">
          <img
            className="ril-image-next ril__imageNext ril__image"
            src="https://i.ytimg.com/vi/9k301M4ZEw0/hqdefault.jpg"
            alt="ril-ims"
            draggable="false"
            style={{ transform: 'translate3d(1920px, 0px, 0px) scale3d(1, 1, 1)' }}
          />
          <div
            style={{
              display: 'flex',
              height: '100%',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {renderVideo()}
          </div>
          <img
            className="ril-image-prev ril__imagePrev ril__image"
            src="https://i.ytimg.com/vi/IJNR2EpS0jw/hqdefault.jpg"
            alt="ril-ims"
            draggable="false"
            style={{ transform: 'translate3d(-1920px, 0px, 0px) scale3d(1, 1, 1)' }}
          />
        </div>
        <button
          type="button"
          aria-label="Previous image"
          className="ril-prev-button ril__navButtons ril__navButtonPrev"
          style={{ zIndex: 2 }}
          onClick={() => props.onPrev()}
        ></button>
        <button
          type="button"
          aria-label="Next image"
          className="ril-next-button ril__navButtons ril__navButtonNext"
          style={{ zIndex: 2 }}
          onClick={() => props.onNext()}
        ></button>
        <div className="ril-toolbar ril__toolbar">
          <ul className="ril-toolbar-left ril__toolbarSide ril__toolbarLeftSide">
            <li className="ril-toolbar__item ril__toolbarItem">
              <span className="ril-toolbar__item__child ril__toolbarItemChild"></span>
            </li>
          </ul>
          <ul className="ril-toolbar-right ril__toolbarSide ril__toolbarRightSide">
            <li className="ril-toolbar__item ril__toolbarItem">
              <button
                onClick={props.onRequestClose}
                type="button"
                aria-label="Close lightbox"
                className="ril-close ril-toolbar__item__child ril__toolbarItemChild ril__builtinButton ril__closeButton"
              ></button>
            </li>
          </ul>
        </div>
      </div>
    </ReactModal>
  );
};

export default AuctionVideo;
