import { AllowedEmails } from '@types';
import { API, APIResponse, useApi } from '@api/api';

export const detailPath = () => `/admin/allowed-emails`;

export interface AllowedEmailsAPI extends API {
  detail(): APIResponse<AllowedEmails>;
  update(data: AllowedEmails): APIResponse;
}

export const useAllowedEmailsApi = (): AllowedEmailsAPI => {
  const api = useApi();
  return {
    ...api,
    detail() {
      const cancelToken = api.prepareCancelToken('detail');
      return api.authRequest.get(detailPath(), { cancelToken });
    },
    update(data: AllowedEmails) {
      const cancelToken = api.prepareCancelToken('update');
      return api.authRequest.put(detailPath(), data, { cancelToken });
    },
  };
};
