import React, { useEffect, useState } from 'react';
import parse from 'html-react-parser';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { Button, MapComponent } from '@components';
import FrontTabsMenu from '@components/FrontTabsMenu/FrontTabsMenu';
import OngoingAuctionTab from '@components/OngoingAuctionTab/OngoingAuctionTab';
import AuctionParams from '@components/FrontAuctionDetail/components/AuctionParams';
import { AuctioneerAddress, AuctionParameterResponse, AuctionResponse } from '@types';
import AuctionDocumentTable from '@components/FrontAuctionDetail/components/AuctionDocumentTable';

interface Props {
  tabsRef: React.RefObject<any>;
  auctionDetail: AuctionResponse;
  activeSection: number;
  authorizationState: string;
  admitted: boolean;
  preEmption: boolean;
  canDraw: boolean;
  hasActiveProtest: boolean;
  uniqueCode: string;
  auctionReload: () => void;
  onChange: (section: number) => void;
  onJoinClick?: (e: React.MouseEvent) => void;
  auctionParameterComplete?: AuctionParameterResponse[];
}

const AuctionTabs: React.FC<Props> = (props) => {
  const pageState = usePageStore();
  const [auctioneerMainAddress, setAuctioneerMainAddress] = useState<AuctioneerAddress>();

  const menuItems = [
    { label: props.auctionDetail.translations?.tab_description, isActive: props.activeSection === 0 },
    { isActive: props.activeSection === 1, label: props.auctionDetail.translations?.tab_all_informations },
    {
      isActive: props.activeSection === 2,
      label: props.auctionDetail.translations?.tab_auctioneer,
      hide: props.auctionDetail?.auctioneer?.state === 'tester',
    },
    { label: props.auctionDetail.translations?.tab_documents, isActive: props.activeSection === 3 },
    { label: props.auctionDetail.translations?.tab_result, isActive: props.activeSection === 4 },
  ];

  useEffect(() => {
    const address = props.auctionDetail?.auctioneer?.invoiceAddress;
    if (!!address) {
      setAuctioneerMainAddress(address);
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auctionDetail?.auctioneer?.invoiceAddress]);

  const getTabContent = () => {
    if (props.activeSection === 0) {
      const { auctionDetail } = props;
      const auctionGps = { lat: auctionDetail.auctionAddress?.latitude, lng: auctionDetail.auctionAddress?.longitude };
      return (
        <div className="auction-tabs-content">
          <div className="row">
            {auctionDetail.description && (
              <div className="mb-2 col-sm-12 col-md-6 auction-description">{parse(auctionDetail.description)}</div>
            )}
            <div className="mb-2 col-sm-12 col-md-6">
              {auctionDetail.auctionAddress && (
                <MapComponent colors height={400} zoom={16} pin={auctionGps} displayDetail={true} />
              )}
            </div>
          </div>
        </div>
      );
    }
    if (props.activeSection === 1) {
      return (
        <div className="auction-tabs-content">
          <div className="row">
            <div className="mb-2 col-sm-12">
              <AuctionParams
                isMoreInfo={true}
                auction={props.auctionDetail}
                parameterComplete={props.auctionParameterComplete}
              />
            </div>
          </div>
        </div>
      );
    }
    if (props.activeSection === 2 && props.auctionDetail?.auctioneer?.state !== 'tester') {
      const { auctionDetail } = props;
      let auctioneerGps = { lat: 0, lng: 0 };
      let auctioneerGpsNotFound = false;
      const gps = auctioneerMainAddress?.gps.split(',');
      if (gps && gps.length > 1 && parseInt(gps[0]) !== 0 && parseInt(gps[1]) !== 0) {
        auctioneerGps = { lat: parseFloat(gps[0]), lng: parseFloat(gps[1]) };
      } else {
        auctioneerGpsNotFound = true;
      }

      return (
        <div className="auction-tabs-content">
          <div className="row">
            <div className="mb-2 col-sm-12 col-md-6">
              <div className="auctioneer-detail">
                {!!auctionDetail?.auctioneer?.auctioneerName && (
                  <p className="auctioneer-name">{auctionDetail?.auctioneer?.auctioneerName}</p>
                )}
                {!!auctionDetail?.auctioneer?.ic && (
                  <p>
                    <span className="font-weight-bold">IČO: </span>
                    {auctionDetail?.auctioneer?.ic}
                  </p>
                )}
                {!!auctionDetail?.auctioneer?.dic && (
                  <p>
                    <span className="font-weight-bold">DIČ: </span>
                    {auctionDetail?.auctioneer?.dic}
                  </p>
                )}

                <h4 className="mt-4 mb-3">Kontakt</h4>

                {!!auctionDetail?.auctioneer?.phone && (
                  <p>
                    <span className="font-weight-bold">Telefon: </span>
                    {auctionDetail?.auctioneer?.phone}
                  </p>
                )}
                {!!auctionDetail?.auctioneer?.email && (
                  <p>
                    <span className="font-weight-bold">E-mail: </span>
                    {auctionDetail?.auctioneer?.email}
                  </p>
                )}

                {!!auctionDetail?.auctioneer?.invoiceAddress && <h4 className="mt-4 mb-3">Fakturační adresa</h4>}

                {!!auctionDetail?.auctioneer?.invoiceAddress?.street && (
                  <p className="mb-2">
                    {auctionDetail?.auctioneer?.invoiceAddress.street}{' '}
                    {auctionDetail?.auctioneer?.invoiceAddress?.houseNumber}
                  </p>
                )}

                {!!auctionDetail?.auctioneer?.invoiceAddress?.city && (
                  <p className="mb-2">{auctionDetail?.auctioneer?.invoiceAddress.city}</p>
                )}
                {!!auctionDetail?.auctioneer?.invoiceAddress?.zipCode && (
                  <p>{auctionDetail?.auctioneer?.invoiceAddress.zipCode}</p>
                )}

                {!!auctionDetail?.auctioneer?.auctioneerName && (
                  <div className="pt-3">
                    <Button
                      to={`${pageState.getPagePath(routes.front.AUCTIONEER_DETAIL, {
                        ':id': auctionDetail.auctioneer.id,
                      })}?status=prepared`}
                    >
                      Nabídka tohoto dražebníka
                    </Button>
                  </div>
                )}
              </div>
            </div>
            <div className="mb-2 col-sm-12 col-md-6">
              {!auctioneerGpsNotFound && (
                <MapComponent colors displayDetail height={400} zoom={16} pin={auctioneerGps} />
              )}
            </div>
          </div>
        </div>
      );
    }

    if (props.activeSection === 3) {
      const { auctionPublicDocuments } = props.auctionDetail;

      return (
        <div className="auction-tabs-content">
          <div className="row">
            <div className="mb-2 col-sm-12">
              <AuctionDocumentTable auctionPublicDocuments={auctionPublicDocuments} />
            </div>
          </div>
        </div>
      );
    }

    if (props.activeSection === 4) {
      return (
        <OngoingAuctionTab
          isDetail
          auction={props.auctionDetail}
          admitted={props.admitted}
          preEmption={props.preEmption}
          canDraw={props.canDraw}
          uniqueCode={props.uniqueCode}
          authorizationState={props.authorizationState}
          onJoinClick={props.onJoinClick}
          hasActiveProtest={props.hasActiveProtest}
          auctionReload={props.auctionReload}
        />
      );
    }
  };

  return (
    <>
      <FrontTabsMenu items={menuItems} onClick={(index) => props.onChange(index)} />
      {getTabContent()}
    </>
  );
};

export default AuctionTabs;
