import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { BasePreloader, Button } from '@components';

import plusIco from '@assets/images/plus-ico.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';
import colorIcoCheck from '@assets/images/color-ico-check.svg';

interface Props {
  isDetail: boolean;
  saved: boolean;
  file?: File;
  onSave: () => void;
  onFileChange: (file: File) => void;
}

const AuctionInformationAddDocument: React.FC<Props> = (props) => {
  const inputRef = React.createRef<HTMLInputElement>();

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | undefined = e.target.files !== null ? e.target.files[0] : undefined;
    if (!!file) {
      props.onFileChange(file);
    }
  };

  const handleAddClick = (e: React.MouseEvent) => {
    e.preventDefault();
    inputRef.current?.click();
  };

  return (
    <div className="add-document">
      <div className="item-input">
        <div className="block-title">Přidat dokument:</div>
        <a href="/" className="item-add" onClick={handleAddClick}>
          Vyberte soubor z počítače
          {!!props.file && (
            <OverlayTrigger
              key="file"
              placement="top"
              overlay={
                <Tooltip id="table-permission">
                  <div>{props.file.name}</div>
                </Tooltip>
              }
            >
              <img src={colorIcoCheck} alt="ico" className="success-ico" />
            </OverlayTrigger>
          )}
        </a>
        {props.saved && <input type="file" ref={inputRef} className="d-none" onChange={handleSelectFile} />}
      </div>
      {props.isDetail && (
        <div className="item-submit">
          {props.saved ? (
            <>
              {!!props.file && (
                <Button type="button" onClick={() => props.onSave()}>
                  <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
                  <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
                  Přidat dokument
                </Button>
              )}
            </>
          ) : (
            <BasePreloader className="file-preloader" size={25} />
          )}
          <div className="clearfix" />
        </div>
      )}
      <div className="clearfix" />
    </div>
  );
};

export default AuctionInformationAddDocument;
