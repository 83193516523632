import { useEffect } from 'react';
import moment, { Moment } from 'moment';

import { SystemInfo } from '@types';
import { createStore, useStoreState } from './store';
import { useApplicationApi } from '@api/application';

export interface ApplicationState {
  offset?: number;
  systemInfo?: SystemInfo;
  timeTimer: NodeJS.Timer;
  zoomEnabled: boolean;
  tickListeners: ((time?: number) => void)[];
}

let currentApplicationTime: number | undefined = undefined;

export const applicationStore = createStore<ApplicationState>({
  zoomEnabled: true,
  tickListeners: [],
  timeTimer: setInterval(() => {
    const offset = applicationStore.getState().offset;
    if (!!currentApplicationTime) {
      currentApplicationTime = moment()
        .add(offset || 0, 'milliseconds')
        .toDate()
        .getTime();
    }
    applicationStore.getState().tickListeners.forEach((dispatch) => dispatch(currentApplicationTime));
  }, 100),
});

type Props = {
  onTick?: (time?: number) => void;
};

export const useApplicationStore = (props: Props = {}) => {
  const applicationApi = useApplicationApi();
  const applicationState = useStoreState(applicationStore);

  useEffect(() => {
    const tickListeners = applicationState.tickListeners;
    if (props.onTick) {
      tickListeners.push(props.onTick);
    }
    return () => {
      if (props.onTick) {
        tickListeners.splice(tickListeners.indexOf(props.onTick), 1);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getTime = () => currentApplicationTime;

  const getServerTime = (): Promise<Moment> => {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await applicationApi.getServerTime();
        const serverTime = moment(response.data.data.dateTime.date);
        resolve(serverTime);
      } catch (err) {
        if (applicationApi.isCancel(err)) {
          return;
        }
        reject(err);
      }
    });
  };

  const getOffset = async (): Promise<number> => {
    try {
      const browserTimestamp = moment().valueOf();
      const serverTime = (await getServerTime()).valueOf();
      return serverTime - browserTimestamp;
    } catch (error) {
      return 0;
    }
  };

  const getCurrentTime = async (offset: number): Promise<number> => {
    try {
      return moment().add(offset, 'milliseconds').toDate().getTime();
    } catch (error) {
      return Date.now();
    }
  };

  const startTime = async () => {
    const offset = await getOffset();
    applicationStore.setState({ offset });
    currentApplicationTime = await getCurrentTime(offset);
  };

  const loadSystemInfo = async () => {
    try {
      const res = await applicationApi.getSystemInfo();
      applicationStore.setState({ systemInfo: res.data });
    } catch (err) {
      if (applicationApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const setZoomEnabled = (zoomEnabled: boolean) => {
    applicationStore.setState({ zoomEnabled });
  };

  return {
    ...applicationState,
    startTime,
    getTime,
    loadSystemInfo,
    setZoomEnabled,
  };
};
