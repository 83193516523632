import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Formik, FormikValues } from 'formik';

import { Modal, Button, BasePreloader } from '@components';
import { ModalProps } from '@components/Modal/Modal';

interface Props extends ModalProps {
  onCancel: () => void;
  onReject: (reson: string) => void;
}

const RejectModal: React.FC<Props> = (props) => {
  const handleFormSubmit = async (values: FormikValues) => {
    if (typeof props.onReject === 'function') {
      props.onReject(values.reason);
    }
  };

  return (
    <Modal {...props} onRequestClose={props.onCancel} className="modal-content-md">
      {props.isOpen && (
        <Formik
          initialValues={{
            reason: '',
          }}
          onSubmit={handleFormSubmit}
        >
          {(formProps) => (
            <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formProps.handleSubmit(e)}>
              <Form.Group>
                <Form.Label>
                  <span style={{ fontWeight: 'bold', fontSize: '14px', lineHeight: '21px' }}>Důvod zamítnutí</span>
                </Form.Label>
                <Form.Control
                  required={true}
                  type="reason"
                  name="reason"
                  as="textarea"
                  rows={5}
                  disabled={formProps.isSubmitting}
                  value={formProps.values.reason}
                  onChange={formProps.handleChange}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>
                  <span style={{ fontWeight: 'bold', fontSize: '14px', lineHeight: '21px' }}>
                    Opravdu si přejete neschválit tento dokument?
                  </span>
                </Form.Label>
              </Form.Group>
              {formProps.isSubmitting ? (
                <BasePreloader size={34} />
              ) : (
                <>
                  <Button type="submit" variant="btn-outline-primary">
                    Zamítnout
                  </Button>
                  <Link
                    to="#"
                    className="d-inline-flex align-items-center"
                    onClick={(event) => {
                      event.preventDefault();
                      event.stopPropagation();

                      props.onCancel();
                    }}
                    style={{ marginLeft: '32px' }}
                  >
                    Zrušit
                  </Link>
                </>
              )}
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default RejectModal;
