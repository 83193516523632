import React, { FormEvent } from 'react';
import { Form } from 'react-bootstrap';
import { useFormik } from 'formik';

import { BasePreloader, Button, FormGroup } from '@components';
import { useHelpRequestApi } from '@api/helpRequest';

import formImage from '@assets/images/front/contact/sidebar-image.png';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

const initialFormValues = {
  to: 'exdrazby',
  name: '',
  email: '',
  phone: '',
  message: '',
  captcha: '',
};

export const ContactUsSideBar = () => {
  const helpRequestApi = useHelpRequestApi();
  const [captcha, setCaptcha] = React.useState('');
  const [refreshReCaptcha, setRefreshReCaptcha] = React.useState(false);
  const formik = useFormik({
    initialValues: initialFormValues,
    onSubmit: () => handleSubmit(),
  });

  const handleSubmit = async () => {
    try {
      let values = {
        ...formik.values,
        captcha,
      };
      await helpRequestApi.sendForWeb(values);
      formik.setSubmitting(false);
      await formik.setValues(initialFormValues);
      handleRegenerateCaptcha();
    } catch (err) {
      handleRegenerateCaptcha();
      if (!err.response) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
  };

  const handleRegenerateCaptcha = () => {
    setRefreshReCaptcha((r) => !r);
  };

  const onVerify = React.useCallback((token) => {
    setCaptcha(token);
  }, []);

  return (
    <div className="contact-us-sidebar">
      <GoogleReCaptcha onVerify={onVerify} refreshReCaptcha={refreshReCaptcha} />
      <div className="contact-item contact-item--image">
        <img src={formImage} alt="Form background" />
      </div>
      <Form onSubmit={(e: FormEvent<HTMLFormElement>) => formik.handleSubmit(e)}>
        <h2 className="heading">Poptávkový formulář</h2>
        <div className="pt-2">
          <FormGroup
            required={true}
            name="name"
            label="Jméno a příjmení"
            labelClassName="text-left"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.errors.name as string}
          />
          <FormGroup
            required={true}
            name="email"
            label="E-mail"
            labelClassName="text-left"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email as string}
          />
          <FormGroup
            required={true}
            name="phone"
            label="Tel.:"
            labelClassName="text-left"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.errors.phone as string}
          />
          <FormGroup
            rows={4}
            as="textarea"
            name="message"
            label="Vaše zpráva"
            className="f-align-start"
            labelClassName="text-left mb-0"
            controlClassName="w-max-100p b-radius-20 noresize"
            value={formik.values.message}
            onChange={formik.handleChange}
            error={formik.errors.message as string}
          />
          <div className="mt-2">
            {!formik.isSubmitting ? (
              <Button type="submit" variant="btn-outline-primary" disabled={formik.isSubmitting}>
                Odeslat
              </Button>
            ) : (
              <BasePreloader size={29} className="d-inline-block" />
            )}
            <div className="clearfix" />
          </div>
        </div>
      </Form>
    </div>
  );
};
