import React, { useEffect, useState, MouseEvent } from 'react';
import { Form } from 'react-bootstrap';
import fileDownload from 'js-file-download';

import { BasePreloader } from '@components';
import { PDAuthorization } from './PDAuthorization';
import { useAuctioneersApi } from '@api/auctioneers';

import apiDoc from '@assets/documents/api_doc.txt';

import { BankList } from './BankList';

interface Props {
  id: string;
  onNotFound: () => void;
}

const DataAdministration: React.FC<Props> = (props) => {
  const auctioneersApi = useAuctioneersApi();
  const [showPDForm, setShowPDForm] = useState(false);
  const [showBankAccount, setShowBankAccount] = useState(false);
  const [showFioAccount, setShowFioAccount] = useState(false);
  const [showPD, setShowPD] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    loadData();
    return () => auctioneersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDownloadApiDoc = (e: MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    fetch(apiDoc)
      .then((res) => res.text())
      .then((text) => fileDownload(text, 'api_doc.txt'));
  };

  const loadData = () => {
    auctioneersApi
      .auctionTypesList(props.id)
      .then((res) => {
        setShowPD(
          res.data.data.some((v) => !!~['executor_auction_voluntary', 'executor_auction_involuntary'].indexOf(v.type))
        );
        setIsLoaded(true);
      })
      .catch((err) => {
        if (auctioneersApi.isCancel(err)) {
          return;
        }
      });
  };

  if (!isLoaded) {
    return (
      <div>
        <BasePreloader />
      </div>
    );
  }

  return (
    <>
      {
        <div>
          {showPD && !showBankAccount && !showFioAccount && (
            <PDAuthorization
              id={props.id}
              showPDForm={showPDForm}
              setShowPDForm={setShowPDForm}
              onNotFound={props.onNotFound}
            />
          )}
          {!showPDForm && (
            <BankList
              id={props.id}
              showBankAccount={showBankAccount}
              showFioAccount={showFioAccount}
              onNotFound={props.onNotFound}
              setShowFioAccount={setShowFioAccount}
              setShowBankAccount={setShowBankAccount}
            />
          )}
        </div>
      }

      <div className="mt-5 mb-5">
        <h2>Sdílet na stránky dražebníka - API</h2>
        <p>
          Dokumentaci k implementaci API najde zde{' '}
          <a className="f-weight-bold" href="/" onClick={handleDownloadApiDoc}>
            dokumentace
          </a>{' '}
          a vaše auctioneerId je <span className="font-weight-bold">{props.id}</span>
        </p>
      </div>

      <div className="mt-3">
        <h2>Sdílet na stránky dražebníka - Iframe</h2>
        <div style={{ maxWidth: 500 }} className="pt-2">
          <Form.Control
            disabled
            rows={5}
            as="textarea"
            className="noresize"
            value={`<iframe src='${window.location.protocol}//${window.location.hostname}${
              window.location.port ? `:${window.location.port}` : ``
            }/iframe?auctioneer=${props.id}' style='width: 800px; height: 800px; border: 0;'></iframe>`}
          />
        </div>
      </div>
    </>
  );
};

export default DataAdministration;
