import React from 'react';
import classNames from 'classnames';
import { useLocation } from 'react-router-dom';
import HamburgerMenu from 'react-hamburger-menu';

import SidebarMenu from '../../AdminSidebar/components/SidebarMenu';

const MobileMenu: React.FC = () => {
  const [openMobileMenu, setOpenMobileMenu] = React.useState(false);
  const location = useLocation();

  React.useEffect(() => {
    setOpenMobileMenu(false);
  }, [location.pathname, location.search]);

  return (
    <div className={classNames(['hamburger-menu', { 'menu-active': openMobileMenu }])}>
      <div className="hamburger-menu-ico">
        <HamburgerMenu
          isOpen={openMobileMenu}
          menuClicked={() => setOpenMobileMenu(!openMobileMenu)}
          color={'linear-gradient(#62CEA3, #3D8CBB)'}
          width={22}
          height={16}
          strokeWidth={2}
          animationDuration={0.5}
        />
      </div>
      <div className="menu-content">
        <div className="menu-inner">
          <SidebarMenu />
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
