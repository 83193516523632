import React from 'react';
import Slider from 'react-slick';
import Lightbox from 'react-image-lightbox';

import { NewsImageType } from '@types';
import { useMediaApi } from '@api/media';

import noImage from '@assets/images/front/news/no-image.jpg';

interface Props {
  images: NewsImageType[];
}

export type GalleryItemType = {
  src: string;
  image: NewsImageType;
};

interface GalleryImageProps {
  image: NewsImageType;
  onLoad: (url: string, image: NewsImageType) => void;
  onClick: (url: NewsImageType) => void;
}

const GalleryImage: React.FC<GalleryImageProps> = (props) => {
  const mediaApi = useMediaApi();
  const [imageUrl, setImageUrl] = React.useState('');

  React.useEffect(() => {
    loadImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadImages = async () => {
    await loadImage('news.photo.detail');
    await loadDetail('news.photo.detail');
  };

  const loadImage = async (size?: string) => {
    try {
      const media = await mediaApi.detailFront(props.image.media.hash, true, false, size);
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(media.data);
      setImageUrl(imageUrl);
    } catch (err) {
      console.error(err);
      setImageUrl(props.image.id.toString());
    }
  };

  const loadDetail = async (size?: string) => {
    try {
      const media = await mediaApi.detailFront(props.image.media.hash, true, false, size);
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(media.data);
      props.onLoad(imageUrl, props.image);
    } catch (err) {
      props.onLoad(props.image.id.toString(), props.image);
    }
  };

  return (
    <div className="cursor-pointer position-relative" onClick={() => props.onClick(props.image)}>
      <img src={imageUrl} alt="gallery" />
    </div>
  );
};

const NewsGallery: React.FC<Props> = (props) => {
  const [openLightbox, setOpenLightbox] = React.useState(false);
  const [imageIndex, setImageIndex] = React.useState(0);
  const [newsGalleryImages, setNewsGalleryImages] = React.useState<GalleryItemType[]>([]);

  const handleImageLoad = (url: string, image: NewsImageType, imageIndex: number) => {
    newsGalleryImages[imageIndex] = { src: url, image };
    setNewsGalleryImages(newsGalleryImages);
  };

  const handleImageClick = (image: NewsImageType) => {
    const index = newsGalleryImages.findIndex((i) => i.image.id === image.id);
    if (index > -1) {
      setImageIndex(index);
      setOpenLightbox(true);
    }
  };

  const renderLightbox = () => {
    const currentImage = newsGalleryImages[imageIndex];
    return (
      <Lightbox
        animationDuration={0}
        clickOutsideToClose={false}
        mainSrc={currentImage.src}
        nextSrc={newsGalleryImages[(imageIndex + 1) % newsGalleryImages.length].src}
        prevSrc={newsGalleryImages[(imageIndex + newsGalleryImages.length - 1) % newsGalleryImages.length].src}
        onCloseRequest={() => setOpenLightbox(false)}
        onMovePrevRequest={() => setImageIndex((imageIndex + newsGalleryImages.length - 1) % newsGalleryImages.length)}
        onMoveNextRequest={() => setImageIndex((imageIndex + 1) % newsGalleryImages.length)}
      />
    );
  };

  return (
    <div className="image-items">
      {openLightbox && renderLightbox()}
      {(props.images || []).length > 0 ? (
        <Slider
          slidesToShow={1}
          slidesToScroll={1}
          nextArrow={<a href="/">&nbsp;</a>}
          prevArrow={<a href="/">&nbsp;</a>}
        >
          {(props.images || []).map((galleryImage, index) => (
            <div className="image-item" key={`auction-image-${index}`}>
              <GalleryImage
                image={galleryImage}
                onLoad={(url, image) => handleImageLoad(url, image, index)}
                onClick={handleImageClick}
              />
            </div>
          ))}
        </Slider>
      ) : (
        <div className="no-image">
          <img src={noImage} alt="gallery" />
        </div>
      )}
    </div>
  );
};

export default NewsGallery;
