import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import * as routes from '@routes';
import bellGreenIco from '@assets/images/bell-green.svg';
import { BasePreloader, Button, SEO } from '@components';
import { NewsType } from '@types';
import { useNewsApi } from '@api/news';
import { getDateFormat } from '@helpers/datetime';
import { usePageStore } from '@stores';

import { NewsItem } from './NewsItem';
import NewsGallery from './NewsGallery';

interface ParamsType {
  slug: string;
}

export const NewsDetail = () => {
  const newsApi = useNewsApi();
  const pageState = usePageStore();
  const { slug } = useParams<ParamsType>();
  const [loaded, setLoaded] = useState(false);
  const [news, setNews] = useState<NewsType | null>(null);
  const [relatedNews, setRelatedNews] = useState<Array<NewsType>>([]);

  useEffect(() => {
    loadData();
    return () => newsApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug]);

  useEffect(() => {
    if (news?.id) {
      loadRelatedNews();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [news]);

  const loadData = async () => {
    setLoaded(false);
    try {
      const response = await newsApi.webDetailNews(slug);
      setNews(response.data.data);
      setLoaded(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
      setLoaded(true);
    }
  };

  const loadRelatedNews = async () => {
    try {
      const response = await newsApi.webNewsInterested(news!.id);
      setRelatedNews(response.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  if (news === null) {
    return (
      <div className="d-flex pb-5 pt-2 align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  if (!loaded) {
    return (
      <div className="d-flex pb-5 pt-2 align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <>
      <SEO title={news.title} />
      <h1 className="news-page-title">Aktuality</h1>
      <div className="component-front-news-detail">
        <div>
          <p>
            <img src={bellGreenIco} style={{ marginRight: '8px' }} alt="" />
            {getDateFormat(news.createdDt)}
          </p>
        </div>
        <h2 className="news-title">{news.title}</h2>
        <div className="news-detail-image-holder">
          {news.newsMedia.length > 0 && <NewsGallery images={news.newsMedia} />}
        </div>
        <p className="news-content" dangerouslySetInnerHTML={{ __html: news.content }} />

        <Button
          type="button"
          variant="btn-outline-primary"
          className="mt-4"
          to={pageState.getPagePath(routes.front.NEWS)}
        >
          Vrátit se zpět
        </Button>
      </div>
      {relatedNews.length > 0 && (
        <>
          <h2 className="related-title">Mohlo by Vás zajímat</h2>
          <div className="list-items">
            {relatedNews.map((item) => (
              <NewsItem item={item} />
            ))}
          </div>
        </>
      )}
    </>
  );
};
