import React from 'react';
import classNames from 'classnames';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { FrontTabsMenuItemType } from '@types';

import logIcoActive from '@assets/images/admin-sidebar/log-active.svg';

interface Props {
  items: Array<FrontTabsMenuItemType>;
  onClick: (index: number) => void;
}

const FrontTabsMenu: React.FC<Props> = (props) => {
  const handleItemClick = (item: FrontTabsMenuItemType, index: number) => {
    if (!item.disabled) {
      props.onClick(index);
    }
  };

  const renderItemContent = (item: FrontTabsMenuItemType, index: number) => {
    return (
      <span onClick={() => handleItemClick(item, index)} className={classNames({ 'cursor-pointer': !item.disabled })}>
        {item.label}
        {item.count !== undefined && <small>({item.count})</small>}
        {item.disabled && item.disabledMessage && (
          <img src={logIcoActive} style={{ width: 12, marginLeft: 12, marginBottom: 4 }} alt="ico-lock" />
        )}
      </span>
    );
  };

  const renderItem = (item: FrontTabsMenuItemType, index: number) => {
    if (item.disabled && item.disabledMessage) {
      return (
        <OverlayTrigger
          placement="top"
          key={`${index}-disabled`}
          overlay={<Tooltip id="table-permission">{item.disabledMessage}</Tooltip>}
        >
          {renderItemContent(item, index)}
        </OverlayTrigger>
      );
    }

    return renderItemContent(item, index);
  };

  return (
    <div className="component-front-tabs-menu">
      <ul>
        {props.items.map((item, index) => (
          <React.Fragment key={`menu-item-${index}`}>
            {!item.hide && <li className={classNames({ 'is-active': item.isActive })}>{renderItem(item, index)}</li>}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default FrontTabsMenu;
