import { API, APIResponse, useApi } from '@api/api';
import { Translation, BaseObjectType } from '@types';

export const domainTranslationPath = (domain: string, text: string) =>
  `/translation/${domain}/${encodeURIComponent(text)}`;
export const domainTranslationsPath = (domain: string) => `/translation/${domain}`;

export interface TranslatorAPI extends API {
  domainTranslate(domain: string, text: string): APIResponse<Translation>;
  domainTranslations(domain: string): APIResponse<BaseObjectType<string>>;
}

export const useTranslatorApi = (): TranslatorAPI => {
  const api = useApi();

  return {
    ...api,
    domainTranslate(domain: string, text: string) {
      const cancelToken = api.prepareCancelToken(`domainTranslate${domain}${text}`);
      return api.authRequest.get(domainTranslationPath(domain, text), { cancelToken });
    },
    domainTranslations(domain: string) {
      const cancelToken = api.prepareCancelToken(`domainTranslations`);
      return api.authRequest.get(domainTranslationsPath(domain), { cancelToken });
    },
  };
};
