import React from 'react';
import { FormikValues, useFormik } from 'formik';
import { Col, Form, Row, Table } from 'react-bootstrap';
import classNames from 'classnames';
import moment from 'moment';
import _ from 'lodash';

import { AuctionInvoiceItem, BaseSelectType, InvoiceResponse } from '@types';
import { BasePreloader, FormGroup, Button, DatePickerInput, ControlFeedback, Select } from '@components';
import { useInvoiceApi } from '@api/invoice';
import { getRequestDateFormat } from '@helpers/datetime';

import inputIcoCalendar from '@assets/images/input-ico-calendar.svg';
import colorIcoConf from '@assets/images/color-ico-conf.svg';
import colorIcoDel from '@assets/images/color-ico-del.svg';
import { Link } from 'react-router-dom';
import InvoiceServiceModal from '../../InvoicesPage/components/InvoiceAddServiceModal';
import { useServicesApi } from '@api/services';
import { ValueType } from 'react-select';

interface Props {
  data: InvoiceResponse;
}

interface TypeSelectOptionType {
  value: string;
  label: string;
}

const paidOptions = [
  {
    value: 'true',
    label: 'Ano',
  },
  {
    value: 'false',
    label: 'Ne',
  },
];

const enforcementOptions = [
  {
    value: 'true',
    label: 'Ano',
  },
  {
    value: 'false',
    label: 'Ne',
  },
];

export const InvoiceForm: React.FC<Props> = (props) => {
  const invoiceApi = useInvoiceApi();
  const servicesApi = useServicesApi();
  const [vatOptions, setVatOptions] = React.useState<BaseSelectType[]>([]);
  const [showForm, setShowForm] = React.useState({
    subscriber: false,
    supplier: false,
  });
  const [showModal, setShowModal] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      invoiceNumber: props.data.invoiceNumber || '',
      dateOfIssue: !!props.data.dateOfIssue ? getRequestDateFormat(props.data.dateOfIssue) : '',
      datePaid: !!props.data.datePaid ? getRequestDateFormat(props.data.datePaid) : '',
      paid: props.data.paid || false,
      note: props.data.note || '',
      enforcement: props.data.enforcement || false,
      currency: props.data.currency || 'CZK',
      dateOfOfTaxableSupply: !!props.data.dateOfOfTaxableSupply
        ? getRequestDateFormat(props.data.dateOfOfTaxableSupply)
        : '',
      dateDue: !!props.data.dateDue ? getRequestDateFormat(props.data.dateDue) : '',
      auctionInvoiceItems: props.data.auctionInvoiceItems || [],
      auctionInvoiceSubscriber: {
        title: props.data.auctionInvoiceSubscriber.title || '',
        email: props.data.auctionInvoiceSubscriber.email || '',
        phoneNumber: props.data.auctionInvoiceSubscriber.phoneNumber || '',
        ico: props.data.auctionInvoiceSubscriber.ico || '',
        dic: props.data.auctionInvoiceSubscriber.dic || '',
        street: props.data.auctionInvoiceSubscriber.street || '',
        city: props.data.auctionInvoiceSubscriber.city || '',
        zip: props.data.auctionInvoiceSubscriber.zip || '',
        bankAccount: props.data.auctionInvoiceSubscriber.bankAccount || '',
        bankTitle: props.data.auctionInvoiceSubscriber.bankTitle || '',
      },
      auctionInvoiceSupplier: {
        title: props.data.auctionInvoiceSupplier.title || '',
        email: props.data.auctionInvoiceSupplier.email || '',
        phoneNumber: props.data.auctionInvoiceSupplier.phoneNumber || '',
        ico: props.data.auctionInvoiceSupplier.ico || '',
        dic: props.data.auctionInvoiceSupplier.dic || '',
        street: props.data.auctionInvoiceSupplier.street || '',
        city: props.data.auctionInvoiceSupplier.city || '',
        zip: props.data.auctionInvoiceSupplier.zip || '',
        bankAccount: props.data.auctionInvoiceSupplier.bankAccount || '',
        iban: props.data.auctionInvoiceSupplier.iban || '',
        swift: props.data.auctionInvoiceSupplier.swift || '',
        bankTitle: props.data.auctionInvoiceSupplier.bankTitle || '',
      },
    },
    onSubmit: (values) => handleSubmit(values),
  });

  React.useEffect(() => {
    loadVatOptions();
    return () => servicesApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values: FormikValues) => {
    const omitAuctionInvoiceItems = [
      'serviceTypeTranslation',
      'unitPriceWithVat',
      'unit',
      'unitTranslation',
      'sumPrice',
      'sumPriceVat',
      'sumPriceWithVat',
      'vatTranslation',
    ];
    values.auctionInvoiceSupplier.phoneNumber = values.auctionInvoiceSupplier.phoneNumber.replaceAll(' ', '');
    values.auctionInvoiceSubscriber.phoneNumber = values.auctionInvoiceSubscriber.phoneNumber.replaceAll(' ', '');
    const auctionInvoiceItems = values.auctionInvoiceItems.map((item: AuctionInvoiceItem) =>
      _.omit(item, omitAuctionInvoiceItems)
    );
    const omitInputs = values.paid ? values : _.omit(values, ['datePaid']);
    const inputs = { ...omitInputs, auctionInvoiceItems: auctionInvoiceItems };
    invoiceApi
      .updateInvoice(props.data.id, inputs)
      .then(() => {
        formik.setSubmitting(false);
      })
      .catch((err) => {
        if (invoiceApi.isCancel(err)) {
          return;
        }
        const errors = err.response?.data?.errors || {};
        Object.getOwnPropertyNames(errors).map((prop) => {
          formik.setFieldError(prop, errors[prop][0]);
          return prop;
        });
        formik.setSubmitting(false);
      });
  };

  const loadVatOptions = async () => {
    try {
      const response = await servicesApi.vatEnum();
      setVatOptions(
        response.data.data.map((item) => ({
          value: item.type,
          label: item.translated,
        }))
      );
    } catch (err) {
      if (servicesApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleDateChange = (prop: string, value: Date | Date[] | null) => {
    if (value !== null) {
      const inputValue = value as Date;
      formik.setFieldValue(prop, !!inputValue ? getRequestDateFormat(inputValue) : '');
    }
  };

  const handleShowSubscriber = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowForm({ ...showForm, subscriber: !showForm.subscriber });
  };

  const handleShowSupplier = (e: React.MouseEvent) => {
    e.preventDefault();
    setShowForm({ ...showForm, supplier: !showForm.supplier });
  };

  const handleVatChange = (value: ValueType<TypeSelectOptionType, boolean>, index: number) => {
    const itemValue = value as TypeSelectOptionType;
    formik.setFieldValue(`auctionInvoiceItems[${index}].vat`, itemValue?.value || '');
  };

  const handleDeleteClick = (e: React.MouseEvent, index: number) => {
    e.preventDefault();
    const newArr = formik.values.auctionInvoiceItems;
    newArr.splice(index, 1);
    formik.setFieldValue('[auctionInvoiceItems]', newArr);
  };

  function handlePaid(value: ValueType<TypeSelectOptionType, boolean>) {
    const itemValue = value as TypeSelectOptionType;
    if (itemValue?.value === 'true') {
      return formik.setFieldValue('paid', true);
    }
    if (itemValue?.value === 'false') {
      return formik.setFieldValue('paid', false);
    }
    return formik.setFieldValue('paid', '');
  }

  function handleEnforcement(value: ValueType<TypeSelectOptionType, boolean>) {
    const itemValue = value as TypeSelectOptionType;
    if (itemValue?.value === 'true') {
      return formik.setFieldValue('enforcement', true);
    }
    if (itemValue?.value === 'false') {
      return formik.setFieldValue('enforcement', false);
    }
    return formik.setFieldValue('enforcement', '');
  }

  return (
    <div className="invoices-form">
      <div>
        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-5">
          <div>
            <Row>
              <Col lg={6} xs={12}>
                <div className="group-with-edit">
                  <FormGroup
                    type="text"
                    name="auctionInvoiceSubscriber.title"
                    label="Odběratel:"
                    value={formik.values.auctionInvoiceSubscriber.title}
                    onChange={formik.handleChange}
                    error={_.get(formik.errors, 'auctionInvoiceSubscriber_title')}
                    required
                    readOnly={!showForm.subscriber}
                  />
                  <Link to="/" className="d-inline-flex align-items-center" onClick={(e) => handleShowSubscriber(e)}>
                    <img src={showForm.subscriber ? colorIcoDel : colorIcoConf} alt="ico" className="mr-2" />
                    {showForm.subscriber ? 'Zrušit' : 'Upravit'}
                  </Link>
                </div>
                {showForm.subscriber && (
                  <>
                    <FormGroup
                      type="text"
                      name="auctionInvoiceSubscriber.email"
                      label="Email:"
                      error={_.get(formik.errors, 'auctionInvoiceSubscriber_email')}
                      value={formik.values.auctionInvoiceSubscriber.email}
                      onChange={formik.handleChange}
                    />
                    <FormGroup
                      type="text"
                      name="auctionInvoiceSubscriber.phoneNumber"
                      label="Telefon:"
                      error={_.get(formik.errors, 'auctionInvoiceSubscriber_phoneNumber')}
                      value={formik.values.auctionInvoiceSubscriber.phoneNumber}
                      onChange={formik.handleChange}
                    />
                    <FormGroup
                      type="text"
                      name="auctionInvoiceSubscriber.ico"
                      label="IČ:"
                      error={_.get(formik.errors, 'auctionInvoiceSubscriber_ico')}
                      value={formik.values.auctionInvoiceSubscriber.ico}
                      onChange={formik.handleChange}
                    />
                    <FormGroup
                      type="text"
                      name="auctionInvoiceSubscriber.dic"
                      label="DIČ:"
                      value={formik.values.auctionInvoiceSubscriber.dic}
                      onChange={formik.handleChange}
                    />
                    <FormGroup
                      type="text"
                      name="auctionInvoiceSubscriber.street"
                      label="Ulice:"
                      value={formik.values.auctionInvoiceSubscriber.street}
                      onChange={formik.handleChange}
                      error={_.get(formik.errors, 'auctionInvoiceSubscriber_street')}
                      required
                    />
                    <FormGroup
                      type="text"
                      name="auctionInvoiceSubscriber.city"
                      label="Město:"
                      value={formik.values.auctionInvoiceSubscriber.city}
                      onChange={formik.handleChange}
                      error={_.get(formik.errors, 'auctionInvoiceSubscriber_city')}
                      required
                    />
                    <FormGroup
                      type="text"
                      name="auctionInvoiceSubscriber.zip"
                      label="PSČ:"
                      value={formik.values.auctionInvoiceSubscriber.zip}
                      onChange={formik.handleChange}
                      error={_.get(formik.errors, 'auctionInvoiceSubscriber_zip')}
                      required
                    />
                    <FormGroup
                      type="text"
                      name="auctionInvoiceSubscriber.bankAccount"
                      label="Bankovní účet:"
                      error={_.get(formik.errors, 'auctionInvoiceSubscriber_bankAccount')}
                      value={formik.values.auctionInvoiceSubscriber.bankAccount}
                      onChange={formik.handleChange}
                    />
                    <FormGroup
                      type="text"
                      name="auctionInvoiceSubscriber.bankTitle"
                      label="Název banky:"
                      error={_.get(formik.errors, 'auctionInvoiceSubscriber_bankTitle')}
                      value={formik.values.auctionInvoiceSubscriber.bankTitle}
                      onChange={formik.handleChange}
                    />
                  </>
                )}
                <div className="group-with-edit">
                  <FormGroup
                    type="text"
                    name="auctionInvoiceSupplier.title"
                    label="Dodavatel:"
                    value={formik.values.auctionInvoiceSupplier.title}
                    onChange={formik.handleChange}
                    error={_.get(formik.errors, 'auctionInvoiceSupplier_title')}
                    required
                    readOnly={!showForm.supplier}
                  />
                  <Link to="/" className="d-inline-flex align-items-center" onClick={(e) => handleShowSupplier(e)}>
                    <img src={showForm.supplier ? colorIcoDel : colorIcoConf} alt="ico" className="mr-2" />
                    {showForm.supplier ? 'Zrušit' : 'Upravit'}
                  </Link>
                </div>
                {showForm.supplier && (
                  <>
                    <FormGroup
                      type="text"
                      name="auctionInvoiceSupplier.email"
                      error={_.get(formik.errors, 'auctionInvoiceSupplier_email')}
                      label="Email:"
                      value={formik.values.auctionInvoiceSupplier.email}
                      onChange={formik.handleChange}
                    />
                    <FormGroup
                      type="text"
                      name="auctionInvoiceSupplier.phoneNumber"
                      error={_.get(formik.errors, 'auctionInvoiceSupplier_phoneNumber')}
                      label="Telefon: *"
                      value={formik.values.auctionInvoiceSupplier.phoneNumber}
                      onChange={formik.handleChange}
                    />
                    <FormGroup
                      type="text"
                      name="auctionInvoiceSupplier.ico"
                      label="IČ:"
                      error={_.get(formik.errors, 'auctionInvoiceSupplier_ico')}
                      value={formik.values.auctionInvoiceSupplier.ico}
                      onChange={formik.handleChange}
                    />
                    <FormGroup
                      type="text"
                      name="auctionInvoiceSupplier.dic"
                      label="DIČ:"
                      value={formik.values.auctionInvoiceSupplier.dic}
                      onChange={formik.handleChange}
                    />
                    <FormGroup
                      type="text"
                      name="auctionInvoiceSupplier.street"
                      label="Ulice:"
                      value={formik.values.auctionInvoiceSupplier.street}
                      onChange={formik.handleChange}
                      error={_.get(formik.errors, 'auctionInvoiceSupplier_street')}
                      required
                    />
                    <FormGroup
                      type="text"
                      name="auctionInvoiceSupplier.city"
                      label="Město:"
                      value={formik.values.auctionInvoiceSupplier.city}
                      onChange={formik.handleChange}
                      error={_.get(formik.errors, 'auctionInvoiceSupplier_city')}
                      required
                    />
                    <FormGroup
                      type="text"
                      name="auctionInvoiceSupplier.zip"
                      label="PSČ:"
                      value={formik.values.auctionInvoiceSupplier.zip}
                      onChange={formik.handleChange}
                      error={_.get(formik.errors, 'auctionInvoiceSupplier_zip')}
                      required
                    />
                    <FormGroup
                      type="text"
                      name="auctionInvoiceSupplier.bankAccount"
                      label="Bankovní účet:"
                      error={_.get(formik.errors, 'auctionInvoiceSupplier_bankAccount')}
                      value={formik.values.auctionInvoiceSupplier.bankAccount}
                      onChange={formik.handleChange}
                    />
                    <FormGroup
                      type="text"
                      required
                      name="auctionInvoiceSupplier.iban"
                      error={_.get(formik.errors, 'auctionInvoiceSupplier_iban')}
                      label="IBAN:"
                      value={formik.values.auctionInvoiceSupplier.iban}
                      onChange={formik.handleChange}
                    />
                    <FormGroup
                      type="text"
                      required
                      name="auctionInvoiceSupplier.swift"
                      error={_.get(formik.errors, 'auctionInvoiceSupplier_swift')}
                      label="SWIFT:"
                      value={formik.values.auctionInvoiceSupplier.swift}
                      onChange={formik.handleChange}
                    />
                    <FormGroup
                      type="text"
                      name="auctionInvoiceSupplier.bankTitle"
                      error={_.get(formik.errors, 'auctionInvoiceSupplier_bankTitle')}
                      label="Název banky:"
                      value={formik.values.auctionInvoiceSupplier.bankTitle}
                      onChange={formik.handleChange}
                    />
                  </>
                )}
                <div className="d-flex align-items-center mb-35 invoice-number">
                  <FormGroup
                    type="text"
                    name="invoiceNumber"
                    label="Číslo faktury"
                    className="mt-0 mb-0"
                    value={formik.values.invoiceNumber}
                    onChange={formik.handleChange}
                    error={formik.errors.invoiceNumber}
                  />
                </div>
                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label text-right">Datum vystavení</Form.Label>
                  <div className="f-inline-control d-flex align-items-center datetime-feedback">
                    <div className="w-100p">
                      <DatePickerInput
                        name="dateOfIssue"
                        className={classNames([
                          'form-control',
                          'w-max-140',
                          { 'is-invalid': formik.errors.dateOfIssue },
                        ])}
                        calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                        clearIcon={null}
                        onChange={(val) => handleDateChange('dateOfIssue', val)}
                        value={formik.values.dateOfIssue ? moment(formik.values.dateOfIssue || '').toDate() : undefined}
                      />
                      {!!_.get(formik.errors, 'dateOfIssue') && (
                        <ControlFeedback type="invalid" style={{ left: 16, bottom: -25 }}>
                          {_.get(formik.errors, 'dateOfIssue')}
                        </ControlFeedback>
                      )}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label text-right">Datum zdanitelného plnění</Form.Label>
                  <div className="f-inline-control d-flex align-items-center datetime-feedback">
                    <div className="w-100p">
                      <DatePickerInput
                        name="dateOfOfTaxableSupply"
                        className={classNames([
                          'form-control',
                          'w-max-140',
                          { 'is-invalid': formik.errors.dateOfOfTaxableSupply },
                        ])}
                        calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                        clearIcon={null}
                        onChange={(val) => handleDateChange('dateOfOfTaxableSupply', val)}
                        value={
                          formik.values.dateOfOfTaxableSupply
                            ? moment(formik.values.dateOfOfTaxableSupply || '').toDate()
                            : undefined
                        }
                      />
                      {!!_.get(formik.errors, 'dateOfOfTaxableSupply') && (
                        <ControlFeedback type="invalid" style={{ left: 16, bottom: -25 }}>
                          {_.get(formik.errors, 'dateOfOfTaxableSupply')}
                        </ControlFeedback>
                      )}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label text-right">Datum splatnosti</Form.Label>
                  <div className="f-inline-control d-flex align-items-center datetime-feedback">
                    <div className="w-100p">
                      <DatePickerInput
                        name="dateDue"
                        className={classNames(['form-control', 'w-max-140', { 'is-invalid': formik.errors.dateDue }])}
                        calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                        clearIcon={null}
                        onChange={(val) => handleDateChange('dateDue', val)}
                        value={formik.values.dateDue ? moment(formik.values.dateDue || '').toDate() : undefined}
                      />
                      {!!_.get(formik.errors, 'dateDue') && (
                        <ControlFeedback type="invalid" style={{ left: 16, bottom: -25 }}>
                          {_.get(formik.errors, 'dateDue')}
                        </ControlFeedback>
                      )}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label text-right">Zaplaceno</Form.Label>
                  <Select
                    options={paidOptions}
                    value={paidOptions.find((i) => i.value === String(formik.values.paid)) || null}
                    onChange={(val) => handlePaid(val)}
                    className="invoice-select"
                  />
                </Form.Group>
                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label text-right">Datum zaplacení</Form.Label>
                  <div className="f-inline-control d-flex align-items-center datetime-feedback">
                    <div className="w-100p">
                      <DatePickerInput
                        name="datePaid"
                        className={classNames(['form-control', 'w-max-140', { 'is-invalid': formik.errors.datePaid }])}
                        calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                        clearIcon={null}
                        onChange={(val) => handleDateChange('datePaid', val)}
                        value={formik.values.datePaid ? moment(formik.values.datePaid || '').toDate() : undefined}
                        readOnly={!formik.values.paid}
                      />
                      {!!_.get(formik.errors, 'datePaid') && (
                        <ControlFeedback type="invalid" style={{ left: 16, bottom: -25 }}>
                          {_.get(formik.errors, 'datePaid')}
                        </ControlFeedback>
                      )}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label text-right">Vymáháno</Form.Label>
                  <Select
                    options={enforcementOptions}
                    value={enforcementOptions.find((i) => i.value === String(formik.values.enforcement)) || null}
                    onChange={(val) => handleEnforcement(val)}
                    className="invoice-select"
                  />
                </Form.Group>
              </Col>
              <Col lg={6} xs={12}>
                <FormGroup
                  rows={10}
                  as="textarea"
                  name="note"
                  label="Poznámka"
                  placeholder="Poznámka"
                  className="f-align-start"
                  labelClassName="d-none"
                  controlClassName="b-radius-20 text-area"
                  value={formik.values.note}
                  onChange={formik.handleChange}
                  error={formik.errors.note as string}
                />
              </Col>
              <Col xs={12}>
                <div className="responsive-table-content">
                  <Table className="table-middle" striped>
                    <thead>
                      <tr>
                        <th>Počet</th>
                        <th>MJ</th>
                        <th>Popis</th>
                        <th>Cena za MJ</th>
                        <th>DPH</th>
                      </tr>
                    </thead>
                    <tbody>
                      {formik.values.auctionInvoiceItems.map((item, index) => (
                        <tr key={`list-item-${index}`}>
                          <td>
                            <FormGroup
                              type="number"
                              name={`auctionInvoiceItems[${index}].items`}
                              className="mt-0 mb-0"
                              value={item.items}
                              onChange={formik.handleChange}
                            />
                          </td>
                          <td>{item.unitTranslation}</td>
                          <td>{item.serviceTypeTranslation}</td>
                          <td>
                            <div className="d-flex align-items-center">
                              <FormGroup
                                type="number"
                                name={`auctionInvoiceItems[${index}].unitPrice`}
                                className="mt-0 mb-0"
                                value={item.unitPrice}
                                onChange={formik.handleChange}
                              />
                              <span className="f-size-12 f-weight-400 ml-2">Kč</span>
                            </div>
                          </td>
                          <td>
                            <div className="table-select">
                              <Select
                                size="md"
                                options={vatOptions}
                                value={vatOptions.find((i) => i.value === item.vat) || null}
                                onChange={(val) => handleVatChange(val, index)}
                              />
                            </div>
                          </td>
                          <td>
                            <Link
                              to="/"
                              className="d-inline-flex align-items-center ml-4"
                              onClick={(e) => handleDeleteClick(e, index)}
                            >
                              <img src={colorIcoDel} alt="ico" className="mr-2" />
                              Smazat
                            </Link>
                          </td>
                        </tr>
                      ))}
                      {formik.values.auctionInvoiceItems.length < 1 && (
                        <tr>
                          <td className="text-left" colSpan={100}>
                            Nebyla nalezena žádná data.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <Button
                  type="button"
                  variant="btn-outline-primary"
                  className="float-right"
                  onClick={() => setShowModal(true)}
                >
                  Přidat službu
                </Button>
              </Col>
            </Row>
          </div>
          <div className="mt-5">
            {!formik.isSubmitting ? (
              <Button
                type="submit"
                variant="btn-outline-primary"
                className="float-right"
                disabled={formik.isSubmitting}
              >
                Uložit fakturu
              </Button>
            ) : (
              <BasePreloader size={29} className="d-inline-block float-right" />
            )}
            <div className="clearfix" />
          </div>
        </Form>
      </div>
      <InvoiceServiceModal
        onRequestClose={() => setShowModal(false)}
        setFieldValue={formik.setFieldValue}
        title="Přidat službu"
        isOpen={showModal}
        values={formik.values}
      />
    </div>
  );
};
