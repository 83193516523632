import { FC, useState } from 'react';
import moment from 'moment';
import { useFormik } from 'formik';
import fileDownload from 'js-file-download';

import { Button } from '@components';
import { InvoicesFilters } from '@types';
import { useMediaApi } from '@api/media';
import { useInvoiceApi } from '@api/invoice';

import { InvoiceExportModal } from './InvoiceExportModal';

export type InvoiceExportType = 'INVOICE_NUMBER_RANGE' | 'INVOICE_DATE' | 'INVOICE_DATE_RANGE' | 'QUERY_FILTER';
type InvoiceFormatType = 'pdf' | 'xlsx' | 'xml_pohoda';

const getFormatTitle = (format: InvoiceFormatType | undefined): string => {
  if (format === 'pdf') {
    return 'PDF';
  } else if (format === 'xlsx') {
    return 'Excelu';
  }
  return 'Pohody';
};

type Props = {
  query?: InvoicesFilters;
};

export const InvoiceExports: FC<Props> = (props) => {
  const invoiceApi = useInvoiceApi();
  const mediaApi = useMediaApi();
  const [exportType, setExportType] = useState<InvoiceExportType>('INVOICE_NUMBER_RANGE');
  const [formatType, setFormatType] = useState<InvoiceFormatType | undefined>(undefined);
  const [activeExport, setActiveExport] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      invoiceNumberMin: 0,
      invoiceNumberMax: 0,
      invoiceDate: undefined,
      ymdMin: '',
      ymdMax: '',
    },
    onSubmit: () => handleSubmit(),
  });

  const handleSubmit = async () => {
    if (exportType === 'QUERY_FILTER' && formatType) {
      const response = await invoiceApi.exportByQuery(formatType, props.query || {});
      mediaApi
        .detail(response.data.data.hash, true)
        .then((res) => {
          fileDownload(res.data, response.data.data.originalName);
        })
        .catch((err) => {
          if (invoiceApi.isCancel(err)) {
            return;
          }
          console.error(err);
        });
    }
    if (exportType === 'INVOICE_NUMBER_RANGE' && formatType) {
      const response = await invoiceApi.exportByInvoiceNumber(
        formatType,
        formik.values.invoiceNumberMin,
        formik.values.invoiceNumberMax
      );
      mediaApi
        .detail(response.data.data.hash, true)
        .then((res) => {
          fileDownload(res.data, response.data.data.originalName);
        })
        .catch((err) => {
          if (invoiceApi.isCancel(err)) {
            return;
          }
          console.error(err);
        });
    }
    if (exportType === 'INVOICE_DATE' && formatType) {
      const year = moment(formik.values.invoiceDate).year();
      const month = moment(formik.values.invoiceDate).month() + 1;
      const response = await invoiceApi.exportByInvoiceDate(formatType, year, month);
      mediaApi
        .detail(response.data.data.hash, true)
        .then((res) => {
          fileDownload(res.data, response.data.data.originalName);
        })
        .catch((err) => {
          if (invoiceApi.isCancel(err)) {
            return;
          }
          console.error(err);
        });
    }
    if (exportType === 'INVOICE_DATE_RANGE' && formatType) {
      const response = await invoiceApi.exportByInvoiceDateRange(
        formatType,
        formik.values.ymdMin,
        formik.values.ymdMax
      );
      mediaApi
        .detail(response.data.data.hash, true)
        .then((res) => {
          fileDownload(res.data, response.data.data.originalName);
        })
        .catch((err) => {
          if (invoiceApi.isCancel(err)) {
            return;
          }
          console.error(err);
        });
    }
  };

  const handleOpenExportModal = (format: InvoiceFormatType) => {
    setFormatType(format);
    setActiveExport(true);
  };

  return (
    <>
      <InvoiceExportModal
        title={`Export faktur do ${getFormatTitle(formatType)}`}
        setExportType={setExportType}
        exportType={exportType}
        isOpen={activeExport}
        values={formik.values}
        errors={formik.errors}
        onCancel={() => setActiveExport(false)}
        isSubmitting={formik.isSubmitting}
        handleChange={formik.handleChange}
        handleSubmit={formik.handleSubmit}
        setFieldValue={formik.setFieldValue}
      />
      <div className="invoice-exports">
        <Button onClick={() => handleOpenExportModal('xlsx')}>Exportovat do Excelu</Button>
        <Button onClick={() => handleOpenExportModal('pdf')}>Exportovat do PDF</Button>
        <Button onClick={() => handleOpenExportModal('xml_pohoda')}>Exportovat do Pohody</Button>
      </div>
    </>
  );
};
