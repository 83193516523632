import React, { useEffect } from 'react';
import { BooleanParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import * as routes from '@routes';
import { AdminProgressFormMenu, BasePage } from '@components';

import SectionInvoices from './components/SectionInvoices';
import SectionPayments from './components/SectionPayments';

export enum InvoicesSections {
  invoices = 'invoices',
  payments = 'payments',
}

const InvoicesPage: React.FC = () => {
  const [query, setQuery] = useQueryParams({
    section: withDefault(StringParam, InvoicesSections.invoices),
    page: withDefault(NumberParam, 1),
    perPage: withDefault(NumberParam, 10),
    paid: withDefault(BooleanParam, undefined),
    invoiceNumber: withDefault(StringParam, undefined),
    enforcement: withDefault(BooleanParam, undefined),
    auctioneer: withDefault(StringParam, undefined),
    auction: withDefault(StringParam, undefined),
    auctioneerType: withDefault(StringParam, undefined),
    senderName: withDefault(StringParam, undefined),
    paymentType: withDefault(StringParam, undefined),
    variableSymbol: withDefault(StringParam, undefined),
    senderAccountNumber: withDefault(StringParam, undefined),
    dateOfIssueFrom: withDefault(StringParam, undefined),
    dateOfIssueTo: withDefault(StringParam, undefined),
  });

  const navItems = [
    {
      label: 'Faktury',
      url: InvoicesSections.invoices,
      isActive: query.section === InvoicesSections.invoices,
    },
    {
      label: 'Platby',
      url: InvoicesSections.payments,
      isActive: query.section === InvoicesSections.payments,
    },
  ];

  useEffect(() => {
    window?.scrollTo(0, 0);
  }, []);

  const getSectionTitle = () => navItems.find((i) => i.url === query.section)?.label;

  const renderContent = () => {
    switch (query.section) {
      case InvoicesSections.payments:
        return <SectionPayments />;
      default:
        return <SectionInvoices />;
    }
  };

  return (
    <BasePage page={routes.admin.INVOICES} title={getSectionTitle()}>
      <AdminProgressFormMenu
        onClick={(state?: string | number) => {
          setQuery(() => ({
            section: state?.toString(),
            page: undefined,
            perPage: undefined,
            paid: undefined,
            invoiceNumber: undefined,
            enforcement: undefined,
            auctioneer: undefined,
            auctioneerType: undefined,
            auction: undefined,
            senderName: undefined,
            paymentType: undefined,
            variableSymbol: undefined,
            senderAccountNumber: undefined,
            dateOfIssueFrom: undefined,
            dateOfIssueTo: undefined,
          }));
        }}
        items={navItems}
      />
      {renderContent()}
    </BasePage>
  );
};

export default InvoicesPage;
