import React from 'react';
import { useHistory } from 'react-router-dom';

import alertIco from '@assets/images/front/account/alert.svg';
import iconHammerColor19 from '@assets/images/front/icons/hammer-color-19.svg';
import iconHammerWhite19 from '@assets/images/front/icons/hammer-white-19.svg';
import iconHammerGrey19 from '@assets/images/front/icons/hammer-grey-19.svg';
import iconHammerGreyer19 from '@assets/images/front/icons/hammer-greyer-19.svg';

import * as routes from '@routes';
import { useAuctionsApi } from '@api/auctions';
import BeforeJoinModal from './BeforeJoinModal';
import { useAuthFrontStore, usePageStore } from '@stores';
import { BaseAccountPage, BasePreloader, AuctionTile, useVisibility } from '@components';
import { AuctionResponse, UserAuctionResponse, UserRole, AccountAuctionsSections, AuctionStatus } from '@types';
import { AuctionInformationsSections } from '../AccountAuctionInformations/AccountAuctionInformations';

let auctionsTimeout: NodeJS.Timer | undefined = undefined;

const AccountFavouriteAuctionsPage: React.FC = () => {
  const history = useHistory();
  const { isLogged } = useAuthFrontStore();
  const auctionsApi = useAuctionsApi();
  const pageState = usePageStore();
  const { shouldBeVisible } = useVisibility();
  const [items, setItems] = React.useState<Array<UserAuctionResponse>>([]);
  const [joinedLoaded, setJoinedLoaded] = React.useState<string | number | undefined>();
  const [loaded, setLoaded] = React.useState(false);
  const [beforeJoin, setBeforeJoin] = React.useState<{
    content: React.ReactNode;
    actionText: string;
    onAction: () => void;
  } | null>(null);

  React.useEffect(() => {
    loadAuctions();
    return () => {
      auctionsApi.cancelAllRequests();
      if (!!auctionsTimeout) {
        clearTimeout(auctionsTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAuctions = async () => {
    if (!!auctionsTimeout) {
      clearTimeout(auctionsTimeout);
    }
    try {
      const response = await auctionsApi.getFavouriteAuctions({
        page: 1,
        perPage: 15,
      });
      setItems(response.data.data);
      setLoaded(true);
      auctionsTimeout = setTimeout(() => loadAuctions(), 10000);
    } catch (err) {
      if (!err.response) {
        return;
      }
      setLoaded(true);
    }
  };

  const handleAuctionJoin = async (auction: AuctionResponse) => {
    if (!!joinedLoaded) {
      return;
    }
    setJoinedLoaded(auction.id);
    try {
      await auctionsApi.joinAuction(auction.id);
      setJoinedLoaded(undefined);
      history.push(`${pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS)}?s=${AccountAuctionsSections.prepared}`);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      setJoinedLoaded(undefined);
    }
  };

  const handleRemoveAuctionFromFavourites = async (auction: AuctionResponse) => {
    try {
      await auctionsApi.removeAuctionFromFavourites(auction.id);
      loadAuctions();
    } catch (err) {}
  };

  const getJoinText = (auction: AuctionResponse) => {
    if (auction.joinAuctionButtonText) {
      return auction.joinAuctionButtonText;
    }
    if (auction.parentStatus === AuctionStatus.ongoing) {
      return 'Dražba/aukce již probíhá';
    }
    if (auction.parentStatus === AuctionStatus.ended) {
      return 'Dražba/aukce již proběhla';
    }
    if (!auction.canJoin) {
      return 'Nelze se připojit';
    }
    return 'Připojit se k dražbě/aukci';
  };

  const enableJoinAuction = shouldBeVisible({
    isLoggedFront: true,
    role: UserRole.buyer,
    disableSuperAdminOverride: true,
  });

  return (
    <BaseAccountPage page={routes.front.ACCOUNT_FAVOURITES}>
      <BeforeJoinModal isOpen={!!beforeJoin} onRequestClose={() => setBeforeJoin(null)} {...(beforeJoin as any)} />
      <div className="component-front-auctions-list mt-0">
        <div className="list-items">
          {loaded ? (
            items.map((item, index) => (
              <AuctionTile
                key={`${index}-${item.auction.id}`}
                auction={item.auction}
                primaryActionLoader={joinedLoaded === item.auction.id}
                primaryActionVariant={
                  !enableJoinAuction || (!item.auction.isJoined && !item.auction.canJoin)
                    ? 'btn-outline-grey'
                    : undefined
                }
                primaryActionClassName={
                  !item.auction.isJoined &&
                  item.auction.canJoin &&
                  (item.auction.parentStatus === AuctionStatus.ongoing ||
                    item.auction.parentStatus === AuctionStatus.ended)
                    ? 'cursor-default'
                    : 'cursor-pointer'
                }
                primaryActionText={
                  (enableJoinAuction && item.auction.parentStatus === AuctionStatus.prepared && item.auction.canJoin) ||
                  item.auction.isJoined ? (
                    <>
                      <img src={iconHammerColor19} alt="icon" className="mr-13 hover-hide" />
                      <img src={iconHammerWhite19} alt="icon" className="mr-13 hover-show" />
                      {item.auction.isJoined ? `Již jste připojen` : item.auction.joinAuctionButtonText}
                    </>
                  ) : (
                    <>
                      <img src={iconHammerGrey19} alt="icon" className="mr-13 hover-hide" />
                      <img src={iconHammerGreyer19} alt="icon" className="mr-13 hover-show" />
                      {getJoinText(item.auction)}
                    </>
                  )
                }
                onPrimaryActionClick={() => {
                  if (enableJoinAuction && item.auction.canJoin) {
                    if (!item.auction.isJoined) {
                      handleAuctionJoin(item.auction);
                      return;
                    }
                  }

                  if (
                    !item.auction.isJoined &&
                    (item.auction.parentStatus === AuctionStatus.ongoing ||
                      item.auction.parentStatus === AuctionStatus.ended)
                  ) {
                    return;
                  }

                  if (item.auction.isJoined && item.auction.parentStatus === AuctionStatus.prepared) {
                    history.push(
                      `${pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS_INFORMATIONS, {
                        ':id': item.auction.id,
                      })}?s=${AuctionInformationsSections.terms}`
                    );
                    return;
                  }

                  if (item.auction.isJoined && item.auction.parentStatus === AuctionStatus.ended) {
                    history.push(
                      `${pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS_INFORMATIONS, {
                        ':id': item.auction.id,
                      })}?s=${AuctionInformationsSections.result}`
                    );
                    return;
                  }

                  if (item.auction.isJoined && item.auction.parentStatus === AuctionStatus.ongoing) {
                    history.push(
                      `${pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS_INFORMATIONS, {
                        ':id': item.auction.id,
                      })}?s=${AuctionInformationsSections.process}`
                    );
                    return;
                  }

                  if (isLogged && item.auction.canJoin) {
                    setBeforeJoin({
                      content: 'Pro aktivní účast v dražbě/aukci je potřeba dokončit registraci dražitele.',
                      actionText: 'Dokončit registraci dražitele',
                      onAction: () => {
                        setBeforeJoin(null);
                        history.push(pageState.getPagePath(routes.front.ACCOUNT_AUCTIONEER_REGISTRATION));
                      },
                    });
                    return;
                  }
                }}
                onIsFavouriteChange={() => handleRemoveAuctionFromFavourites(item.auction)}
                disableFooter
                isFavorite
              />
            ))
          ) : (
            <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
              <BasePreloader />
            </div>
          )}
        </div>
        {loaded && items.length < 1 && (
          <div className="auctions-empty mt-0" data-test-id="emptyFavoriteAuctions">
            <img src={alertIco} alt="ico" className="item-ico" />
            Nemáte žádné oblíbené dražby
          </div>
        )}
      </div>
    </BaseAccountPage>
  );
};

export default AccountFavouriteAuctionsPage;
