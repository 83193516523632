import React from 'react';
import _ from 'lodash';
import { Form } from 'react-bootstrap';
import { FormikValues, useFormik } from 'formik';

import { useLocationApi } from '@api/location';
import { useAuctioneersApi } from '@api/auctioneers';
import { AddressType, AuctioneerAddress, BaseSelectType } from '@types';
import { Select, BasePreloader, ControlFeedback, FormGroup, Button, MapAddressModal } from '@components';

interface Props {
  id: string;
  data?: AuctioneerAddress;
  mainAddress?: AuctioneerAddress;
  onSubmit: () => void;
  onClose: () => void;
  onSave: () => void;
}

const AddressForm: React.FC<Props> = (props) => {
  const locationApi = useLocationApi();
  const auctioneersApi = useAuctioneersApi();

  const [showMapModal, setShowMapModal] = React.useState(false);
  const [countryOptions, setCountryOptions] = React.useState<BaseSelectType[]>([]);
  const [regionOptions, setRegionOptions] = React.useState<BaseSelectType[]>([]);
  const [districtOptions, setDistrictOptions] = React.useState<BaseSelectType[]>([]);
  const [districtPartOptions, setDistrictPartOptions] = React.useState<BaseSelectType[]>([]);
  const [isDistrictOptionsLoading, setIsDistrictOptionsLoading] = React.useState(false);
  const [isDistrictPartOptionsLoading, setIsDistrictPartOptionsLoading] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      name: props.data?.name,
      country: props.data?.country?.id || '',
      region: props.data?.region?.id || '',
      district: props.data?.district?.id || '',
      districtPart: props.data?.districtPart?.id || '',
      street: props.data?.street || '',
      houseNumber: props.data?.houseNumber || '',
      city: props.data?.city || '',
      zipCode: props.data?.zipCode || '',
      ruian: props.data?.ruian || '',
      gps: props.data?.gps || '',
      cadastralArea: props.data?.cadastralArea || '',
    },
    onSubmit: (values) => handleSubmit(values),
  });

  React.useEffect(() => {
    loadCountryOptions();

    if (formik.values.country) {
      loadRegionOptions(formik.values.country.toString());
    }
    if (formik.values.region) {
      loadDistrictOptions(formik.values.region.toString());
    }
    return () => auctioneersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (formik.values.country) {
      loadRegionOptions(formik.values.country.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.country]);

  React.useEffect(() => {
    if (formik.values.region) {
      loadDistrictOptions(formik.values.region.toString());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.region]);

  React.useEffect(() => {
    if (formik.values.district) {
      loadDistrictPartsOptions(formik.values.district.toString());
    } else {
      setDistrictPartOptions([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.district]);

  const loadCountryOptions = async () => {
    try {
      const response = await locationApi.getCountries();
      setCountryOptions(
        response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
      if (!formik.values.country && response.data.data.length > 0) {
        formik.setFieldValue('country', response.data.data[0].id);
      }
    } catch (err) {
      if (locationApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadRegionOptions = async (countryId: string) => {
    try {
      const response = await locationApi.getRegions(countryId);
      setRegionOptions(
        response.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (err) {}
  };

  const loadDistrictOptions = async (regionId: string) => {
    setIsDistrictOptionsLoading(true);

    try {
      const respose = await locationApi.getDistricts(regionId);
      setDistrictOptions(
        respose.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (err) {
    } finally {
      setIsDistrictOptionsLoading(false);
    }
  };

  const loadDistrictPartsOptions = async (districtId: string) => {
    if (!districtId) {
      setDistrictPartOptions([]);
    }
    setIsDistrictPartOptionsLoading(true);
    try {
      const respose = await locationApi.getDistrictParts(districtId);
      setDistrictPartOptions(
        respose.data.data.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    } catch (err) {
      if (locationApi.isCancel(err)) {
        return;
      }
    } finally {
      setIsDistrictPartOptionsLoading(false);
    }
  };

  const handleSubmit = (values: FormikValues) => {
    const inputs = { ...values };
    _.set(inputs, 'gps', values.gps.replace(/\s/g, ''));
    (!!props.data
      ? auctioneersApi.establishmentUpdate(props.id, props.data?.id.toString() || '', inputs)
      : auctioneersApi.establishmentCreate(props.id, inputs)
    )
      .then(() => {
        props.onSave();
      })
      .catch((err) => {
        if (auctioneersApi.isCancel(err)) {
          return;
        }
        const errors = err.response?.data?.errors || {};
        Object.getOwnPropertyNames(errors).map((prop) => {
          formik.setFieldError(prop, errors[prop][0]);
          return prop;
        });
        formik.setSubmitting(false);
      });
  };

  const handleCountryChange = (value: any) => {
    formik.setFieldValue('country', value?.value || '');

    if (formik.values.country !== value?.value) {
      formik.setFieldValue('region', '');
      formik.setFieldValue('district', '');
    }
  };

  const handleRegionChange = (value: any) => {
    formik.setFieldValue('region', value?.value || '');

    if (formik.values.region !== value?.value) {
      formik.setFieldValue('district', '');
      formik.setFieldValue('districtPart', '');
    }
  };

  const handleDistrictChange = (value: any) => {
    formik.setFieldValue('district', value?.value || '');

    if (formik.values.district !== value?.value) {
      formik.setFieldValue('districtPart', value?.value || '');
    }
  };

  const handleDistrictPartChange = (value: any) => {
    formik.setFieldValue('districtPart', value?.value || '');
  };

  const handleSelectFromMap = (v: AddressType) => {
    formik.setFieldValue('gps', v.addressGps);
    formik.setFieldValue('city', v.addressCity);
    formik.setFieldValue('ruian', v.addressRuian);
    formik.setFieldValue('zipCode', v.addressZipCode);
    formik.setFieldValue('street', v.addressStreet);
    formik.setFieldValue('houseNumber', v.addressHouseNumber);
    formik.setFieldValue('cadastralArea', v.addressCadastral);
    formik.setFieldValue('country', v.addressCountry ? parseInt(v.addressCountry) : undefined);
    formik.setFieldValue('region', v.addressRegion ? parseInt(v.addressRegion) : undefined);
    formik.setFieldValue('district', v.addressDistrict ? parseInt(v.addressDistrict) : undefined);
    formik.setFieldValue('districtPart', v.addressDistrictPart ? parseInt(v.addressDistrictPart) : undefined);
    setShowMapModal(false);
  };

  return (
    <div>
      <h2>{!!props.data ? 'Upravit provozovnu' : 'Přidat provozovnu'}</h2>

      <div className="mt-4">
        <Button type="button" onClick={() => setShowMapModal(true)}>
          Vybrat z mapy
        </Button>
      </div>

      <div>
        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-5">
          <div className="responsive-table-content">
            <div className="pt-1">
              <FormGroup
                required
                type="text"
                name="name"
                label="Název"
                error={formik.errors.name}
                value={formik.values.name || ''}
                onChange={formik.handleChange}
              />

              <Form.Group className="f-inline-group">
                <Form.Label className="f-inline-label text-right">Stát *</Form.Label>
                <div className="f-inline-control">
                  <div className="w-max-500" data-test-id="admin-auctioneer-address-form-country">
                    <Select
                      size="md"
                      isInvalid={!!formik.errors.country}
                      options={countryOptions}
                      value={countryOptions.find((i) => i.value === formik.values.country) || null}
                      onChange={handleCountryChange}
                    />
                    {!!formik.errors.country && (
                      <ControlFeedback type="invalid">{formik.errors.country}</ControlFeedback>
                    )}
                  </div>
                </div>
              </Form.Group>

              <Form.Group className="f-inline-group">
                <Form.Label className="f-inline-label text-right">Kraj *</Form.Label>
                <div className="f-inline-control">
                  <div className="w-max-500" data-test-id="admin-auctioneer-address-form-region">
                    <Select
                      size="md"
                      isInvalid={!!formik.errors.region}
                      options={regionOptions}
                      value={regionOptions.find((i) => i.value === formik.values.region) || null}
                      onChange={handleRegionChange}
                    />
                    {!!formik.errors.region && <ControlFeedback type="invalid">{formik.errors.region}</ControlFeedback>}
                  </div>
                </div>
              </Form.Group>

              <Form.Group className="f-inline-group">
                <Form.Label className="f-inline-label text-right">Okres *</Form.Label>
                <div className="f-inline-control">
                  <div className="w-max-500" data-test-id="admin-auctioneer-address-form-district">
                    <Select
                      size="md"
                      isInvalid={!!formik.errors.district}
                      options={districtOptions}
                      value={districtOptions.find((i) => i.value === formik.values.district) || null}
                      onChange={handleDistrictChange}
                      isLoading={isDistrictOptionsLoading}
                    />
                    {!!formik.errors.district && (
                      <ControlFeedback type="invalid">{formik.errors.district}</ControlFeedback>
                    )}
                  </div>
                </div>
              </Form.Group>

              {districtPartOptions.length > 0 && (
                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label text-right">Městská část</Form.Label>
                  <div className="f-inline-control">
                    <div className="w-max-500" data-test-id="admin-auctioneer-address-form-district-part">
                      <Select
                        size="md"
                        isClearable
                        isInvalid={!!formik.errors.districtPart}
                        options={districtPartOptions}
                        value={districtPartOptions.find((i) => i.value === formik.values.districtPart) || null}
                        onChange={handleDistrictPartChange}
                        isLoading={isDistrictPartOptionsLoading}
                      />
                      {!!formik.errors.districtPart && (
                        <ControlFeedback type="invalid">{formik.errors.districtPart}</ControlFeedback>
                      )}
                    </div>
                  </div>
                </Form.Group>
              )}

              <FormGroup
                required
                type="text"
                name="city"
                label="Město"
                error={formik.errors.city}
                value={formik.values.city || ''}
                onChange={formik.handleChange}
              />

              <FormGroup
                required
                type="text"
                name="street"
                label="Ulice"
                error={formik.errors.street}
                value={formik.values.street || ''}
                onChange={formik.handleChange}
              />

              <FormGroup
                required
                type="text"
                name="houseNumber"
                label="Č.p. / Č.o."
                error={formik.errors.houseNumber}
                value={formik.values.houseNumber || ''}
                onChange={formik.handleChange}
              />

              <FormGroup
                required
                type="text"
                name="zipCode"
                label="PSČ"
                error={formik.errors.zipCode}
                value={formik.values.zipCode || ''}
                onChange={formik.handleChange}
              />

              <FormGroup
                name="cadastralArea"
                labelClassName="text-right"
                label="Katastrální pracoviště"
                error={formik.errors.cadastralArea as string}
                value={formik.values.cadastralArea}
                onChange={formik.handleChange}
              />

              <FormGroup
                name="ruian"
                label="RUIAN"
                type="number"
                labelClassName="text-right"
                error={formik.errors.ruian as string}
                value={formik.values.ruian}
                onChange={formik.handleChange}
              />

              <FormGroup
                required
                name="gps"
                label="GPS"
                helpText={
                  <div>
                    GPS souřadnice ve formátu
                    <br />
                    [00.000000, 00.000000]
                  </div>
                }
                labelClassName="text-right"
                error={formik.errors.gps as string}
                value={formik.values.gps}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="mt-5">
            <Button variant="btn-outline-default" className="float-left" type="button" onClick={() => props.onClose()}>
              Zrušit
            </Button>
            {!formik.isSubmitting ? (
              <Button
                type="submit"
                variant="btn-outline-primary"
                className="float-right"
                disabled={formik.isSubmitting}
              >
                Uložit provozovnu
              </Button>
            ) : (
              <BasePreloader size={29} className="d-inline-block float-right" />
            )}
            <div className="clearfix" />
          </div>
        </Form>
      </div>

      {showMapModal && (
        <MapAddressModal
          isOpen={showMapModal}
          gps={formik.values.gps}
          onSelect={handleSelectFromMap}
          onClose={() => setShowMapModal(false)}
        />
      )}
    </div>
  );
};

export default AddressForm;
