export const BASE = 'admin';
export const AUCTIONEER_LIST = `${BASE}-auctioneers`;
export const AUCTIONEER_CREATE = `${BASE}-auctioneer-create`;
export const AUCTIONEER_UPDATE = `${BASE}-auctioneer-update`;
export const MY_COMPANY_LIST = `${BASE}-my-company`;
export const MY_COMPANY_UPDATE = `${BASE}-my-company-detail`;
export const AUCTIONS_LIST = `${BASE}-auctions`;
export const AUCTIONS_CREATE = `${BASE}-auctions-create`;
export const AUCTIONS_UPDATE = `${BASE}-auctions-update`;
export const AUCTIONS_ACTIVE_DETAIL = `${BASE}-auctions-active-detail`;
export const INVOICES = `${BASE}-invoices`;
export const INVOICES_UPDATE = `${BASE}-invoices-update`;
export const CASES = `${BASE}-cases`;
export const DOCUMENTS = `${BASE}-documents`;
export const DOCUMENTS_AND_SECURITY = `${BASE}-documents-and-security`;
export const SETTINGS = `${BASE}-settings`;
export const SETTINGS_INFORMATIONS = `${BASE}-informations`;
export const SETTINGS_INFORMED_CONSENTS = `${BASE}-informed-consents`;
export const SETTINGS_PRICE_LIST = `${BASE}-price-list`;
export const SETTINGS_ADMINISTRATORS = `${BASE}-administrators`;
export const SETTINGS_NEWS = `${BASE}-news`;
export const SETTINGS_BILLING_INFORMATIONS = `${BASE}-billing-informations`;
export const SETTINGS_OTHER = `${BASE}-other`;
export const SETTINGS_TEXT_BLOCK = `${BASE}-text-block`;
export const SETTINGS_TEXT_BLOCK_CREATE = `${BASE}-text-block-create`;
export const SETTINGS_TEXT_BLOCK_UPDATE = `${BASE}-text-block-update`;
export const SETTINGS_TEXT_BLOCK_CONTENT_CREATE = `${BASE}-text-block-content-create`;
export const SETTINGS_TEXT_BLOCK_CONTENT_UPDATE = `${BASE}-text-block-content-update`;
export const SETTINGS_ADMINISTRATORS_CREATE = `${BASE}-administrator-create`;
export const SETTINGS_ADMINISTRATORS_UPDATE = `${BASE}-administrator-update`;
export const SETTINGS_NEWS_CREATE = `${BASE}-news-create`;
export const SETTINGS_NEWS_UPDATE = `${BASE}-news-update`;
export const SETTINGS_PAGES_LIST = `${BASE}-pages-list`;
export const SETTINGS_PAGES_DETAIL = `${BASE}-pages-detail`;
export const SETTINGS_PAGES_CREATE = `${BASE}-pages-create`;
export const SETTINGS_PAGES_BLOCK_CREATE = `${BASE}-pages-block-create`;
export const SETTINGS_PAGES_BLOCK_DETAIL = `${BASE}-pages-block-detail`;
export const USER_LIST = `${BASE}-buyers`;
export const USER_CREATE = `${BASE}-buyer-create`;
export const USER_DETAIL = `${BASE}-buyer-detail`;
export const USER_UPDATE = `${BASE}-buyer-update`;
export const USER_DOCUMENTS = `${BASE}-buyer-documents`;
export const LOG = `${BASE}-log`;
export const STATISTICS = `${BASE}-statistics`;
