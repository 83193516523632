import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useFormik } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { usePagesApi } from '@api/pages';
import Wysiwyg from '@components/Wysiwyg/Wysiwyg';
import { BasePreloader, Button, FormGroup } from '@components';

interface ParamsType {
  pageId: string;
  blokId: string;
}

const PageBlockForm: React.FC = () => {
  const history = useHistory();
  const { blokId, pageId } = useParams<ParamsType>();
  const pagesApi = usePagesApi();
  const pageState = usePageStore();
  const [isLoaded, setIsLoaded] = useState<boolean>(!blokId);
  const page = pageState.getPage(
    !blokId ? routes.admin.SETTINGS_PAGES_BLOCK_CREATE : routes.admin.SETTINGS_PAGES_BLOCK_DETAIL
  );
  const formik = useFormik({
    enableReinitialize: false,
    initialValues: {
      name: '',
      content: '',
      pageOrder: 0,
      type: 'content',
    },
    onSubmit: () => handleSubmit(),
  });

  useEffect(() => {
    formik.setValues(formik.initialValues);
    setIsLoaded(!blokId);
    if (blokId) {
      loadData();
    }
    return () => {
      pagesApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blokId]);

  const loadData = async () => {
    if (!blokId) {
      formik.setValues(formik.initialValues);
      return;
    }
    try {
      const response = await pagesApi.getAdminPageBlockDetail(pageId, blokId);
      const result = _.omit(response.data.data, ['id']);
      formik.setValues(result);
      setIsLoaded(true);
    } catch (err) {
      if (pagesApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const handleSubmit = () => {
    if (blokId) {
      blockUpdate();
    } else {
      blockCreate();
    }
  };

  const blockUpdate = async () => {
    try {
      await pagesApi.getAdminPageBlockUpdate(pageId, blokId, formik.values);
      formik.setSubmitting(false);
    } catch (err) {
      if (pagesApi.isCancel(err)) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
  };

  const blockCreate = async () => {
    try {
      const response = await pagesApi.getAdminPageBlockCreate(pageId, formik.values);
      formik.setSubmitting(false);
      history.push(
        pageState.getPagePath(routes.admin.SETTINGS_PAGES_BLOCK_DETAIL, {
          ':pageId': pageId,
          ':blokId': response.data.data.id,
        })
      );
    } catch (err) {
      if (pagesApi.isCancel(err)) {
        return;
      }
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
  };

  if (!isLoaded) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <div>
      <h2 className="f-size-25 f-weight-300 mr-4 mb-0 mt-0">{page?.name}</h2>
      <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-5">
        <div className="responsive-table-content">
          <div className="pt-1">
            <FormGroup
              required
              label="Název"
              name="name"
              labelClassName="text-left"
              error={formik.errors.name}
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            <Wysiwyg
              name="content"
              label="Obsah"
              required={true}
              value={formik.values.content}
              onChange={(val) => formik.setFieldValue('content', val)}
            />
            <FormGroup
              required
              type="number"
              label="Pořadí"
              name="pageOrder"
              labelClassName="text-left"
              error={formik.errors.pageOrder}
              value={formik.values.pageOrder}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <Row>
          <Col xs={12} className="mt-4 text-right">
            {!formik.isSubmitting ? (
              <Button variant="btn-outline-primary" type="submit" disabled={formik.isSubmitting}>
                Uložit
              </Button>
            ) : (
              <BasePreloader size={29} className="d-inline-block" />
            )}
          </Col>
        </Row>
      </Form>

      <div className="mt-5">
        <Button to={pageState.getPagePath(routes.admin.SETTINGS_PAGES_DETAIL, { ':id': pageId })}>
          Zpět na detail stránky
        </Button>
      </div>
    </div>
  );
};

export default PageBlockForm;
