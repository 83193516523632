import React, { useCallback, useEffect, useState } from 'react';
import clx from 'classnames';
import ReactDOM from 'react-dom';
import { get } from 'lodash';
import classNames from 'classnames';
import { ToastProvider } from 'react-toast-notifications';
import { useHistory, useLocation } from 'react-router-dom';

import { AuctionMapResponse, LatLng } from '@types';
import MapPopup from '@components/MapComponent/MapPopup';
import MapPointsPopup from '@components/MapComponent/MapPointsPopup';

import mapPointImage from '@assets/images/map-point.svg';

interface Props {
  className?: string;
  zoom?: number;
  height?: number;
  auctions?: AuctionMapResponse[];
  displayDetail?: boolean;
  pin?: LatLng;
  colors?: boolean;
}

class CustomCluster extends window.SMap.Marker.Cluster {
  constructor(id: any, options: any) {
    options = options || {};
    options.radius = () => {
      return 20;
    };
    super(id, options);
    this._dom.circle.style.border = '0';
    this._dom.circle.className = 'map-div-icon';
    this._dom.content.className = 'map-cluster';
  }
}

const MapComponent: React.FC<Props> = (props) => {
  const initialCenter = window.SMap.Coords.fromWGS84(15.4749126, 49.8037633);
  const history = useHistory();
  const location = useLocation();
  const [mapInstance, setMapInstance] = useState<any>();
  const [markerLayer, setMarkerLayer] = useState<any>();
  const [markerCluster, setMarkerCluster] = useState<any>();
  const [currentPin, setCurrentPin] = useState<LatLng | undefined>();

  const mapCallback = useCallback(async (node: HTMLDivElement) => {
    if (!node) {
      return;
    }

    const _mapInstance = new window.SMap(node, initialCenter, getInitialZoom());
    _mapInstance.addDefaultControls();
    _mapInstance.addDefaultLayer(window.SMap.DEF_BASE).enable();

    // Disable zoom by mouse wheel
    _mapInstance.getControls().forEach((c: any) => {
      if (c instanceof window.SMap.Control.Mouse) {
        c.configure(window.SMap.MOUSE_PAN | window.SMap.MOUSE_ZOOM);
      }
    });

    const _markerLayer = new window.SMap.Layer.Marker();
    _mapInstance.addLayer(_markerLayer);
    _markerLayer.enable();

    const _markerCluster = new window.SMap.Marker.Clusterer(_mapInstance, 100, CustomCluster);
    _markerLayer.setClusterer(_markerCluster);

    _mapInstance.getSignals().addListener(window, 'map-focus', (e: any) => e.target?.getMap()?.removeCard());
    _mapInstance.getSignals().addListener(window, 'card-close', handleCardClose);
    _mapInstance.getSignals().addListener(window, 'marker-click', handleMarkerClick);

    setMapInstance(_mapInstance);
    setMarkerLayer(_markerLayer);
    setMarkerCluster(_markerCluster);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!!mapInstance) {
      mapInstance.removeCard();
      mapInstance.setCenter(initialCenter);
      mapInstance.setZoom(getInitialZoom());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapInstance, location.search, location.pathname]);

  React.useEffect(() => {
    if (!mapInstance || !markerLayer || !markerCluster) {
      return;
    }

    if (!props.pin) {
      markerLayer.removeAll();
    }

    if (!!props.auctions && !!props.auctions.length) {
      setPoints(props.auctions);
    }

    if (!!props.pin && !(props.pin.lng === currentPin?.lng && props.pin.lat === currentPin?.lat)) {
      markerLayer.removeAll();
      setPoint(props.pin);
    }
    setCurrentPin(props.pin);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapInstance, markerLayer, markerCluster, props.auctions, props.pin]);

  const handleCardClose = (e: any) => {
    const _mapInstance = e.target.getMap();
    if (e.target._isMarker) {
      setTimeout(() => {
        const parentMarkers = _mapInstance._parentMarkers;
        if (!!parentMarkers) {
          showMultipleMarkers(parentMarkers.coords, parentMarkers.markers, _mapInstance);
        }
        _mapInstance._parentMarkers = undefined;
      }, 100);
    }
  };

  const handleMarkerClick = (e: any) => {
    const _mapInstance = e.target.getMap();
    const isCluster = !!e.target.getMarkers;
    if (isCluster && _mapInstance.getZoom() > 17 && e.target.getMarkers().length > 0) {
      showMultipleMarkers(e.target._coords, e.target.getMarkers(), e.target.getMap());
    } else if (!!e.target._auction) {
      renderCardBody(e.target._auction, e.target._card);
      setTimeout(() => e.target._card.makeVisible(), 100);
    }
  };

  const renderCardBody = (auction: AuctionMapResponse, card: any) => {
    const popupContent = (
      <ToastProvider>
        <MapPopup
          history={history}
          id={auction.auction}
          title={auction.title}
          number={auction.number}
          deposit={auction.cautionDeposit}
          auctionType={auction.auctionType}
          city={auction.city}
          translations={auction.translations}
          category={auction.auctionCategoryTitle}
          typeTranslation={auction.auctionTypeTranslation}
          region={auction.region}
          parentCategory={auction.auctionCategoryParentTitle || ''}
          image={auction.mediaHash}
        />
      </ToastProvider>
    );
    ReactDOM.render(popupContent, card.getBody());
  };

  const showMultipleMarkers = (coords: any, markers: any[], _mapInstance: any) => {
    const popupContent = (
      <MapPointsPopup
        auctions={markers.map((m: any) => m._auction)}
        onClick={(auction) => {
          _mapInstance.removeCard();
          markers.forEach((m: any) => {
            if (m._auction?.auction === auction.auction) {
              _mapInstance._parentMarkers = { markers, coords };
              renderCardBody(m._auction, m._card);
              _mapInstance.addCard(m._card, m._coords);
              setTimeout(() => m._card.makeVisible(), 100);
            }
          });
        }}
      />
    );
    const card = new window.SMap.Card();
    card.setSize(530, 'auto');
    card.getBody().className = 'card-body card-overflow';
    ReactDOM.render(popupContent, card.getBody());
    _mapInstance.addCard(card, coords);
    setTimeout(() => card.makeVisible(), 100);
  };

  const getInitialZoom = () => {
    const zoom = props.zoom || 7;
    return zoom <= 18 ? zoom : 18;
  };

  const setPoints = (auctions: AuctionMapResponse[]) => {
    const markers: any[] = [];
    auctions.forEach((auction) => {
      const markerCenter = window.SMap.Coords.fromWGS84(auction.longitude, auction.latitude);
      const card = new window.SMap.Card(null);
      card._isMarker = true;
      card.setSize(281, 'auto');
      // ReactDOM.render(popupContent, card.getBody());
      const marker = new window.SMap.Marker(markerCenter, null, { url: getMarkerEl({ pointer: true }) });
      marker._auction = auction;
      markers.push(marker);
      marker.decorate(window.SMap.Marker.Feature.Card, card);
    });
    markerLayer.addMarker(markers);
  };

  const setPoint = (point: LatLng) => {
    const markerCenter = window.SMap.Coords.fromWGS84(point.lng, point.lat);
    const marker = new window.SMap.Marker(markerCenter, null, { url: getMarkerEl() });
    markerLayer.addMarker(marker);
    mapInstance.setCenter(markerCenter);
  };

  const getMarkerEl = (options?: { pointer: boolean }) => {
    const markerDiv = window.JAK.mel('div');
    markerDiv.className = 'marker';
    if (options?.pointer) {
      markerDiv.className += ' marker-pointer';
    }
    const markerImage = window.JAK.mel('img', { src: mapPointImage }, { width: '40px', height: '40px' });
    markerDiv.appendChild(markerImage);
    return markerDiv;
  };

  return (
    <div className={classNames(['component-map', props.className])}>
      <div
        className={clx(['map', { bw: !props.colors }])}
        ref={mapCallback}
        style={{ height: get(props, 'height', 500) }}
      />
    </div>
  );
};

export default MapComponent;
