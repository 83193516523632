import React, { useState, useEffect, createRef, useCallback } from 'react';
import cx from 'classnames';
import { useDropzone } from 'react-dropzone';
import { useToasts } from 'react-toast-notifications';
import { arrayMove, SortableContainer, SortableElement } from 'react-sortable-hoc';

import config from '@config';
import { applicationStore } from '@stores';
import { fileToBase64 } from '@helpers/files';
import { useAuctionsApi } from '@api/auctions';
import { getFormatBytes } from '@helpers/formats';
import { confirmMessage } from '@helpers/messages';
import { BasePreloader, Button } from '@components';
import { AdminAuctionImageType, BaseType } from '@types';

import MediaModal from './MediaModal';
import VideoModal from './VideoModal';

import plusIco from '@assets/images/plus-ico.svg';
import colorIcoView from '@assets/images/color-ico-eye.svg';
import colorIcoDel from '@assets/images/color-ico-del.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';

interface AuctionImageProps {
  image: ImageType;
  disabled?: boolean;
  onClick: () => void;
  onDelete(event: React.MouseEvent, image: ImageType): void;
}

const AuctionImage = SortableElement(({ image, onDelete, onClick }: AuctionImageProps) => {
  return (
    <div className={cx('admin-auction-item-image')}>
      {image.type.startsWith('image') && (
        <a
          href="/"
          className="item-image-preview"
          onClick={(e) => {
            e.preventDefault();
            onClick();
          }}
        >
          <img src={colorIcoView} alt="del" className="preview-image" />
        </a>
      )}
      {image.type !== 'file' && !image.type.startsWith('image') && (
        <div className="video-icon" onClick={onClick}>
          <svg
            role="img"
            focusable="false"
            data-prefix="far"
            aria-hidden="true"
            viewBox="0 0 512 512"
            data-icon="play-circle"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill="#ddd"
              d="M371.7 238l-176-107c-15.8-8.8-35.7 2.5-35.7 21v208c0 18.4 19.8 29.8 35.7 21l176-101c16.4-9.1 16.4-32.8 0-42zM504 256C504 119 393 8 256 8S8 119 8 256s111 248 248 248 248-111 248-248zm-448 0c0-110.5 89.5-200 200-200s200 89.5 200 200-89.5 200-200 200S56 366.5 56 256z"
            ></path>
          </svg>
        </div>
      )}
      <div
        className="image-content"
        style={{ backgroundImage: `url(${image.src})`, backgroundSize: 'cover' }}
        onClick={onClick}
      />
      <div className="image-action">
        <a href="/" onClick={(e) => onDelete(e, image)} className="d-inline-flex align-items-center">
          <img src={colorIcoDel} alt="ico" className="mr-2" />
          Smazat
        </a>
      </div>
    </div>
  );
});

interface DraggableAuctionImagesProps {
  disabled?: boolean;
  images: Array<ImageType>;
  onDelete(event: React.MouseEvent, image: ImageType): void;
}

const DraggableAuctionImages = SortableContainer(({ images, onDelete, disabled }: DraggableAuctionImagesProps) => {
  const [imageSelected, setImageSelected] = React.useState<AdminAuctionImageType | undefined>(undefined);

  if (images.length < 1) {
    return <div className="no-items">Nebyla nalezena žádná data.</div>;
  }

  return (
    <>
      {!!imageSelected && <MediaModal item={imageSelected} onRequestClose={() => setImageSelected(undefined)} />}
      <div className="images">
        {images.map((image, index) => (
          <AuctionImage
            key={index}
            {...{
              index,
              disabled,
              image: image,
              onDelete: onDelete,
              onClick: () =>
                setImageSelected({
                  src: image.src,
                  type: image.type,
                  srcDetail: image.srcDetail,
                  embedHash: image.embedHash,
                }),
            }}
          />
        ))}
      </div>
    </>
  );
});

interface Props {
  id: string | number;
  getTranslation: (key: string) => string;
}

interface ImageType {
  id: string | number;
  src: string;
  srcDetail?: string;
  type: string;
  embedHash?: string;
}

const AuctionImages: React.FC<Props> = (props) => {
  const { addToast } = useToasts();
  const auctionsApi = useAuctionsApi();
  const [loaded, setLoaded] = useState(false);
  const [saved, setSaved] = useState(true);
  const [images, setImages] = useState<ImageType[]>([]);
  const [showVideoModal, setShowVideoModal] = useState<boolean>(false);
  const inputRef = createRef<HTMLInputElement>();

  const onDrop = useCallback(async (acceptedFiles) => {
    const accept = ['image/jpeg', 'image/gif', 'image/bmp', 'image/png', 'image/webp'];
    setSaved(false);
    for (const i in acceptedFiles) {
      if (accept.some((e) => e === acceptedFiles[i].type)) {
        await saveFile(acceptedFiles[i]);
      }
    }
    setSaved(true);
    setLoaded(false);
    loadImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true });

  useEffect(() => {
    loadImages();
    return () => auctionsApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadImages = async () => {
    try {
      const response = await auctionsApi.getImages(props.id);
      const images: ImageType[] = [];
      for (let i = 0; i < response.data.length; i++) {
        try {
          images.push({
            id: response.data[i].id,
            type: response.data[i].media.type !== 'file' ? response.data[i].media.mimeType : 'file',
            src: `${config.apiBaseUrl}/file/${response.data[i].media.hash}?view=true&size=auction.crop`,
            srcDetail: `${config.apiBaseUrl}/file/${response.data[i].media.hash}?view=true`,
            embedHash: response.data[i].media.embedHash,
          });
        } catch (err) {
          images.push({
            src: '',
            id: response.data[i].id,
            type: response.data[i].media.type !== 'file' ? response.data[i].media.mimeType : 'file',
            embedHash: response.data[i].media.embedHash,
          });
        }
      }
      setImages(images);
      setLoaded(true);
      setSaved(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
      setLoaded(true);
    }
  };

  const handleSelectFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target?.files) {
      return;
    }
    setSaved(false);
    for (let i = 0; i < e.target.files.length; i++) {
      await saveFile(e.target.files[i]);
    }
    setSaved(true);
    setLoaded(false);
    loadImages();
  };

  const handleDeleteClick = (e: React.MouseEvent, item: ImageType) => {
    e.preventDefault();
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat tuto položku?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return new Promise(async (resolve) => {
          try {
            await auctionsApi.deleteImage(props.id, item.id);
            await loadImages();
            resolve(true);
          } catch {
            resolve(true);
          }
        });
      },
    });
  };

  const saveFile = async (file: File) => {
    const maxPostBodySize = applicationStore.getState().systemInfo?.post_max_size;
    if (!!maxPostBodySize && maxPostBodySize < file.size) {
      addToast(`Soubor ${file.name} nesmí být větší než ${getFormatBytes(maxPostBodySize)}`, {
        appearance: 'error',
        autoDismiss: true,
      });
      return;
    }

    try {
      const fileBase64 = await fileToBase64(file as File);
      await auctionsApi.createImage(props.id, {
        type: 'image',
        data: fileBase64,
        mime: file?.type as string,
        image_title: file.name,
        original_name: file.name,
      });
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const handleReorder = async (order: Array<string | number>) => {
    try {
      await auctionsApi.reorderAuctionImages(
        props.id,
        order.map((id, index) => ({
          auctionMediaId: id,
          position: index,
        }))
      );
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const handleVideoModalClose = (reload?: boolean) => {
    if (reload) {
      setLoaded(false);
      loadImages();
    }
    setShowVideoModal(false);
  };

  return (
    <>
      <VideoModal auctionId={props.id} isOpen={showVideoModal} onClose={handleVideoModalClose} />
      <div {...getRootProps()} className="auction-images">
        {' '}
        <div className="item-button d-flex align-items-center">
          <div>
            {isDragActive ? (
              <div className="item-drop">Vložit soubor</div>
            ) : (
              <Button type="button" className="mr-2" onClick={() => inputRef.current?.click()}>
                <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
                <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
                Přidat obrázky
              </Button>
            )}
          </div>
          <Button type="button" className="mr-2" onClick={() => setShowVideoModal(true)}>
            <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
            <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
            Přidat video
          </Button>
          {!saved && <BasePreloader className="image-preloader" size={25} />}
          {saved && (
            <input
              {...getInputProps()}
              multiple
              type="file"
              ref={inputRef}
              className="d-none"
              accept="image/jpeg,image/gif,image/bmp,image/png,image/webp"
              onChange={handleSelectFile}
            />
          )}
        </div>
        <div className="item-help">{props.getTranslation('tab_attachments_images_help_text')}</div>
        {loaded ? (
          <div className="items-list">
            <DraggableAuctionImages
              axis="xy"
              images={images}
              disabled={images.length < 2}
              onDelete={handleDeleteClick}
              shouldCancelStart={(el: BaseType) => {
                const target = el.target;
                if (target.className === 'preview-image') {
                  return true;
                }
                return ['svg', 'button', 'path', 'a'].some((v) => v === target.nodeName.toLowerCase());
              }}
              onSortEnd={({ oldIndex, newIndex }) => {
                const newMedia = arrayMove(images, oldIndex, newIndex);
                if (JSON.stringify(newMedia) !== JSON.stringify(images)) {
                  setImages(newMedia);
                  handleReorder(newMedia.map((m) => m.id));
                }
              }}
            />
          </div>
        ) : (
          <div className="mt-4 mb-4">
            <BasePreloader size={25} />
          </div>
        )}
      </div>
    </>
  );
};

export default AuctionImages;
