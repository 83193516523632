import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { ValueType } from 'react-select';
import { useLocation } from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';

import * as routes from '@routes';
import { useCurrentUserRoleChecks, usePageStore } from '@stores';
import { useAuctionsApi } from '@api/auctions';
import { useAuctioneersApi } from '@api/auctioneers';
import { useAuctionCategoriesApi } from '@api/auctionCategories';
import { getRequestDateFormat } from '@helpers/datetime';
import { DatePickerInput, AdminFiltersBox, Select } from '@components';
import FiltersInputs from '@components/AdminFiltersBox/components/FiltersInputs';
import FiltersSubmit from '@components/AdminFiltersBox/components/FiltersSubmit';
import {
  AuctionAuctionTypeResponse,
  AuctionCategoryReponse,
  Auctioneer,
  AdminAuctionFilters,
  AuctionStatusResponse,
} from '@types';

import inputIcoCalendar from '@assets/images/input-ico-calendar.svg';

interface Props {
  showAuctions: boolean;
  showPublicAuctions: boolean;
  values: AdminAuctionFilters;
  onSubmit: (values: AdminAuctionFilters) => void;
  renderTranslation: (text: string) => string;
}

interface SelectOptionType {
  value: string;
  label: string;
}

const FiltersForm: React.FC<Props> = (props) => {
  const pageState = usePageStore();
  const auctionsApi = useAuctionsApi();
  const auctioneersApi = useAuctioneersApi();
  const currentUserRole = useCurrentUserRoleChecks();
  const auctionCategoriesApi = useAuctionCategoriesApi();
  const location = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [auctioneers, setAuctioneers] = React.useState<Array<Auctioneer>>([]);
  const [auctionTypes, setAuctionTypes] = React.useState<Array<AuctionAuctionTypeResponse>>([]);
  const [auctionStatus, setAuctionStatus] = useState<Array<AuctionStatusResponse>>([]);
  const [auctionCategories, setAuctionCategories] = useState<AuctionCategoryReponse[]>([]);
  const [auctionSubCategories, setAuctionSubCategories] = useState<AuctionCategoryReponse[]>([]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: props.values,
    onSubmit: (values) => handleSubmit(values),
  });
  let stateOptions = [
    { value: 'active', label: 'Online' },
    { value: 'deactive', label: 'Rozpracované' },
    { value: 'deleted', label: 'Smazaná' },
  ];
  if (currentUserRole.isOfRoleAuctioneerEditor()) {
    stateOptions = stateOptions.filter((v) => v.value !== 'deleted');
  }

  const yesNoOptions = [
    { value: true, label: 'Ano' },
    { value: false, label: 'Ne' },
  ];

  useEffect(() => {
    setIsOpen(false);
    loadAuctioneers();
    loadAuctionStatus();
    loadAuctionCategories();
    loadAuctionTypes();
    return () => auctionsApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values]);

  useEffect(() => {
    loadAuctionStatus();
    loadAuctionTypes();
    return () => auctionsApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.auctionType]);

  useEffect(() => {
    setIsOpen(false);
    formik.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values]);

  useEffect(() => {
    formik.values.mainCategory && loadAuctionSubCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.mainCategory]);

  const loadAuctioneers = async () => {
    try {
      const auctioneersResponse = await auctioneersApi.list({ page: 1, perPage: 1000 });
      setAuctioneers(auctioneersResponse.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const loadAuctionStatus = async () => {
    try {
      let auctionType =
        props.values.auctionType === 'auction'
          ? 'auction'
          : formik.values.auctionType === 'public' || formik.values.auctionType === ''
          ? undefined
          : formik.values.auctionType;

      if (props.showAuctions && !props.showPublicAuctions) {
        auctionType = 'auction';
      }

      if (!props.showAuctions && props.showPublicAuctions && formik.values.auctionType === 'auction') {
        auctionType = undefined;
      }

      const response = await auctionsApi.getAuctionStatus(auctionType);
      setAuctionStatus(response.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const loadAuctionTypes = async () => {
    try {
      let auctionType = props.values.auctionType === 'auction' ? 'auction' : 'auction_public';
      if (props.showAuctions && !props.showPublicAuctions) {
        auctionType = 'auction';
      }
      if (!props.showAuctions && props.showPublicAuctions) {
        auctionType = 'auction_public';
      }
      const response = await auctionsApi.getAuctionAuctionType(auctionType);
      setAuctionTypes(response.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const loadAuctionCategories = async () => {
    try {
      const response = await auctionCategoriesApi.getCategories();
      setAuctionCategories(response.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };
  const loadAuctionSubCategories = async () => {
    try {
      const response = await auctionCategoriesApi.getCategories(formik.values.mainCategory);
      setAuctionSubCategories(response.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const handleSubmit = (values: FormikValues) => {
    const formValues = { ...values };
    Object.getOwnPropertyNames(formValues).map((prop) => {
      if (formValues[prop] === '') {
        formValues[prop] = undefined;
      }
      return prop;
    });
    formValues.page = 1;
    formValues.id = formValues.id || undefined;
    formValues.title = formValues.title || undefined;
    formValues.number = formValues.number || undefined;
    formValues.startDtFrom = formValues.startDtFrom || undefined;
    formValues.startDtTo = formValues.startDtTo || undefined;
    formValues.endDtFrom = formValues.endDtFrom || undefined;
    formValues.endDtTo = formValues.endDtTo || undefined;
    formValues.active = formValues.active || undefined;
    formValues.status = formValues.status || undefined;
    formValues.auctioneer = formValues.auctioneer || undefined;
    formValues.mainCategory = formValues.mainCategory || undefined;
    formValues.subCategory = formValues.subCategory || undefined;
    props.onSubmit(formValues as AdminAuctionFilters);
  };

  const getClearUrl = () => {
    if (props.values.auctionType) {
      return `${pageState.getPagePath(routes.admin.AUCTIONS_LIST)}?auctionType=${props.values.auctionType}`;
    }
    return pageState.getPagePath(routes.admin.AUCTIONS_LIST);
  };

  const hasFilters = (): boolean => {
    return !!location.search && !['?auctionType=auction', '?auctionType=public'].some((i) => i === location.search);
  };

  const handleDateChange = (prop: string, value: Date | Date[] | null) => {
    if (value !== null) {
      const inputValue = value as Date;
      formik.setFieldValue(prop, !!inputValue ? getRequestDateFormat(inputValue) : '');
    } else {
      formik.setFieldValue(prop, '');
    }
  };

  const getAuctioneerOptions = () => {
    return auctioneers.map((i) => ({ value: i.id.toString(), label: i.auctioneerName }));
  };

  const getAuctionTypesOptions = () => {
    return auctionTypes.map((i) => ({ value: i.type, label: i.translated }));
  };

  const getStatusOptions = () => {
    return auctionStatus.map((i) => ({ value: i.type, label: i.translated }));
  };

  const getCategoryOptions = () => {
    return auctionCategories.map((i) => ({ value: i.id.toString(), label: i.title }));
  };

  const getSubCategoryOptions = () => {
    return auctionSubCategories.map((i) => ({ value: i.id.toString(), label: i.title }));
  };

  const handleStatusChange = (value: ValueType<SelectOptionType, true>) => {
    const itemValue = value as SelectOptionType[];
    formik.setFieldValue(
      'status',
      itemValue.map((s) => s.value)
    );
  };

  const handleAuctionTypeChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    formik.setFieldValue('auctionType', itemValue?.value || '');
  };

  const handleStateChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    formik.setFieldValue('state', itemValue?.value || '');
  };

  const handleAuctioneerChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    formik.setFieldValue('auctioneer', itemValue?.value || '');
  };

  const handleCategoryChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    formik.setFieldValue('mainCategory', itemValue?.value || '');
    formik.setFieldValue('subCategory', '');
  };

  const handleSubCategoryChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    formik.setFieldValue('subCategory', itemValue?.value || '');
  };

  return (
    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)}>
      <AdminFiltersBox isOpen={isOpen}>
        <FiltersInputs>
          <Row>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>{props.renderTranslation('filter_id')}</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="id"
                  value={formik.values.id || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>{props.renderTranslation('filter_name')}</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="title"
                  value={formik.values.title || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>{props.renderTranslation('filter_number')}</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="number"
                  value={formik.values.number || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            {currentUserRole.isOfAnyStaffRole() && (
              <Col xs={12} md={6} lg={3}>
                <Form.Group>
                  <Form.Label>{props.renderTranslation('filter_auctioneer')}</Form.Label>
                  <Select
                    isClearable={true}
                    options={getAuctioneerOptions()}
                    value={getAuctioneerOptions().find((i) => i.value === formik.values.auctioneer) || null}
                    onChange={handleAuctioneerChange}
                  />
                </Form.Group>
              </Col>
            )}
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>{props.renderTranslation('filter_state')}</Form.Label>
                <Select
                  isClearable={true}
                  options={stateOptions}
                  value={stateOptions.find((i) => i.value === formik.values.state) || null}
                  onChange={handleStateChange}
                />
              </Form.Group>
            </Col>
            {((formik.values.auctionType !== 'auction' && props.showPublicAuctions && props.showAuctions) ||
              (props.showPublicAuctions && !props.showAuctions)) && (
              <Col xs={12} md={6} lg={3}>
                <Form.Group>
                  <Form.Label>{props.renderTranslation('filter_auctionType')}</Form.Label>
                  <Select
                    isClearable={true}
                    options={getAuctionTypesOptions()}
                    value={getAuctionTypesOptions().find((i) => i.value === formik.values.auctionType) || null}
                    onChange={handleAuctionTypeChange}
                  />
                </Form.Group>
              </Col>
            )}

            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>{props.renderTranslation('filter_status')}</Form.Label>
                <Select
                  isMulti
                  isClearable={true}
                  options={getStatusOptions()}
                  value={(() => {
                    const res: SelectOptionType[] = [];
                    (formik.values.status || []).forEach((v) => {
                      const item = getStatusOptions().find((s) => s.value === v);
                      if (!!item) {
                        res.push(item);
                      }
                    });
                    return res;
                  })()}
                  onChange={(val: any) => handleStatusChange(val)}
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>{props.renderTranslation('filter_main_category')}</Form.Label>
                <Select
                  isClearable={true}
                  options={getCategoryOptions()}
                  value={getCategoryOptions().find((i) => i.value === formik.values.mainCategory) || null}
                  onChange={handleCategoryChange}
                />
              </Form.Group>
            </Col>
            {formik.values.mainCategory && (
              <Col xs={12} md={6} lg={3}>
                <Form.Group>
                  <Form.Label>{props.renderTranslation('filter_sub_category')}</Form.Label>
                  <Select
                    isClearable={true}
                    options={getSubCategoryOptions()}
                    value={getSubCategoryOptions().find((i) => i.value === formik.values.subCategory) || null}
                    onChange={handleSubCategoryChange}
                  />
                </Form.Group>
              </Col>
            )}
          </Row>

          <Row>
            <Col sm="auto">
              <Form.Group style={{ minWidth: 220 }}>
                <Form.Label>{props.renderTranslation('filter_published')}</Form.Label>
                <Select
                  isClearable
                  options={yesNoOptions}
                  value={yesNoOptions.find((v) => v.value === formik.values.published) || (null as any)}
                  onChange={(val) => formik.setFieldValue('published', val?.value)}
                />
              </Form.Group>
            </Col>

            <Col sm="auto">
              <Form.Group className="mb-0">
                <Form.Label>{props.renderTranslation('filter_start_dt')}</Form.Label>
              </Form.Group>
              <div className="d-block d-sm-flex mt-0">
                <Form.Group className="mb-3 inline-label mr-2 input-ico">
                  <Form.Label>Od</Form.Label>
                  <DatePickerInput
                    isNullable
                    clearIcon={null}
                    className="form-control form-control-sm w-135"
                    calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                    onChange={(val) => handleDateChange('startDtFrom', val)}
                    value={formik.values.startDtFrom ? moment(formik.values.startDtFrom || '').toDate() : undefined}
                  />
                </Form.Group>
                <Form.Group className="mb-3 inline-label input-ico">
                  <Form.Label>Do</Form.Label>
                  <DatePickerInput
                    isNullable
                    clearIcon={null}
                    className="form-control form-control-sm w-135"
                    calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                    onChange={(val) => handleDateChange('startDtTo', val)}
                    value={formik.values.startDtTo ? moment(formik.values.startDtTo || '').toDate() : undefined}
                  />
                </Form.Group>
              </div>
            </Col>

            <Col sm="auto">
              <Form.Group className="mb-0">
                <Form.Label>{props.renderTranslation('filter_end_dt')}</Form.Label>
              </Form.Group>
              <div className="d-block d-sm-flex mt-0">
                <Form.Group className="mb-3 inline-label mr-2 input-ico">
                  <Form.Label>Od</Form.Label>
                  <DatePickerInput
                    isNullable
                    clearIcon={null}
                    className="form-control form-control-sm w-135"
                    calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                    onChange={(val) => handleDateChange('endDtFrom', val)}
                    value={formik.values.endDtFrom ? moment(formik.values.endDtFrom || '').toDate() : undefined}
                  />
                </Form.Group>
                <Form.Group className="mb-3 inline-label input-ico">
                  <Form.Label>Do</Form.Label>
                  <DatePickerInput
                    isNullable
                    clearIcon={null}
                    className="form-control form-control-sm w-135"
                    calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                    onChange={(val) => handleDateChange('endDtTo', val)}
                    value={formik.values.endDtTo ? moment(formik.values.endDtTo || '').toDate() : undefined}
                  />
                </Form.Group>
              </div>
            </Col>
          </Row>
        </FiltersInputs>
        <FiltersSubmit hasFilters={hasFilters()} onOpenClick={() => setIsOpen(true)} clearUrl={getClearUrl()} />
      </AdminFiltersBox>
    </Form>
  );
};

export default FiltersForm;
