import React from 'react';
import { Link } from 'react-router-dom';
import { Formik, FormikValues } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';

import * as routes from '@routes';
import { useAuthApi } from '@api/auth';
import { usePageStore } from '@stores';
import { Button } from '@components';

interface Props {
  onLogin: (token: string, refreshToken: string) => void;
}

const LoginForm: React.FC<Props> = (props) => {
  const authApi = useAuthApi();
  const pageState = usePageStore();

  const handleFormSubmit = (
    values: FormikValues,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) => {
    authApi
      .login(values.email, values.password, 'admin')
      .then((res) => {
        props.onLogin(res.data.token, res.data.refresh_token);
      })
      .catch((err) => {
        if (authApi.isCancel(err)) {
          return;
        }
        setFieldValue('password', ``);
        setSubmitting(false);
      });
  };

  return (
    <Formik
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={(values, { setSubmitting, setFieldValue }) => {
        handleFormSubmit(values, setSubmitting, setFieldValue);
      }}
    >
      {(formProps) => (
        <Form
          onSubmit={(e: React.FormEvent<HTMLFormElement>) => formProps.handleSubmit(e)}
          data-test-id="admin-auth-login-form"
        >
          <Form.Group>
            <Form.Label>E-mail</Form.Label>
            <Form.Control
              required={true}
              disabled={formProps.isSubmitting}
              type="email"
              value={formProps.values.email}
              name="email"
              data-test-id="admin-auth-login-form-email"
              onChange={formProps.handleChange}
            />
          </Form.Group>

          <Form.Group>
            <Form.Label>Heslo</Form.Label>
            <Form.Control
              required={true}
              disabled={formProps.isSubmitting}
              type="password"
              value={formProps.values.password}
              name="password"
              data-test-id="admin-auth-login-form-password"
              onChange={formProps.handleChange}
            />
          </Form.Group>

          <Form.Group className="mb-0">
            <Row className="align-items-center mt-4 pt-2">
              <Col xs={12} md={6}>
                <Button
                  disabled={formProps.isSubmitting}
                  variant="btn-outline-primary"
                  type="submit"
                  dataTestId="admin-auth-login-form-btn"
                >
                  Přihlásit se
                </Button>
              </Col>
              <Col xs={12} md={6} className="text-left text-md-right pt-4 pt-md-0">
                <Link to={pageState.getPagePath(routes.auth.RESET_PASSWORD)}>Zapomněli jste své heslo?</Link>
              </Col>
            </Row>
          </Form.Group>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
