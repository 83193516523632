import { isArray } from 'lodash';

export type Resolver<Value extends any = any> = string | ((value: Value) => string);

export interface FilterOptions<Value extends any = any> {
  value?: (value: Value) => any;
  operator?: Resolver<Value>;
  type?: Resolver<Value>;
  property?: Resolver<Value>;
  splitKeyWords?: boolean;
  key: string;
  group?: string;
}

export interface Filter {
  value: string;
  operator: string;
  type: string;
  property: string;
}

export interface GridFilterOptions {
  defaultType?: string;
  defaultOperator?: string;
}

export interface GridFilter<Params extends object> {
  getQueryFiltres(params: Params): Array<Filter | Array<Filter>>;
  isEmpty(params: Params): boolean;
  toString(params: Params): string;
}

export interface Params {
  [key: string]: any;
}

const ROOT_GROUP = '__root';
const DEFAULT_TYPE = 'string';
const DEFAULT_OPERATOR = 'eq';

export const useGridFilter = <_Params extends Params>(
  filterOptions: Array<FilterOptions<any>> = [],
  options: GridFilterOptions = {}
): GridFilter<_Params> => {
  const defaultType = options?.defaultType || DEFAULT_TYPE;
  const defaultOperator = options?.defaultType || DEFAULT_OPERATOR;

  const gridFilter: GridFilter<_Params> = {
    toString(params: _Params) {
      return JSON.stringify(gridFilter.getQueryFiltres(params));
    },
    isEmpty(params: _Params) {
      return gridFilter.getQueryFiltres(params).length < 1;
    },
    getQueryFiltres(params: _Params) {
      const filterGroups: {
        [ROOT_GROUP]: Array<Filter>;
        [key: string]: Array<Filter>;
      } = { [ROOT_GROUP]: [] };

      for (const index in filterOptions) {
        const filterOption = filterOptions[index];
        const value = params[filterOption.key];

        let _values = typeof filterOption.value === 'function' ? [filterOption.value(value)] : [value];

        if (_values.length < 1 || !_values.reduce((a, b) => a === b || a || b, false)) {
          continue;
        }

        if (typeof _values[0] === 'string' && filterOption.splitKeyWords) {
          _values = _values[0].split(' ');
        }

        for (const valueIndex in _values) {
          const filter: Filter = {
            value: _values[valueIndex],
            operator:
              typeof filterOption.operator === 'function'
                ? filterOption.operator(value)
                : filterOption.operator || defaultOperator,
            type: typeof filterOption.type === 'function' ? filterOption.type(value) : filterOption.type || defaultType,
            property:
              typeof filterOption.property === 'function'
                ? filterOption.property(value)
                : filterOption.property || filterOption.key,
          };

          const groupName = filterOption.group || ROOT_GROUP;
          if (!isArray(filterGroups[groupName])) {
            filterGroups[groupName] = [];
          }

          if (Array.isArray(filter.value)) {
            filter.value.map((value: string, index: number) => {
              const arrFilter = { ...filter };
              arrFilter.value = value;
              filterGroups[groupName].push(arrFilter);
              return value;
            });
          } else {
            filterGroups[groupName].push(filter);
          }
        }
      }

      const filters: Array<Filter | Array<Filter>> = [...filterGroups[ROOT_GROUP]];
      for (const groupName in filterGroups) {
        if (groupName === ROOT_GROUP || !filterGroups[groupName]) {
          continue;
        }

        if (filterGroups[groupName].length > 1) {
          filters.push(filterGroups[groupName]);
          continue;
        }

        if (filterGroups[groupName][0]) {
          filters.push(filterGroups[groupName][0]);
          continue;
        }
      }

      return filters;
    },
  };

  return gridFilter;
};
