import React from 'react';

import * as routes from '@routes';
import { BaseAccountPage, FrontAccountBox } from '@components';
import { useAuthFrontStore } from '@stores';
import StepBuyer from '@components/RegistrationSteps/StepBuyer';

const AccountAuctioneerRegistrationPage: React.FC = () => {
  const { user } = useAuthFrontStore();

  return (
    <BaseAccountPage page={routes.front.ACCOUNT_AUCTIONEER_REGISTRATION}>
      <FrontAccountBox className="page-account-personal-data">
        <StepBuyer isRegistration id={user?.id} email={user?.email || ''} onSuccess={() => {}} />
      </FrontAccountBox>
    </BaseAccountPage>
  );
};

export default AccountAuctioneerRegistrationPage;
