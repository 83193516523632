import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import { stripeHtml } from '@helpers/stripeHtml';
import { InformedConsentDefaultResponse } from '@api/informedConsent';

import colorIcoConf from '@assets/images/color-ico-conf.svg';

interface Props {
  data: InformedConsentDefaultResponse[];
  onUpdateClick: (item: InformedConsentDefaultResponse) => void;
}

const InformedConsentsTable: React.FC<Props> = (props) => {
  const handleUpdateClick = (e: React.MouseEvent, item: InformedConsentDefaultResponse) => {
    e.preventDefault();
    props.onUpdateClick(item);
  };

  return (
    <div>
      <div className="mt-4">
        <div className="responsive-table-content">
          <Table className="table-middle border-bottom-0" striped responsive>
            <thead>
              <tr>
                <th>ID</th>
                <th>Název</th>
                <th>Text</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {props.data.length < 1 && (
                <tr>
                  <td className="text-left" colSpan={100}>
                    Nebyla nalezena žádná data.
                  </td>
                </tr>
              )}
              {props.data.map((item, index) => (
                <tr key={`item-${index}`}>
                  <td className="text-left">{item.id}</td>
                  <td className="text-left">{item.title}</td>
                  <td className="text-left information-text">{stripeHtml(item.text, 100)}</td>
                  <td className="text-right">
                    <Link
                      to="/"
                      className="d-inline-flex align-items-center ml-4"
                      onClick={(e) => handleUpdateClick(e, item)}
                    >
                      <img src={colorIcoConf} alt="ico" className="mr-2" />
                      Upravit
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default InformedConsentsTable;
