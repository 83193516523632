import React from 'react';

import { AuctionInformationsCType } from '@types';
import { BasePreloader } from '@components';
import InformationsTable from './InformationsTable';
import { useSettingsInformationsApi } from '@api/settingsInformations';
import InformationsForm from './InformationsForm';

interface Props {}

const Informations: React.FC<Props> = (props) => {
  const settingsInformationsApi = useSettingsInformationsApi();
  const [loaded, setLoaded] = React.useState(false);
  const [updateItem, setUpdateItem] = React.useState(undefined as AuctionInformationsCType | undefined);
  const [showForm, setShowForm] = React.useState(false);
  const [informationData, setInformationsData] = React.useState([] as Array<AuctionInformationsCType>);

  React.useEffect(() => {
    loadData();
    return () => settingsInformationsApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    settingsInformationsApi
      .list()
      .then((res) => {
        setInformationsData(res.data.data);
        setLoaded(true);
      })
      .catch((err) => {
        if (settingsInformationsApi.isCancel(err)) {
          return;
        }
      });
  };

  const handleUpdateClick = (item: AuctionInformationsCType) => {
    setUpdateItem(item);
    setShowForm(true);
  };

  const handleSave = () => {
    setShowForm(false);
    setUpdateItem(undefined);
    setLoaded(false);
    loadData();
  };

  if (!loaded) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <>
      {showForm ? (
        <InformationsForm
          data={updateItem}
          onClose={() => setShowForm(false)}
          onSubmit={() => setShowForm(false)}
          onSave={handleSave}
        />
      ) : (
        <InformationsTable data={informationData} onUpdateClick={handleUpdateClick} />
      )}
    </>
  );
};

export default Informations;
