import React from 'react';

import { BaseFrontPage } from '@components';
import { FrontAuctioneerList } from '@components/FrontAuctioneerList/FrontAuctioneerList';
import { FrontSidebarBanners, FrontSidebarStatistics, FrontSidebarNotifications } from '@components/FrontSidebarBox';

export const AuctioneerListPage = () => {
  return (
    <BaseFrontPage
      disableScrollToTop
      sidebarItems={[
        <FrontSidebarNotifications title="Upozornění na Váš e-mail" />,
        <FrontSidebarStatistics title="Exdražby v číslech" />,
        <FrontSidebarBanners />,
      ]}
    >
      <FrontAuctioneerList />
    </BaseFrontPage>
  );
};
