import React from 'react';
import { Link } from 'react-router-dom';

import * as routes from '@routes';
import { Button } from '@components';

import filterIco from '@assets/images/filter-ico.svg';
import filterIcoWhite from '@assets/images/filter-ico-white.svg';
import filtersCloseIco from '@assets/images/filters-close-ico.svg';

interface Props {
  clearUrl?: string;
  hasFilters?: boolean;
  onOpenClick?: () => void;
}

const FiltersSubmit: React.FC<Props> = (props) => {
  return (
    <div className="filters-submit">
      <Button type="submit" className="submit-button">
        <img src={filterIco} alt="ico" className="hover-hide" />
        <img src={filterIcoWhite} alt="ico" className="hover-show" />
        Filtrovat
      </Button>
      <Button className="open-button" onClick={props.onOpenClick}>
        <img src={filterIco} alt="ico" className="hover-hide" />
        <img src={filterIcoWhite} alt="ico" className="hover-show" />
        Všechny filtry
      </Button>
      {props.hasFilters && (
        <>
          <Link
            to={props.clearUrl || routes.admin.AUCTIONEER_LIST}
            className="d-inline-flex align-items-center f-size-12 clear-btn"
          >
            <img src={filtersCloseIco} alt="ico" className="mr-10" />
            Zrušit filtry
          </Link>
        </>
      )}
    </div>
  );
};

export default FiltersSubmit;
