import { useWebsocket } from '@websocket/base';
import { WebAuctionBid } from '@types';

export type AuctionBidsWebsocketResponse = {
  data: WebAuctionBid[];
};

type Props = {
  id: string | number;
  onMessage?: (res: AuctionBidsWebsocketResponse) => void;
};

export const useAuctionBidsWebsocket = (props: Props) => {
  return useWebsocket<AuctionBidsWebsocketResponse>({
    topic: `/auctions/${props.id}/bids`,
    onMessage: props.onMessage,
  });
};
