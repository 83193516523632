import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import colorIcoDel from '@assets/images/color-ico-del.svg';
import colorIcoConf from '@assets/images/color-ico-conf.svg';
import colorIcoRestore from '@assets/images/color-ico-restore.svg';
import { PermissionGroup, User, UserPermission } from '@types';
import * as routes from '@routes';
import { usePageStore } from '@stores';
import { useAllowedUserRoles, useAllowedUserStates } from '@stores/auth';
import { Visible } from '@components';
import { getName } from '@helpers/string';

interface Props {
  data: Array<User>;
  onDeleteClick: (item: User) => void;
  onRestoreClick: (item: User) => void;
  onReinviteClick: (item: User) => void;
}

const AdministratorsTable: React.FC<Props> = (props) => {
  const pageState = usePageStore();
  const { resolveUserRoleText } = useAllowedUserRoles('admin');
  const { resolveUserStateText } = useAllowedUserStates();

  const handleDeleteClick = (e: React.MouseEvent, item: User) => {
    e.preventDefault();
    props.onDeleteClick(item);
  };

  const handleRestoreClick = (e: React.MouseEvent, item: User) => {
    e.preventDefault();
    props.onRestoreClick(item);
  };

  const handleReinviteClick = (e: React.MouseEvent, item: User) => {
    e.preventDefault();
    props.onReinviteClick(item);
  };

  return (
    <div>
      <div className="responsive-table-content">
        <Table className="table-middle" striped>
          <thead>
            <tr>
              <th>Jméno a příjmení</th>
              <th>E-mail</th>
              <th>Oprávnění</th>
              <th>Stav</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {props.data.map((item, index) => (
              <tr key={`list-item-${index}`}>
                <td className="text-left">{getName(item) || '-'}</td>
                <td className="text-left">{item.email}</td>
                <td className="text-left">{resolveUserRoleText(item.role)}</td>
                <td className="text-left">{resolveUserStateText(item.state)}</td>
                <td className="text-right">
                  <Visible
                    and={[
                      {
                        anyAdmin: true,
                      },
                      () => item.state === 'unverified',
                    ]}
                    disableSuperAdminOverride
                  >
                    <Link
                      className="d-inline-flex align-items-center ml-4"
                      to="/"
                      onClick={(e) => handleReinviteClick(e, item)}
                    >
                      <img src={colorIcoRestore} alt="ico" className="mr-2" />
                      Znovu&nbsp;pozvat
                    </Link>
                  </Visible>
                  <Visible
                    and={[
                      {
                        permissionGroupName: PermissionGroup.administrator,
                        permissionName: UserPermission.canEdit,
                      },
                      () => item.state !== 'deleted',
                    ]}
                    disableSuperAdminOverride
                  >
                    <Link
                      className="d-inline-flex align-items-center ml-4"
                      to={pageState.getPagePath(routes.admin.SETTINGS_ADMINISTRATORS_UPDATE, {
                        ':id': item.id.toString(),
                      })}
                    >
                      <img src={colorIcoConf} alt="ico" className="mr-2" />
                      Upravit
                    </Link>
                  </Visible>
                  <Visible
                    and={[
                      {
                        permissionGroupName: PermissionGroup.administrator,
                        permissionName: UserPermission.canDelete,
                      },
                      () => item.state !== 'deleted',
                    ]}
                    disableSuperAdminOverride
                  >
                    <Link
                      className="d-inline-flex align-items-center ml-4"
                      to="/"
                      onClick={(e) => handleDeleteClick(e, item)}
                    >
                      <img src={colorIcoDel} alt="ico" className="mr-2" />
                      Smazat
                    </Link>
                  </Visible>
                  <Visible
                    and={[
                      {
                        anyAdmin: true,
                      },
                      () => item.state === 'deleted',
                    ]}
                    disableSuperAdminOverride
                  >
                    <Link
                      className="d-inline-flex align-items-center ml-4"
                      to="/"
                      onClick={(e) => handleRestoreClick(e, item)}
                    >
                      <img src={colorIcoRestore} alt="ico" className="mr-2" />
                      Obnovit
                    </Link>
                  </Visible>
                </td>
              </tr>
            ))}
            {props.data.length < 1 && (
              <tr>
                <td className="text-left" colSpan={100}>
                  Nebyla nalezena žádná data.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AdministratorsTable;
