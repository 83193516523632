import React from 'react';

import { BaseFrontPage } from '@components';
import {
  FrontSidebarMenu,
  FrontSidebarBanners,
  FrontSidebarStatistics,
  FrontSidebarNotifications,
} from '@components/FrontSidebarBox';
import FrontAuctionList from '@components/FrontAuctionList/FrontAuctionList';

interface PageProps {
  status: string;
}

const AuctionListPage: React.FC<PageProps> = ({ status }) => {
  return (
    <BaseFrontPage
      disableScrollToTop
      sidebarItems={[
        <FrontSidebarMenu title="Dražby a aukce" status={status} />,
        <FrontSidebarNotifications title="Upozornění na Váš e-mail" />,
        <FrontSidebarStatistics title="Exdražby v číslech" />,
        <FrontSidebarBanners />,
      ]}
    >
      <FrontAuctionList status={status} />
    </BaseFrontPage>
  );
};

export default AuctionListPage;
