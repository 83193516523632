import React from 'react';
import { Link } from 'react-router-dom';

import { usePageStore } from '@stores';

const FooterMenu: React.FC = () => {
  const pageState = usePageStore();

  const footerPages = pageState.pages.filter((page) => page.footer && page.showInAdministration);

  const sortedFooterPages = footerPages.sort((a, b) => {
    if (a.menuPosition === null) {
      return 1;
    } else if (b.menuPosition === null) {
      return -1;
    }
    return a.menuPosition - b.menuPosition;
  });
  return (
    <ul className="footer-menu">
      <li>
        <Link to="/">Úvod</Link>
      </li>
      {sortedFooterPages.map((page, index) => (
        <li key={index}>
          <Link to={pageState.getPagePath(page.routeName)}>{page.name}</Link>
        </li>
      ))}
    </ul>
  );
};

export default FooterMenu;
