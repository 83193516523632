import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { ValueType } from 'react-select';
import fileDownload from 'js-file-download';
import { useDropzone } from 'react-dropzone';
import { useToasts } from 'react-toast-notifications';
import { OverlayTrigger, Table, Tooltip } from 'react-bootstrap';

import { useUsersApi } from '@api/users';
import { getName } from '@helpers/string';
import { applicationStore } from '@stores';
import { fileToBase64 } from '@helpers/files';
import { getFormatBytes } from '@helpers/formats';
import { closeMessage, confirmMessage } from '@helpers/messages';
import { BasePreloader, FileIcon, Select, Visible } from '@components';
import RejectedModal from '../../DocumentsPage/components/RejectedModal';
import { User, BuyerDocument, UserPermission, DocumentState, UserDocumentType } from '@types';

import infoColorIco from '@assets/images/info-color.svg';
import colorIcoDel from '@assets/images/color-ico-del.svg';
import colorIcoConf from '@assets/images/color-ico-conf.svg';
import colorIcoCheck from '@assets/images/color-ico-check.svg';
import { DocumentTypeModal } from './DocumentTypeModal';

interface Props {
  user?: User;
  readOnly?: boolean;
  userId: string | number;
}

interface SelectOptionType {
  value: string;
  label: string;
}

const UserDocuments: React.FC<Props> = (props) => {
  const usersApi = useUsersApi();
  const { addToast } = useToasts();
  const [updateDocumentType, setUpdateDocumentType] = React.useState<BuyerDocument | undefined>();
  const [documents, setDocuments] = React.useState<Array<BuyerDocument>>([]);
  const [loaded, setLoaded] = React.useState(false);
  const inputRef = React.createRef<HTMLInputElement>();
  const [saved, setSaved] = React.useState(true);
  const [reason, setReason] = React.useState<React.ReactNode | null>(null);
  const [file] = React.useState<File | undefined>(undefined);
  const [documentType, setDocumentType] = React.useState<string | null>(null);
  const [documentTypes, setDocumentTypes] = React.useState<UserDocumentType[]>([]);

  const documentTypeOptions = documentTypes.map((i) => ({
    label: i.translated,
    value: i.type,
  }));

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const accept = ['.pdf'];
      for (const i in acceptedFiles) {
        const extension = `.${acceptedFiles[i].name.split('.').slice(-1)[0].toLowerCase()}`;
        if (accept.some((e) => e === extension)) {
          await saveFile(acceptedFiles[i]);
          return;
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [documentType]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, noClick: true });

  React.useEffect(() => {
    if (props.user && props.user.id) {
      loadDocuments(props.user.id);
    }
    // eslint-disable-next-line
  }, [props.user]);

  React.useEffect(() => {
    loadDocumentTypes();
    return () => usersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadDocumentTypes = async () => {
    try {
      const response = await usersApi.getDocumentTypes();
      setDocumentTypes(response.data);
      if (response.data.length > 0) {
        setDocumentType(response.data[0].type);
      }
    } catch (err) {
      if (usersApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const handleDocumentTypeChange = (value: ValueType<SelectOptionType, boolean>) => {
    const itemValue = value as SelectOptionType;
    setDocumentType(itemValue.value);
  };

  const loadDocuments = (id: number) => {
    setLoaded(false);
    usersApi
      .getDocuments(id)
      .then((res) => {
        setDocuments(res.data.data);
        setLoaded(true);
      })
      .catch((err) => {
        if (usersApi.isCancel(err)) {
          return;
        }
      });
  };

  const handleFileClick = (e: React.MouseEvent<HTMLAnchorElement>, item: BuyerDocument) => {
    e.preventDefault();
    usersApi
      .getAdminDocument(props.userId, item.id)
      .then((res) => {
        fileDownload(res.data, item.media.originalName);
      })
      .catch((err) => {
        if (usersApi.isCancel(err)) {
          return;
        }
      });
  };

  const saveFile = async (file: File) => {
    const maxPostBodySize = applicationStore.getState().systemInfo?.post_max_size;
    if (!!file && !!maxPostBodySize && maxPostBodySize < file.size) {
      addToast(`Soubor ${file.name} nesmí být větší než ${getFormatBytes(maxPostBodySize)}`, { appearance: 'error' });
      return;
    }

    setSaved(false);
    try {
      const fileBase64 = await fileToBase64(file);
      usersApi
        .uploadDocument(props.user?.id as number, {
          mime: file.type,
          type: 'file',
          document_type: documentType || undefined,
          data: fileBase64,
          original_name: file.name,
        })
        .then(async () => {
          await loadDocuments(props.user?.id as number);
          setSaved(true);
        })
        .catch((err) => {
          if (usersApi.isCancel(err)) {
            return;
          }
          setSaved(true);
        });
    } catch (err) {
      console.error(err);
      setSaved(true);
    }
  };

  const handleSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: File | undefined = e.target.files !== null ? e.target.files[0] : undefined;
    if (!!file) {
      saveFile(file);
    }
  };

  const renderStatus = (item: BuyerDocument) => {
    if (item.state === DocumentState.rejected) {
      return (
        <a
          href="/"
          onClick={(e) => {
            e.preventDefault();
            setReason(item.reason ? item.reason : <i>Bez udání důvodu</i>);
          }}
        >
          {item.translatedState}
          <img src={infoColorIco} alt="rejected" style={{ marginLeft: '8px' }} />
        </a>
      );
    }

    return item.translatedState;
  };

  const handleAddClick = (e: React.MouseEvent) => {
    e.preventDefault();
    inputRef.current?.click();
  };

  const handleDeleteClick = (event: React.MouseEvent, item: BuyerDocument) => {
    event.preventDefault();
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat tuto položku?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return usersApi.deleteBuyerDocument(props.user?.id!, item.id).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadDocuments(props.user?.id!);
      }
    });
  };

  if (!loaded) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <>
      <RejectedModal title="Důvod zamítnutí" isOpen={reason !== null} reason={reason} onClose={() => setReason(null)} />
      <div {...getRootProps()}>
        <div>
          <h2>Dokumenty</h2>
          <Visible permissionGroupName="admin-buyer-documents" permissionName={UserPermission.canCreate}>
            <div className="component-account-documents" style={{ padding: 0 }}>
              <div className="add-document">
                <div className="item-input">
                  <div className="add-document-wrapper">
                    <div className="block-title">Přidat dokument:</div>
                  </div>
                  <div className="item-type-select">
                    <div className="select-label">Typ dokumentu:</div>
                    <div className="select-container">
                      <Select
                        options={documentTypeOptions}
                        onChange={handleDocumentTypeChange}
                        value={documentTypeOptions.find((i) => i.value === documentType) || null}
                      />
                    </div>
                  </div>
                  {isDragActive ? (
                    <div>
                      <div className="item-drop">Vložit soubor</div>
                    </div>
                  ) : (
                    <a href="/" className="item-add" onClick={handleAddClick}>
                      Vyberte soubor z počítače
                      {!!file && (
                        <OverlayTrigger
                          key="file"
                          placement="top"
                          overlay={
                            <Tooltip id="table-document">
                              <div>{file.name}</div>
                            </Tooltip>
                          }
                        >
                          <img src={colorIcoCheck} alt="ico" className="success-ico" />
                        </OverlayTrigger>
                      )}
                    </a>
                  )}
                  {saved && (
                    <input
                      {...getInputProps()}
                      type="file"
                      accept=".pdf"
                      ref={inputRef}
                      className="d-none"
                      onChange={handleSelectFile}
                    />
                  )}
                </div>
                <div className="item-submit">
                  {!saved && <BasePreloader className="file-preloader" size={25} />}
                  <div className="clearfix" />
                </div>
                <div className="clearfix" />
              </div>
            </div>
          </Visible>
        </div>
        <div className="mt-4">
          <div className="responsive-table-content">
            <Table
              className="text-center table-middle border-bottom-0"
              data-test-id="admin-user-update-documents-table"
              striped
            >
              <thead>
                <tr>
                  <th className="text-left">Název dokumentu</th>
                  <th className="text-left">Typ dokumentu</th>
                  <th className="text-left">Stav</th>
                  <th className="text-left">Dokument zpracoval</th>
                  <th />
                </tr>
              </thead>
              <tbody>
                {documents.length < 1 && (
                  <tr>
                    <td className="text-left" colSpan={100}>
                      Nebyla nalezena žádná data.
                    </td>
                  </tr>
                )}
                {documents.map((item, index) => (
                  <tr key={`document-${index}`}>
                    <td className="text-left">
                      <FileIcon name={item.media.originalName} />
                      <a href="/" onClick={(e) => handleFileClick(e, item)}>
                        {item.media.originalName}
                      </a>
                    </td>
                    <td className="text-left">
                      <span className="d-flex">
                        <a
                          href="/"
                          className="d-flex"
                          onClick={(e) => {
                            e.preventDefault();
                            setUpdateDocumentType(item);
                          }}
                        >
                          <img src={colorIcoConf} alt="ico" className="mr-2" />
                        </a>
                        {item.translatedType || '-'}
                      </span>
                    </td>
                    <td className="text-left">{renderStatus(item)}</td>
                    <td className="text-left">{item.approvedUser ? getName(item.approvedUser) : '-'}</td>
                    <td className="text-right">
                      <Link
                        to="/"
                        className="d-inline-flex align-items-center"
                        onClick={(e) => handleDeleteClick(e, item)}
                      >
                        <img src={colorIcoDel} alt="ico" className="mr-2" />
                        Smazat
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>

      {updateDocumentType && (
        <DocumentTypeModal
          buyerDocument={updateDocumentType}
          documentTypes={documentTypeOptions}
          onClose={() => setUpdateDocumentType(undefined)}
          onSuccess={() => {
            setUpdateDocumentType(undefined);
            if (props.user && props.user.id) {
              loadDocuments(props.user.id);
            }
          }}
        />
      )}
    </>
  );
};

export default UserDocuments;
