import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';
import { ArrayParam, BooleanParam, NumberParam, StringParam, useQueryParams, withDefault } from 'use-query-params';

import * as routes from '@routes';
import { Select, AdminFiltersBox } from '@components';
import FiltersInputs from '@components/AdminFiltersBox/components/FiltersInputs';
import FiltersSubmit from '@components/AdminFiltersBox/components/FiltersSubmit';
import { AuctionUsersFilters, AuctionAuthorizationType, AuctionCautionDepositType, BaseType } from '@types';

import { usePageStore } from '@stores';
import { useAuctionsApi } from '@api/auctions';
import { AuctionSectionStep } from '../ActiveAuctionPage';

type Props = {
  auctionId: string | number;
  isAuctionSecurity?: boolean;
  hasCautionDeposit?: boolean;
  getTranslation: (key: string) => string;
};

const FiltersForm: React.FC<Props> = (props) => {
  const location = useLocation();
  const pageState = usePageStore();
  const auctionsApi = useAuctionsApi();
  const [isOpen, setIsOpen] = React.useState(false);
  const [auctionAuthorizationTypes, setAuctionAuthorizationTypes] = React.useState<AuctionAuthorizationType[]>([]);
  const [auctionCautionDepositTypes, setAuctionCautionDepositTypes] = React.useState<AuctionCautionDepositType[]>([]);
  const formik = useFormik<AuctionUsersFilters>({
    enableReinitialize: true,
    initialValues: {},
    onSubmit: (values) => handleSubmit(values),
  });
  const [query, setQuery] = useQueryParams({
    s: withDefault(StringParam, AuctionSectionStep.auctioneers),
    page: withDefault(NumberParam, 1),
    perPage: withDefault(NumberParam, 15),
    uniqueCode: withDefault(StringParam, undefined),
    specificSymbol: withDefault(StringParam, undefined),
    variableSymbol: withDefault(StringParam, undefined),
    preEmption: withDefault(BooleanParam, undefined),
    admitted: withDefault(BooleanParam, undefined),
    auctionSecurity: withDefault(ArrayParam, undefined),
    authorizationState: withDefault(StringParam, undefined),
    fullName: withDefault(StringParam, undefined),
    withoutWinner: withDefault(BooleanParam, undefined),
  });

  const authorizationTypes = auctionAuthorizationTypes.map((v) => ({
    value: v.type,
    label: v.translated,
  }));

  const cautionDepositTypes = auctionCautionDepositTypes.map((v) => ({
    value: v.type,
    label: v.translated,
  }));

  useEffect(() => {
    loadEnums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.auctionId]);

  useEffect(() => {
    formik.setValues(query);
    setIsOpen(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  const loadEnums = async () => {
    try {
      setAuctionAuthorizationTypes((await auctionsApi.getAuthorizationType(props.auctionId)).data.data);
      setAuctionCautionDepositTypes((await auctionsApi.getCautionDeposit(props.auctionId)).data.data);
    } catch (err: any) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleSubmit = (values: FormikValues) => {
    const formValues = { ...values };
    Object.getOwnPropertyNames(formValues).map((prop) => {
      if (formValues[prop] === '') {
        formValues[prop] = undefined;
      }
      return prop;
    });
    formValues.page = 1;
    setQuery(formValues, 'push');
  };

  const hasFilters = (): boolean => {
    if (location.search === '?s=auctionSecurity' || location.search === '?s=auctioneers') {
      return false;
    }
    return !!location.search;
  };

  const getClearUrl = () => {
    if (props.isAuctionSecurity) {
      return `${pageState.getPagePath(routes.admin.AUCTIONS_ACTIVE_DETAIL, { ':id': props.auctionId })}?s=${
        AuctionSectionStep.auctionSecurity
      }`;
    }
    return pageState.getPagePath(routes.admin.AUCTIONS_ACTIVE_DETAIL, { ':id': props.auctionId });
  };

  return (
    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-3">
      <AdminFiltersBox isOpen={isOpen}>
        <FiltersInputs>
          <Row>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Identifikační číslo</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="uniqueCode"
                  value={formik.values.uniqueCode || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Jméno a příjmení</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="fullName"
                  value={formik.values.fullName || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Specific. symbol</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="specificSymbol"
                  value={formik.values.specificSymbol || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Variabilní symbol</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="variableSymbol"
                  value={formik.values.variableSymbol || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Ověření totožnosti</Form.Label>
                <Select
                  isClearable={true}
                  options={authorizationTypes}
                  value={authorizationTypes.find((i) => i.value === formik.values.authorizationState) || null}
                  onChange={(val) => formik.setFieldValue('authorizationState', val?.value)}
                />
              </Form.Group>
            </Col>
            {props.hasCautionDeposit && (
              <Col xs={12} md={6} lg={3}>
                <Form.Group>
                  <Form.Label>Jistota</Form.Label>
                  <Select
                    isMulti
                    isClearable={true}
                    options={cautionDepositTypes}
                    onChange={(val: BaseType) =>
                      formik.setFieldValue(
                        'auctionSecurity',
                        val.map((v: { value: string }) => v.value)
                      )
                    }
                    value={(() => {
                      const res: { label: string; value: string }[] = [];
                      formik.values.auctionSecurity?.forEach((v) => {
                        const option = cautionDepositTypes.find((o) => o.value === v);
                        if (option) {
                          res.push(option);
                        }
                      });
                      return res;
                    })()}
                  />
                </Form.Group>
              </Col>
            )}
            <Col xs={12} md={6} lg={6}>
              <Row>
                <Col sm="auto" className="py-4 align-self-center">
                  <Row>
                    <Col sm="auto">
                      <Form.Check
                        custom
                        type="checkbox"
                        label="Předkupní právo"
                        className="mt-checkbox"
                        id="preEmption"
                        name="preEmption"
                        checked={formik.values.preEmption || false}
                        onChange={(e) => formik.setFieldValue('preEmption', e.target.checked || undefined)}
                      />
                    </Col>
                    <Col sm="auto">
                      <Form.Check
                        custom
                        type="checkbox"
                        label="Připuštěn"
                        className="mt-checkbox"
                        id="admitted"
                        name="admitted"
                        checked={formik.values.admitted || false}
                        onChange={(e) => formik.setFieldValue('admitted', e.target.checked || undefined)}
                      />
                    </Col>
                    {props.isAuctionSecurity && (
                      <Col sm="auto">
                        <Form.Check
                          custom
                          type="checkbox"
                          label="Bez vítěze"
                          className="mt-checkbox"
                          id="withoutWinner"
                          name="withoutWinner"
                          checked={formik.values.withoutWinner || false}
                          onChange={(e) => formik.setFieldValue('withoutWinner', e.target.checked || undefined)}
                        />
                      </Col>
                    )}
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </FiltersInputs>
        <FiltersSubmit hasFilters={hasFilters()} onOpenClick={() => setIsOpen(true)} clearUrl={getClearUrl()} />
      </AdminFiltersBox>
    </Form>
  );
};

export default FiltersForm;
