import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Table } from 'react-bootstrap';

import * as routes from '@routes';
import { WatchDogType } from '@types';
import { usePageStore } from '@stores';

import colorIcoEdit from '@assets/images/color-ico-edit.svg';
import colorIcoDelete from '@assets/images/color-ico-del.svg';

type Props = {
  items: WatchDogType[];
  onDelete: (item: WatchDogType) => void;
  stateToggle: (item: WatchDogType) => void;
};

export const AccountWatchdogList: React.FC<Props> = (props) => {
  const pageState = usePageStore();

  return (
    <div>
      <div className="responsive-table-content">
        <Table className="text-left table-middle border-bottom-0" striped>
          <thead>
            <tr style={{ borderBottom: '1px solid #DFDFDF' }}>
              <th className="text-left">Název</th>
              <th className="text-center">Interval</th>
              <th className="text-center">Status</th>
              <th className="w-1" />
            </tr>
          </thead>
          <tbody>
            {props.items.length < 1 && (
              <tr>
                <td className="text-left" colSpan={100}>
                  Nebyla nalezena žádná data.
                </td>
              </tr>
            )}
            {props.items.map((item, index) => (
              <tr key={`watchdog-${index}`}>
                <td>
                  <Link to={pageState.getPagePath(routes.front.ACCOUNT_DOG_DETAIL, { ':id': item.id })}>
                    {item.title}
                  </Link>
                </td>
                <td className="text-center">{item.watchDogIntervalTranslation}</td>
                <td className="text-center">
                  <Form.Check
                    custom
                    type="checkbox"
                    className="mt-checkbox"
                    id={`watch-dog-${index}`}
                    label={item.stateTranslation}
                    checked={item.state === 'active'}
                    onChange={() => props.stateToggle(item)}
                  />
                </td>
                <td className="text-right nowrap">
                  <Link
                    to={pageState.getPagePath(routes.front.ACCOUNT_DOG_DETAIL, { ':id': item.id })}
                    className="d-inline-flex align-items-center mr-3"
                  >
                    <img src={colorIcoEdit} alt="ico" className="mr-2" />
                    Upravit
                  </Link>
                  <a
                    href="/"
                    className="d-inline-flex align-items-center"
                    onClick={(e) => {
                      e.preventDefault();
                      props.onDelete(item);
                    }}
                  >
                    <img src={colorIcoDelete} alt="ico" className="mr-2" />
                    Odebrat
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
};
