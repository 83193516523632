import React from 'react';
import { DecodedValueMap, QueryParamConfig, SetQuery } from 'use-query-params';

import { AuctionResponse } from '@types';
import { useAuctionsApi } from '@api/auctions';
import { BasePreloader, FrontActionsList, Pagination, SEO } from '@components';
import { FrontAuctioneerAuctionsFilter } from './FrontAuctioneerAuctionsFilter';

type FrontAuctioneerAuctionsProps = {
  id: string;
  auctions: AuctionResponse[];
  loaded: boolean;
  totalAuctions: number;
  query: DecodedValueMap<{
    page: QueryParamConfig<number | null | undefined, number>;
    perPage: QueryParamConfig<number | null | undefined, number>;
  }>;
  setQuery: SetQuery<{
    page: QueryParamConfig<number | null | undefined, number>;
    perPage: QueryParamConfig<number | null | undefined, number>;
  }>;
  loadAuctions: (id: string | number) => Promise<void>;
};

export const FrontAuctioneerAuctions = (props: FrontAuctioneerAuctionsProps) => {
  const auctionsApi = useAuctionsApi();
  const { auctions, id, loadAuctions, loaded, setQuery, totalAuctions, query } = props;
  const [joinedLoaded, setJoinedLoaded] = React.useState<string | number | undefined>();

  const handleAuctionJoin = async (auction: AuctionResponse) => {
    if (!!joinedLoaded) {
      return;
    }
    setJoinedLoaded(auction.id);
    try {
      await auctionsApi.joinAuction(auction.id);
      setJoinedLoaded(undefined);
      loadAuctions(id);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
      setJoinedLoaded(undefined);
    }
  };

  const handleAuctionIsFavouriteChange = async (auction: AuctionResponse, isFavorite: boolean) => {
    try {
      if (isFavorite) {
        await auctionsApi.addAuctionToFavourites(auction.id);
      } else {
        await auctionsApi.removeAuctionFromFavourites(auction.id);
      }
      loadAuctions(id);
    } catch (err) {}
  };

  return (
    <>
      <SEO title="Dražby dražebníka" />
      <h1 className="auctioneers-page-title">Dražby dražebníka</h1>
      <FrontAuctioneerAuctionsFilter values={query} onSubmit={(values) => setQuery(values, 'push')} />
      {!loaded ? (
        <div className="d-flex align-items-center justify-content-center">
          <BasePreloader />
        </div>
      ) : (
        <>
          {auctions.length > 0 ? (
            <FrontActionsList
              auctions={auctions}
              isLoading={!loaded}
              joinedLoaded={joinedLoaded}
              onAuctionJoin={handleAuctionJoin}
              onAuctionIsFavouriteChange={handleAuctionIsFavouriteChange}
            />
          ) : (
            <div className="auctioneers__box">Tento dražebník nemá žádné aktivní dražby/aukce.</div>
          )}
          {loaded && totalAuctions > query.perPage && (
            <div className="mt-2">
              <Pagination
                page={query.page}
                perPage={query.perPage}
                totalRows={totalAuctions}
                onPageChange={(page) => setQuery({ ...query, page: page }, 'push')}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};
