import React from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import { AuctioneerAddress, PermissionGroup, UserPermission } from '@types';
import { Button, Visible } from '@components';

import plusIco from '@assets/images/plus-ico.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';
import colorIcoDel from '@assets/images/color-ico-del.svg';
import colorIcoConf from '@assets/images/color-ico-conf.svg';

interface Props {
  data: Array<AuctioneerAddress>;
  onClickAdd: () => void;
  onUpdateClick: (item: AuctioneerAddress) => void;
  onDeleteClick: (item: AuctioneerAddress) => void;
}

const AddressTable: React.FC<Props> = (props) => {
  const handleCreateButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    props.onClickAdd();
  };

  const handleUpdateClick = (e: React.MouseEvent, item: AuctioneerAddress) => {
    e.preventDefault();
    props.onUpdateClick(item);
  };

  const handleDeleteClick = (e: React.MouseEvent, item: AuctioneerAddress) => {
    e.preventDefault();
    props.onDeleteClick(item);
  };

  return (
    <div>
      <div>
        <h2>Provozovny</h2>
        <Visible permissionGroupName={PermissionGroup.auctioneerAddress} permissionName={UserPermission.canCreate}>
          <Button
            className="f-size-12 f-weight-400 mt-3"
            onClick={handleCreateButtonClick}
            dataTestId="admin-auctioneer-address-create-btn"
          >
            <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
            <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
            Přidat provozovnu
          </Button>
        </Visible>
      </div>
      <div className="mt-4">
        <div className="responsive-table-content">
          <Table className="table-middle border-bottom-0" striped responsive>
            <thead>
              <tr>
                <th>Název</th>
                <th>Adresa</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {props.data.length < 1 && (
                <tr>
                  <td className="text-left" colSpan={100}>
                    Nebyla nalezena žádná data.
                  </td>
                </tr>
              )}
              {props.data.map((item, index) => (
                <tr key={`item-${index}`}>
                  <td>{item.name || '-'}</td>
                  <td>
                    {item.street} {item.houseNumber}, {item.city}, {item.zipCode}
                  </td>
                  <td className="text-right">
                    <Visible
                      permissionGroupName={PermissionGroup.auctioneerAddress}
                      permissionName={UserPermission.canEdit}
                    >
                      <Link
                        to="/"
                        className="d-inline-flex align-items-center"
                        onClick={(e) => handleUpdateClick(e, item)}
                      >
                        <img src={colorIcoConf} alt="ico" className="mr-2" />
                        Upravit
                      </Link>
                    </Visible>
                    <Visible
                      permissionGroupName={PermissionGroup.auctioneerAddress}
                      permissionName={UserPermission.canDelete}
                    >
                      <Link
                        to="/"
                        className="d-inline-flex align-items-center ml-4"
                        onClick={(e) => handleDeleteClick(e, item)}
                      >
                        <img src={colorIcoDel} alt="ico" className="mr-2" />
                        Smazat
                      </Link>
                    </Visible>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default AddressTable;
