import React from 'react';
import { Link } from 'react-router-dom';

import icoCloseCircleWhite from '@assets/images/ico-close-circle-white.svg';

import { User } from '@types';
import * as routes from '@routes';
import { LayoutFrontHeader, LayoutFrontFooter, FrontTopMenu } from '@components';
import { useAuthFrontStore, usePageStore, userRoleChecks } from '@stores';

const BaseFront: React.FC = (props) => {
  const { user } = useAuthFrontStore();
  const { isOfRoleUser, isOfRoleBuyer } = userRoleChecks(user as User);
  const pageState = usePageStore();
  const [hideTopBarAlert, setHideTopBarAlert] = React.useState(false);

  return (
    <>
      {isOfRoleUser() && !hideTopBarAlert && (
        <div className="buyer-registration-alert">
          <span>K přihlášení k dražbě je nutné dokončit registraci. Registraci dokončíte ve </span>
          <Link to={pageState.getPagePath(routes.front.ACCOUNT_AUCTIONEER_REGISTRATION)}>svém účtu</Link>.
          <img
            style={{ marginLeft: '8px', cursor: 'pointer' }}
            src={icoCloseCircleWhite}
            alt="close"
            onClick={() => setHideTopBarAlert(true)}
          />
        </div>
      )}
      {isOfRoleBuyer() && user?.verificationState === 'unverified' && !hideTopBarAlert && (
        <div className="buyer-registration-alert">
          <span>K aktivní účasti na více dražbách / aukcích je potřeba </span>
          <Link to={pageState.getPagePath(routes.front.ACCOUNT_DOCUMENTS)}>
            ověřit totožnost konvertovaným dokladem.
          </Link>
          <img
            style={{ marginLeft: '8px', cursor: 'pointer' }}
            src={icoCloseCircleWhite}
            alt="close"
            onClick={() => setHideTopBarAlert(true)}
          />
        </div>
      )}
      <div className="page-front">
        <div className="page-content">
          <LayoutFrontHeader />
          <FrontTopMenu />
          {props.children}
        </div>
        <LayoutFrontFooter />
      </div>
    </>
  );
};

export default BaseFront;
