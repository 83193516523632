import { useWebsocket } from '@websocket/base';

type Props = {
  onMessage: () => void;
};

export const useAuctionsListWebsocket = (props: Props) => {
  return useWebsocket({
    topic: `/auctions-change`,
    onMessage: () => {
      setTimeout(() => {
        props.onMessage();
      }, 50);
    },
  });
};
