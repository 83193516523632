import React from 'react';
import moment from 'moment';
import { Col, Container, Row } from 'react-bootstrap';

import FooterMenu from './component/FooterMenu';

import icoMail from '@assets/images/front/footer/ico-mail.svg';
import icoPhone from '@assets/images/front/footer/ico-phone.svg';
import icoFacebook from '@assets/images/front/icons/facebook-white-15.svg';
import icoInstagram from '@assets/images/front/icons/instagram-white-31.svg';

const LayoutFrontFooter: React.FC = () => (
  <div className="layout-front-footer">
    <Container fluid>
      <Row className="justify-content-between">
        <Col lg="auto" sm={6} xs={12}>
          <div className="footer-block">
            <div>
              <a href="tel:+420774740636" className="footer__contact">
                <img src={icoPhone} alt="ico" />
                +420 774 740 636
              </a>
              <a href="mailto:info@exdrazby.cz" className="footer__contact footer__contact--email">
                <img src={icoMail} alt="ico" />
                info@exdrazby.cz
              </a>
            </div>
            <p>
              <b>K dispozici:</b> Po-Čt 8:30-16:00, Pá 8:30-12:00
              <br />
              Portál je registrován u ÚOOÚ pod č. 00042980
            </p>
          </div>
        </Col>
        <Col lg="auto" sm={6} xs={12}>
          <div className="footer-block">
            <p>
              <span className="f-weight-700">JURIS REAL Dražby, a.s.</span>
              <br />
              IČ: 24140384
              <br />
              vedená u rejstříkového soudu v Praze pod spisovou značkou B 17354
            </p>
            <p>
              <span className="f-weight-700">Adresa</span>
              <br />
              Šrobárova 2100/49,
              <br />
              130 00 Praha 3 - Vinohrady
            </p>
          </div>
        </Col>
        <Col lg="auto" sm={6} xs={12}>
          <div className="footer-block">
            <FooterMenu />
          </div>
        </Col>
        <Col lg="auto" sm={6} xs={12}>
          <div className="footer-block">
            <div className="footer-social">
              <div className="footer-social-text">Sledujte nás na sociálních sítích</div>
              <div className="footer-social-sites">
                <a href="https://www.facebook.com/Exdrazby.cz" target="_blank" rel="noreferrer">
                  <img src={icoFacebook} alt="facebook" />
                </a>
                <a href="https://www.instagram.com/exdrazby.cz/" target="_blank" rel="noreferrer">
                  <img src={icoInstagram} alt="instagram" />
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
    <div className="copyright">© 2010-{moment().format('YYYY')} Exdrazby.cz</div>
  </div>
);

export default LayoutFrontFooter;
