import React, { useEffect } from 'react';

export const CaptchaWrapper: React.FC = (props) => {
  useEffect(() => {
    const badge = document.getElementsByClassName('grecaptcha-badge')[0];
    if (badge && badge instanceof HTMLElement) {
      badge.classList.add('captcha-visible');
    }

    return () => {
      if (badge && badge instanceof HTMLElement) {
        badge.classList.remove('captcha-visible');
      }
    };
  }, []);

  return <>{props.children}</>;
};
