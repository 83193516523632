import React from 'react';

import { AuctioneerTemplate } from '@types';
import { useAuctioneersApi } from '@api/auctioneers';
import { BasePreloader } from '@components';
import { closeMessage, confirmMessage } from '@helpers/messages';
import TemplatesTable from './TemplatesTable';
import TemplatesForm from './TemplatesForm';

interface Props {
  id: string;
  onNotFound: () => void;
}

const Templates: React.FC<Props> = (props) => {
  const auctioneersApi = useAuctioneersApi();
  const [loaded, setLoaded] = React.useState(false);
  const [showForm, setShowForm] = React.useState(false);
  const [updateItem, setUpdateItem] = React.useState<AuctioneerTemplate | undefined>();
  const [templatesData, setTemplatesData] = React.useState<AuctioneerTemplate[]>([]);

  React.useEffect(() => {
    loadData();
    return () => auctioneersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = () => {
    auctioneersApi
      .listTemplates(props.id)
      .then((res) => {
        setTemplatesData(res.data.data);
        setLoaded(true);
      })
      .catch((err) => {
        if (auctioneersApi.isCancel(err)) {
          return;
        }
        if (err?.response?.status === 404 || err?.response?.status === 403) {
          props.onNotFound();
          return;
        }
      });
  };

  const handleAddClick = () => {
    setUpdateItem(undefined);
    setShowForm(true);
  };

  const handleUpdateClick = (item: AuctioneerTemplate) => {
    setUpdateItem(item);
    setShowForm(true);
  };

  const handleChangeDefault = async (item: AuctioneerTemplate, isDefault: boolean) => {
    const data = JSON.parse(item.data);
    try {
      await auctioneersApi.updateTemplate(item.name, item.auctionType, isDefault, data, props.id, item.id.toString());
      loadData();
    } catch (err) {
      if (auctioneersApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleClose = () => {
    setShowForm(false);
    loadData();
  };

  const handleDeleteClick = (item: AuctioneerTemplate) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat tuto položku?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return auctioneersApi.deleteTemplate(props.id, item.id.toString()).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadData();
      }
    });
  };

  if (!loaded) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <div>
      {showForm ? (
        <TemplatesForm
          id={props.id}
          updatedItem={updateItem}
          setUpdateItem={setUpdateItem}
          auctionTemplateId={updateItem?.id.toString()}
          default={updateItem?.isDefault}
          name={updateItem?.name}
          auctionType={updateItem?.auctionType}
          onClose={handleClose}
          onSubmit={() => setShowForm(false)}
        />
      ) : (
        <TemplatesTable
          data={templatesData}
          onClickAdd={handleAddClick}
          onUpdateClick={handleUpdateClick}
          onDeleteClick={handleDeleteClick}
          onChangeDefault={handleChangeDefault}
        />
      )}
    </div>
  );
};

export default Templates;
