import { BaseFrontPage } from '@components';
import {
  FrontSidebarBanners,
  FrontSidebarMenu,
  FrontSidebarNotifications,
  FrontSidebarStatistics,
} from '@components/FrontSidebarBox';
import { NewsDetail } from '@components/News/NewsDetail';

export const NewsPageDetail = () => {
  return (
    <BaseFrontPage
      disableScrollToTop
      sidebarItems={[
        <FrontSidebarMenu title="Dražby a aukce" />,
        <FrontSidebarNotifications title="Upozornění na Váš e-mail" />,
        <FrontSidebarStatistics title="Exdražby v číslech" />,
        <FrontSidebarBanners />,
      ]}
    >
      <NewsDetail />
    </BaseFrontPage>
  );
};
