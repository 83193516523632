import { API, APIResponse, useApi } from '@api/api';
import { EnumType, TextBlockContent, TextBlockContentsResponse, TextBlockType } from '@types';

export const textBlocksPath = () => `/admin/text-block`;

export const textBlockPath = (textBlockId: string | number) => `/admin/text-block/${textBlockId}`;

export const textBlockLanguagesPats = () => '/enum/text-block/allowed-languages';

export const createTextBlockContentPath = () => '/admin/text-block-content';

export const textBlockContentPath = (textBlockContentId: string | number) =>
  `/admin/text-block-content/${textBlockContentId}`;

export const textBlockContentsTextBlockPath = (textBlockId: string | number) =>
  `/admin/text-block/${textBlockId}/text-block-content`;

export const textBlockContentTextBlockPath = (textBlockId: string | number, textBlockContentId: string | number) =>
  `/admin/text-block/${textBlockId}/text-block-content/${textBlockContentId}`;

export interface TextBlockAPI extends API {
  listTextBlock(): APIResponse<Array<TextBlockType>>;
  createTextBlock(textBlock: Partial<TextBlockType>): APIResponse;
  updateTextBlock(textBlockId: number | string, textBlock: Partial<TextBlockType>): APIResponse;
  detailTextBlock(textBlockId: number | string): APIResponse<TextBlockType>;
  deleteTextBlock(textBlockId: number | string): APIResponse;
  listAllowedLanguages(): APIResponse<Array<EnumType>>;
  createTextBlockContent(textBlockContent: Partial<TextBlockContent>): APIResponse;
  updateTextBlockContent(textBlockContentId: string | number, textBlockContent: Partial<TextBlockContent>): APIResponse;
  deleteTextBlockContent(textBlockContentId: string | number): APIResponse;
  listTextBlockContentsOfTextBlock(textBlockId: string | number): APIResponse<Array<TextBlockContentsResponse>>;
  detailTextBlockContentOfTextBlock(
    textblockId: string | number,
    textBlockContentId: string | number
  ): APIResponse<TextBlockContentsResponse>;
}

export const useTextBlockApi = (): TextBlockAPI => {
  const api = useApi();

  return {
    ...api,
    listTextBlock() {
      const cancelToken = api.prepareCancelToken('listTextBlock');
      return api.authRequest.get(textBlocksPath(), { cancelToken });
    },
    createTextBlock(textBlock: Partial<TextBlockType>) {
      const cancelToken = api.prepareCancelToken('createTextBlock');
      return api.authRequest.post(textBlocksPath(), textBlock, { cancelToken });
    },
    detailTextBlock(textBlockId: number | string) {
      const cancelToken = api.prepareCancelToken('detailTextBlock');
      return api.authRequest.get(textBlockPath(textBlockId), { cancelToken });
    },
    updateTextBlock(textBlockId: number | string, textBlock: Partial<TextBlockType>) {
      const cancelToken = api.prepareCancelToken('updateTextBlock');
      return api.authRequest.put(textBlockPath(textBlockId), textBlock, { cancelToken });
    },
    deleteTextBlock(textBlockId: number | string) {
      const cancelToken = api.prepareCancelToken('deleteTextBlock');
      return api.authRequest.delete(textBlockPath(textBlockId), { cancelToken });
    },
    listAllowedLanguages() {
      const cancelToken = api.prepareCancelToken('listAllowedLanguages');
      return api.authRequest.get(textBlockLanguagesPats(), { cancelToken });
    },
    createTextBlockContent(textBlockContent: Partial<TextBlockContent>) {
      const cancelToken = api.prepareCancelToken('createTextBlockContent');
      return api.authRequest.post(createTextBlockContentPath(), textBlockContent, { cancelToken });
    },
    updateTextBlockContent(textBlockContentId: number | string, textBlockContent: Partial<TextBlockContent>) {
      const cancelToken = api.prepareCancelToken('updateTextBlockContent');
      return api.authRequest.put(textBlockContentPath(textBlockContentId), textBlockContent, { cancelToken });
    },
    deleteTextBlockContent(textBlockContentId: number | string) {
      const cancelToken = api.prepareCancelToken('deleteTextBlockContent');
      return api.authRequest.delete(textBlockContentPath(textBlockContentId), { cancelToken });
    },
    listTextBlockContentsOfTextBlock(textBlockId: number | string) {
      const cancelToken = api.prepareCancelToken('listTextBlockContentsOfTextBlock');
      return api.authRequest.get(textBlockContentsTextBlockPath(textBlockId), { cancelToken });
    },
    detailTextBlockContentOfTextBlock(textBlockId: number | string, textBlockContentId: number | string) {
      const cancelToken = api.prepareCancelToken('detailTextBlockContentOfTextBlock');
      return api.authRequest.get(textBlockContentTextBlockPath(textBlockId, textBlockContentId), { cancelToken });
    },
  };
};
