import React from 'react';

import { Modal } from '@components';
import { ModalProps } from '@components/Modal/Modal';
import { ServiceForAuctioneer } from '@types';

interface Props extends ModalProps {
  data: ServiceForAuctioneer;
  actionText: string;
  onAction: () => void;
  onRequestClose: () => void;
}

const ServiceDetailModal: React.FC<Props> = (props) => {
  return (
    <Modal className="service-detail-modal-parent" {...props}>
      <div className="service-detail-modal">
        <h5>Ceník</h5>
        <div className="items-wrapper">
          <div className="items-holder">
            <p className="font-weight-bold">Cenové rozpětí</p>
            <p className="font-weight-bold">Procenta</p>
            <p className="font-weight-bold">Paušální částka</p>
          </div>
          <div className="items-holder">
            <p className="mr-2">0 - 30 000:</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.percent30000} %</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.plus30000} Kč</p>
          </div>
          <div className="items-holder">
            <p className="mr-2">30 001 - 300 0000:</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.percent300000} %</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.plus300000} Kč</p>
          </div>
          <div className="items-holder">
            <p className="mr-2">300 001 - 1 000 000:</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.percent1000000} %</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.plus1000000} Kč</p>
          </div>
          <div className="items-holder">
            <p className="mr-2">1 000 001 - 3 000 000:</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.percent3000000} %</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.plus3000000} Kč</p>
          </div>
          <div className="items-holder">
            <p className="mr-2">3 000 001 - 10 000 000:</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.percent10000000} %</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.plus10000000} Kč</p>
          </div>
          <div className="items-holder">
            <p className="mr-2">10 000 001 - 999 999 999:</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.percent999999999} %</p>
            <p>{props.data.serviceForAuctioneerElectronicAuction.plus999999999} Kč</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ServiceDetailModal;
