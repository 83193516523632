import React from 'react';
import { FormikErrors, FormikValues } from 'formik';

import { AuctioneerTemplate, AuctionInformationsCType } from '@types';
import TemplatesInformationTable from './TemplatesInformationTable';
import TemplatesInformationForm from './TemplatesInformationForm';

interface Props {
  id: string;
  values: FormikValues;
  isSubmitting: boolean;
  updatedItem?: AuctioneerTemplate;
  errors: FormikErrors<FormikValues>;
  getTranslation: (key: string) => string;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
  auctionTemplateId?: string;
}

const TemplatesInformations: React.FC<Props> = (props) => {
  const { values } = props;

  const [showForm, setShowForm] = React.useState<boolean>(false);
  const [itemEdit, setItemEdit] = React.useState<AuctionInformationsCType | undefined>(undefined);

  const handleEditClick = (item: AuctionInformationsCType) => {
    setItemEdit(item);
    setShowForm(true);
  };

  const handleFormClose = () => {
    setShowForm(false);
    setItemEdit(undefined);
  };

  return (
    <div className="mt-4">
      {showForm && (
        <TemplatesInformationForm
          id={props.id}
          item={itemEdit}
          onClose={handleFormClose}
          auctionType={values.data.auctionType}
          values={props.values}
          setFieldValue={props.setFieldValue}
          setShowForm={setShowForm}
          title="Úprava informace"
          isOpen={showForm}
          updatedItem={props.updatedItem}
          auctionTemplateId={props.auctionTemplateId}
          isSubmitting={props.isSubmitting}
          errors={props.errors}
        />
      )}
      <TemplatesInformationTable
        type="c"
        title="Informace typ C - (automatické možnosti na konci vydraženo/nevydraženo)"
        data={values.data.informations}
        onEditClick={(item) => handleEditClick(item)}
      />
    </div>
  );
};

export default TemplatesInformations;
