import React from 'react';
import moment from 'moment';
import { Col, Container, Row } from 'react-bootstrap';

const LayoutFooter: React.FC = () => (
  <div className="layout-footer d-flex align-items-center">
    <Container fluid>
      <Row>
        <Col xs={12}>
          <p className="float-none float-lg-left mb-2 mb-lg-0">
            © Copyright {moment().format('YYYY')} exdražby | Všechna práva vyhrazena
          </p>
          <p className="float-none float-lg-right mb-0">
            web: <strong>Svět IT</strong>
          </p>
        </Col>
      </Row>
    </Container>
  </div>
);

export default LayoutFooter;
