import React from 'react';

import { NewsImageType } from '@types';
import { useMediaApi } from '@api/media';

import noImage from '@assets/images/front/news/no-image.jpg';

interface Props {
  images: NewsImageType[];
}

const NewsImage: React.FC<Props> = (props) => {
  const mediaApi = useMediaApi();
  const [imageUrl, setImageUrl] = React.useState('');

  React.useEffect(() => {
    loadImages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadImages = async () => {
    await loadImage('news.photo.thumbnail');
  };

  const loadImage = async (size?: string) => {
    try {
      const media = await mediaApi.detailFront(props.images[0].media.hash, true, false, size);
      const urlCreator = window.URL || window.webkitURL;
      const imageUrl = urlCreator.createObjectURL(media.data);
      setImageUrl(imageUrl);
    } catch (err) {
      console.error(err);
      setImageUrl(props.images[0].id.toString());
    }
  };

  return (
    <div className="detail-image">
      <img src={imageUrl || noImage} alt="Actuality" />
    </div>
  );
};

export default NewsImage;
