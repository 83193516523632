import React from 'react';
import { Link } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { Formik, FormikValues } from 'formik';

import { Modal, Button, BasePreloader, DatePickerInput } from '@components';
import moment from 'moment';

interface Props {
  date?: string;
  onCancel: () => void;
  onConfirm: (validity: Date) => void;
}

const ApprovedModal: React.FC<Props> = (props) => {
  const handleFormSubmit = async (values: FormikValues) => {
    await props.onConfirm(values.validity);
  };

  return (
    <Modal isOpen title="Schválení dokumentu" onRequestClose={props.onCancel} className="modal-content-sm">
      <Formik
        initialValues={{
          validity: props.date ? moment(props.date).toDate() : moment().add('years', 3).toDate(),
        }}
        onSubmit={handleFormSubmit}
      >
        {(formProps) => (
          <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formProps.handleSubmit(e)}>
            <Form.Group>
              <Form.Label>
                <span>Opravdu si přejete schválit tento dokument?</span>
              </Form.Label>
            </Form.Group>
            <Form.Group>
              <Form.Label>
                <span>Doba platnosti dokumentu</span>
              </Form.Label>
              <DatePickerInput
                className="form-control"
                value={formProps.values.validity}
                onChange={(val) => formProps.setFieldValue('validity', val)}
              />
            </Form.Group>
            {formProps.isSubmitting ? (
              <BasePreloader size={34} />
            ) : (
              <>
                <Button type="submit" variant="btn-outline-primary">
                  Schválit dokument
                </Button>
                <Link
                  to="#"
                  className="d-inline-flex align-items-center"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();

                    props.onCancel();
                  }}
                  style={{ marginLeft: '32px' }}
                >
                  Zrušit
                </Link>
              </>
            )}
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default ApprovedModal;
