import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import * as routes from '@routes';
import { BaseAuth, SEO } from '@components';
import { usePageStore } from '@stores';
import AuthLoginPage from './pages/auth/AuthLoginPage/AuthLoginPage';
import AuthActivationPage from './pages/auth/AuthActivationPage/AuthActivationPage';
import AuthResetPasswordPage from './pages/auth/AuthResetPasswordPage/AuthResetPasswordPage';

const AuthRoutes: React.FC = () => {
  const pageState = usePageStore();

  return (
    <BaseAuth>
      <SEO />
      <Switch>
        {!!pageState.getPage(routes.auth.LOGIN) && (
          <Route exact path={pageState.getPagePath(routes.auth.LOGIN)}>
            <AuthLoginPage />
          </Route>
        )}
        {!!pageState.getPage(routes.auth.RESET_PASSWORD) && (
          <Route exact path={pageState.getPagePath(routes.auth.RESET_PASSWORD)}>
            <AuthResetPasswordPage />
          </Route>
        )}
        {!!pageState.getPage(routes.auth.ACTIVATION) && (
          <Route exact path={pageState.getPagePath(routes.auth.ACTIVATION)}>
            <AuthActivationPage />
          </Route>
        )}
        {!!pageState.getPage(routes.auth.LOGIN) && <Redirect from="*" to={pageState.getPagePath(routes.auth.LOGIN)} />}
      </Switch>
    </BaseAuth>
  );
};

export default AuthRoutes;
