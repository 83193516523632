import React from 'react';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';

import { useAuctionsApi } from '@api/auctions';
import { Button, FormGroup, Modal, Select } from '@components';

interface Props {
  auctionId: string | number;
  isOpen: boolean;
  onClose: (reload?: boolean) => void;
}

const VideoModal: React.FC<Props> = (props) => {
  const auctionsApi = useAuctionsApi();

  const videoTypeOptions = [
    {
      value: 'youtube',
      label: 'YouTube',
    },
    {
      value: 'matterport',
      label: 'Matterport',
    },
  ];
  const formik = useFormik({
    initialValues: {
      mime: videoTypeOptions[0].value,
      data: '',
    },
    validateOnChange: false,
    onSubmit: () => handleSubmit(),
  });

  const save = async () => {
    try {
      await auctionsApi.createVideo(props.auctionId, formik.values);
      formik.setValues(formik.initialValues);
      formik.setErrors({});
      props.onClose(true);
    } catch (err) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleSubmit = () => {
    if (!formik.values.data.trim()) {
      formik.setFieldError('data', 'Zadejte prosím identifikátor videa.');
      return;
    }
    formik.setErrors({});
    save();
  };

  const handleRequestClose = () => {
    formik.setValues(formik.initialValues);
    formik.setErrors({});
    props.onClose();
  };

  return (
    <div>
      <Modal title="Nahrát video" isOpen={props.isOpen} onRequestClose={handleRequestClose}>
        <Form onSubmit={formik.handleSubmit}>
          <div className="form-group">
            <div className="form-label mb-2">Zdroj *</div>
            <Select
              options={videoTypeOptions}
              value={videoTypeOptions.find((i) => i.value === formik.values.mime)}
              onChange={(val) => formik.setFieldValue('mime', val?.value)}
            ></Select>
          </div>
          <div className="form-group">
            <div className="form-label mb-2">Identifikátor *</div>
            <FormGroup
              controlOnly
              name="data"
              controlClassName="input-md"
              error={formik.errors.data}
              value={formik.values.data}
              onChange={formik.handleChange}
            />
          </div>
          <div className="form-group mt-35">
            <Button type="submit">Odeslat video</Button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default VideoModal;
