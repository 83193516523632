import React from 'react';

import { BaseAccountPage } from '@components';
import * as routes from '@routes';
import Documents from './components/Documents';
import SupportDocuments from './components/SupportDocuments';

const AccountDocumentsPage: React.FC = () => {
  return (
    <BaseAccountPage page={routes.front.ACCOUNT_DOCUMENTS}>
      <Documents />
      <SupportDocuments />
    </BaseAccountPage>
  );
};

export default AccountDocumentsPage;
