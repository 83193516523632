import React from 'react';
import classNames from 'classnames';

import AdminBoxContent from '../AdminBoxContent/AdminBoxContent';

interface Props {
  isOpen?: boolean;
}

const AdminFiltersBox: React.FC<Props> = (props) => (
  <AdminBoxContent className="p-0">
    <div className={classNames(['component-admin-filters-box', { 'block-open': props.isOpen }])}>{props.children}</div>
  </AdminBoxContent>
);

export default AdminFiltersBox;
