import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import * as routes from '@routes';
import { WatchDogType } from '@types';
import { usePageStore } from '@stores';
import { useWatchDogApi } from '@api/watchDog';
import { closeMessage, confirmMessage } from '@helpers/messages';
import { BaseAccountPage, AccountWatchdogList, BasePreloader, FrontAccountBox, Button } from '@components';

const AccountWatchdogPage: React.FC = () => {
  const watchdogApi = useWatchDogApi();
  const history = useHistory();
  const pageState = usePageStore();
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState<WatchDogType[]>([]);
  const page = pageState.getPage(routes.front.ACCOUNT_DOG);

  useEffect(() => {
    loadData();
    return () => watchdogApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async (loadedDisable?: boolean) => {
    if (!loadedDisable) {
      setItems([]);
      setIsLoaded(false);
    }
    try {
      const res = await watchdogApi.getWatchDogList();
      if (res.data.data.length > 0) {
        setIsLoaded(true);
        setItems(res.data.data);
      } else {
        history.push(pageState.getPagePath(routes.front.ACCOUNT_DOG_CREATE));
      }
    } catch (err: any) {
      if (watchdogApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleDeleteClick = (item: WatchDogType) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat tuto položku?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return watchdogApi.deleteWatchDog(item.id.toString()).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadData(true);
      }
    });
  };

  const handleToggleStateClick = (item: WatchDogType) => {
    confirmMessage({
      title: 'Potvrzení',
      text: `Opravdu si přejete ${item.state !== 'active' ? 'aktivovat' : 'deaktivovat'} tuto položku?`,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return watchdogApi
          .saveWatchDog(
            {
              title: item.title,
              state: item.state !== 'active' ? 'active' : 'deactive',
              priceFrom: (item.priceFrom || '').toString(),
              priceTo: (item.priceTo || '').toString(),
              keywords: item.keywords,
              trackAll: item.trackAll,
              watchDogInterval: item.watchDogInterval,
              auctionWatchDogCategories: item.auctionWatchDogCategories.map((i) => ({
                auctionCategory: i.auctionCategory.id,
              })),
              auctionWatchDogRegions: item.auctionWatchDogRegions.map((i) => ({
                region: i.region.id,
                auctionWatchDogDistricts: i.auctionWatchDogDistricts.map((d) => ({ district: d.district.id })),
              })),
            },
            item.id
          )
          .catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && res.value.status === 200) {
        loadData(true);
      }
    });
  };

  return (
    <BaseAccountPage page={routes.front.ACCOUNT_DOG}>
      <FrontAccountBox>
        <h2 className="box-title mb-3">{page?.name}</h2>
        {isLoaded ? (
          <div>
            <AccountWatchdogList items={items} onDelete={handleDeleteClick} stateToggle={handleToggleStateClick} />
            <Button to={pageState.getPagePath(routes.front.ACCOUNT_DOG_CREATE)} className="mt-2">
              Přidat hlídacího psa
            </Button>
          </div>
        ) : (
          <div className="d-flex justify-content-center pt-5 pb-5">
            <BasePreloader />
          </div>
        )}
      </FrontAccountBox>
    </BaseAccountPage>
  );
};

export default AccountWatchdogPage;
