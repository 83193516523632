import React from 'react';
import classNames from 'classnames';

interface Tab {
  text: React.ReactNode;
  value?: any;
}

interface Props {
  items: Array<Tab>;
  value: any;
  onClick?: (tab: Tab) => any;
}

const Tabs: React.FC<Props> = (props) => {
  const handleClick = (e: any, tab: Tab) => {
    e.preventDefault();
    if (typeof props.onClick === 'function') {
      props.onClick(tab);
    }
  };

  return (
    <div className="component-tabs">
      <ul className="component-tabs-items">
        {props.items.map((item, index) => (
          <li
            key={`${index}-${item.text}`}
            className={classNames('component-tabs-item', {
              isActive: item.value === props.value,
            })}
            onClick={(e) => handleClick(e, item)}
          >
            <span className="item-text">{item.text}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Tabs;
