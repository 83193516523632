import React from 'react';
import SortableTree, { TreeItem } from 'react-sortable-tree';

interface Props {
  data: TreeItem[];
  onDataChange: (data: TreeItem[]) => void;
}

const PagesTable: React.FC<Props> = (props) => {
  return (
    <div>
      <SortableTree
        isVirtualized={false}
        canDrag={false}
        treeData={props.data}
        onChange={() => {}}
        onVisibilityToggle={(e) => props.onDataChange(e.treeData)}
      />
    </div>
  );
};

export default PagesTable;
