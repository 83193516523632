import React from 'react';
import parse from 'html-react-parser';

import { AuctionParameterResponse, AuctionResponse } from '@types';
import FrontTabsMenu from '@components/FrontTabsMenu/FrontTabsMenu';
import AuctionParams from '@components/FrontAuctionDetail/components/AuctionParams';
import AuctionDocumentTable from '@components/FrontAuctionDetail/components/AuctionDocumentTable';

interface Props {
  auctionDetail: AuctionResponse;
  activeSection: number;
  onChange: (section: number) => void;
  auctionParameterComplete?: AuctionParameterResponse[];
}

const AuctionTabs: React.FC<Props> = (props) => {
  const menuItems = [
    { label: props.auctionDetail.translations?.tab_description, isActive: props.activeSection === 0 },
    { label: props.auctionDetail.translations?.tab_all_informations, isActive: props.activeSection === 1 },
    { label: props.auctionDetail.translations?.tab_documents, isActive: props.activeSection === 2 },
  ];

  const getTabContent = () => {
    if (props.activeSection === 0) {
      const { auctionDetail } = props;
      return (
        <div className="auction-tabs-content">
          <div className="row">
            {auctionDetail.description && <div className="mb-2 col-sm-12">{parse(auctionDetail.description)}</div>}
          </div>
        </div>
      );
    }

    if (props.activeSection === 1) {
      return (
        <div className="auction-tabs-content">
          <div className="row">
            <div className="mb-2 col-sm-12">
              <AuctionParams
                isMoreInfo={true}
                auction={props.auctionDetail}
                parameterComplete={props.auctionParameterComplete}
              />
            </div>
          </div>
        </div>
      );
    }

    if (props.activeSection === 2) {
      const { auctionPublicDocuments } = props.auctionDetail;

      return (
        <div className="auction-tabs-content">
          <div className="row">
            <div className="mb-2 col-sm-12">
              <AuctionDocumentTable auctionPublicDocuments={auctionPublicDocuments} />
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <FrontTabsMenu items={menuItems} onClick={(index) => props.onChange(index)} />
      {getTabContent()}
    </>
  );
};

export default AuctionTabs;
