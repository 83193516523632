import { useState, useEffect } from 'react';
import { isArray } from 'lodash';
import Cookies from 'js-cookie';

import config from '@config';
import { useAuthApi } from '@api/auth';
import { createStore, useStoreState } from './store';
import { User, AllowedUserState, AllowedRole, Auctioneer } from '@types';

export interface AuthState {
  mainLogged?: boolean;
  isLogged: boolean;
  user?: User;
  auctioneer?: Auctioneer;
  allowedUserStates: AllowedUserState[];
  allowedUserRoles: {
    [userType: string]: AllowedRole[];
  };
}

export const authStore = createStore<AuthState>({
  mainLogged: false,
  isLogged: false,
  user: undefined,
  auctioneer: undefined,
  allowedUserStates: [],
  allowedUserRoles: {},
});

export const useAuthStore = () => {
  const mainLoggedPasswd = 'exdrazbydev123456';
  const authState = useStoreState(authStore);

  useEffect(() => {
    authStore.setState({ mainLogged: Cookies.get('mainLogged') === '1' || config.appEnv === 'production' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setMainLogged = () => {
    Cookies.set('mainLogged', '1', { expires: 365 });
    authStore.setState({ mainLogged: true });
  };

  const setUser = (user: User): void => {
    authStore.setState({
      user,
      isLogged: true,
    });
  };

  const setAuctioneer = (auctioneer?: Auctioneer): void => {
    authStore.setState({
      auctioneer,
    });
  };

  const unsetUser = (): void => {
    authStore.setState({
      isLogged: false,
      user: undefined,
    });

    clearAuthCookies();
  };

  const setAuthCookies = (token: string, refreshToken: string): void => {
    Cookies.set(config.auth.accessTokenCookieName, token, { expires: 365 });
    Cookies.set(config.auth.refreshTokenCookieName, refreshToken, { expires: 365 });
  };

  const clearAuthCookies = (): void => {
    Cookies.remove(config.auth.accessTokenCookieName);
    Cookies.remove(config.auth.refreshTokenCookieName);
  };

  return {
    ...authState,
    mainLoggedPasswd,
    setMainLogged,
    setUser,
    unsetUser,
    setAuctioneer,
    setAuthCookies,
    clearAuthCookies,
  };
};

export const useAllowedUserRoles = (userType: 'admin' | 'user' | 'auctioneer') => {
  const authState = useAuthStore();
  const [isLoading, setIsLoading] = useState(false);
  const { getAllowedUserRoles } = useAuthApi();

  const loadAllowedUserStates = async () => {
    setIsLoading(true);

    try {
      const response = await getAllowedUserRoles(userType);
      authStore.setState(({ allowedUserRoles, ...rest }) => ({
        ...rest,
        allowedUserRoles: {
          ...allowedUserRoles,
          [userType]: response.data.data,
        },
      }));
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isArray(authState.allowedUserRoles[userType]) || authState.allowedUserRoles[userType].length < 1) {
      loadAllowedUserStates();
    }
    // eslint-disable-next-line
  }, []);

  return {
    isLoading,
    allowedUserRoles: isArray(authState.allowedUserRoles[userType]) ? authState.allowedUserRoles[userType] : [],
    resolveUserRoleText: (userRole: string | Array<string>): string => {
      const userRoles = isArray(userRole) ? userRole : [userRole];

      if (!isArray(authState.allowedUserRoles[userType]) || userRoles.length < 1) {
        return '-';
      }

      return authState.allowedUserRoles[userType]
        .filter((allowedRole) => ~userRoles.indexOf(allowedRole.type))
        .map((allowedRole) => allowedRole.translated)
        .join(', ');
    },
  };
};

export const useAllowedUserStates = () => {
  const authState = useAuthStore();
  const [isLoading, setIsLoading] = useState(false);
  const { getAllowedUserStates } = useAuthApi();

  const loadAllowedUserStates = async () => {
    setIsLoading(true);

    try {
      const response = await getAllowedUserStates();
      authStore.setState((current) => ({
        ...current,
        allowedUserStates: response.data,
      }));
    } catch (err) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (authState.allowedUserStates.length < 1) {
      loadAllowedUserStates();
    }
    // eslint-disable-next-line
  }, []);

  return {
    isLoading,
    allowedUserStates: isArray(authState.allowedUserStates) ? authState.allowedUserStates : [],
    resolveUserStateText: (userState: string): string => {
      if (isArray(authState.allowedUserStates)) {
        for (const index in authState.allowedUserStates) {
          if (authState.allowedUserStates[index].state === userState) {
            return authState.allowedUserStates[index].name;
          }
        }
      }

      return '-';
    },
  };
};
