import { ServiceForAuctioneer, ServicesType } from '@types';
import { API, APIResponse, useApi } from '@api/api';

export const servicesForAuctioneerPath = () => `/admin/service-for-auctioneer`;

export const serviceForAuctioneerPath = (serviceForAuctioneerId: number | string) =>
  `/admin/service-for-auctioneer/${serviceForAuctioneerId}`;

export const serviceForAuctioneerRestorePath = (serviceForAuctioneerId: number | string) =>
  `/admin/service-for-auctioneer/${serviceForAuctioneerId}/restore`;

export const serviceForAuctioneerActivateForAllPath = (serviceForAuctioneerId: number | string) =>
  `/admin/service-for-auctioneer/${serviceForAuctioneerId}/activate-for-all`;

export const servicesEnumPath = () => '/enum/auctioneer/service-types';

export const vatEnumPath = () => '/enum/auction/vat';

export interface ServicesAPI extends API {
  list(): APIResponse<Array<ServiceForAuctioneer>>;
  create(serviceForAuctioneer: Partial<ServiceForAuctioneer>): APIResponse<ServiceForAuctioneer>;
  update(
    serviceForAuctioneerId: number | string,
    serviceForAuctioneer: Partial<ServiceForAuctioneer>
  ): APIResponse<ServiceForAuctioneer>;
  delete(serviceForAuctioneerId: number | string): APIResponse;
  restore(serviceForAuctioneerId: number | string): APIResponse;
  activateForAll(serviceForAuctioneerId: number | string): APIResponse;
  servicesEnum(): APIResponse<Array<ServicesType>>;
  vatEnum(): APIResponse<Array<ServicesType>>;
}

export const useServicesApi = (): ServicesAPI => {
  const api = useApi();

  return {
    ...api,
    list() {
      const cancelToken = api.prepareCancelToken('list');
      return api.authRequest.get(servicesForAuctioneerPath(), { cancelToken });
    },
    create(serviceForAuctioneer: Partial<ServiceForAuctioneer>) {
      const cancelToken = api.prepareCancelToken('create');
      return api.authRequest.post(servicesForAuctioneerPath(), serviceForAuctioneer, { cancelToken });
    },
    update(serviceForAuctioneerId: number | string, serviceForAuctioneer: Partial<ServiceForAuctioneer>) {
      const cancelToken = api.prepareCancelToken('update');
      return api.authRequest.put(serviceForAuctioneerPath(serviceForAuctioneerId), serviceForAuctioneer, {
        cancelToken,
      });
    },
    delete(serviceForAuctioneerId: number | string) {
      const cancelToken = api.prepareCancelToken('delete');
      return api.authRequest.delete(serviceForAuctioneerPath(serviceForAuctioneerId), { cancelToken });
    },
    restore(serviceForAuctioneerId: number | string) {
      const cancelToken = api.prepareCancelToken('restore');
      return api.authRequest.get(serviceForAuctioneerRestorePath(serviceForAuctioneerId), { cancelToken });
    },
    activateForAll(serviceForAuctioneerId: number | string) {
      const cancelToken = api.prepareCancelToken('activateForAll');
      return api.authRequest.get(serviceForAuctioneerActivateForAllPath(serviceForAuctioneerId), { cancelToken });
    },
    servicesEnum() {
      const cancelToken = api.prepareCancelToken('servicesEnum');
      return api.authRequest.get(servicesEnumPath(), { cancelToken });
    },
    vatEnum() {
      const cancelToken = api.prepareCancelToken('vatEnum');
      return api.authRequest.get(vatEnumPath(), { cancelToken });
    },
  };
};
