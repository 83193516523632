import registracePortalu from '@assets/images/front/hint/ico-hint-registrace-portalu.svg';
import registracePortaluHover from '@assets/images/front/hint/ico-hint-registrace-portalu-white.svg';
import podminkyUcasti from '@assets/images/front/hint/ico-hint-ucast-v-drazbe.svg';
import podminkyUcastiHover from '@assets/images/front/hint/ico-hint-ucast-v-drazbe-white.svg';
import jakDrazit from '@assets/images/front/hint/ico-hint-jak-drazit.svg';
import jakDrazitHover from '@assets/images/front/hint/ico-hint-jak-drazit-white.svg';
import jakHledat from '@assets/images/front/hint/ico-hint-jak-hledat.svg';
import jakHledatHover from '@assets/images/front/hint/ico-hint-jak-hledat-white.svg';
import pojmy from '@assets/images/front/hint/ico-hint-pojmy.svg';
import pojmyHover from '@assets/images/front/hint/ico-hint-pojmy-white.svg';

export type HintIcons = {
  icon: string;
  hoverIcon: string;
};

export const hintDataIcons = (routeName: string): HintIcons => {
  switch (routeName) {
    case 'registration-on-portal':
      return {
        icon: registracePortalu,
        hoverIcon: registracePortaluHover,
      };
    case 'how-to-meet-conditions-for-participation-in-auction':
      return {
        icon: podminkyUcasti,
        hoverIcon: podminkyUcastiHover,
      };
    case 'how-to-bid':
      return {
        icon: jakDrazit,
        hoverIcon: jakDrazitHover,
      };
    case 'how-to-search-auctions':
      return {
        icon: jakHledat,
        hoverIcon: jakHledatHover,
      };
    case 'concepts':
      return {
        icon: pojmy,
        hoverIcon: pojmyHover,
      };
    default:
      return {
        icon: registracePortalu,
        hoverIcon: registracePortaluHover,
      };
  }
};
