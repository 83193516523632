import React from 'react';
import { Form } from 'react-bootstrap';
import { FormikValues, useFormik } from 'formik';

import { useAuctioneersApi } from '@api/auctioneers';
import { BasePreloader, FormGroup, Button } from '@components';
import { AuctioneerPDData } from '@types';

interface Props {
  id: string;
  data?: AuctioneerPDData;
  onSubmit: () => void;
  onClose: () => void;
  onSave: () => void;
}

const DataAdministrationForm: React.FC<Props> = (props) => {
  const auctioneersApi = useAuctioneersApi();

  const formik = useFormik({
    initialValues: {
      login: props.data?.login || '',
      password: props.data?.password || '',
      hash: props.data?.hash || '',
    },
    onSubmit: (values) => handleSubmit(values),
  });

  React.useEffect(() => {
    return () => auctioneersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values: FormikValues) => {
    (!!props.data ? auctioneersApi.updatePDData(props.id, values) : auctioneersApi.createPDData(props.id, values))
      .then(() => {
        props.onSave();
      })
      .catch((err) => {
        if (auctioneersApi.isCancel(err)) {
          return;
        }
        const errors = err.response?.data?.errors || {};
        Object.getOwnPropertyNames(errors).map((prop) => {
          formik.setFieldError(prop, errors[prop][0]);
          return prop;
        });
        formik.setSubmitting(false);
      });
  };

  return (
    <div>
      <h2>{!!props.data ? 'Upravit údaje' : 'Přidat údaje'}</h2>
      <div>
        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-5">
          <div className="responsive-table-content">
            <div className="pt-1">
              <FormGroup
                required
                type="text"
                name="login"
                label="Login"
                autoComplete="new-password"
                error={formik.errors.login}
                value={formik.values.login || ''}
                onChange={formik.handleChange}
              />

              <FormGroup
                required
                type="password"
                name="password"
                label="Heslo"
                autoComplete="new-password"
                placeholder={!!props.data ? 'Z bezpečnostního důvodu musíte znovu vyplnit heslo.' : ''}
                error={formik.errors.password}
                value={formik.values.password || ''}
                onChange={formik.handleChange}
              />
              <FormGroup
                required
                type="text"
                name="hash"
                label="Hash"
                autoComplete="new-password"
                error={formik.errors.hash}
                value={formik.values.hash || ''}
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className="mt-5">
            <Button variant="btn-outline-default" className="float-left" type="button" onClick={() => props.onClose()}>
              Zrušit
            </Button>
            {!formik.isSubmitting ? (
              <Button
                type="submit"
                variant="btn-outline-primary"
                className="float-right"
                disabled={formik.isSubmitting}
              >
                Uložit údaje
              </Button>
            ) : (
              <BasePreloader size={29} className="d-inline-block float-right" />
            )}
            <div className="clearfix" />
          </div>
        </Form>
      </div>
    </div>
  );
};

export default DataAdministrationForm;
