import * as React from 'react';
import cx from 'classnames';

import colorIcoPdf from '@assets/images/color-ico-pdf.svg';
import colorIcoDoc from '@assets/images/color-ico-doc.svg';
import colorIcoXls from '@assets/images/color-ico-xls.svg';
import colorIcoFile from '@assets/images/color-ico-file.svg';

interface FileIconProps {
  name: string;
  className?: string;
}

const FileIcon: React.FC<FileIconProps> = (props) => {
  let src: string = colorIcoFile;

  const nameParts = props.name.toLocaleLowerCase().split('.');
  const fileExt = nameParts[nameParts.length - 1];

  switch (true) {
    case /pdf$/.test(fileExt):
      src = colorIcoPdf;
      break;
    case /doc$/.test(fileExt):
      src = colorIcoDoc;
      break;
    case /xls$/.test(fileExt):
      src = colorIcoXls;
      break;
  }

  return <img src={src} alt="fileicon" className={cx('mr-2', props.className)} />;
};

export default FileIcon;
