export const BASE = '';
export const HOME = `${BASE}/`;
export const REGISTRATION = `registration`;
export const ACCOUNT = `account`;
export const ACCOUNT_DOCUMENTS = `account-documents`;
export const ACCOUNT_AUCTIONS = `account-my-auctions`;
export const ACCOUNT_AUCTIONS_INFORMATIONS = `account-my-auctions-informations`;
export const ACCOUNT_PERSONAL_DATA = `account-personal-data`;
export const ACCOUNT_EMAILS = `account-emails`;
export const ACCOUNT_DOG = `account-dog`;
export const ACCOUNT_DOG_DETAIL = `account-dog-detail`;
export const ACCOUNT_DOG_CREATE = `account-dog-create`;
export const ACCOUNT_FAVOURITES = `account-favourite`;
export const ACCOUNT_SETTINGS = `account-settings`;
export const ACCOUNT_AUCTIONEER_REGISTRATION = `account-auctioneer-registration`;
export const AUCTION_DETAIL = `auction-detail`;
export const AUCTION_PUBLIC_DETAIL = `auction-public-detail`;
export const AUCTION_LIST_PREPARED = 'auction-list-prepared';
export const AUCTION_LIST_ONGOING = 'auction-list-ongoing';
export const AUCTION_LIST_ENDED = 'auction-list-ended';
export const AUCTION_LIST_OVERCHARGE = 'auction-list-overcharge';
export const AUCTIONEER_LIST = 'auctioneer-list';
export const AUCTIONEER_DETAIL = 'auctioneer-list-detail';
export const CONTACT = 'contact';
export const I_WANT_TO_SELL = 'i-want-to-sell';
export const I_WANT_TO_SELL_AUCTION = 'i-want-to-sell-auction';
export const I_WANT_TO_SELL_DRAZBA = 'i-want-to-sell-drazba';
export const I_WANT_TO_SELL_INDIVIDUAL = 'i-want-to-sell-individual';
export const I_WANT_TO_SELL_LEGAL_PERSON = 'i-want-to-sell-legal-person';
export const I_WANT_TO_SELL_REAL_ESTATE_AGENCIES = 'i-want-to-sell-real-estate-agencies';
export const I_WANT_TO_SELL_INSOLVENCY_ADMINISTRATORS = 'i-want-to-sell-insolvency-administrators';
export const NEWS = 'news';
export const NEWS_DETAIL = 'news-detail';
export const HINT = 'hint';
export const ABOUT_US = 'about-us';
export const TERMS = 'terms';
export const GDPR = 'gdpr';
