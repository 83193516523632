import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';

import { useEnumApi } from '@api/enum';
import { useBuyerApi } from '@api/buyer';
import { BasePreloader, Button, Select } from '@components';
import { BaseObjectType, BuyerAuctionResponse } from '@types';
import ControlFeedback from '@components/ControlFeedback/ControlFeedback';

type SelectValue = {
  value: string;
  label: string;
};

interface Props {
  auctionId: string;
  buyer: BuyerAuctionResponse;
  onSuccess: (value: boolean) => void;
  getTranslation: (key: string) => string;
}

const PreEmptionPanel: React.FC<Props> = (props) => {
  const enumApi = useEnumApi();
  const buyerApi = useBuyerApi();
  const [saved, setSaved] = useState<boolean>(true);
  const [preEmption, setPreEmption] = useState<boolean>(props.buyer.preEmption);
  const [ownershipPreEmption, setOwnershipPreEmption] = useState<boolean>(props.buyer.ownershipPreEmption);
  const [preEmptionWeight, setPreEmptionWeight] = useState<string | undefined>(props.buyer.ownershipPreEmptionWeight);
  const [preEmptionWeightOptions, setPreEmptionWeightOptions] = useState<SelectValue[]>([]);
  const [errors, setErrors] = useState<BaseObjectType<string>>({});

  useEffect(() => {
    loadEnums().then();
    return () => {
      enumApi.cancelAllRequests();
      buyerApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadEnums = async () => {
    try {
      const preEmptionWeightRes = await enumApi.getPreEmptionWeight();
      setPreEmptionWeightOptions(
        preEmptionWeightRes.data.data.map((v) => ({
          value: v.type,
          label: v.translated,
        }))
      );
    } catch (err) {
      if (enumApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleSubmit = async () => {
    setSaved(false);
    try {
      await buyerApi.changePreEmption(
        props.buyer.user.id || '',
        props.auctionId,
        preEmption,
        ownershipPreEmption,
        ownershipPreEmption ? preEmptionWeight : undefined
      );
      setErrors({});
      setSaved(true);
      props.onSuccess(preEmption);
    } catch (err) {
      if (buyerApi.isCancel(err)) {
        return;
      }
      const errors = err?.response?.data?.errors;
      setErrors(errors || {});
      setSaved(true);
    }
  };

  return (
    <div className="auction-panel-content pb-3">
      <div>
        <div className="mt-3">
          <Form.Check
            custom
            checked={preEmption}
            type="checkbox"
            id="pre-emption"
            name="pre-emption"
            className="radio-point"
            label={props.getTranslation('tab_pre_emption_label')}
            onChange={(e) => setPreEmption(e.target.checked)}
          />
        </div>
      </div>

      <div className="mt-3">
        <Form.Check
          custom
          type="checkbox"
          className="radio-point"
          id="ownership-pre-emption"
          name="ownership-pre-emption"
          checked={ownershipPreEmption}
          label={props.getTranslation('tab_ownership_pre_emption_label')}
          onChange={(e) => setOwnershipPreEmption(e.target.checked)}
        />
      </div>

      {ownershipPreEmption && (
        <div className="mt-5">
          <div className="mt-4 mb-5">
            <Form.Group className="f-inline-group">
              <Form.Label className="f-inline-label text-left">
                {props.getTranslation('tab_pre_emption_weight_label')}
              </Form.Label>
              <div className="f-inline-control">
                <div style={{ maxWidth: 355 }} data-test-id="userPreEmtionWeight">
                  <Select
                    size="md"
                    options={preEmptionWeightOptions}
                    isInvalid={!!errors.preEmptionWeight}
                    value={preEmptionWeightOptions.find((i) => i.value === preEmptionWeight) || null}
                    onChange={(val) => {
                      setPreEmptionWeight(val?.value);
                    }}
                  />
                  {!!errors.preEmptionWeight && (
                    <ControlFeedback type="invalid">{errors.preEmptionWeight}</ControlFeedback>
                  )}
                </div>
              </div>
            </Form.Group>
          </div>
        </div>
      )}

      <div className="mt-40 d-flex flex-wrap align-items-center">
        {saved ? (
          <>
            <Button type="button" className="f-size-12 mr-4" onClick={handleSubmit}>
              {props.getTranslation('tab_pre_emption_btn')}
            </Button>
            {/* <p className="mb-0 f-size-12 f-weight-normal text-color-gray-3">Posláno: 0x naposledy</p> */}
          </>
        ) : (
          <BasePreloader size={34} className="m-0" />
        )}
      </div>
    </div>
  );
};

export default PreEmptionPanel;
