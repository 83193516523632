import { API, APIResponse, BaseAPIResponse, useApi } from '@api/api';
import { StatisticFilters, StatisticGraphEnumType, StatisticReponse, StatisticYearsType } from '@types';

export const getAuctionStatisticsGraphEnumPath = () => `/enum/statistic/auction-statistic-graph`;
export const getAuctionStatisticsYearsPath = () => `/admin/statistic/auction/years`;
export const getAuctionStatisticsPath = (statisticTypeGraph: string, year: string) =>
  `/admin/statistic/auction/${statisticTypeGraph}/${year}`;

export interface StatisticAPI extends API {
  statisticGraphEnum(): APIResponse<Array<StatisticGraphEnumType>>;
  statisticYears(): BaseAPIResponse<StatisticYearsType>;
  getStatistics(statisticTypeGraph: string, year: string, params: StatisticFilters): BaseAPIResponse<StatisticReponse>;
}

export const useStatisticApi = (): StatisticAPI => {
  const api = useApi();

  return {
    ...api,
    statisticGraphEnum() {
      const cancelToken = api.prepareCancelToken('statisticGraphEnum');
      return api.authRequest.get(getAuctionStatisticsGraphEnumPath(), { cancelToken });
    },
    statisticYears() {
      const cancelToken = api.prepareCancelToken('statisticYears');
      return api.authRequest.get(getAuctionStatisticsYearsPath(), { cancelToken });
    },
    getStatistics(statisticTypeGraph: string, year: string, params: StatisticFilters) {
      const cancelToken = api.prepareCancelToken('getStatistics');
      return api.authRequest.get(getAuctionStatisticsPath(statisticTypeGraph, year), {
        cancelToken,
        params: {
          auctioneerId: params.auctioneer,
        },
      });
    },
  };
};
