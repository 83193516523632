import { API, APIResponse, useApi } from '@api/api';
import { AuctionInformedConsent } from '@types';

const getDefaultsPath = () => `/admin/auction-informed-consent`;
const getDefaultPath = (id: number) => `/admin/auction-informed-consent/${id}`;
const getInformedConsentsPath = (auctionId: number | string) => `/admin/auction/${auctionId}/informed-consents`;
const getInformedConsentPath = (auctionId: number | string, id: number) =>
  `/admin/auction/${auctionId}/informed-consents/${id}`;
const informedConsentsApprovedPath = (auctionId: string | number) =>
  `/user/auction/${auctionId}/informed-consents-approved`;

export type InformedConsentDefaultResponse = {
  id: number;
  title: string;
  text: string;
};

export type InformedContendDefaultData = {
  title: string;
  text: string;
};

export type InformedConsentData = {
  title: string;
  text: string;
};

export interface InformedConsentAPI extends API {
  getDefaults(): APIResponse<InformedConsentDefaultResponse[]>;
  updateDefault(id: number, data: InformedContendDefaultData): APIResponse<InformedConsentDefaultResponse>;
  delete(auctionId: string | number, id: number): APIResponse;
  create(auctionId: string | number, data: InformedConsentData): APIResponse;
  update(auctionId: string | number, id: number, data: InformedConsentData): APIResponse<AuctionInformedConsent>;
  getInformedConsents(auctionId: string | number): APIResponse<AuctionInformedConsent[]>;
  informedConsentApproved(auctionId: string | number): APIResponse;
}

export const useInformedConsentApi = (
  errorToastDisabled?: boolean,
  successToastDisabled?: boolean
): InformedConsentAPI => {
  const api = useApi(errorToastDisabled, successToastDisabled);

  return {
    ...api,
    getDefaults() {
      const cancelToken = api.prepareCancelToken('getDefaults');
      return api.authRequest.get(getDefaultsPath(), { cancelToken });
    },
    updateDefault(id: number, data: InformedContendDefaultData) {
      const cancelToken = api.prepareCancelToken('updateDefault');
      return api.authRequest.put(getDefaultPath(id), data, { cancelToken });
    },
    create(auctionId: string | number, data: InformedConsentData) {
      const cancelToken = api.prepareCancelToken('create');
      return api.authRequest.post(getInformedConsentsPath(auctionId), data, { cancelToken });
    },
    update(auctionId: string | number, id: number, data: InformedConsentData) {
      const cancelToken = api.prepareCancelToken('update');
      return api.authRequest.put(getInformedConsentPath(auctionId, id), data, { cancelToken });
    },
    delete(auctionId: string | number, id: number) {
      const cancelToken = api.prepareCancelToken('delete');
      return api.authRequest.delete(getInformedConsentPath(auctionId, id), { cancelToken });
    },
    getInformedConsents(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('getInformedConsents');
      return api.authRequest.get(getInformedConsentsPath(auctionId), { cancelToken });
    },
    informedConsentApproved(auctionId: string | number) {
      const cancelToken = api.prepareCancelToken('informedConsentApproved');
      return api.authFrontRequest.post(informedConsentsApprovedPath(auctionId), {}, { cancelToken });
    },
  };
};
