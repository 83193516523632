import { API, APIResponse, BaseAPIResponse, useApi } from '@api/api';
import { FacebookConnectData, FacebookInfoResponse, FacebookPageData, FacebookPagesResponse } from '@types';

export const connectPath = () => `/admin/facebook`;
export const pagePath = () => `/admin/facebook/page`;
export const getPagesPath = () => `/admin/facebook/pages`;
export const getConnectInstagramPath = () => `/admin/instagram`;

export interface FacebookAPI extends API {
  info(): APIResponse<FacebookInfoResponse>;
  connect(data: FacebookConnectData): APIResponse;
  getPages(): BaseAPIResponse<FacebookPagesResponse>;
  updatePage(data: FacebookPageData): APIResponse;
  connectInstagram(): APIResponse;
}

export const useFacebookApi = (): FacebookAPI => {
  const api = useApi();

  return {
    ...api,
    info() {
      const cancelToken = api.prepareCancelToken('info');
      return api.authRequest.get(connectPath(), { cancelToken });
    },
    connect(data: FacebookConnectData) {
      const cancelToken = api.prepareCancelToken('connect');
      return api.authRequest.put(connectPath(), data, { cancelToken });
    },
    getPages() {
      const cancelToken = api.prepareCancelToken('getPages');
      return api.authRequest.get(getPagesPath(), { cancelToken });
    },
    updatePage(data: FacebookPageData) {
      const cancelToken = api.prepareCancelToken('updatePage');
      return api.authRequest.put(pagePath(), data, { cancelToken });
    },
    connectInstagram() {
      const cancelToken = api.prepareCancelToken('connectInstagram');
      return api.authRequest.get(getConnectInstagramPath(), { cancelToken });
    },
  };
};
