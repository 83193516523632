import { Link } from 'react-router-dom';

import * as routes from '@routes';
import { NewsType } from '@types';
import { getDateFormat } from '@helpers/datetime';
import { usePageStore } from '@stores';

import NewsImage from './NewsImage';

import bellGreenIco from '@assets/images/bell-green.svg';
import noImage from '@assets/images/front/news/no-image.jpg';

export type NewsItemProps = {
  item: NewsType;
};

export const NewsItem = (props: NewsItemProps) => {
  const { title, perex, createdDt, newsMedia, slug } = props.item;
  const pageState = usePageStore();
  return (
    <div className="news-item">
      <Link to={pageState.getPagePath(routes.front.NEWS_DETAIL, { ':slug': slug })}>
        <div className="news-item-image-holder">
          {newsMedia.length > 0 ? <NewsImage images={newsMedia} /> : <img src={noImage} alt="Actuality" />}
        </div>
      </Link>
      <div className="news-item-text">
        <Link to={pageState.getPagePath(routes.front.NEWS_DETAIL, { ':slug': slug })}>
          <h2 className="news-title">{title}</h2>
        </Link>
        <p className="mt-3">
          <img src={bellGreenIco} style={{ marginRight: '8px' }} alt="" />
          {getDateFormat(createdDt)}
        </p>
        <p className="news-perex" dangerouslySetInnerHTML={{ __html: perex }} />
        <Link className="news-read-more" to={pageState.getPagePath(routes.front.NEWS_DETAIL, { ':slug': slug })}>
          Číst více
        </Link>
      </div>
    </div>
  );
};
