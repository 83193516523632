import React from 'react';
import { useLocation, Link } from 'react-router-dom';

import { usePageStore } from '@stores';

const MobileMenuItems = () => {
  const pageState = usePageStore();
  const location = useLocation();

  const menuPages = pageState.pages.filter((page) => page.menu && page.showInAdministration);

  const sortedMenuPages = menuPages.sort((a, b) => {
    if (a.menuPosition === null) {
      return 1;
    } else if (b.menuPosition === null) {
      return -1;
    }
    return a.menuPosition - b.menuPosition;
  });

  return (
    <div className="mobile-menu-items">
      <ul>
        {sortedMenuPages.map((page, index) => (
          <li key={`menu-item-${index}`} className={location.pathname === page.fullUrl ? 'item-active' : ''}>
            <Link to={pageState.getPagePath(page.routeName)}>{page.name}</Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MobileMenuItems;
