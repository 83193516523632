import { HelpRequestValues } from '@types';
import { API, APIResponse, useApi } from '@api/api';

export const sendForWebPath = () => `/web/help-request`;
export const sendForUserPath = (auctionId: string | number) => `/web/auction/${auctionId}/help-request`;

export interface HelpRequestAPI extends API {
  sendForWeb(values: HelpRequestValues): APIResponse;
  sendForUser(auctionId: string | number, values: HelpRequestValues): APIResponse;
}

export const useHelpRequestApi = (): HelpRequestAPI => {
  const api = useApi();

  return {
    ...api,
    sendForWeb(values: HelpRequestValues) {
      const cancelToken = api.prepareCancelToken('sendForWeb');
      return api.request.post(sendForWebPath(), values, { cancelToken });
    },
    sendForUser(auctionId: string | number, values: HelpRequestValues) {
      const cancelToken = api.prepareCancelToken('sendForUser');
      return api.request.post(sendForUserPath(auctionId), values, { cancelToken });
    },
  };
};
