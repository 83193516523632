import React from 'react';
import moment from 'moment';
import { ValueType } from 'react-select';
import { useLocation } from 'react-router-dom';
import { FormikValues, useFormik } from 'formik';
import { Col, Form, Row } from 'react-bootstrap';

import * as routes from '@routes';
import { useAuctioneersApi } from '@api/auctioneers';
import { getRequestDateFormat } from '@helpers/datetime';
import { AuctioneerFilters, AuctioneerStates, AuctioneerType, UserRole } from '@types';
import { Select, DatePickerInput, AdminFiltersBox, Visible } from '@components';
import FiltersInputs from '@components/AdminFiltersBox/components/FiltersInputs';
import FiltersSubmit from '@components/AdminFiltersBox/components/FiltersSubmit';

import { usePageStore } from '@stores';

interface Props {
  values: AuctioneerFilters;
  onSubmit: (values: AuctioneerFilters) => void;
}

interface TypeSelectOptionType {
  value: string;
  label: string;
}

const FiltersForm: React.FC<Props> = (props) => {
  const pageState = usePageStore();
  const auctioneersApi = useAuctioneersApi();
  const location = useLocation();
  const [isOpen, setIsOpen] = React.useState(false);
  const [auctioneerTypes, setAuctioneerTypes] = React.useState([] as Array<AuctioneerType>);
  const [auctioneerStates, setAuctioneerStates] = React.useState([] as Array<AuctioneerStates>);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: props.values,
    onSubmit: (values) => handleSubmit(values),
  });

  React.useEffect(() => {
    setIsOpen(false);
    loadAuctioneerTypes();
    loadAuctioneerStates();
    formik.resetForm();
    return () => auctioneersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.values]);

  const loadAuctioneerTypes = async () => {
    try {
      const response = await auctioneersApi.types();
      setAuctioneerTypes(response.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const loadAuctioneerStates = async () => {
    try {
      const response = await auctioneersApi.getAuctioneerStates();
      setAuctioneerStates(response.data.data);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const handleSubmit = (values: FormikValues) => {
    const formValues = { ...values };
    Object.getOwnPropertyNames(formValues).map((prop) => {
      if (formValues[prop] === '') {
        formValues[prop] = undefined;
      }
      return prop;
    });
    formValues.page = 1;
    formValues.testDate = formValues.testDate || undefined;
    formValues.invoice = formValues.invoice || undefined;
    formValues.noInvoice = formValues.noInvoice || undefined;
    props.onSubmit(formValues as AuctioneerFilters);
  };

  const hasFilters = (): boolean => {
    return !!location.search;
  };

  const getAuctioneerTypeOptions = () => {
    return auctioneerTypes.map((i) => ({ label: i.translated, value: i.type }));
  };

  const getAuctioneerStateOptions = () => {
    return auctioneerStates.map((i) => ({ label: i.translated, value: i.type }));
  };

  const handleTypeChange = (value: ValueType<TypeSelectOptionType, boolean>) => {
    const itemValue = value as TypeSelectOptionType;
    formik.setFieldValue('type', itemValue?.value || '');
  };

  const handleStateChange = (value: ValueType<TypeSelectOptionType, boolean>) => {
    const itemValue = value as TypeSelectOptionType;
    formik.setFieldValue('state', itemValue?.value || '');
  };

  const handleDateChange = (prop: string, value: Date | Date[] | null) => {
    if (value !== null) {
      const inputValue = value as Date;
      formik.setFieldValue(prop, !!inputValue ? getRequestDateFormat(inputValue) : '');
    }
  };

  return (
    <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)}>
      <AdminFiltersBox isOpen={isOpen}>
        <FiltersInputs>
          <Row>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Název subjektu / uživatele</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="auctioneerName"
                  value={formik.values.auctioneerName || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>IČ</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="ic"
                  value={formik.values.ic || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>E-mail subjektu / uživatele</Form.Label>
                <Form.Control
                  size="sm"
                  type="text"
                  name="email"
                  value={formik.values.email || ''}
                  onChange={formik.handleChange}
                />
              </Form.Group>
            </Col>
            <Col xs={12} md={6} lg={3}>
              <Form.Group>
                <Form.Label>Typ dražebníka</Form.Label>
                <Select
                  isClearable={true}
                  options={getAuctioneerTypeOptions()}
                  value={getAuctioneerTypeOptions().find((i) => i.value === formik.values.type) || null}
                  onChange={handleTypeChange}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Visible role={UserRole.superAdmin}>
              <Col xs={12} md={6} lg={3}>
                <Form.Group>
                  <Form.Label>Stav</Form.Label>
                  <Select
                    isClearable={true}
                    options={getAuctioneerStateOptions()}
                    value={getAuctioneerStateOptions().find((i) => i.value === formik.values.state) || null}
                    onChange={handleStateChange}
                  />
                </Form.Group>
              </Col>
            </Visible>
            <Col xs={12} md={6} lg={9}>
              <Row>
                <Col sm="auto" className="py-4 align-self-center">
                  <Row>
                    <Col sm="auto">
                      <Form.Check
                        custom
                        type="checkbox"
                        label="Bez smlouvy"
                        className="mt-checkbox"
                        id="noInvoice"
                        name="noInvoice"
                        checked={formik.values.noInvoice || false}
                        onChange={formik.handleChange}
                      />
                    </Col>
                    <Col sm="auto">
                      <Form.Check
                        custom
                        type="checkbox"
                        label="Zkušební doba"
                        className="mt-checkbox"
                        id="testDate"
                        name="testDate"
                        checked={formik.values.testDate || false}
                        onChange={formik.handleChange}
                      />
                    </Col>
                    <Col sm="auto">
                      <Form.Check
                        custom
                        type="checkbox"
                        label="Smlouva"
                        id="invoice"
                        name="invoice"
                        className="mt-checkbox"
                        checked={formik.values.invoice || false}
                        onChange={formik.handleChange}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col sm="auto">
                  <Row>
                    <Col xs="auto">
                      <Form.Group>
                        <Form.Label>od</Form.Label>
                        <DatePickerInput
                          className="form-control form-control-sm w-135"
                          clearIcon={null}
                          onChange={(val) => handleDateChange('testDateFrom', val)}
                          value={
                            formik.values.testDateFrom ? moment(formik.values.testDateFrom || '').toDate() : undefined
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col xs="auto">
                      <Form.Group>
                        <Form.Label>do</Form.Label>
                        <DatePickerInput
                          className="form-control form-control-sm w-135"
                          clearIcon={null}
                          onChange={(val) => handleDateChange('testDateTo', val)}
                          value={formik.values.testDateTo ? moment(formik.values.testDateTo || '').toDate() : undefined}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </FiltersInputs>
        <FiltersSubmit
          hasFilters={hasFilters()}
          onOpenClick={() => setIsOpen(true)}
          clearUrl={pageState.getPagePath(routes.admin.AUCTIONEER_LIST)}
        />
      </AdminFiltersBox>
    </Form>
  );
};

export default FiltersForm;
