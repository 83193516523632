import React from 'react';
import classNames from 'classnames';
import { Form } from 'react-bootstrap';

import { BaseType } from '@types';
import { ControlFeedback, Select } from '@components';

type Props = {
  name: string;
  label: string;
  readOnly?: boolean;
  required?: boolean;
  value?: string;
  errors?: BaseType;
  className?: string;
  dataTestId?: string;
  onChange: (val?: string) => void;
};

export const BooleanParameter: React.FC<Props> = (props) => {
  const options = [
    {
      value: 'no',
      label: 'NE',
    },
    {
      value: 'yes',
      label: 'ANO',
    },
  ];

  return (
    <Form.Group className={classNames(['f-inline-group', props.className])}>
      <Form.Label className="f-inline-label text-left">
        {props.label}
        {props.required ? ' *' : ''}
      </Form.Label>
      <div className="f-inline-control">
        <div className="w-max-500" data-test-id={props.dataTestId}>
          <Select
            size="md"
            isClearable
            name={props.name}
            options={options}
            isInvalid={!!props.errors}
            isDisabled={props.readOnly}
            value={options.find((i) => i.value === props.value) || null}
            onChange={(val) => props.onChange(val?.value !== null && val?.value !== undefined ? val?.value : undefined)}
          />
          {!!props.errors && <ControlFeedback type="invalid">{props.errors}</ControlFeedback>}
        </div>
      </div>
    </Form.Group>
  );
};
