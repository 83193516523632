import React from 'react';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { Button } from '@components';
import { usePagesApi } from '@api/pages';
import { PageTextBlockType, PageType } from '@types';
import { closeMessage, confirmMessage } from '@helpers/messages';

import plusIco from '@assets/images/plus-ico.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';
import colorIcoDel from '@assets/images/color-ico-del.svg';
import colorIcoConf from '@assets/images/color-ico-conf.svg';

interface Props {
  page: PageType;
  onDelete: () => void;
}

const PageTextBlocksTable: React.FC<Props> = (props) => {
  const pagesApi = usePagesApi();
  const pageState = usePageStore();

  const handleDeleteClick = (event: React.MouseEvent, item: PageTextBlockType) => {
    event.preventDefault();
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat tuto položku?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return pagesApi.adminPageBlockDelete(props.page.id, item.id).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        props.onDelete();
      }
    });
  };

  return (
    <div>
      <div>
        <h2>Bloky</h2>
        <Button
          to={pageState.getPagePath(routes.admin.SETTINGS_PAGES_BLOCK_CREATE, {
            ':pageId': props.page.id.toString(),
          })}
          className="mt-3"
        >
          <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
          <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
          Přidat blok
        </Button>
      </div>
      <div className="responsive-table-content mt-4">
        <Table className="table-middle" striped>
          <thead>
            <tr>
              <th>ID</th>
              <th>Název</th>
              <th>Typ</th>
              <th>Pozice</th>
            </tr>
          </thead>
          <tbody>
            {props.page.pageTextBlocks.map((item, index) => (
              <tr key={`list-item-${index}`}>
                <td className="text-left">{item.id}</td>
                <td className="text-left">{item.name}</td>
                <td className="text-left">{item.type}</td>
                <td className="text-left">{item.pageOrder}</td>
                <td className="text-right">
                  <Link
                    className="d-inline-flex align-items-center ml-4"
                    to={pageState.getPagePath(routes.admin.SETTINGS_PAGES_BLOCK_DETAIL, {
                      ':pageId': props.page.id,
                      ':blokId': item.id,
                    })}
                  >
                    <img src={colorIcoConf} alt="ico" className="mr-2" />
                    Upravit
                  </Link>
                  <Link
                    className="d-inline-flex align-items-center ml-4"
                    to="/"
                    onClick={(e) => handleDeleteClick(e, item)}
                  >
                    <img src={colorIcoDel} alt="ico" className="mr-2" />
                    Smazat
                  </Link>
                </td>
              </tr>
            ))}
            {props.page.pageTextBlocks.length < 1 && (
              <tr>
                <td className="text-left" colSpan={100}>
                  Nebyla nalezena žádná data.
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default PageTextBlocksTable;
