import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { TreeItem } from 'react-sortable-tree';

import { PageType } from '@types';
import * as routes from '@routes';
import { usePageStore } from '@stores';
import { usePagesApi } from '@api/pages';
import { BasePreloader, Button } from '@components';

import PagesTree from './PagesTree';

import plusIco from '@assets/images/plus-ico.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';

const Pages: React.FC = () => {
  const pageState = usePageStore();
  const pagesApi = usePagesApi();
  const [pages, setPages] = useState<TreeItem[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const page = pageState.getPage(routes.admin.SETTINGS_PAGES_LIST);

  useEffect(() => {
    loadPages();
    return () => pagesApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getPage = (page: PageType, responsePages: PageType[]): TreeItem => {
    return {
      title: (
        <Link to={pageState.getPagePath(routes.admin.SETTINGS_PAGES_DETAIL, { ':id': page.id })}>{page.name}</Link>
      ),
      children: responsePages.filter((i) => i.parentPageId === page.id).map((i) => getPage(i, responsePages)),
    };
  };

  const loadPages = async () => {
    try {
      const response = await pagesApi.getAdminPagesList();
      setPages(response.data.data.filter((i) => !i.parentPageId).map((i) => getPage(i, response.data.data)));
      setIsLoaded(true);
    } catch (err) {
      if (pagesApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  if (!isLoaded) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <div>
      <div className="d-block d-md-flex align-items-center mb-4">
        <h2 className="f-size-25 f-weight-300 mr-4 mb-0 mt-0">{page?.name}</h2>
        <Button to={pageState.getPagePath(routes.admin.SETTINGS_PAGES_CREATE)} className="ml-auto mt-3 mt-md-0">
          <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
          <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
          Vytvořit stránku
        </Button>
      </div>
      <div>
        <PagesTree data={pages} onDataChange={setPages} />
      </div>
    </div>
  );
};

export default Pages;
