import * as React from 'react';
import { Table } from 'react-bootstrap';
import fileDownload from 'js-file-download';

import icoCheckGreen from '@assets/images/ico-check-green.svg';
import icoDelRed from '@assets/images/ico-del-red.svg';

import { FileIcon } from '@components';
import AddDocument from './AddDocument';
import { useUsersApi } from '@api/users';
import { DocumentState, UserDocument } from '@types';

const Documents: React.FC = () => {
  const usersApi = useUsersApi();
  const [documents, setDocuments] = React.useState<Array<UserDocument>>([]);

  React.useEffect(() => {
    loadDocuments();
    // eslint-disable-next-line
  }, []);

  const loadDocuments = async () => {
    try {
      const response = await usersApi.getMyDocumentsFront();
      setDocuments(response.data.data);
    } catch (err) {
      if (usersApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const handleFileClick = (e: React.MouseEvent<HTMLAnchorElement>, item: UserDocument) => {
    e.preventDefault();
    usersApi
      .getUserDocument(item.id || '')
      .then((res) => {
        fileDownload(res.data, item.media.originalName);
      })
      .catch((err) => {
        if (usersApi.isCancel(err)) {
          return;
        }
      });
  };

  const handleDeleteFile = async (id: string | number) => {
    try {
      await usersApi.deleteUserDocument(id);
      loadDocuments();
    } catch (err) {
      if (usersApi.isCancel(err)) {
        return;
      }
      console.error(err);
    }
  };

  const renderStatus = ({ state, id }: UserDocument) => {
    switch (state) {
      case DocumentState.uploaded:
        return (
          <>
            <span style={{ marginRight: '8px' }}>Vyřizuje se</span>
            {id && (
              <img
                alt="refused"
                src={icoDelRed}
                style={{ cursor: 'pointer' }}
                onClick={() => handleDeleteFile(id)}
                data-test-id="front-user-account-documents-table-icon-delete"
              />
            )}
          </>
        );
      case DocumentState.rejected:
        return (
          <span style={{ color: '#FF0000' }} data-test-id="front-user-account-documents-table-icon-rejected">
            Neschváleno
          </span>
        );
      case DocumentState.approved:
        return (
          <>
            <img src={icoCheckGreen} alt="check" data-test-id="front-user-account-documents-table-icon-approved" />
            <span style={{ marginLeft: '8px', color: '#2FA023' }}>Schváleno</span>
          </>
        );
    }
    return '-';
  };

  return (
    <div className="component-account-documents">
      <div>Doložení ověřené totožnosti doklady konvertovanými na poště</div>
      <div>
        <AddDocument onSave={() => loadDocuments()} />
      </div>
      {documents.length > 0 && (
        <>
          <h2>Nahrané soubory</h2>
          <div className="mt-4">
            <div className="responsive-table-content">
              <Table
                striped
                className="text-center table-middle border-bottom-0"
                data-test-id="front-user-account-documents-table"
              >
                <thead>
                  <tr style={{ borderBottom: '1px solid #DFDFDF' }}>
                    <th className="text-left">Název dokumentu</th>
                    <th className="text-left">Typ dokumentu</th>
                    <th className="text-right">Stav</th>
                  </tr>
                </thead>
                <tbody>
                  {documents.map((item, index) => (
                    <tr key={`document-${index}`}>
                      <td className="text-left">
                        <FileIcon name={item.media.originalName} />
                        <a href="/" onClick={(e) => handleFileClick(e, item)}>
                          {item.media.originalName}
                        </a>
                      </td>
                      <td className="text-left">{item.translatedType || '-'}</td>
                      <td className="text-right">{renderStatus(item)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </div>
        </>
      )}
      <div style={{ marginTop: '42px', fontSize: '12px', lineHeight: '25px' }}>
        <div style={{ fontWeight: 700 }}>Upozornění</div>
        Vložení souboru (stav Čeká na kontrolu) a autorizace Dokladu elektronického prokázání totožnosti (stav Nahráno)
        neznamená automaticky prokázání totožnosti ke každé dražbě. Splnění podmínky účasti v dražbě doložením
        totožnosti musí být potvrzeno pořadatelem dražby a o jeho průběhu budete vždy informováni.
      </div>
    </div>
  );
};

export default Documents;
