import React from 'react';
import { Switch, Route, Redirect, useHistory, useLocation } from 'react-router-dom';

import * as routes from '@routes';
import { BasePage, AdminBoxContent, AdminProgressFormMenu } from '@components';
import { usePageStore } from '@stores';
import Services from './components/Services';
import Administrators from './components/Administrators';
import Administrator from './components/Administrator';
import Informations from './components/Informations';
import Pages from './components/Pages';
import TextBlocks from './components/TextBlocks';
import TextBlock from './components/TextBlock';
import TextBlockContent from './components/TextBlockContent';
import PageForm from './components/PageForm';
import PageBlockForm from './components/PageBlockForm';
import News from './components/News';
import New from './components/New';
import BillingInformations from './components/BillingInformations';
import OtherSettings from './components/OtherSettings';
import InformedConsents from './components/InformedConsents';

const SettingsPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const pageState = usePageStore();
  const page = pageState.getPage(routes.admin.SETTINGS);

  const menuItems = pageState
    .getPagesByParentPageId(pageState.getPage(routes.admin.SETTINGS)?.id || 0)
    .filter((item) => item.menu)
    .map((item) => ({
      label: item.name,
      url: item.url,
      routeName: item.routeName,
      isActive: location.pathname.startsWith(item.url),
    }));

  return (
    <BasePage page={routes.admin.SETTINGS} title={page?.name}>
      <AdminProgressFormMenu
        onClick={(url) => history.push(`${url}`)}
        items={menuItems}
        dataTestId="admin-settings-menu"
      />
      <AdminBoxContent className="p-0">
        <div className="w-min-500">
          <div className="component-admin-box-content overlow-x-auto">
            <Switch>
              {!!pageState.getPage(routes.admin.SETTINGS_INFORMATIONS) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_INFORMATIONS)?.url}>
                  <Informations />
                </Route>
              )}
              {!!pageState.getPage(routes.admin.SETTINGS_INFORMED_CONSENTS) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_INFORMED_CONSENTS)?.url}>
                  <InformedConsents />
                </Route>
              )}
              {!!pageState.getPage(routes.admin.SETTINGS_PRICE_LIST) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_PRICE_LIST)?.url}>
                  <Services />
                </Route>
              )}
              {!!pageState.getPage(routes.admin.SETTINGS_ADMINISTRATORS) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_ADMINISTRATORS)?.url}>
                  <Administrators />
                </Route>
              )}
              {!!pageState.getPage(routes.admin.SETTINGS_ADMINISTRATORS_CREATE) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_ADMINISTRATORS_CREATE)?.url}>
                  <Administrator />
                </Route>
              )}
              {!!pageState.getPage(routes.admin.SETTINGS_ADMINISTRATORS_UPDATE) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_ADMINISTRATORS_UPDATE)?.url}>
                  <Administrator />
                </Route>
              )}
              {!!pageState.getPage(routes.admin.SETTINGS_PAGES_LIST) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_PAGES_LIST)?.url}>
                  <Pages />
                </Route>
              )}
              {!!pageState.getPage(routes.admin.SETTINGS_PAGES_DETAIL) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_PAGES_DETAIL)?.url}>
                  <PageForm />
                </Route>
              )}
              {!!pageState.getPage(routes.admin.SETTINGS_PAGES_CREATE) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_PAGES_CREATE)?.url}>
                  <PageForm />
                </Route>
              )}
              {!!pageState.getPage(routes.admin.SETTINGS_PAGES_BLOCK_CREATE) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_PAGES_BLOCK_CREATE)?.url}>
                  <PageBlockForm />
                </Route>
              )}
              {!!pageState.getPage(routes.admin.SETTINGS_PAGES_BLOCK_DETAIL) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_PAGES_BLOCK_DETAIL)?.url}>
                  <PageBlockForm />
                </Route>
              )}
              {!!pageState.getPage(routes.admin.SETTINGS_TEXT_BLOCK) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_TEXT_BLOCK)?.url}>
                  <TextBlocks />
                </Route>
              )}
              {!!pageState.getPage(routes.admin.SETTINGS_TEXT_BLOCK_CREATE) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_TEXT_BLOCK_CREATE)?.url}>
                  <TextBlock />
                </Route>
              )}
              {!!pageState.getPage(routes.admin.SETTINGS_TEXT_BLOCK_UPDATE) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_TEXT_BLOCK_UPDATE)?.url}>
                  <TextBlock />
                </Route>
              )}
              {!!pageState.getPage(routes.admin.SETTINGS_TEXT_BLOCK_CONTENT_CREATE) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_TEXT_BLOCK_CONTENT_CREATE)?.url}>
                  <TextBlockContent />
                </Route>
              )}
              {!!pageState.getPage(routes.admin.SETTINGS_TEXT_BLOCK_CONTENT_UPDATE) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_TEXT_BLOCK_CONTENT_UPDATE)?.url}>
                  <TextBlockContent />
                </Route>
              )}
              {!!pageState.getPage(routes.admin.SETTINGS_NEWS) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_NEWS)?.url}>
                  <News />
                </Route>
              )}
              {!!pageState.getPage(routes.admin.SETTINGS_NEWS_CREATE) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_NEWS_CREATE)?.url}>
                  <New />
                </Route>
              )}
              {!!pageState.getPage(routes.admin.SETTINGS_NEWS_UPDATE) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_NEWS_UPDATE)?.url}>
                  <New />
                </Route>
              )}
              {!!pageState.getPage(routes.admin.SETTINGS_BILLING_INFORMATIONS) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_BILLING_INFORMATIONS)?.url}>
                  <BillingInformations />
                </Route>
              )}
              {!!pageState.getPage(routes.admin.SETTINGS_OTHER) && (
                <Route exact path={pageState.getPage(routes.admin.SETTINGS_OTHER)?.url}>
                  <OtherSettings />
                </Route>
              )}
              <Redirect to={pageState.getPage(routes.admin.SETTINGS_PRICE_LIST)?.url as string} />
            </Switch>
          </div>
        </div>
      </AdminBoxContent>
    </BasePage>
  );
};

export default SettingsPage;
