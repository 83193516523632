import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import { Form } from 'react-bootstrap';

import { Auctioneer, CSASAccount } from '@types';
import { useAuctioneersApi } from '@api/auctioneers';
import { BasePreloader, Button, ControlFeedback, Select } from '@components';

type Props = {
  id: string;
  auctioneer: Auctioneer;
  onSave: () => void;
  setShowBankAccount: (val: boolean) => void;
};

export const BankAccount: React.FC<Props> = (props) => {
  const auctioneersApi = useAuctioneersApi();
  const [accountsLoaded, setAccountsLoaded] = useState(false);
  const [accounts, setAccounts] = useState<CSASAccount[]>([]);
  const formik = useFormik({
    validateOnChange: false,
    onSubmit: () => handleSubmit(),
    initialValues: {
      account: '',
    },
  });
  const accountsOptions = accounts.map((i) => ({ value: i.id, label: `${i.account_name} - ${i.account_number}` }));

  useEffect(() => {
    if (!!props.auctioneer.auctioneerAccessCsas) {
      loadAccounts();
    } else {
      setAccounts([]);
      setAccountsLoaded(true);
    }
    return () => auctioneersApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadAccounts = async () => {
    try {
      const res = await auctioneersApi.getCSASAccounts(props.id);
      if (res.data.some((i) => i.id === props.auctioneer.auctioneerAccessCsas?.accountId)) {
        await formik.setFieldValue('account', props.auctioneer.auctioneerAccessCsas?.accountId);
      }
      setAccounts(res.data);
      setAccountsLoaded(true);
    } catch (err) {
      if (auctioneersApi.isCancel(err)) {
        return;
      }
      setAccounts([]);
      setAccountsLoaded(true);
    }
  };

  const openAuth = async () => {
    try {
      const res = await auctioneersApi.getCSASAuth(props.id);
      window.location.replace(res.data.url);
    } catch (err) {
      if (auctioneersApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleSubmit = async () => {
    try {
      await auctioneersApi.updateCSASAccount(props.id, formik.values.account);
      props.onSave();
    } catch (err) {
      const errors = err.response?.data?.errors || {};
      Object.getOwnPropertyNames(errors).map((prop) => {
        formik.setFieldError(prop, errors[prop][0]);
        return prop;
      });
      formik.setSubmitting(false);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div>
        <h2>Propojení bankovního účtu české spořitelny</h2>

        {!accountsLoaded && (
          <div className="py-3">
            <BasePreloader size={25} />
          </div>
        )}

        {accountsOptions.length > 0 && (
          <Form.Group className="pt-4">
            <Form.Label className="f-inline-label text-left">Bankovní účet *</Form.Label>
            <div className="f-inline-control">
              <div className="w-max-500">
                <Select
                  size="md"
                  options={accountsOptions}
                  isInvalid={!!formik.errors.account}
                  value={accountsOptions.find(({ value }) => formik.values.account === value) || null}
                  onChange={(v) => formik.setFieldValue('account', v?.value || '')}
                />
                {!!formik.errors.account && <ControlFeedback type="invalid">{formik.errors.account}</ControlFeedback>}
              </div>
            </div>
          </Form.Group>
        )}

        <div className="pt-3">
          <Button type="button" onClick={() => openAuth()}>
            Přihlášení do bankovního účtu
          </Button>
        </div>

        <div className="mt-5">
          <Button
            type="button"
            className="float-left"
            variant="btn-outline-default"
            onClick={() => props.setShowBankAccount(false)}
          >
            Zrušit
          </Button>
          {formik.isSubmitting ? (
            <div className="float-right">
              <BasePreloader />
            </div>
          ) : (
            <Button type="submit" className="float-right" variant="btn-outline-primary">
              Uložit údaje
            </Button>
          )}
          <div className="clearfix" />
        </div>
      </div>
    </form>
  );
};
