import React from 'react';
import { Form } from 'react-bootstrap';
import { FormikErrors, FormikValues } from 'formik';

import { Modal, Button, BasePreloader, FormGroup, DatePickerInput } from '@components';
import { ModalProps } from '@components/Modal/Modal';
import { InvoiceExportType } from './InvoiceExports';

import inputIcoCalendar from '@assets/images/input-ico-calendar.svg';
import moment from 'moment';
import { getRequestDateFormat } from '@helpers/datetime';

interface Props extends ModalProps {
  setExportType: React.Dispatch<React.SetStateAction<InvoiceExportType>>;
  exportType: InvoiceExportType;
  values: FormikValues;
  onCancel: () => void;
  isSubmitting: boolean;
  errors: FormikErrors<FormikValues>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

export const InvoiceExportModal: React.FC<Props> = (props) => {
  const handleYearMonthChange = (value: Date | Date[] | null) => {
    if (value !== null) {
      const inputValue = value as Date;
      props.setFieldValue('invoiceDate', !!inputValue ? getRequestDateFormat(inputValue) : '');
    }
  };

  const handleYearMonthDayChange = (prop: string, value: Date | Date[] | null) => {
    if (value !== null) {
      const inputValue = value as Date;
      props.setFieldValue(prop, !!inputValue ? getRequestDateFormat(inputValue) : '');
    }
  };

  return (
    <Modal {...props} onRequestClose={props.onCancel} className="modal-content-sm invoice-modal-content">
      {props.isOpen && (
        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => props.handleSubmit(e)}>
          <Form.Check
            custom
            type="radio"
            label="Podle čísel faktur"
            className="mr-4 radio-point font-weight-bold mb-3"
            id="INVOICE_NUMBER_RANGE"
            name="exportType"
            checked={props.exportType === 'INVOICE_NUMBER_RANGE'}
            onChange={() => props.setExportType('INVOICE_NUMBER_RANGE')}
          />
          {props.exportType === 'INVOICE_NUMBER_RANGE' && (
            <div>
              <div className="mb-35">
                <FormGroup
                  required
                  type="number"
                  name="invoiceNumberMin"
                  label="Od"
                  className="mt-2 mb-2"
                  labelClassName="text-left"
                  error={props.errors.invoiceNumberMin as string}
                  value={props.values.invoiceNumberMin}
                  onChange={props.handleChange}
                />
                <FormGroup
                  required
                  type="number"
                  name="invoiceNumberMax"
                  label="Do"
                  className="mt-0 mb-0"
                  labelClassName="text-left"
                  error={props.errors.invoiceNumberMax as string}
                  value={props.values.invoiceNumberMax}
                  onChange={props.handleChange}
                />
              </div>
            </div>
          )}
          <Form.Check
            custom
            type="radio"
            label="Podle období"
            className="mr-4 radio-point font-weight-bold mb-3"
            id="INVOICE_DATE"
            name="exportType"
            checked={props.exportType === 'INVOICE_DATE'}
            onChange={() => props.setExportType('INVOICE_DATE')}
          />

          {props.exportType === 'INVOICE_DATE' && (
            <div>
              <div className="mt-3 mb-3">
                <DatePickerInput
                  name="invoiceDate"
                  dateFormat="MM.yyyy"
                  showMonthYearPicker
                  className={'form-control w-max-140'}
                  calendarIcon={<img src={inputIcoCalendar} alt="ico" />}
                  clearIcon={null}
                  onChange={(val) => handleYearMonthChange(val)}
                  value={!!props.values.invoiceDate ? moment(props.values.invoiceDate || '').toDate() : undefined}
                />
              </div>
            </div>
          )}
          <Form.Check
            custom
            type="radio"
            label="Podle datumu vystavení faktur"
            className="mr-4 radio-point font-weight-bold mb-2"
            id="INVOICE_DATE_RANGE"
            name="exportType"
            checked={props.exportType === 'INVOICE_DATE_RANGE'}
            onChange={() => props.setExportType('INVOICE_DATE_RANGE')}
          />
          {props.exportType === 'INVOICE_DATE_RANGE' && (
            <div>
              <div className="mt-3 mb-3 d-flex align-items-center">
                <span className="f-size-12 w-weight-400 mr-3">Od</span>
                <DatePickerInput
                  name="ymdMin"
                  className={'form-control w-max-140'}
                  calendarIcon={<img src={inputIcoCalendar} alt="calendar" />}
                  clearIcon={null}
                  onChange={(val) => handleYearMonthDayChange('ymdMin', val)}
                  value={!!props.values.ymdMin ? moment(props.values.ymdMin || '').toDate() : undefined}
                />
              </div>
              <div className="mb-3 d-flex align-items-center">
                <span className="f-size-12 w-weight-400 mr-3">Do</span>
                <DatePickerInput
                  name="ymdMax"
                  className={'form-control w-max-140'}
                  calendarIcon={<img src={inputIcoCalendar} alt="calendar" />}
                  clearIcon={null}
                  onChange={(val) => handleYearMonthDayChange('ymdMax', val)}
                  value={!!props.values.ymdMax ? moment(props.values.ymdMax || '').toDate() : undefined}
                />
              </div>
            </div>
          )}

          <div className="mt-3">
            <Form.Check
              custom
              type="radio"
              label="Podle aktuálního filtru"
              className="mr-4 radio-point font-weight-bold mb-2"
              id="QUERY_FILTER"
              name="exportType"
              checked={props.exportType === 'QUERY_FILTER'}
              onChange={() => props.setExportType('QUERY_FILTER')}
            />
          </div>
          {props.isSubmitting ? (
            <BasePreloader size={34} />
          ) : (
            <>
              <Button className="mt-4" type="submit" variant="btn-outline-primary">
                Exportovat
              </Button>
            </>
          )}
        </Form>
      )}
    </Modal>
  );
};
