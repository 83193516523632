import React from 'react';

import { Button, FormGroup, Modal, Select } from '@components';
import { ModalProps } from '@components/Modal/Modal';
import { BaseSelectType, ServicesType } from '@types';
import { useServicesApi } from '@api/services';
import { FormikValues, useFormik } from 'formik';
import { Form } from 'react-bootstrap';
import { ValueType } from 'react-select';

interface Props extends ModalProps {
  onRequestClose: () => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  values: FormikValues;
}

interface TypeSelectOptionType {
  value: string;
  label: string;
}

const InvoiceServiceModal: React.FC<Props> = (props) => {
  const servicesApi = useServicesApi();
  const [vatOptions, setVatOptions] = React.useState<BaseSelectType[]>([]);
  const [servicesOptions, setServicesOptions] = React.useState<BaseSelectType[]>([]);
  const [services, setServices] = React.useState<ServicesType[]>([]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      unitPrice: 0,
      vat: '',
      serviceType: '',
      serviceTypeTranslation: '',
      unitTranslation: '',
      items: 0,
    },
    onSubmit: (values) => handleSubmit(values),
  });

  React.useEffect(() => {
    loadServicesOptions();
    loadVatOptions();
    return () => servicesApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (values: FormikValues) => {
    const newArr = [...props.values.auctionInvoiceItems, values];
    props.setFieldValue('[auctionInvoiceItems]', newArr);
    props.onRequestClose();
    loadServicesOptions();
    formik.resetForm();
    formik.setSubmitting(false);
  };

  const loadVatOptions = async () => {
    try {
      const response = await servicesApi.vatEnum();
      setVatOptions(
        response.data.data.map((item) => ({
          value: item.type,
          label: item.translated,
        }))
      );
    } catch (err) {
      if (servicesApi.isCancel(err)) {
        return;
      }
    }
  };

  const loadServicesOptions = async () => {
    try {
      const response = await servicesApi.servicesEnum();
      setServices(response.data.data);
      setServicesOptions(
        response.data.data.map((item) => ({
          value: item.type,
          label: item.translated,
        }))
      );
    } catch (err) {
      if (servicesApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleVatChange = (value: ValueType<TypeSelectOptionType, boolean>) => {
    const itemValue = value as TypeSelectOptionType;
    formik.setFieldValue('vat', itemValue?.value || '');
  };

  const handleTypeChange = (value: ValueType<TypeSelectOptionType, boolean>) => {
    const itemValue = value as TypeSelectOptionType;
    formik.setFieldValue('serviceType', itemValue?.value || '');
    formik.setFieldValue('serviceTypeTranslation', itemValue?.label || '');
    const unit = services.find((item) => item.type === itemValue?.value);
    formik.setFieldValue('unitTranslation', unit?.unit.translated);
  };

  return (
    <Modal className="invoice-service-modal" {...props}>
      <div>
        <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-2">
          <div className="responsive-table-content admin-services ">
            <div className=" pt-1">
              <Form.Group className="f-inline-group">
                <Form.Label className="f-inline-label text-right">Služba *</Form.Label>
                <div className="f-inline-control">
                  <div className="w-max-500">
                    <Select
                      size="md"
                      options={servicesOptions}
                      value={servicesOptions.find((i) => i.value === formik.values.serviceType) || null}
                      onChange={handleTypeChange}
                    />
                  </div>
                </div>
              </Form.Group>
              <div className="d-flex align-items-center mb-35">
                <FormGroup
                  type="text"
                  name="unitPrice"
                  label="Cena za MJ"
                  className="mt-0 mb-0"
                  controlClassName="w-max-205"
                  value={formik.values.unitPrice}
                  onChange={formik.handleChange}
                  required
                />
                <span className="f-size-12 f-weight-400 flex-shrink-0 ml-2 ml-md-0 mt-4 mt-md-0">Kč</span>
              </div>
              <div className="d-flex align-items-center mb-35">
                <FormGroup
                  type="text"
                  name="items"
                  label="Počet"
                  className="mt-0 mb-0"
                  controlClassName="w-max-205"
                  value={formik.values.items}
                  onChange={formik.handleChange}
                  required
                />
              </div>
              <Form.Group className="f-inline-group">
                <Form.Label className="f-inline-label text-right">DPH *</Form.Label>
                <div className="f-inline-control">
                  <div className="w-max-500">
                    <Select
                      size="md"
                      options={vatOptions}
                      value={vatOptions.find((i) => i.value === formik.values?.vat) || null}
                      onChange={handleVatChange}
                    />
                  </div>
                </div>
              </Form.Group>
            </div>
          </div>
          <div className="mt-5">
            <Button type="submit" variant="btn-outline-primary" className="float-right" disabled={formik.isSubmitting}>
              Přidat službu
            </Button>
            <div className="clearfix" />
          </div>
        </Form>
      </div>
    </Modal>
  );
};

export default InvoiceServiceModal;
