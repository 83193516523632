import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Table } from 'react-bootstrap';

import plusIco from '@assets/images/plus-ico.svg';
import plusIcoWhite from '@assets/images/plus-ico-white.svg';
import colorIcoDel from '@assets/images/color-ico-del.svg';
import colorIcoConf from '@assets/images/color-ico-conf.svg';
import { TextBlockContentsResponse } from '@types';
import * as routes from '@routes';
import { usePageStore } from '@stores';
import { getDateTimeFormat } from '@helpers/datetime';
import { useTextBlockApi } from '@api/textBlock';
import { closeMessage, confirmMessage } from '@helpers/messages';
import { BasePreloader, Button } from '@components';
import { stripeHtml } from '@helpers/stripeHtml';

interface Props {
  id: number;
}

const TextBlockContentTable: React.FC<Props> = (props) => {
  const textBlockApi = useTextBlockApi();
  const pageState = usePageStore();
  const [textBlockContents, setTextBlockContents] = useState<TextBlockContentsResponse[]>([]);
  const [loaded, setLoaded] = useState(true);

  useEffect(() => {
    if (props.id) {
      loadTextBlockContent(props.id);
    }
    return () => {
      textBlockApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.id]);

  const loadTextBlockContent = (id: number) => {
    setLoaded(false);
    textBlockApi
      .listTextBlockContentsOfTextBlock(id)
      .then((res) => {
        const data = res.data.data;
        setTextBlockContents(data);
        setLoaded(true);
      })
      .catch((err) => {
        if (textBlockApi.isCancel(err)) {
          return;
        }
      });
  };

  const handleDeleteClick = (event: React.MouseEvent, item: TextBlockContentsResponse) => {
    event.preventDefault();
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat obsah textového bloků?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return textBlockApi.deleteTextBlockContent(item.id).catch(() => closeMessage());
      },
    }).then((res) => {
      if (!!res.value && (res.value.status === 200 || res.value.status === 204)) {
        loadTextBlockContent(props.id);
      }
    });
  };
  return (
    <div>
      <div>
        <h2>Obsah</h2>
        <Button
          to={pageState.getPagePath(routes.admin.SETTINGS_TEXT_BLOCK_CONTENT_CREATE, {
            ':textBlockId': props.id.toString(),
          })}
          className="mt-3"
        >
          <img src={plusIco} alt="ico" className="mr-2 hover-hide" />
          <img src={plusIcoWhite} alt="ico" className="mr-2 hover-show" />
          Přidat obsah
        </Button>
      </div>
      {loaded ? (
        <div className="responsive-table-content mt-4">
          <Table className="table-middle" striped>
            <thead>
              <tr>
                <th>ID</th>
                <th>Obsah</th>
                <th>Jazyk</th>
                <th>Poslední úprava</th>
              </tr>
            </thead>
            <tbody>
              {textBlockContents.map((item, index) => (
                <tr key={`list-item-${index}`}>
                  <td className="text-left">{item.id}</td>
                  <td className="text-left">{stripeHtml(item.content, 100)}</td>
                  <td className="text-left">{item.language}</td>
                  <td className="text-left">{getDateTimeFormat(item.timeUpdate)}</td>
                  <td className="text-right">
                    <Link
                      className="d-inline-flex align-items-center ml-4"
                      to={pageState.getPagePath(routes.admin.SETTINGS_TEXT_BLOCK_CONTENT_UPDATE, {
                        ':textBlockId': props.id.toString(),
                        ':textBlockContentId': item.id.toString(),
                      })}
                    >
                      <img src={colorIcoConf} alt="ico" className="mr-2" />
                      Upravit
                    </Link>
                    <Link
                      className="d-inline-flex align-items-center ml-4"
                      to="/"
                      onClick={(e) => handleDeleteClick(e, item)}
                    >
                      <img src={colorIcoDel} alt="ico" className="mr-2" />
                      Smazat
                    </Link>
                  </td>
                </tr>
              ))}
              {textBlockContents.length < 1 && (
                <tr>
                  <td className="text-left" colSpan={100}>
                    Nebyla nalezena žádná data.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
          <BasePreloader />
        </div>
      )}
    </div>
  );
};

export default TextBlockContentTable;
