import { API, APIResponse, useApi } from '@api/api';
import { WatchDogType, WatchDogRequestType, EnumType } from '@types';

export const getIntervalPath = () => `/enum/auction-watch-dog/intervals`;
export const getWatchDogListPath = () => `/user/watch-dog`;
export const saveWatchDogPath = () => `/user/watch-dog`;
export const getWatchDogPath = (id: string | number) => `/user/watch-dog/${id}`;

export interface WatchDog extends API {
  getInterval(): APIResponse<EnumType[]>;
  getWatchDogList(): APIResponse<WatchDogType[]>;
  getWatchDogDetail(id: string | number): APIResponse<WatchDogType>;
  deleteWatchDog(id: string | number): APIResponse<WatchDogType>;
  saveWatchDog(values: WatchDogRequestType, id?: string | number): APIResponse<WatchDogType[]>;
}

export const useWatchDogApi = (): WatchDog => {
  const api = useApi();

  return {
    ...api,
    getInterval() {
      const cancelToken = api.prepareCancelToken('getInterval');
      return api.request.get(getIntervalPath(), { cancelToken });
    },
    getWatchDogList() {
      const cancelToken = api.prepareCancelToken('getWatchDogList');
      return api.authFrontRequest.get(getWatchDogListPath(), { cancelToken });
    },
    getWatchDogDetail(id: string | number) {
      const cancelToken = api.prepareCancelToken('getWatchDogDetail');
      return api.authFrontRequest.get(getWatchDogPath(id), { cancelToken });
    },
    deleteWatchDog(id: string | number) {
      const cancelToken = api.prepareCancelToken('deleteWatchDogDetail');
      return api.authFrontRequest.delete(getWatchDogPath(id), { cancelToken });
    },
    saveWatchDog(values: WatchDogRequestType, id?: string | number) {
      const cancelToken = api.prepareCancelToken('saveWatchDog');
      if (!!id) {
        return api.authFrontRequest.put(getWatchDogPath(id), values, { cancelToken });
      } else {
        return api.authFrontRequest.post(saveWatchDogPath(), values, { cancelToken });
      }
    },
  };
};
