import React, { useEffect, useState } from 'react';
import moment from 'moment';

type RenderType = {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
};

interface Props {
  date: Date;
  now: () => number;
  onComplete?: () => void;
  onTick?: (t: RenderType) => void;
  renderer: (date: RenderType) => React.ReactElement | null;
}

const Countdown: React.FC<Props> = (props) => {
  const [isProcess, setIsProcess] = useState(false);
  const [renderDate, setRenderDate] = useState<RenderType>({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  useEffect(() => {
    if (props.date.getTime() > props.now()) {
      setIsProcess(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.date]);

  useEffect(() => {
    if (isProcess) {
      process();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.now()]);

  const process = () => {
    if (props.date.getTime() <= props.now()) {
      if (typeof props.onComplete === 'function') {
        props.onComplete();
      }
      if (typeof props.onTick === 'function') {
        props.onTick({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      }
      setIsProcess(false);
      setRenderDate({ days: 0, hours: 0, minutes: 0, seconds: 0 });
      return;
    }
    let seconds = moment.duration(moment(props.date).diff(moment(props.now()))).asSeconds();
    const days = Math.floor(seconds / 60 / 60 / 24);
    const hours = Math.floor(seconds / 60 / 60 - days * 24);
    const minutes = Math.floor(seconds / 60 - days * 24 * 60 - hours * 60);
    seconds = Math.floor(seconds - minutes * 60 - days * 24 * 60 * 60 - hours * 60 * 60);
    setRenderDate({ days, hours, minutes, seconds });
    if (typeof props.onTick === 'function') {
      props.onTick({ days, hours, minutes, seconds });
    }
  };

  return props.renderer(renderDate);
};

export default Countdown;
