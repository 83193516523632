import React from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { useUsersApi } from '@api/users';
import { Button, FormGroup, BasePreloader } from '@components';

interface Props {
  email: string;
  captcha: string;
  aggreeValues: { [key: string]: boolean };
  onAggreeValueChange: (prop: string, value: boolean) => void;
  onBasicRegistration: (email: string) => any;
  onBuyerRegistration: (email: string) => any;
  regenerateCaptcha: () => void;
}

const StepEmail: React.FC<Props> = (props) => {
  const pageState = usePageStore();
  const usersApi = useUsersApi();
  const [emailError, setEmailError] = React.useState<string | undefined>(undefined);
  const [email, setEmail] = React.useState(props.email);
  const [saved, setSaved] = React.useState(true);
  const [aggreeErrors, setAggreeErrors] = React.useState<{ [key: string]: boolean }>({
    tnc: false,
    marketing: false,
    privacyPolicy: false,
  });

  const isAggreeValid = () => {
    setAggreeErrors({
      tnc: !props.aggreeValues.tnc,
      privacyPolicy: !props.aggreeValues.privacyPolicy,
    });
    return props.aggreeValues.tnc && props.aggreeValues.privacyPolicy;
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
    setEmailError(undefined);
  };

  const checkEmail = async () => {
    const validateEmail = async (email: string) => {
      try {
        await usersApi.emailValidation(email, props.aggreeValues.tnc, props.aggreeValues.privacyPolicy);
        return true;
      } catch (error) {
        const { errors } = error.response.data;
        if (errors?.email) {
          setEmailError(errors.email[0]);
        } else {
          setEmailError(undefined);
        }
        return false;
      }
    };
    return validateEmail(email);
  };

  const handleContinueBasic = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!(await checkEmail()) || !isAggreeValid()) {
      return;
    }
    setSaved(false);
    try {
      await usersApi.userRegistration(
        email,
        props.captcha,
        !!props.aggreeValues?.marketing,
        props.aggreeValues.tnc,
        props.aggreeValues.privacyPolicy
      );
      props.onBasicRegistration(email);
    } catch (err) {
      props.regenerateCaptcha();
      if (!err.response) {
        return;
      }
      const error = err.response?.data?.errors?.email;
      if (!!error) {
        setEmailError(error[0]);
      }
      setSaved(true);
    }
  };

  const handleContinueBuyer = async (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();

    if (!(await checkEmail()) || !isAggreeValid()) {
      return;
    }

    props.regenerateCaptcha();

    props.onBuyerRegistration(email);
  };

  const registrationPageContent = pageState.getPage(routes.front.REGISTRATION)?.content;

  return (
    <div className="step-email">
      <Form>
        <div className="responsive-table-content">
          {!!registrationPageContent && (
            <p className="registration-text" dangerouslySetInnerHTML={{ __html: registrationPageContent }} />
          )}
          <div className="pt-1">
            <FormGroup
              required
              type="text"
              name="email"
              label="E-mail"
              value={email}
              error={emailError}
              dataTestId="user-registration-basic-email"
              onChange={handleEmailChange}
            />
          </div>
          <div className="pt-2">
            <Form.Group className="d-flex align-items-center ml-3" data-test-id="user-registration-ntc">
              <Form.Check
                custom
                type="checkbox"
                label={
                  <p className="terms">
                    Souhlasím s{' '}
                    <Link to={pageState.getPagePath(routes.front.TERMS)} target="_blank">
                      podmínkami portálu
                    </Link>{' '}
                    *
                  </p>
                }
                className="mr-4"
                id="doesAggreeTnc"
                name="doesAggreeTnc"
                isInvalid={aggreeErrors.tnc}
                checked={props.aggreeValues.tnc}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  props.onAggreeValueChange('tnc', e.target.checked)
                }
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center ml-3" data-test-id="user-registration-privacy-policy">
              <Form.Check
                custom
                type="checkbox"
                label={
                  <p className="terms">
                    Souhlasím s{' '}
                    <Link to={pageState.getPagePath(routes.front.GDPR)} target="_blank">
                      podmínkami ochrany osobních údajů
                    </Link>{' '}
                    *
                  </p>
                }
                className="mr-4"
                id="doesAggreePrivacyPolicy"
                name="doesAggreePrivacyPolicy"
                isInvalid={aggreeErrors.privacyPolicy}
                checked={props.aggreeValues.privacyPolicy}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  props.onAggreeValueChange('privacyPolicy', e.target.checked)
                }
              />
            </Form.Group>
            <Form.Group className="d-flex align-items-center ml-3" data-test-id="user-registration-marketing">
              <Form.Check
                custom
                type="checkbox"
                label={
                  <p className="terms">
                    Souhlasím se zasíláním obchodních sdělení ve formě aktuální nabídky nových dražeb a aukcí
                  </p>
                }
                className="mr-4"
                id="doesAggreeMarketing"
                name="doesAggreeMarketing"
                checked={props.aggreeValues.marketing}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  props.onAggreeValueChange('marketing', e.target.checked)
                }
              />
            </Form.Group>
          </div>
        </div>
        <div className="account-type-wrapper">
          <div className="d-flex align-items-center">
            <Button
              type="submit"
              variant="btn-outline-primary"
              dataTestId="user-registration-basic-btn"
              onClick={handleContinueBasic}
            >
              Chci pouze základní účet
            </Button>
          </div>
          <Button
            type="submit"
            variant="btn-outline-primary"
            dataTestId="user-registration-basic-advanced-btn"
            onClick={handleContinueBuyer}
          >
            Pokračovat na registraci dražitele
          </Button>
          {!saved && <BasePreloader className="m-0 p-0 ml-4" size={30} />}
        </div>
      </Form>
    </div>
  );
};

export default StepEmail;
