import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import * as routes from '@routes';
import { usePageStore } from '@stores';
import { useWindowFocus } from '@hooks';
import { useAuctionsApi } from '@api/auctions';
import { useAuctionsListWebsocket } from '@websocket';
import { AuctionCountResponse, BaseResponse, FrontSidebarMenuItemType } from '@types';

import BaseFrontSidebarBox from './BaseFrontSidebarBox';

interface Props {
  title: string;
  items?: FrontSidebarMenuItemType[];
  onClick?: (item: FrontSidebarMenuItemType) => void;
  status?: string;
}

const route: any = {
  prepared: routes.front.AUCTION_LIST_PREPARED,
  ongoing: routes.front.AUCTION_LIST_ONGOING,
  ended: routes.front.AUCTION_LIST_ENDED,
  overcharge: routes.front.AUCTION_LIST_OVERCHARGE,
};

const FrontSidebarMenu: React.FC<Props> = (props) => {
  const history = useHistory();
  const pageState = usePageStore();
  const auctionsApi = useAuctionsApi();
  const [items, setItems] = useState<FrontSidebarMenuItemType[]>([]);
  useAuctionsListWebsocket({
    onMessage: () => getAuctionCounts(),
  });

  useWindowFocus({
    onFocus: () => {
      getAuctionCounts();
    },
  });

  useEffect(() => {
    if (props.items) {
      setItems(props.items);
      return;
    }
    getAuctionCounts();

    return () => {
      auctionsApi.cancelAllRequests();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [!!props.items ? items : undefined, props.status]);

  const getAuctionCounts = async () => {
    try {
      const auctionCountsResponse: BaseResponse<AuctionCountResponse[]> = (await auctionsApi.getWebAuctionsCount())
        .data;
      const items: FrontSidebarMenuItemType[] = auctionCountsResponse.data.map((countItem) => ({
        label: countItem.title,
        count: countItem.count,
        link: countItem.link,
        active: !!props.status && props.status === countItem.status,
        onClick: () => {
          history.push(`${pageState.getPagePath(route[countItem.status as string])}`);
        },
      }));
      setItems(items);
    } catch (err: any) {
      if (auctionsApi.isCancel(err)) {
        return;
      }
    }
  };

  const handleItemClick = (e: React.MouseEvent, item: FrontSidebarMenuItemType) => {
    e.preventDefault();
    if (!!props.onClick) {
      props.onClick(item);
      return;
    }
    item.onClick && item.onClick();
  };

  return (
    <BaseFrontSidebarBox title={props.title} className="d-none d-lg-block">
      <div className="sidebar-menu">
        <ul>
          {items
            .filter((countItem) => countItem.label !== 'Archiv')
            .map((item, index) => (
              <li key={`menu-item-${index}`} className={classNames({ 'is-active': item.active })}>
                {item.link ? (
                  <a target="_blank" rel="noopener noreferrer" data-test-id={item.testId} href={item.link}>
                    {item.label}
                    {item.count !== undefined && <span>({item.count})</span>}
                  </a>
                ) : (
                  <a href="/" data-test-id={item.testId} onClick={(e) => handleItemClick(e, item)}>
                    {item.label}
                    {item.count !== undefined && <span>({item.count})</span>}
                  </a>
                )}
              </li>
            ))}
          {items
            .filter((countItem) => countItem.label === 'Archiv')
            .map((item, index) => (
              <li key={`menu-item-${index}`} className={classNames({ 'is-active': item.active })}>
                <a target="_blank" rel="noopener noreferrer" href="https://exdrazby.cz" data-test-id={item.testId}>
                  {item.label}
                  {item.count !== undefined && <span>({item.count})</span>}
                </a>
              </li>
            ))}
        </ul>
      </div>
    </BaseFrontSidebarBox>
  );
};

export default FrontSidebarMenu;
