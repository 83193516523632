import React from 'react';
import { Form } from 'react-bootstrap';
import { Formik, FormikValues } from 'formik';

import { useUsersApi } from '@api/users';
import { ModalProps } from '@components/Modal/Modal';
import { Modal, Button, BasePreloader } from '@components';

interface Props extends ModalProps {
  onSuccess: () => void;
  userId: number;
}

const ChangeUserEmailModal: React.FC<Props> = (props) => {
  const usersApi = useUsersApi();

  const handleFormSubmit = async (values: FormikValues, setSubmitting: (isSubmitting: boolean) => void) => {
    try {
      await usersApi.changeEmail(props.userId, values.email1, values.email2);
      setSubmitting(false);
      props.onSuccess();
    } catch (err: any) {
      setSubmitting(false);
    }
  };

  return (
    <Modal {...props} title={props.title} onAfterClose={() => {}}>
      {props.isOpen && (
        <Formik
          initialValues={{
            email1: '',
            email2: '',
          }}
          onSubmit={(values, { setSubmitting }) => handleFormSubmit(values, setSubmitting)}
        >
          {(formProps) => (
            <Form
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => formProps.handleSubmit(e)}
              data-test-id="front-login-modal-form"
            >
              <Form.Group className="mb-16">
                <Form.Label>Nový e-mail</Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="email1"
                  className="input-md"
                  data-test-id="front-login-modal-form-email"
                  value={formProps.values.email1}
                  disabled={formProps.isSubmitting}
                  onChange={formProps.handleChange}
                />
              </Form.Group>

              <Form.Group className="mb-16">
                <Form.Label>Nový e-mail znovu pro ověření</Form.Label>
                <Form.Control
                  required
                  type="email"
                  name="email2"
                  className="input-md"
                  data-test-id="front-login-modal-form-email"
                  value={formProps.values.email2}
                  disabled={formProps.isSubmitting}
                  onChange={formProps.handleChange}
                />
              </Form.Group>

              <Form.Group>
                {formProps.isSubmitting ? (
                  <BasePreloader size={34} />
                ) : (
                  <Button
                    type="submit"
                    variant="btn-outline-primary"
                    dataTestId="front-login-modal-form-btn"
                    className="fa-pull-right mb-20"
                  >
                    Změnit email
                  </Button>
                )}
              </Form.Group>
            </Form>
          )}
        </Formik>
      )}
    </Modal>
  );
};

export default ChangeUserEmailModal;
