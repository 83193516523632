import React, { ChangeEvent, useEffect, useRef } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { FormikErrors } from 'formik';
import { Form } from 'react-bootstrap';

import { FormGroup } from '@components';
import { BuyerFormValues } from '@types';
import { strIsLatin } from '@helpers/string';

interface Props {
  readOnly?: boolean;
  title?: string;
  withoutSuggest?: boolean;
  contentClassName?: string;
  values: BuyerFormValues;
  errors: FormikErrors<BuyerFormValues>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => any;
  handleChange: {
    (e: React.ChangeEvent<any>): void;
    <T_1 = string | React.ChangeEvent<any>>(field: T_1): T_1 extends React.ChangeEvent<any>
      ? void
      : (e: string | React.ChangeEvent<any>) => void;
  };
}

class SuggestProvider extends window.SMap.SuggestProvider {
  _processData() {
    if (!this._responseData) return;

    const rawData: any = JSON.parse(this._responseData.data);

    if (rawData.result && Array.isArray(rawData.result)) {
      const resultsItems = rawData.result.map((item: any) => {
        const poiTypeId = item.userData.poiTypeId || 0;
        const firstRow = (item.userData.suggestFirstRow || '').trim();

        let id = item.userData.id;

        if (item.userData.source === 'coor' && typeof id === 'number') {
          id = item.userData.longitude + ',' + item.userData.latitude;
        }

        return {
          origData: item.userData,
          coords: window.SMap.Coords.fromWGS84(item.userData.longitude, item.userData.latitude),
          longitude: parseFloat(item.userData.longitude),
          latitude: parseFloat(item.userData.latitude),
          source: item.userData.source,
          id: id,
          title: firstRow,
          secondRow: (item.userData.suggestSecondRow || '').trim(),
          thirdRow: (item.userData.suggestThirdRow || '').trim(),
          phrase: firstRow || item.sentence,
          iconType: item.userData.iconType || '',
          iconUrl: this._getIconUrl(poiTypeId),
          poiTypeId: poiTypeId,
          mmid: item.userData.mmid,
          mmsource: item.userData.mmsource,
          mmtype: item.userData.mmtype,
          searchCategory: item.category,
        };
      });
      this._promise.fulfill(resultsItems);
    }
    this._promise = null;
    this._request = null;
  }
}

const Address: React.FC<Props> = (props) => {
  const addressSuggest = useRef<any>();
  const deliverySuggest = useRef<any>();
  const invoiceSuggest = useRef<any>();

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;
    if (!props.withoutSuggest) {
      timeout = setTimeout(() => {
        addressSuggest.current = addSuggest('user-address');
      }, 500);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      addressSuggest.current?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.withoutSuggest]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;
    if (!props.withoutSuggest && props.values.hasDifferentDeliveryAddress) {
      timeout = setTimeout(() => {
        deliverySuggest.current = addSuggest('user-address-delivery');
      }, 500);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      deliverySuggest.current?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.withoutSuggest, props.values.hasDifferentDeliveryAddress]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined = undefined;
    if (!props.withoutSuggest && props.values.hasDifferentInvoiceAddress) {
      timeout = setTimeout(() => {
        invoiceSuggest.current = addSuggest('user-address-invoice');
      }, 500);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
      invoiceSuggest.current?.destroy();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.withoutSuggest, props.values.hasDifferentInvoiceAddress]);

  const addSuggest = (inputId: string) => {
    const suggest = new window.SMap.Suggest(document.getElementById(inputId), {
      provider: new SuggestProvider(),
    });

    suggest.addListener('suggest', (e: any) => {
      if (inputId === 'user-address') {
        props.setFieldValue('address', e.data.origData.suggestFirstRow);
        props.setFieldValue('city', e.data.origData.municipality);
        props.setFieldValue('zip', e.data.origData.zipCode);
      }

      if (inputId === 'user-address-delivery') {
        props.setFieldValue('deliveryAddress', e.data.origData.suggestFirstRow);
        props.setFieldValue('deliveryCity', e.data.origData.municipality);
        props.setFieldValue('deliveryZip', e.data.origData.zipCode);
      }

      if (inputId === 'user-address-invoice') {
        props.setFieldValue('invoiceAddress', e.data.origData.suggestFirstRow);
        props.setFieldValue('invoiceCity', e.data.origData.municipality);
        props.setFieldValue('invoiceZip', e.data.origData.zipCode);
      }
    });

    return suggest;
  };

  const handleHasDifferentDeliveryAddressChange = (hasDifferentDeliveryAddress: boolean) => {
    props.setFieldValue('hasDifferentDeliveryAddress', hasDifferentDeliveryAddress);
  };

  const handleHasDifferentInvoiceAddressChange = (value: boolean) => {
    props.setFieldValue('hasDifferentInvoiceAddress', value);
  };

  const handleLatinValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      props.handleChange(e);
      return;
    }
    if (strIsLatin(e.target.value)) {
      props.handleChange(e);
    }
  };

  return (
    <>
      <div className="step-buyer-section">
        {!!props.title && <div className={cx('section-title')}>{props.title}</div>}
        <div className={props.contentClassName || 'responsive-table-content'}>
          <FormGroup
            required
            type="text"
            autoComplete="off"
            name="address"
            id="user-address"
            label="Ulice a č.p."
            value={props.values.address}
            readOnly={props.readOnly}
            error={_.get(props.errors, 'userAddresses_0_address')}
            onChange={handleLatinValueChange}
          />
          <FormGroup
            required
            type="text"
            name="zip"
            label="PSČ"
            value={props.values.zip}
            readOnly={props.readOnly}
            error={_.get(props.errors, 'userAddresses_0_zipCode')}
            onChange={handleLatinValueChange}
          />
          <FormGroup
            required
            type="text"
            name="city"
            label="Město"
            value={props.values.city}
            readOnly={props.readOnly}
            error={_.get(props.errors, 'userAddresses_0_city')}
            onChange={handleLatinValueChange}
          />
          <FormGroup
            required
            type="text"
            name="country"
            label="Země"
            value={props.values.country}
            readOnly={props.readOnly}
            error={_.get(props.errors, 'userAddresses_0_country')}
            onChange={handleLatinValueChange}
          />
          <Form.Group className="d-flex align-items-center ml-3 mt-5">
            <Form.Check
              custom
              readOnly={props.readOnly}
              type="checkbox"
              label="Adresa pro doručování je jiná než adresa trvalého bydliště"
              className="mr-4"
              id="hasDifferentDeliveryAddress"
              name="hasDifferentDeliveryAddress"
              checked={props.values.hasDifferentDeliveryAddress}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (!props.readOnly) {
                  handleHasDifferentDeliveryAddressChange(e.target.checked);
                }
              }}
            />
          </Form.Group>
        </div>
      </div>
      {!!props.values.hasDifferentDeliveryAddress && (
        <div className="step-buyer-section mt-0">
          <div className={cx('section-title')}>Doručovací adresa</div>
          <div className={props.contentClassName || 'responsive-table-content'}>
            <FormGroup
              required
              id="user-address-delivery"
              error={_.get(props.errors, 'userAddresses_1_address')}
              type="text"
              name="deliveryAddress"
              label="Ulice a č.p."
              value={props.values.deliveryAddress}
              readOnly={props.readOnly}
              onChange={handleLatinValueChange}
            />
            <FormGroup
              required
              type="text"
              name="deliveryZip"
              label="PSČ"
              error={_.get(props.errors, 'userAddresses_1_zipCode')}
              value={props.values.deliveryZip}
              readOnly={props.readOnly}
              onChange={handleLatinValueChange}
            />
            <FormGroup
              required
              type="text"
              name="deliveryCity"
              label="Město"
              error={_.get(props.errors, 'userAddresses_1_city')}
              value={props.values.deliveryCity}
              readOnly={props.readOnly}
              onChange={handleLatinValueChange}
            />
            <FormGroup
              required
              type="text"
              name="deliveryCountry"
              label="Země"
              error={_.get(props.errors, 'userAddresses_1_country')}
              value={props.values.deliveryCountry}
              readOnly={props.readOnly}
              onChange={handleLatinValueChange}
            />
          </div>
        </div>
      )}

      <div className="step-buyer-section invoice-address-checkbox">
        <div className={props.contentClassName || 'responsive-table-content'}>
          <Form.Group className="d-flex align-items-center ml-3 mt-4">
            <Form.Check
              custom
              readOnly={props.readOnly}
              type="checkbox"
              label="Fakturační adresa je jiná než adresa trvalého bydliště"
              className="mr-4"
              id="hasDifferentInvoiceAddress"
              name="hasDifferentInvoiceAddress"
              checked={props.values.hasDifferentInvoiceAddress}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                if (!props.readOnly) {
                  handleHasDifferentInvoiceAddressChange(e.target.checked);
                }
              }}
            />
          </Form.Group>
        </div>
      </div>

      {!!props.values.hasDifferentInvoiceAddress && (
        <div className="step-buyer-section invoice-address-content mt-0">
          <div className={cx('section-title')}>Fakturační adresa</div>
          <div className={props.contentClassName || 'responsive-table-content'}>
            <FormGroup
              required
              id="user-address-invoice"
              type="text"
              name="invoiceAddress"
              label="Ulice a č.p."
              readOnly={props.readOnly}
              error={_.get(
                props.errors,
                `userAddresses_${
                  props.values.hasDifferentDeliveryAddress && props.values.hasDifferentInvoiceAddress ? '2' : '1'
                }_address`
              )}
              value={props.values.invoiceAddress}
              onChange={handleLatinValueChange}
            />
            <FormGroup
              required
              type="text"
              name="invoiceZip"
              label="PSČ"
              error={_.get(
                props.errors,
                `userAddresses_${
                  props.values.hasDifferentDeliveryAddress && props.values.hasDifferentInvoiceAddress ? '2' : '1'
                }_zipCode`
              )}
              value={props.values.invoiceZip}
              readOnly={props.readOnly}
              onChange={handleLatinValueChange}
            />
            <FormGroup
              required
              type="text"
              name="invoiceCity"
              label="Město"
              error={_.get(
                props.errors,
                `userAddresses_${
                  props.values.hasDifferentDeliveryAddress && props.values.hasDifferentInvoiceAddress ? '2' : '1'
                }_city`
              )}
              value={props.values.invoiceCity}
              readOnly={props.readOnly}
              onChange={handleLatinValueChange}
            />
            <FormGroup
              required
              type="text"
              name="invoiceCountry"
              label="Země"
              error={_.get(
                props.errors,
                `userAddresses_${
                  props.values.hasDifferentDeliveryAddress && props.values.hasDifferentInvoiceAddress ? '2' : '1'
                }_country`
              )}
              value={props.values.invoiceCountry}
              readOnly={props.readOnly}
              onChange={handleLatinValueChange}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Address;
