import React from 'react';
import { useHistory } from 'react-router-dom';

import iconHammerGrey19 from '@assets/images/front/icons/hammer-grey-19.svg';
import iconHammerColor19 from '@assets/images/front/icons/hammer-color-19.svg';
import iconHammerWhite19 from '@assets/images/front/icons/hammer-white-19.svg';
import iconHammerGreyer19 from '@assets/images/front/icons/hammer-greyer-19.svg';

import * as routes from '@routes';
import { AuctionResponse, UserRole, AuctionStatus } from '@types';
import { useAuthFrontStore, usePageStore } from '@stores';
import { AuctionTile, BasePreloader, LoginModal, useVisibility } from '@components';
import BeforeJoinModal from './BeforeJoinModal';
import { AuctionInformationsSections } from '../../pages/front/AccountAuctionInformations/AccountAuctionInformations';

interface Props {
  title?: string;
  auctions: Array<AuctionResponse>;
  isLoading?: boolean;
  joinedLoaded?: string | number;
  onAuctionJoin?(auction: AuctionResponse): void;
  onAuctionIsFavouriteChange?(auction: AuctionResponse, isFavorite: boolean): void;
}

const FrontAuctionsList: React.FC<Props> = (props) => {
  const history = useHistory();
  const { isLogged } = useAuthFrontStore();
  const pageState = usePageStore();
  const { shouldBeVisible } = useVisibility();
  const [showLoginModal, setShowLoginModal] = React.useState(false);
  const [beforeJoin, setBeforeJoin] = React.useState<{
    content: React.ReactNode;
    actionText: string;
    onAction: () => void;
  } | null>(null);

  const enableJoinAuction = shouldBeVisible({
    isLoggedFront: true,
    role: UserRole.buyer,
    disableSuperAdminOverride: true,
  });

  const getJoinText = (auction: AuctionResponse) => {
    if (auction.outage) {
      return 'Dražba/aukce již probíhá';
    }
    if (auction.joinAuctionButtonText) {
      return auction.joinAuctionButtonText;
    }
    if (auction.parentStatus === AuctionStatus.ongoing) {
      return 'Dražba/aukce již probíhá';
    }
    if (auction.parentStatus === AuctionStatus.ended) {
      return 'Dražba/aukce již proběhla';
    }
    if (!auction.canJoin) {
      return 'Nelze se připojit';
    }
    return 'Připojit se k dražbě/aukci';
  };

  return (
    <>
      <LoginModal
        title="Přihlášení"
        isOpen={showLoginModal}
        onRequestClose={() => setShowLoginModal(false)}
        onSuccess={() => setShowLoginModal(false)}
      />
      <BeforeJoinModal isOpen={!!beforeJoin} onRequestClose={() => setBeforeJoin(null)} {...(beforeJoin as any)} />
      <div className="component-front-auctions-list">
        {!!props.title && <h2 className="block-title">{props.title}</h2>}
        {props.isLoading ? (
          <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
            <BasePreloader />
          </div>
        ) : (
          <div className="list-items">
            {props.auctions.map((auction, index) => (
              <AuctionTile
                key={`${index}-${auction.id}`}
                auction={auction}
                primaryActionLoader={props.joinedLoaded === auction.id}
                primaryActionVariant={
                  !enableJoinAuction || (!auction.isJoined && !auction.canJoin) ? 'btn-outline-grey' : undefined
                }
                primaryActionClassName={
                  !auction.isJoined &&
                  auction.canJoin &&
                  (auction.parentStatus === AuctionStatus.ongoing || auction.parentStatus === AuctionStatus.ended)
                    ? 'cursor-default'
                    : 'cursor-pointer'
                }
                primaryActionText={
                  (enableJoinAuction && auction.parentStatus === AuctionStatus.prepared && auction.canJoin) ||
                  auction.isJoined ? (
                    <>
                      <img src={iconHammerColor19} alt="icon" className="mr-13 hover-hide" />
                      <img src={iconHammerWhite19} alt="icon" className="mr-13 hover-show" />
                      {auction.isJoined ? `Již jste připojen` : auction.joinAuctionButtonText}
                    </>
                  ) : (
                    <>
                      <img src={iconHammerGrey19} alt="icon" className="mr-13 hover-hide" />
                      <img src={iconHammerGreyer19} alt="icon" className="mr-13 hover-show" />
                      {getJoinText(auction)}
                    </>
                  )
                }
                onPrimaryActionClick={() => {
                  if (enableJoinAuction && auction.canJoin && !auction.outage) {
                    if (typeof props.onAuctionJoin === 'function' && !auction.isJoined) {
                      props.onAuctionJoin(auction);
                      return;
                    }
                  }

                  if (
                    !auction.isJoined &&
                    (auction.parentStatus === AuctionStatus.ongoing || auction.parentStatus === AuctionStatus.ended)
                  ) {
                    return;
                  }

                  if (auction.isJoined && auction.parentStatus === AuctionStatus.prepared) {
                    history.push(
                      `${pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS_INFORMATIONS, {
                        ':id': auction.id,
                      })}?s=${AuctionInformationsSections.terms}`
                    );
                    return;
                  }

                  if (auction.isJoined && auction.parentStatus === AuctionStatus.ended) {
                    history.push(
                      `${pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS_INFORMATIONS, {
                        ':id': auction.id,
                      })}?s=${AuctionInformationsSections.result}`
                    );
                    return;
                  }

                  if (auction.isJoined && auction.parentStatus === AuctionStatus.ongoing) {
                    history.push(
                      `${pageState.getPagePath(routes.front.ACCOUNT_AUCTIONS_INFORMATIONS, {
                        ':id': auction.id,
                      })}?s=${AuctionInformationsSections.process}`
                    );
                    return;
                  }

                  if (isLogged && auction.canJoin) {
                    setBeforeJoin({
                      content: 'Pro aktivní účast v dražbě/aukci je potřeba dokončit registraci dražitele.',
                      actionText: 'Dokončit registraci dražitele',
                      onAction: () => {
                        setBeforeJoin(null);
                        history.push(pageState.getPagePath(routes.front.ACCOUNT_AUCTIONEER_REGISTRATION));
                      },
                    });
                    return;
                  }

                  if (auction.canJoin) {
                    setBeforeJoin({
                      content: 'Pro aktivní účast v dražbě/aukci je potřeba se přihlásit.',
                      actionText: 'Přihlásit',
                      onAction: () => {
                        setBeforeJoin(null);
                        setShowLoginModal(true);
                      },
                    });
                  }
                }}
                onIsFavouriteChange={(isFavorite) => {
                  if (typeof props.onAuctionIsFavouriteChange === 'function') {
                    props.onAuctionIsFavouriteChange(auction, isFavorite);
                  }
                }}
                disableFooter
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default FrontAuctionsList;
