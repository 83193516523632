import React from 'react';

import AddDocument from './AddDocument';
import AuctionImages from './AuctionImages';
import DocumentsTable from './DocumentsTable';
import { useAuctionsApi } from '@api/auctions';
import { confirmMessage } from '@helpers/messages';
import { BasePreloader, Visible } from '@components';
import { AuctionDocumentResponse, PermissionGroup, UserPermission } from '@types';

interface Props {
  id: string;
  auctionType: string;
  getTranslation: (key: string) => string;
}

const Documents: React.FC<Props> = (props) => {
  const auctionsApi = useAuctionsApi();
  const [loaded, setLoaded] = React.useState(false);
  const [documents, setDocuments] = React.useState([] as Array<AuctionDocumentResponse>);

  React.useEffect(() => {
    loadData();
    return () => auctionsApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    try {
      const response = await auctionsApi.getDocuments(props.id);
      setDocuments(response.data);
      setLoaded(true);
    } catch (err) {
      if (!err.response) {
        return;
      }
    }
  };

  const handleDeleteClick = (item: AuctionDocumentResponse) => {
    confirmMessage({
      title: 'Potvrzení',
      text: 'Opravdu si přejete odebrat tuto položku?',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return new Promise(async (resolve) => {
          try {
            await auctionsApi.deleteDocument(props.id, item.id);
            await loadData();
            resolve(true);
          } catch {
            resolve(true);
          }
        });
      },
    });
  };

  if (!loaded) {
    return (
      <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
        <BasePreloader />
      </div>
    );
  }

  return (
    <div>
      <div>
        <h2>Dokumenty</h2>
        <Visible permissionGroupName={PermissionGroup.auctionDocuments} permissionName={UserPermission.canCreate}>
          <AddDocument id={props.id} auctionType={props.auctionType} isPublic={true} onSave={() => loadData()} />
        </Visible>
        <DocumentsTable
          auctionId={props.id}
          isPublic={true}
          onDeleteClick={handleDeleteClick}
          data={documents.filter((i) => i.public)}
        />
      </div>
      <div className="mt-5">
        <h2>Galerie</h2>
        <AuctionImages id={props.id} getTranslation={props.getTranslation} />
      </div>
      <div className="mt-5">
        <h2>Neveřejné přílohy</h2>
        <Visible permissionGroupName={PermissionGroup.auctionDocuments} permissionName={UserPermission.canCreate}>
          <AddDocument
            id={props.id}
            isPublic={false}
            auctionType={props.auctionType}
            accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png"
            onSave={() => loadData()}
          />
        </Visible>
        <DocumentsTable
          auctionId={props.id}
          isPublic={false}
          onDeleteClick={handleDeleteClick}
          data={documents.filter((i) => !i.public)}
        />
      </div>
    </div>
  );
};

export default Documents;
