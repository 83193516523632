import React from 'react';
import _ from 'lodash';
import { FormikValues, useFormik } from 'formik';
import { Form } from 'react-bootstrap';

import { AuctionService, BaseSelectType } from '@types';
import { useServicesApi } from '@api/services';
import { BasePreloader, FormGroup, Button, Select, ControlFeedback } from '@components';
import { ValueType } from 'react-select';
import { useAuctioneersApi } from '@api/auctioneers';
import { useState } from 'react';

interface Props {
  data: AuctionService;
  auctioneerId: string;
  onClose: () => void;
  onSave: () => void;
}

interface TypeSelectOptionType {
  value: string;
  label: string;
}

const ServiceForm: React.FC<Props> = (props) => {
  const auctioneersApi = useAuctioneersApi();
  const servicesApi = useServicesApi();
  const [vatOptions, setVatOptions] = React.useState<BaseSelectType[]>([]);
  const [loaded, setLoaded] = useState(false);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      price: props.data.price ?? undefined,
      state: props.data.state ?? 'active',
      vat: props.data.vat ?? '',
      auctioneerServiceElectronicAuction: {
        percent30000: _.get(props.data, 'auctioneerServiceElectronicAuction.percent30000', ''),
        percent300000: _.get(props.data, 'auctioneerServiceElectronicAuction.percent300000', ''),
        percent1000000: _.get(props.data, 'auctioneerServiceElectronicAuction.percent1000000', ''),
        percent3000000: _.get(props.data, 'auctioneerServiceElectronicAuction.percent3000000', ''),
        percent10000000: _.get(props.data, 'auctioneerServiceElectronicAuction.percent10000000', ''),
        percent999999999: _.get(props.data, 'auctioneerServiceElectronicAuction.percent999999999', ''),
        plus30000: _.get(props.data, 'auctioneerServiceElectronicAuction.plus30000', ''),
        plus300000: _.get(props.data, 'auctioneerServiceElectronicAuction.plus300000', ''),
        plus1000000: _.get(props.data, 'auctioneerServiceElectronicAuction.plus1000000', ''),
        plus3000000: _.get(props.data, 'auctioneerServiceElectronicAuction.plus3000000', ''),
        plus10000000: _.get(props.data, 'auctioneerServiceElectronicAuction.plus10000000', ''),
        plus999999999: _.get(props.data, 'auctioneerServiceElectronicAuction.plus999999999', ''),
      },
    },
    onSubmit: (values) => handleSubmit(values),
  });

  React.useEffect(() => {
    loadVatOptions();
    return () => servicesApi.cancelAllRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadVatOptions = async () => {
    try {
      const response = await servicesApi.vatEnum();
      setVatOptions(
        response.data.data.map((item) => ({
          value: item.type,
          label: item.translated,
        }))
      );
    } catch (err) {
      if (servicesApi.isCancel(err)) {
        return;
      }
    }
    setLoaded(true);
  };

  const handleSubmit = (values: FormikValues) => {
    const omitInputs = ['auctioneerServiceElectronicAuction'];
    const omitElectronicInputs = ['price'];
    const inputs =
      props.data.serviceForAuctioneer.serviceType === 'electronic_auction'
        ? _.omit(values, omitElectronicInputs)
        : _.omit(values, omitInputs);
    auctioneersApi
      .servicesUpdate(props.auctioneerId, props.data.id.toString(), inputs)
      .then(() => {
        props.onSave();
        formik.setSubmitting(false);
      })
      .catch((err) => {
        if (servicesApi.isCancel(err)) {
          return;
        }
        const errors = err.response?.data?.errors || {};
        Object.getOwnPropertyNames(errors).map((prop) => {
          formik.setFieldError(prop, errors[prop][0]);
          return prop;
        });
        formik.setSubmitting(false);
      });
  };

  const handleVatChange = (value: ValueType<TypeSelectOptionType, boolean>) => {
    const itemValue = value as TypeSelectOptionType;
    formik.setFieldValue('vat', itemValue?.value || '');
  };

  function handleAuctionType(value: string) {
    formik.setFieldValue('state', value);
  }

  return (
    <div>
      <h2>Upravit službu</h2>
      {loaded ? (
        <div>
          <Form onSubmit={(e: React.FormEvent<HTMLFormElement>) => formik.handleSubmit(e)} className="mt-5">
            <div className="responsive-table-content admin-services ">
              <div className=" pt-1">
                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label mb-0 text-right">Stav</Form.Label>
                  <div className="f-inline-control d-flex align-items-center settings-group">
                    <Form.Check
                      custom
                      type="radio"
                      label="Aktivní"
                      className="mr-4 radio-point"
                      id="active"
                      name="state"
                      checked={formik.values.state === 'active'}
                      onChange={() => handleAuctionType('active')}
                    />
                    <Form.Check
                      custom
                      type="radio"
                      label="Neaktivní"
                      className="mr-4 radio-point"
                      id="deactive"
                      name="state"
                      checked={formik.values.state === 'deactive'}
                      onChange={() => handleAuctionType('deactive')}
                    />
                  </div>
                </Form.Group>
                {props.data.serviceForAuctioneer.serviceType !== 'electronic_auction' && (
                  <div className="d-flex align-items-center mb-35">
                    <FormGroup
                      required
                      thousandSeparator
                      name="price"
                      label="Cena"
                      className="mt-0 mb-0"
                      controlClassName="w-max-205"
                      value={formik.values.price}
                      error={formik.errors.price}
                      onValueChange={(v) => formik.setFieldValue('price', v?.value ?? '')}
                    />
                    <span className="f-size-12 f-weight-400">Kč</span>
                  </div>
                )}
                <Form.Group className="f-inline-group">
                  <Form.Label className="f-inline-label text-right">DPH *</Form.Label>
                  <div className="f-inline-control">
                    <div className="w-max-500">
                      <Select
                        size="md"
                        options={vatOptions}
                        isInvalid={!!_.get(formik.errors, 'vat')}
                        value={vatOptions.find((i) => i.value === formik.values.vat) || null}
                        onChange={handleVatChange}
                      />
                      {!!_.get(formik.errors, 'vat') && (
                        <ControlFeedback type="invalid">{_.get(formik.errors, 'vat')}</ControlFeedback>
                      )}
                    </div>
                  </div>
                </Form.Group>
                {props.data.serviceForAuctioneer.serviceType === 'electronic_auction' && (
                  <>
                    <h5>Ceník</h5>
                    <Form.Group className="f-inline-group multiline-input">
                      <Form.Label className="f-inline-label text-right main-label">0 - 30 000:</Form.Label>
                      <FormGroup
                        thousandSeparator
                        type="text"
                        name="auctioneerServiceElectronicAuction.percent30000"
                        label="Procenta:"
                        value={formik.values.auctioneerServiceElectronicAuction.percent30000}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_percent30000')}
                        onValueChange={(v) =>
                          formik.setFieldValue('auctioneerServiceElectronicAuction.percent30000', v?.value ?? '')
                        }
                      />
                      <FormGroup
                        thousandSeparator
                        type="text"
                        name="auctioneerServiceElectronicAuction.plus30000"
                        label="Paušální částka:"
                        value={formik.values.auctioneerServiceElectronicAuction.plus30000}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_plus30000')}
                        onValueChange={(v) =>
                          formik.setFieldValue('auctioneerServiceElectronicAuction.plus30000', v?.value ?? '')
                        }
                      />
                    </Form.Group>

                    <Form.Group className="f-inline-group multiline-input">
                      <Form.Label className="f-inline-label text-right main-label">30 001 - 300 000:</Form.Label>
                      <FormGroup
                        thousandSeparator
                        type="text"
                        name="auctioneerServiceElectronicAuction.percent300000"
                        label="Procenta:"
                        value={formik.values.auctioneerServiceElectronicAuction.percent300000}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_percent300000')}
                        onValueChange={(v) =>
                          formik.setFieldValue('auctioneerServiceElectronicAuction.percent300000', v?.value ?? '')
                        }
                      />
                      <FormGroup
                        thousandSeparator
                        type="text"
                        name="auctioneerServiceElectronicAuction.plus300000"
                        label="Paušální částka:"
                        value={formik.values.auctioneerServiceElectronicAuction.plus300000}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_plus300000')}
                        onValueChange={(v) =>
                          formik.setFieldValue('auctioneerServiceElectronicAuction.plus300000', v?.value ?? '')
                        }
                      />
                    </Form.Group>

                    <Form.Group className="f-inline-group multiline-input">
                      <Form.Label className="f-inline-label text-right main-label">300 001 - 1 000 000:</Form.Label>
                      <FormGroup
                        thousandSeparator
                        type="text"
                        name="auctioneerServiceElectronicAuction.percent1000000"
                        label="Procenta:"
                        value={formik.values.auctioneerServiceElectronicAuction.percent1000000}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_percent1000000')}
                        onValueChange={(v) =>
                          formik.setFieldValue('auctioneerServiceElectronicAuction.percent1000000', v?.value ?? '')
                        }
                      />
                      <FormGroup
                        thousandSeparator
                        type="text"
                        name="auctioneerServiceElectronicAuction.plus1000000"
                        label="Paušální částka:"
                        value={formik.values.auctioneerServiceElectronicAuction.plus1000000}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_plus1000000')}
                        onValueChange={(v) =>
                          formik.setFieldValue('auctioneerServiceElectronicAuction.plus1000000', v?.value ?? '')
                        }
                      />
                    </Form.Group>

                    <Form.Group className="f-inline-group multiline-input">
                      <Form.Label className="f-inline-label text-right main-label">1 000 001 - 3 000 000:</Form.Label>
                      <FormGroup
                        thousandSeparator
                        type="text"
                        name="auctioneerServiceElectronicAuction.percent3000000"
                        label="Procenta:"
                        value={formik.values.auctioneerServiceElectronicAuction.percent3000000}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_percent3000000')}
                        onValueChange={(v) =>
                          formik.setFieldValue('auctioneerServiceElectronicAuction.percent3000000', v?.value ?? '')
                        }
                      />
                      <FormGroup
                        thousandSeparator
                        type="text"
                        name="auctioneerServiceElectronicAuction.plus3000000"
                        label="Paušální částka:"
                        value={formik.values.auctioneerServiceElectronicAuction.plus3000000}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_plus3000000')}
                        onValueChange={(v) =>
                          formik.setFieldValue('auctioneerServiceElectronicAuction.plus3000000', v?.value ?? '')
                        }
                      />
                    </Form.Group>

                    <Form.Group className="f-inline-group multiline-input">
                      <Form.Label className="f-inline-label text-right main-label">3 000 001 - 10 000 000:</Form.Label>
                      <FormGroup
                        thousandSeparator
                        type="text"
                        name="auctioneerServiceElectronicAuction.percent10000000"
                        label="Procenta:"
                        value={formik.values.auctioneerServiceElectronicAuction.percent10000000}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_percent10000000')}
                        onValueChange={(v) =>
                          formik.setFieldValue('auctioneerServiceElectronicAuction.percent10000000', v?.value ?? '')
                        }
                      />
                      <FormGroup
                        thousandSeparator
                        type="text"
                        name="auctioneerServiceElectronicAuction.plus10000000"
                        label="Paušální částka:"
                        value={formik.values.auctioneerServiceElectronicAuction.plus10000000}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_plus10000000')}
                        onValueChange={(v) =>
                          formik.setFieldValue('auctioneerServiceElectronicAuction.plus10000000', v?.value ?? '')
                        }
                      />
                    </Form.Group>

                    <Form.Group className="f-inline-group multiline-input">
                      <Form.Label className="f-inline-label text-right main-label">
                        10 000 001 - 999 999 999:
                      </Form.Label>
                      <FormGroup
                        thousandSeparator
                        type="text"
                        name="auctioneerServiceElectronicAuction.percent999999999"
                        label="Procenta:"
                        value={formik.values.auctioneerServiceElectronicAuction.percent999999999}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_percent999999999')}
                        onValueChange={(v) =>
                          formik.setFieldValue('auctioneerServiceElectronicAuction.percent999999999', v?.value ?? '')
                        }
                      />
                      <FormGroup
                        thousandSeparator
                        type="text"
                        name="auctioneerServiceElectronicAuction.plus999999999"
                        label="Paušální částka:"
                        value={formik.values.auctioneerServiceElectronicAuction.plus999999999}
                        error={_.get(formik.errors, 'auctioneerServiceElectronicAuction_plus999999999')}
                        onValueChange={(v) =>
                          formik.setFieldValue('auctioneerServiceElectronicAuction.plus999999999', v?.value ?? '')
                        }
                      />
                    </Form.Group>
                  </>
                )}
              </div>
            </div>
            <div className="mt-5">
              <Button
                variant="btn-outline-default"
                className="float-left"
                type="button"
                onClick={() => props.onClose()}
              >
                Zpět
              </Button>
              {!formik.isSubmitting ? (
                <Button
                  type="submit"
                  variant="btn-outline-primary"
                  className="float-right"
                  disabled={formik.isSubmitting}
                >
                  Uložit službu
                </Button>
              ) : (
                <BasePreloader size={29} className="d-inline-block float-right" />
              )}
              <div className="clearfix" />
            </div>
          </Form>
        </div>
      ) : (
        <div className="pt-5 pb-5 d-flex align-items-center justify-content-center">
          <BasePreloader />
        </div>
      )}
    </div>
  );
};

export default ServiceForm;
